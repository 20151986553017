import { createSlice } from "@reduxjs/toolkit";

const modalState = {
  workflowModalOpened: false,
  modalMode: undefined,
  selectedWorkflow: undefined,
  selectedSource: undefined,
  selectedEvent: undefined,
  // riskLevels: RISK_LEVEL_OPTIONS,
  selectedRiskLevel: undefined,
  selectedAction: undefined,
  // selectedSentimentalRating: SENTIMENT_RATINGS_OPTIONS[1],
  selectedSentimentalRating: undefined,
  selectedFrequency: undefined,
  selectedDestination: undefined,
  // selectedView: workflowModalViews.WORKFLOW_DETAILS,
  selectedView: undefined,
  silenceEvent: false,
  selectedTraining: undefined,
  editorRelatedImages: [],
  companyTrainings: [],
};

const initialState = {
  workflows: [],
  events: [],
  riskLevels: [],
  ...modalState,
};

const workflowsSlice = createSlice({
  name: "workflows",
  initialState,
  reducers: {
    initStore(state, action) {
      state.selectedSentimentalRating =
        action.payload.selectedSentimentalRating;
      state.riskLevels = action.payload.riskLevels;
      state.selectedView = action.payload.selectedView;
    },

    setWorkflows(state, action) {
      state.workflows = action.payload.workflows;
    },

    setEvents(state, action) {
      state.events = action.payload.events;
    },

    addWorkflow(state) {
      state.workflowModalOpened = true;
      state.modalMode = "CREATE_WORKFLOW";
      state.selectedWorkflow = undefined;
    },

    editWorkflow(state, action) {
      state.workflowModalOpened = true;
      state.modalMode = "EDIT_WORKFLOW";
      state.selectedWorkflow = action.payload.workflow;
    },

    addWorkflowSuccess(state, action) {
      let workflow = action.payload.workflow;
      workflow.action = workflow.action.name;
      workflow.event = workflow.event.event_name;
      state.workflows.unshift(workflow);
      state.workflowModalOpened = false;
      state.selectedWorkflow = undefined;
      state.modalMode = undefined;
      state.selectedView = "Workflow Details";
    },

    editWorkflowSuccess(state, action) {
      const selectedWorkflow = state.workflows.find(
        (w) => w.id === action.payload.workflow.id
      );
      state.workflows = state.workflows.map((w) => {
        if (w.id === selectedWorkflow.id) {
          return selectedWorkflow;
        }
        return w;
      });
      state.workflowModalOpened = false;
      state.selectedWorkflow = undefined;
      state.modalMode = undefined;
      state.selectedView = "Workflow Details";
    },

    selectSource(state, action) {
      state.selectedSource = action.payload.source;
    },

    selectEvent(state, action) {
      let recommendedRiskLevel;
      let event = {};
      if (action.payload.event.value) {
        const eventSelectedByUser = state.events.find(
          (event) => event.id === action.payload.event.value
        );
        recommendedRiskLevel = state.riskLevels.find(
          (riskLevel) =>
            riskLevel.value === eventSelectedByUser.recommended_risk_level
        );
        event = action.payload.event;
      } else if (action.payload.event.id) {
        const eventSelectedByUser = state.events.find(
          (event) => event.id === action.payload.event.id
        );
        recommendedRiskLevel = state.riskLevels.find(
          (riskLevel) =>
            riskLevel.value === eventSelectedByUser.recommended_risk_level
        );
        event = {
          label: eventSelectedByUser.description,
          value: eventSelectedByUser.id,
        };
      }

      state.selectedEvent = event;
      state.selectedRiskLevel = recommendedRiskLevel;
    },

    selectRiskLevel(state, action) {
      state.selectedRiskLevel = action.payload.riskLevel;
    },

    selectAction(state, action) {
      state.selectedAction = action.payload.action;
    },

    selectSentimentalRating(state, action) {
      state.selectedSentimentalRating = action.payload.sentimentalRating;
    },

    selectFrequency(state, action) {
      state.selectedFrequency = action.payload.frequency;
    },

    selectDestination(state, action) {
      state.selectedDestination = action.payload.destination;
    },

    selectView(state, action) {
      state.selectedView = action.payload.view;
    },

    setSilenceEvent(state, action) {
      state.silenceEvent = action.payload.value;
    },

    setCompanyTrainings(state, action) {
      state.companyTrainings = action.payload.trainings;
    },

    selectTraining(state, action) {
      state.selectedTraining = action.payload.training;
    },

    addImage(state, action) {
      state.editorRelatedImages.push(action.payload.imageID);
    },

    setRiskLevels(state, action) {
      state.riskLevels = action.payload.riskLevels;
    },

    closeWorkflowModal(state, action) {
      return {
        ...state,
        ...modalState,
        workflows: state.workflows,
        events: state.events,
        selectedView: action.payload.view,
        selectedSentimentalRating: action.payload.sentiment,
      };
    },
  },
});

export const workflowsActions = workflowsSlice.actions;

export default workflowsSlice.reducer;
