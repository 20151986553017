import { useState, useEffect, Fragment } from 'react';
import Modal from '../../Modal/Modal';
import { CURRICULUM_ITEM_TYPES } from '../../../utils/training';

const ReorderErrorConfirmModal = ({ showModal, onSubmit, onClose, data }) => {
  const [activeItemTitle, setActiveItemTitle] = useState('');
  const [activeItemType, setActiveItemType] = useState('');
  const [obsticleItemTitle, setObsticleItemTitle] = useState('');
  const [obsticleItemType, setObsticleItemType] = useState('');
  const [assessmentTitle, setAssessmentTitle] = useState('');
  const [lessonTitle, setLessonTitle] = useState('');
  const [direction, setDirection] = useState('');
  const [backendError, setBackendError] = useState(undefined);

  useEffect(() => {
    if (data.reason) {
      setBackendError(data.reason);
      return;
    }
    if (data.activeItemType === CURRICULUM_ITEM_TYPES.ASSESSMENT) {
      setActiveItemTitle(data.activeItem.assessment.title);
      setActiveItemType('quiz');
      setObsticleItemTitle(data.obsticleItem.title);
      setObsticleItemType('lesson');
      setAssessmentTitle(data.activeItem.assessment.title);
      setLessonTitle(data.obsticleItem.title);
      setDirection('after');
    }
    if (data.activeItemType === CURRICULUM_ITEM_TYPES.LESSON) {
      setActiveItemTitle(data.activeItem.title);
      setActiveItemType('lesson');
      setObsticleItemTitle(data.obsticleItem.assessment.title);
      setObsticleItemType('quiz');
      setAssessmentTitle(data.obsticleItem.assessment.title);
      setLessonTitle(data.activeItem.title);
      setDirection('in front of');
    }
  }, [data]);

  return (
    <Modal
      show={showModal}
      title='Reorder Error'
      onSubmit={onSubmit}
      onClose={onClose}
      compact
      noFooter
    >
      {backendError && (
        <Fragment>
          {backendError.split(':').map((item, i) => (
            <p className='mt-1 block' key={i}>
              {item}
              {item.endsWith('.') ? '' : '.'}
            </p>
          ))}
        </Fragment>
      )}

      {!backendError && (
        <Fragment>
          <p className='mt-1'>
            You tried to move the <b>{activeItemTitle}</b> {activeItemType}{' '}
            {direction} the <b>{obsticleItemTitle}</b> {obsticleItemType}, but
            we prevented that action.
          </p>
          <p className='mt-1'>
            The <b>{assessmentTitle}</b> quiz settings include the{' '}
            <b>{lessonTitle}</b> lesson in the <b>Skip Lessons</b> option.
          </p>
          <p className='mt-1'>Please remove it from there and try again.</p>
        </Fragment>
      )}
    </Modal>
  );
};

export default ReorderErrorConfirmModal;
