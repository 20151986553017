import api from "../client";
/*
 * Calls Slack API method.
 *
 * apiMethod: string. Slack API method name in Python lib interpetation.
 * For example, instead of 'users.list' use 'users_list'
 */
export const slackApi = async ({ apiMethod, params = {} }) => {
  const { data } = await api.post(`api/slack/api`, {
    method_name: apiMethod,
    params,
  });
  return data;
};
