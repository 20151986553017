import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteEmployee } from "../../../api/employeesApi";

export const useDeleteEmployee = ({ employeeID, onSuccess }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteEmployee,
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries({ queryKey: ["employees"] });
      queryClient.invalidateQueries({
        queryKey: ["employee-details", employeeID],
        exact: true,
      });
    },
  });
};
