import { useCallback, useEffect, useState } from "react";
import * as api from "../../api";
import { onError } from "../../libs/errorLib";

const useFetchSlackChannels = ({ auth }) => {
  const [trigger, setTrigger] = useState(false);
  const [reloadChannels, setReloadChannels] = useState(false);
  const [channels, setChannels] = useState([]);
  const [completed, setCompleted] = useState(false);

  const reloadChannelsHandler = () => {
    setCompleted(false);
    setChannels([]);
    setReloadChannels((state) => !state);
  };

  const doRequest = useCallback(
    (params) => {
      return api.slackApi({
        auth,
        apiMethod: "users_conversations",
        params,
      });
    },
    [auth]
  );

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        let fetchedChannels = [];
        let params = {
          limit: 1000,
          exclude_archived: true,
          types: "public_channel, private_channel",
        };
        let response = null;

        let loadMore = true;
        do {
          response = await doRequest(params);
          params = {
            ...params,
            cursor: response.response_metadata.next_cursor,
          };
          fetchedChannels = fetchedChannels.concat(response.channels);
          if (response.response_metadata.next_cursor === "") loadMore = false;
        } while (response.response_metadata && loadMore);
        const options = fetchedChannels.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setChannels([...options]);
        setCompleted(true);
      } catch (e) {
        onError(e);
      }
    };
    if (trigger && auth && auth.company_details.title) fetchChannels();
  }, [doRequest, trigger, auth, reloadChannels]);

  return { channels, completed, setTrigger, reloadChannelsHandler };
};

export default useFetchSlackChannels;
