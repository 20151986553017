import React from 'react';
import { useNavigate } from 'react-router-dom';

const Back = ({ caption, push = false }) => {
  const navigate = useNavigate();

  return (
    <button
      type='button'
      onClick={() => (push ? navigate(push) : navigate(-1))}
      className='button__icon--left button button--gray bg-hka_gray-light'
    >
      <span className='icon-arrow left'></span>
      {caption}
    </button>
  );
};

export default Back;
