import Divider from '../../../Base/Divider';
import LoaderInline from '../../../LoaderInline';

const EmployeeResultItem = ({
  title,
  itemType,
  attemptResults,
  position,
  isLoadingData,
  onAttemptSelect,
  assessmentItemId,
}) => {
  const selectAttemptHandler = () => {
    if (attemptResults) {
      onAttemptSelect({
        itemType,
        item: attemptResults,
        assessmentID: assessmentItemId,
      });
    }
  };

  return (
    <div className=''>
      <div className='flex justify-between items-center py-0.7'>

        <div
          className={` ${
            !!attemptResults && 'cursor-pointer'
          }`}
          onClick={selectAttemptHandler}
        >
          <p className='text-sm'>
            {itemType === 'Assessment' ? 'Question' : 'Lesson'} {position}
          </p>
          <p className='text-base link--bold'>{title}</p>
        </div>
        {isLoadingData && (
          <LoaderInline wrapperClasses={'min-h-2 mx-2'} loaderSize={2} />
        )}
        {!isLoadingData && !attemptResults && <p>Item not attempted yet</p>}
        {!isLoadingData && attemptResults && (
          <p className='text-sm font-basis-bold text-hka_gray-dark'>
            {(
              (attemptResults.earned_score / attemptResults.possible_score) *
              100
            ).toFixed(0)}
            %
          </p>
        )}
      </div>
      <Divider horizontal />
    </div>
  );
};

export default EmployeeResultItem;
