import React from "react";
import Modal from "../../Modal/Modal";
import Note from "../../Note";

export default function RemoveTemplateFromCampaignModal({
  showModal,
  template,
  onSubmit,
  onClose,
}) {
  return (
    <Modal
      title={`Remove Template From Campaign`}
      show={showModal}
      submitButtonText="Remove"
      submitButtonType="danger"
      onSubmit={onSubmit}
      onClose={onClose}
      compact
    >
      <Note type="danger">
        <p>
          If you remove this from the campaign, your assigned users will not be
          receiving the emails from the following template:
        </p>
        <p className="font-basis-bold">{`${
          template.application || "Haekka"
        }:  ${template.name}`}</p>
      </Note>
    </Modal>
  );
}
