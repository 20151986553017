import { checkboxOptions } from './CheckboxGroup';

const Checkbox = ({ isCorrect, disabled, selected, onSelect }) => {
  const icon =
    isCorrect === checkboxOptions.CORRECT ? 'icon-check' : 'icon-close';

  let css = undefined;
  if (disabled) {
    css = 'text-hka_gray-light bg-hka_gray-ultraLight';
  } else if (isCorrect === checkboxOptions.CORRECT && selected) {
    css = 'text-hka_green bg-hka_green-light';
  } else if (isCorrect === checkboxOptions.INCORRECT && selected) {
    css = 'text-hka_red bg-hka_red-light';
  }

  return (
    <div onClick={() => onSelect(isCorrect)}>
      <span
        className={`${icon} w-2.4 h-2.4 rounded-md ${css} flex items-center justify-center`}
      ></span>
    </div>
  );
};

export default Checkbox;
