import React, { useState } from "react";
import ActionButton from "../ActionButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag, faUsers } from "@fortawesome/free-solid-svg-icons";
import { modes, types } from "../../utils/groupModalUtils";
import { formatAvatar } from "../../utils/formatAvatar";

const ListItemGroupModal = ({ payload, onRemove, onAdd, mode, removable }) => {
  const [sync, setSync] = useState(payload.sync || false);

  const handleOnSyncButtonClick = () => {
    setSync(!sync);
    payload.sync = !sync;
  };

  const handleOnRemove = (payload) => onRemove(payload);
  const handleOnAdd = (payload) => onAdd(payload);

  return (
    <li className="border-b border-hka_gray-light">
      {payload.type === types.EMPLOYEE && (
        <div>
          {payload.avatar ? (
            <img
              className="mr-1 user-img"
              src={payload.avatar}
              alt={payload.label}
            />
          ) : (
            formatAvatar({
              name: payload.label,
              width: 2.4,
              marginRight: 1,
              textSize: "text-sm",
            })
          )}
        </div>
      )}
      {payload.type === types.CHANNEL && (
        <span className="flex items-center justify-center w-2.4 h-2.4 mr-1 text-md">
          <FontAwesomeIcon className="select__channel-icon" icon={faHashtag} />
        </span>
      )}
      {payload.type === types.SLACK_USER_GROUP && (
        <span className="flex items-center justify-center w-4 h-4 mr-1 text-md">
          <FontAwesomeIcon className="select__channel-icon" icon={faUsers} />
        </span>
      )}
      <div className="flex flex-1 lg:flex-row flex-nowrap">
        <p
          className={`flex-1 self-center text-hka_gray-dark font-basis-medium`}
        >
          {payload.label}
        </p>
      </div>
      {payload.type === "channel" && (
        <div className="flex items-center mr-3">
          <label className="switch">
            <input
              type="checkbox"
              name={`${payload.value}_sync`}
              checked={sync}
              onChange={handleOnSyncButtonClick}
              disabled={!removable && mode === modes.EDIT}
            />
            <span className="slider"></span>
          </label>
          <span
            className="hidden ml-1 text-sm border-b border-dotted sm:block border-hka_gray-dark decoration-dotted font-basis-bold"
            data-tip={`This automatically assigns employees to the group when they join #${payload.label}.`}
          >
            Sync
          </span>
        </div>
      )}
      {mode === modes.CREATE ? (
        <button
          type="button"
          onClick={() => onRemove(payload)}
          className="px-0 button button--red"
        >
          <span className="flex text-red icon-trash -mt-0.1"></span>
        </button>
      ) : (
        <div className="container--edit-group-item">
          <ActionButton
            onClick={() =>
              removable ? handleOnRemove(payload) : handleOnAdd(payload)
            }
            type={removable ? "danger" : "primary"}
          >
            <span
              className={`h-3 mb-0.3 ${
                removable ? "text-red icon-trash" : "text-blue icon-plus"
              }`}
            ></span>
          </ActionButton>
        </div>
      )}
    </li>
  );
};

export default ListItemGroupModal;
