import { Fragment, useContext } from "react";
import AssignEmployeesContext from "../../store/assign-employeees-context";
import TrainingGroupSelector from "../Groups/TrainingGroupSelector";

const GroupsOptions = () => {
  const assignEmployeesContext = useContext(AssignEmployeesContext);
  const {
    groups,
    setSelectedGroupsForTraining,
    loadingGroupsCompleted,
    syncGroups,
    setSyncGroups,
  } = assignEmployeesContext;

  const options = groups.map(({ icon, name, id }) => ({
    icon,
    name,
    value: id,
  }));

  const defaultValue = options.filter((option) =>
    syncGroups.includes(option.value)
  );

  return (
    <Fragment>
      <section className="mb-3">
        <h4 className="mb-1 font-basis-bold">
          Add users From Group <span className="text-hka_gray">(Optional)</span>
        </h4>
        <TrainingGroupSelector
          options={options}
          loadingGroupsCompleted={loadingGroupsCompleted}
          onChange={setSelectedGroupsForTraining}
        />
      </section>

      <section className="mb-3">
        <h4 className="mb-1 font-basis-bold">
          Sync users from group{" "}
          <span className="text-hka_gray">(Optional)</span>
        </h4>
        <p className="mb-1">
          Note: syncing users from a group will only grab users that are added
          after this change is applied. To also add existing users from a group,
          you can use sync in combination with <b>Add users from a group</b>{" "}
          above.
        </p>
        <TrainingGroupSelector
          loadingGroupsCompleted={loadingGroupsCompleted}
          options={options}
          onChange={setSyncGroups}
          defaultValue={defaultValue}
        />
      </section>
    </Fragment>
  );
};

export default GroupsOptions;
