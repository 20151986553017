const HeaderSection = () => {
  return (
    <section className="flex items-center gap-2">
      <img
        src="https://haekka-public-images.s3.us-west-2.amazonaws.com/haekka__engagement-graphic.svg"
        alt="Engagement Icon"
        className="min-w-5"
      />
      <p className="mb-1 text-sm sm:text-base">
        Engagements are a great way to continuously engage your staff on privacy
        and security topics. Think of engagements as ongoing quizzes, surveys or
        announcements.
      </p>
    </section>
  );
};

export default HeaderSection;
