import { formatAvatar } from "../../utils/formatAvatar";

const AssignedEmployeesCardSecton = ({ assignedEmployeesData }) => {
  return (
    <div className="card__item-group pl-0.5">
      {assignedEmployeesData?.employees.map((employee) => (
        <div className="card__item--avatar" key={employee.employee__name}>
          {employee.employee__avatar_image ? (
            <img
              className="rounded-md"
              src={employee.employee__avatar_image}
              alt={employee.employee__name}
              height={24}
              width={24}
            />
          ) : (
            formatAvatar({
              name: employee.employee__name,
              width: 2.4,
              marginRight: 0,
              textSize: "text-sm",
            })
          )}
        </div>
      ))}
      {assignedEmployeesData?.total > 3 && (
        <p className="mb-0.5 ml-1">
          {" "}
          + {assignedEmployeesData.total - 3}
        </p>
      )}
    </div>
  );
};

export default AssignedEmployeesCardSecton;
