import BulkTrainingDropdownOptions from './BulkTrainingDropdownOptions';
import Select from 'react-select';
import { FILTER_OPTIONS, SORT_OPTIONS } from './ListTrainingUsers';
import { useAppContext } from '../../../libs/contextLib';

const ListHeader = ({
  allEmployeesSelected,
  setAllEmployeesSelected,
  setSelectedEmployees,
  curriculumEmployees,
  training,
  selectedEmployees,
  listChangedHandler,
  setSortOption,
  setFilterOption,
  setSearchQuery,
  setCurrentPage,
  menuItems,
}) => {
  const { isAuthenticated } = useAppContext();

  const selectAllEmployeesHandler = () => {
    setAllEmployeesSelected((ps) => !ps);
    if (allEmployeesSelected) {
      setSelectedEmployees([]);
    } else {
      const employeeIDs = curriculumEmployees?.results.map(
        (employee) => employee.employee
      );
      setSelectedEmployees(employeeIDs);
    }
  };

  return (
    <div>
      <h4 className='flex-1 w-full leading-4 text-hka_gray-dark sm:w-auto font-basis-medium'>
        Assigned Employees
      </h4>
      <div className='flex flex-col sm:flex-row justify-between space-y-0.5 sm:space-y-0 border-hka_gray-border border-opacity-20'>
        <section className='flex-1 w-full text-hka_gray sm:w-auto'>
          <div className='flex items-center space-x-1'>
            <section className='flex items-center text-hka_gray sm:w-auto'>
              <label className='mr-1 checkbox'>
                <input
                  type='checkbox'
                  id='select-all'
                  checked={allEmployeesSelected}
                  onChange={selectAllEmployeesHandler}
                  className='cursor-pointer'
                />
                <span className='icon-check'></span>
              </label>
              <p className='cursor-pointer' onClick={selectAllEmployeesHandler}>
                Select All
              </p>
            </section>
            {isAuthenticated.is_admin && (
              <BulkTrainingDropdownOptions
                training={training}
                allEmployeesSelected={allEmployeesSelected}
                selectedEmployees={selectedEmployees}
                updateEmployeeList={listChangedHandler}
              />
            )}
          </div>
        </section>

        <div className='w-full sm:w-20 sm:mr-1'>
          <Select
            classNamePrefix='select'
            placeholder='Sort By'
            defaultValue={SORT_OPTIONS[1]}
            options={SORT_OPTIONS}
            onChange={(e) => {
              setSortOption(e.value);
              setCurrentPage(1);
            }}
            unstyled
          />
        </div>
        <div className='w-full sm:w-20 sm:mr-1'>
          <Select
            classNamePrefix='select'
            placeholder='Filter By'
            defaultValue={FILTER_OPTIONS[0]}
            options={FILTER_OPTIONS}
            onChange={(e) => {
              setFilterOption(e.value);
              setCurrentPage(1);
            }}
            unstyled
          />
        </div>
        <div className='flex items-center flex-1 pl-0.5 h-4 lg:flex-none border border-hka_gray-border rounded-lg bg-white w-full sm:w-auto'>
          <span className='icon-search text-hka_gray mt-0.2'></span>
          <input
            className='h-3 text-base bg-transparent border-none min-w-20 pl-0.5 max-h-3.8 focus:outline-none'
            type='text'
            placeholder='Search Employees'
            onChange={({ target }) => {
              setSearchQuery(target.value);
              setCurrentPage(1);
            }}
          />
          <span className='h-2 py-1 w-0.1'></span>
          {menuItems && (
            <div className='flex flex-row'>
              {menuItems.map(({ icon, onClick, label }, i) => (
                <div
                  key={i}
                  className='cursor-pointer flex items-center justify-center h-2.8 w-3 border-l border-hka_gray-light'
                  onClick={onClick}
                >
                  <span
                    className={`${icon} ${i !== menuItems.length - 1 && ''}`}
                    data-tip={label}
                  ></span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListHeader;
