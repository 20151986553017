import { Fragment, useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import AssignEmployeesContext from '../store/assign-employeees-context';
import { useEngagementsData } from '../hooks/data-hooks/engagements';
import { useAppContext } from '../libs/contextLib';
import * as api from '../api';

import { removeUrlParam } from '../utils/commonUtils';
import Loader from '../components/Loader';
import Callout from '../components/Callout';
import TransitionWrapper from '../components/Base/TransitionWrapper';
import EngagementListItem from '../components/Engagements/EngagementListItem';
import ListItems from '../components/ListItems';
import Pagination from '../components/Pagination';
import EngagementModal from '../components/Engagements/EngagementModal/EngagementModal';

export default function Engagements() {
  const { isAuthenticated } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  let params = queryString.parse(location.search);

  const [currentPage, setCurrentPage] = useState(1);
  const [showCreateEngagementModal, setShowCreateEngagementModal] =
    useState(false);

  const { isLoading, data: engagements } = useEngagementsData({ currentPage });

  // open create eng modal if there is ?create=true in the URL
  const createEngagementParam =
    params && params.create ? Boolean(params.create) : false;
  useEffect(() => {
    if (createEngagementParam) {
      setShowCreateEngagementModal(true);
    }
  }, [createEngagementParam]);

  const closeCreateEngagementModalHandler = () => {
    setShowCreateEngagementModal(false);
    removeUrlParam({ location, navigate, param: 'create' });
  };

  // trigger loading users and channels (should be refactored)
  const { onImportUsers } = useContext(AssignEmployeesContext);
  useEffect(() => {
    onImportUsers();
  }, [onImportUsers]);

  useEffect(() => {
    // TO-DO: improve this all over the code base
    const loadEmojis = async () => {
      const emojis = await api.getEmojisJsonMapper({
        auth: isAuthenticated,
        file: 'emojis.json',
      });
      localStorage.setItem('haekka-emojis', emojis);

      const emojis_2 = await api.getEmojisJsonMapper({
        auth: isAuthenticated,
        file: 'emojis-2.json',
      });
      localStorage.setItem('haekka-emojis-2', emojis_2);
    };
    if (
      !localStorage.getItem('haekka-emojis') ||
      !localStorage.getItem('haekka-emojis-2')
    )
      loadEmojis();
  }, [isAuthenticated]);

  if (isLoading)
    return (
      <TransitionWrapper show={true}>
        <Loader fullscreen />
      </TransitionWrapper>
    );

  return (
    <Fragment>
      <header className='flex justify-between mb-2'>
        <h1 className='mb-1 sm:mb-0'>Engagements</h1>
        <button
          onClick={() => setShowCreateEngagementModal(true)}
          type='button'
          className='px-2 button button--blue'
        >
          Create Engagement
        </button>
      </header>

      <main>
        {engagements.results.length === 0 && (
          <Callout
            title='Haekka'
            message="It doesn't look like you have had the chance to create an Engagement yet. No worries! It only takes a few seconds. Click the Create button above &#9757;"
            permanent
            date={false}
          />
        )}
        <ListItems>
          {engagements.results.map((engagement) => (
            <EngagementListItem
              companyEngagement={engagement}
              key={engagement.id}
              page={currentPage}
            />
          ))}
        </ListItems>
        <Pagination
          pageSize={engagements.results.length}
          count={engagements.count}
          currentPage={currentPage}
          onPageChanged={(page) => setCurrentPage(page)}
        />
      </main>

      {showCreateEngagementModal && (
        <EngagementModal
          create
          show={showCreateEngagementModal}
          onClose={closeCreateEngagementModalHandler}
        />
      )}
    </Fragment>
  );
}
