import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import ReactTooltip from 'react-tooltip';
import { useQueryClient } from '@tanstack/react-query';

import {
  useTrainingsTagsData,
  useTraningsWithAssigmentsData,
} from '../hooks/data-hooks/trainings';
import { useAppContext } from '../libs/contextLib';
import { onError } from '../libs/errorLib';
import * as api from '../api';

import ListItems from '../components/ListItems';
import Loader from '../components/Loader';
import CreateTrainingModal from '../components/CreateTrainingModal';
import Callout from '../components/Callout';
import TransitionWrapper from '../components/Base/TransitionWrapper';
import TrainingTagButton from '../components/TrainingTag/TrainingTagButton';
import useDebounce from '../utils/custom-hooks/useDebounce';
import TrainingListItem from '../components/Trainings/TrainingListItem';
import Pagination from '../components/Pagination';

const pageSize = 10;

const buildNoTrainingsFoundMessage = ({ tags, searchQuery, isAdmin }) => {
  if (
    (isAdmin && (tags.length > 0 || !!searchQuery)) ||
    (!isAdmin && (tags.length > 0 || !!searchQuery))
  ) {
    return '👀 There are no trainings found associated with the selected filters.';
  } else if (isAdmin && !(tags.length > 0 || !!searchQuery)) {
    return "It doesn't look like you have had the chance to create a training yet. No worries! It only takes a few seconds. Click the Create Training button above &#9757;";
  } else if (!isAdmin && !(tags.length > 0 || !!searchQuery)) {
    return 'You have no training assigned yet.';
  }
};

export default function Trainings() {
  const { isAuthenticated } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  let params = queryString.parse(location.search);

  const [currentPage, setCurrentPage] = useState(1);
  const [reloadTrainings, setReloadTrainings] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [curriculums, setCurriculums] = useState([]);
  const [curriculumTypes, setCurriculumTypes] = useState([]);
  const [showCreateTrainingModal, setShowCreateTrainingModal] = useState(false);

  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const { data: trainingsData, isLoading: isLoadingTrainigns } =
    useTraningsWithAssigmentsData({
      companyID: isAuthenticated.company,
      selectedTags,
      title: debouncedSearchQuery,
      page: currentPage,
      pageSize,
    });

  const { data: trainingsTagsData, isLoading: isLoadingTrainignsTags } =
    useTrainingsTagsData({
      companyID: isAuthenticated.company,
      enabled: !!isAuthenticated.is_admin,
    });

  useEffect(() => {
    setShowCreateTrainingModal(
      params && params.create ? Boolean(params.create) : false
    );
  }, [params]);

  useEffect(() => {
    function loadCurriculums() {
      return api.allCurriculums({ auth: isAuthenticated });
    }

    function loadCurriculumTypes() {
      return api.curriculumTypes({
        auth: isAuthenticated,
        pageSize: 10000,
        includeTitles: false,
      });
    }

    ReactTooltip.rebuild();

    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const curriculumTypesResponse = await loadCurriculumTypes();
        const allCurriculums = await loadCurriculums();
        setCurriculumTypes(curriculumTypesResponse);
        const sortedCurriculums = allCurriculums.sort((a, b) => {
          return a.title.localeCompare(b.title, 'en', { sensitivity: 'base' });
        });
        setCurriculums(sortedCurriculums);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [reloadTrainings]);

  const onTrainingTagClick = (tagId) => {
    if (selectedTags.includes(tagId)) {
      setSelectedTags(selectedTags.filter((id) => id !== tagId));
    } else {
      setSelectedTags([...selectedTags, tagId]);
    }
  };

  if (
    isLoadingTrainigns ||
    (isAuthenticated.is_admin ? isLoadingTrainignsTags : false)
  ) {
    return (
      <TransitionWrapper show={true}>
        <Loader fullscreen />
      </TransitionWrapper>
    );
  }

  return (
    <>
      <header className='mb-2'>
        <div className='flex justify-between mb-1 sm:mb-2'>
          <h1>Trainings</h1>
          {isAuthenticated.is_admin && (
            <button
              onClick={() => navigate('/trainings?create=true')}
              className='px-2 button button--blue'
            >
              Create Training
            </button>
          )}
        </div>
        <div className='flex items-center pl-0.5 h-4 bg-white rounded-lg lg:flex-none border border-hka_gray-border w-full sm:w-30'>
          <span className='icon-search text-hka_gray mt-0.2'></span>
          <input
            className='h-3 text-base border-none max-h-3.8 focus:outline-none'
            type='text'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder='Search Trainings'
          />
        </div>
        {isAuthenticated.is_admin && (
          <div className='flex flex-row flex-wrap gap-0.5 pt-2'>
            {trainingsTagsData.results.map((tag) => (
              <TrainingTagButton
                key={tag.id}
                tag={tag}
                onClick={() => onTrainingTagClick(tag.id)}
                active={selectedTags.includes(tag.id)}
              />
            ))}
          </div>
        )}
      </header>
      <main>
        <ListItems
          name={isAuthenticated.is_admin ? null : 'Assigned Trainings'}
          htag='h3'
        >
          {trainingsData.results.map((training) => (
            <TrainingListItem key={training.id} training={training} />
          ))}
        </ListItems>

        {(trainingsData.results.length === 0 ||
          trainingsData.results.filter((t) => !!t.assigment_id).length ===
            0) && (
          <Callout
            title='Haekka'
            date={false}
            message={buildNoTrainingsFoundMessage({
              tags: selectedTags,
              searchQuery,
              isAdmin: isAuthenticated.is_admin,
            })}
          />
        )}

        <Pagination
          pageSize={pageSize}
          count={trainingsData.count}
          currentPage={currentPage}
          onPageChanged={(page) => setCurrentPage(page)}
        />

        <ReactTooltip place='bottom' effect='solid' />
      </main>

      {showCreateTrainingModal && isAuthenticated.is_admin && (
        <CreateTrainingModal
          showModal={showCreateTrainingModal && isAuthenticated.is_admin}
          onTrainingCreate={() => {
            setShowCreateTrainingModal(false);
            setReloadTrainings((v) => !v);
            queryClient.invalidateQueries(['trainings-with-assigments-list']);
          }}
          allCurriculums={curriculums}
          curriculumTypes={curriculumTypes.results}
          trainingTags={trainingsTagsData?.results}
          onClose={() => navigate('/trainings')}
        />
      )}
    </>
  );
}
