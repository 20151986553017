import axios from "axios";

export const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeaders = () => {
  const storedAuth = localStorage.getItem("auth");
  if (!storedAuth) return undefined;

  const auth = JSON.parse(storedAuth);
  const employeeSlackId = auth.slack_uid;
  const companySlackId = auth.company_details.slack_workspace;
  const google_token_id = localStorage.getItem("google_token_id");
  const loginToken = google_token_id || auth.login_token;

  return {
    "Slack-User-Id": employeeSlackId,
    "Slack-Cid": companySlackId,
    "Login-Token": loginToken,
    "Google-User-id": auth.google_id,
  };
};

const api = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
});

api.interceptors.request.use(
  (config) => {
    const authHeaders = getAuthHeaders();
    if (authHeaders) {
      config.headers = { ...config.headers, ...authHeaders };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
