import { useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { useGlobalStore } from '../../store';
import { useAppContext } from '../../libs/contextLib';
import {
  useConvertStreamPostToCustom,
  useDeleteCustomStreamPost,
  useDeleteStream,
  useDeleteStreamPost,
  useSubscribeToStream,
  useUpdateStreamSubscription,
} from '../../hooks/data-hooks/streams';
import AssignEmployeesContext from '../../store/assign-employeees-context';

import { clearParams } from '../../utils/commonUtils';

import PostNotFoundModal from './Posts/PostNotFoundModal';
import PostPreviewModal from './Posts/PostPreviewModal';
import PostModal from './Posts/PostModal';
import CustomPostConfirmModal from './Posts/CustomPostConfirmModal';
import StreamModal from './Streams/StreamModal';
import DeleteStreamModal from './Streams/DeleteStreamModal';
import DeletePostModal from './Posts/DeletePostModal';
import SubscribeToStreamModal from './Streams/SubscribeToStreamModal';
import DeleteCustomPostConfirmModal from './Posts/DeleteCustomPostConfirmModal';
import SubscribeEmployeesModal from './SubcribeEmployees/SubscribeEmployeesModal';
import ManageOwnersModal from './Streams/ManageOwnersModal';
import { useNavigate } from 'react-router-dom';

const StreamModalsContainer = () => {
  const { isAuthenticated } = useAppContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { modalOpened } = useContext(AssignEmployeesContext);

  const {
    previewPostModal,
    setPreviewPostModal,
    postModal,
    setPostModal,
    customPostConfirmModal,
    setCustomPostConfirmModal,
    streamModal,
    setStreamModal,
    deleteStreamModal,
    setDeleteStreamModal,
    deletePostModal,
    setDeletePostModal,
    subscribeToStreamModal,
    setSubscribeToStreamModal,
    deleteCustomPostConfirmModal,
    setDeleteCustomPostConfirmModal,
    postNotFoundModal,
    setPostNotFoundModal,
    manageOwnersModal,
    setManageOwnersModal,
  } = useGlobalStore((state) => state.streams);

  const { mutate: deleteStream } = useDeleteStream({
    currentPage: deleteStreamModal.currentPage,
    onSuccess: () => {
      toast.success(`${deleteStreamModal.stream.title} stream deleted`);
      setDeleteStreamModal({ show: false });
    },
  });

  const { mutate: deletePost } = useDeleteStreamPost({
    onSuccess: () => {
      toast.success(`Post Deleted`);
      queryClient.invalidateQueries('streams');
      setDeletePostModal({ show: false });
    },
  });

  const { mutate: convertToCustom } = useConvertStreamPostToCustom({
    onSuccess: (response) => {
      toast.success(
        `Post ${response.post.title} coverted to custom! Start editing it.`
      );
      setCustomPostConfirmModal({ show: false });
      queryClient.invalidateQueries('streams');
    },
  });

  const { mutate: subscribeToStream } = useSubscribeToStream({
    onSuccess: () => {
      queryClient.invalidateQueries([
        'streams',
        'posts',
        isAuthenticated.company,
        subscribeToStreamModal.stream.id,
      ]);
      queryClient.invalidateQueries([
        'company-stream',
        isAuthenticated.company,
        subscribeToStreamModal.stream.id,
      ]);
      toast.success(
        `You are now subscribed to ${subscribeToStreamModal.stream.title} stream!`
      );
      setSubscribeToStreamModal({ show: false });
    },
  });

  const { mutate: updateStreamSubscription } = useUpdateStreamSubscription({
    onSuccess: () => {
      queryClient.invalidateQueries(['streams']);
      queryClient.invalidateQueries([
        'company-stream',
        isAuthenticated.company,
        subscribeToStreamModal.stream.id,
      ]);
      toast.success(
        `You are now unsubscribed from ${subscribeToStreamModal.stream.title} stream!`
      );
      setSubscribeToStreamModal({ show: false });
    },
  });

  const { mutate: deleteCustomPost } = useDeleteCustomStreamPost({
    onSuccess: () => {
      toast.success(
        "Custom Post deleted! You're falling back to global post instead."
      );
      setDeleteCustomPostConfirmModal({ show: false });
      queryClient.invalidateQueries('streams');
    },
  });

  const subscribeToStreamHandler = () => {
    if (subscribeToStreamModal.mode === 'SUBSCRIBE') {
      subscribeToStream({
        companyID: isAuthenticated.company,
        streamID: subscribeToStreamModal.stream.id,
      });
    }
    if (subscribeToStreamModal.mode === 'UNSUBSCRIBE') {
      updateStreamSubscription({
        companyID: isAuthenticated.company,
        streamID: subscribeToStreamModal.stream.id,
        payload: { is_active: false },
      });
    }
  };

  const submitCustomPostConfirmModalHandler = () => {
    convertToCustom({
      companyID: isAuthenticated.company,
      postID: customPostConfirmModal.post.id,
      streamID: customPostConfirmModal.post.stream,
      payload: {},
      params: { convert_to_custom: true },
    });

    setCustomPostConfirmModal({ show: false });
    setPreviewPostModal({ show: false });
  };

  const submitCustomPostDeleteModalHandler = () => {
    deleteCustomPost({
      companyID: isAuthenticated.company,
      streamID: deleteCustomPostConfirmModal.post.stream,
      postID: deleteCustomPostConfirmModal.post.id,
    });
  };

  const closePostNotFoundHandler = () => {
    setPostNotFoundModal({ show: false });
    clearParams({ navigate });
  };

  return (
    <>
      {postNotFoundModal.show && (
        <PostNotFoundModal onClose={closePostNotFoundHandler} />
      )}

      {streamModal.show && (
        <StreamModal
          show={streamModal.show}
          mode={streamModal.mode}
          onClose={() => setStreamModal({ show: false })}
          currentPage={streamModal.currentPage}
          selectedStream={streamModal.stream}
        />
      )}

      {deleteStreamModal.show && (
        <DeleteStreamModal
          show={deleteStreamModal.show}
          streamTitle={deleteStreamModal.stream.title}
          onSubmit={() =>
            deleteStream({ streamID: deleteStreamModal.stream.id })
          }
          onClose={() => setDeleteStreamModal({ show: false })}
        />
      )}

      {subscribeToStreamModal.show && (
        <SubscribeToStreamModal
          show={subscribeToStreamModal.show}
          streamTitle={subscribeToStreamModal.stream.title}
          subscriptionMode={subscribeToStreamModal.mode}
          onSubmit={subscribeToStreamHandler}
          onClose={() => setSubscribeToStreamModal({ show: false })}
        />
      )}

      {postModal.show && (
        <PostModal
          modalMode={postModal.mode}
          selectedPost={postModal.post}
          stream={postModal.stream}
          onClose={() => setPostModal({ show: false })}
        />
      )}

      {deletePostModal.show && (
        <DeletePostModal
          onSubmit={() => deletePost({ postID: deletePostModal.postID })}
          onClose={() => setDeletePostModal({ show: false })}
        />
      )}

      {previewPostModal.show && (
        <PostPreviewModal
          previewPostModalData={previewPostModal}
          onClose={() => setPreviewPostModal({ show: false })}
          onEditPost={(post) => {
            setPostModal({
              show: true,
              mode: 'EDIT_POST',
              post,
              stream: post.stream,
            });
            setPreviewPostModal({ show: false });
          }}
          onConvertToCustomPostAttempt={(post) => {
            setCustomPostConfirmModal({ show: true, post });
          }}
        />
      )}

      {customPostConfirmModal.show && (
        <CustomPostConfirmModal
          onClose={() => {
            setCustomPostConfirmModal({ show: false });
          }}
          onSubmit={submitCustomPostConfirmModalHandler}
        />
      )}

      {deleteCustomPostConfirmModal.show && (
        <DeleteCustomPostConfirmModal
          onClose={() => setDeleteCustomPostConfirmModal({ show: false })}
          onSubmit={submitCustomPostDeleteModalHandler}
        />
      )}
      {manageOwnersModal.show && (
        <ManageOwnersModal
          onClose={() => setManageOwnersModal({ show: false })}
          stream={manageOwnersModal.stream}
        />
      )}

      {modalOpened && <SubscribeEmployeesModal />}
    </>
  );
};

export default StreamModalsContainer;
