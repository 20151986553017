import { useLocation } from "react-router-dom";
import CertificateDownloadProgressBar from "./CertificateDownloadProgressBar";

const LessonCompletedPage = ({
  curriculumDetails,
  employeeTraining,
  trainingComplete,
  generatingCertificate,
  requestCertificate,
  progress,
  showProgressBar,
  certificateError,
}) => {
  const { pathname } = useLocation();
  return (
    <section className="flex-grow order-2 col-span-6 mx-2 mb-2 lg:order-1 sm:mx-3 lg:col-span-10">
      <img
        src="https://haekka-product-assets.s3.us-west-2.amazonaws.com/Seal--large.png"
        alt="Training Complete"
        className="mb-1 rounded-xl shadow-light"
      />
      <div className="w-full p-2 mb-1 bg-white lg:mb-0 rounded-xl shadow-light">
        <div className="flex">
          <img
            alt="Lesson Completed"
            className="w-4 h-4 mr-1 rounded-md"
            src="https://haekka-author-icons.s3.us-west-2.amazonaws.com/HKA--UPDATED--Author_Haekka.png"
          />
          <div className="mb-0.5">
            <h4>Haekka</h4>
            <p className="font-basis-bold">Lesson Complete!</p>
          </div>
        </div>
        <p className="ml-5">
          Nice work! You completed{" "}
          <span className={curriculumDetails?.title ? "font-basis-bold" : ""}>
            {curriculumDetails?.title || "this training"}.
          </span>{" "}
          {employeeTraining?.completed_lessons && (
            <span>
              You finished this training in just{" "}
              {employeeTraining?.completed_lessons} lessons.
            </span>
          )}
        </p>
      </div>
      <button
        disabled={
          pathname.includes("catalog")
            ? false
            : !trainingComplete || generatingCertificate
        }
        onClick={requestCertificate}
        className="w-full mb-1 button button--blue lg:hidden"
      >
        {generatingCertificate ? "Generating" : "Request"} Certificate
      </button>
      {showProgressBar && (
        <div className="lg:hidden">
          <CertificateDownloadProgressBar
            progress={progress}
            certificateError={certificateError}
          />
        </div>
      )}
    </section>
  );
};

export default LessonCompletedPage;
