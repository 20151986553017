import { useEffect, useState } from 'react';
import Modal from '../../Modal/Modal';
import Note from '../../Note';
import useCopyToClipboard from '../../../utils/custom-hooks/useCopyToClipboard';
import { EXTERNAL_APP_REDIRECT_URL } from '../../../utils/constants';

const EnterDoceboConfigurationModal = ({
  subdomain,
  setSubdomain,
  onSubmit,
  clientID,
  setClientID,
  secret,
  setSecret,
  onClose,
}) => {
  const [isSubmitValid, setIsSubmitValid] = useState(false);

  const [copyToClipboardBackendURL, { success: backendURLcopied }] =
    useCopyToClipboard();

  useEffect(() => {
    setIsSubmitValid(
      subdomain?.length > 0 && clientID?.length > 0 && secret?.length > 0
    );
  }, [clientID?.length, secret?.length, subdomain?.length]);

  const submitHandler = () => {
    setIsSubmitValid(false);
    onSubmit();
  };

  return (
    <Modal
      show={true}
      title='Enter your Docebo platform parameters'
      onSubmit={submitHandler}
      onClose={onClose}
      submitButtonType='success'
      submitButtonText='Submit'
      submitValid={isSubmitValid}
      compact
    >
      <div className='w-full'>
        <Note type='info'>
          <p>
            In https://
            <span className='font-basis-bold'>haekka</span>.docebosaas.com the
            subdomain is <span className='font-basis-bold'>haekka</span>.
          </p>
        </Note>
        <input
          type='text'
          className='mt-2 outline-none'
          placeholder='Enter subdomain'
          value={subdomain}
          onChange={(e) => setSubdomain(e.target.value)}
          autoFocus
        />
        <input
          type='text'
          className='mt-1 outline-none'
          placeholder='Enter Client ID'
          value={clientID}
          onChange={(e) => setClientID(e.target.value)}
        />
        <input
          type='password'
          className='mt-1 outline-none'
          placeholder='Enter Client Secret'
          value={secret}
          onChange={(e) => setSecret(e.target.value)}
        />

        <div className='flex my-1'>
          <p className='text-sm'>
            For the <strong>Redirect URI</strong> field in your app
            configuration, you need to set a value. This value is provided by
            Haekka, please click the link to copy.{' '}
            <span
              className='inline-block mt-1 p-1 bg-blue-50 rounded-md text-blue-500 cursor-pointer'
              onClick={() =>
                copyToClipboardBackendURL(EXTERNAL_APP_REDIRECT_URL)
              }
            >
              {backendURLcopied ? 'Copied' : 'Click here to copy the URI'}
            </span>
          </p>
        </div>

        <span className='note text-sm text-gray-500'>
          Your sensitive information is secured in transit with SSL/TLS
          encryption, and further safeguarded through encryption prior to being
          stored in the database. This data is essential for constructing the
          OAuth URL and for refreshing the access token as required.
        </span>
      </div>
    </Modal>
  );
};

export default EnterDoceboConfigurationModal;
