import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addEngagementMessage } from "../../../api/engagementsApi";
import { onCreateMessageSuccess } from "../../../utils/engagementsUtils";

export const useCreateMessageData = ({ companyEngagement, onClose }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addEngagementMessage,
    onSuccess: (newMessageData) => {
      onClose();
      queryClient.setQueryData(["engagement", companyEngagement.id], (curr) =>
        onCreateMessageSuccess(curr, newMessageData)
      );
      queryClient.refetchQueries(["engagement", companyEngagement.id]);
    },
  });
};
