import React, { useState, useEffect, useContext, Fragment } from 'react';

import AssignEmployeesContext from '../store/assign-employeees-context';
import { useAppContext } from '../libs/contextLib';
import useFetchGroups from '../utils/custom-hooks/useFetchGroups';
import { useEmployeesData } from '../hooks/data-hooks/employees';

import useDebounce from '../utils/custom-hooks/useDebounce';

import ListItems from '../components/ListItems';
import ListItemUserManage from '../components/Employees/ListItemUserManage';
import Pagination from '../components/Pagination';
import Loader from '../components/Loader';
import TransitionWrapper from '../components/Base/TransitionWrapper';
import EmployeesFilters from '../components/Employees/EmployeesFilters';
import EmployeesModalsContainers from '../components/Employees/EmployeesModalsContainer';
import DropdownMenu from '../components/Base/DropdownMenu';

export default function Employees() {
  const { isAuthenticated } = useAppContext();

  const assignEmployeesContext = useContext(AssignEmployeesContext);
  const { groups, loadingGroupsCompleted } = assignEmployeesContext;

  const [searchString, setSearchString] = useState('');
  const [isActiveTraining, setIsActiveTraining] = useState(undefined);
  const [isActiveEmployees, setIsActiveEmployees] = useState(undefined);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [editGroupModal, setEditGroupModal] = useState(false);
  const [groupsLocal, setGroupsLocal] = useState([]);

  const debaouncedValue = useDebounce(searchString, 300);

  const [currentPage, setCurrentPage] = useState(1);
  const { data: employees, isLoading } = useEmployeesData({
    auth: isAuthenticated,
    page: currentPage,
    name: debaouncedValue,
    isActiveTraining,
    isActiveEmployees,
    selectedGroup,
  });

  const { response, setTrigger: loadGroups } = useFetchGroups({
    auth: isAuthenticated,
    params: { minimal_data: 'True' },
  });

  useEffect(() => {
    if (!loadingGroupsCompleted) loadGroups(true);
    setGroupsLocal(groups);

    if (groups.length === 0) {
      setGroupsLocal(response);
    }
  }, [groups, loadGroups, loadingGroupsCompleted, response]);

  const dropMenuActions = [
    {
      label: 'Create Group (in beta)',
      icon: 'icon-group',
      onClick: () => setCreateGroupModal(true),
      disabled: !isAuthenticated.is_admin,
    },
  ];

  return (
    <Fragment>
      <TransitionWrapper show={isLoading}>
        <Loader />
      </TransitionWrapper>

      <header className='flex flex-col'>
        <div className='flex flex-row mb-2'>
          <h1 className='flex-1'>Employees</h1>
          <div className='flex flex-row-reverse flex-1'>
            <DropdownMenu actions={dropMenuActions} />
          </div>
        </div>
        <EmployeesFilters
          setCurrentPage={setCurrentPage}
          searchString={searchString}
          setSearchString={setSearchString}
          setIsActiveTraining={setIsActiveTraining}
          setIsActiveEmployees={setIsActiveEmployees}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          setEditGroupModal={setEditGroupModal}
          groupsLocal={groupsLocal}
        />
      </header>

      {!isLoading && (
        <Fragment>
          <ListItems className='bg-white rounded-lg shadow-light'>
            {employees.results.map((user) => (
              <ListItemUserManage
                key={user.id}
                user={user}
                groups={groups.filter((group) =>
                  group?.members.includes(user.id)
                )}
                loadingGroupsCompleted={loadingGroupsCompleted}
              />
            ))}
          </ListItems>
          <Pagination
            count={employees.count}
            currentPage={currentPage}
            onPageChanged={(page) => setCurrentPage(page)}
          />
        </Fragment>
      )}

      <EmployeesModalsContainers
        createGroupModal={createGroupModal}
        setCreateGroupModal={setCreateGroupModal}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        editGroupModal={editGroupModal}
        setEditGroupModal={setEditGroupModal}
      />
    </Fragment>
  );
}
