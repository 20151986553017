import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../libs/contextLib';
import * as api from '../api';
import { onError } from '../libs/errorLib';
import Back from '../components/Back';
import EditTrainingModal from '../components/EditTrainingModal';
import Loader from '../components/Loader';
import ListCurriculumContent from '../components/Training/ListCurriculumContent/ListCurriculumContent';
import TrainingDropdownMenu from '../components/TrainingDropdownMenu';
import EditLessonModal from '../components/EditLessonModal/EditLessonModal';
import toast from 'react-hot-toast';
import Expander from '../components/Expander';
import GlobalCurriculumSettings from '../components/GlobalCurriculumSettings';
import AssignEmployeesModal from '../components/AssignEmployees/AssignEmployeesModal';
import AssignEmployeesContext, {
  OBJECT_TYPES,
} from '../store/assign-employeees-context';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import {
  expanderViewModes,
  getLoggedInUserDetails,
  modalModes,
} from '../utils/commonUtils';
import MaxLessonCountWarningModal from '../components/Training/MaxLessonCountWarningModal';
import TranistionWrapper from '../components/Base/TransitionWrapper';
import { useSelector } from 'react-redux';
import Tag from '../components/Tag';
import Modal from '../components/Modal/Modal';
import Note from '../components/Note';
import TrainingEvidenceModal from '../components/Training/Evidence/TrainingEvidenceModal';
import TrainingOptionIndicators from '../components/Training/TrainingOptionIndicators';
import AssessmentModal from '../components/Assessments/AssessmentModal';
import { useCurriculumContentData } from '../hooks/data-hooks/trainings/useCurriculumContentData';
import {
  CURRICULUM_ITEM_TYPES,
  getNextCurriculumItemPosition,
} from '../utils/training';
import { getSimpleEmployeeTrainings } from '../api/employeeTrainingsApi';
import ListTrainingUsers from '../components/Training/EmployeeList/ListTrainingUsers';
import { useTrainingStatistics } from '../hooks/data-hooks/trainings';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sendEmployeeAssignedTrainingNotification } from '../api/trainingsApi';

export default function Training() {
  const queryClient = useQueryClient();
  const assignEmployeesContext = useContext(AssignEmployeesContext);
  const {
    onImportUsers,
    setObject,
    setObjectType,
    setFetchedChannels,
    setSlackAutoEnroll,
    setGoogleAutoEnroll,
    modalOpened,
    setModalOpened,
    onModalClose,
    setSyncGroups,
    setSyncOktaGroupsIDs,
    setGoogleGroupsToSyncIDs,
  } = assignEmployeesContext;

  const { evidenceModalOpened } = useSelector(
    (state) => state.trainingEvidence
  );

  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const { isAuthenticated } = useAppContext();
  const [training, setTraining] = useState(null);
  const [isHaekkaOwner, setIsHaekkaOwner] = useState(true);
  const [isCustomContent, setIsCustomContent] = useState(null);
  const [isEditable, setIsEditable] = useState(null);
  const [activeLesson, setActiveLesson] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const [editTrainingModalShow, setEditTrainingModalShow] = useState(false);
  const [curriculumId, setCurriculumId] = useState(null);
  const [isCurriculumArchived, setIsCurriculumArchived] = useState(false);

  const [triggerGetTraining, setTriggerGetTraining] = useState(true);

  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [isCustomTraining, setIsCustomTraining] = useState(false);
  const [viewMode, setViewMode] = useState(
    localStorage.getItem('training_page_view_mode') ||
      expanderViewModes.TILE_MODE
  );

  const MAX_LESSON_COUNT = 100; // Slack restriction - 100 items in the dropdown input
  const [showMaxLessonCountWarning, setShowMaxLessonCountWarning] =
    useState(false);
  const [globalCurriculum, setGlobalCurriculum] = useState(null);
  const [
    isGlobalCurriculumSettingsModalOpened,
    setIsGlobalCurriculumSettingsModalOpened,
  ] = useState(false);

  const [renewedTrainingId, setRenewedTrainingId] = useState(null);

  const [alertEmployeesWarning, setAlertEmployeesWarning] = useState(false);

  const [assignedTrainings, setAssignedTrainings] = useState([]);

  const [addAssessmentModal, setAddAssessmentModal] = useState(undefined);
  const [editLessonModalOpened, setEditLessonOpened] = useState(undefined);
  const [scenarioType, setScenarioType] = useState(undefined);
  const [checkForAssignedTraining, setCheckForAssignedTraining] =
    useState(false);
  const [alertBtnDisabled, setAlertBtnDisabled] = useState(false);

  const { data: curriculumContent, isLoading: areLessonsLoading } =
    useCurriculumContentData({
      curriculumID: training?.curriculum_detail.id,
      enabled: !!training?.curriculum_detail.id && !addAssessmentModal,
    });

  const {
    data: trainingStatistics,
    error,
    isError,
  } = useTrainingStatistics({
    companyID: isAuthenticated.company,
    trainingID: id,
  });

  const { mutate: alertEmployees } = useMutation({
    mutationFn: sendEmployeeAssignedTrainingNotification,
    onSuccess: () => {
      setTimeout(() => {
        queryClient.refetchQueries([
          'training-statistics',
          isAuthenticated.company,
          id,
        ]);
        setAlertBtnDisabled(false);
      }, 1500);
    },
  });

  const trainingLessons = (curriculumContent ?? [])
    .filter((cc) => cc.type === CURRICULUM_ITEM_TYPES.LESSON)
    .map((l) => l.value);

  useEffect(() => {
    if (!editLessonModalOpened) {
      setTriggerGetTraining((p) => !p);
      setActiveLesson(null);
    }
  }, [editLessonModalOpened]);

  useEffect(() => {
    onImportUsers();
  }, [onImportUsers]);

  useEffect(() => {
    const loadEmployeeTrainingsAPI = () => {
      return getSimpleEmployeeTrainings({
        employeeID: isAuthenticated.id,
      });
    };
    const loadEmployeeTrainings = async () => {
      try {
        const employeeTrainings = await loadEmployeeTrainingsAPI();
        setAssignedTrainings(
          employeeTrainings.results.map((et) => et.company_training_id)
        );
      } catch (e) {
        onError(e);
      }
    };
    loadEmployeeTrainings();
  }, [isAuthenticated, checkForAssignedTraining]);

  useEffect(() => {
    function loadTraining() {
      return api.training({ auth: isAuthenticated, trainingId: id });
    }
    async function getEmployeeDetails() {
      return await getLoggedInUserDetails({
        auth: isAuthenticated,
      });
    }
    function getGlobalCurriculum(id) {
      return api.getCurriculum({
        auth: isAuthenticated,
        id,
      });
    }
    async function onLoad() {
      setIsLoading(true);

      try {
        const training = await loadTraining();
        if (!training.active) {
          setRedirect(true);
          return;
        }
        const employeeDetails = await getEmployeeDetails();
        setEmployeeDetails(employeeDetails);
        setCurriculumId(training.curriculum_detail.id);
        setIsHaekkaOwner(!training.curriculum_detail.company);
        setIsCustomContent(employeeDetails.company_details.custom_content);
        setIsEditable(training.curriculum_detail.is_editable);
        setTraining(training);
        if (
          training.renew_cycle === 'none' &&
          !training.curriculum_detail.company &&
          training.curriculum_detail.is_archived
        ) {
          setIsCurriculumArchived({
            replacement_curriculum_title:
              training.curriculum_detail.replacement_curriculum_title,
          });
        }
        setObject(training);
        setObjectType(OBJECT_TYPES.TRAINING);
        setFetchedChannels(training.channels);
        setSyncGroups(training.groups_sync);
        setSyncOktaGroupsIDs(training.okta_groups_sync);
        setGoogleGroupsToSyncIDs(training.google_groups_sync);
        setSlackAutoEnroll(training.auto_enroll);
        setGoogleAutoEnroll(training.auto_enroll_google);
        let curriculum = null;
        if (isAuthenticated.company_details.is_global_content_maker) {
          curriculum = await getGlobalCurriculum(training.curriculum_detail.id);
          setGlobalCurriculum(curriculum);
        }
        setScenarioType(training.curriculum_detail.action_id);

        const customTraining = Boolean(
          ((curriculum || false) && !training.curriculum_detail.company) ||
            ((employeeDetails.company_details.custom_content || false) &&
              training.curriculum_detail.company)
        );

        setIsCustomTraining(customTraining);

        setIsLoading(false);
      } catch (e) {
        setRedirect(true);
        if (e.message.endsWith('404'))
          toast.error('Training could not be found.');
        else onError(e);
      }
    }
    onLoad();
  }, [id, isAuthenticated, triggerGetTraining]);

  const lessonExpanderMenuItems = [
    {
      icon: 'icon-tile',
      value: expanderViewModes.TILE_MODE,
      label: 'Tile View',
      onClick: () => handleSetViewMode(expanderViewModes.TILE_MODE),
      active: expanderViewModes.TILE_MODE === viewMode,
    },
    {
      icon: 'icon-list',
      value: expanderViewModes.LIST_MODE,
      label: 'List View',
      onClick: () => handleSetViewMode(expanderViewModes.LIST_MODE),
      active: expanderViewModes.LIST_MODE === viewMode,
    },
  ];

  if (isCustomTraining) {
    lessonExpanderMenuItems.push(
      {
        icon: 'icon-add-lesson',
        label: 'Add Lesson',
        onClick: () => handleAddLessonClick(),
      },
      {
        icon: 'icon-quiz',
        label: 'Add Quiz',
        onClick: () => setAddAssessmentModal({ create: true }),
      }
    );
  }

  const completedPercentage = useMemo(() => {
    let percentage = 0;
    if (training && training.total_employees > 0) {
      percentage = Math.round(
        (training.employees_finished_count / training.total_employees) * 100
      );
    }
    return percentage;
  }, [training]);

  const handleSetViewMode = (viewMode) => {
    localStorage.setItem('training_page_view_mode', viewMode);
    setViewMode(viewMode);
  };

  const handleAddLessonClick = () => {
    setEditLessonOpened({ isOpened: true, mode: modalModes.ADD });
  };

  const alertEmployeesHandler = () => {
    alertEmployees({ trainingID: id });
    setAlertEmployeesWarning(false);
    setAlertBtnDisabled(true);
  };

  const migrateTrainingHandler = async () => {
    try {
      const { new_training_id } =
        await api.migrateTrainingWithArchivedCurriculum({
          auth: isAuthenticated,
          trainingID: training.id,
        });
      toast.success(
        "Great! We'll start to migrate this training right away and reassign your employees. This action can take a while depending on the number of employees assigned."
      );
      setIsCurriculumArchived(false);
      navigate(`/trainings/${new_training_id}`);
    } catch (error) {
      onError(error);
    }
  };

  const assignEmployeesModalClosedEventHandler = () => {
    // assigning employees is delayed to Celery in the backend
    setTriggerGetTraining((p) => !p);
    setTimeout(() => {
      setCheckForAssignedTraining((s) => !s);
    }, 1000);
  };

  const onDeleteTrainingSuccessHandler = () => {
    setTimeout(() => {
      queryClient.invalidateQueries(['trainings-with-assigments-list']);
    }, 1000);
  };

  const handleRedirectToEmployeeAttempt = () => {
    navigate(`/trainings/attempt/${training.id}`);
  };

  // Once we move fetching a training with react-query, this check should be performed on
  // the training object, and not on the call for /statistics
  if (isError && error?.response.status === 404) {
    navigate('/trainings');
    return null;
  }

  if (redirect) {
    navigate('/trainings');
    return null;
  }

  if (isLoading || !training || areLessonsLoading) {
    return (
      <TranistionWrapper show={true}>
        <Loader fullscreen />
      </TranistionWrapper>
    );
  }

  return (
    <div>
      <header className='mb-2'>
        <section className='flex items-center justify-between w-full mb-2'>
          <Back push='/trainings' caption='Trainings' />
          <div className='flex space-x-1'>
            <button
              // data-tip="View Training"
              type='button'
              className='button--view-training button button--gray bg-hka_gray-light'
              onClick={handleRedirectToEmployeeAttempt}
              disabled={!assignedTrainings.includes(training.id)}
            >
              <span
                className={
                  !assignedTrainings.includes(training.id)
                    ? 'icon-view--disabled'
                    : 'icon-view'
                }
                data-tip={
                  !assignedTrainings.includes(training.id)
                    ? 'Assign yourself to view this training'
                    : 'View Training'
                }
              ></span>
            </button>
            <TrainingDropdownMenu
              training={training}
              displayAddLessonButton={isCustomTraining}
              onSetEditTrainingModalShow={setEditTrainingModalShow}
              setIsGlobalCurriculumSettingsModalOpened={
                setIsGlobalCurriculumSettingsModalOpened
              }
              onHandleAddLesson={() => {
                if (training.lessons < MAX_LESSON_COUNT) {
                  setEditLessonOpened({ isOpened: true, mode: modalModes.ADD });
                } else {
                  setShowMaxLessonCountWarning(true);
                }
              }}
              isCustomContent={isCustomContent}
              isEditable={isEditable}
              setTraining={(training) => {
                setTraining(training);
                setIsHaekkaOwner(!training.curriculum_detail.company);
                setIsCustomContent(
                  employeeDetails?.company_details.custom_content
                );
                setIsEditable(training.curriculum_detail.is_editable);
                setCurriculumId(training.curriculum_detail.id);
              }}
              employeeDetails={employeeDetails}
              isHaekkaOwner={isHaekkaOwner}
              setTriggerGetTraining={setTriggerGetTraining}
              globalCurriculum={globalCurriculum}
              setModalOpened={setModalOpened}
              isCustomTraining={isCustomTraining}
            />
          </div>
        </section>
        <section className='flex flex-col sm:flex-row'>
          {/* Progress bar */}
          <div className='flex flex-col w-8 mb-1 mr-2 sm:w-10 sm:mt-1 lg:mr-4'>
            <CircularProgressbar
              value={completedPercentage}
              text={`${completedPercentage}%`}
              styles={buildStyles({
                textColor: '#878a9a',
                pathColor: '#F99A00',
                trailColor: '#EFF0F7',
              })}
              strokeWidth={10}
            />
            <p className='text-xs hidden mt-1.2 text-left text-hka_gray sm:flex'>
              Completion Rate
            </p>
          </div>

          {/* Training title, due date, tags */}
          <div className='flex-col flex-1 sm:ml-1'>
            <h1 className='mb-0.5 font-basis-bold text-md sm:text-xl w-full'>
              {training.curriculum_detail.title}
            </h1>
            <TrainingOptionIndicators training={training} />
            {training.tags.length > 0 && (
              <section className='flex flex-wrap mt-0.5'>
                {training.tags.map((tag, index) => {
                  return (
                    <Tag
                      tag={tag.name}
                      onRemove={() => {}}
                      noRemoveIcon
                      key={index}
                    />
                  );
                })}
              </section>
            )}
            {!trainingStatistics?.all_employees_alerted_in_slack && (
              <Note type='info'>
                <p>
                  Employees have been assigned, but not alerted of this training
                  yet. <br />
                  <span
                    className={`font-basis-bold ${
                      alertBtnDisabled
                        ? 'text-gray-500 cursor-default'
                        : 'text-blue-500 cursor-pointer'
                    }`}
                    onClick={() => {
                      if (alertBtnDisabled) return;
                      setAlertEmployeesWarning(true);
                    }}
                  >
                    Click here
                  </span>{' '}
                  to send your employees an assignment alert.
                </p>
              </Note>
            )}

            {trainingStatistics?.employees_without_slack > 0 && (
              <Note type='info'>
                <p>
                  {trainingStatistics.employees_without_slack} employees
                  assigned to this training do not exist in Slack yet. Once they
                  receive a Slack account they will be automatically notified of
                  their training assignment.
                </p>
              </Note>
            )}
          </div>
        </section>
      </header>
      {globalCurriculum && (
        <section>
          <p className='mb-1'>
            This training is associated with
            <a
              className='inline-flex items-center link ml-0.5'
              href={`${process.env.REACT_APP_API_URL}/admin/curriculum/curriculum/${globalCurriculum.id}/change/`}
              target='_blank'
              rel='noreferrer'
            >
              this global curriculum
            </a>
            .
          </p>
        </section>
      )}
      {isCurriculumArchived && (
        <div className='mb-2'>
          <Note type='danger'>
            <p>
              We have achieved this training's curriculum. We do this when we
              make large improvements to existing training. This training has
              been replaced by{' '}
              <b>{isCurriculumArchived.replacement_curriculum_title}</b>.
            </p>
            <p>
              To migrate this trainig to the new curriculum,{' '}
              <span
                className='text-red-500 cursor-pointer font-basis-bold'
                onClick={() =>
                  setIsCurriculumArchived((s) => ({
                    ...s,
                    showConfirmModal: true,
                  }))
                }
              >
                click here
              </span>
              .
            </p>
          </Note>
        </div>
      )}
      <Expander title='Lessons and Quizzes' menuItems={lessonExpanderMenuItems}>
        <ListCurriculumContent
          curriculumContent={curriculumContent}
          curriculumId={training.curriculum_detail.id}
          isCustomTraining={isCustomTraining}
          onSetActiveLesson={(lesson) => setActiveLesson(lesson)}
          setEditLessonOpened={setEditLessonOpened}
          setAddAssessmentModal={setAddAssessmentModal}
          viewMode={viewMode}
        />
      </Expander>
      <section className='mb-4'>
        <ListTrainingUsers
          training={training}
          setShowAssignEmployeesModal={() => setModalOpened(true)}
          trainingLessons={trainingLessons}
          curriculumContent={curriculumContent}
          assessmentOnlyTraining={trainingLessons.length === 0}
        />
      </section>
      {editTrainingModalShow && (
        <EditTrainingModal
          showModal={editTrainingModalShow}
          onModalHide={() => {
            if (renewedTrainingId) {
              navigate(`/trainings/${renewedTrainingId}`);
              setRenewedTrainingId(null);
            }
            setEditTrainingModalShow(false);
          }}
          setTriggerGetTraining={() => {
            setTriggerGetTraining((t) => !t);
          }}
          isHaekkaOwner={isHaekkaOwner}
          isCustomContent={isCustomContent}
          training={training}
          renewedTrainingId={renewedTrainingId}
          setRenewedTrainingId={setRenewedTrainingId}
          onDeleteSuccess={onDeleteTrainingSuccessHandler}
        />
      )}
      <AssignEmployeesModal
        showModal={modalOpened}
        assignEmployeesContext={assignEmployeesContext}
        setTriggerGetTraining={assignEmployeesModalClosedEventHandler}
        onClose={() => {
          setModalOpened(false);
          onModalClose();
          setSyncOktaGroupsIDs(undefined);
        }}
      />
      {editLessonModalOpened?.isOpened && (
        <EditLessonModal
          show={editLessonModalOpened.isOpened}
          curriculumId={curriculumId}
          mode={editLessonModalOpened.mode}
          lesson={activeLesson}
          nextLessonPosition={getNextCurriculumItemPosition(curriculumContent)}
          position={
            editLessonModalOpened.mode === modalModes.ADD
              ? getNextCurriculumItemPosition(curriculumContent)
              : activeLesson.position
          }
          isHaekkaOwner={isHaekkaOwner}
          setEditLessonOpened={setEditLessonOpened}
          trainingScenarioType={scenarioType}
        />
      )}
      <MaxLessonCountWarningModal
        showModal={showMaxLessonCountWarning}
        onClose={() => setShowMaxLessonCountWarning(false)}
      />
      {isGlobalCurriculumSettingsModalOpened && (
        <GlobalCurriculumSettings
          showModal={true}
          onModalHide={() => {
            setTriggerGetTraining((p) => !p);
            setIsGlobalCurriculumSettingsModalOpened(false);
          }}
          curriculumId={training.curriculum_detail.id}
          trainingId={training.id}
          setTriggerGetTraining={() => {
            setTriggerGetTraining((t) => !t);
          }}
        />
      )}
      {alertEmployeesWarning && (
        <Modal
          show={true}
          title='Alert Employees'
          onSubmit={alertEmployeesHandler}
          onClose={() => setAlertEmployeesWarning(false)}
          submitButtonType='primary'
          submitButtonText='Alert Reminder'
          compact
        >
          <Note type='info'>
            <p>
              This action will only alert employees who have{' '}
              <span className='font-basis-bold'>
                not yet finished this training
              </span>
              .
            </p>
          </Note>
        </Modal>
      )}
      {evidenceModalOpened && (
        <TrainingEvidenceModal curriculumID={training.curriculum_detail.id} />
      )}
      {isCurriculumArchived?.showConfirmModal && (
        <Modal
          show={true}
          title='Migrate Training'
          onSubmit={migrateTrainingHandler}
          onClose={() =>
            setIsCurriculumArchived((s) => ({ ...s, showConfirmModal: false }))
          }
          submitButtonType='primary'
          submitButtonText='Migrate Training'
          compact
        >
          <Note type='info'>
            <p>
              This action will update this training to use{' '}
              <b>{isCurriculumArchived.replacement_curriculum_title}</b> and
              reassing employees in the training immediately.
            </p>
          </Note>
        </Modal>
      )}
      {!!addAssessmentModal && (
        <AssessmentModal
          show={!!addAssessmentModal}
          onClose={() => setAddAssessmentModal(undefined)}
          create={!!addAssessmentModal.create}
          assessment={addAssessmentModal.assessment}
          curriculumContent={curriculumContent}
          curriculumID={curriculumId}
        />
      )}
    </div>
  );
}
