import React, { useState } from "react";
import ActionButton from "../../../ActionButton";
import { testPhishingForwardedEmailIMAPBinding } from "../../../../api/phishing";
import { toast } from "react-hot-toast";

export default function IMAPBindingRow({
  binding,
  imapHost,
  imapPort,
  setPhishingForwardedEmailIMAPBindings,
  setImapSettingsChanged,
  index,
}) {
  const [loading, setLoading] = useState(false);

  const onTestIMAPBindingButtonClick = async (binding) => {
    try {
      setLoading(true);

      await testPhishingForwardedEmailIMAPBinding(binding, imapHost, imapPort);
      toast.success(
        `The credentials for ${binding.email} within the IMAP settings are valid!`
      );
    } catch (error) {
      toast.error(
        `The credentials for ${binding.email} within the IMAP settings are invalid.`
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex flex-row items-center w-full py-0.5 border-b border-b-hka_gray-light">
      <p className="w-4/5 text-base truncate font-basis-bold text-hka_gray-dark">
        {binding.email}
      </p>
      <input
        placeholder="Enter Password"
        type="password"
        className="input--imap-password mr-4"
        value={binding.decrypted_password}
        onChange={(e) => {
          const value = e.target.value;
          setPhishingForwardedEmailIMAPBindings((prev) => {
            const updated = [...prev];
            updated[index].decrypted_password = value;
            return updated;
          });

          setImapSettingsChanged(true);
        }}
      />
      <ActionButton
        disabled={!binding.decrypted_password || loading}
        onClick={() => onTestIMAPBindingButtonClick(binding)}
      >
        {!loading ? "Test" : "Testing"}
      </ActionButton>
    </div>
  );
}
