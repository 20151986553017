import ReactPlayer from "react-player";
import Back from "../Back";
import Loader from "../Loader";

const VideoContentPlayer = ({ headerCaption, title, error, videoURL }) => {
  return (
    <div className="fixed left-0 right-0 flex flex-col p-2 overflow-auto border sm:p3 bg-hka_gray-ultraLight top-10 bottom-4 border-b-hka_gray-light border-t-hka_gray-light sm:flex-row">
      <div className="w-1/5">
        <Back push="/" caption="Home" />
      </div>
      <section className="w-full sm:w-3/5">
        <h1>{headerCaption}</h1>
        <h2 className="mb-2 text-orange">{title}</h2>
        {error && (
          <section className="w-full p-1 bg-red-400 rounded-lg">
            <p className="text-white">{error}</p>
          </section>
        )}
        {!error && !videoURL && <Loader />}
        {!error && videoURL && (
          <section className="w-full">
            <ReactPlayer
              url={videoURL}
              controls
              className="react-player"
              width="100%"
            />
          </section>
        )}
      </section>
      <div className="w-1/5"></div>
    </div>
  );
};

export default VideoContentPlayer;
