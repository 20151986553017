import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAppContext } from '../../../../libs/contextLib';
import { useTrainingsTagsData } from '../../../../hooks/data-hooks/trainings';

import Modal from '../../../Modal/Modal';
import Paginator from '../../../Paginator';
import EditTrainingTagModal from '../../../TrainingTag/EditTrainingTagModal';
import Callout from '../../../Callout';
import Tag from '../../../Tag';
import LoaderInline from '../../../LoaderInline';
import {
  deleteTrainingTag,
  updateTrainingTag,
} from '../../../../api/trainingsApi';

const page_size = 20;

const TrainingTagSettings = () => {
  const { isAuthenticated } = useAppContext();
  const queryClient = useQueryClient();

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showEditTagModal, setShowEditTagModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);

  const { data: tags, isLoading } = useTrainingsTagsData({
    companyID: isAuthenticated.company,
    page: currentPage,
    pageSize: page_size,
    enabled: isAuthenticated.is_admin,
  });

  const removeTagHandler = (index) => {
    setSelectedTag(tags.results[index]);
    setShowDeleteConfirmModal(true);
  };

  const selectTagHandler = (index) => {
    setSelectedTag(tags.results[index]);
    setShowEditTagModal(true);
  };

  const { mutate: updateTag } = useMutation({
    mutationFn: updateTrainingTag,
    onSuccess: (tag) => {
      setShowEditTagModal(false);
      toast.success(`Training Tag ${selectedTag.name} renamed to ${tag.name}`);
      setSelectedTag(undefined);
      queryClient.invalidateQueries(['trainings-tags']);
    },
  });

  const handleTrainingTagUpdate = (title) => {
    updateTag({
      companyID: isAuthenticated.company,
      tag_id: selectedTag.id,
      name: title,
    });
  };

  const { mutate: deleteTag } = useMutation({
    mutationFn: deleteTrainingTag,
    onSuccess: () => {
      setShowDeleteConfirmModal(false);
      toast.success(`Training Tag ${selectedTag.name} deleted`);
      setSelectedTag(undefined);
      queryClient.invalidateQueries(['trainings-tags']);
    },
  });

  const handleTagDeletion = () => {
    deleteTag({
      companyID: isAuthenticated.company,
      tag_id: selectedTag.id,
    });
  };

  if (isLoading) return <LoaderInline />;

  if (tags.results.length === 0) {
    return (
      <section className='p-2 mb-4 bg-white col-span-full shadow-light rounded-xl'>
        <Callout
          permanent
          date={false}
          title='No Training Tags'
          message='You have no training tags.'
        />
      </section>
    );
  }

  return (
    <section className='p-2 mb-4 bg-white col-span-full shadow-light rounded-xl'>
      <section>
        <h3 className='setting__title'>Training Tags</h3>
        <p className='mb-1 text-sm'>
          Click a training tag to select and edit it, or click{' '}
          <span className='icon-sm icon-close bg-hka_gray-light h-2.4 w-2.5 pr-0.1 rounded-sm inline-flex'></span>{' '}
          to delete it.
        </p>
      </section>

      <section>
        <div className='flex flex-wrap mb-1'>
          {tags.results.map((tag, index) => {
            return (
              <Tag
                tag={tag.name}
                key={tag.id}
                onRemove={() => removeTagHandler(index)}
                onSelect={() => selectTagHandler(index)}
                pointer
              />
            );
          })}
        </div>

        {tags.count > 20 && (
          <Paginator
            pageSize={20}
            count={tags.count}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </section>

      {selectedTag && (
        <EditTrainingTagModal
          showModal={showEditTagModal}
          onClose={() => setShowEditTagModal(false)}
          onSetSelectedTag={() => setSelectedTag(null)}
          tag={selectedTag}
          handleTrainingTagUpdate={(title) => handleTrainingTagUpdate(title)}
        />
      )}

      {selectedTag && (
        <Modal
          show={showDeleteConfirmModal}
          title={`Confirm '${selectedTag.name}' Training Tag deletion`}
          submitButtonText='Yes, delete Tag'
          submitButtonType='danger'
          onSubmit={handleTagDeletion}
          onClose={() => {
            setShowDeleteConfirmModal(false);
            setSelectedTag(null);
          }}
          compact
        >
          <p>
            This action will remove '{selectedTag.name}' Training Tag from any
            Training which use this tag.
          </p>
        </Modal>
      )}
    </section>
  );
};

export default TrainingTagSettings;
