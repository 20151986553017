import { useQuery } from '@tanstack/react-query';
import { getEmployeeTrainingAttempts } from '../../../api/trainingsApi';

export const useEmployeeTrainingAttemptsData = ({ trainingID, employeeID }) => {
  return useQuery({
    queryKey: ['employee-training-attempts', trainingID, employeeID],
    queryFn: () =>
      getEmployeeTrainingAttempts({
        trainingID,
        employeeID,
      }),
  });
};
