const WysiwygLinkPopup = ({ setMode, setShowPopup }) => {
  return (
    <div className="slack-link">
      <div className={"rdw-inline-wrapper"}>
        <div
          className={"rdw-option-wrapper"}
          onClick={() => {
            setMode("insert-new-link");
            setShowPopup(true);
          }}
        >
          <span className="icon-link"></span>
        </div>
      </div>
    </div>
  );
};

export default WysiwygLinkPopup;
