import React, { Fragment, useRef, useState } from 'react';

import { useAppContext } from '../../../libs/contextLib';
import { useStreamPostsData } from '../../../hooks/data-hooks/streams';

import Paginator from '../../Paginator';
import Post from './Post';
import WarningMessage from '../Streams/WarningMessage';
import LoaderInline from '../../LoaderInline';

const renderPosts = ({ postsData, reviewPosts, caption, ref }) => (
  <>
    {caption && postsData.results.length > 0 && (
      <h4 className='mb-1' ref={ref}>
        {caption}
      </h4>
    )}
    <div className='space-y-1'>
      {postsData.results.map((post) => (
        <Post
          key={post.id}
          post={{
            ...post.post,
            isSentForReview: post.is_sent_for_review,
            approvalStatus: post.approval_status,
            ack: post.ack,
            rate: post.rate,
            voted: post.voted,
            delivered: post.delivered,
          }}
          reviewPosts={reviewPosts}
        />
      ))}
    </div>
  </>
);

const renderWarning = ({
  isFreeStream,
  isAuthenticated,
  companySubscribed,
  sent,
  postsLength,
}) => (
  <WarningMessage
    isFreeStream={isFreeStream}
    hasPaidStreams={isAuthenticated.company_details.has_paid_streams}
    companySubscribed={companySubscribed}
    postsLength={postsLength}
    globalContentMaker={isAuthenticated.company_details.is_global_content_maker}
    textAlter={sent ? 'sent' : 'queued'}
  />
);

const PostContainer = ({
  sent,
  isFreeStream,
  companyStream,
  showWarning,
  stream,
}) => {
  const { isAuthenticated } = useAppContext();
  const [selectedPage, setSelectedPage] = useState(1);
  const ref = useRef();

  const { data: postsData, isLoading } = useStreamPostsData({
    comapnyID: isAuthenticated.company,
    streamID: stream.id,
    sent,
    page: selectedPage,
  });

  const caption = sent ? 'Sent Posts' : 'Posts in Queue';
  const renderPaginator = postsData?.count > postsData?.results.length;
  const companySubscribed = companyStream?.is_active;

  const scrollIntoViewHandler = () => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  if (isLoading && !!companyStream) {
    const preloadedPosts = sent
      ? companyStream.posts_sent
      : companyStream.posts_in_queue;

    if (preloadedPosts.data.length === 0) {
      return renderWarning({
        isFreeStream,
        isAuthenticated,
        companySubscribed,
        sent,
        postsLength: preloadedPosts.data.length,
      });
    }

    return renderPosts({
      postsData: { results: preloadedPosts.data },
      reviewPosts: companyStream.review_posts,
      caption,
      ref,
    });
  }

  if (isLoading && !companyStream) return <LoaderInline />;

  if (showWarning) {
    return renderWarning({
      isFreeStream,
      isAuthenticated,
      companySubscribed,
      sent,
      postsLength: postsData.results.length,
    });
  }

  return (
    <Fragment>
      {postsData.results.length > 0 && (
        <h4 className='mb-1' ref={ref}>
          {caption}
        </h4>
      )}

      {renderPosts({
        postsData,
        reviewPosts: companyStream?.review_posts,
        ref,
      })}

      {renderPaginator && (
        <div className='mt-1'>
          <Paginator
            pageSize={4}
            count={postsData.count}
            currentPage={selectedPage}
            onPageChange={(page) => {
              setSelectedPage(page);
              scrollIntoViewHandler();
            }}
          />
        </div>
      )}
    </Fragment>
  );
};

export default PostContainer;
