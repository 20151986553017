import { EditorState, RichUtils, SelectionState } from "draft-js";
import { useEffect, useState } from "react";
import { applyLinkToEditorState } from "../../utils/wysiwygEditorHelpers";
import validator from "validator";
import ReactTooltip from "react-tooltip";

const WysiwygEditorInnerModal = ({
  edit,
  setEdit,
  setShowPopup,
  text,
  setText,
  url,
  setUrl,
  editorState,
  setEditorState,
  mode,
  setMode,
}) => {
  const [isUrlValid, setIsUrlValid] = useState(true);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    if (mode === "insert-new-link") {
      const selectionState = editorState.getSelection();
      const anchorKey = selectionState.getAnchorKey();
      const currentContent = editorState.getCurrentContent();
      const currentContentBlock = currentContent.getBlockForKey(anchorKey);
      const start = selectionState.getStartOffset();
      const end = selectionState.getEndOffset();
      const selectedText = currentContentBlock.getText().slice(start, end);
      setText(selectedText);
    }
  }, [editorState]);

  const normalizeSelection = (editorState) => {
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const offset = selectionState.getStartOffset();
    const newSelection = new SelectionState({
      anchorKey: anchorKey,
      anchorOffset: text.length + offset,
      focusKey: anchorKey,
      focusOffset: text.length + offset,
    });
    return EditorState.forceSelection(editorState, newSelection);
  };

  const header = (
    <div className="flex items-center w-full h-2.5 border-b border-hka_gray-light relative z-10">
      <div className="flex-auto -mt-0.4">
        <h3 className="font-basis-bold">
          {mode === "insert-new-link"
            ? "Insert new URL"
            : edit
            ? "Edit URL"
            : "URL"}
        </h3>
      </div>
      <div className="ml-auto -mt-0.5 -mr-1.5">
        <button
          onClick={(e) => {
            e.stopPropagation();
            setShowPopup(false);
            setEdit(false);
            setEditorState(normalizeSelection(editorState));
            setText("");
            setUrl("");
            setMode("");
          }}
          data-tip="Close"
        >
          <span className="icon-close"></span>
        </button>
      </div>
    </div>
  );

  const onLinkInputKeyDown = (e) => {
    if (!isUrlValid) return;
    if (e.which === 13) {
      e.preventDefault();
      setEditorState(applyLinkToEditorState(editorState, text, url));
      setShowPopup(false);
      setEdit(false);
    }
  };

  const removeLink = (e) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      let nextEditorState = RichUtils.toggleLink(editorState, selection, null);
      // create new selection - to move cursor to the end of previos selection
      const selectionState = editorState.getSelection();
      const anchorKey = selectionState.getAnchorKey();
      const offset = selectionState.getStartOffset();
      const newSelection = new SelectionState({
        anchorKey: anchorKey,
        anchorOffset: text.length + offset,
        focusKey: anchorKey,
        focusOffset: text.length + offset,
      });
      nextEditorState = EditorState.forceSelection(
        nextEditorState,
        newSelection
      );
      return nextEditorState;
    }
  };

  return (
    <>
      <div className="absolute z-20 w-full h-full -mt-1 rounded-lg top-1 ">
        {edit || mode === "insert-new-link" ? (
          <div className="flex flex-col items-end justify-end w-full h-full m-auto sm:justify-center sm:items-center sm:w-3/4">
            <>
              <div className="relative flex items-center w-full rounded-lg sm:shadow-smooth bg-hka_gray-dark">
                <button
                  className="p-0 button--close-link-decorator"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowPopup(false);
                    setEdit(false);
                    setEditorState(normalizeSelection(editorState));
                    setText("");
                    setUrl("");
                    setMode("");
                  }}
                >
                  <span className="text-white icon-close"></span>
                </button>
                <div className="flex items-center flex-1">
                  <input
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    value={text}
                    onKeyDown={onLinkInputKeyDown}
                    className="w-1/2 text-base leading-4 tracking-wide outline-none input--link-text input--dark"
                    placeholder="Enter Link Text"
                    type="text"
                  />
                  <span className="h-2 vr w-0.1 bg-hka_gray"></span>
                  <input
                    onChange={(e) => {
                      setUrl(e.target.value);
                      setIsUrlValid(
                        e.target.value === ""
                          ? true
                          : validator.isURL(e.target.value)
                      );
                    }}
                    value={url}
                    onKeyDown={onLinkInputKeyDown}
                    className="w-1/2 pl-1 text-base leading-4 tracking-wide outline-none input--url input--dark"
                    type="text"
                    placeholder="Enter Link URL"
                  />
                </div>
                <div className="flex justify-end mr-0.5 sm:w-8">
                  <button
                    className="relative h-3 px-1 text-sm button button--blue text-hka_blue button--add-link font-basis-bold"
                    onClick={() => {
                      if (url) {
                        setEditorState(
                          applyLinkToEditorState(editorState, text, url)
                        );
                      }
                      setShowPopup(false);
                      setEdit(false);
                      setUrl("");
                      setText("");
                    }}
                    onKeyDown={onLinkInputKeyDown}
                    disabled={!isUrlValid}
                    data-tip="Add Link"
                  >
                    Add Link
                  </button>
                </div>
                {!isUrlValid && (
                  <span className="absolute self-start text-xs text-hka_red top-full">
                    Please enter a valid URL
                  </span>
                )}
              </div>
            </>
          </div>
        ) : (
          <div className="flex items-end justify-center justify-end w-full h-full sm:items-center">
            <>
              <div className="flex items-center w-full rounded-lg sm:w-3/4 shadow-smooth flex-nowrap bg-hka_gray-dark">
                <button
                  className="p-0 button--close-link-decorator"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowPopup(false);
                    setEdit(false);
                    setEditorState(normalizeSelection(editorState));
                    setText("");
                    setUrl("");
                    setMode("");
                  }}
                >
                  <span className="text-white icon-close"></span>
                </button>
                <p className="flex-1 pl-1 truncate font-basis-medium">{text}</p>
                <span className="h-2 vr w-0.1 bg-gray-600"></span>
                <p className="flex-1 pl-1 truncate">{url}</p>
                <div className="flex">
                  <button
                    className="relative h-4 p-0 transition-all transform hover:text-white icon-cog text-hka_gray"
                    onClick={() => setEdit(true)}
                    data-tip="Edit Link"
                  >
                    {/* <span className="transition-all transform icon-cog hover:text-white"></span> */}
                  </button>
                  <button
                    className="relative h-4 p-0 transition-all transform hover:text-white icon-trash text-hka_gray"
                    onClick={(e) => {
                      setEditorState(removeLink(e));
                      setShowPopup(false);
                      setEdit(false);
                    }}
                    data-tip="Delete Link"
                  >
                    {/* <span className="icon-trash text-inherit"></span> */}
                  </button>
                </div>
              </div>
            </>
          </div>
        )}
      </div>
      {/* <div className="absolute z-10 w-full h-full -mt-1 bg-gray-500 rounded-lg opacity-75 top-1"></div> */}
    </>
  );
};

export default WysiwygEditorInnerModal;
