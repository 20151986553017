import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteAssessmentAnswer } from '../../../api/assessmentApi';

export const useDeleteAssessmentAnswer = ({ curriculumID }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteAssessmentAnswer,
    onSuccess: () =>
      queryClient.invalidateQueries(['curriculum-content', curriculumID]),
  });
};
