import { useEffect, useState } from "react";
import Select from "react-select";
import SelectAction from "./SelectAction";
import SelectFrequency from "./SelectFrequency";
import { useSelector, useDispatch } from "react-redux";
import { workflowsActions } from "../../../store/workflowsSlice";

export const customStyles = {
  control: (base, state) => {
    return {
      ...base,
      border: "none",
      boxShadow: `0 0 0 1px ${
        state.selectProps.error ? "#f2181f" : base.borderColor
      }`,
    };
  },
  singleValue: (base, state) => {
    return {
      ...base,
      color: `${state.selectProps.error ? "#f2181f" : base.borderColor}`,
    };
  },
  menuList: (base, state) => {
    return {
      ...base,
      color: "#FFF",
    };
  },
};

const CreateEvent = ({ customResponseES, setCustmResponseES }) => {
  const dispatch = useDispatch();
  const {
    selectedSource,
    selectedEvent,
    selectedRiskLevel,
    events,
    selectedAction,
    selectedTraining,
    riskLevels,
  } = useSelector((state) => state.workflows);

  const selectEventHandler = (event) => {
    dispatch(workflowsActions.selectEvent({ event }));
  };

  const selectRiskLevelHandler = (riskLevel) => {
    dispatch(workflowsActions.selectRiskLevel({ riskLevel }));
  };

  const [enabled, setEnabled] = useState(!!selectedSource);

  useEffect(() => {
    setEnabled(!!selectedSource);
  }, [selectedSource]);

  let showSelectFrequency = false;
  if (selectedAction?.value !== "assign_training" && selectedAction) {
    showSelectFrequency = true;
  }

  const eventsOptions = events?.map((ev) => {
    return {
      value: ev.id,
      label: ev.description,
    };
  });
  return (
    <div
      className={`flex flex-col w-full py-3 border-b border-hka_gray-light ${
        enabled ? "" : "opacity-40"
      }`}
    >
      <div className="w-full mb-2">
        <div className="flex items-center">
          <span
            className={`${
              enabled ? "workflow-step" : "workflow-step-disabled"
            }`}
          >
            2
          </span>
          <div>
            <h4 className="text-base2 text-hka_gray-dark font-basis-medium">
              Configure an Event
            </h4>
            <p className="text-sm">
              The <span className="font-basis-bold">event</span> is{" "}
              <span className="italic">what</span> Haekka listens for.
            </p>
          </div>
        </div>
      </div>
      <div className="p-3 border rounded-lg border-hka_gray-light bg-hka_gray-ultraLight">
        <p className="mb-1 font-basis-medium text-base2 text-hka_gray-dark">
          When an employee:
        </p>
        <div>
          {/* <p className="mb-0.5 text-hka_gray-dark">Event</p> */}
          <div className="border-hka_gray-border sm:flex-row">
            <div className={`${selectedEvent ? "mb-2" : ""}`}>
              <Select
                onChange={selectEventHandler}
                value={selectedEvent}
                placeholder="Select an Event"
                isMulti={false}
                options={eventsOptions}
                classNamePrefix="select"
                styles={customStyles}
                maxMenuHeight={140}
                className="w-full"
                isDisabled={!enabled}
                unstyled
              />
            </div>
            {selectedEvent && (
              <div className="pl-2 border-l-4">
                <p className="mb-0.5 text-hka_gray-dark">Risk</p>
                <Select
                  onChange={selectRiskLevelHandler}
                  value={selectedRiskLevel}
                  placeholder="Select Risk Level"
                  isMulti={false}
                  options={riskLevels}
                  classNamePrefix="select"
                  styles={customStyles}
                  maxMenuHeight={170}
                  className="w-full mb-1 sm:w-3/4"
                  unstyled
                />
                <p className="w-full text-sm sm:w-3/4">
                  How is risk calculated and what does it mean? See{" "}
                  <a
                    href="https://www.haekka.com"
                    target="_blank"
                    rel="noreferrer"
                    className="link font-basis-bold"
                  >
                    this guide
                  </a>{" "}
                  on how to determine risk.
                </p>
              </div>
            )}
          </div>
        </div>
        {selectedEvent && (
          <SelectAction
            customResponseES={customResponseES}
            setCustmResponseES={setCustmResponseES}
          />
        )}
        {showSelectFrequency && <SelectFrequency />}
      </div>
    </div>
  );
};

export default CreateEvent;
