import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  getPhishingTemplateCategories,
  getPhishingTemplates,
} from "../../../../api";
import { onError } from "../../../../libs/errorLib";
import Modal from "../../../Modal/Modal";
import LoaderInline from "../../../LoaderInline";
import TemplateCard from "./TemplateCard";
import useDebounce from "../../../../utils/custom-hooks/useDebounce";
import Select from "react-select";
import Pagination from "../../../Pagination";

export default function AddTemplateModal({
  auth,
  campaign,
  showModal,
  onSubmit,
  onClose,
}) {
  const PAGE_SIZE = 9;

  const [isLoading, setIsLoading] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [templatesResponse, setTemplatesResponse] = useState(null);
  const [templateCategories, setTemplateCategories] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState(null);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState(null);
  const [page, setPage] = useState(1);

  const debouncedNameFilter = useDebounce(nameFilter, 300);

  const nameFilterInputElementRef = useRef(null);

  useEffect(() => {
    const onLoad = async () => {
      try {
        const templateCategories = await getPhishingTemplateCategories({
          auth,
        });
        setTemplateCategories(
          templateCategories.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );
      } catch (error) {
        onError(error);
      }
    };

    onLoad();
  }, [auth]);

  useEffect(() => {
    setPage(1);
  }, [debouncedNameFilter, selectedCategoryFilter]);

  useEffect(() => {
    const onTemplateLoad = async () => {
      try {
        setIsLoading(true);

        const templatesResponse = await getPhishingTemplates({
          auth,
          page,
          nameFilter: debouncedNameFilter,
          categoryFilter: selectedCategoryFilter?.value,
          pageSize: PAGE_SIZE,
        });

        setTemplatesResponse(templatesResponse);

        if (!selectedTemplates) {
          const alreadySelectedTemplates = [...campaign.templates];

          setSelectedTemplates(alreadySelectedTemplates);
        }
      } catch (error) {
        if (error?.response?.data?.detail !== "Invalid page.") {
          onError(error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    onTemplateLoad();
  }, [auth, page, debouncedNameFilter, selectedCategoryFilter, campaign]);

  useEffect(() => {
    if (!isLoading) {
      nameFilterInputElementRef?.current?.focus();
    }
  }, [isLoading]);

  const handleOnTemplateSelect = (template, selected) => {
    if (selected) {
      setSelectedTemplates((v) => v.filter((t) => t.id !== template.id));
    } else {
      if (campaign.send_option === "once_immediately") {
        setSelectedTemplates([template]);
      } else {
        setSelectedTemplates((v) => [...v, template]);
      }
    }
  };

  return (
    <Modal
      expandable
      show={showModal}
      value={selectedCategoryFilter}
      onSubmit={() => onSubmit(selectedTemplates)}
      onClose={() => {
        setSelectedTemplates([...campaign.templates]);
        onClose();
      }}
      title="Select Phishing Template(s)"
      submitButtonText="Update Template Selections"
    >
      {isLoading ? (
        <LoaderInline />
      ) : (
        <div>
          {templatesResponse && (
            <Fragment>
              <div className="flex flex-row space-x-1">
                <input
                  autoFocus
                  ref={nameFilterInputElementRef}
                  type="text"
                  onChange={({ target }) => setNameFilter(target.value)}
                  placeholder="Search Templates"
                  value={nameFilter}
                  className="input focus:outline-none"
                  maxLength={255}
                />

                <div className="flex-1 lg:flex-none">
                  <Select
                    unstyled
                    isClearable={true}
                    value={selectedCategoryFilter}
                    placeholder={"Filter By Category"}
                    options={templateCategories}
                    className="flex-1 w-full h-4 text-hka_gray min-w-25r select--employee-filter"
                    maxMenuHeight="20.8rem"
                    classNamePrefix="select"
                    isSearchable={false}
                    onChange={(value) => setSelectedCategoryFilter(value)}
                  />
                </div>
              </div>
              <div
                className={`mt-2 mb-1 ${
                  templatesResponse.count > 0
                    ? "grid grid-cols-2 sm:grid-cols-3 gap-1"
                    : ""
                }`}
              >
                {templatesResponse.count > 0 ? (
                  templatesResponse.results.map((template, i) => (
                    <TemplateCard
                      key={i}
                      template={template}
                      onSelect={handleOnTemplateSelect}
                      selected={
                        !!selectedTemplates.find((t) => t.id === template.id)
                      }
                    />
                  ))
                ) : (
                  <p>
                    <b>0</b> templates match your search criteria.
                  </p>
                )}
              </div>
              <p>{selectedTemplates?.length || 0} templates selected.</p>
              <Pagination
                count={templatesResponse.count}
                currentPage={page}
                onPageChanged={(page) => setPage(page)}
                pageSize={PAGE_SIZE}
              />
            </Fragment>
          )}
        </div>
      )}
    </Modal>
  );
}
