import Modal from '../../Modal/Modal';

const DeleteCustomPostConfirmModal = ({ onClose, onSubmit }) => {
  return (
    <Modal
      show={true}
      title='Delete Custom Post?'
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonText='Delete Post'
    >
      <p>
        You are going to delete this post. Please note that this is a custom
        post owned by your company. If you proceed, this post will be deleted
        and the global post created by Haekka will be set instead.
      </p>
      <p>Do you want to proceed?</p>
    </Modal>
  );
};

export default DeleteCustomPostConfirmModal;
