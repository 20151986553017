import React from 'react';
import Modal from '../Modal/Modal';

export default function RemoveAnswerExplanationConfirmationModal({
  showModal,
  onSubmit,
  onClose,
}) {
  return (
    <Modal
      show={showModal}
      title='Delete Answer Explanation'
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonText='Delete Answer Explanation'
      submitButtonType='danger'
      compact
    >
      <p>Are you sure you want to delete this explanation?</p>
    </Modal>
  );
}
