import { useLocation } from "react-router-dom";

const LessonHeader = ({ setRedirect, isAuthenticated, trainingId, title }) => {
  const { pathname } = useLocation();
  return (
    <header className="relative flex items-start justify-between p-2 pb-1 truncate lg:pb-2 sm:px-3 border-hka_gray-light">
      <section className="flex flex-col items-start w-full lg:flex-row lg:items-center">
        <button
          onClick={() => {
            setRedirect(
              pathname.includes("catalog") ? "/catalog" : "/trainings"
            );
          }}
          className="pl-1.5 lg:p-0 pr-2 mb-1 lg:mb-0 mr-2 text-base text-hka_gray bg-hka_gray-light hover:bg-gray-300"
        >
          <span className="icon-arrow left"></span>
          <span className="flex lg:hidden">Back</span>
        </button>
        <div className="flex items-center w-full truncate">
          <h4 className="mr-1 truncate text-base2 font-basis-medium">{title}</h4>
          {pathname.includes("catalog") && (
            <p className="px-0.5 py-0.2 text-sm inline-flex font-basis-bold text-white rounded bg-orange">
              Demo Training
            </p>
          )}
        </div>
      </section>
      <section className="absolute flex right-2 sm:right-3">
        {isAuthenticated.is_admin && !pathname.includes("catalog") && (
          <button
            data-tip="Training Settings"
            onClick={() => {
              setRedirect(`/trainings/${trainingId}`);
            }}
            className="relative w-4 h-4 px-0 rounded-lg button hover:bg-hka_gray-light"
          >
            <span className="icon-cog"></span>
          </button>
        )}

        <button
          data-tip="Close Training"
          onClick={() => {
            setRedirect(
              pathname.includes("catalog") ? "/catalog" : "/trainings"
            );
          }}
          className="relative w-4 h-4 px-0 rounded-lg button hover:bg-hka_gray-light"
        >
          <span className="icon-close"></span>
        </button>
      </section>
    </header>
  );
};

export default LessonHeader;
