import React, { Fragment } from "react";
import {
  DataCell,
  EmployeeCell,
  HeaderCell,
} from "./AssignedEmployeesTableCells";

export default function TableBody({
  columns,
  rows,
  sortOption,
  setSelectedEmployeeForRemoval,
}) {
  return (
    <div className="overflow-x-auto bg-white rounded-lg sm:w-auto shadow-light w-46">
      <Fragment>
        <div className="flex table__header">
          <HeaderCell columnInfo={{ label: "Employee" }} sticky={true} />
          {columns.map((column, i) => (
            <HeaderCell key={i} columnInfo={column} />
          ))}
        </div>
        <div className="table__body">
          {rows.map((row, i) => (
            <div className="table__row" key={i}>
              {row.map((cell, j) =>
                j === 0 ? (
                  <EmployeeCell
                    key={j}
                    employee={cell}
                    setSelectedEmployeeForRemoval={
                      setSelectedEmployeeForRemoval
                    }
                  />
                ) : (
                  <div key={j} className="table__row">
                    <DataCell action={cell} sortOption={sortOption} />
                  </div>
                )
              )}
            </div>
          ))}
        </div>
      </Fragment>
    </div>
  );
}
