import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../libs/contextLib';

export default function AuthenticatedRoute({
  component,
  adminsOnly,
  allowManagers,
}) {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();

  let permitted = false;

  if (adminsOnly) {
    permitted = isAuthenticated?.is_admin;
  } else if (allowManagers) {
    permitted = isAuthenticated?.is_admin || isAuthenticated?.is_manager;
  } else {
    permitted = !!isAuthenticated;
  }

  const redirectUri =
    adminsOnly || allowManagers ? '/' : `/login?redirect=${pathname}${search}`;

  return permitted ? component : <Navigate to={redirectUri} replace />;
}
