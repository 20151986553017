import ProgressBar from "@ramonak/react-progress-bar";
import { useDispatch } from "react-redux";
import { videoUploadActions } from "../../store/video-upload";
import { getUploadRequestReference } from "../../utils/video-upload-utils";

const UploadProgress = ({ upload }) => {
  const dispatch = useDispatch();

  const { id, parent, type, title } = upload;

  const cancelHandler = () => {
    const uploadRequestReference = getUploadRequestReference(upload.randomId);
    uploadRequestReference.source.cancel("Upload canceled by user.");
    dispatch(
      videoUploadActions.updateUpload({
        upload: {
          success: false,
          progress: 100,
          title,
          etc: 0,
          message: "Upload canceled by user.",
          type,
          id,
          parent,
          source: undefined,
          completed: true,
          randomId: upload.randomId,
          mapped: upload.mapped,
          position: upload.position,
        },
      })
    );
  };

  return (
    <div className="">
      <div className="flex justify-between mb-1">
        <p className="w-2/3 truncate">
          <span className="font-basis-bold">File:</span> {upload.title}
        </p>
        {!!upload.etc && (
          <p>
            <span className="font-basis-bold">ETC:</span> {upload.etc}
          </p>
        )}
      </div>
      <div className="flex items-center">
        <div className="w-full">
          <ProgressBar
            completed={upload.progress}
            isLabelVisible={false}
            width={"100%"}
            height={"0.5rem"}
            borderRadius={"1rem"}
            baseBgColor={"#EFF0F7"}
            bgColor={upload.success ? "#F99A00" : "#f91e00"}
          />
        </div>
        {!upload.completed && !upload.message.length > 0 && (
          <span
            className="icon-close text-hka_gray cursor-pointer ml-auto -mt-0.8 -mr-0.5"
            onClick={cancelHandler}
            data-tip={`Cancel upload`}
          ></span>
        )}
      </div>
      {upload.message && (
        <p
          className={`text-sm font-basis-medium mt-0.5 ${
            upload.success ? "text-hka_green" : "text-orange-dark"
          }`}
        >
          {upload.message}
        </p>
      )}
    </div>
  );
};

export default UploadProgress;
