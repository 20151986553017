import { SLACK_LOGO } from "../../utils/constants";
import AssignedEmployeesCardSecton from "./AssignedEmployeesCardSecton";
import { Icon } from "./WorkflowTile";
import { useSelector } from "react-redux";

const WorkflowRow = ({
  workflow,
  workflowActionTitle,
  editWorflowHandler,
  onDelete,
  assignedEmployeesData,
}) => {
  const { events } = useSelector((state) => state.workflows);

  let source_icon;
  let destination_icon;
  if (workflow.event_source.startsWith("Slack")) {
    source_icon = { icon: SLACK_LOGO, alt: "Slack icon" };
  }
  if (workflow.event_destination.startsWith("Slack")) {
    destination_icon = { icon: SLACK_LOGO, alt: "Slack icon" };
  }

  const event = events.find((ev) => ev.name === workflow.event);

  return (
    <div className="z-0 flex justify-between p-3 mt-1 overflow-hidden bg-white rounded-lg hover:z-10 shadow-light h-7">
      <div className="flex w-full">
        <div className="flex items-center justify-between w-3/12 mr-1">
          <div className="flex">
            <Icon src={source_icon.icon} alt={source_icon.alt} size={25} />{" "}
            <span className="icon-arrow right mx-0.5"></span>{" "}
            <Icon
              src={destination_icon.icon}
              alt={destination_icon.alt}
              size={25}
            />
          </div>
        </div>
        <div className="w-5/12 h-7 -mt-1.3 hidden sm:block">
          <p>
            <span className="font-basis-bold">Event:</span> {event?.description}
          </p>
          <p>
            <span className="font-basis-bold">Action:</span>{" "}
            {workflowActionTitle}
          </p>
        </div>
        <div className="w-4/12 -mt-1 hidden sm:block">
          <AssignedEmployeesCardSecton
            assignedEmployeesData={assignedEmployeesData}
          />
        </div>
      </div>
      <div>
        <div className="flex -mr-1 space-x-0.5 -mt-1">
          <i
            className="flex items-center justify-center transition-all rounded-lg cursor-pointer icon-cog text-hka_gray hover:bg-hka_gray-light"
            onClick={() => editWorflowHandler(workflow.id)}
          ></i>
          <i
            className="flex items-center justify-center transition-all rounded-lg cursor-pointer icon-trash text-hka_gray hover:bg-hka_gray-light"
            onClick={() => onDelete(workflow.id)}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default WorkflowRow;
