import Modal from "../../Modal/Modal";
import React, { Fragment } from "react";
import LoaderInline from "../../LoaderInline";

export default function DeletePhishingCampaignModal({
  showModal,
  campaign,
  onDeletePhishingCampaign,
  onClose,
  isSubmitting,
}) {
  return (
    <Modal
      show={showModal}
      onSubmit={() => onDeletePhishingCampaign(campaign)}
      onClose={onClose}
      title={`Delete Phishing Campaign`}
      submitButtonText="Delete Campaign"
      submitButtonType="danger"
      compact
    >
      <section>
        {isSubmitting ? (
          <Fragment>
            <LoaderInline />
          </Fragment>
        ) : (
          <Fragment>
            <p className="mb-1">
              Are you sure you want to delete{" "}
              <span className="font-basis-bold">{campaign.name}</span>?
            </p>
          </Fragment>
        )}
      </section>
    </Modal>
  );
}
