import React, { Fragment } from 'react';
import APIKeysContainer from './APIKeysContainer';
import DeleteAPIKeyModal from './DeleteAPIKeyModal';
import GenerateAPIKeyModal from './GenerateAPIKeyModal';
import GoogleIntegration from './Integrations/GoogleIntegration';
import HrisIntegrations from './Integrations/HrisIntegrations/HrisIntegrations';
import VantaIntegration from './VantaIntegration';
import DoceboIntegration from './Integrations/DoceboIntegration';
import Callout from '../Callout';
import UpgradePlanButton from '../UpgradePlanButton';
import { useExternalAppsData } from '../../hooks/data-hooks/integrations/useExternalAppsData';
import SeismicIntegration from './Integrations/SeismicIntegration';

const integrationCalloutMessage = (integrationName) =>
  String.fromCodePoint('0x1F44B') +
  ` Your plan does not include access to ${integrationName} integration. Please upgrade your plan.`;

export default function IntegrationSettings({
  isAdmin,
  apiKeys,
  selectedApiKey,
  setSelectedApiKey,
  payPlanIncludesAPIAccess,
  payPlanIncludesHRIS,
  vantaOAuthLink,
  isVantaInstalled,
  hasDocebo,
  hasSeismic,
  showDeleteApiKeyModal,
  setShowDeleteApiKeyModal,
  handleOnDeleteApiKeyModalSubmit,
  showGenerateApiKeyModal,
  handleOnGenerateApiKeyButtonClick,
  handleOnGenerateApiKeyModalClose,
  setIsLoading,
  setIsHrisSectionReady,
  isGoogleWorkspaceIntegrated,
  autoSyncGoogleUsers,
  setAutoSyncGoogleUsers,
}) {
  const { data: externalIntegrations, isLoading: isLoadingExternalApps } =
    useExternalAppsData();
  const activeDoceboApp = externalIntegrations?.results.find(
    (ei) => ei.resourcetype === 'DoceboInstallation' && ei.is_active
  );
  const activeGoogleApp = externalIntegrations?.results.find(
    (ei) => ei.resourcetype === 'GoogleInstallation' && ei.is_active
  );
  const activeSeismicApp = externalIntegrations?.results.find(
    (ei) => ei.resourcetype === 'SeismicInstallation' && ei.is_active
  );

  return (
    <Fragment>
      <h1 className='mb-2'>Integrations</h1>
      {isAdmin && payPlanIncludesAPIAccess && (
        <>
          <APIKeysContainer
            onGenerateApiKeyButtonClick={handleOnGenerateApiKeyButtonClick}
            keys={apiKeys}
            setSelectedApiKey={(key) => {
              setSelectedApiKey(key);
              setShowDeleteApiKeyModal(true);
            }}
          />
        </>
      )}

      {isAdmin && (
        <>
          <HrisIntegrations
            setIsLoading={setIsLoading}
            payPlanIncludesHRIS={payPlanIncludesHRIS}
            setIsHrisSectionReady={setIsHrisSectionReady}
          />
        </>
      )}

      {isAdmin && (
        <>
          <VantaIntegration
            vantaOAuthLink={vantaOAuthLink}
            isVantaInstalled={isVantaInstalled}
          />
        </>
      )}

      {!hasDocebo && (
        <div className='col-span-full'>
          <Callout
            title={'Haekka'}
            message={integrationCalloutMessage('Docebo')}
            permanent
            date={false}
          >
            <UpgradePlanButton parentCpmToTrack={'Settings'} />
          </Callout>
        </div>
      )}

      {hasDocebo && (
        <DoceboIntegration
          activeDoceboApp={activeDoceboApp}
          isLoadingExternalApps={isLoadingExternalApps}
        />
      )}

      {!hasSeismic && (
        <div className='col-span-full'>
          <Callout
            title={'Haekka'}
            message={integrationCalloutMessage('Seismic')}
            permanent
            date={false}
          >
            <UpgradePlanButton parentCpmToTrack={'Settings'} />
          </Callout>
        </div>
      )}

      {hasSeismic && (
        <SeismicIntegration
          activeSeismicApp={activeSeismicApp}
          isLoadingExternalApps={isLoadingExternalApps}
        />
      )}

      <GoogleIntegration
        activeGoogleApp={activeGoogleApp}
        isLoadingExternalApps={isLoadingExternalApps}
      />

      <GenerateAPIKeyModal
        showModal={showGenerateApiKeyModal}
        onClose={handleOnGenerateApiKeyModalClose}
      />

      {selectedApiKey && (
        <DeleteAPIKeyModal
          key={selectedApiKey}
          showModal={showDeleteApiKeyModal}
          onSubmit={handleOnDeleteApiKeyModalSubmit}
          onClose={() => setShowDeleteApiKeyModal(false)}
        />
      )}
    </Fragment>
  );
}
