import React, { Fragment, useMemo, useState, useEffect } from "react";
import { format } from "date-fns";
import {
  getEmployeesAssignedToPhishingCampaign,
  getPhishingCampaignActionDetailsTable,
} from "../../../api";
import { onError } from "../../../libs/errorLib";
import Paginator from "../../Paginator";
import useDebounce from "../../../utils/custom-hooks/useDebounce";
import LoaderInline from "../../LoaderInline";
import TableBody from "./TableBody";
import NoPhishingAttacksTableBody from "./NoPhishingAttacksTableBody";

export default function AssignedEmployeesTable({
  auth,
  campaign,
  attacks,
  setSelectedEmployeeForRemoval,
  searchQuery,
  filteringOptions,
  sortOption,
}) {
  const [isLoading, setisLoading] = useState(false);
  const [employees, setEmployees] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [page, setPage] = useState(1);

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(
    () => setPage(1),
    [debouncedSearchQuery, filteringOptions, sortOption]
  );

  useEffect(() => {
    async function loadActions() {
      try {
        setisLoading(true);

        const assignedEmployeesPromise = () =>
          getEmployeesAssignedToPhishingCampaign({
            auth,
            page,
            name: debouncedSearchQuery,
            campaignId: campaign.id,
          });

        const employees = await assignedEmployeesPromise();

        if (attacks.length > 0) {
          const tableData = await getPhishingCampaignActionDetailsTable({
            auth,
            phishingCampaignId: campaign.id,
            params: {
              sort: sortOption.value,
              attacks:
                filteringOptions?.length > 0
                  ? filteringOptions.map((filter) => filter.value)
                  : attacks.map((attack) => attack.id),
              employees: employees.results.map((result) => result.id),
            },
          });

          setTableData(tableData);
        }

        setEmployees(employees);
      } catch (error) {
        onError(error);
      } finally {
        setisLoading(false);
      }
    }

    loadActions();
  }, [
    page,
    debouncedSearchQuery,
    filteringOptions,
    sortOption,
    attacks,
    auth,
    campaign.id,
  ]);

  const formatAttackDateToColumnLabel = (date) =>
    format(new Date(date), "MMM dd, yyyy");

  const filteredAttacks = useMemo(() => {
    const filteringValues = filteringOptions
      ? filteringOptions.map((filter) => filter.value)
      : [];

    if (filteringValues.length > 0) {
      return attacks.filter((attack) => filteringValues.includes(attack.id));
    } else {
      return attacks;
    }
  }, [attacks, filteringOptions]);

  const columns = useMemo(() => {
    return filteredAttacks.map((attack) => ({
      id: attack.id,
      label: formatAttackDateToColumnLabel(attack.created),
      templateIcon: attack.template.icon,
      templateName: attack.template.name,
    }));
  }, [filteredAttacks]);

  const rows = useMemo(() => {
    const results = [];

    employees?.results?.forEach((employee) => {
      const row = [employee];

      const employeeData =
        tableData?.find((t) => t.employee === employee.id) || null;

      columns.forEach((column) => {
        const action = employeeData ? employeeData[column.id] : null;

        if (action) {
          action.employeeId = employee?.id || null;
          action.attackId = column?.id || null;
        }

        row.push(action);
      });

      results.push(row);
    });

    return results;
  }, [employees?.results, tableData, columns]);

  if (isLoading) {
    return <LoaderInline />;
  }

  return (
    <Fragment>
      {attacks.length > 0 ? (
        <TableBody
          columns={columns}
          rows={rows}
          sortOption={sortOption}
          setSelectedEmployeeForRemoval={setSelectedEmployeeForRemoval}
        />
      ) : (
        <NoPhishingAttacksTableBody
          campaign={campaign}
          rows={rows}
          setSelectedEmployeeForRemoval={setSelectedEmployeeForRemoval}
        />
      )}
      {!isLoading && employees?.count > 0 && (
        <Paginator
          count={employees.count}
          currentPage={page}
          onPageChange={setPage}
          pageSize={10}
        />
      )}
    </Fragment>
  );
}
