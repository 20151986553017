import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createStream } from '../../../../api/streamsApi';
import faker from '@faker-js/faker';

export const useCreateStream = ({ onSuccess = () => {}, currentPage }) => {
  const queryClient = useQueryClient();
  const queryKey = ['streams', currentPage];

  return useMutation({
    mutationFn: createStream,
    onMutate: async ({ payload }) => {
      await queryClient.cancelQueries({ queryKey });

      const previousStreams = queryClient.getQueriesData(queryKey);

      queryClient.setQueriesData(queryKey, (old) => ({
        ...old,
        results: [{ ...payload, id: faker.datatype.uuid() }, ...old.results],
      }));

      return { previousStreams };
    },
    onError: (_error, _data, context) => {
      queryClient.setQueryData(queryKey, context.previousStreams);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onSuccess,
  });
};
