import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import * as api from "../api";
import Modal from "./Modal/Modal";
import Loader from "./Loader";
import TrainingTagInput from "./TrainingTag/TrainingTagInput";

const GlobalCurriculumSettings = ({
  onModalHide,
  curriculumId,
  trainingId,
  setTriggerGetTraining,
}) => {
  const { id } = useParams();
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [isTechnical, setIsTechnical] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isAvailableForFree, setIsAvailableForFree] = useState(false);
  const [fetcher, setFetcher] = useState(true);
  const [curriculum, setCurriculum] = useState(null);
  const [curriculumType, setCurriculumType] = useState("");
  const [curriculumTypes, setCurriculumTypes] = useState(null);
  const [authorName, setAuthorName] = useState("");
  const [authorIconUrl, setAuthorIconUrl] = useState("");
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    async function onLoad() {
      try {
        const curriculumTypesResponse = await api.curriculumTypes({
          auth: isAuthenticated,
          pageSize: 10000,
        });

        const curriculumTagesResponse = await api.curriculumTags({
          auth: isAuthenticated,
          pageSize: 10000,
        });

        setCurriculumTypes(curriculumTypesResponse.results);
        const curriculum = await api.getCurriculum({
          auth: isAuthenticated,
          id: curriculumId,
        });
        if (curriculum) {
          setTitle(curriculum.title);
          setIsTechnical(curriculum.is_technical);
          setIsArchived(curriculum.is_archived);
          setIsPublished(curriculum.is_published);
          setIsEditable(curriculum.is_editable);
          setIsAvailableForFree(curriculum.is_available_for_free);
          setSubtitle(curriculum.subtitle);
          setCurriculum(curriculum);
          setCurriculumType(curriculum.curriculum_type);
          setAuthorName(curriculum.author_name);
          setAuthorIconUrl(curriculum.author_icon_url);
          setTags(curriculum.tags);

          const suggestions = [];
          curriculumTagesResponse.results.forEach((tag) => {
            const match = curriculum.tags.find(
              (curTag) => curTag.name === tag.name
            );
            if (!match) suggestions.push(tag);
          });
          setSuggestions(suggestions);
        }
      } catch (e) {
        onError(e);
        // toast.error(e);
      }
      setIsLoading(false);
    }
    onLoad();
  }, [id, fetcher, isAuthenticated, curriculumId]);

  const handleUpdateSuggestions = (suggestion) => {
    const match = suggestions.find((s) => s.name === suggestion.name);
    if (!match) {
      setSuggestions(
        [...suggestions, suggestion].sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        )
      );
    }
  };

  async function handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    const updatedData = {
      title,
      subtitle: subtitle,
      is_technical: isTechnical,
      is_archived: isArchived,
      is_published: isPublished,
      is_editable: isEditable,
      is_available_for_free: isAvailableForFree,
      curriculum_type: curriculumType,
      author_name: authorName,
      author_icon_url: authorIconUrl,
      tags: tags,
    };
    try {
      await api.updateCurriculum({
        auth: isAuthenticated,
        id: curriculum.id,
        updatedData,
      });
      toast.success(`Global curriculum updated`);
      setTriggerGetTraining();
      onModalHide();
    } catch (e) {
      onError(e);
    }
  }

  const createGlobalCurriculum = async () => {
    try {
      setIsLoading(true);
      await api.haekkaPublishTraining({
        auth: isAuthenticated,
        trainingId,
      });
      setFetcher((f) => !f);
    } catch (error) {
      onError(error);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <Modal
      show={true}
      id="edit-training-modal"
      title="Global Curriculum Settings"
      onClose={onModalHide}
      onSubmit={handleSubmit}
      submitButtonText="Save Changes"
      submitValid={!!title && !!subtitle}
      expandable
    >
      {curriculum ? (
        <section>
          {isAuthenticated.company_details.is_global_content_maker && (
            <>
              <section className="mb-3">
                <h4 className="mb-1 font-basis-bold">
                  Global Curriculum Title{" "}
                </h4>
                <input
                  type="text"
                  name="days_till_due"
                  value={title}
                  className="w-full h-4 pl-1 pr-1 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray-dark border-hka_gray-lights hka-input mb-1"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </section>

              <section className="mb-3">
                <h4 className="mb-1 font-basis-bold">
                  Global Curriculum Subtitle{" "}
                </h4>
                <textarea
                  type="text"
                  name="days_till_due"
                  value={subtitle}
                  className="w-full min-h-7 pl-1 pr-1 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray-dark border-hka_gray-lights hka-input mb-1"
                  onChange={(e) => setSubtitle(e.target.value)}
                ></textarea>
              </section>

              <section className="w-full mb-4">
                <h4 className="mb-1">
                  Global Training Category{" "}
                  <span className="text-hka_gray">(Required)</span>
                </h4>
                <select
                  className={`hka-dropdown outline-none text-hka_gray-dark border-hka_gray-lights hka-input`}
                  name="category"
                  id="category-select"
                  value={curriculumType}
                  onChange={(e) => setCurriculumType(e.target.value)}
                >
                  <option value="" className="bg-white text-hka_gray-dark">
                    -- Select Training Category --
                  </option>
                  {curriculumTypes &&
                    curriculumTypes.map((item) => (
                      <option
                        key={item.id}
                        value={item.id}
                        title={item.name}
                        className="bg-white text-hka_gray-dark"
                      >
                        {item.name}
                      </option>
                    ))}
                </select>
              </section>

              <section className="w-full mb-3">
                <h4 className="mb-1 font-basis-bold">
                  Training Tags{" "}
                  <span className="text-hka_gray">(Optional)</span>
                </h4>
                <TrainingTagInput
                  tags={tags}
                  setTags={(e) => {
                    const sugg = [];
                    suggestions.forEach((s) => {
                      const match = e.find((a) => a.name === s.name);
                      if (!match) sugg.push(s);
                    });
                    setSuggestions(sugg);
                    setTags(e);
                  }}
                  suggestions={suggestions}
                  updateSuggestions={handleUpdateSuggestions}
                />
              </section>

              <section className="mb-3 flex">
                <section className="flex items-center">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="has_weekly_quiz"
                      checked={isTechnical}
                      onChange={(e) => setIsTechnical(e.target.checked)}
                      className="cursor-pointer"
                    />
                    <span className="slider"></span>
                  </label>
                </section>
                <h4 className="ml-1 font-basis-bold ">Is Technical?</h4>
              </section>

              <section className="mb-3 flex">
                <section className="flex items-center">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="has_weekly_quiz"
                      checked={isArchived}
                      onChange={(e) => setIsArchived(e.target.checked)}
                      className="cursor-pointer"
                    />
                    <span className="slider"></span>
                  </label>
                </section>
                <h4 className="ml-1 font-basis-bold ">Is Archived?</h4>
              </section>

              <section className="mb-3 flex">
                <section className="flex items-center">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="has_weekly_quiz"
                      checked={isPublished}
                      onChange={(e) => setIsPublished(e.target.checked)}
                      className="cursor-pointer"
                    />
                    <span className="slider"></span>
                  </label>
                </section>
                <h4 className="ml-1 font-basis-bold ">Is Published?</h4>
              </section>

              <section className="mb-3 flex">
                <section className="flex items-center">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="has_weekly_quiz"
                      checked={isEditable}
                      onChange={(e) => setIsEditable(e.target.checked)}
                      className="cursor-pointer"
                    />
                    <span className="slider"></span>
                  </label>
                </section>
                <h4 className="ml-1 font-basis-bold ">Is Editable?</h4>
              </section>

              <section className="mb-4 flex">
                <section className="flex items-center">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="has_weekly_quiz"
                      checked={isAvailableForFree}
                      onChange={(e) => setIsAvailableForFree(e.target.checked)}
                      className="cursor-pointer"
                    />
                    <span className="slider"></span>
                  </label>
                </section>
                <h4 className="ml-1 font-basis-bold">Available For Free?</h4>
              </section>

              <section className="mb-3">
                <h4 className="mb-1 font-basis-bold">Author Name</h4>
                <input
                  type="text"
                  name="days_till_due"
                  value={authorName}
                  className="w-full h-4 pl-1 pr-1 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray-dark border-hka_gray-lights hka-input mb-1"
                  onChange={(e) => setAuthorName(e.target.value)}
                />
              </section>

              <section className="mb-3">
                <h4 className="mb-1 font-basis-bold">Author Icon URL</h4>
                <input
                  type="text"
                  name="days_till_due"
                  value={authorIconUrl}
                  className="w-full h-4 pl-1 pr-1 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray-dark border-hka_gray-lights hka-input mb-1"
                  onChange={(e) => setAuthorIconUrl(e.target.value)}
                />
              </section>
            </>
          )}
        </section>
      ) : (
        <section>
          <p>
            Global curriculum associated with this training could not be found.
            Perhaps you haven't created it yet?
          </p>
          <button
            className="w-full px-2 bg-green-100 button button--default sm:w-auto hover:bg-green-200 text-hka_green mt-3"
            onClick={createGlobalCurriculum}
          >
            Create Global Curriculum
          </button>
        </section>
      )}
    </Modal>
  );
};

export default GlobalCurriculumSettings;
