import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createExternalAppWithBasicAuth } from '../../../api/integrationsApi';

export const useCreateExternalAppWithBasicAuth = ({
  callbackFn = () => {},
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createExternalAppWithBasicAuth,
    onSuccess: () => {
      queryClient.invalidateQueries(['external-installations-all']);
      callbackFn();
    },
  });
};
