import api from '../client';

export const getStreams = async ({ page = 1 }) => {
  const { data } = await api.get(`/api/streams?page=${page}`);
  return data;
};

export const createStream = async ({ payload }) => {
  const { data } = await api.post('/api/streams/', payload);
  return data;
};

export const updateStream = async ({ streamID, payload }) => {
  const { data } = await api.put(`/api/streams/${streamID}`, payload);
  return data;
};

export const deleteStream = async ({ streamID }) => {
  const { data } = await api.delete(`/api/streams/${streamID}`);
  return data;
};

export const getPostCategories = async ({ page = 1 }) => {
  const { data } = await api.get(`/api/streams/categories?page=${page}`);
  return data;
};

export const createStreamPost = async ({ payload }) => {
  const { data } = await api.post('/api/streams/posts', payload);
  return data;
};

export const updateStreamPost = async ({ postID, payload }) => {
  const { data } = await api.put(`/api/streams/posts/${postID}`, payload);
  return data;
};

export const updateSentStreamPosts = async ({ postID }) => {
  const { data } = await api.post(`/api/streams/posts/${postID}/update`, {});
  return data;
};

export const deleteStreamPost = async ({ postID }) => {
  const { data } = await api.delete(`/api/streams/posts/${postID}`);
  return data;
};

export const getStreamPosts = async ({
  comapnyID,
  streamID,
  sent,
  page = 1,
}) => {
  const { data } = await api.get(
    `/api/companies/${comapnyID}/streams/${streamID}/posts`,
    { params: { sent, page } }
  );

  return data;
};

export const getCompanyStream = async ({ companyID, streamID }) => {
  const { data } = await api.get(
    `/api/companies/${companyID}/streams/${streamID}`
  );
  return data;
};

export const subscribeToStream = async ({ companyID, streamID }) => {
  const { data } = await api.put(
    `/api/companies/${companyID}/streams/${streamID}/subscribe`,
    {}
  );
  return data;
};

export const updateStreamSubscription = async ({
  companyID,
  streamID,
  payload,
}) => {
  const { data } = await api.patch(
    `/api/companies/${companyID}/streams/${streamID}/subscribe`,
    payload
  );
  return data;
};

export const getCompanyStreamPost = async ({ companyID, streamID, postID }) => {
  const { data } = await api.get(
    `/api/companies/${companyID}/streams/${streamID}/posts/${postID}`
  );
  return data;
};

export const updateCompanyPost = async ({
  companyID,
  streamID,
  postID,
  payload,
}) => {
  const { data } = await api.patch(
    `/api/companies/${companyID}/streams/${streamID}/posts/${postID}`,
    payload
  );
  return data;
};

export const updateCompanyPostToCustom = async ({
  companyID,
  streamID,
  payload,
  postID,
  params,
}) => {
  let url = `/api/companies/${companyID}/streams/${streamID}/posts/${postID}`;
  const { data } = await api.put(url, payload, { params });
  return data;
};

export const deleteCustomStreamPost = async ({
  companyID,
  streamID,
  postID,
}) => {
  const { data } = await api.delete(
    `/api/companies/${companyID}/streams/${streamID}/posts/${postID}`
  );
  return data;
};

export const getEmployeesInStream = async ({ streamID, page = 1 }) => {
  const { data } = await api.get(
    `/api/employees/streams/${streamID}?page=${page}`
  );
  return data;
};

export const employeeUnassignStream = async ({ employeeID, streamID }) => {
  const { data } = await api.post(
    `/api/employees/${employeeID}/streams/${streamID}/unassign`,
    {}
  );
  return data;
};
