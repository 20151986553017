import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteEngagementQuestion } from "../../../api/engagementsApi";

export const useDeleteMessageData = ({
  companyEngagementID,
  selectedQuestionId,
  setSelectedQuestionId,
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteEngagementQuestion,
    onSuccess: () => {
      setSelectedQuestionId(null);
      queryClient.setQueryData(["engagement", companyEngagementID], (curr) => ({
        ...curr,
        quiz: {
          ...curr.quiz,
          questions: curr.quiz.questions.filter(
            (q) => q.id !== selectedQuestionId
          ),
        },
      }));
      queryClient.refetchQueries(["engagement", companyEngagementID]);
    },
  });
};
