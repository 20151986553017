import ReactDatePicker from 'react-datepicker';
import { formatTrainingDate } from '../utils/formatTrainingDate';

const RenewTrainingDatePicker = ({
  renewDate,
  renewCycle,
  setRenewDate,
  renewOnTheSameDayForEveryone,
}) => {
  return (
    <>
      {/* <p className="text-sm ml-0.1 mb-0.5">
        {renewCycle === "none"
          ? "This training is currently not set to renew. As a result, you cannot change it's renewal date."
          : `Don't want the training to renew based on ${formatTrainingDate(
              new Date(renewDate)
            )}? Select a different date below`}
      </p> */}
      <div className='mt-0.5'>
        <ReactDatePicker
          minDate={new Date()}
          disabled={renewCycle === 'none' || !renewOnTheSameDayForEveryone}
          className={`w-full h-4 pl-4 pr-1 focus:outline-none disabled:bg-hka_gray-light ${
            renewCycle === 'none' ? 'text-hka_gray' : 'text-hka_gray'
          } datepicker`}
          selected={new Date(renewDate)}
          onChange={(date) => {
            setRenewDate(date);
          }}
          dateFormat='PP'
        />
      </div>
    </>
  );
};

export default RenewTrainingDatePicker;
