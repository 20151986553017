import { Fragment, useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { onError } from "../../../libs/errorLib";
import { customStyles } from "./CreateEvent";
import * as api from "../../../api";
import { useAppContext } from "../../../libs/contextLib";
import LoaderInline from "../../LoaderInline";
import { useSelector, useDispatch } from "react-redux";
import { workflowsActions } from "../../../store/workflowsSlice";

const AssignTrainingAction = () => {
  const { isAuthenticated } = useAppContext();
  const [loadingCompanyTrainings, setLoadingCompanyTrainings] = useState(true);

  const dispatch = useDispatch();
  const { selectedTraining, companyTrainings } = useSelector(
    (state) => state.workflows
  );

  const selectTrainingHandler = useCallback(
    (training) => {
      dispatch(workflowsActions.selectTraining({ training }));
    },
    [dispatch]
  );

  const loadCompanyTrainings = useCallback(() => {
    return api.getActiveCompanyTrainings({
      auth: isAuthenticated,
    });
  }, [isAuthenticated]);

  useEffect(() => {
    if (companyTrainings.length > 0 && selectedTraining?.label.length === 0) {
      const training = companyTrainings.find(
        (training) => training.value === selectedTraining.value
      );
      selectTrainingHandler(training);
    }
  }, [
    selectedTraining,
    companyTrainings.length,
    companyTrainings,
    selectTrainingHandler,
  ]);

  useEffect(() => {
    const setCompanyTrainingsHandler = (results) => {
      const trainings = results.map((training) => ({
        value: training.id,
        label: training.curriculum_detail.title,
      }));

      dispatch(workflowsActions.setCompanyTrainings({ trainings }));
    };

    const onInit = async () => {
      try {
        if (companyTrainings.length === 0) {
          const { results } = await loadCompanyTrainings();
          setCompanyTrainingsHandler(results);
        }
        setLoadingCompanyTrainings(false);
      } catch (e) {
        onError(e);
      }
    };

    onInit();
  }, [
    companyTrainings.length,
    isAuthenticated,
    loadCompanyTrainings,
    selectTrainingHandler,
    dispatch,
  ]);

  return (
    <Fragment>
      <div className="pb-2 pl-2 border-l-4 border-hka_gray-border">
        <p className="mb-0.5 text-hka_gray-dark">Training</p>

        {loadingCompanyTrainings && (
          <LoaderInline wrapperClasses={"w-full min-h-2"} loaderSize={3} />
        )}

        {!loadingCompanyTrainings && companyTrainings.length > 0 && (
          <Select
            onChange={(value) => selectTrainingHandler(value)}
            value={selectedTraining}
            placeholder="Select Training"
            isMulti={false}
            options={companyTrainings}
            classNamePrefix="select"
            styles={customStyles}
            maxMenuHeight={170}
            className="w-3/4"
            unstyled
          />
        )}

        {!loadingCompanyTrainings && companyTrainings.length === 0 && (
          <p className="mt-0.5">
            Oops, it looks like you don't have any training created yet. To use
            this action, please create your first training
          </p>
        )}
      </div>
    </Fragment>
  );
};

export default AssignTrainingAction;
