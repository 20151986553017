import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import * as api from '../../api';
import { useAppContext } from '../../libs/contextLib';
import { onError } from '../../libs/errorLib';
import usePolling from '../../utils/custom-hooks/usePolling';
import { lessonProperties } from '../../utils/trainingUtils';
import { handleVideoDeletedTrack } from '../../utils/video-upload-utils';
import VideoUploader from '../VideoUpload/VideoUploader';
import DeleteVideoModal from './DeleteVideoModal';
import { useSelector, useDispatch } from 'react-redux';
import { videoUploadActions } from '../../store/video-upload';
import { lessonActions } from '../../store/lessonSlice';
import CaptionsModal from '../VideoUpload/CaptionsModal';
import Note from '../Note';

const VideoSection = ({ position, setEditLessonOpened }) => {
  const { videoURL, videoURLValid, fetchedLesson, showToBrowserLinkInSlack } =
    useSelector((state) => state.lesson);

  const dispatch = useDispatch();
  const { isAuthenticated } = useAppContext();
  const [customVideoUpload, setCustomVideoUpload] = useState(false);
  const [video, setVideo] = useState(false);
  const [showDeleteVideoModal, setShowDeleteVideoModal] = useState(false);
  const [showCaptionSettingsModal, setShowCaptionSettingsModal] =
    useState(false);

  const [customVideoUploded, setCustomVideoUploaded] = useState(undefined);
  const [videoUploadCompletenessChecked, setVideoUploadCompletenessChecked] =
    useState(false);
  const { updateResponses } = useSelector((state) => state.videoUpload);

  useEffect(() => {
    if (fetchedLesson?.id) {
      const updatedData = updateResponses[fetchedLesson.id];
      if (updatedData) {
        setCustomVideoUploaded({ ...updatedData });
      }
    }
  }, [fetchedLesson, updateResponses]);

  usePolling(async () => {
    if (video && !video.processed) {
      const v = await api.getCustomVideo({
        auth: isAuthenticated,
        videoId: video.id,
      });
      setVideo(v);
      setVideoUploadCompletenessChecked(true);
    }
  }, 750);

  useEffect(() => {
    if (fetchedLesson && fetchedLesson.lesson_custom_video.length > 0) {
      setCustomVideoUpload(true);
      setVideo(fetchedLesson.lesson_custom_video[0]);
    }
    if (customVideoUploded) {
      setCustomVideoUpload(true);
      setVideo(customVideoUploded);
    }
  }, [fetchedLesson, customVideoUploded]);

  const onDelete = async () => {
    setShowDeleteVideoModal(true);
  };

  const deleteVideoHandler = async () => {
    try {
      await api.deleteCustomVideo({
        auth: isAuthenticated,
        videoId: video.id,
        type: 'lesson',
      });
      toast.success('Video deleted');
      setCustomVideoUpload(false);
      setEditLessonOpened(undefined);
      dispatch(lessonActions.resetStore());
      handleVideoDeletedTrack({ auth: isAuthenticated, type: 'lesson' });
      setVideo(false);

      dispatch(
        videoUploadActions.removeDeletedUpload({
          videoId: video.id,
          objectId: fetchedLesson?.id,
        })
      );
    } catch (error) {
      onError(error);
    }
  };

  return (
    <section className='w-full pb-2'>
      <h4 className='font-basis-bold'>
        Video <span className='text-sm text-hka_gray'>(Optional)</span>
      </h4>
      {customVideoUpload ? (
        <>
          {!!video ? (
            <div>
              {/* <p className="mb-0.3">Video uploaded:</p> */}
              <div className='flex items-center space-x-0.5'>
                <div className='flex items-center flex-grow space-x-1'>
                  <img
                    src={video.thumbnail}
                    alt={video.name}
                    height={60}
                    width={100}
                    className='rounded-lg shadow-light'
                  />{' '}
                  <div>
                    <p className='font-basis-bold text-hka_gray-dark '>
                      {video.name}
                    </p>
                    <p className='text-sm'>{video.duration} sec</p>
                  </div>
                </div>
                <div>
                  <button
                    type='button'
                    className='button button--gray bg-hka_gray-light h-3.2 min-w-0 w-3.2 px-0'
                    onClick={() => setShowCaptionSettingsModal(true)}
                    title='Caption Settings'
                  >
                    <span className='icon-tile'></span>
                  </button>
                </div>
                <div className=''>
                  <button
                    type='button'
                    className='px-0 button button--gray bg-hka_gray-light h-3.2 min-w-0 w-3.2 '
                    onClick={onDelete}
                    title='Remove Video'
                  >
                    <span className='icon-trash'></span>
                  </button>
                </div>
              </div>
              {!video.processed && videoUploadCompletenessChecked && (
                <Note type='default'>
                  <p>
                    👋 Your video is being processed. We will send you a message
                    in Slack once it's ready. Depending on the size of your
                    file, it can take several minutes.
                  </p>
                </Note>
              )}
            </div>
          ) : (
            <>
              <VideoUploader
                type={'lesson'}
                id={fetchedLesson?.id}
                position={position}
                parent={fetchedLesson?.id}
              />

              <p className='mb-1 text-sm'>
                Add a video URL
                <span
                  className='inline-flex items-center link ml-0.5 cursor-pointer'
                  onClick={() => setCustomVideoUpload(false)}
                >
                  here.
                </span>
              </p>
            </>
          )}
        </>
      ) : (
        <>
          {' '}
          <p className='mb-1 text-sm'>
            You can add a video to this lesson by pasting a URL from YouTube,
            Wistia, Loom or Vimeo. This video will be displayed at the top of
            the lesson message in the Haekka Slack App.
          </p>
          <input
            type='text'
            value={videoURL}
            onChange={(e) =>
              dispatch(
                lessonActions.updateLesson({
                  property: lessonProperties.VIDEO_URL,
                  value: e.target.value,
                })
              )
            }
            placeholder='Enter Video URL'
            className={`w-full p-1 font-semibold border outline-none rounded-xl text-base2 text-hka_gray-dark border-hka_gray-light ${
              !videoURLValid
                ? 'text-red-500 bg-red-50 border-red-500 border p-1'
                : 'text-hka_gray-dark border-hka_gray-light hka-input'
            }`}
          />
          {videoURL.length > 0 && (
            <section className='flex items-center justify-between w-full my-2'>
              <p className=''>Render the "view in browser" link in Slack?</p>
              <label className='switch'>
                <input
                  type='checkbox'
                  name='showToBrowserLinkInSlack'
                  checked={showToBrowserLinkInSlack}
                  onChange={(e) => {
                    dispatch(
                      lessonActions.updateLesson({
                        property:
                          lessonProperties.SHOW_TO_BROWSER_LINK_IN_SLACK,
                        value: e.target.checked,
                      })
                    );
                  }}
                />
                <span className='slider'></span>
              </label>
            </section>
          )}
        </>
      )}
      <h5>
        {!videoURLValid && (
          <span className='text-red-500'>Pleasse enter a valid URL</span>
        )}
      </h5>

      <p className='mt-0.5 mb-1 text-sm'>
        You can also upload a video file directly to Haekka by
        <span
          className='inline-flex items-center link ml-0.5 cursor-pointer'
          onClick={() => setCustomVideoUpload(true)}
        >
          clicking here.
        </span>
      </p>
      {showDeleteVideoModal && (
        <DeleteVideoModal
          showModal={showDeleteVideoModal}
          onSubmit={deleteVideoHandler}
          onClose={() => setShowDeleteVideoModal(false)}
        />
      )}
      {showCaptionSettingsModal && (
        <CaptionsModal
          videoURL={video?.hashed_id}
          onClose={() => setShowCaptionSettingsModal(false)}
        />
      )}
    </section>
  );
};

export default VideoSection;
