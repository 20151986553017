import "./styles/PhishingCampaignDetailsSearchAndFilteringOptions.css";
import React from "react";
import DropdownMenu from "../Base/DropdownMenu";
import Select from "react-select";
import ActionButton from "../ActionButton";

export default function PhishingCampaignDetailsSearchAndFilteringOptions({
  campaignSendOption,
  searchQuery,
  setSearchQuery,
  sortOptions,
  filteringOptions,
  selectedFilteringOptions,
  setSelectedFilteringOptions,
  selectedSortingOption,
  setSelectedSortingOption,
  setShowAssignEmployeesModal,
}) {
  const actions = [
    {
      icon: "icon-users",
      label: "Assign Employees",
      onClick: () => {
        setShowAssignEmployeesModal(true);
      },
    },
  ];
  const formatOptionLabel = ({ label, icon }) => (
    <div className="flex flex-row items-center">
      <div>
        <span className="w-2.4 h-2.4 flex items-center justify-center mr-1">
          <img
            alt="Template Icon"
            src={
              icon ||
              "https://haekka-author-icons.s3.us-west-2.amazonaws.com/HKA--UPDATED--Author_Haekka.png"
            }
          />
        </span>
      </div>
      <span className="self-center text-hka_gray">{label}</span>
    </div>
  );

  return (
    <div className="flex space-x-1">
      <div className="flex flex-col flex-1 space-y-0.5 md:flex-row md:space-y-0 md:space-x-0.5">
        <div className="flex flex-grow md:flex-grow-0  items-center md:w-20 pl-0.5 h-4 bg-white rounded-lg lg:flex-none border border-hka_gray-border">
          <span className="icon-search text-hka_gray mt-0.2"></span>
          <input
            className="h-3 text-base border-none w-full max-h-3.8 focus:outline-none"
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search Employees"
          />
        </div>

        {campaignSendOption !== "once_immediately" &&
          filteringOptions.length > 1 && (
            <Select
              formatOptionLabel={formatOptionLabel}
              value={selectedFilteringOptions}
              onChange={setSelectedFilteringOptions}
              placeholder="Filter By Attack"
              options={filteringOptions}
              className="h-4 md:w-20 text-hka_gray"
              maxMenuHeight="20.8rem"
              classNamePrefix="select"
              isMulti={filteringOptions.length > 1}
              isSearchable={false}
              unstyled
            />
          )}
        <div className="flex-1">
          <Select
            value={selectedSortingOption}
            onChange={setSelectedSortingOption}
            placeholder="Sort By"
            options={sortOptions}
            className="h-4 md:w-20 text-hka_gray"
            maxMenuHeight="20.8rem"
            classNamePrefix="select"
            isSearchable={false}
            unstyled
          />
        </div>
      </div>
      <ActionButton onClick={actions[0].onClick}>
        <span className="px-2 sm:px-0 font-basis-medium">
          {actions[0].label}
        </span>
      </ActionButton>
    </div>
  );
}
