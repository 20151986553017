import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertToRaw, EditorState } from 'draft-js';

import * as api from '../../../api';
import {
  useCreateMessageData,
  useUpdateMessageData,
} from '../../../hooks/data-hooks/engagements';

import { convertToSlackNotation } from '../../../utils/wysiwygEditorHelpers';
import { useAppContext } from '../../../libs/contextLib';
import { videoUploadActions } from '../../../store/video-upload';
import usePolling from '../../../utils/custom-hooks/usePolling';
import { getNextMessagePosition } from '../../../utils/commonUtils';
import {
  engagementAnswerTypeOptions,
  getAnswerTypeForQuestion,
  initializeQuestionEditorState,
  validateAddMessageForm,
} from '../../../utils/engagementsUtils';

import Modal from '../../Modal/Modal';
import EditorSection from './EditorSection';
import VideoSection from './VideoSection';
import AnswerTypeSection from './AnswerTypeSection';

const EngagementMessageModal = ({
  show,
  create,
  onClose,
  selectedQuestion,
  companyEngagement,
  nextQuestionPosition,
  isReadOnly,
}) => {
  const { isAuthenticated } = useAppContext();
  const dispatch = useDispatch();

  const [isFormValid, setIsFormValid] = useState(false);
  const [answerType, setAnswerType] = useState(undefined);
  const [answers, setAnswers] = useState([]);
  const [questionEditorState, setQuestionEditorState] = useState(undefined);
  const [imageIds, setImageIds] = useState([]);
  const [video, setVideo] = useState(false);
  const [externalUrl, setExternalUrl] = useState('');
  const [externalUrlBtnText, setExternalUrlBtnText] = useState('');
  const [isSingleSelect, setIsSingleSelect] = useState(false);

  // video-related stuff - revisit later
  const { uploadsInProgress, updateResponses } = useSelector(
    (state) => state.videoUpload
  );
  const [videoUploadCompletenessChecked, setVideoUploadCompletenessChecked] =
    useState(false);
  const np = useRef(nextQuestionPosition.current);

  useEffect(() => {
    const initFormState = async () => {
      const editorState = await initializeQuestionEditorState(selectedQuestion);
      setQuestionEditorState(editorState);
      setAnswerType(getAnswerTypeForQuestion(selectedQuestion));
      setAnswers(
        selectedQuestion.answers.map((a) => ({
          ...a,
          explanation: a.explanation === '' ? null : a.explanation,
        }))
      );
      if (selectedQuestion.engagement_custom_video.length > 0) {
        setVideo(selectedQuestion.engagement_custom_video[0]);
      }
      setExternalUrl(selectedQuestion.external_url);
      setExternalUrlBtnText(selectedQuestion.external_url_btn_text);
      setIsSingleSelect(selectedQuestion.single_select);
    };
    if (!create && selectedQuestion) initFormState();
    if (create) {
      setQuestionEditorState(EditorState.createEmpty());
    }
    if (create && companyEngagement.quiz.engagement_type === 'channel') {
      setAnswerType(
        engagementAnswerTypeOptions.find((o) => o.value === 'user_acknowledged')
      );
      setAnswerType(
        engagementAnswerTypeOptions.find((o) => o.value === 'user_acknowledged')
      );
    }
  }, [companyEngagement.quiz.engagement_type, create, selectedQuestion]);

  usePolling(async () => {
    if (video && !video.processed) {
      const v = await api.getCustomVideo({
        auth: isAuthenticated,
        videoId: video.id,
      });
      setVideo(v);
      setVideoUploadCompletenessChecked(true);
    }
  }, 750);

  const { isLoading: isCreateMutating, mutate: createMessageMutation } =
    useCreateMessageData({ companyEngagement, onClose });

  const { isLoading: isUpdateMutating, mutate: updateMessageMutation } =
    useUpdateMessageData({
      companyEngagement,
      selectedQuestion,
      onClose,
    });

  useEffect(() => {
    if (isCreateMutating || isUpdateMutating) {
      setIsFormValid(false);
      return;
    }
    setIsFormValid(
      isReadOnly
        ? false
        : validateAddMessageForm({
            questionEditorState,
            answerType,
            answers,
            externalUrl,
            externalUrlBtnText,
          })
    );
  }, [
    answerType,
    answers,
    questionEditorState,
    isCreateMutating,
    isUpdateMutating,
    externalUrl,
    externalUrlBtnText,
    isReadOnly,
  ]);

  const sumbitHandler = async () => {
    const questionEditorStateRaw = JSON.stringify(
      convertToRaw(questionEditorState.getCurrentContent())
    );
    const slackMarkup = await convertToSlackNotation(
      questionEditorState.getCurrentContent()
    );

    const data = {
      quiz: companyEngagement.quiz.id,
      answer_type: answerType.value,
      quiz_question_context: slackMarkup,
      editor_content: {
        quiz_editor_state: questionEditorStateRaw,
      },
      answers: answers.map((ans) =>
        ans.id.startsWith('answer') ? { ...ans, id: undefined } : ans
      ),
      image_ids: imageIds,
      // custom_video_id: upload.uploadResponseData?.id,
      position: create
        ? getNextMessagePosition(companyEngagement.quiz.questions)
        : selectedQuestion.position,
      external_url: externalUrl,
      external_url_btn_text: externalUrlBtnText,
      single_select: isSingleSelect,
    };

    if (create) {
      createMessageMutation(data);
    } else {
      data.question_id = selectedQuestion?.id;
      updateMessageMutation(data);
    }

    // check if video is envoled
    const upload = uploadsInProgress.find(
      (u) =>
        u.type === 'engagement-question' &&
        u.position === np.current &&
        u.parent === companyEngagement.quiz.id
    );

    // if mapped (video upload finished) -> update object
    if (upload && upload.mapped && upload.uploadResponseData) {
      await api.updateCustomVideo({
        auth: isAuthenticated,
        reqData: {
          ...upload.uploadResponseData,
          object_id: selectedQuestion?.id,
        },
      });
      dispatch(
        videoUploadActions.updateUpload({
          upload: {
            type: 'engagement-question',
            parent: companyEngagement.quiz.id,
            position: np.current,
            success: true,
            message: 'Upload completed!',
            progress: 100,
            title: upload.name,
            completed: true,
          },
        })
      );
    }
    // if NOT mapped (video upload is NOT finished) -> update uploadProgress object in context
    // set it as mapped and set mappedId
    else if (upload && !upload.mapped) {
      dispatch(
        videoUploadActions.updateUpload({
          upload: {
            ...upload,
            type: 'engagement-question',
            parent: companyEngagement.quiz.id,
            position: np.current,
            success: true,
            message:
              'Engagement Question saved. Waiting for upload to complete...',
            progress: 100,
            completed: false,
            mapped: true,
            mappedId: selectedQuestion?.id,
          },
        })
      );
    }
  };

  return (
    <Modal
      id='add-engagement-message-modal'
      show={show}
      title={`${create ? 'Add' : 'Update'} Message`}
      submitButtonText={`${create ? 'Add' : 'Update'} Message`}
      submitButtonType='success'
      onSubmit={sumbitHandler}
      onClose={onClose}
      expandable
      submitValid={isFormValid}
      modalClass='modal--manage-message'
    >
      <EditorSection
        questionEditorState={questionEditorState}
        setQuestionEditorState={setQuestionEditorState}
        setImageIds={setImageIds}
        create={create}
        selectedQuestion={selectedQuestion}
        isReadOnly={isReadOnly}
      />

      <VideoSection
        video={video}
        setVideo={setVideo}
        companyEngagement={companyEngagement}
        selectedQuestion={selectedQuestion}
        nextQuestionPosition={nextQuestionPosition}
        videoUploadCompletenessChecked={videoUploadCompletenessChecked}
        questionEditorState={questionEditorState}
        isReadOnly={isReadOnly}
      />

      <AnswerTypeSection
        companyEngagement={companyEngagement}
        answers={answers}
        setAnswers={setAnswers}
        answerType={answerType}
        setAnswerType={setAnswerType}
        externalUrl={externalUrl}
        setExternalUrl={setExternalUrl}
        externalUrlBtnText={externalUrlBtnText}
        setExternalUrlBtnText={setExternalUrlBtnText}
        create={create}
        isSingleSelect={isSingleSelect}
        setIsSingleSelect={setIsSingleSelect}
        isReadOnly={isReadOnly}
      />
    </Modal>
  );
};

export default EngagementMessageModal;
