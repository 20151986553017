import { useEffect, useState } from 'react';
import { useAppContext } from '../../../libs/contextLib';

import Note from '../../Note';
import RemoveExternalAppConfirmModal from './RemoveExternalAppConfirmModal';

import {
  useExternalAppOAuthLink,
  useRemoveExternalAppSettings,
  useUpdateExternalAppSettings,
} from '../../../hooks/data-hooks/integrations';

const GoogleIntegration = ({ activeGoogleApp, isLoadingExternalApps }) => {
  const { isAuthenticated } = useAppContext();
  const [syncEmployees, setSyncEmployees] = useState(undefined);
  const [changedWarning, setChangeWarning] = useState(false);
  const [removeAppWarning, setRemoveAppWarning] = useState(false);

  useEffect(() => {
    if (!isLoadingExternalApps && !!activeGoogleApp) {
      setSyncEmployees(activeGoogleApp.sync_employees);
    }
  }, [activeGoogleApp, isLoadingExternalApps]);

  const onOAuthLinkFetched = (googleOAuthLink) => {
    window.open(googleOAuthLink, '_self');
  };

  const { refetch } = useExternalAppOAuthLink({
    companyID: isAuthenticated.company,
    integration: 'google',
    enabled: false,
    onSuccess: onOAuthLinkFetched,
  });

  const { mutate: updateGoogleInstallation, isLoading: submittingSettings } =
    useUpdateExternalAppSettings({
      resourcetype: 'GoogleInstallation',
      companyID: isAuthenticated.company,
    });

  const { mutate: removeGoogleInstallation, isLoading: removingGoogleApp } =
    useRemoveExternalAppSettings({
      resourcetype: 'GoogleInstallation',
      integration: 'docebo',
      companyID: isAuthenticated.company,
    });

  const syncEmployeesHandler = () => {
    setSyncEmployees((s) => !s);
    setChangeWarning(true);
  };

  const updateDoceboInstallationHandler = () => {
    updateGoogleInstallation({
      companyID: isAuthenticated.company,
      integrationID: activeGoogleApp.id,
      settings: {
        sync_employees: syncEmployees,
      },
    });
    setChangeWarning(false);
  };

  const removeGoogleInstallationHandler = () => {
    removeGoogleInstallation({
      companyID: isAuthenticated.company,
      integrationID: activeGoogleApp.id,
    });
    setRemoveAppWarning(false);
  };

  return (
    <section className='p-2 mb-4 bg-white shadow-light rounded-xl col-span-full'>
      <h3 className='mb-1 setting__title'>Google Integration</h3>

      {!activeGoogleApp && (
        <section className='w-full md:w-max-content mt-2 flex flex-col rounded-md border border-hka_gray-light p-1.5 min-w-20 relative'>
          <div className='h-4 mb-3 overflow-hidden'>
            <img
              src='https://haekka-product-assets.s3.us-west-2.amazonaws.com/logo--google.svg'
              alt='Google Logo'
              className='w-4'
            />
          </div>
          <button
            className='w-full button button--save'
            onClick={() => refetch()}
          >
            Link with Google
          </button>
        </section>
      )}

      {!!activeGoogleApp && (
        <section>
          <div className='h-4 mb-3 overflow-hidden'>
            <img
              src='https://haekka-product-assets.s3.us-west-2.amazonaws.com/logo--google.svg'
              alt='Google Logo'
              className='w-4'
            />
          </div>

          <section className='flex w-full items-start justify-start mt-1 mb-2'>
            <p>
              <span className='font-basis-bold'>
                Your Google Workspace is now connected.
              </span>
            </p>
          </section>

          <section className='flex mt-3 mb-2 space-x-3'>
            <label className='switch'>
              <input
                type='checkbox'
                name='new_users_onboarding'
                checked={syncEmployees}
                onChange={syncEmployeesHandler}
              />
              <span className='slider'></span>
            </label>
            <div
              className='flex-1 cursor-pointer'
              onClick={syncEmployeesHandler}
            >
              <h4 className='mb-0.5'>Sync Google Workspace accounts</h4>
              <p>
                <strong>On</strong> by default, Haekka will automatically sync
                users from your Google Workspace account on daily basis.
              </p>
            </div>
          </section>

          {changedWarning && (
            <section className='mb-2'>
              <Note type='warning'>
                <p className='py-0.2'>
                  Your changes have not been saved. Please click the save button
                  below.
                </p>
              </Note>
            </section>
          )}

          <div className='flex flex-col items-center justify-start w-full gap-1 md:flex-row'>
            <button
              className={`w-full button button--save md:w-auto`}
              onClick={() => refetch()}
            >
              Reinstall Google App
            </button>

            <button
              className={`w-full button button--remove md:w-auto`}
              onClick={() => setRemoveAppWarning(true)}
              type='danger'
              disabled={removingGoogleApp}
            >
              Disconnect Google
            </button>

            <button
              className={`w-full button button--save md:w-auto`}
              onClick={updateDoceboInstallationHandler}
              type='success'
              disabled={submittingSettings}
            >
              Save
            </button>
          </div>

          {removeAppWarning && (
            <RemoveExternalAppConfirmModal
              onSubmit={removeGoogleInstallationHandler}
              onClose={() => setRemoveAppWarning(false)}
              integrationCaption='Google'
            />
          )}
        </section>
      )}
    </section>
  );
};

export default GoogleIntegration;
