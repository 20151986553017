import Select from "react-select";
import ReactDatePicker from "react-datepicker";

import {
  formatNoteDate,
  frequencyOptions,
  getCurrentDateWithLastHourSet,
  getMinTime,
  roundSendTimeTo30MinutesTop,
} from "../../../utils/engagementsUtils";

const ScheduleSection = ({
  frequency,
  setFrequency,
  dynamicStartDate,
  startDate,
  setStartDate,
  respectUsersTz,
  setRespectUsersTz,
  engagementType,
  isReadOnly,
}) => {
  const selectedDate = () => {
    if (!startDate) {
      return new Date();
    }
    if (respectUsersTz) {
      new Date(dynamicStartDate);
    }
    return new Date(startDate);
  };

  return (
    <section className="flex flex-col justify-between">
      <div className="w-full">
        <h4 className="mb-0.2">
          Send Schedule{" "}
          <span className="text-sm text-hka_gray">(Required)</span>
        </h4>
        <p className="text-sm">
          Set the frequency, date, and time to send this Engagement.
        </p>
      </div>

      <div className="flex flex-col items-center justify-between w-full gap-1 my-1">
        <div className="section--send-frequency">
          <p>Send Frequency</p>
          <div className="relative w-full md:w-2/5">
            <Select
              onChange={(option) => setFrequency(option)}
              value={frequency}
              options={frequencyOptions}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              classNamePrefix="select"
              unstyled
              menuPortalTarget={document.body}
              maxMenuHeight="17rem"
              menuPlacement="auto"
              isDisabled={isReadOnly}
            />
          </div>
        </div>

        {/* <p className='justify-center hidden w-3 font-basis-bold sm:flex'>on</p> */}

        <div className="section--send-date">
          <p>
            Send Date <span className="text-sm">(Engagement kick-off)</span>
          </p>
          <div className="w-full md:w-2/5">
            <ReactDatePicker
              minDate={new Date()}
              className={`datepicker`}
              selected={selectedDate()}
              onChange={(date) => setStartDate(date.toISOString())}
              dateFormat="PP"
              popperPlacement="top-center"
              disabled={isReadOnly}
            />
          </div>
        </div>

        {/* <p className='justify-center hidden w-3 font-basis-bold sm:flex'>at</p> */}

        <div className="section--send-time">
          <p>Send Time</p>
          <div className="w-full md:w-2/5 timepicker">
            <ReactDatePicker
              selected={selectedDate()}
              onChange={(date) => setStartDate(date.toISOString())}
              className={`datepicker`}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="h:mm aa"
              popperPlacement="top-center"
              minTime={getMinTime(selectedDate())}
              maxTime={getCurrentDateWithLastHourSet()}
              onBlur={() =>
                setStartDate(roundSendTimeTo30MinutesTop(selectedDate()))
              }
              disabled={isReadOnly}
            />
          </div>
        </div>
      </div>

      {engagementType?.value === "private" && (
        <section className="flex items-center justify-between w-full pt-1 pb-2">
          <h4>Account for user time zones</h4>
          <label className="switch">
            <input
              type="checkbox"
              name="acknowledge_button"
              checked={respectUsersTz}
              onChange={(e) => setRespectUsersTz(e.target.checked)}
              className="cursor-pointer"
              disabled={isReadOnly}
            />
            <span className="slider"></span>
          </label>
        </section>
      )}

      <p className="text-sm italic">
        * This Engagement will be sent{" "}
        {formatNoteDate(roundSendTimeTo30MinutesTop(selectedDate()))}
        {respectUsersTz ? ", based on each user's time zone" : ""}.
      </p>
    </section>
  );
};

export default ScheduleSection;
