import { useState, useEffect } from 'react';

import {
  useCreateTrainingAssessment,
  useUpdateTrainingAssessment,
} from '../../hooks/data-hooks/assessments';
import { useAppContext } from '../../libs/contextLib';

import {
  assessmentRetakesOptions,
  getAssessmentPayload,
  initQuestion,
  validateAssessmentModalForm,
} from '../../utils/assessments';
import { CURRICULUM_ITEM_TYPES } from '../../utils/training';
import Modal from '../Modal/Modal';
import Divider from '../Base/Divider';
import TitleSection from './TitleSection';
import AssessmentQuestion from './AssessmentQuestion';
import QuestionTabs from './QuestionTabs';
import AssessmentSettings from './AssessmentSettings';
import { initializeQuestionEditorState } from '../../utils/engagementsUtils';

const AssessmentModal = ({
  show,
  create,
  assessment,
  curriculumContent,
  curriculumID,
  onClose,
}) => {
  const { isAuthenticated } = useAppContext();

  const [title, setTitle] = useState('');
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [lessonsToSkip, setLessonsToSkip] = useState([]);
  const [gradingThreshold, setGradingThreshold] = useState(0);
  const [retakes, setRetakes] = useState(undefined);
  const [sumbitValid, setSubmitValid] = useState(false);
  const [assessmentPosition, setAssessmentPosition] = useState(0);

  const isThisAssessmentTheLastItemInCurriculum = curriculumContent.every(
    (item) => item.position <= assessmentPosition
  );

  const trainingLessons = (curriculumContent ?? [])
    .filter((cc) => cc.type === CURRICULUM_ITEM_TYPES.LESSON)
    .map((l) => l.value);

  useEffect(() => {
    const initQuestions = async (questions) => {
      const qs = await Promise.all(
        questions.map(async (q) => {
          return {
            ...q,
            answers: q.answers.map((a) => ({
              ...a,
              explanation: a.explanation === '' ? null : a.explanation,
            })),
            editorState: await initializeQuestionEditorState(q),
            imageIDs: [],
          };
        })
      );
      setQuestions(qs.sort((a, b) => a.position - b.position));
      setSelectedQuestion(qs[0].position);
    };
    if (create) {
      setQuestions([initQuestion()]);
      setSelectedQuestion(0);
      const nextPosition =
        curriculumContent.reduce((previousValue, currentValue) => {
          if (currentValue.position > previousValue) {
            return currentValue.position;
          }
          return previousValue;
        }, 0) + 1;
      setAssessmentPosition(nextPosition);
      setRetakes(assessmentRetakesOptions[1]);
    } else {
      setTitle(assessment.assessment.title);
      initQuestions(assessment.assessment.questions);
      setLessonsToSkip(
        trainingLessons
          .filter((l) => assessment.lessons_to_skip.includes(l.id))
          .map((l) => ({ label: l.title, value: l.id }))
      );
      setGradingThreshold(assessment.passing_grade);
      setRetakes(
        assessmentRetakesOptions.find(
          (o) => o.value === assessment.retake_status
        )
      );
      setAssessmentPosition(assessment.position);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment, create, curriculumContent]);

  useEffect(() => {
    const isSubmitValid = validateAssessmentModalForm({
      title,
      questions,
      gradingThreshold,
      retakes,
      isThisAssessmentTheLastItemInCurriculum,
    });
    setSubmitValid(isSubmitValid);
  }, [
    title,
    questions,
    gradingThreshold,
    retakes,
    isThisAssessmentTheLastItemInCurriculum,
  ]);

  const submitModalCallback = () => onClose();

  const { mutate: createTrainingAssessment } = useCreateTrainingAssessment({
    curriculumID,
    cb: submitModalCallback,
  });
  const { mutate: updateTrainingAssessment } = useUpdateTrainingAssessment({
    curriculumID,
    cb: submitModalCallback,
  });

  const getTrainingCompanyAssessmentPayload = () => ({
    company: isAuthenticated.company,
    curriculum: curriculumID,
    passing_grade: gradingThreshold,
    retake_status: retakes.value,
    position: assessmentPosition,
    lessons_to_skip: lessonsToSkip.map((lesson) => lesson.value),
    created_by: isAuthenticated.id,
  });

  const formSumbitHandler = async () => {
    setSubmitValid(false);
    const assessmentPayload = await getAssessmentPayload({
      title,
      isAuthenticated,
      questions,
    });

    const trainingCompanyAssessment = getTrainingCompanyAssessmentPayload();
    if (create) {
      createTrainingAssessment({
        assessmentPayload,
        trainingCompanyAssessment,
      });
    } else {
      delete trainingCompanyAssessment.created_by;
      updateTrainingAssessment({
        assessmentID: assessment.assessment.id,
        assessmentPayload,
        trainingCompanyAssessmentID: assessment.id,
        trainingCompanyAssessment,
      });
    }
  };

  return (
    <Modal
      show={show}
      title={`${create ? 'Create' : 'Edit'} Quiz`}
      submitButtonText={`${create ? 'Create' : 'Update'} Quiz`}
      onClose={onClose}
      submitValid={sumbitValid}
      onSubmit={formSumbitHandler}
      expandable
      modalClass={`modal--training-quiz`}
    >
      <TitleSection title={title} setTitle={setTitle} />
      <Divider />
      <AssessmentSettings
        lessonsToSkip={lessonsToSkip}
        setLessonsToSkip={setLessonsToSkip}
        trainingLessons={trainingLessons.map((lesson) => ({
          label: lesson.title,
          value: lesson.id,
          position: lesson.position,
        }))}
        gradingThreshold={gradingThreshold}
        setGradingThreshold={setGradingThreshold}
        retakes={retakes}
        setRetakes={setRetakes}
        assessmentPosition={assessment?.position ?? 0}
        curriculumContent={curriculumContent}
        isThisAssessmentTheLastItemInCurriculum={
          isThisAssessmentTheLastItemInCurriculum
        }
        create={create}
      />
      <QuestionTabs
        questions={questions}
        setQuestions={setQuestions}
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        curriculumID={curriculumID}
      />

      {questions.map((question) => (
        <AssessmentQuestion
          key={question.position}
          question={question}
          selectedQuestion={selectedQuestion}
          setQuestions={setQuestions}
          curriculumID={curriculumID}
        />
      ))}
    </Modal>
  );
};

export default AssessmentModal;
