import { format } from "date-fns";
import Modal from "../../Modal/Modal";

export default function ConfirmSendNowModal({
  showModal,
  activeSchedule,
  onSubmit,
  onClose,
}) {
  const day = format(new Date(activeSchedule.send_time), "MM/dd/yyyy");
  const time = format(new Date(activeSchedule.send_time), "h:mm aa");

  return (
    <Modal
      show={showModal}
      title="Send Engagement Now"
      onSubmit={onSubmit}
      onClose={onClose}
      submitButtonType="primary"
      submitButtonText="Send Now"
      compact
    >
      <p>
        Are you sure you want to send now and skip currently scheduled time of{" "}
        {day} @ {time}.
      </p>
    </Modal>
  );
}
