import React from "react";

export default function TrainingTagButton({ tag, onClick, active }) {
  return (
    <span
      className={`py-0.4 px-1 text-sm rounded cursor-pointer whitespace-nowrap ${
        active
          ? "bg-orange-light text-orange border border-orange"
          : "bg-hka_gray-light text-hka_gray border border-hka_gray-border border-opacity-20"
      }`}
      onClick={onClick}
    >
      {tag.name}
    </span>
  );
}
