import { format } from "date-fns";
import React, { Fragment, useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { toast } from "react-hot-toast";
import { generatePhishingAttackReport } from "../../../api";
import { onError } from "../../../libs/errorLib";
import ActionButton from "../../ActionButton";
import Divider from "../../Base/Divider";
import Modal from "../../Modal/Modal";

function DownloadReportsModalListItem({
  auth,
  isFirst,
  isLast,
  attackId,
  templateName,
  startDate,
  endDate,
}) {
  const [downloading, setDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [report, setReport] = useState(null);

  const formatDate = (date) => format(date, "MM/dd/yy");

  const generateFileName = () => {
    const date = new Date();

    const dateFragment = format(date, "MM-dd-yy");
    const timeFragment = `${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;

    return `${dateFragment}-report-${timeFragment}.pdf`;
  };

  const resetState = () => {
    setDownloading(false);
    setDownloadProgress(0);
    setReport(null);
  };

  const calculateDownloadPercentage = (progressEvent) => {
    const { total, loaded } = progressEvent;

    const value = Math.round(Number(((loaded / total) * 100).toFixed(2)));

    setDownloadProgress(value);
  };

  const onDownloadReportClick = async (attackId, startDate, endDate) => {
    try {
      setDownloading(true);
      const { data } = await generatePhishingAttackReport({
        auth,
        attackId,
        startDate,
        endDate,
        onDownloadProgress: calculateDownloadPercentage,
      });

      setReport(data);
    } catch (error) {
      onError(error);
      resetState();
    }
  };

  useEffect(() => {
    const saveReport = () => {
      const blob = new Blob([report], {
        type: "pdf",
      });

      const link = document.createElement("a");

      link.href = window.URL.createObjectURL(blob);
      link.download = generateFileName();

      link.click();

      toast.success("Report Downloaded!");

      setTimeout(resetState, [2000]);
    };

    if (downloadProgress !== 100 || !report) {
      return;
    }

    saveReport();
  }, [downloadProgress, report]);

  return (
    <Fragment>
      {isFirst && <Divider />}
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <p className="text-md font-basis-medium">
            {formatDate(startDate)} - {formatDate(endDate)}
          </p>
          <p>{templateName}</p>
        </div>
        {!downloading && (
          <ActionButton
            onClick={() => onDownloadReportClick(attackId, startDate, endDate)}
            type="primary"
            customCss="px-0"
          >
            <span className="icon-download text-hka_blue"></span>
          </ActionButton>
        )}
      </div>

      {downloading && (
        <div className="mb-2">
          <ProgressBar
            completed={downloadProgress}
            isLabelVisible={false}
            width={"100%"}
            height={"100%"}
            borderRadius={"1rem"}
            labelClassName={"label--cert-download--transparent"}
            baseBgColor={"#EFF0F7"}
            bgColor={"#0094FF"}
          />
        </div>
      )}

      {!isLast && <Divider />}
    </Fragment>
  );
}

export default function DownloadReportsModal({
  auth,
  showModal,
  setShowModal,
  attacks,
}) {
  return (
    <Modal
      noFooter
      show={showModal}
      title="Download Reports"
      onClose={() => setShowModal(false)}
    >
      <div className="space-y-1 overflow-y-auto">
        {attacks
          .sort((a, b) => new Date(a.created) - new Date(b.created))
          .map((attack, i) => {
            const startDate = new Date(attack.created);
            const endDate = new Date(
              attacks[i + 1] ? attacks[i + 1].created : Date.now()
            );

            if (attacks[i + 1]) {
              endDate.setDate(endDate.getDate() - 1);
            }

            return (
              <DownloadReportsModalListItem
                key={i}
                auth={auth}
                isFirst={i === 0}
                isLast={i === attacks.length - 1}
                attackId={attack.id}
                startDate={startDate}
                endDate={endDate}
                templateName={attack.template.name}
              />
            );
          })}
      </div>
    </Modal>
  );
}
