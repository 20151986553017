import Select from 'react-select';

import { assessmentRetakesOptions } from '../../utils/assessments';
import { formatOptionLabel } from '../../utils/commonUtils';
import Divider from '../Base/Divider';
import Note from '../Note';

const AssessmentSettings = ({
  lessonsToSkip,
  setLessonsToSkip,
  trainingLessons,
  gradingThreshold,
  setGradingThreshold,
  retakes,
  setRetakes,
  assessmentPosition,
  isThisAssessmentTheLastItemInCurriculum,
  create,
}) => {
  const remainingLessonsFn = (create ? [] : trainingLessons).filter(
    (o) => o.position > assessmentPosition
  );

  const skipRemainingLessonsHandler = () => {
    setLessonsToSkip(remainingLessonsFn);
  };

  const gradingThresholdUpdateHandler = (e) => {
    if (e.target.value >= 0 && e.target.value <= 100) {
      setGradingThreshold(e.target.value);
    }
  };

  const formattedOption = isThisAssessmentTheLastItemInCurriculum
    ? assessmentRetakesOptions.map((o, i) =>
        i === 1 ? o : { ...o, isDisabled: true }
      )
    : assessmentRetakesOptions;

  return (
    <section className=''>
      <section className='flex flex-col justify-between py-2 sm:items-center sm:flex-row'>
        <div className='w-full mb-1 sm:mb-0 sm:w-4/5'>
          <h4 className=''>Grading Threshold</h4>
          <p className='text-sm'>
            Users must score above the grading threshold to skip the selected
            lessons(s).
          </p>
        </div>
        <div className='relative flex items-center sm:w-1/5'>
          <p className='mr-0.5'>%</p>
          <input
            type='number'
            autoComplete={'off'}
            onWheel={(event) => event.currentTarget.blur()}
            name='days_till_due'
            value={gradingThreshold}
            className='input--number'
            onChange={gradingThresholdUpdateHandler}
            placeholder='Set Threshold'
            min={0}
            max={100}
            step={5}
          />
        </div>
      </section>

      <Divider />

      {gradingThreshold > 0 && (
        <section className='pt-2 pb-1'>
          <div className='flex justify-between sm:items-center'>
            <div className='w-full sm:w-3/5'>
              <h4 className=''>Quiz Retakes</h4>
              <p className='text-sm'>Select a retake option for this quiz.</p>
            </div>
            <div className='relative flex flex-1 w-full gap-1 sm:w-2/5'>
              <Select
                onChange={(option) => setRetakes(option)}
                value={retakes}
                options={formattedOption}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                classNamePrefix='select'
                className='w-full'
                unstyled
                menuPortalTarget={document.body}
                maxMenuHeight='17rem'
                menuPlacement='auto'
                isSearchable={false}
                formatOptionLabel={formatOptionLabel}
              />
            </div>
          </div>
        </section>
      )}

      {gradingThreshold > 0 &&
        isThisAssessmentTheLastItemInCurriculum &&
        retakes.value !== 'force_retake' && (
          <Note type='warning'>
            <p className='py-0.2'>
              Quizzes without any subsequent lessons must have a{' '}
              <span className='font-basis-bold'>Grading Threshold of 0%</span>{' '}
              OR will need
              <span className='font-basis-bold'>
                {' '}
                Quiz Retakes set to Yes.
              </span>{' '}
            </p>
          </Note>
        )}

      <section className='pb-2'>
        <h4 className='mt-2 mb-1 font-basis-bold'>
          Skip lessons(s) if user passes this Quiz
        </h4>

        <Select
          options={remainingLessonsFn}
          onChange={setLessonsToSkip}
          value={lessonsToSkip}
          placeholder='Select lessons'
          isMulti={true}
          classNamePrefix='select'
          unstyled
          className='mb-0.5'
        />
        {!isThisAssessmentTheLastItemInCurriculum && (
          <p
            className='text-xs cursor-pointer text-hka_blue font-basis-bold'
            onClick={skipRemainingLessonsHandler}
          >
            Skip the Remaining Lessons
          </p>
        )}
        {isThisAssessmentTheLastItemInCurriculum && (
          <Note type='info'>
            <p className='py-0.2'>
              This quiz is currently at the end of the training, and has no
              subsequent lessons.
              <span className='font-basis-bold'>
                {' '}
                Only lessons placed after this quiz can be skipped.
              </span>
            </p>
          </Note>
        )}
      </section>
    </section>
  );
};

export default AssessmentSettings;
