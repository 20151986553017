import Modal from "../../Modal/Modal";
import Note from "../../Note";

export default function RemindEmployeesModal({
  showModal,
  onSendReminder,
  onClose,
  type,
}) {
  return (
    <Modal
      show={showModal}
      title={`Remind Employee${type === "ALL_USERS" ? "s" : ""}`}
      onSubmit={onSendReminder}
      onClose={onClose}
      submitButtonType="primary"
      submitButtonText="Send Reminder"
      compact
    >
      {type === "ALL_USERS" && (
        <Note type="info">
          <p>
            This global reminder will only be sent to employees who have{" "}
            <span className="font-basis-bold">
              not yet finished this engagement
            </span>
            .
          </p>
        </Note>
      )}
      {type === "SINGLE_USER" && (
        <p>Are you sure you want remind this employee?</p>
      )}
    </Modal>
  );
}
