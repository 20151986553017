import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteAssessment } from '../../../api/assessmentApi';

export const useDeleteTrainingAssessment = ({ curriculumID }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteAssessment,
    onSuccess: () =>
      queryClient.invalidateQueries(['curriculum-content', curriculumID]),
  });
};
