import ActionButton from "../ActionButton";
import PropTypes from "prop-types";
import ManualUsersSelect from "../AssignEmployees/ManualUsersSelect";
import ProgressBar from "@ramonak/react-progress-bar";
import { Fragment, useEffect, useState } from "react";
import * as api from "../../api";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import { format } from "date-fns";
import CsvOption from "./CsvOption";
import CopyUserFromAnotherTraining from "./CopyUserFromAnotherTraining";
import { useLocation } from "react-router-dom";

const SelectEmployeesManually = ({
  assignEmployeesContext,
  isMulti = true,
  handleEventExternal = () => {},
  csvOption = true,
}) => {
  const {
    employees,
    employeesImportProgress,
    setManuallySelectedEmployees,
    manuallySelectedEmployees,
    syncInProgress,
    setSyncInProgress,
    setObject,
    manualUsersError,
  } = assignEmployeesContext;

  const { isAuthenticated } = useAppContext();
  const { pathname } = useLocation();

  const [lastSync, setLastSync] = useState(undefined);

  useEffect(() => {
    const getLastSyncTime = () => {
      return api.checkLastEmployeesSync({
        auth: isAuthenticated,
      });
    };

    async function onLoad() {
      try {
        const response = await getLastSyncTime();
        if (response.last_employees_sync) {
          const last_slack_sync = new Date(response.last_employees_sync);
          setLastSync(
            `${format(last_slack_sync, "h:mm b")} on ${format(
              last_slack_sync,
              "eeee MMMM do yyyy"
            )}`
          );
        }
      } catch (e) {
        onError(e);
      }
    }
    onLoad();
  }, [isAuthenticated]);

  const onSyncEmployees = async () => {
    await api.syncEmployees({
      auth: isAuthenticated,
    });
    setSyncInProgress(true);
  };

  return (
    <section className="mb-2">
      <ManualUsersSelect
        isMulti={isMulti}
        defaultOptions={manuallySelectedEmployees}
        onChange={(e) => {
          if (isMulti) {
            setManuallySelectedEmployees([...e]);
          } else {
            setManuallySelectedEmployees([e]);
            setObject(e);
          }
          handleEventExternal(e);
        }}
        options={employees}
        isDisabled={+employeesImportProgress !== 100}
        labelText={
          +employeesImportProgress !== 100 ? "Loading your users..." : undefined
        }
      />
      {manualUsersError && (
        <p className="text-xs text-hka_red">{manualUsersError}</p>
      )}
      {+employeesImportProgress !== 100 && (
        <ProgressBar
          completed={employeesImportProgress}
          isLabelVisible={false}
          width={"100%"}
          // labelAlignment={'center'}
          // customLabel={'Progress'}
          height={"0.5rem"}
          borderRadius={"0.25rem"}
          baseBgColor={"#EFF0F7"}
          bgColor={"#F99A00"}
          className="mt-1"
        />
      )}
      {lastSync && (
        <Fragment>
          <div className="flex flex-col items-center mt-1 sm:flex-row">
            <p className="flex-1 mb-1 text-sm md:mb-0 md:pr-2">
              Don't see the user(s) you're looking for? Your account{" "}
              {lastSync ? "was last synced " : "hasn't been synced"}{" "}
              <span className="font-basis-bold">
                {lastSync ? `at ${lastSync}` : ""}
              </span>
              .
            </p>
            <ActionButton
              type="defaultNoIcon"
              onClick={onSyncEmployees}
              disabled={syncInProgress}
            >
              Sync Accounts
            </ActionButton>
          </div>
          {syncInProgress && (
            <p className="px-1 py-0.5 border rounded-lg bg-hka_gray-light border-hka_gray-border border-opacity-20">
              We're syncing your accounts now. We'll send you a Slack
              notification shortly. Keep an eye out for it!
            </p>
          )}
        </Fragment>
      )}

      {csvOption && pathname.includes("training") && (
        <CopyUserFromAnotherTraining
          assignEmployeesContext={assignEmployeesContext}
        />
      )}

      {csvOption && (
        <CsvOption assignEmployeesContext={assignEmployeesContext} />
      )}
    </section>
  );
};

SelectEmployeesManually.propTypes = {
  isMulti: PropTypes.bool,
  handleEventExternal: PropTypes.func,
  assignEmployeesContext: PropTypes.object.isRequired,
};

export default SelectEmployeesManually;
