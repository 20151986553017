import api from '../client';

// Assessments

export const createAssessment = async (payload) => {
  const { data } = await api.post(`/api/assessments/`, payload);
  return data;
};

export const updateAssessment = async ({ assessmentID, payload }) => {
  const { data } = await api.patch(`/api/assessments/${assessmentID}`, payload);
  return data;
};

export const deleteAssessment = async ({ assessmentID }) => {
  await api.delete(`/api/assessments/company/${assessmentID}`);
};

// Assessment Question
export const deleteAssessmentQuestion = async ({ questionID }) => {
  const { data } = await api.delete(`/api/questions/${questionID}/assessment`);
  return data;
};

// Assement Answer

export const deleteAssessmentAnswer = async ({ answerID }) => {
  await api.delete(`/api/answers/${answerID}/assessment`);
};

// Training Company Assessments

export const createTrainingCompanyAssessment = async (payload) => {
  const { data } = await api.post(`/api/assessments/company`, payload);
  return data;
};

export const updateTrainingCompanyAssessment = async ({
  trainingCompanyAssessmentID,
  payload,
}) => {
  const { data } = await api.patch(
    `/api/assessments/company/${trainingCompanyAssessmentID}`,
    payload
  );
  return data;
};
