import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw,
  Modifier,
  SelectionState,
  RichUtils,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { onError } from '../libs/errorLib';

export async function convertToSlackNotation(contentState, fetchedLesson) {
  let text = draftToHtml(convertToRaw(contentState));
  text = text.replaceAll('&nbsp;', '');
  text = removeDeletedImageData('<p>', '</p>', text);
  text = removeDeletedImageData('<li>', '</li>', text);
  text = handleTextStyle(text);
  // text = handleBlockquote(text);
  text = handleImage(text, fetchedLesson);
  text = handleUrl(text);
  // text = handleCodeBlock(text);
  text = await handleEmojis(text);
  if (text.includes('<ul>') || text.includes('<ol>')) {
    text = handleList(text, '<ul>');
    text = handleList(text, '<ol>');
  }
  text = cleanUp(text);

  // handle special characters
  text = text
    .replaceAll('&amp;', '&')
    .replaceAll('&lt;', '< ')
    .replaceAll('&gt;', ' >');

  return text;
}

// When one remove an image by placing the cursor infront of the image (previous row) and pressing the Delete button
// an image will be removed from the UI, but will not be removed from the EditorState
// to be precise, this is what is happening:
// 1. block is removed from the EditorState
// 2. image data is not removed from the EditorState.entityMap
// 3. HTML conversion will result in the pulling the image in the previous tag (example: <p>This is a paragraph <img src=''/></p>)
// We're using converted HTML to conver it further to the Slack friendly markup and it results is this: This is a paragraph img:xyz.com<br/><br/>
// This function will normalize it and remove extra image data
const removeDeletedImageData = (openingTag, closingTag, text) => {
  let loop = true;
  let startIndex = 0;
  let endIndex = 0;

  while (loop) {
    if (!text.includes(openingTag)) {
      loop = false;
      return text;
    }
    startIndex = text.indexOf(openingTag, startIndex);
    endIndex = text.indexOf(closingTag, startIndex) + closingTag.length;
    let fractal = text.substring(startIndex, endIndex);
    if (fractal.includes('<img')) {
      // locate the entire img tag and replace it with the empty string in fractal
      const imgStartIndex = fractal.indexOf('<img');
      const imgEndIndex = fractal.indexOf('/>') + 2;
      fractal = fractal.replace(
        fractal.substring(imgStartIndex, imgEndIndex),
        ''
      );
      // replace the original piece of text with fractal
      text = text.replace(text.substring(startIndex, endIndex), fractal);
      // reset search
      startIndex = 0;
      endIndex = 0;
      continue;
    } else {
      if (!text.substring(endIndex, text.length).includes(openingTag))
        loop = false;
      startIndex = endIndex;
      startIndex = text.indexOf(openingTag, startIndex);
      endIndex = text.indexOf(closingTag, startIndex) + closingTag.length;
      // if the rest of text does not include opening tag, finish the search
    }
  }
  return text;
};

const handleTextStyle = (text) => {
  text = text
    .replaceAll('<p>', '')
    .replaceAll('</p>', '<br>')
    //
    .replaceAll('</strong>', '</strong> ')
    .replaceAll(' </strong> ', '* ')
    .replaceAll(' </strong>', '*')
    .replaceAll('</strong>', '*')
    //
    .replaceAll('<strong>', ' <strong>')
    .replaceAll(' <strong> ', ' *')
    .replaceAll('<strong> ', '*')
    .replaceAll('<strong>', '*')
    //
    .replaceAll('</em>', '</em> ')
    .replaceAll(' </em> ', '_ ')
    .replaceAll(' </em>', '_')
    .replaceAll('</em>', '_')
    //
    .replaceAll('<em>', ' <em>')
    .replaceAll(' <em> ', ' _')
    .replaceAll('<em> ', '_')
    .replaceAll('<em>', '_')
    //
    .replaceAll('</code>', '</code> ')
    .replaceAll(' </code> ', '` ')
    .replaceAll(' </code>', '`')
    .replaceAll('</code>', '`')
    //
    .replaceAll('<code> ', ' <code>')
    .replaceAll(' <code> ', ' `')
    .replaceAll('<code> ', '`')
    .replaceAll('<code>', '`')
    //
    .replaceAll('</del>', '</del> ')
    .replaceAll(' </del> ', '~ ')
    .replaceAll(' </del>', '~')
    .replaceAll('</del>', '~')
    //
    .replaceAll('<del>', ' <del>')
    .replaceAll(' <del> ', ' ~')
    .replaceAll('<del> ', '~')
    .replaceAll('<del>', '~')
    //

    .replaceAll('* _', '*_')
    .replaceAll('_ *', '_*')
    // handle spaces beetween multiple styling
    .replaceAll('* ~', '*~')
    .replaceAll('~ *', '~*')

    .replaceAll('* `', '*`')
    .replaceAll('` *', '`*')

    .replaceAll('_ ~', '_~')
    .replaceAll('~ _', '~_')

    .replaceAll('_ `', '_`')
    .replaceAll('` _', '`_')

    .replaceAll('~ `', '~`')
    .replaceAll('` ~', '`~')

    // merge neighburs inline code
    .replaceAll('` `', ' ')
    .replaceAll('`  `', ' ')

    //
    .replaceAll('~_', '~_PLACEHOLDER')
    .replaceAll('_~', '~_')
    .replaceAll('~_PLACEHOLDER', '_~')

    // already done by Draft.js
    // .replaceAll('&', '&amp')
    // .replaceAll('<', '&lt')
    // .replaceAll('>', '&gt')

    .replaceAll('  ', ' ')
    .replaceAll('__', '')
    .replaceAll('**', '')
    .replaceAll('~~', '');
  return text;
};

// const handleBlockquote = (text) => {
//   text = text
//     .replaceAll('<blockquote>', '>')
//     .replaceAll('</blockquote>\n', '\n');
//   return text;
// };

const handleImage = (text, fetchedLesson) => {
  const images = fetchedLesson?.editor_content?.content;

  let firstPartOfText = '';
  let lastPartOfText = '';
  while (text.indexOf('<img') >= 0) {
    const startIndex = text.indexOf('<img');
    const endIndex = text.indexOf('/>') + 2;
    firstPartOfText = text.substring(0, startIndex);
    let temp = text.substring(startIndex, text.length);
    let tempInd = temp.indexOf('/>') + 2;
    let image = temp.substring(0, tempInd);
    lastPartOfText = text.substring(endIndex, text.length);
    image = image.replace('<img ', 'img:');
    image = image.replace('/>', '');

    // locate url
    let urlStartIndex = image.indexOf('"') + 1;
    let temp2 = image.substring(urlStartIndex, image.length);
    let urlEndIndex = temp2.indexOf('"');
    let src = temp2.substring(0, urlEndIndex);
    if (!src.startsWith('https')) src = src.replace('http', 'https');

    let image_name = src.substring(0, src.lastIndexOf('?'));
    // if image is not signed
    if (image_name.length === 0) {
      image_name = src;
    }
    image_name = image_name.substring(
      src.lastIndexOf('/') + 1,
      image_name.length
    );

    const imageNameWithoutExt = image_name.substring(
      0,
      image_name.indexOf('.')
    );
    (images ?? []).forEach((element) => {
      if (
        element.image_name.startsWith(imageNameWithoutExt) &&
        image_name.length < element.image_name.length
      ) {
        image_name = element.image_name;
      }
    });

    text = firstPartOfText + ' img:[' + image_name + ']<br>' + lastPartOfText;
  }
  return text;
};

const handleUrl = (text) => {
  let firstPartOfText = '';
  let lastPartOfText = '';
  while (text.indexOf('<a') >= 0) {
    const startIndex = text.indexOf('<a');
    const endIndex = text.indexOf('</a>') + 4;
    firstPartOfText = text.substring(0, startIndex);
    lastPartOfText = text.substring(endIndex, text.length);

    let temp = text.substring(startIndex, endIndex);
    temp = temp.replace('<a ', '');
    temp = temp.replace('</a> ', '');

    //locate text
    let txStartIndex = temp.indexOf('>') + 1;
    let txEndIndex = temp.indexOf('<');
    let tx = temp.substring(txStartIndex, txEndIndex);

    // locate url
    let urlStartIndex = temp.indexOf('"') + 1;
    let tempUrl = temp.substring(urlStartIndex, temp.length);
    let urlEndIndex = tempUrl.indexOf('"');
    let src = tempUrl.substring(0, urlEndIndex);
    if (!src.startsWith('https')) src = src.replace('http', 'https');

    const partial = '<link:' + src + '|' + tx + '>';

    text = firstPartOfText + partial + lastPartOfText;
  }
  return text;
};

// const handleCodeBlock = text => {
//   let firstPartOfText = '';
//   let lastPartOfText = '';
//   while (text.indexOf('<pre>') >= 0) {
//     // text = text.replaceAll('\n', '');
//     const startIndex = text.indexOf('<pre>');
//     firstPartOfText = text.substring(0, startIndex);

//     let temp = text.substring(startIndex, text.length);
//     // let temp2 = temp.replaceAll('</pre><pre>', '+');
//     let temp2 = temp.replaceAll('</pre>\n<pre>', '\n');
//     const endIndex = temp2.indexOf('</pre>') + 6;
//     lastPartOfText = temp2.substring(endIndex, temp2.length);
//     let temp3 = temp2.substring(0, endIndex);
//     temp3 = temp3.replace('<pre>', ' ```');
//     temp3 = temp3.replace('</pre>', '``` ');

//     text = firstPartOfText + temp3 + lastPartOfText;
//   }
//   return text
// }

const handleEmojis = async (text) => {
  let emojisInText =
    text.match(
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g
    ) || [];
  emojisInText = emojisInText.filter((e) => e !== '’');
  const unifiedCodes = [];
  const fetchedEmojiJson = JSON.parse(localStorage.getItem('haekka-emojis'));
  if (emojisInText.length > 0) {
    emojisInText.forEach((e) => {
      unifiedCodes.push(e.codePointAt(0).toString(16));
    });
  }

  if (unifiedCodes.length > 0) {
    unifiedCodes.forEach((uc) => {
      const emoji = fetchedEmojiJson.filter((e) => {
        return e.unicode.toUpperCase().includes(uc.toUpperCase());
      });

      if (emoji[0]) {
        text = text.replaceAll(
          String.fromCodePoint('0x' + uc),
          ':' + emoji[0].name + ':'
        );
      } else {
        text = text.replaceAll(String.fromCodePoint('0x' + uc), '');
      }
    });
  }
  return text;
};

function getPosition(string, subString, index) {
  return string.split(subString, index).join(subString).length;
}

const handleList = (text, listType) => {
  let firstPartOfText = '';
  let lastPartOfText = '';
  let cont = 0;

  while (text.indexOf(listType) >= 0) {
    let counter = 1;

    let startIndex = getPosition(
      text,
      listType === '<ul>' ? '<ul>' : '<ol>',
      counter
    );
    let endIndex =
      getPosition(text, listType === '<ul>' ? '</ul>' : '</ol>', 1) + 5;

    let ul = text.substring(startIndex, endIndex);
    let hasNestedList = ul.substring(4, ul.length).includes(listType);

    while (hasNestedList) {
      counter++;
      if (counter > cont) cont = counter;

      startIndex = getPosition(
        text,
        listType === '<ul>' ? '<ul>' : '<ol>',
        counter
      );
      endIndex =
        getPosition(text, listType === '<ul>' ? '</ul>' : '</ol>', 1) + 5;
      ul = text.substring(startIndex, endIndex);
      hasNestedList = ul.substring(4, ul.length).includes(listType);
      ul = ul.replace(
        listType === '<ul>' ? '<ul>' : '<ol>',
        `LEVEL_${counter}`
      );
      ul = ul.replace(
        listType === '<ul>' ? '</ul>' : '</ol>',
        `LEVEL_${counter}_END`
      );
    }

    if (counter !== 1) {
      firstPartOfText = text.substring(0, startIndex);
      lastPartOfText = text.substring(endIndex, text.length);
      text = firstPartOfText + ul + lastPartOfText;
    } else {
      text = text.replace(listType === '<ul>' ? '<ul>' : '<ol>', `LEVEL_${1}`);
      text = text.replace(
        listType === '<ul>' ? '</ul>' : '</ol>',
        `LEVEL_${1}_END`
      );
      text = formJsObjectFromList(text, cont, listType);
      cont = 0;
    }
  }

  return text;
};

var updateMainJsObject = function (mainJsObject, nestedList, tempJsObject) {
  mainJsObject.forEach((m, i) => {
    if (m.hasOwnProperty('nest')) {
      if (m.nest === nestedList.name) {
        m.nest = [...tempJsObject.mainJsObject];
      } else if (typeof m.nest === 'object') {
        updateMainJsObject([...m.nest], nestedList, tempJsObject);
      }
      return;
    }
  });
  return mainJsObject;
};

const formJsObjectFromList = (text, levels, listType) => {
  // eslint-disable-next-line no-self-assign
  levels === 0 ? (levels = 1) : (levels = levels);
  const startIndex = text.indexOf('LEVEL_1');
  const endIndex = text.indexOf('LEVEL_1_END') + 11;
  const firstPartOfText = text.substring(0, startIndex);
  const lastPartOfText = text.substring(endIndex, text.length);
  let list = text.substring(startIndex, endIndex);
  list = list.replace(/\n|\r/g, '');
  let nestedLists = [];
  let nestedLists2 = [];
  let mainJsObject = [];

  if (levels === 1) {
    list = list.replace(`LEVEL_${1}`, '');
    list = list.replace(`LEVEL_${1}_END`, '');
    list = list
      .replaceAll('<li>', '')
      .replaceAll('</li>', 'MY_PLACEHOLDER_TEMP');
    list = list.substring(0, list.length - 1);
    const arr = list.split('MY_PLACEHOLDER_TEMP');
    arr.forEach((item) => {
      mainJsObject.push({ item: item.replace('MY_PLACEHOLDER_TEM', '') });
    });
  } else {
    for (let i = 1; i <= levels; i++) {
      list = list.replace(`LEVEL_${i}`, '');
      list = list.replace(`LEVEL_${i}_END`, '');
      if (nestedLists.length === 0) {
        const jsObject = getJSObject(list, i);
        mainJsObject = [...jsObject.mainJsObject];
        nestedLists = [...jsObject.nestedLists];
      } else {
        nestedLists2 = [];
        // eslint-disable-next-line no-loop-func
        nestedLists.forEach((nl) => {
          nl.list = nl.list.replace(`LEVEL_${i}`, '');
          nl.list = nl.list.replace(`LEVEL_${i}_END`, '');
          const tempJsObject = getJSObject(nl.list, i, i === levels);
          nestedLists2 = [...nestedLists2, ...tempJsObject.nestedLists];

          const updatedJsObject = updateMainJsObject(mainJsObject, nl, {
            mainJsObject: [...tempJsObject.mainJsObject],
          });
          mainJsObject = updatedJsObject;
        });
        nestedLists = [...nestedLists2];
      }
    }
  }

  return (
    firstPartOfText +
    `<${listType === '<ul>' ? 'ulist' : 'olist'}|${JSON.stringify(
      mainJsObject
    )}><br>` +
    lastPartOfText
  );
};

const getJSObject = (list, i, lastRound) => {
  const nestedLists = [];
  const mainJsObject = [];
  let c = 0;
  while (list.indexOf(`LEVEL_${i + 1}`) >= 0) {
    c++;
    const startIndexSub = list.indexOf(`LEVEL_${i + 1}`);
    const endIndexSub = list.indexOf(`LEVEL_${i + 1}_END`) + 11;

    const rnd = `NESTED_${i + 1}_${c + Math.random()}`;
    let sublist = list.substring(startIndexSub, endIndexSub);
    nestedLists.push({ name: rnd, list: sublist });
    list = list.replaceAll(sublist, `${rnd};`);
    list = list.replaceAll('<li>', '').replaceAll('</li>', ';');
    list = list.substring(0, list.length - 1);
  }

  const arr = list.split(';');

  if (lastRound) {
    list = list.replace(`LEVEL_${1}`, '');
    list = list.replace(`LEVEL_${1}_END`, '');
    list = list.replaceAll('<li>', '').replaceAll('</li>', ';');
    list = list.substring(0, list.length - 1);
    const arr = list.split(';');
    arr.forEach((item, i) => {
      mainJsObject.push({ item });
    });
  } else {
    arr.forEach((item, i) => {
      if (arr[i + 1]?.startsWith('NESTED')) {
        mainJsObject.push({
          item: item.replace('<li>', '').replace('</li>', ''),
          nest: arr[i + 1],
        });
      } else if (item && !item.startsWith('NESTED')) {
        mainJsObject.push({
          item: item.replace('<li>', '').replace('</li>', ''),
        });
      }
    });
  }
  return { mainJsObject, nestedLists };
};

export async function getEditorState(
  rawState,
  content_holder1,
  slackMarkup,
  createNewState
) {
  if (createNewState) {
    return EditorState.createEmpty();
  } else if (rawState) {
    try {
      const jsObjectState = JSON.parse(rawState);
      const content_holder = content_holder1.content;
      for (const property in jsObjectState.entityMap) {
        if (jsObjectState.entityMap[property].type === 'IMAGE') {
          const link = jsObjectState.entityMap[property].data.src; // full link and need to be replaced
          let imageName = link.substring(0, link.lastIndexOf('?'));
          if (imageName.length !== 0) {
            imageName = imageName.substring(
              link.lastIndexOf('/') + 1,
              imageName.length
            );
            const url = content_holder.find((el) =>
              el.image_name.startsWith(
                imageName.substring(0, imageName.lastIndexOf('.'))
              )
            )?.image_url;
            jsObjectState.entityMap[property].data.src = url;
          } else {
            // if URL is not signed
            jsObjectState.entityMap[property].data.src = link;
          }
        }
      }

      return EditorState.createWithContent(convertFromRaw(jsObjectState));
    } catch (e) {
      onError(e);
    }
  } else if (slackMarkup) {
    const html = await convertToHTML(slackMarkup);
    const blocksFromHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(state);
  }
  return EditorState.createEmpty();
}

const cleanUp = (text) => {
  // remove <br> for the empty paragraphs
  text = text.replaceAll('<br>\n<br>', '<br>');
  text = text.replaceAll('<br>\r<br>', '<br>');

  return text;
};

export const convertToHTML = async (slackMarkup) => {
  const { slackMarkup: sm, emojis } = protectEmojis(slackMarkup);
  slackMarkup = sm;
  slackMarkup = convertToHTML_images(slackMarkup);
  slackMarkup = convertToHTML_BoldText(slackMarkup);
  slackMarkup = convertToHTML_ItalicText(slackMarkup);
  slackMarkup = convertToHTML_CodeText(slackMarkup);
  slackMarkup = convertToHTML_StrikeText(slackMarkup);
  slackMarkup = convertToHTML_OrderedList(slackMarkup);
  slackMarkup = convertToHTML_UnorderedList(slackMarkup);
  slackMarkup = await convertToHTML_Emojis(slackMarkup, emojis);
  slackMarkup = normalizeLink(slackMarkup);

  return slackMarkup;
};

const convertToHTML_BoldText = (slackMarkup) => {
  while (slackMarkup.indexOf('*') >= 0) {
    const openingTagIndex = slackMarkup.indexOf('*');
    let firstpartOfString = slackMarkup.substring(0, openingTagIndex + 1);
    firstpartOfString = firstpartOfString.replace('*', '<b>');
    let secondPartOfString = slackMarkup.substring(
      openingTagIndex + 1,
      slackMarkup.length
    );
    secondPartOfString = secondPartOfString.replace('*', '</b>');
    slackMarkup = firstpartOfString + secondPartOfString;
  }
  return slackMarkup;
};

const convertToHTML_ItalicText = (slackMarkup) => {
  while (slackMarkup.indexOf('_') >= 0) {
    const openingTagIndex = slackMarkup.indexOf('_');
    let firstpartOfString = slackMarkup.substring(0, openingTagIndex + 1);
    firstpartOfString = firstpartOfString.replace('_', '<i>');
    let secondPartOfString = slackMarkup.substring(
      openingTagIndex + 1,
      slackMarkup.length
    );
    secondPartOfString = secondPartOfString.replace('_', '</i>');
    slackMarkup = firstpartOfString + secondPartOfString;
  }
  return slackMarkup;
};

const convertToHTML_CodeText = (slackMarkup) => {
  while (slackMarkup.indexOf('`') >= 0) {
    const openingTagIndex = slackMarkup.indexOf('`');
    let firstpartOfString = slackMarkup.substring(0, openingTagIndex + 1);
    firstpartOfString = firstpartOfString.replace('`', '<code>');
    let secondPartOfString = slackMarkup.substring(
      openingTagIndex + 1,
      slackMarkup.length
    );
    secondPartOfString = secondPartOfString.replace('`', '</code>');
    slackMarkup = firstpartOfString + secondPartOfString;
  }
  return slackMarkup;
};

const convertToHTML_StrikeText = (slackMarkup) => {
  while (slackMarkup.indexOf('~') >= 0) {
    const openingTagIndex = slackMarkup.indexOf('~');
    let firstpartOfString = slackMarkup.substring(0, openingTagIndex + 1);
    firstpartOfString = firstpartOfString.replace('~', '<del>');
    let secondPartOfString = slackMarkup.substring(
      openingTagIndex + 1,
      slackMarkup.length
    );
    secondPartOfString = secondPartOfString.replace('~', '</del>');
    slackMarkup = firstpartOfString + secondPartOfString;
  }
  return slackMarkup;
};

const convertToHTML_OrderedList = (slackMarkup) => {
  while (slackMarkup.indexOf('<olist') >= 0) {
    const openingTagIndex = slackMarkup.indexOf('<olist');
    const closingTagIndex = slackMarkup.indexOf(']>', openingTagIndex) + 2;
    const prevText = slackMarkup.substring(0, openingTagIndex);
    const nextText = slackMarkup.substring(closingTagIndex, slackMarkup.length);
    const list = slackMarkup.substring(openingTagIndex, closingTagIndex);
    const listObject = JSON.parse(list.substring(7, list.length - 1));

    let new_list = '<ol>';
    listObject.forEach((o) => {
      new_list += `<li>${o.item}</li>`;
    });
    new_list += '</ol>';

    slackMarkup = prevText + new_list + nextText;
  }

  return slackMarkup;
};

const convertToHTML_UnorderedList = (slackMarkup) => {
  while (slackMarkup.indexOf('<ulist') >= 0) {
    const openingTagIndex = slackMarkup.indexOf('<ulist');
    const closingTagIndex = slackMarkup.indexOf(']>', openingTagIndex) + 2;
    const prevText = slackMarkup.substring(0, openingTagIndex);
    const nextText = slackMarkup.substring(closingTagIndex, slackMarkup.length);
    const list = slackMarkup.substring(openingTagIndex, closingTagIndex);
    const listObject = JSON.parse(list.substring(7, list.length - 1));

    let new_list = '<ul>';
    listObject.forEach((o) => {
      new_list += `<li>${o.item}</li>`;
    });
    new_list += '</ul>';

    slackMarkup = prevText + new_list + nextText;
  }

  return slackMarkup;
};

const convertToHTML_images = (slackMarkup) => {
  while (slackMarkup.indexOf('img:') >= 0) {
    const openingTagIndex = slackMarkup.indexOf('img:');

    let firstpartOfString = slackMarkup.substring(0, openingTagIndex + 4);

    firstpartOfString = firstpartOfString.replace('img:', '<figure><img ');

    let secondPartOfString = slackMarkup.substring(
      openingTagIndex + 4,
      slackMarkup.length
    );
    let endIndex = secondPartOfString.indexOf('.png');
    if (endIndex < 0) endIndex = secondPartOfString.indexOf('.PNG');
    if (endIndex < 0) endIndex = secondPartOfString.indexOf('.jpg');
    if (endIndex < 0) endIndex = secondPartOfString.indexOf('.JPG');
    if (endIndex < 0) endIndex = secondPartOfString.indexOf('.jpeg');
    if (endIndex < 0) endIndex = secondPartOfString.indexOf('.JPEG');
    if (endIndex < 0) endIndex = secondPartOfString.indexOf('.gif');
    if (endIndex < 0) endIndex = secondPartOfString.indexOf('.GIF');

    let thirdpartOfString = secondPartOfString.substring(
      endIndex + 4,
      secondPartOfString.length
    );
    secondPartOfString = secondPartOfString.substring(0, endIndex + 4);
    secondPartOfString = secondPartOfString.replaceAll(
      '_',
      'UNDERSCOREPLACEHOLDER'
    );
    secondPartOfString = secondPartOfString.replaceAll('*', 'STARPLACEHOLDER');
    secondPartOfString =
      'src="' + secondPartOfString + '" height="auto" width="800"></figure>';
    slackMarkup = firstpartOfString + secondPartOfString + thirdpartOfString;
  }
  return slackMarkup;
};

const normalizeLink = (slackMarkup) => {
  slackMarkup = slackMarkup.replaceAll('UNDERSCOREPLACEHOLDER', '_');
  slackMarkup = slackMarkup.replaceAll('STARPLACEHOLDER', '*');
  return slackMarkup;
};

const getEmoji = (emoji, esm) => {
  let e = '';
  try {
    const unified = emoji.unified.includes('-')
      ? emoji.unified.substring(0, emoji.unified.indexOf('-'))
      : emoji.unified;
    e = String.fromCodePoint('0x' + unified);
  } catch (error) {
    e = ':' + esm + ':';
  }
  return e;
};

const protectEmojis = (slackMarkup) => {
  // const emojis_slack_markup = slackMarkup.match(/[^ :\]]+(?=: )/g) || [];
  const emojis_slack_markup = slackMarkup.match(/\:([a-zA-Z_-]+)\:/g) || [];
  const emojis = [];
  emojis_slack_markup.forEach((e) => {
    emojis.push(e);
    slackMarkup = slackMarkup.replace(e, 'EMOJIPLACEHOLDER');
  });
  return { slackMarkup, emojis };
};

const convertToHTML_Emojis = async (slackMarkup, emojis) => {
  const fetchedEmojiJson2 = JSON.parse(localStorage.getItem('haekka-emojis-2'));

  emojis.forEach((e) => {
    const found = fetchedEmojiJson2.find(
      (em) => em.short_name === e.substring(1, e.length - 1)
    );
    if (found) {
      slackMarkup = slackMarkup.replace('EMOJIPLACEHOLDER', getEmoji(found, e));
    } else {
      const fetchedEmojiJson = JSON.parse(
        localStorage.getItem('haekka-emojis')
      );
      const found2 = fetchedEmojiJson.find(
        (em) => em.name === e.substring(1, e.length - 1)
      );
      if (found2) {
        const unified = found2.unicode.includes('-')
          ? found2.unicode.substring(0, found2.unicode.indexOf('-'))
          : found2.unicode;
        slackMarkup = slackMarkup.replace(
          'EMOJIPLACEHOLDER',
          String.fromCodePoint('0x' + unified)
        );
      } else {
        slackMarkup = slackMarkup.replace('EMOJIPLACEHOLDER', '');
      }
    }
  });

  return slackMarkup;
};

export function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

export const applyLinkToEditorState = (editorState, selectedText, url) => {
  let contentState = editorState.getCurrentContent();
  // replace selected text
  contentState = Modifier.replaceText(
    contentState,
    editorState.getSelection(),
    selectedText
  );

  // create new selection according to selected text
  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const offset = selectionState.getStartOffset();
  let newSelection = new SelectionState({
    anchorKey: anchorKey,
    anchorOffset: offset,
    focusKey: anchorKey,
    focusOffset:
      (selectedText.endsWith(' ')
        ? selectedText.length - 1
        : selectedText.length) + offset,
  });

  // create entity (link)
  const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
    url,
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  // apply entity
  let nextEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  nextEditorState = EditorState.forceSelection(nextEditorState, newSelection);

  // apply selection
  nextEditorState = RichUtils.toggleLink(
    nextEditorState,
    nextEditorState.getSelection(),
    entityKey
  );

  // create new selection - to move cursor to the end of previos selection
  newSelection = new SelectionState({
    anchorKey: anchorKey,
    anchorOffset: selectedText.length + offset,
    focusKey: anchorKey,
    focusOffset: selectedText.length + offset,
  });
  nextEditorState = EditorState.forceSelection(nextEditorState, newSelection);
  return nextEditorState;
};
