import { WORKFLOW_SOURCES } from "../../../utils/workflowsUtils";
import SourceDestinationLogo from "./SourceDestinationLogo";
import { useSelector, useDispatch } from "react-redux";
import { workflowsActions } from "../../../store/workflowsSlice";

const SelectSource = () => {
  const dispatch = useDispatch();
  const { selectedSource } = useSelector((state) => state.workflows);

  const selectSourceHandler = (source) => {
    dispatch(workflowsActions.selectSource({ source }));
  };

  return (
    <div className="flex flex-col w-full py-3 border-b sm:flex-row border-hka_gray-light">
      <div className="flex items-center flex-1 mb-2 sm:mb-0">
        <span className="workflow-step">1</span>
        <div className="">
          <h4 className="text-base2 text-hka_gray-dark font-basis-medium">
            Select a Source
          </h4>
          <p className="text-sm">
            The source is <span className="italic">where</span> Haekka listens
            for an event.
          </p>
        </div>
      </div>
      <div className="flex items-center justify-start sm:justify-end">
        <div className="flex items-center">
          {WORKFLOW_SOURCES.map((source) => (
            <span
              key={source.id}
              onClick={() => {
                if (source.active) selectSourceHandler(source.id);
              }}
            >
              <SourceDestinationLogo
                key={source.id}
                source={source}
                selectedSource={selectedSource}
              />
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectSource;
