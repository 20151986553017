import { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AssignEmployeesContext from '../../../store/assign-employeees-context';
import { useAppContext } from '../../../libs/contextLib';
import { getIntegrationsAction } from '../../../store/settingsActions';
import useFetchMergeGroups from '../../../hooks/utility-hooks/useFetchMergeGroups';

import LoaderInline from '../../LoaderInline';
import SelectInput from '../../Base/SelectInput';
import OktaWebHook from './OktaWebHook';

const syncGroupsMessage = (
  <p>
    <span className='font-basis-bold'>
      Groups syncing assigns Okta users added to the group after the training is
      created.
    </span>{' '}
    Haekka syncs selected groups on a daily basis.
  </p>
);

const OktaOptions = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAppContext();
  const assignEmployeesContext = useContext(AssignEmployeesContext);
  const {
    syncOktaGroupsIDs,
    selectedOktaGroups,
    setSelectedOktaGroups,
    oktaGroupsSync,
    setOktaGroupsSync,
  } = assignEmployeesContext;

  const { integrations } = useSelector((state) => state.settings);

  const { groups, completed } = useFetchMergeGroups({
    auth: isAuthenticated,
    integration: 'okta',
    startLoading: !!integrations && integrations.okta,
  });

  useEffect(() => {
    setOktaGroupsSync(
      groups
        .filter((g) => (syncOktaGroupsIDs ?? []).includes(g.remote_id))
        .map((g) => ({ value: g.remote_id, label: g.name }))
    );
  }, [groups, setOktaGroupsSync, syncOktaGroupsIDs]);

  if (integrations === undefined) {
    dispatch(
      getIntegrationsAction({
        auth: isAuthenticated,
      })
    );
  }

  if (integrations === undefined) return <LoaderInline wrapperClasses='h-8' />;

  return (
    <section className='my-3 min-h-18'>
      <SelectInput
        label={{
          text: 'Add users from Okta Groups',
          optional: true,
        }}
        value={selectedOktaGroups}
        onChange={(gs) => setSelectedOktaGroups(gs)}
        placeholder='Search Okta Groups'
        isMultiSelect
        options={groups.map((g) => ({ value: g.remote_id, label: g.name }))}
      />

      <SelectInput
        label={{
          text: 'Sync Okta Groups',
          optional: true,
        }}
        value={oktaGroupsSync}
        onChange={(gs) => setOktaGroupsSync(gs)}
        placeholder='Search Okta Groups'
        isMultiSelect
        options={groups.map((g) => ({ value: g.remote_id, label: g.name }))}
        isDisabled={!completed}
        infoMessages={[
          {
            show: true,
            type: 'info',
            message: syncGroupsMessage,
          },
        ]}
      />

      {!!integrations && <OktaWebHook />}
    </section>
  );
};

export default OktaOptions;
