import validator from 'validator';

import { reorderList } from '../../../utils/commonUtils';
import { ANSWERS_PER_QUESTION_ALLOWED } from '../../../utils/constants';
import { formatRequiredAnswerWarningHelper } from '../../../utils/engagementsUtils';
import Note from '../../Note';
import SortableContainer from '../../SortableContainer';
import EngagementMessageAnswer from './EngagementMessageAnswer';

const AnswersSection = ({
  companyEngagement,
  answerType,
  answers,
  setAnswers,
  externalUrl,
  setExternalUrl,
  externalUrlBtnText,
  setExternalUrlBtnText,
  isSingleSelect,
  setIsSingleSelect,
  isReadOnly,
}) => {
  const isValidUrl = validator.isURL(externalUrl ?? '', {
    require_protocol: true,
  });

  const onAddAnswer = () => {
    let correct = undefined;
    if (answerType.value === 'neutral') correct = null;
    if (answerType.value === 'multiple_choice') correct = true;

    setAnswers((prev) => [
      ...prev,
      {
        question: companyEngagement.quiz.id,
        position: answers.length,
        id: `answer-${answers.length}`,
        correct,
        explanation: null,
        answer: '',
      },
    ]);
  };

  const onDragEndHandler = async ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = answers.findIndex((answer) => answer.id === active.id);
      const newIndex = answers.findIndex((answer) => answer.id === over.id);
      const items = reorderList(answers, oldIndex, newIndex).map(
        (item, index) => ({ ...item, position: index })
      );
      setAnswers(items);
    }
  };

  const customHandle = (
    <span className='cursor-move icon-drag-drop text-hka_gray'></span>
  );

  return (
    <section className='section--answers'>
      {(answerType?.value === 'multiple_choice' ||
        answerType?.value === 'neutral') && (
        <div className='flex'>
          <section className='flex items-center justify-between gap-1 py-1 -mt-1 mb-1'>
            <p className=''>Allow user to only select one answer</p>
            <label className='switch'>
              <input
                type='checkbox'
                checked={isSingleSelect}
                onChange={(e) => setIsSingleSelect(e.target.checked)}
                className='cursor-pointer'
                disabled={isReadOnly}
              />
              <span className='slider'></span>
            </label>
          </section>
        </div>
      )}
      {answerType?.value === 'user_acknowledged' && (
        <p>User Acknowledged adds an "Acknowledge" button after the message.</p>
      )}
      {answerType?.value === 'written_response' && (
        <p>Written Response is a free-form text area input.</p>
      )}
      {answerType?.value === 'no_action' && (
        <p>This message does not require users' action.</p>
      )}
      {(answerType?.value === 'neutral' ||
        answerType?.value === 'multiple_choice') && (
        <section className='container--multiple-choice'>
          {answers.length > 0 && (
            <SortableContainer
              onDragEndHandler={onDragEndHandler}
              customHandle={customHandle}
              containerName='engagement__answers'
              isDisabled={isReadOnly}
            >
              {answers.map((answer) => (
                <EngagementMessageAnswer
                  key={answer.id}
                  answerType={answerType?.value}
                  answer={answer}
                  sortableID={answer.id}
                  setAnswers={setAnswers}
                  isReadOnly={isReadOnly}
                />
              ))}
            </SortableContainer>
          )}
          {answers.length === 0 && (
            <div className='mb-1'>
              <Note>
                <p>
                  At least one {formatRequiredAnswerWarningHelper(answerType)}
                  answer is required in order to create this Engagement Message.
                </p>
              </Note>
            </div>
          )}
          {answers.length < ANSWERS_PER_QUESTION_ALLOWED && (
            <button
              onClick={onAddAnswer}
              type='button'
              className='px-2 button button--blue bg-hka_blue-light'
              disabled={isReadOnly}
            >
              Add Answer
            </button>
          )}
          {answers.length === ANSWERS_PER_QUESTION_ALLOWED && (
            <p className='font-basis-medium text-hka_gray'>
              You can't add more than {ANSWERS_PER_QUESTION_ALLOWED} answers per
              message.
            </p>
          )}
        </section>
      )}
      {answerType?.value === 'external_url' && (
        <section>
          <section className='flex flex-col items-start justify-start mb-2 sm:flex-row'>
            <p className='mb-1 sm:w-2/3'>
              External URL creates a link/button to content outside of Haekka.
            </p>

            <div className='relative flex flex-col flex-1 w-full gap-1'>
              <input
                type='text'
                placeholder='Enter URL'
                className={`input text-base outline-none border ${
                  isValidUrl
                    ? ''
                    : 'text-red-500 bg-red-50 border-red-500 border p-1'
                }`}
                value={externalUrl}
                onChange={(e) => setExternalUrl(e.target.value)}
                maxLength={255}
              />
              <input
                type='text'
                placeholder='Enter Button Text'
                className={`input text-base outline-none border`}
                value={externalUrlBtnText}
                onChange={(e) => setExternalUrlBtnText(e.target.value)}
                maxLength={75}
              />
            </div>
          </section>
        </section>
      )}
    </section>
  );
};

export default AnswersSection;
