import React from "react";
import Callout from "../components/Callout";

const Message = () => (
  <span>
    &#129300; Sorry about that. For some reason we're having an issue displaying
    this page. If you've come here expecting content, please try logging out and
    then logging back in. If you're still experiencing issues, please contact
    our support team{" "}
    <a href="https://www.haekka.com/contact-us" className="link">
      here
    </a>
    .
  </span>
);

export default function NotFound() {
  return <Callout title="Haekka" message={<Message />}></Callout>;
}
