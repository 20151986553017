import React, { useState } from "react";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import ActionButton from "../ActionButton";
import Modal from "../Modal/Modal";
import * as api from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";

export default function GenerateAPIKeyModal({ showModal, onClose }) {
  const { isAuthenticated } = useAppContext();
  const [name, setName] = useState("");
  const [response, setResponse] = useState(null);

  const onCopyToClipboardClick = () => {
    navigator.clipboard.writeText(response.key);
    toast.success("Copied key to clipboard!");
  };

  const handleOnClose = () => {
    setName("");
    setResponse(null);
    onClose(response);
  };

  return (
    <Modal
      show={showModal}
      onClose={handleOnClose}
      hideSubmitButton
      title="Generate A New API Key"
    >
      <div className="mb-2">
        <h4 className="mb-1 font-basis-bold">
          Key Name <span className="text-hka_gray">(Required)</span>
        </h4>
        <div className="flex flex-col sm:flex-row">
          <div className="flex-1 mb-1 mr-2">
            <input
              type="text"
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Key Name"
              value={name}
              className="input focus:outline-none"
              maxLength={30}
              disabled={response}
            />
          </div>
          <button
            type="button"
            disabled={!name || response}
            className="button button--blue"
            onClick={async () => {
              try {
                const response = await api.generateApiKey({
                  auth: isAuthenticated,
                  name,
                });
                setResponse(response);
              } catch (error) {
                onError(error);
              }
            }}
          >
            Generate Key
          </button>
        </div>
      </div>
      {/* Response */}
      {response && (
        <section className="my-2 response">
          <h4 className="mb-1 font-basis-bold">
            Your New API Key: {response.name}
          </h4>
          <p className="mb-1">
            As stated, we do not store your API keys. Please ensure you have
            stored the key in a safe location before closing this modal.
          </p>
          <div className="flex flex-row w-full space-x-2">
            <div className="flex-1 border-2 px-1 py-0.5 border-solid rounded-md text-sm">
              {response.key}
            </div>
            <div className="flex items-center justify-center">
              <FontAwesomeIcon
                icon={faClipboard}
                size={"2x"}
                className="cursor-pointer"
                data-tip="Copy to Clipboard"
                onClick={onCopyToClipboardClick}
              />
            </div>
          </div>
        </section>
      )}
    </Modal>
  );
}
