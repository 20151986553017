import { format } from 'date-fns';
import validator from 'validator';
import { ContentState, EditorState, convertToRaw } from 'draft-js';

import { convertToSlackNotation, getEditorState } from './wysiwygEditorHelpers';
import {
  createCompanyEngagement,
  createEngagement,
  updateCompanyEngagement,
  updateEngagement,
} from '../api/engagementsApi';

export const formatSendCycleMessage = (send_cycle) => {
  let frequency = '';
  let employees = 'assigned employees';
  switch (send_cycle) {
    case 'once':
      frequency = 'once';
      employees = 'assigned employees';
      break;
    case 'weekly':
      frequency = 'every week';
      break;
    case 'monthly':
      frequency = 'every month';
      break;
    case 'quarterly':
      frequency = 'every 3 months';
      break;
    case 'semi-annual':
      frequency = 'every 6 months';
      break;
    case 'annual':
      frequency = 'every 12 months';
      break;
    default:
      break;
  }
  return `Sent ${frequency}`;
};

export const composeTitle = (question) => {
  const title = 'Engagement Question';
  if (!question.editor_content) {
    return title;
  }
  const jsState = JSON.parse(question.editor_content?.quiz_editor_state);
  let counter = 0;
  while (
    (jsState.blocks[counter]?.text === '' ||
      jsState.blocks[counter]?.text === ' ') &&
    counter < jsState.blocks.length
  ) {
    counter++;
  }
  return jsState.blocks[counter]?.text.length > 0
    ? jsState.blocks[counter]?.text
    : title;
};

export const engagementTypeOptions = [
  {
    label: 'Users',
    value: 'private',
  },
  {
    label: 'Channel',
    value: 'channel',
  },
];

export const alertEveryoneInChannelOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const frequencyOptions = [
  {
    label: 'Send once',
    value: 'once',
  },
  {
    label: 'Send weekly',
    value: 'weekly',
  },
  {
    label: 'Send monthly',
    value: 'monthly',
  },
  {
    label: 'Send quarterly',
    value: 'quarterly',
  },
  {
    label: 'Send semi-annually',
    value: 'semi-annual',
  },
  {
    label: 'Send annually',
    value: 'annual',
  },
];

export const createEngagementCombinedHandler = async (sumbitData) => {
  const engagement = await createEngagement({ sumbitData });
  sumbitData.quiz = engagement.id;
  const companyEngagement = await createCompanyEngagement({ sumbitData });
  companyEngagement.quiz = engagement;
  return companyEngagement;
};

export const updateEngagementCombinedHandler = async ({
  sumbitData,
  companyEngagement,
}) => {
  const updatedEngagement = await updateEngagement({
    sumbitData: { ...sumbitData, id: companyEngagement.quiz.id },
  });
  const updatedCompanyEngagement = await updateCompanyEngagement({
    sumbitData: { ...sumbitData, id: companyEngagement.id },
  });
  updatedCompanyEngagement.quiz = updatedEngagement;
  return updatedCompanyEngagement;
};

export const employeeViewTabs = (isAnonymousEngagement) => {
  const tabs = [
    {
      type: 'content',
      caption: 'Content',
    },
    {
      type: 'employees',
      caption: 'Employees',
    },
  ];
  if (isAnonymousEngagement) {
    tabs.push({
      type: 'results',
      caption: 'Results (Anonymous)',
    });
  }
  return tabs;
};

export const engagementAnswerTypeOptions = [
  {
    label: 'No Action (Announcement)',
    value: 'no_action',
  },
  {
    label: 'User Acknowledge',
    value: 'user_acknowledged',
  },
  {
    label: 'Scored Answer',
    value: 'multiple_choice',
  },
  {
    label: 'Neutral Answer Response',
    value: 'neutral',
  },
  {
    label: 'Written Response',
    value: 'written_response',
  },
  {
    label: 'External URL',
    value: 'external_url',
  },
];

export const getAnswerTypeForQuestion = (question) => {
  return engagementAnswerTypeOptions.find(
    (option) => option.value === question.answer_type
  );
};

export const formattedEngagementAnswerType = (answerType) => {
  return engagementAnswerTypeOptions.find(
    (option) => option.value === answerType
  ).label;
};

export const initializeQuestionEditorState = async (question) => {
  return await getEditorState(
    question.editor_content.quiz_editor_state,
    question.editor_content,
    undefined,
    false
  );
};

export const initializeEditorState = async (editor_content) => {
  return await getEditorState(
    editor_content.editor_state,
    editor_content,
    undefined,
    false
  );
};

const areAnswersCorrect = ({ answerType, answers }) => {
  if (
    answerType?.value === 'neutral' ||
    answerType?.value === 'multiple_choice'
  ) {
    return (
      answers.length > 0 && answers.every((a) => a.answer.trim().length > 0)
    );
  }
  return true;
};

const isExternalUrlSectionValid = ({
  answerType,
  externalUrl,
  externalUrlBtnText,
}) => {
  if (answerType?.value !== 'external_url') {
    return true;
  }
  return (
    (externalUrl ?? '').trim().length > 0 &&
    (externalUrlBtnText ?? '').trim().length > 0 &&
    validator.isURL(externalUrl, { require_protocol: true })
  );
};

export const validateAddMessageForm = ({
  questionEditorState,
  answerType,
  answers,
  externalUrl,
  externalUrlBtnText,
}) => {
  const messageHasText =
    questionEditorState?.getCurrentContent().getPlainText('\u0001') !== '';
  return (
    messageHasText &&
    !!answerType &&
    areAnswersCorrect({ answerType, answers }) &&
    isExternalUrlSectionValid({ answerType, externalUrl, externalUrlBtnText })
  );
};

export const validateAnswerTypeChangeAttempt = ({
  answerType,
  selectedValue,
}) => {
  let message = undefined;
  if (
    (answerType?.value === 'multiple_choice' ||
      answerType?.value === 'neutral') &&
    (selectedValue.value === 'no_action' ||
      selectedValue.value === 'user_acknowledged' ||
      selectedValue.value === 'written_response' ||
      selectedValue.value === 'external_url')
  ) {
    message = `Answers are not allowed when the ${formattedEngagementAnswerType(
      selectedValue.value
    )} action type is used. We will delete all answers for you if you decide to proceed.`;
  } else if (
    answerType?.value === 'multiple_choice' &&
    selectedValue.value === 'neutral'
  ) {
    message = `Each answer has to be neutral when the ${formattedEngagementAnswerType(
      selectedValue.value
    )} action type is used. We will convert all your answers to neutral if you decide to proceed.`;
  } else if (
    answerType?.value === 'neutral' &&
    selectedValue.value === 'multiple_choice'
  ) {
    message = `Each answer has to be correct or incorrect when the ${formattedEngagementAnswerType(
      selectedValue.value
    )} action type is used. We will convert all your answers to correct (default) if you decide to proceed.`;
  }
  return message;
};

export const formatRequiredAnswerWarningHelper = (answerType) => {
  if (answerType.value === 'neutral') return '';
  if (answerType.value === 'multiple_choice') return 'correct or incorrect ';
};

export const onCreateMessageSuccess = (curr, newMessageData) => ({
  ...curr,
  quiz: {
    ...curr.quiz,
    questions: [...curr.quiz.questions, newMessageData],
  },
});

export const onUpdateMessageSuccess = (
  curr,
  selectedQuestion,
  newMessageData
) => ({
  ...curr,
  quiz: {
    ...curr.quiz,
    questions: curr.quiz.questions.map((q) =>
      q.id === selectedQuestion.id ? newMessageData : q
    ),
  },
});

export const onDeleteMessageSuccess = (curr, selectedQuestion) => ({
  ...curr,
  quiz: {
    ...curr.quiz,
    questions: curr.quiz.questions.map((q) =>
      q.id === selectedQuestion.id ? { ...q, engagement_custom_video: [] } : q
    ),
  },
});

const isEngagementTypeValid = ({ engagementType, channel }) => {
  if (!engagementType) return false;
  if (engagementType?.value === 'private') {
    return true;
  } else if (engagementType?.value === 'channel') {
    return !!channel;
  }
};

const areMessagesValid = ({
  welcomeMessage,
  closingMessage,
  engagementType,
}) => {
  if (engagementType?.value === 'channel') {
    return true;
  }
  return (
    welcomeMessage?.getCurrentContent().getPlainText('\u0001') !== '' &&
    closingMessage?.getCurrentContent().getPlainText('\u0001') !== ''
  );
};

export const validateAddEngagementForm = ({
  isMutating,
  title,
  startDate,
  engagementType,
  channel,
  welcomeMessage,
  closingMessage,
  welcomeMessageButtonText,
}) => {
  return (
    !isMutating &&
    title.length > 0 &&
    welcomeMessageButtonText.length > 0 &&
    !!startDate &&
    isEngagementTypeValid({ engagementType, channel }) &&
    areMessagesValid({ welcomeMessage, closingMessage, engagementType })
  );
};

const getCellAnswerBorderStyle = (isCorrect) => {
  if (isCorrect === true) {
    return 'selected-answer--correct';
  } else if (isCorrect === false) {
    return 'selected-answer--incorrect';
  } else {
    return 'selected-answer--neutral';
  }
};

const composeEmployeeAnswers = (message, globalMessage) => {
  if (globalMessage.answer_type === 'user_acknowledged') {
    return message.acknowledged ? 'Acknowledged' : '-';
  }
  if (globalMessage.answer_type === 'written_response') {
    return message.written_answer;
  }
  if (globalMessage.answer_type === 'external_url' && message.finished) {
    return 'Clicked';
  }
  if (
    globalMessage.answer_type === 'neutral' ||
    globalMessage.answer_type === 'multiple_choice'
  ) {
    let answerRepresentation = undefined;
    if (message.answers) {
      const parsedEmployeeAnswers = JSON.parse(message.answers);
      answerRepresentation = parsedEmployeeAnswers.map((answer) => {
        const globalAnswer = globalMessage.answers.find((a) => a.id === answer);
        return {
          text: globalAnswer.answer,
          cssClasses: getCellAnswerBorderStyle(globalAnswer.correct),
        };
      });
    }
    if (answerRepresentation) {
      answerRepresentation.hasAnswers = true;
    }
    return answerRepresentation;
  }
  return '';
};

export const transformEmployeesResults = ({ data, questions }) => {
  return data.results.map((item) => {
    const messages = item.messages;
    for (const message of messages) {
      const globalMessage = questions.find((q) => q.id === message.question_id);
      item[message.question_id] = {
        answer: composeEmployeeAnswers(message, globalMessage),
      };
    }
    if (item.finish_date) {
      item.finish_date = format(new Date(item.finish_date), 'MMM d, yyyy');
    }
    item.employee = {
      ...item.employee,
      active: item.active,
      is_sent: item.is_sent,
      engagement_id: item.engagement_id,
      finished: !!item.finish_date,
    };
    return item;
  });
};

const populateEmployeesWithReactions = (employees, vReactions, mReactions) => {
  const concetedReactions = vReactions.concat(mReactions);
  for (const employee in employees) {
    for (const i in concetedReactions) {
      const reactionName = concetedReactions[i]?.name;
      const users = concetedReactions[i]?.users ?? [];
      if (users.includes(employee)) {
        employees[employee] = [...employees[employee], `:${reactionName}:`];
      }
    }
  }
  return employees;
};

export const currentEmployeesReactions = ({
  videoReactions,
  messageReactions,
  channelEngagementResults,
}) => {
  if (channelEngagementResults.length === 0) return {};

  const employees = channelEngagementResults.reduce((acc, current) => {
    acc[current.employee.slack_uid] = [];
    return acc;
  }, {});

  const vReactions = videoReactions?.message.reactions ?? [];
  const mReactions = messageReactions?.message.reactions ?? [];
  return populateEmployeesWithReactions(employees, vReactions, mReactions);
};

export const getMinTime = (selectedDate) => {
  const currentTS = new Date();
  if (
    selectedDate.getFullYear() !== currentTS.getFullYear() ||
    selectedDate.getMonth() !== currentTS.getMonth() ||
    selectedDate.getDay() !== currentTS.getDay()
  ) {
    currentTS.setHours(0);
  }
  return currentTS;
};

export const getCurrentDateWithLastHourSet = () => {
  const dateWithLastHour = new Date();
  dateWithLastHour.setHours(23);
  return dateWithLastHour;
};

export const roundSendTimeTo30MinutesTop = (date) => {
  let sDate = new Date(date);
  const minutes = sDate.getMinutes();
  if (minutes > 30) {
    sDate.setHours(sDate.getHours() + 1);
    sDate.setMinutes(0);
  } else if (minutes > 0) {
    sDate.setMinutes(30);
  }
  return sDate;
};

export const formatNoteDate = (date) => {
  return `on ${format(date, 'MMM dd, yyyy')} at ${format(date, 'h:mm aa')}`;
};

export const getDefaultEditorStateForWelcomeMessage = () => {
  const message = `👋 USER_NAME! We hope this message finds you well. You've been assigned a new engagement to work on - ENGAGEMENT_NAME! To kick things off, simply click the button below.`;
  return EditorState.createWithContent(ContentState.createFromText(message));
};

export const getDefaultEditorStateForClosingMessage = () => {
  const message = `🥳 Congratulations, USER_NAME! You've finished!`;
  return EditorState.createWithContent(ContentState.createFromText(message));
};

export const getEditorStatesForSumbit = async ({ message }) => {
  const editorStateRaw = JSON.stringify(
    convertToRaw(message.getCurrentContent())
  );
  const slackMarkup = await convertToSlackNotation(message.getCurrentContent());

  return { editorStateRaw, slackMarkup };
};

export const transformEmployeesAnonimousResults = ({ data, questions }) => {
  if (!data) return [];

  const rows = data.results.map((row) => {
    const reducedAnswers = row.question_responses.reduce(
      (accumulator, currentValue) => {
        let answerRepresentation = undefined;
        const globalMessage = questions.find(
          (q) => q.id === currentValue.question
        );

        if (
          currentValue.written_answer &&
          currentValue.written_answer.length > 0
        ) {
          answerRepresentation = currentValue.written_answer;
        } else if (currentValue.answers) {
          const parsedEmployeeAnswers = JSON.parse(currentValue.answers);
          answerRepresentation = parsedEmployeeAnswers.map((answer) => {
            const globalAnswer = globalMessage.answers.find(
              (a) => a.id === answer
            );
            return {
              text: globalAnswer.answer,
              cssClasses: getCellAnswerBorderStyle(globalAnswer.correct),
            };
          });
          if (answerRepresentation) {
            answerRepresentation.hasAnswers = true;
          }
        }
        return {
          ...accumulator,
          [currentValue.question]: {
            answer: answerRepresentation,
            answers: currentValue.answers,
            written_answer: currentValue.written_answer,
          },
        };
      },
      {}
    );

    return { user: row.hashed_user_id, ...reducedAnswers };
  });

  return rows;
};
