import { useMutation } from '@tanstack/react-query';
import { updateCompanyPost } from '../../../../api/streamsApi';

export const useUpdateCompanyStreamPost = ({
  onSuccess = () => {},
  onError = () => {},
}) => {
  return useMutation({
    mutationFn: updateCompanyPost,
    onSuccess,
    onError,
  });
};
