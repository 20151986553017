import React from "react";
import ActionButton from "../components/ActionButton";

const ListItemUserRemove = ({ name, avatarImage, onRemove, isRemoving }) => {
  return (
    <li className="items-center border-b border-hka_gray-light">
      <img className="mr-2 user-img" src={avatarImage} alt="user-image" />
      <p className="flex-1 text-hka_gray-dark">{name}</p>
      <div className="flex justify-end flex-1">
        <ActionButton onClick={onRemove} type="danger">
          <span className="h-3 sm:-ml-1 mb-0.4 icon-trash"></span>
          <span className="hidden sm:block">
            {isRemoving ? "Removing..." : "Remove Admin"}
          </span>
        </ActionButton>
      </div>
    </li>
  );
};

export default ListItemUserRemove;
