import React from "react";
import Modal from "../Modal/Modal";
import Note from "../Note";

export default function DeleteEmployeeModal({
  showModal,
  onSubmit,
  employee,
  onClose,
}) {
  return (
    <Modal
      show={showModal}
      title="Remove Employee from Haekka"
      submitButtonText="Remove Employee"
      submitButtonType="danger"
      onSubmit={onSubmit}
      onClose={onClose}
      compact
    >
      <p className="mb-1">
        Are you sure you want to remove this employee rom Haekka?
      </p>
      <Note type="danger">
        <p>
          This action will permanently remove all training data for this
          employee.
        </p>
      </Note>
    </Modal>
  );
}
