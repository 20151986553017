import { Fragment, useContext } from "react";
import ChannelsSelect from "../AssignEmployees/ChannelsSelect";
import SelectEmployeesManually from "./SelectEmployeesManually";
import { Link } from "react-router-dom";
import AssignEmployeesContext from "../../store/assign-employeees-context";
import ActionButton from "../ActionButton";
import Note from "../Note";
import Divider from "../Base/Divider";

const SlackOptions = ({ hideChannelSync }) => {
  const assignEmployeesContext = useContext(AssignEmployeesContext);
  const {
    channelOptions,
    slackAutoEnroll,
    setSlackAutoEnroll,
    slackAddAllUsers,
    setSlackAddAllUsers,
    setSelectedChannels,
    setSelectedChannelsSync,
    loadingChannelsCompleted,
    selectedChannels,
    selectedChannelsSync,
    reloadChannelsHandler,
  } = assignEmployeesContext;

  return (
    <Fragment>
      <section className="mb-2">
        {/* <Divider horizontal/> */}
        <div className="flex justify-between pb-2">
          <p className="text-hka_gray-dark">
            Add{" "}
            <span className="font-basis-bold">all existing Slack users</span>
          </p>
          <label className="switch">
            <input
              type="checkbox"
              name="select-all-users"
              checked={slackAddAllUsers}
              onChange={() => setSlackAddAllUsers((p) => !p)}
              className="cursor-pointer"
            />
            <span className="slider"></span>
          </label>
        </div>
        <Divider horizontal />
        <div className="flex justify-between py-2">
          <p className="text-hka_gray-dark">
            Automatically add{" "}
            <span className="font-basis-bold">new Slack users</span>
          </p>
          <label className="switch">
            <input
              type="checkbox"
              name="workplace-sync"
              checked={slackAutoEnroll}
              onChange={() => setSlackAutoEnroll((p) => !p)}
              className="cursor-pointer"
            />
            <span className="slider"></span>
          </label>
        </div>
        <Note>
          <p className="">
            To ensure all existing and future Slack users in your workspace are
            assigned this training, toggle both switches above. If you want to
            globally exclude guest accounts, you can do that
            <Link to={`/settings`} className="link--bold ml-0.5">
              here
            </Link>
            .
          </p>
        </Note>
      </section>

      <section className="mb-2">
        <h4 className="mb-1">
          Add users one-by-one{" "}
          <span className="text-sm text-hka_gray">(Optional)</span>
        </h4>
        <SelectEmployeesManually
          assignEmployeesContext={assignEmployeesContext}
          csvOption={false}
        />
      </section>

      {!slackAddAllUsers && (
        <section className="mb-2 space-y-1">
          <h4 className="mb-1">
            Add users from channel{" "}
            <span className="text-sm text-hka_gray">(Optional)</span>
          </h4>

          <ChannelsSelect
            defaultOptions={selectedChannels}
            onChange={(e) => setSelectedChannels(e)}
            options={channelOptions}
            className="select--select-employees-manually"
            classNamePrefix="select"
            disabled={!loadingChannelsCompleted}
            labelText={
              !loadingChannelsCompleted ? "Loading channels..." : undefined
            }
            height={190}
          />

          <div className="flex flex-col items-center sm:flex-row sm:space-x-3">
            <p className="flex-1 mb-1 text-sm md:mb-0">
              Don't see the channel(s) you're looking for? Add Haekka as an
              integration to the channel, then click the Sync Channels button to
              the right.
            </p>
            <ActionButton
              type="defaultNoIcon"
              onClick={reloadChannelsHandler}
              disabled={!loadingChannelsCompleted}
            >
              Sync Channels
            </ActionButton>
          </div>

          <Note>
            <p>
              <span className="font-basis-bold">
                Haekka must be added as an integration to each channel you want
                to assign employees from.
              </span>{" "}
              Type @Haekka in any channel to add the integration. To learn more
              about adding the integration, read
              <a
                className="inline-flex items-center link--bold ml-0.4"
                href="https://www.haekka.com/documentation/adding-haekka-as-a-channel-integration"
                target="_blank"
                rel="noreferrer"
              >
                this article
              </a>
              .
            </p>
          </Note>
        </section>
      )}

      {/* Sync Users from Channel */}
      {!slackAddAllUsers && !hideChannelSync && (
        <section className="mb-2 space-y-1">
          <h4 className="mb-0.5 sm:mb-0 w-3/5">
            Sync users from channel{" "}
            <span className="text-sm text-hka_gray">(Optional)</span>
          </h4>
          <ChannelsSelect
            defaultOptions={selectedChannelsSync}
            onChange={(e) => setSelectedChannelsSync(e)}
            options={channelOptions}
            // maxMenuHeight="2"
            disabled={!loadingChannelsCompleted}
            labelText={
              !loadingChannelsCompleted ? "Loading channels..." : undefined
            }
          />

          <Note>
            <p>
              <span className="font-basis-bold">
                Channel syncing assigns Slack users added to the channel after
                the training or workflow is created.
              </span>{" "}
              To add existing users from a channel, use Add users from channel
              (above).
            </p>
          </Note>
        </section>
      )}
    </Fragment>
  );
};

SlackOptions.propTypes = {};

export default SlackOptions;
