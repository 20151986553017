import React from "react";
import Modal from "../Modal/Modal";
import Note from "../Note";

export default function RemindAllEmployeesModal({
  showModal,
  onSendReminder,
  onClose,
  type = "training",
}) {
  return (
    <Modal
      show={showModal}
      title="Remind Employees"
      onSubmit={onSendReminder}
      onClose={onClose}
      submitButtonType="primary"
      submitButtonText="Send Reminder"
      compact
    >
      <Note type="info">
        <p>
          This global reminder will only be sent to employees who have{" "}
          <span className="font-basis-bold">not yet finished this {type}</span>.
        </p>
      </Note>
    </Modal>
  );
}
