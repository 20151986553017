import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  integrations: undefined,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setIntegrations(state, action) {
      state.integrations = action.payload.integrations;
    },
  },
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice.reducer;
