export const convertRenewalPeriodToText = (renewCycle) => {
  let months = 0;
  switch (renewCycle) {
    case 'quarterly':
      months = 3;
      break;
    case 'semi-annual':
      months = 6;
      break;
    case 'annual':
      months = 12;
      break;
    default:
      break;
  }
  return `${months} months`;
};

export const CURRICULUM_ITEM_TYPES = {
  LESSON: 'Lesson',
  ASSESSMENT: 'Quiz',
};

export const getNextCurriculumItemPosition = (curriculumContent) => {
  const nextPosition = curriculumContent.reduce(
    (previousPos, currentLesson) => {
      if (currentLesson.position > previousPos) {
        return currentLesson.position;
      }
      return previousPos;
    },
    0
  );
  return nextPosition + 1;
};

export const getReorderCurriculumItemsPayload = (payload) =>
  payload.reduce(
    (previousValue, currentValue, currentIndex) => {
      if (currentValue.type === CURRICULUM_ITEM_TYPES.LESSON) {
        previousValue.lessons[currentValue.value.id] = currentIndex;
      }
      if (currentValue.type === CURRICULUM_ITEM_TYPES.ASSESSMENT) {
        previousValue.assessments[currentValue.value.id] = currentIndex;
      }
      return previousValue;
    },
    { lessons: {}, assessments: {} }
  );

export const checkSkippedLessons = (active, items, newIndex) => {
  const curriculumContentItem = items.find((ci) => ci.value.id === active.id);

  if (curriculumContentItem.type === CURRICULUM_ITEM_TYPES.ASSESSMENT) {
    const lessonsToSkip = curriculumContentItem.value.lessons_to_skip;
    if (lessonsToSkip.length === 0) return false;

    const lessonsBelowTheIndex = [];

    const areAllLessonsAboveTheAssessmentIndex = items
      .filter((i) => i.type === CURRICULUM_ITEM_TYPES.LESSON)
      .map((ci) => ci.value)
      .filter((l) => lessonsToSkip.includes(l.id))
      .every((l) => {
        if (l.position <= newIndex) {
          lessonsBelowTheIndex.push({
            activeItem: curriculumContentItem.value,
            activeItemType: CURRICULUM_ITEM_TYPES.ASSESSMENT,
            obsticleItem: l,
            obsticleItemType: CURRICULUM_ITEM_TYPES.LESSON,
          });
        }
        return l.position > newIndex;
      });
    if (areAllLessonsAboveTheAssessmentIndex) return false;

    return lessonsBelowTheIndex[0];
  }

  if (curriculumContentItem.type === CURRICULUM_ITEM_TYPES.LESSON) {
    const remainingAssessments = items.filter(
      (i) =>
        i.type === CURRICULUM_ITEM_TYPES.ASSESSMENT &&
        i.value.position > curriculumContentItem.value.position
    );
    const skipLessonsSettings = remainingAssessments.reduce(
      (accumulator, item) => {
        if (
          item.value.lessons_to_skip.includes(curriculumContentItem.value.id)
        ) {
          return [
            ...accumulator,
            {
              activeItem: curriculumContentItem.value,
              activeItemType: CURRICULUM_ITEM_TYPES.LESSON,
              obsticleItem: item.value,
              obsticleItemType: CURRICULUM_ITEM_TYPES.ASSESSMENT,
            },
          ];
        }
        return accumulator;
      },
      []
    );
    if (skipLessonsSettings.length === 0) return false;

    return skipLessonsSettings[0];
  }
};

export const formatEmployeeTrainingProgressLabel = ({
  totalLessons,
  completedLessons,
  totalAssessments,
  completedAssessments,
}) => {
  if (totalAssessments === 0) {
    return `${completedLessons} of ${totalLessons} ${
      totalLessons > 1 ? 'lessons' : 'lesson'
    } complete`;
  }
  if (totalLessons === 0) {
    return `${completedAssessments} of ${totalAssessments} ${
      totalAssessments > 1 ? 'quizzes' : 'quiz'
    } complete`;
  }
  const totalItems = totalLessons + totalAssessments;
  return `${completedLessons + completedAssessments} of ${totalItems} ${
    totalItems > 1 ? 'items' : 'item'
  } complete`;
};
