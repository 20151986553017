import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ReactPlayer from 'react-player';

import { useAppContext } from '../../../libs/contextLib';
import { useUpdateCompanyStreamPost } from '../../../hooks/data-hooks/streams';
import { clearParams } from '../../../utils/commonUtils';

import Modal from '../../Modal/Modal';
import WysiwygRichTextEditor from '../../WysiwygEditor/WysiwygRichTextEditor';
import { getEditorState } from '../../../utils/wysiwygEditorHelpers';
import ActionButton from '../../ActionButton';
import { useNavigate } from 'react-router-dom';

const PostPreviewModal = ({
  previewPostModalData,
  onEditPost,
  onConvertToCustomPostAttempt,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { isAuthenticated } = useAppContext();
  const isGlobalContentWriter =
    isAuthenticated.company_details.is_global_content_maker;

  const [editorState, setNotesEditorState] = useState(undefined);

  const { mutate: updateCompanyPost } = useUpdateCompanyStreamPost({
    onSuccess: (res) => {
      queryClient.invalidateQueries(['streams']);
      queryClient.invalidateQueries(['company-stream']);
      toast.success(`Post ${res.approval_status}!`);
      clearParams({ navigate });
      onClose();
    },
    onError: (error) => {
      if (error.response.status === 400) {
        toast.error(error.response.data.errors);
      }
    },
  });

  const {
    show,
    post,
    electedText,
    header,
    approveButton,
    skipButton,
    editButton,
  } = previewPostModalData;

  useEffect(() => {
    const initializePostES = async () => {
      const state = await getEditorState(
        post.editor_content.editor_state,
        post.editor_content,
        null,
        false
      );
      setNotesEditorState(state);
    };
    initializePostES();
  }, [post.editor_content]);

  const editPostHandler = () => {
    if (!isGlobalContentWriter && post.is_sent) {
      return;
    }

    if (isGlobalContentWriter) {
      onEditPost(post);
    } else if (
      !isGlobalContentWriter &&
      !post.is_sent &&
      post.is_editable &&
      !post.company
    ) {
      onConvertToCustomPostAttempt(post);
    } else if (
      !isGlobalContentWriter &&
      !post.is_sent &&
      post.is_editable &&
      !!post.company
    ) {
      onEditPost(post);
    }
  };

  const postApprovalHandler = (approvalStatus) => {
    updateCompanyPost({
      companyID: isAuthenticated.company,
      streamID: post.stream,
      postID: post.id,
      payload: { approval_status: approvalStatus },
    });
  };

  return (
    <Modal
      show={show}
      title={header ? 'Post Preview' : 'Post Review'}
      onClose={onClose}
      noFooter
      modalClass='modal--post-preview'
    >
      <main className='modal__body'>
        <section>
          {electedText && (
            <section className='mb-3.5'>
              <p className='p-1 text-base border rounded-lg bg-hka_gray-ultraLight border-hka_gray-light text-hka_gray-dark'>
                You've elected to review each Stream post before it is sent to
                your employees. Below is this week's post for the{' '}
                <b>{post.title}</b>. If you do not Approve or Skip this post by
                tomorrow, it will be sent to your employees automatically.
              </p>
            </section>
          )}

          <section className='mb-2.5'>
            <h5 className='mb-1 title text-md font-basis-bold'>{post.title}</h5>
          </section>

          {post.video_url && (
            <section className='mb-2.5'>
              <ReactPlayer
                url={post.video_url}
                controls
                className='react-player'
                height='100%'
                width='100%'
              />
            </section>
          )}

          <section className='mb-3.5'>
            <WysiwygRichTextEditor
              editorState={editorState}
              readOnly
              toolbarHidden
            />
          </section>

          <section className='mb-2.5'>
            <div className='flex justify-evenly'>
              {approveButton && (
                <ActionButton
                  onClick={() => postApprovalHandler('approved')}
                  type='secondary'
                >
                  <span className='block icon-plus text-hka_blue sm:hidden'></span>
                  <span className='hidden sm:inline-block min-w-10'>
                    Approve Post
                  </span>
                </ActionButton>
              )}
              {skipButton && (
                <ActionButton
                  onClick={() => postApprovalHandler('skipped')}
                  type='secondary'
                >
                  <span className='block icon-plus text-hka_blue sm:hidden'></span>
                  <span className='hidden sm:inline-block min-w-10'>
                    Skip Post
                  </span>
                </ActionButton>
              )}
              {editButton && (
                <ActionButton onClick={editPostHandler} type='secondary'>
                  <span className='block icon-plus text-hka_blue sm:hidden'></span>
                  <span className='hidden sm:inline-block min-w-10'>
                    Edit Post
                  </span>
                </ActionButton>
              )}
            </div>
          </section>
        </section>
      </main>
    </Modal>
  );
};

export default PostPreviewModal;
