import { useAppContext } from "../../libs/contextLib";
import ActionButton from "../ActionButton";
import { useDispatch } from "react-redux";
import { workflowsActions } from "../../store/workflowsSlice";

const WorkflowsHeader = () => {
  const { isAuthenticated } = useAppContext();
  const isWorkflowFeaturePaid = isAuthenticated.company_details.has_workflows;
  const dispatch = useDispatch();

  const addWorkflowHandler = () => {
    dispatch(workflowsActions.addWorkflow());
  };

  return (
    <header className="mb-1">
      {isAuthenticated.is_admin ? (
        <>
          <section className="flex justify-between mb-4">
            <h1>Workflows</h1>
            {isWorkflowFeaturePaid && (
              <button
                onClick={addWorkflowHandler}
                type="button"
                className="px-2 button button--blue"
              >
                Create Workflow
              </button>
            )}
          </section>
        </>
      ) : (
        <p>Your Workflows</p>
      )}
    </header>
  );
};

export default WorkflowsHeader;
