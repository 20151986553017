import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import queryString from 'query-string';
import * as api from '../../api';
import { useAppContext } from '../../libs/contextLib';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import Loader from '../Loader';
import { getSlackUserInfo, getSlackTeamInfo } from '../../utils/slackApiUtils';
import { onError } from '../../libs/errorLib';
import {
  EVENT_TRACKER,
  internalSlackChannelLogger,
} from '../../analytics/eventTracker';
import { getLoggedInUserDetails } from '../../utils/commonUtils';

const SlackInstallRedirect = () => {
  const {
    isAuthenticated,
    userHasAuthenticated,
    setAppInstalled,
    setIsConnectedWithSlack,
  } = useAppContext();

  const location = useLocation();
  const navigate = useNavigate();
  let params = queryString.parse(location.search);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!params.code) {
      navigate('/', { replace: true });
    }
    const checkInstalledApps = () => {
      const auth = JSON.parse(localStorage.getItem('auth'));
      return api.checkInstalledApps({
        auth: auth,
      });
    };
    const getInfo = async () => {
      try {
        const data = await api.authenticateWithSlack({
          code: params.code,
          redirect_uri: `https://${window.location.host}/auth/redirect/slack`,
        });

        if (data.error) {
          navigate('/', { replace: true });
        }

        const slackUser = await getSlackUserInfo(
          data.access_token,
          data.authed_user.id
        );

        const slackInstallation = await api.createSlackInstallation({
          installationData: {
            enterprise_id: data.enterprise ? data.enterprise.id : undefined,
            team_id: data.team.id,
            user_id: data.authed_user.id,
            user_email: slackUser.user.profile.email,
            user_name: slackUser.user.real_name,
            bot_token: data.access_token,
            bot_id: data.app_id,
            bot_user_id: data.bot_user_id,
            team_name: data.team.name,
          },
        });

        const slackTeam = await getSlackTeamInfo(
          data.access_token,
          slackUser.user.team_id
        );

        let auth = undefined;
        let employee = undefined;

        if (isAuthenticated) {
          employee = await api.loginEmployee({
            auth: isAuthenticated,
            loginData: {
              slack_user_id: slackUser.user.id,
              slack_user_email: slackUser.user.profile.email,
              slack_user_name: slackUser.user.real_name,
              slack_company_id: slackTeam.team.id,
              slack_company_title: slackTeam.team.name,
            },
          });
          isAuthenticated.id = employee.id;
          userHasAuthenticated(isAuthenticated);
          // localStorage.setItem("auth", JSON.stringify(employee));

          auth = await getLoggedInUserDetails({
            auth: isAuthenticated,
          });
          auth.login_token = localStorage.getItem('login_token');
          localStorage.removeItem('login_token');
          localStorage.setItem('auth', JSON.stringify(auth));
          userHasAuthenticated(auth);

          // TO-DO: extract this logic to utility function or hook (it's used in the App.js, GoogleAuth and here)
          const { is_app_installed_in_slack, is_google_workspace_integrated } =
            await checkInstalledApps();
          localStorage.setItem(
            'apps_installations',
            JSON.stringify({
              ts: new Date(),
              slack: is_app_installed_in_slack,
              google: is_google_workspace_integrated,
            })
          );

          setIsConnectedWithSlack(is_app_installed_in_slack);
        }

        const isFirstInstall =
          format(new Date(slackInstallation.created), 'yyyy-MM-dd') ===
          new Date().toISOString().split('T')[0];

        const payload = {
          $name: slackUser.user.real_name,
          $created: new Date().toISOString(),
          $email: slackUser.user.profile.email,
          $avatar: slackUser.user.profile.image_192,
          Timezone: slackUser.user.tz,
          'Slack Admin': slackUser.user.is_admin,
          'Slack Owner': slackUser.user.is_owner,
          'Haekka Admin': true,
          'Slack User ID': slackUser.user.id,
          'Slack Team ID': slackTeam.team.id,
          Company: slackTeam.team.name,
        };

        if (employee) {
          payload['Employee ID'] = employee.id;
          payload[
            'Employee Link to Admin Panel'
          ] = `${process.env.REACT_APP_API_URL}/admin/employee/employee/${employee.id}/change`;
          payload['Company ID'] = employee.company;
          payload[
            'Company Link to Admin Panel'
          ] = `${process.env.REACT_APP_API_URL}/admin/company/company/${isAuthenticated.company_details.id}/change`;
          payload['Plan Max Users'] = auth.company_details.max_users;
        }

        if (isAuthenticated) {
          internalSlackChannelLogger(
            EVENT_TRACKER.APP_INSTALL,
            isAuthenticated ? isAuthenticated : { is_admin: true },
            {
              Admin: slackUser.user.real_name,
              distinct_id: slackUser.user.id,
              Company: slackTeam.team.name,
              'Company ID': isAuthenticated?.company_details.id,
              'Is First Install': isFirstInstall ? 'YES' : 'NO',
              'Company Link to Admin Panel': `${process.env.REACT_APP_API_URL}/admin/company/company/${isAuthenticated?.company_details.id}/change`,
            }
          );
        }
        if (!isAuthenticated) {
          toast.success('Slack app installed. Log in!');
        }

        setAppInstalled(true);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error(
          'Haekka Slack app installation failed. Please contact your administrator.'
        );
        onError(error);
      }
    };

    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoading) {
    navigate('/', { replace: true });
  }

  return <Loader />;
};

export default SlackInstallRedirect;
