import { Fragment, useEffect, useState } from "react";
import { useAppContext } from "../../libs/contextLib";
import * as api from "../../api";
import ListItems from "../ListItems";
import Pagination from "../Pagination";
import { workflowModalViews } from "../../utils/workflowsUtils";
import LoaderInline from "../LoaderInline";
import RemoveAssignedEmployee from "./CreateEditWorkflowModal/RemoveAssignedEmployee";
import { onError } from "../../libs/errorLib";
import toast from "react-hot-toast";

const ListAssignedEmployees = ({ switchViewHandler, workflow, onClose }) => {
  const { isAuthenticated } = useAppContext();

  const [currentEmployees, setCurrentEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEmployees, setTotalEmployees] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedEmployeeForRemoval, setSelectedEmployeeForRemoval] =
    useState(null);

  useEffect(() => {
    const getCurrentEmployees = async () => {
      try {
        const { results, count } = await api.getEmployeesWithAssignedWorkflow({
          auth: isAuthenticated,
          workflow_id: workflow.id,
          page: currentPage,
        });
        setCurrentEmployees(results);
        setTotalEmployees(count);
        setLoading(false);
      } catch (error) {
        onError(error);
      }
    };
    getCurrentEmployees();
  }, [currentPage, isAuthenticated, workflow.id]);

  if (loading) return <LoaderInline wrapperClasses="w-full min-h-30" />;

  if (currentEmployees.length === 0) {
    return (
      <section>
        <p>
          There are no assigned employees to this workflow. Start assigning them{" "}
          <span
            className="cursor-pointer text-orange"
            onClick={() =>
              switchViewHandler(workflowModalViews.ASSIGN_EMPLOYEES)
            }
          >
            here
          </span>
          .
        </p>
      </section>
    );
  }

  const removeEmployeeHandler = async () => {
    try {
      const response = await api.employeeUnassignWorkflow({
        auth: isAuthenticated,
        employeeId: selectedEmployeeForRemoval.id,
        workflow_id: workflow.id,
      });
      setCurrentEmployees((ce) =>
        ce.filter((employee) => employee.id !== response.id)
      );
      setTotalEmployees((te) => te - 1);
      setShowRemoveModal(false);
      setSelectedEmployeeForRemoval(null);
      onClose();
      toast.success("Employee removed");
    } catch (error) {
      onError(error);
    }
  };

  const closeModalHandler = () => {
    setShowRemoveModal(false);
    setSelectedEmployeeForRemoval(null);
  };

  return (
    <Fragment>
      <ListItems>
        {currentEmployees.map((employee) => (
          <li
            key={employee.id}
            className="relative items-start border-b lg:items-center hover:z-10 focus-within:z-10 border-hka_gray-light"
          >
            <img
              className="mr-2 user-img"
              src={employee.avatar_image}
              alt={employee.avatar_image}
            />
            <div className="flex flex-col items-start flex-1 flex-grow lg:flex-row flex-nowrap lg:items-center">
              <p className="flex flex-1 leading-3 flex-nowrap text-hka_gray-dark">
                {employee.name}
              </p>
            </div>
            <div className="flex justify-end space-x-1 lg:pl-4">
              <button
                onClick={() => {
                  setSelectedEmployeeForRemoval(employee);
                  setShowRemoveModal(true);
                }}
                className="relative px-0 bg-hka_red-light hover:bg-red-200 "
                data-tip="Remove User"
              >
                <span className="text-hka_red icon-trash"></span>
              </button>
            </div>
          </li>
        ))}
        <div className="mt-2">
          <Pagination
            pageSize={10}
            count={totalEmployees}
            currentPage={currentPage}
            onPageChanged={(page) => setCurrentPage(page)}
          />
        </div>
      </ListItems>

      {showRemoveModal && (
        <RemoveAssignedEmployee
          onClose={closeModalHandler}
          onSubmit={removeEmployeeHandler}
          selectedEmployeeForRemoval={selectedEmployeeForRemoval}
        />
      )}
    </Fragment>
  );
};

export default ListAssignedEmployees;
