import { Fragment } from "react";

import { formattedEngagementAnswerType } from "../../../utils/engagementsUtils";
import MessageCardStats from "./MessageCardStats";
import DropdownMenu from "../../Base/DropdownMenu";

const EngagementMessageCard = ({
  preview,
  question,
  questionTitle,
  onQuestionDeleteAttempt,
  onQuestionEdit,
  messageStatistics,
  isLoadingStatistics,
  controlsDisabled = false,
}) => {
  const question_custom_video = question.engagement_custom_video;

  const dropMenuActions = [
    {
      label: "Edit Message",
      icon: "icon-cog",
      onClick: onQuestionEdit,
      disabled: controlsDisabled,
    },
    {
      label: "Delete Message",
      icon: "icon-trash",
      onClick: onQuestionDeleteAttempt,
      disabled: controlsDisabled,
    },
  ];

  return (
    <div className={`${!preview && "card"}`}>
      <section>
        {!preview && (
          <section className="flex items-center justify-between w-full">
            <p className="text-sm">
              {formattedEngagementAnswerType(question.answer_type)}
            </p>

            <DropdownMenu actions={dropMenuActions} />
          </section>
        )}
        <p
          className={`line-clamp-3 font-basis-medium text-base h-5.4 ${
            !preview && "link"
          }`}
          onClick={onQuestionEdit}
        >
          {questionTitle}
        </p>
      </section>

      <section>
        {question_custom_video.length > 0 && (
          <Fragment>
            <div className="flex space-x-0.2 -ml-0.5 h-3.2">
              <span className="icon-video -mt-0.5"></span>
              <div className="truncate">
                <p className="text-sm truncate font-basis-medium">
                  {question_custom_video[0].name}
                </p>
                <p className="text-sm opacity-75">
                  ~ {question_custom_video[0].plays} plays
                </p>
              </div>
            </div>
          </Fragment>
        )}
        {question_custom_video.length === 0 && (
          <Fragment>
            <div className="flex space-x-0.2 -ml-0.5 opacity-50 h-3.2">
              <span className="icon-video -mt-0.5"></span>
              <p className="text-sm italic truncate font-basis-medium">
                No video
              </p>
            </div>
          </Fragment>
        )}
      </section>

      <MessageCardStats
        questionAnswers={question.answers}
        messageStatistics={messageStatistics}
        isLoadingStatistics={isLoadingStatistics}
      />
    </div>
  );
};

export default EngagementMessageCard;
