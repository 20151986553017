import {
  EVENT_TRACKER,
  internalSlackChannelLogger,
} from '../analytics/eventTracker';

export const logEmployeesAssignedTraining = ({
  isAuthenticated,
  training,
  isAllUsersChecked,
  channels = [],
  channelsSync = [],
  manuallySelecetedUsers,
  csvUsers,
  copyFromTraining,
  selectedOktaGroups,
  oktaGroupsSync,
}) => {
  const data = {
    Admin: isAuthenticated.name,
    distinct_id: isAuthenticated.slack_uid,
    Company: isAuthenticated.company_details.title,
    Training: training.curriculum_detail.title,
    'Interaction Origin': 'Web Dashboard',
  };

  if (isAllUsersChecked) {
    data['Is All Users Checked'] = isAllUsersChecked;
  }
  if (channels.length > 0) {
    data['Channels'] = channels.map((c) => c.label).join(', ');
  }
  if (channelsSync.length > 0) {
    data['Sync Channels'] = channelsSync.map((c) => c.label).join(', ');
  }
  if (manuallySelecetedUsers) {
    data['Manually Selected Users'] = manuallySelecetedUsers;
  }
  if (csvUsers && csvUsers.length > 0) {
    data['CSV upload'] = csvUsers.length;
  }
  if (copyFromTraining) {
    data['Copy From Taining'] = copyFromTraining;
  }

  if (selectedOktaGroups?.length > 0) {
    data['Okta Option'] = true;
  }
  if (oktaGroupsSync?.length > 0) {
    data['Sync Okta Groups'] = oktaGroupsSync.map((c) => c.label).join(', ');
  }

  data[
    'Company Link to Admin Panel'
  ] = `${process.env.REACT_APP_API_URL}/admin/company/company/${isAuthenticated.company_details.id}/change`;

  internalSlackChannelLogger(
    EVENT_TRACKER.ADD_USERS_TO_TRAINING,
    isAuthenticated,
    data
  );
};
