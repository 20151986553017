import { useState } from 'react';
import { useExternalAppsData } from '../../hooks/data-hooks/integrations/useExternalAppsData';
import { getSeismicContent } from '../../api/integrationsApi';

import RemoveSeismicContentConfirmModal from './RemoveSeismicContentConfirmModal';
import Note from '../Note';
import SelectInput from '../Base/SelectInput';

const SeismicInput = ({
  seismicContent,
  setSeismicContent,
  removeAssigmentsWhenRemovingSeismic = false,
  setRemoveAssigmentsWhenRemovingSeismic = () => {},
}) => {
  const [removeSeismicContent, setRemoveSeismicContent] = useState(undefined);
  const [changedWarning, setChangedWarning] = useState(false);
  const { data: externalIntegrations } = useExternalAppsData();
  const activeSeismicApp = externalIntegrations?.results.find(
    (ei) => ei.resourcetype === 'SeismicInstallation' && ei.is_active
  );

  const changeEventHandler = (value) => {
    if (value.length > 0) {
      setChangedWarning(true);
      setSeismicContent(value[value.length - 1]);
    }
    if (value.length === 0) {
      setRemoveSeismicContent(true);
    }
  };

  if (!activeSeismicApp) return null;

  return (
    <>
      <SelectInput
        label={{ text: 'Associated Seismic Content', optional: true }}
        value={seismicContent}
        onChange={changeEventHandler}
        placeholder='Search Seismic Content'
        isMultiSelect
        isAsync={{
          apiFunction: async (args) => {
            const response = await getSeismicContent({ ...args });
            return {
              items: response.items.map((i) => ({
                ...i,
                title: `${
                  i.resource_type.charAt(0).toUpperCase() +
                  i.resource_type.slice(1)
                }: ${i.title}`,
              })),
            };
          },
          responseProperty: 'items',
          responseItemName: 'title',
          remoteSearchSupported: false,
        }}
      />
      <section className='w-full mb-2'>
        {changedWarning && (
          <section className='mb-2 mt-1'>
            <Note type='warning'>
              <p className='py-0.2'>
                Changes are not saved yet. Submit the Modal to save it.
              </p>
            </Note>
          </section>
        )}
      </section>

      {removeSeismicContent && (
        <RemoveSeismicContentConfirmModal
          onClose={() => setRemoveSeismicContent(false)}
          onSubmit={() => {
            setSeismicContent('');
            setRemoveSeismicContent(false);
            setChangedWarning(true);
          }}
          removeAssigmentsWhenRemovingSeismic={
            removeAssigmentsWhenRemovingSeismic
          }
          setRemoveAssigmentsWhenRemovingSeismic={
            setRemoveAssigmentsWhenRemovingSeismic
          }
        />
      )}
    </>
  );
};

export default SeismicInput;
