import ActionButton from '../../ActionButton';
import Note from '../../Note';
import { useNavigate, useParams } from 'react-router-dom';

const ExternalAppInstallationsError = () => {
  const navigate = useNavigate();
  const { integration } = useParams();
  const integrationCapitalized =
    integration.charAt(0).toUpperCase() + integration.slice(1);

  return (
    <>
      <section className='mb-2'>
        <Note type='warning'>
          <p>
            There was a trouble integrating with {integrationCapitalized}.
            Please try again or contact Haekka team.
          </p>
        </Note>
      </section>
      <ActionButton
        onClick={() => navigate('/settings?view=integrations')}
        type='secondary'
      >
        <span className='hidden sm:block'>Back to the Integrations page</span>
      </ActionButton>
    </>
  );
};

export default ExternalAppInstallationsError;
