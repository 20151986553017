import { useContext, Fragment } from "react";
import Select from "react-select";

import { slackVariables } from "../../../config";

import AssignEmployeesContext from "../../../store/assign-employeees-context";

import {
  alertEveryoneInChannelOptions,
  engagementTypeOptions,
} from "../../../utils/engagementsUtils";

const EngagementTypeSection = ({
  engagementType,
  setEngagementType,
  alertEveryoneInChannel,
  setAlertEveryoneInChannel,
  channel,
  setChannel,
  disableOptions,
}) => {
  const assignEmployeesContext = useContext(AssignEmployeesContext);
  const { channelOptions, loadingChannelsCompleted, missingScopesError } =
    assignEmployeesContext;

  return (
    <Fragment>
      <section className="flex flex-col items-start justify-between">
        <div className="flex flex-col w-full sm:flex-row">
          <div className="w-full mb-1 sm:mb-0 sm:w-3/5">
            <h4>
              Audience <span className="text-sm text-hka_gray">(Required)</span>
            </h4>
            <p className="text-sm">Assign users or a channel</p>
          </div>

          <div className="flex flex-col flex-1 w-full gap-1 sm:w-auto">
            <Select
              onChange={(option) => setEngagementType(option)}
              value={engagementType}
              placeholder="Select Engagement Audience"
              options={engagementTypeOptions}
              isDisabled={disableOptions}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              classNamePrefix="dropdown--select-audience select"
              unstyled
              menuPortalTarget={document.body}
              maxMenuHeight="17rem"
              menuPlacement="auto"
              isSearchable={false}
            />

            {engagementType?.value === "channel" && (
              <Fragment>
                <Select
                  onChange={(option) => setChannel(option)}
                  value={channel}
                  placeholder="Select channel"
                  options={channelOptions}
                  isDisabled={!loadingChannelsCompleted}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  classNamePrefix="select"
                  unstyled
                  menuPortalTarget={document.body}
                  maxMenuHeight="17rem"
                  menuPlacement="auto"
                />
                {!missingScopesError && !loadingChannelsCompleted && (
                  <p>loading channels...</p>
                )}
                {missingScopesError && !loadingChannelsCompleted && (
                  <p>
                    Hello! Haekka has updated it's Slack Scopes.
                    <br />
                    You need to reinstall the Haekka Slack app. Please click{" "}
                    <a
                      href={slackVariables.shareableSlackAppUrl}
                      className="cursor-pointer text-orange link"
                    >
                      this link
                    </a>{" "}
                    to do so.
                  </p>
                )}
              </Fragment>
            )}
          </div>
        </div>
        {engagementType?.value === "channel" && (
          <section className="flex flex-col justify-between w-full pt-2 mt-2 border-t sm:flex-row border-hka_gray-light">
            <div className="w-full mb-1 sm:mb-0 sm:w-4/5">
              <h4 className="mb-0.2">Channel Alert</h4>
              <p className="text-sm">
                Haekka will mention @channel to alert users that the Engagement
                was sent.
              </p>
            </div>

            <div className="flex flex-col flex-1 w-full gap-1 sm:w-auto">
              <Select
                onChange={(option) => setAlertEveryoneInChannel(option)}
                value={alertEveryoneInChannel}
                options={alertEveryoneInChannelOptions}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                classNamePrefix="select"
                unstyled
                menuPortalTarget={document.body}
                maxMenuHeight="17rem"
                menuPlacement="auto"
              />
            </div>
          </section>
        )}
      </section>
    </Fragment>
  );
};

export default EngagementTypeSection;
