import { getIntegrations } from '../api';
import { onError } from '../libs/errorLib';
import { settingsActions } from './settingsSlice';

export const getIntegrationsAction = ({ auth }) => {
  return async (dispatch) => {
    const getData = async () => {
      return await getIntegrations({
        auth,
      });
    };

    try {
      const integrations = await getData();
      dispatch(settingsActions.setIntegrations({ integrations }));
    } catch (error) {
      onError(error);
    }
  };
};
