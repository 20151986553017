import { useState, useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import {
  prefetchCompanyEngagement,
  prefetchEngagementSchedulesData,
  prefetchEngagementStatisticsData,
  useDeleteEngagementData,
} from "../../hooks/data-hooks/engagements";

import AssignEmployeesContext from "../../store/assign-employeees-context";
import { formatSendCycleMessage } from "../../utils/engagementsUtils";
import DeleteEngagementConfirmationModal from "./confirmationModals/DeleteEngagementConfirmationModal";

const EngagementListItem = ({ companyEngagement, page }) => {
  const queryClient = useQueryClient();
  const { channelOptions } = useContext(AssignEmployeesContext);
  const channel = channelOptions.find(
    (o) => o.value === companyEngagement.channel
  )?.label;

  const [deleteItemWarning, setDeleteItemWarning] = useState(false);

  const { mutate: deleteEngagementMutation } = useDeleteEngagementData({
    page,
    companyEngagement,
    setDeleteItemWarning,
  });

  const deleteEngagementHandler = () => {
    deleteEngagementMutation({ companyEngagementID: companyEngagement.id });
  };

  const onItemHover = () => {
    prefetchCompanyEngagement(queryClient, companyEngagement.id);
    prefetchEngagementStatisticsData(queryClient, companyEngagement.id);
    prefetchEngagementSchedulesData(queryClient, companyEngagement.id);
  };

  return (
    <>
      <li className="list-item--engagement" onMouseEnter={onItemHover}>
        <h4 className="list-item--title">⚡ {companyEngagement.quiz_title}</h4>
        {/* <p className="list-item--send-date">
          <span className="icon-calendar"></span>May 18, 2023
        </p> */}
        <p className="list-item--send-cycle">
          <span className="icon-renew -ml-0.5"></span>
          {formatSendCycleMessage(companyEngagement.send_cycle)}{" "}
        </p>
        {/* <p className="list-item--employees">
          <span className="icon-users"></span>218
        </p> */}
        <p className="list-item--channel">{channel && <>#{channel}</>}</p>
        <div className="list-item--options">
          <Link
            to={`/engagements/${companyEngagement.id}`}
            className="button button--icon"
          >
            <span className="card__action icon-cog"></span>
          </Link>
          <button
            className="button button--icon"
            onClick={() => setDeleteItemWarning(true)}
          >
            <span className="card__action icon-trash"></span>
          </button>
        </div>
      </li>

      {deleteItemWarning && (
        <DeleteEngagementConfirmationModal
          show={deleteItemWarning}
          onSubmit={deleteEngagementHandler}
          onClose={() => setDeleteItemWarning(false)}
          engagementTitle={companyEngagement.quiz_title}
        />
      )}
    </>
  );
};

export default EngagementListItem;
