import { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';

import {
  useCompanyEngagementData,
  useCreateEngagementData,
  useUpdateEngagementData,
} from '../../../hooks/data-hooks/engagements';
import AssignEmployeesContext from '../../../store/assign-employeees-context';

import {
  alertEveryoneInChannelOptions,
  engagementTypeOptions,
  frequencyOptions,
  getDefaultEditorStateForClosingMessage,
  getDefaultEditorStateForWelcomeMessage,
  getEditorStatesForSumbit,
  initializeEditorState,
  roundSendTimeTo30MinutesTop,
  validateAddEngagementForm,
} from '../../../utils/engagementsUtils';
import { useAppContext } from '../../../libs/contextLib';
import Divider from '../../Base/Divider';
import Modal from '../../Modal/Modal';
import Loader from '../../Loader';
import HeaderSection from './HeaderSection';
import TitleSection from './TitleSection';
import EngagementTypeSection from './EngagementTypeSection';
import ScheduleSection from './ScheduleSection';
import OwnersSection from './OwnersSection';
import WelcomeMessage from './WelcomeMessage';
import ClosingMessage from './ClosingMessage';

const EngagementModal = ({ create, show, onClose, isReadOnly }) => {
  const { isAuthenticated } = useAppContext();
  const { id } = useParams();

  const assignEmployeesContext = useContext(AssignEmployeesContext);
  const { channelOptions } = assignEmployeesContext;

  const [title, setTitle] = useState('');
  const [frequency, setFrequency] = useState(frequencyOptions[0]);
  const [startDate, setStartDate] = useState(
    roundSendTimeTo30MinutesTop(new Date())
  );
  const [respectUsersTz, setRespectUsersTz] = useState(false);
  const [anonymizeUserResults, setAnonymizeUserResults] = useState(false);
  const [dynamicStartDate, setDynamicStartDate] = useState(false);
  const [sumbitIsValid, setIsSumbitValid] = useState(false);
  const [engagementType, setEngagementType] = useState(undefined);
  const [channel, setChannel] = useState(undefined);
  const [alertEveryoneInChannel, setAlertEveryoneInChannel] = useState({
    label: 'Yes',
    value: true,
  });
  const [welcomeMessageEnabled, setWelcomeMessageEnabled] = useState(true);
  const [welcomeMessage, setWelcomeMessage] = useState(undefined);
  const [welcomeMessageButtonText, setWelcomeMessageButtonText] =
    useState('Get Started');
  const [closingMessageEnabled, setClosingMessageEnabled] = useState(true);
  const [closingMessage, setClosingMessage] = useState(undefined);
  const [reviewEnabled, setReviewEnabled] = useState(true);
  const [anonymousResultsDisabled, setAnonymousResultsDisabled] =
    useState(false);

  const [owners, setOwners] = useState([
    { label: isAuthenticated.name, value: isAuthenticated.id },
  ]);

  const { data: companyEngagement, isLoading } = useCompanyEngagementData({
    id,
    enabled: !create,
  });

  const { isLoading: isMutating, mutate: createEngagement } =
    useCreateEngagementData();

  const { mutate: updateEngagement } = useUpdateEngagementData({ id, onClose });

  const createSumbitHandler = async () => {
    const {
      editorStateRaw: welcomeEditorStateRaw,
      slackMarkup: welcomeSlackMarkup,
    } = await getEditorStatesForSumbit({ message: welcomeMessage });
    const {
      editorStateRaw: closingEditorStateRaw,
      slackMarkup: closingSlackMarkup,
    } = await getEditorStatesForSumbit({ message: closingMessage });

    createEngagement({
      title,
      company: isAuthenticated.company,
      start_date: startDate,
      send_cycle: frequency.value,
      engagement_type: engagementType.value,
      channel: channel?.value,
      mention_channel: alertEveryoneInChannel?.value,
      respect_users_tz: respectUsersTz,
      created_by: isAuthenticated.id,
      owners: owners.map((o) => o.value),
      welcome_message: {
        editor_state: welcomeEditorStateRaw,
      },
      welcome_message_button_text: welcomeMessageButtonText,
      welcome_message_markup: welcomeSlackMarkup,
      welcome_message_enabled: welcomeMessageEnabled,
      closing_message: {
        editor_state: closingEditorStateRaw,
      },
      closing_message_markup: closingSlackMarkup,
      closing_message_enabled: closingMessageEnabled,
      review_enabled: reviewEnabled,
      anonymize_user_results:
        engagementType.value === 'channel' ? false : anonymizeUserResults,
    });
  };

  const updateSubmitHandler = async () => {
    const {
      editorStateRaw: welcomeEditorStateRaw,
      slackMarkup: welcomeSlackMarkup,
    } = await getEditorStatesForSumbit({ message: welcomeMessage });
    const {
      editorStateRaw: closingEditorStateRaw,
      slackMarkup: closingSlackMarkup,
    } = await getEditorStatesForSumbit({ message: closingMessage });

    updateEngagement({
      sumbitData: {
        title,
        company: isAuthenticated.company,
        start_date: startDate,
        send_cycle: frequency.value,
        engagement_type: engagementType.value,
        channel: channel?.value,
        mention_channel: alertEveryoneInChannel.value,
        respect_users_tz: respectUsersTz,
        created_by: isAuthenticated.id,
        owners: owners.map((o) => o.value),
        welcome_message: {
          editor_state: welcomeEditorStateRaw,
        },
        welcome_message_button_text: welcomeMessageButtonText,
        welcome_message_markup: welcomeSlackMarkup,
        welcome_message_enabled: welcomeMessageEnabled,
        closing_message: {
          editor_state: closingEditorStateRaw,
        },
        closing_message_markup: closingSlackMarkup,
        closing_message_enabled: closingMessageEnabled,
        review_enabled: reviewEnabled,
        anonymize_user_results:
          engagementType.value === 'channel' ? false : anonymizeUserResults,
      },
      companyEngagement,
    });
  };

  useEffect(() => {
    const isFormValid = isReadOnly
      ? false
      : validateAddEngagementForm({
          isMutating,
          title,
          startDate,
          engagementType,
          channel,
          welcomeMessage,
          closingMessage,
          welcomeMessageButtonText,
        });
    setIsSumbitValid(isFormValid);
  }, [
    isMutating,
    startDate,
    engagementType,
    channel,
    title,
    welcomeMessage,
    closingMessage,
    welcomeMessageButtonText,
    isReadOnly,
  ]);

  useEffect(() => {
    const initEditors = async () => {
      const welcomeMessageEditorState = await initializeEditorState(
        companyEngagement.welcome_message.editor_content
      );
      setWelcomeMessage(welcomeMessageEditorState);
      const closingMessageEditorState = await initializeEditorState(
        companyEngagement.closing_message.editor_content
      );
      setClosingMessage(closingMessageEditorState);
    };
    if (!create && companyEngagement) {
      setTitle(companyEngagement.quiz.title);
      setFrequency(
        frequencyOptions.find((o) => o.value === companyEngagement.send_cycle)
      );
      setStartDate(new Date(companyEngagement.start_date).toISOString());
      setDynamicStartDate(
        new Date(
          companyEngagement.start_date_for_dynamic_engagement
        ).toISOString()
      );
      setRespectUsersTz(companyEngagement.respect_users_tz);
      setAnonymizeUserResults(companyEngagement.quiz.anonymize_user_results);
      setEngagementType(
        engagementTypeOptions.find(
          (o) => o.value === companyEngagement.quiz.engagement_type
        )
      );
      setChannel(
        channelOptions.find((o) => o.value === companyEngagement.quiz.channel)
      );
      setAlertEveryoneInChannel(
        alertEveryoneInChannelOptions.find(
          (o) => o.value === companyEngagement.quiz.mention_channel
        )
      );
      setOwners(
        companyEngagement.owners.map((owner) => ({
          label: owner.name,
          value: owner.id,
        }))
      );
      setAnonymousResultsDisabled(
        companyEngagement.quiz.questions.some((q) =>
          ['no_action', 'user_acknowledged', 'external_url'].includes(
            q.answer_type
          )
        ) || engagementType?.value === 'channel'
      );
    }
    if (create) {
      setAnonymousResultsDisabled(engagementType?.value === 'channel');
    }
    if (create || (companyEngagement && !companyEngagement.welcome_message)) {
      const welcomeEditorState = getDefaultEditorStateForWelcomeMessage();
      setWelcomeMessage(welcomeEditorState);
    }
    if (companyEngagement && !!companyEngagement.welcome_message) {
      setWelcomeMessageEnabled(companyEngagement.welcome_message.enabled);
      setWelcomeMessageButtonText(
        companyEngagement.welcome_message.button_text
      );
      setClosingMessageEnabled(companyEngagement.closing_message.enabled);
      setReviewEnabled(companyEngagement.closing_message.show_review_option);
      initEditors();
    }
    if (create || (companyEngagement && !companyEngagement.closing_message)) {
      const closingEditorState = getDefaultEditorStateForClosingMessage();
      setClosingMessage(closingEditorState);
    }
  }, [channelOptions, companyEngagement, create, engagementType?.value]);

  if (!create && isLoading) {
    return <Loader />;
  }

  return (
    <Modal
      show={show}
      title={`${create ? 'Create' : 'Edit'} Engagement`}
      submitButtonText={`${create ? 'Create' : 'Update'} Engagement`}
      onClose={onClose}
      submitValid={sumbitIsValid}
      onSubmit={create ? createSumbitHandler : updateSubmitHandler}
      expandable
      modalClass={`modal--create-engagement`}
    >
      <div className='space-y-2'>
        {create && (
          <>
            <HeaderSection />
            <Divider />
          </>
        )}
        <TitleSection
          title={title}
          setTitle={setTitle}
          isReadOnly={isReadOnly}
        />
        <Divider />
        <EngagementTypeSection
          engagementType={engagementType}
          setEngagementType={setEngagementType}
          alertEveryoneInChannel={alertEveryoneInChannel}
          setAlertEveryoneInChannel={setAlertEveryoneInChannel}
          channel={channel}
          setChannel={setChannel}
          disableOptions={!create || isReadOnly}
        />
        <Divider />

        <div className='flex justify-between gap-2 sm:items-center'>
          <div>
            <h4 className='mb-0.2'>Anonymize User Responses</h4>
            <p className='text-sm'>
              Toggle this option to dissociate user names from their responses (not available for channel engagements).
            </p>
          </div>

          <div>
            <label className='switch'>
              <input
                type='checkbox'
                name='acknowledge_button'
                checked={anonymizeUserResults}
                onChange={(e) => setAnonymizeUserResults(e.target.checked)}
                className='cursor-pointer'
                disabled={isReadOnly || anonymousResultsDisabled}
              />
              <span className='slider'></span>
            </label>
          </div>
        </div>
        <Divider />

        <ScheduleSection
          frequency={frequency}
          setFrequency={setFrequency}
          dynamicStartDate={dynamicStartDate}
          startDate={startDate}
          setStartDate={setStartDate}
          respectUsersTz={respectUsersTz}
          setRespectUsersTz={setRespectUsersTz}
          engagementType={engagementType}
          isReadOnly={isReadOnly}
        />
        <Divider />
        {engagementType?.value === 'private' && (
          <Fragment>
            <WelcomeMessage
              welcomeMessage={welcomeMessage}
              setWelcomeMessage={setWelcomeMessage}
              welcomeMessageEnabled={welcomeMessageEnabled}
              setWelcomeMessageEnabled={setWelcomeMessageEnabled}
              welcomeMessageButtonText={welcomeMessageButtonText}
              setWelcomeMessageButtonText={setWelcomeMessageButtonText}
              isReadOnly={isReadOnly}
            />
            <Divider />
            <ClosingMessage
              closingMessage={closingMessage}
              setClosingMessage={setClosingMessage}
              closingMessageEnabled={closingMessageEnabled}
              setClosingMessageEnabled={setClosingMessageEnabled}
              reviewEnabled={reviewEnabled}
              setReviewEnabled={setReviewEnabled}
              isReadOnly={isReadOnly}
            />
            <Divider />
          </Fragment>
        )}
        <OwnersSection
          owners={owners}
          setOwners={setOwners}
          isReadOnly={isReadOnly}
        />
      </div>
    </Modal>
  );
};

export default EngagementModal;
