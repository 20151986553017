import { useQuery } from '@tanstack/react-query';
import { getWebhookIntegrationAuthHeader } from '../../../api/integrationsApi';

export const useWebhookIntegrationAuthHeaderData = ({
  integration,
  companyID,
  onSuccess,
}) => {
  return useQuery({
    queryKey: ['integration-webhook-auth-header', integration, companyID],
    queryFn: () => getWebhookIntegrationAuthHeader({ integration, companyID }),
    enabled: false,
    onSuccess,
  });
};
