import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from '../libs/contextLib';
import Modal from './Modal/Modal';
import Callout from './Callout';
import SelectEmployeesManually from './AssignEmployees/SelectEmployeesManually';

const AddAdminModal = ({ admins, show, onClose, assignEmployeesContext }) => {
  const { isAuthenticated } = useAppContext();

  const onAdminChange = (admin) => {
    let isAdminAlready = false;
    admins.every((adm) => {
      if (adm.id === admin.value) {
        isAdminAlready = true;
        return false;
      } else {
        isAdminAlready = false;
        return true;
      }
    });
    if (isAdminAlready) {
      assignEmployeesContext.setManualUsersError(
        `${admin.label} is already an admin`
      );
    } else {
      assignEmployeesContext.setManualUsersError(undefined);
      assignEmployeesContext.setSubmitIsValid(true);
    }
  };

  if (!show) return null;

  return (
    <Modal
      show={show}
      title='Add Admin'
      onClose={() => {
        onClose();
      }}
      onSubmit={async () => {
        await assignEmployeesContext.handleSubmit();
        onClose();
      }}
      submitButtonText={
        assignEmployeesContext.submitIsValid ? 'Add Admin' : 'Adding Admin...'
      }
      expandable
      submitValid={
        assignEmployeesContext.submitIsValid ||
        assignEmployeesContext.uploadedCsvUsers.length > 0
      }
    >
      <section>
        {!isAuthenticated.company_details.guest_accounts_included && (
          <Callout
            title={'Looking for guests?'}
            message={
              "You've set Haekka to ignore guests. You can update your settings in the Settings view."
            }
            permanent={false}
          />
        )}
      </section>
      <section className='min-h-20'>
        <SelectEmployeesManually
          assignEmployeesContext={assignEmployeesContext}
          title={'Select Admin'}
          isMulti={false}
          handleEventExternal={(admin) => onAdminChange(admin)}
        />
      </section>
    </Modal>
  );
};

AddAdminModal.propTypes = {
  admins: PropTypes.array,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  assignEmployeesContext: PropTypes.object.isRequired,
  setFetchAdmins: PropTypes.func,
};

export default AddAdminModal;
