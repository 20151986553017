import { onError } from "../libs/errorLib";
import { workflowsActions } from "./workflowsSlice";
import {
  getCompanyWorkflows,
  getEvdtEvents,
  createCompanyWorkflow,
  updateCompanyWorkflow,
  updateLessonImage,
} from "../api";
import { handleWorkflowCreatedTrack } from "../utils/workflowsUtils";

export const fetchWorkflowsData = ({ auth }) => {
  return async (dispatch) => {
    const fetchWorkflows = async () => {
      return await getCompanyWorkflows({
        auth,
      });
    };

    try {
      const { results } = await fetchWorkflows();
      dispatch(workflowsActions.setWorkflows({ workflows: results }));
    } catch (error) {
      onError(error);
    }
  };
};

export const fetchEventsData = ({ auth }) => {
  return async (dispatch) => {
    const fetchEvents = async () => {
      return await getEvdtEvents({
        auth,
      });
    };

    try {
      const { results } = await fetchEvents();
      dispatch(workflowsActions.setEvents({ events: results }));
    } catch (error) {
      onError(error);
    }
  };
};

export const createWorkflowAction = ({
  auth,
  bodyData,
  setObject,
  handleAssigneEmployeesSubmit,
}) => {
  return async (dispatch, getState) => {
    const createWorkflowApi = async () => {
      return await createCompanyWorkflow({ auth, bodyData });
    };
    try {
      const workflow = await createWorkflowApi();
      dispatch(workflowsActions.addWorkflowSuccess({ workflow }));

      const editorRelatedImages = getState().workflows.editorRelatedImages;
      if (editorRelatedImages.length > 0) {
        await updateLessonImage({
          auth,
          id: workflow.id,
          imagesIds: editorRelatedImages,
          contentType: "workflow",
        });
      }

      setObject(workflow);
      handleAssigneEmployeesSubmit(workflow);
      handleWorkflowCreatedTrack({
        auth,
        reqData: {
          workflowID: workflow.id,
          source: workflow.event_source,
          event: workflow.event,
          action: workflow.action,
          destination: workflow.event_destination,
        },
      });
    } catch (error) {
      onError(error);
    }
  };
};

export const updateWorkflowAction = ({
  auth,
  bodyData,
  workflowId,
  setObject,
  handleAssigneEmployeesSubmit,
}) => {
  return async (dispatch) => {
    const updateWorkflowApi = async () => {
      return await updateCompanyWorkflow({ auth, bodyData, workflowId });
    };
    try {
      const workflow = await updateWorkflowApi();
      dispatch(workflowsActions.editWorkflowSuccess({ workflow }));

      setObject(workflow);
      handleAssigneEmployeesSubmit(workflow);
    } catch (error) {
      onError(error);
    }
  };
};
