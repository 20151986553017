import { Fragment } from 'react';
import Select from 'react-select';

import Divider from '../../../Base/Divider';
import EmployeeResultItem from './EmployeeResultItem';

const TrainingAttemptResults = ({
  attempts,
  setSelectedTrainingAttempt,
  selectedTrainingAttempt,
  lessonItems,
  isLoadingData,
  selectAttemptHandler,
}) => {
  return (
    <Fragment>
      {attempts.length > 1 && (
        <div className='w-auto mb-1'>
          <Select
            onChange={setSelectedTrainingAttempt}
            value={selectedTrainingAttempt}
            options={attempts}
            classNamePrefix='select'
            unstyled
            maxMenuHeight='17rem'
            menuPlacement='auto'
            className=''
          />
        </div>
      )}

      <p className='mb-1 text-hka_gray-dark'>Lessons</p>
      <Divider horizontal />

      {lessonItems.map((item, index) => (
        <EmployeeResultItem
          key={item.value.id}
          itemType={'Lesson'}
          title={item.value.title}
          position={index + 1}
          isLoadingData={isLoadingData}
          attemptResults={selectedTrainingAttempt?.data.lesson_attempts.find(
            (la) => la.lesson === item.value.id
          )}
          onAttemptSelect={selectAttemptHandler}
        />
      ))}

      {selectedTrainingAttempt &&
        !!selectedTrainingAttempt.data.finish_date && (
          <div className='flex justify-end w-full'>
            <p className='mt-1 text-sm text-hka_gray-dark'>
              Training Grade:{' '}
              <span className='ml-0.5 font-basis-bold'>
              {(
                (selectedTrainingAttempt.data.earned_score /
                  selectedTrainingAttempt.data.possible_score) *
                100
              ).toFixed(0)}
              %
              </span>
            </p>
          </div>
        )}

      {selectedTrainingAttempt && !selectedTrainingAttempt.data.finish_date && (
        <div className='flex justify-end'>
          <div className='flex flex-col pt-2'>
            <h4 className='w-full font-basis-regular'>Training Grade: N/A</h4>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default TrainingAttemptResults;
