import { useState } from 'react';
import useValidateInputLength from '../../utils/custom-hooks/useValidateInputLength';
import RemoveAnswerExplanationConfirmationModal from './RemoveAnswerExplanationConfirmationModal';

const AnswerExplanation = ({
  answer,
  editExplanationHandler,
  removeExplanationHandler,
  isReadOnly = false,
}) => {
  const showExplanation = answer.explanation?.length >= 0;
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const { showWarning, allowedLength, remainingChars } = useValidateInputLength(
    answer.explanation,
    170,
    200
  );

  const addExplanationHandler = () => {
    editExplanationHandler({ target: { value: '' } });
  };

  return (
    <div className='engagement__answer-explanation'>
      {!showExplanation && !isReadOnly && (
        <p
          className='relative z-10 inline-block text-sm cursor-pointer text-hka_blue font-basis-bold bg-hka_gray-ultraLight'
          onClick={addExplanationHandler}
        >
          + Add Explanation
        </p>
      )}
      {showExplanation && (
        <div className='container--explaination-input'>
          <input
            type='text'
            maxLength={allowedLength}
            value={answer.explanation}
            onChange={editExplanationHandler}
            placeholder={
              'Answer explanation (why this answer is right or wrong)'
            }
            className={`w-full outline-none rounded-r-none text-base border-none ${
              showWarning ? '' : ''
            }`}
            disabled={isReadOnly}
          />

          {showWarning && (
            <p
              className={`${
                showWarning
                  ? 'text-xs text-orange absolute bottom-0.3 left-1.5'
                  : ''
              }`}
            >
              <span className='font-basis-bold'>{remainingChars}</span>{' '}
              characters remaining.
            </p>
          )}
          {showExplanation && (
            <span
              className={`icon-trash flex items-center justify-center sm:relative text-hka_gray cursor-pointer`}
              onClick={() => setShowDeleteConfirmModal(true)}
            ></span>
          )}
        </div>
      )}
      {showDeleteConfirmModal && (
        <RemoveAnswerExplanationConfirmationModal
          showModal={showDeleteConfirmModal}
          onSubmit={() => {
            removeExplanationHandler();
            setShowDeleteConfirmModal(false);
          }}
          onClose={() => setShowDeleteConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default AnswerExplanation;
