import { useState } from 'react';
import Select from 'react-select';

import { useAppContext } from '../../libs/contextLib';
import { useEmployeesData } from '../../hooks/data-hooks/employees/useEmployeesData';

// TO-DO: Check the OwnersSection component - it might have the same purpose
const OwnersInput = ({ owners, setOwners, createNote = null, instanceOf }) => {
  const { isAuthenticated } = useAppContext();
  const { data: admins } = useEmployeesData({
    auth: isAuthenticated,
    page: 1,
    pageSize: 100,
    isAdmin: true,
  });

  const [oneOwnerRequiredWarning, setOneOwnerRequiredWarning] = useState(false);

  const changeOwnerHandler = (data) => {
    if (data.length > 0) {
      setOwners(data);
      setOneOwnerRequiredWarning(false);
    } else {
      setOneOwnerRequiredWarning(true);
    }
  };

  return (
    <>
      <h4 className='mb-0.5 font-basis-bold'>
        Manage {instanceOf} owners{' '}
        <span className='text-sm text-hka_gray'>(Optional)</span>
      </h4>
      <Select
        options={admins?.results.map((a) => ({ value: a.id, label: a.name }))}
        onChange={changeOwnerHandler}
        value={owners}
        classNamePrefix='select'
        className='select--select-employees-manually'
        placeholder={'Select owners'}
        isMulti
        unstyled
      />
      {oneOwnerRequiredWarning && (
        <p className='my-1 text-hka_red'>
          At least one admin has to be selected as an owner.
        </p>
      )}
      {createNote}
    </>
  );
};

export default OwnersInput;
