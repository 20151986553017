import Modal from '../../Modal/Modal';

const RemoveExternalAppConfirmModal = ({
  onSubmit,
  onClose,
  integrationCaption,
}) => {
  return (
    <Modal
      show={true}
      title={`Remove ${integrationCaption} Integration`}
      onSubmit={onSubmit}
      onClose={onClose}
      submitButtonType='danger'
      submitButtonText='Remove Integration'
      compact
    >
      <p>
        Are you sure you want to remove the {integrationCaption} integration?
      </p>
    </Modal>
  );
};

export default RemoveExternalAppConfirmModal;
