import {
  EVENT_ACTION_OPTIONS,
  SENTIMENT_RATINGS_OPTIONS,
} from "../../../utils/workflowsUtils";
import Select from "react-select";
import { customStyles } from "./CreateEvent";
import CustomResponseAction from "./CustomResponseAction";
import AssignTrainingAction from "./AssignTrainingAction";
import { useDispatch, useSelector } from "react-redux";
import { workflowsActions } from "../../../store/workflowsSlice";

const SelectAction = ({ customResponseES, setCustmResponseES }) => {
  const dispatch = useDispatch();
  const { selectedAction, selectedSentimentalRating } = useSelector(
    (state) => state.workflows
  );

  const selectActionHandler = (action) => {
    dispatch(workflowsActions.selectAction({ action }));
  };

  const selectSentimentalRatingHandler = (sentimentalRating) => {
    dispatch(workflowsActions.selectSentimentalRating({ sentimentalRating }));
  };

  return (
    <div className="mt-4">
      <p className="text-base2 text-hka_gray-dark font-basis-medium">
        Tell Haekka to:
      </p>
      <div className="mt-1">
        <div className="">
          <div className={`${selectedAction ? "w-full mb-2" : "w-full"}`}>
            {/* <p className="mb-0.5 text-hka_gray-dark">Action</p> */}
            <Select
              onChange={selectActionHandler}
              value={selectedAction}
              placeholder="Select an Action"
              isMulti={false}
              options={EVENT_ACTION_OPTIONS}
              classNamePrefix="select"
              styles={customStyles}
              maxMenuHeight={125}
              className="w-full"
              unstyled
            />
          </div>
          <div>
            <div
              className={`${
                selectedAction?.value === "admin_message" ? "" : ""
              }`}
            >
              {selectedAction?.value === "custom_response" && (
                <CustomResponseAction
                  customResponseES={customResponseES}
                  setCustmResponseES={setCustmResponseES}
                />
              )}

              {selectedAction?.value === "assign_training" && (
                <AssignTrainingAction />
              )}
            </div>
            {selectedAction && (
              <div className="pl-2 border-l-4">
                <p className=" mb-0.5 text-hka_gray-dark">Sentiment</p>
                <Select
                  defaultValue={SENTIMENT_RATINGS_OPTIONS[1]}
                  onChange={selectSentimentalRatingHandler}
                  value={selectedSentimentalRating}
                  placeholder="Select Risk Level"
                  isMulti={false}
                  options={SENTIMENT_RATINGS_OPTIONS}
                  classNamePrefix="select"
                  styles={customStyles}
                  maxMenuHeight={125}
                  className="w-full mb-1 sm:w-3/4"
                  unstyled
                />
                <p className="w-full text-sm sm:w-3/4">
                  Read{" "}
                  <a
                    href="https://www.haekka.com"
                    target="_blank"
                    rel="noreferrer"
                    className="link font-basis-bold"
                  >
                    this guide
                  </a>{" "}
                  to learn more aboute the Action Sentiment.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectAction;
