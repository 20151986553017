import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Employees from './containers/Employees';
import Login from './containers/Login';
import Employee from './containers/Employee';
import Settings from './containers/Settings';
import Home from './containers/Home';
import Admins from './containers/Admins';
import Trainings from './containers/Trainings';
import Catalog from './containers/Catalog';
import Training from './containers/Training';
import Engagements from './containers/Engagements';
import NotFound from './containers/NotFound';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import SlackInstallRedirect from './components/AppInstallation/SlackInstallRedirect';
import TrainingAttempt from './containers/TrainingAttempt';
import Engagement from './containers/Engagement';
import Workflows from './containers/Workflows';
import Billing from './containers/Billing';
import Phishing from './containers/Phishing';
import Streams from './containers/Streams';

import VantaSuccess from './components/Settings/VantaSuccess';
import VantaError from './components/Settings/VantaError';
import { useAppContext } from './libs/contextLib';
import PhishingCampaignDetails from './containers/PhishingCampaignDetails';
import TrainingVideoLesson from './containers/TrainingVideoLesson';
import EngagementVideoLesson from './containers/EngagementVideoLesson';
import ExternalAppInstallationsSuccess from './components/Settings/Integrations/ExternalAppInstallationsSuccess';
import ExternalAppInstallationsError from './components/Settings/Integrations/ExternalAppInstallationsError';

export default function AppRoutes() {
  const { featureFlags } = useAppContext();

  return (
    <Routes>
      <Route path='/' element={<AuthenticatedRoute component={<Home />} />} />
      <Route
        path='/login'
        element={<UnauthenticatedRoute component={<Login />} />}
      />
      <Route path='/auth/redirect/slack' element={<SlackInstallRedirect />} />
      <Route
        path='/settings'
        element={<AuthenticatedRoute component={<Settings />} />}
      />
      <Route
        path='/trainings'
        element={<AuthenticatedRoute component={<Trainings />} />}
      />
      <Route
        path='/trainings/:id'
        element={<AuthenticatedRoute adminsOnly component={<Training />} />}
      />
      <Route
        path='/engagements'
        element={<AuthenticatedRoute adminsOnly component={<Engagements />} />}
      />
      <Route
        path='/engagements/:id'
        element={<AuthenticatedRoute adminsOnly component={<Engagement />} />}
      />
      <Route
        path='/engagements/attempt/video-lesson'
        element={<AuthenticatedRoute component={<EngagementVideoLesson />} />}
      />
      <Route
        path='/catalog'
        element={<AuthenticatedRoute adminsOnly component={<Catalog />} />}
      />
      <Route
        path='/catalog/:id'
        element={
          <AuthenticatedRoute adminsOnly component={<TrainingAttempt />} />
        }
      />
      <Route
        path='/trainings/attempt/video-lesson'
        element={<AuthenticatedRoute component={<TrainingVideoLesson />} />}
      />
      <Route
        path='/trainings/attempt/:id'
        element={<AuthenticatedRoute component={<TrainingAttempt />} />}
      />
      <Route
        path='/admins'
        element={<AuthenticatedRoute adminsOnly component={<Admins />} />}
      />
      <Route
        path='/employees'
        element={<AuthenticatedRoute allowManagers component={<Employees />} />}
      />
      <Route
        path='/employees/:id'
        element={<AuthenticatedRoute allowManagers component={<Employee />} />}
      />
      <Route
        path='/workflows'
        element={<AuthenticatedRoute adminsOnly component={<Workflows />} />}
      />
      <Route
        path='/streams'
        element={<AuthenticatedRoute adminsOnly component={<Streams />} />}
      />
      <Route
        path='/billing'
        element={<AuthenticatedRoute adminsOnly component={<Billing />} />}
      />

      {featureFlags?.PHISHING && (
        <Route
          path='/phishing'
          element={<AuthenticatedRoute adminsOnly component={<Phishing />} />}
        />
      )}
      {featureFlags?.PHISHING && (
        <Route
          path='/phishing/:phishingCampaignId'
          element={
            <AuthenticatedRoute
              adminsOnly
              component={<PhishingCampaignDetails />}
            />
          }
        />
      )}
      <Route
        path='/vanta/success'
        element={<AuthenticatedRoute adminsOnly component={<VantaSuccess />} />}
      />
      <Route
        path='/vanta/error'
        element={<AuthenticatedRoute adminsOnly component={<VantaError />} />}
      />
      <Route
        path='/integrations/:integration/success'
        element={
          <AuthenticatedRoute
            adminsOnly
            component={<ExternalAppInstallationsSuccess />}
          />
        }
      />
      <Route
        path='/integrations/:integration/error'
        element={
          <AuthenticatedRoute
            adminsOnly
            component={<ExternalAppInstallationsError />}
          />
        }
      />

      {/* Catch-all route for unmatched routes */}
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}
