import React from "react";
import Modal from "../../../Modal/Modal";

export default function RemoveVideoConfirmationModal({
  showModal,
  questionEditorState,
  onSubmit,
  isSubmitValid,
  onClose,
}) {
  return (
    <Modal
      show={showModal}
      title="Delete Message Video"
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonText="Delete Video"
      submitValid={isSubmitValid}
      submitButtonType="danger"
      compact
    >
      <p>
        Are you sure you want to delete this video?
        {/* {questionEditorState.getCurrentContent().getPlainText("\u0001") !== ""
            ? questionEditorState.getCurrentContent().getPlainText("\u0001")
            : "Question"}
          . */}
      </p>
    </Modal>
  );
}
