import Modal from '../../../Modal/Modal';

const ConfirmRoleDeleteModal = ({ onSubmit, onClose, roleCaption }) => {
  return (
    <Modal
      show={true}
      title={`Confirm ${roleCaption} Role deletion`}
      submitButtonText='Yes, delete Role'
      submitButtonType='danger'
      onSubmit={onSubmit}
      onClose={onClose}
      compact
    ></Modal>
  );
};

export default ConfirmRoleDeleteModal;
