import { useQuery } from "@tanstack/react-query";
import { getCompanyEngagement } from "../../../api/engagementsApi";

const staleTime = 3000;

export const useCompanyEngagementData = ({ id, enabled = true }) => {
  return useQuery({
    queryKey: ["engagement", id],
    queryFn: () => getCompanyEngagement({ companyEngagementID: id }),
    enabled,
  });
};

export const prefetchCompanyEngagement = (queryClient, companyEngagementID) => {
  queryClient.prefetchQuery({
    queryKey: ["engagement", companyEngagementID],
    queryFn: () => getCompanyEngagement({ companyEngagementID }),
    staleTime,
  });
};
