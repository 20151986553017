import { useContext } from "react";
import AssignEmployeesContext from "../../store/assign-employeees-context";
import SelectEmployeesManually from "./SelectEmployeesManually";

const ManuallyOptions = () => {
  const assignEmployeesContext = useContext(AssignEmployeesContext);

  return (
    <section className="mb-3">
      <h4 className="mb-1 font-basis-bold">
        Add users one-by-one from all Haekka sources{" "}
        <span className="text-sm text-hka_gray">(Optional)</span>
      </h4>
      <SelectEmployeesManually
        assignEmployeesContext={assignEmployeesContext}
      />
    </section>
  );
};

export default ManuallyOptions;
