import { format } from "date-fns";
import { useState, Fragment } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";

import { generateEngagementOverviewReport } from "../../api/engagementsApi";
import { useEngagementSchedulesData } from "../../hooks/data-hooks/engagements";

import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import ActionButton from "../ActionButton";
import Divider from "../Base/Divider";
import Modal from "../Modal/Modal";
import { readFileNameFromHeader } from "../../utils/commonUtils";

const ReportListItem = ({
  isFirst,
  isLast,
  scheduleID,
  itemTitle,
  startDate,
  endDate,
  disabled,
}) => {
  const { isAuthenticated: auth } = useAppContext();

  const [downloading, setDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [progressBarVisible, setProgressVisible] = useState(false);

  const formatDate = (date) => format(date, "MM/dd/yy");

  const onDownloadProgress = ({ total, loaded }) => {
    setDownloadProgress(((loaded / total) * 100).toFixed(2));
  };

  const onDownloadReportClick = async () => {
    try {
      setDownloadProgress(0);
      setDownloading(true);
      setProgressVisible(true);
      const { data, headers } = await generateEngagementOverviewReport({
        auth,
        scheduleID,
        onDownloadProgress,
      });

      const blob = new Blob([data], {
        type: "pdf",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = readFileNameFromHeader(headers["content-disposition"]);
      link.click();

      toast.success("Report Downloaded!");
    } catch (error) {
      onError(error);
    }
    setDownloading(false);
  };

  return (
    <Fragment>
      {isFirst && <Divider />}
      <div className="flex flex-row justify-between">
        <div className="flex flex-col justify-center">
          <p className="text-md font-basis-medium">
            {formatDate(startDate)} - {formatDate(endDate)}
          </p>
          {/* <p>{itemTitle}</p> */}
        </div>

        <ActionButton
          onClick={onDownloadReportClick}
          type="primary"
          customCss="px-0"
          disabled={disabled || downloading}
        >
          <span className="icon-download text-hka_blue"></span>
        </ActionButton>
      </div>

      {progressBarVisible && (
        <div className="mb-2">
          <ProgressBar
            completed={downloadProgress}
            isLabelVisible={false}
            width={"100%"}
            height={"100%"}
            borderRadius={"1rem"}
            labelClassName={"label--cert-download--transparent"}
            baseBgColor={"#EFF0F7"}
            bgColor={"#0094FF"}
          />
        </div>
      )}

      {!isLast && <Divider />}
    </Fragment>
  );
};

const EngagementOverviewReportsModal = ({ show, onCancel }) => {
  const { id: companyEngagementID } = useParams();

  const { data: schedules } = useEngagementSchedulesData({
    companyEngagementID,
    transformData: false,
  });

  return (
    <Modal
      noFooter
      show={show}
      title="Download Overview Report"
      onClose={onCancel}
    >
      <div className="space-y-1 overflow-y-auto">
        {schedules?.map((schedule, i) => {
          const startDate = new Date(schedule.created);
          const endDate = new Date(
            schedule[i + 1] ? schedule[i + 1].created : Date.now()
          );

          if (schedules[i + 1]) {
            endDate.setDate(endDate.getDate() - 1);
          }

          return (
            <ReportListItem
              key={i}
              isFirst={i === 0}
              isLast={i === schedules.length - 1}
              scheduleID={schedule.id}
              startDate={startDate}
              endDate={endDate}
              itemTitle={schedule.engagement_title}
              disabled={!schedule.is_sent}
            />
          );
        })}
      </div>
    </Modal>
  );
};

export default EngagementOverviewReportsModal;
