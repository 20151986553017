import UpgradePlanButton from "../../UpgradePlanButton";

const WarningMessage = ({
  isFreeStream,
  hasPaidStreams,
  companySubscribed,
  postsLength,
  globalContentMaker,
  textAlter,
}) => {
  let warningMessage = "";
  let icon = undefined;
  if (!isFreeStream && !hasPaidStreams && !globalContentMaker) {
    warningMessage = (
      <p className="mt-2 text-center">
        You don't have access to this Stream. Please{" "}
        <UpgradePlanButton parentCpmToTrack={"Streams"} inline /> to subscribe.
      </p>
    );
    icon = "lock";
  } else if (!companySubscribed && !globalContentMaker) {
    warningMessage = "You aren't subscribed to this stream.";
  } else if (postsLength === 0) {
    warningMessage = `This stream has no ${textAlter} posts yet.`;
  }
  return (
    <div className="flex flex-col justify-center w-full p-3 text-base border rounded-lg bg-hka_gray-ultraLight border-hka_gray-light">
      {icon && (
        <img
          src="https://haekka-product-assets.s3.us-west-2.amazonaws.com/hka_upgrade_streams.svg"
          alt=""
          className="m-auto"
        />
      )}
      {warningMessage}
    </div>
  );
};

export default WarningMessage;
