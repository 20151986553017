import React from "react";
import { AreaChart, Area, Tooltip, ResponsiveContainer, XAxis } from "recharts";

const ChartTooltip = ({ active, payload }) => {
  if (!active || !payload?.length) return null;

  const label = payload[0].payload.name;
  const value = Math.round(payload[0].value);

  return (
    <div className="flex flex-col px-1 py-0.5 -mt-2 -ml-1 bg-hka_gray-dark rounded-md">
      <p className="text-xs font-basis-bold text-hka_gray-ultraLight leading-1.4">
        {label}
      </p>
      <p className="text-xs font-basis-bold text-hka_gray-light opacity-80 leading-1.4">
        {value} {value === 1 ? "Engagement" : "Engagements"} Completed
      </p>
    </div>
  );
};

export default function EngagementTrendsChart({
  trends,
  gradientName,
  primaryColor,
  secondaryColor,
  gradientStartOpacity,
  gradientEndOpacity,
  aspectRatio,
  borderColorClass,
}) {
  const data = Object.keys(trends)
    .map((key) => ({
      name: key,
      value: trends[key],
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const tooltipPosition = {
    x: XAxis,
    y: -20,
  };

  const tooltipCursorStyles = {
    stroke: primaryColor,
    strokeDasharray: 3,
  };

  const activePointStyles = {
    cy: 3,
    fill: primaryColor,
  };

  const fillURLValue = `url(#${gradientName})`;

  return (
    <ResponsiveContainer
      width="99%"
      height="auto"
      aspect={aspectRatio}
      className={`border-b ${borderColorClass}`}
    >
      <AreaChart data={data} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
        <Tooltip
          id={secondaryColor}
          content={<ChartTooltip />}
          allowEscapeViewBox={{ x: true, y: true }}
          position={tooltipPosition}
          cursor={tooltipCursorStyles}
        />
        <defs>
          <linearGradient id={gradientName} x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor={primaryColor}
              stopOpacity={gradientStartOpacity}
            />
            <stop
              offset="100%"
              stopColor={secondaryColor}
              stopOpacity={gradientEndOpacity}
            />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="value"
          stroke="none"
          fill={fillURLValue}
          activeDot={activePointStyles}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
