import React from "react";

export default function Slider({
  checked = false,
  disabled = false,
  label = null,
  labelLeft = false,
  labelTextSize = "text-base",
  onChange,
}) {
  return (
    <section
      className={`flex items-center ${
        labelLeft ? "flex-row-reverse justify-end" : "flex-row"
      }`}
    >
      <label className="switch">
        <input
          disabled={disabled}
          type="checkbox"
          name="has_weekly_quiz"
          checked={checked}
          onChange={onChange}
          className="cursor-pointer"
        />
        <span className="slider"></span>
      </label>
      {label && (
        <p className={`${labelTextSize} ${labelLeft ? "mr-1" : "ml-1"}`}>
          {label}
        </p>
      )}
    </section>
  );
}
