import { Fragment } from "react";
import EmployeeMetrics from "./Metrics/EmployeeMetrics";
import TrainingMetrics from "./Metrics/TrainingMetrics";
import EngagementMetrics from "./Metrics/EngagementMetrics";
import PhishingMetrics from "./Metrics/PhishingMetrics";

const Statistics = ({
  activeCompanyTrainings,
  finishedTraining30,
  finishedTrainingTotal,
  finishedTraining60,
  finishedEmployeeLessons30,
  finishedEmployeeLessonsTotal,
  finishedEmployeeLessons60,
  employeesWithTrainingAssigned,
  totalEmployees,
  finishedEngagementsInLast30Days,
  finishedEngagementsOfAllTime,
  activeEngagementsCount,
  engagementTrendsInLast30Days,
  engagementTrendsOfAllTime,
  phishingMetrics,
  hasPhishingAccess,
  isAuthenticated,
}) => {
  return (
    <Fragment>
      <TrainingMetrics
        activeCompanyTrainings={activeCompanyTrainings}
        finishedTraining30={finishedTraining30}
        finishedTraining60={finishedTraining60}
        finishedTrainingTotal={finishedTrainingTotal}
        finishedEmployeeLessons30={finishedEmployeeLessons30}
        finishedEmployeeLessons60={finishedEmployeeLessons60}
        finishedEmployeeLessonsTotal={finishedEmployeeLessonsTotal}
      />

      <EngagementMetrics
        finishedEngagementsInLast30Days={finishedEngagementsInLast30Days}
        finishedEngagementsOfAllTime={finishedEngagementsOfAllTime}
        activeEngagementsCount={activeEngagementsCount}
        engagementTrendsInLast30Days={engagementTrendsInLast30Days}
        engagementTrendsOfAllTime={engagementTrendsOfAllTime}
      />

      <EmployeeMetrics
        employeesWithTrainingAssigned={employeesWithTrainingAssigned}
        totalEmployees={totalEmployees}
        isAuthenticated={isAuthenticated}
      />

      {phishingMetrics && hasPhishingAccess && (
        <PhishingMetrics phishingMetrics={phishingMetrics} />
      )}

      <section className="mb-2 space-y-1"></section>
    </Fragment>
  );
};

export default Statistics;
