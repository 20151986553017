import { CURRICULUM_ITEM_TYPES } from '../../../utils/training';

const CurriculumContentItem = ({
  item,
  viewMode,
  openItemHandler,
  itemDeleteAttemptHandler,
  showActionButtons,
  curriculumContent,
  handle,
}) => {
  const itemPosition = () => ` ${item.itemSpecificPosition}`;

  const itemTitle = () => {
    let title = '';
    if (item.type === CURRICULUM_ITEM_TYPES.LESSON) {
      title = item.value.title;
      if (title.includes('|')) {
        title = title
          .replace('| ', '|')
          .substring(title.indexOf('|') + 1, title.length);
      }
    } else if (item.type === CURRICULUM_ITEM_TYPES.ASSESSMENT) {
      title = item.value.assessment.title;
    }
    return title;
  };

  const getItemsToSkipLine = () => {
    if (item.skipLessonsTotal === 0) {
      return (
        <>
          <span className='font-basis-bold'>N/A </span>
        </>
      );
    }
    if (item.value.lessons_to_skip.length === 1) {
      const lesson_to_skip = item.value.lessons_to_skip[0];
      const lessonPosition = curriculumContent.find(
        (item) => item.value.id === lesson_to_skip
      ).itemSpecificPosition;
      return (
        <>
          <span className='font-basis-bold'>Lesson {lessonPosition} </span>
        </>
      );
    }

    const lessons_to_skip = item.value.lessons_to_skip;

    const lessonsToSkip = curriculumContent
      .filter((item) => lessons_to_skip.includes(item.value.id))
      .map((item) => item.itemSpecificPosition);

    return (
      <>
        <span className='font-basis-bold'>
          Lesson {lessonsToSkip.join(', ')}{' '}
        </span>
      </>
    );
  };

  const getGradingThresholdLine = () => {
    if (item.gradingThreshold === 0) {
      return <span className='font-basis-bold mr-0.5'>N/A</span>;
    }
    return (
      <span className='font-basis-bold mr-0.5'>{item.gradingThreshold}</span>
    );
  };

  const statsLine = () => {
    if (item.type === CURRICULUM_ITEM_TYPES.LESSON) {
      return (
        <p className='text-sm'>
          <span className='font-basis-bold'>
            {item.value.employees_finished}
          </span>{' '}
          /{' '}
          <span className='font-basis-bold mr-0.5'>
            {item.value.employees_assigned}
          </span>
          Employees have completed this lesson.
        </p>
      );
    } else if (item.type === CURRICULUM_ITEM_TYPES.ASSESSMENT) {
      return (
        <p className='text-sm'>
          Skips: {getItemsToSkipLine()} Grading Threshold:{' '}
          {getGradingThresholdLine()}
        </p>
      );
    }
  };

  const onItemOpenAttempt = () => {
    if (showActionButtons) {
      openItemHandler();
    }
  };

  if (viewMode === 'TILE_MODE') {
    return (
      <div className='relative z-0 flex flex-col p-2 bg-white rounded-lg hover:z-10 shadow-light lesson-card h-13'>
        {showActionButtons && (
          <div className='absolute card__actions top-1.2 right-1.2'>
            <span
              className='card__action icon-cog'
              onClick={onItemOpenAttempt}
              data-tip={`Edit ${item.type}`}
            ></span>

            <span
              className='card__action icon-trash'
              onClick={itemDeleteAttemptHandler}
              data-tip={`Remove ${item.type}`}
            ></span>

            <span data-tip='Reorder Items'>{handle}</span>
          </div>
        )}

        <div>
          <p className='text-sm mb-0.2'>{item.type + itemPosition()}</p>
          <h4
            className={`flex-1 ${
              showActionButtons ? 'cursor-pointer' : ''
            } line-clamp-2 mb-2 font-basis-medium link--bold sm:max-w-full h-3.6`}
            onClick={onItemOpenAttempt}
          >
            {itemTitle()}
          </h4>
        </div>

        {statsLine()}
      </div>
    );
  } else {
    return (
      <div className='flex items-center pl-1 pr-1.5 py-1 mt-1 bg-white rounded-lg shadow-light'>
        {showActionButtons && (
          <button
            className='relative w-2.4 h-4 min-w-0 mr-1 rounded-md px-0 hover:bg-hka_gray-light'
            data-tip='Reorder Items'
          >
            {handle}
          </button>
        )}
        <div className='flex-1'>
          <p className='text-sm text-hka_gray'>{item.type + itemPosition()}</p>
          <p
            className={`${
              showActionButtons ? 'cursor-pointer' : ''
            } link--bold line-clamp-1`}
            onClick={onItemOpenAttempt}
            title={item.title}
          >
            {itemTitle()}
          </p>
        </div>

        <div className='flex-1'>{statsLine()}</div>

        {showActionButtons && (
          <div className='flex space-x-0.5'>
            <span
              className='flex items-center justify-center w-2.4 h-2.4  transition-all rounded-lg cursor-pointer icon-cog text-hka_gray hover:bg-hka_gray-light'
              onClick={onItemOpenAttempt}
              data-tip={`Edit ${item.type}`}
            ></span>
            <span
              className='flex items-center justify-center w-2.4 h-2.4 transition-all rounded-lg cursor-pointer icon-trash text-hka_gray hover:bg-hka_gray-light'
              onClick={itemDeleteAttemptHandler}
              data-tip={`Remove ${item.type}`}
            ></span>
          </div>
        )}
      </div>
    );
  }
};

export default CurriculumContentItem;
