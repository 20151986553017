import { useMutation, useQueryClient } from '@tanstack/react-query';
import { reorderCurriculumItems } from '../../../api/trainingsApi';

export const useReorderCurriculumItems = ({ curriculumID, reorderErrorCb }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: reorderCurriculumItems,
    onSuccess: () => {
      queryClient.invalidateQueries(['curriculum-content', curriculumID]);
    },
    onError: (_error) => reorderErrorCb(_error.response.data),
  });
};
