const buildBillingUrl = (user) => {
  const BACKEND_URL = process.env.REACT_APP_API_URL;
  const FREE_PLAN_MAX_USERS = 10000;

  if (!user?.company_details) return "";

  const plan = user.company_details;
  const subscriptionUrl = plan.subscription_url;

  if (plan.max_users === FREE_PLAN_MAX_USERS) {
    return `${BACKEND_URL}${subscriptionUrl}`;
  }

  return subscriptionUrl;
};

export default buildBillingUrl;
