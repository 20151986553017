import Modal from '../Modal/Modal';

const RemoveSeismicContentConfirmModal = ({
  onSubmit,
  onClose,
  removeAssigmentsWhenRemovingSeismic,
  setRemoveAssigmentsWhenRemovingSeismic,
}) => {
  return (
    <Modal
      show={true}
      title='Remove Seismic Content'
      onSubmit={onSubmit}
      onClose={onClose}
      submitButtonType='danger'
      submitButtonText='Remove Association'
      compact
    >
      <p>Are you sure you want to remove the associated Seismic Content?</p>

      <section className='mt-2 mb-1 flex'>
        <section className='flex items-center'>
          <label className='switch'>
            <input
              type='checkbox'
              checked={removeAssigmentsWhenRemovingSeismic}
              onChange={() => setRemoveAssigmentsWhenRemovingSeismic((s) => !s)}
              className='cursor-pointer'
            />
            <span className='slider'></span>
          </label>
        </section>
        <p
          className='ml-1 text-sm cursor-pointer'
          onClick={() => setRemoveAssigmentsWhenRemovingSeismic((s) => !s)}
        >
          Do you want to unassign all Seismic users?
        </p>
      </section>
    </Modal>
  );
};

export default RemoveSeismicContentConfirmModal;
