import Checkbox from './Checkbox';

export const checkboxOptions = {
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT',
  NEUTRAL: 'NEUTRAL',
};

const CheckboxGroup = ({
  disabled,
  disabledCorrect,
  disabledIncorrect,
  selectedOption,
  onSelect,
}) => {
  const onSelectHandler = (option) => {
    if (disabled) return;
    if (disabledCorrect && option === checkboxOptions.CORRECT) return;
    if (disabledIncorrect && option === checkboxOptions.INCORRECT) return;
    onSelect(option);
  };

  return (
    <span className='flex gap-0.7 mx-1'>
      <Checkbox
        isCorrect={checkboxOptions.CORRECT}
        disabled={disabled || disabledCorrect}
        selected={selectedOption === checkboxOptions.CORRECT}
        onSelect={onSelectHandler}
      />
      <Checkbox
        isCorrect={checkboxOptions.INCORRECT}
        disabled={disabled || disabledIncorrect}
        selected={selectedOption === checkboxOptions.INCORRECT}
        onSelect={onSelectHandler}
      />
    </span>
  );
};

export default CheckboxGroup;
