import { useState } from "react";
import ActionButton from "./ActionButton";

const Expander = ({
  title,
  menuItems = null,
  actionButtons = null,
  onExpanderToggle = undefined,
  children,
}) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpanderToggle = () => {
    const value = !expanded;
    setExpanded(value);

    if (onExpanderToggle) {
      onExpanderToggle(value);
    }
  };

  return (
    <section className="mb-2">
      <div className="flex items-center justify-between">
        <div className="flex flex-row items-center">
          <p
            className="mr-0.5 cursor-pointer font-basis-medium text-hka_gray-dark leading-4"
            onClick={() => setExpanded(!expanded)}
          >
            {title}
          </p>
          <span
            className={`sm:inline-block icon-chevron text-hka_gray cursor-pointer transition-all ${
              expanded ? "transform rotate-180" : ""
            }`}
            onClick={handleExpanderToggle}
          ></span>
        </div>
        {expanded && (
          <div className="flex flex-row space-x-0 sm:space-x-1">
            {actionButtons && actionButtons?.length > 0 && (
              <div className="flex items-center mr-0.5">
                {actionButtons.map(({ text, onClick, type }, i) => (
                  <ActionButton key={i} onClick={onClick} type={type}>
                    <span className="px-2 sm:px-0 font-basis-medium">
                      {text}
                    </span>
                  </ActionButton>
                ))}
              </div>
            )}
            {menuItems && menuItems?.length > 0 && (
              <div className="flex items-center bg-white border rounded-lg border-hka_gray-border expander__options">
                {menuItems.map(({ icon, onClick, label, active }, i) => (
                  <span
                    key={i}
                    className="min-w-0 px-0 cursor-pointer text-hka_gray py-0.5 expander__option"
                    onClick={onClick}
                  >
                    <span
                      className={`${icon} ${
                        active ? "text-orange" : "text-hka_gray"
                      } w-3.8 h-2.8 flex items-center justify-center expander__option-icon`}
                      data-tip={label}
                    ></span>
                  </span>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <div>{expanded && children}</div>
    </section>
  );
};

export default Expander;
