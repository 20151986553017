import { useState } from 'react';
import * as api from '../api';
import { useAppContext } from '../libs/contextLib';
import { onError } from '../libs/errorLib';

const RenewTrainingSection = ({ training, onRenewSuccess }) => {
  const { isAuthenticated } = useAppContext();
  const [areYouSurePrompt, setAreYouSurePrompt] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [exludeUsers, setExludeUsers] = useState(false);

  const handleSubmit = async () => {
    setAreYouSurePrompt(false);
    setShowMessage(true);
    try {
      const newTrainingId = await api.renewTrainingWithNewCurriculum({
        auth: isAuthenticated,
        trainingId: training.id,
        exludeUsers,
      });
      onRenewSuccess(newTrainingId);
    } catch (error) {
      onError(error);
    }

    setShowMessage(true);
  };

  return (
    <section className='w-full mb-2'>
      <h4 className='mb-0.5 font-basis-bold'>Manually Renew Training</h4>
      {!showMessage && (
        <>
          <section className='mb-2'>
            <p className='mb-1 text-sm'>
              🔄 Please be aware that manually renewing this training will
              effectively reset it for all assigned employees. As a result, we
              recommend{' '}
              <a
                href='https://www.haekka.com/documentation/how-to-download-training-evidence'
                className='link'
                target='_blank'
                rel='noreferrer'
              >
                downloading{' '}
              </a>
              a current version of this trainings evidence{' '}
              <strong>before</strong> performing this action.
            </p>
            <p className='mb-1 text-sm'>
              ⛔ Optionally, you can also exclude employees that have already
              completed this training for the calendar year{' '}
              <strong>{new Date().getFullYear()}</strong>. These employees will
              still be assigned to this training. However, their completion will
              remain 100%. Check the toggle below to exclude these users.
            </p>
            <div className='flex items-center'>
              <label className='switch'>
                <input
                  type='checkbox'
                  name='users_completed_training_admin'
                  checked={exludeUsers}
                  onChange={() => setExludeUsers((s) => !s)}
                />
                <span className='slider'></span>
              </label>
            </div>
          </section>
          <button
            onClick={() => setAreYouSurePrompt(true)}
            className={
              'button button--gray w-full sm:w-auto ' +
              (areYouSurePrompt ? 'hidden' : 'visible')
            }
          >
            Renew Training Now
          </button>
        </>
      )}
      {areYouSurePrompt && (
        <>
          <p className='mb-1'>
            Are you sure you want to manually renew this training?
          </p>
          <button
            onClick={handleSubmit}
            className='w-full button button--gray sm:w-auto'
          >
            Yes, I'm sure
          </button>
        </>
      )}
      {showMessage && (
        <p className='my-1'>
          👍 Great! We'll start renewing this training right away. Depending on
          the number of employees assigned, this action can take a few minutes.
          We'll notify you via <strong>Slack</strong> as soon as the process is
          completed.
        </p>
      )}
    </section>
  );
};

export default RenewTrainingSection;
