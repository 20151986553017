import React from "react";
import "./styles.css";

export default function TemplateCard({ template, selected, onSelect }) {
  const styles = {
    notSelected: "bg-white border-hka_gray-border",
    selected: "border-hka_blue bg-opacity-10",
  };
  return (
    <div
      className={`p-2 rounded-lg cursor-pointer space-y-1 border-2 ${
        selected ? styles.selected : styles.notSelected
      }`}
      onClick={() => onSelect(template, selected)}
    >
      <div className="">
        <div className="flex items-center justify-between mb-1">
          <div className="flex flex-row">
            <img
              className="w-2 h-2 mr-0.3 rounded"
              src={
                template.icon ||
                "https://haekka-author-icons.s3.us-west-2.amazonaws.com/HKA--UPDATED--Author_Haekka.png"
              }
              alt="Icon"
            ></img>
            <p className="font-basis-medium text-base2 text-hka_gray-dark">
              {template.application || "Haekka"}
            </p>
          </div>
          {selected && (
            <div className="template-card--selected">
              <span className="icon-check"></span>
            </div>
          )}
        </div>
        <p className="w-full mb-1 text-sm truncate font-basis-medium text-hka_gray-dark">
          {template.name}
        </p>
        {template.category && (
          <p className="text-xs opacity-75 font-basis-bold">
            {template.category.name}
          </p>
        )}
      </div>
    </div>
  );
}
