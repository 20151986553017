import { useAppContext } from '../../libs/contextLib';

import { useGlobalStore } from '../../store';

const StreamsHeader = ({ currentPage }) => {
  const { isAuthenticated } = useAppContext();
  const { setStreamModal } = useGlobalStore((state) => state.streams);

  return (
    <>
      <header className='mb-1'>
        <section className='flex justify-between mb-4'>
          <h1>Streams</h1>
          {isAuthenticated.company_details.is_global_content_maker && (
            <button
              onClick={() =>
                setStreamModal({
                  show: true,
                  mode: 'CREATE_STREAM',
                  currentPage,
                })
              }
              type='button'
              className='button button--blue bg-hka_blue-light button__icon--left'
            >
              <span className='icon-plus text-hka_blue'></span>
              <span className='hidden sm:flex font-basis-medium'>
                Create Stream
              </span>
            </button>
          )}
        </section>
      </header>
    </>
  );
};

export default StreamsHeader;
