import React from "react";
import { Doughnut } from "react-chartjs-2";
import { white } from "tailwindcss/colors";
import {
  getActionTypeChartColor,
  getActionTypeLabel,
} from "../../../../utils/phishingUtils";
import DonutChartLegend from "./DonutChartLegend";

export default function PhishingMetricsDonutChart({ phishingMetrics }) {
  const dataMap = new Map();

  if (phishingMetrics) {
    Object.keys(phishingMetrics).forEach((key) => {
      const label = getActionTypeLabel(key);
      const value = phishingMetrics[key];

      if (dataMap.has(label)) {
        dataMap.set(label, dataMap.get(label) + value);
      } else {
        dataMap.set(label, value);
      }
    });
  }

  const data = {
    labels: Array.from(dataMap.keys()),
    datasets: [
      {
        label: "Phishing Actions",
        data: Array.from(dataMap.values()),
        backgroundColor: Array.from(dataMap.keys()).map(
          getActionTypeChartColor
        ),
        borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
        borderWidth: 1,
        hoverBorderColor: white,
        borderRadius: 2,
      },
    ],
  };

  return (
    <div className="flex flex-row justify-between">
      <div
        style={{ maxWidth: "60px" }}
        className="flex flex-col justify-center"
      >
        <Doughnut
          data={data}
          options={{
            cutout: "75%",
          }}
        />
      </div>
      <DonutChartLegend metricsDataMap={dataMap} borderWidth={1} />
    </div>
  );
}
