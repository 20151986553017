import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { useAppContext } from '../../../../../libs/contextLib';
import { settingsActions } from '../../../../../store/settingsSlice';
import LoaderInline from '../../../../LoaderInline';
import IntegrationCard from '../../IntegrationCard';
import useInitiateMerge from '../../../../../hooks/utility-hooks/useInitiateMerge';

const GustoNew = () => {
  const { isAuthenticated } = useAppContext();
  const dispatch = useDispatch();

  const cb = () => {
    dispatch(
      settingsActions.setIntegrations({
        integrations: { ...integrations, gusto: true },
      })
    );
    toast.success('You have successfully connected with Okta.');
  };

  const { integrations } = useSelector((state) => state.settings);

  const [isReady, open] = useInitiateMerge({
    isAuthenticated,
    integration: 'gusto',
    cb,
  });

  if (!isReady) return <LoaderInline wrapperClasses={'w-20'} loaderSize={2} />;

  return (
    <IntegrationCard
      integration='gusto'
      src='https://haekka-public-images.s3.us-west-2.amazonaws.com/gusto-logo-vector.svg'
      alt='Gusto Logo'
      imgCssClasses='w-8 -mt-2'
      btnClick={open}
      btnText={integrations?.gusto ? 'Update Gusto' : 'Link with Gusto'}
      connected={integrations?.gusto}
    />
  );
};

export default GustoNew;
