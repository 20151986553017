import { convertToRaw } from "draft-js";
import { convertToSlackNotation } from "./wysiwygEditorHelpers";

export async function optimizeSlackMarkup(id, editorState) {
  const editorStateRaw = JSON.stringify(
    convertToRaw(editorState.getCurrentContent())
  );

  const editor_content = {
    editor_state: editorStateRaw,
    content: [],
  };

  if (id) {
    editor_content["id"] = id;
  }

  const slackMarkup = await convertToSlackNotation(
    editorState.getCurrentContent()
  );

  return { editor_content, slackMarkup };
}
