import { formatSendCycleMessage } from "../../utils/engagementsUtils";

const EmployeeEngagementItem = ({ employeeEngagement }) => {
  return (
    <li className="list-item--engagement">
      <h4 className="list-item--title">
        ⚡ {employeeEngagement.weekly.quiz.title}
      </h4>

      <p className="list-item--send-cycle">
        <span className="icon-renew -ml-0.5"></span>
        {formatSendCycleMessage(employeeEngagement.weekly.send_cycle)}
      </p>
    </li>
  );
};

export default EmployeeEngagementItem;
