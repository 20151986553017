import Modal from '../../Modal/Modal';

const SubscribeToStreamModal = ({
  streamTitle,
  subscriptionMode,
  onSubmit,
  onClose,
  show,
}) => {
  const variant =
    subscriptionMode === 'SUBSCRIBE' ? 'Subscribe to' : 'Unsubscribe from';
  return (
    <Modal
      show={show}
      title={`${variant} Stream`}
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonText={variant.substring(0, variant.indexOf(' '))}
      submitButtonType={
        subscriptionMode === 'UNSUBSCRIBE' ? 'danger' : 'success'
      }
      compact
    >
      <p>
        Are you sure you want to{' '}
        {subscriptionMode === 'UNSUBSCRIBE'
          ? 'unsubscribe from'
          : 'subscribe to'}{' '}
        <span className='font-basis-bold'>{streamTitle}</span>?
      </p>
    </Modal>
  );
};

export default SubscribeToStreamModal;
