import { SLACK_LOGO } from "../../utils/constants";
import AuthButton from "../Auth/AuthButton";

const InstallSlackApp = () => {
  return (
    <div className="absolute left-0 right-0 text-center hka-sign-in-element-group">
      <div className="p-6 mx-2 mb-4 text-left bg-white shadow-xl sm:mx-auto rounded-xl hka-sign-in-container sm:max-w-40r">
        <img
          alt="Slack Logo"
          className="mx-auto mb-4"
          height="43"
          width="144"
          src="https://haekka-public-images.s3-us-west-2.amazonaws.com/HKA--2.0-logo-black-text.svg"
        />
        <p className="mb-4 text-center">
          <span className="font-basis-bold">👋 Hey there!</span> It looks like
          you're new here...not to worry! We'll help you get started. Checkout
          the list below.
        </p>
        <p className="font-basis-bold">1️⃣ First things first.</p>
        <p className="mb-2">
          If you'd like to schedule a completely free onboarding call, you can
          do so{" "}
          <a
            href="https://savvycal.com/rrich/haekka-user-onboarding"
            className="hka-link"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <p className="font-basis-bold">2️⃣ Second.</p>
        <p className="mb-2">
          In order to install the Haekka Slack app, you'll need{" "}
          <a
            href="https://slack.com/help/articles/222386767-Manage-app-approval-for-your-workspace"
            className="hka-link"
            target="_blank"
            rel="noreferrer"
          >
            install permissions
          </a>{" "}
          (which you likely already have).
        </p>
        <p className="font-basis-bold">3️⃣ Lastly.</p>
        <p className="mb-2">
          🥳 Get ready to have fun with training! Use the button below to
          install Haekka.
        </p>
        <div className="flex justify-center">
          <AuthButton
            src={SLACK_LOGO}
            altText="Install in Slack for Free"
            action="Install in Slack for Free"
            href={`https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&scope=${process.env.REACT_APP_SCOPES}&user_scope=`}
          />
        </div>
      </div>
    </div>
  );
};

export default InstallSlackApp;
