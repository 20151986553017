import Modal from "../Modal/Modal";

export default function DeleteCaptionConfirmModal({
  showModal,
  languageForDeletion,
  onSubmit,
  onClose,
}) {
  return (
    <Modal
      show={showModal}
      title="Remove Caption"
      submitButtonText="Remove Caption"
      submitButtonType="danger"
      onSubmit={onSubmit}
      onClose={onClose}
      compact
    >
      <p className="mb-1">
        Are you sure you want to remove{" "}
        <span className="font-basis-bold">{languageForDeletion}</span> from this
        video?
      </p>
    </Modal>
  );
}
