import sendEventToSlackInternal from './sendEventToSlackInternal';

export const EVENT_TRACKER = {
  // General events
  APP_INSTALL: 'App Install',
  UPGRADE_BUTTON_CLICK: 'Upgrade Button Click',

  // Trainining admin events
  ADD_TRAINING: 'Add Training',
  ADD_USERS_TO_TRAINING: 'Add Users To Training',

  // Training users events
  CERTIFICATE_REQUESTED: 'Certificate Request',

  // Custom Video upload
  VIDEO_UPLOADED: 'Video Uploaded',
  VIDEO_DELETED: 'Video Deleted',

  // Workflows
  WORKFLOW_CREATED: 'Workflow Created',
};

const updateUsersRole = (properties, auth) => {
  const { Admin: placeholder, ...rest } = properties;
  return {
    [auth.is_admin ? 'Admin' : 'User']: placeholder,
    ...rest,
  };
};

export const internalSlackChannelLogger = async (
  eventName,
  auth,
  properties
) => {
  await sendEventToSlackInternal(eventName, updateUsersRole(properties, auth));
};
