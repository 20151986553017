import ProgressBar from "@ramonak/react-progress-bar";

const CertificateDownloadProgressBar = ({ progress, certificateError }) => {
  return (
    <>
      <ProgressBar
        completed={certificateError ? 0 : progress}
        isLabelVisible={true}
        width={"100%"}
        height={"2rem"}
        labelSize={"1.4rem"}
        borderRadius={"1rem"}
        labelClassName={"label--cert-download"}
        baseBgColor={"white"}
        bgColor={"#3CD884"}
      />

      {certificateError && (
        <p className="mt-1 text-sm leading-1.7 text-hka_red">
          An error occured. please contact your administrator.
        </p>
      )}
    </>
  );
};

export default CertificateDownloadProgressBar;
