import React, { useRef } from 'react';
import DropdownMenu from './Base/DropdownMenu';
import { useNavigate } from 'react-router-dom';

function UserNavItem({ user, onLogout }) {
  const navigate = useNavigate();
  const buttonRef = useRef(null);

  const toSettingsHandler = () => navigate(`/settings`);
  const logoutHandler = () => onLogout();

  const handleItemClick = () => {
    document.activeElement.blur();
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const dropMenuActions = [
    {
      icon: 'icon-cog',
      label: 'Settings',
      onClick: toSettingsHandler,
      disabled: false,
    },
    {
      icon: 'icon-logout',
      label: 'Logout',
      onClick: logoutHandler,
      disabled: false,
    },
  ];

  return (
    <div
      className='relative flex items-center flex-grow sm:flex-grow-0 cursor-pointer'
      onClick={handleItemClick}
    >
      <img
        className='mr-1 user-img'
        src={
          user.avatar_image
            ? user.avatar_image
            : 'https://i2.wp.com/a.slack-edge.com/df10d/img/avatars/ava_0001-192.png'
        }
        alt=''
      />

      <p className='mr-1'>{user.name}</p>
      <div className='hidden sm:inline-block mt-0.5 w-1'>
        <DropdownMenu
          actions={dropMenuActions}
          btnIcon={'icon-chevron'}
          btnBg={false}
          ref={buttonRef}
        />
      </div>
    </div>
  );
}

export default UserNavItem;
