import React, { useContext, useState } from "react";
import { editPhishingCampaign } from "../../../api";
import { useAppContext } from "../../../libs/contextLib";
import { onError } from "../../../libs/errorLib";
import toast from "react-hot-toast";
import AssignEmployeesContext from "../../../store/assign-employeees-context";
import Modal from "../../Modal/Modal";
import AssignUsersModalFragment from "../../Phishing/modals/fragments/AssignUsersModalFragment";
import LoaderInline from "../../LoaderInline";

export default function AssignEmployeesModal({
  showModal,
  campaign,
  setTriggerReload,
  onClose,
}) {
  const {
    manuallySelectedEmployees,
    setManuallySelectedEmployees,
    selectedChannels,
    setSelectedChannels,
    selectedChannelsSync,
    setSelectedChannelsSync,
    slackAddAllUsers,
    setSlackAddAllUsers,
    slackAutoEnroll,
    setSlackAutoEnroll,
    uploadedCsvUsers,
    setUploadedCsvUsers,
  } = useContext(AssignEmployeesContext);

  const { isAuthenticated } = useAppContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  function resetState() {
    setIsSubmitting(false);
    setManuallySelectedEmployees([]);
    setSelectedChannels([]);
    setSelectedChannelsSync([]);
    setSlackAddAllUsers(false);
    setSlackAutoEnroll(false);
    setUploadedCsvUsers([]);
  }

  function handleOnClose() {
    resetState();
    onClose();
  }

  async function handleOnSubmit() {
    try {
      setIsSubmitting(true);

      const payload = {
        assigned_employees: [
          ...(manuallySelectedEmployees?.map(({ value }) => value) || []),
          ...(uploadedCsvUsers?.map(({ value }) => value) || []),
        ],
        channels: selectedChannels?.map(({ value }) => value) || [],
        channels_to_sync: selectedChannelsSync?.map(({ value }) => value) || [],
        slack_add_all_users: slackAddAllUsers || false,
        slack_auto_enroll: slackAutoEnroll || false,
      };

      await editPhishingCampaign({
        payload,
        auth: isAuthenticated,
        campaignId: campaign.id,
      });

      toast.success("Employees Assigned Successfully!");

      setTriggerReload((v) => !v);
    } catch (error) {
      onError(error);
    } finally {
      handleOnClose();
    }
  }

  return (
    <Modal
      show={showModal}
      title={`Assign Employees: ${campaign.name}`}
      onSubmit={handleOnSubmit}
      onClose={handleOnClose}
      submitButtonText="Assign"
    >
      {isSubmitting ? (
        <LoaderInline />
      ) : (
        <AssignUsersModalFragment campaign={campaign} />
      )}
    </Modal>
  );
}
