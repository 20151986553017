import PropTypes from "prop-types";
import Select, { createFilter } from "react-select";
import { FixedSizeList as List } from "react-window";

const height = 35;

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

const ManualUsersSelect = ({
  defaultOptions,
  options,
  onChange,
  isMulti,
  formatOptionLabel,
  isDisabled = false,
  labelText = "Select users",
  renderSelectedValues = true,
}) => {
  return (
    <Select
      components={{ MenuList }}
      filterOption={createFilter({ ignoreAccents: false })}
      options={options}
      defaultValue={defaultOptions}
      onChange={onChange}
      value={defaultOptions}
      placeholder={labelText}
      isMulti={isMulti}
      classNamePrefix="select"
      isDisabled={isDisabled}
      controlShouldRenderValue={renderSelectedValues}
      formatOptionLabel={formatOptionLabel}
      unstyled
      maxMenuHeight={190}
      // isDisabled
    />
  );

  // return (
  //   <Select
  //     defaultValue={defaultOptions}
  //     onChange={onChange}
  //     value={defaultOptions}
  //     placeholder={labelText}
  //     isMulti={isMulti}
  //     options={options}
  //     classNamePrefix='select'
  //     isDisabled={isDisabled}
  //     // styles={customStyles}
  //     className='select--select-employees-manually'
  //     maxMenuHeight='20.8rem'
  //     controlShouldRenderValue={renderSelectedValues}
  //     formatOptionLabel={formatOptionLabel}
  //   />
  // );
};

// const customStyles = {
//   control: (base, state) => {
//     return {
//       ...base,
//       border: "1px",
//       boxShadow: `0 0 0 1px ${
//         state.selectProps.error ? "#f2181f" : "#DADCEC"
//       }`,
//     };
//   },
//   singleValue: (base, state) => {
//     return {
//       ...base,
//       border: "1px",
//       color: `${state.selectProps.error ? "#f2181f" : "#DADCEC"}`,
//     };
//   },
// };

ManualUsersSelect.propTypes = {
  defaultOptions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  labelText: PropTypes.string,
  formatOptionLabel: PropTypes.func,
};

export default ManualUsersSelect;
