import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../libs/contextLib';
import * as api from '../api';
import '../css/listItems.css';
import Loader from '../components/Loader';
import toast from 'react-hot-toast';
import EditRolesModal from '../components/EditRolesModal';
import ReactTooltip from 'react-tooltip';
import LessonDetails from '../components/WebBasedTraining/LessonDetails';
import LessonNav from '../components/WebBasedTraining/LessonNav';
import LessonHeader from '../components/WebBasedTraining/LessonHeader';
import LessonBody from '../components/WebBasedTraining/LessonBody';
import LessonCompletedPage from '../components/WebBasedTraining/LessonCompletedPage';
import { useDownloadCertificate } from '../utils/custom-hooks/useDownloadCertificifate';
import queryString from 'query-string';
import { getEmployeeTrainingAttempts } from '../api/trainingsApi';
import AssessmentBody from '../components/WebBasedTraining/AssessmentBody';
import { useEmployeeTrainingAssessmentQuestion } from '../hooks/data-hooks/employeeTrainings';
import { useQueryClient } from '@tanstack/react-query';
import { onError } from '../libs/errorLib';
import Callout from '../components/Callout';
import ActionButton from '../components/ActionButton';
import { useUserAccountsSettingsData } from '../hooks/data-hooks/settings';

const CalloutMessage = ({ training }) => (
  <span>
    🤔 Hi. Unfortunetly you did not achieve the grading threshold for this
    training, which was <b>{training.passing_grade}%</b>. <br /> You'll have to
    retake <b>only</b> the lessons you've failed. Click the button below to get
    started.
  </span>
);

// TO-DO: refactore logic, it's pretty complicated
export default function TrainingAttempt() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [activeTrainingItem, setActiveTrainingItem] = useState(undefined);
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();
  const [trainingInstance, setTrainingInstance] = useState(null);
  const [employeeTraining, setEmployeeTraining] = useState(null);
  const [lesson, setLesson] = useState(null);
  const [employeeLesson, setEmployeeLesson] = useState(null);
  const [employeeLessons, setEmployeeLessons] = useState([]);
  const [lessons, setLessons] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [showResultsPage, setShowResultsPage] = useState(false);
  const [trainingComplete, setTrainingComplete] = useState(false);
  const [employeeRole, setEmployeeRole] = useState(null);
  const [generatingCertificate, setGeneratingCertificate] = useState(false);
  const [refetchTraining, setRefetchTraining] = useState(true);
  const [lessonSelect, setLessonSelect] = useState(null);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [completedLessons, setCompletedLessons] = useState(0);
  const [totalLessons, setTotalLessons] = useState(0);
  const [curriculum, setCurriculum] = useState(null);
  const [branchingScenario, setBranchingScenario] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [showEditRolesModal, setShowEditRolesModal] = useState(false);
  const [showRetakeTrainingCallout, setShowRetakeTrainingCallout] =
    useState(false);
  const [showAssessmentQuestion, setShowAssessmentQuestion] =
    useState(undefined);
  const [showAssessmentResults, setShowAssessmentResults] = useState(false);

  const {
    data: assessmentQuestionData,
    isLoading: isLoadingAssessmentQuestion,
  } = useEmployeeTrainingAssessmentQuestion({
    employeeID: isAuthenticated.id,
    assessmentID: showAssessmentQuestion?.assessmentID,
    questionID: showAssessmentQuestion?.questionID,
    enabled: !!showAssessmentQuestion,
  });

  const { data: settings, isLoading: isLoadingSettings } =
    useUserAccountsSettingsData({
      companyID: isAuthenticated.company,
    });

  const {
    downloadCertificate,
    progress,
    errors: certificateError,
  } = useDownloadCertificate({
    trainingID: id,
    employees: [isAuthenticated.id],
    title: curriculum?.title,
    isDemo: pathname.includes('catalog'),
  });

  const { demoLessonId } = queryString.parse(search);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    setTrainingComplete(!!employeeTraining?.finish_date);
  }, [employeeTraining?.finish_date]);

  const getEmployeeLesson = useCallback(
    (lessonId) => {
      return api.getEmployeeLesson({
        auth: isAuthenticated,
        employeeId: isAuthenticated.id,
        trainingId: id,
        lessonId: lessonId,
      });
    },
    [id, isAuthenticated]
  );

  const checkLesson = async (nextTrainingItem) => {
    const employeeLessonTemp = await getEmployeeLesson(
      nextTrainingItem.curriculum_item_id
    );
    if (
      branchingScenario &&
      !employeeLessonTemp.finished &&
      employeeTraining &&
      employeeLessonTemp.lesson.id.localeCompare(
        employeeTraining.active_lesson?.id
      ) !== 0
    ) {
      return;
    }
    setLesson(employeeLessonTemp.lesson);
    setEmployeeLesson(employeeLessonTemp);
  };

  useEffect(() => {
    function loadTraining() {
      return api.training({ auth: isAuthenticated, trainingId: id });
    }
    function loadEmployeeTraining() {
      return api.getEmployeeWebTraining({
        auth: isAuthenticated,
        trainingId: id,
      });
    }
    function loadDemoTraining() {
      return api.getTrainingDemo({ auth: isAuthenticated, curriculumId: id });
    }
    function getEmployeeLessons(page) {
      return api.getEmployeeLessons({
        auth: isAuthenticated,
        employeeId: isAuthenticated.id,
        trainingId: id,
        page,
      });
    }
    function loadEmployeeTrainingAttempts() {
      // TO-DO: refactor and use useEmployeeTrainingAttemptsData hook instead of this fn
      return getEmployeeTrainingAttempts({
        trainingID: id,
        employeeID: isAuthenticated.id,
      });
    }
    const loadEmojis = async () => {
      const emojis = await api.getEmojisJsonMapper({
        auth: isAuthenticated,
        file: 'emojis.json',
      });
      localStorage.setItem('haekka-emojis', emojis);

      const emojis_2 = await api.getEmojisJsonMapper({
        auth: isAuthenticated,
        file: 'emojis-2.json',
      });
      localStorage.setItem('haekka-emojis-2', emojis_2);
    };
    async function onLoad() {
      try {
        const trainingInstance = demoLessonId
          ? await loadDemoTraining()
          : await loadTraining();
        setTrainingInstance(trainingInstance);
        if (
          !localStorage.getItem('haekka-emojis') ||
          !localStorage.getItem('haekka-emojis-2')
        )
          await loadEmojis();
        let complete = false;
        if (pathname.includes('catalog')) {
          const demoLesson = trainingInstance.lessons.find(
            (l) => l.id === demoLessonId
          );
          setLesson(demoLesson ? demoLesson : null);
          setEmployeeLesson({
            id: demoLessonId,
            finished: false,
            lesson: demoLesson,
          });
          const employeeLessons = {
            results: trainingInstance.lessons.map((l) => {
              l.finished = false;
              return Object.assign({ lesson: l });
            }),
          };
          setEmployeeLessons(employeeLessons);
          setLessons(trainingInstance.lessons);
          const items = trainingInstance.lessons.map((l) => ({
            type: 'lesson',
            item: l,
            title: l.title,
            employee_item_id: l.id,
            finished: false,
          }));
          setEmployeeTraining({
            completed_percentage: 0,
            training_items: items,
          });
          setActiveTrainingItem(items[0]);
          setCurriculum(trainingInstance.curriculum_detail);
          setTotalLessons(trainingInstance.lessons.length);
        } else {
          const employeeTraining = await loadEmployeeTraining();
          setEmployeeTraining(employeeTraining);
          setCurriculum(trainingInstance.curriculum_detail);
          setBranchingScenario(
            trainingInstance.curriculum_detail === 'branching'
          );

          const nextTrainingItem = employeeTraining.training_items.find(
            (item) => !item.finished
          );

          setActiveTrainingItem(nextTrainingItem);
          if (!nextTrainingItem && employeeTraining.training_items.length > 0) {
            const lastItem =
              employeeTraining.training_items[
                employeeTraining.training_items.length - 1
              ];
            if (lastItem.type === 'assessment') {
              const lastAssessment = employeeTraining.training_items.find(
                (i) => i.type === 'assessment'
              );
              setActiveTrainingItem(lastAssessment);
              setShowAssessmentResults({
                assessmentID: lastAssessment.curriculum_item_id,
              });
            }
          }

          let page = 1;
          let employeeLessons = [];
          let employeeLessonsData = await getEmployeeLessons(page);
          employeeLessons = employeeLessonsData;

          while (employeeLessonsData.next) {
            page++;
            employeeLessonsData = await getEmployeeLessons(page);
            employeeLessons.results = [
              ...employeeLessons.results,
              ...employeeLessonsData.results,
            ];
          }

          setEmployeeLessons(employeeLessons);
          let completedLessons = employeeLessons.results.filter(
            (i) => i.finished
          ).length;

          const employeeTrainingAttempts = await loadEmployeeTrainingAttempts();
          const employeeTrainingAttemptsSorted =
            employeeTrainingAttempts.results.sort(
              (a, b) => new Date(a.finish_date) - new Date(b.finish_date)
            );
          if (
            employeeTrainingAttemptsSorted.length > 0 &&
            employeeTrainingAttemptsSorted[0].did_user_pass === false
          ) {
            setShowRetakeTrainingCallout(true);
          }
          setCompletedLessons(completedLessons);
          setTotalLessons(employeeLessons.results.length);
          complete = !!employeeTraining.finish_date;
          setTrainingComplete(complete);

          if (complete) {
            setShowResultsPage(true);
          }

          if (nextTrainingItem?.type === 'assessment') {
            queryClient.invalidateQueries([
              'employee-trainings-assessments',
              isAuthenticated.id,
              nextTrainingItem.curriculum_item_id,
              'next_question',
            ]);
            setShowAssessmentQuestion({
              assessmentID: nextTrainingItem.curriculum_item_id,
              questionID: 'next_question',
            });
          }
          let activeLesson = employeeLessons.results[0]?.lesson;
          for (let i = 0; i < employeeLessons.results.length; i++) {
            if (!employeeLessons.results[i].finished) {
              activeLesson = employeeLessons.results[i].lesson;
              break;
            }
          }
          if (activeLesson) {
            const currentLesson = activeLesson;
            const employeeLesson = await getEmployeeLesson(currentLesson.id);
            setEmployeeLesson(employeeLesson);
            setLesson(employeeLesson.lesson);
          }
          const result = employeeLessons.results.map((res) => ({
            value: res.lesson.id,
            label: res.lesson.title,
          }));
          if (complete) {
            result.push({ value: 'results_page', label: 'Results' });
          }
          setLessonSelect(result);
          setLessons(employeeLessons.results.map((res) => res.lesson));
        }
        setEmployeeRole(isAuthenticated.role);
      } catch (e) {
        setRedirect(true);
        if (e.message.endsWith('404'))
          toast.error('Training could not be found.');
        else {
          onError(e);
        }
      }
      setShowLoader(false);
    }
    onLoad();
  }, [
    demoLessonId,
    getEmployeeLesson,
    id,
    isAuthenticated,
    pathname,
    queryClient,
    refetchTraining,
  ]);

  const requestCertificate = async () => {
    try {
      setShowProgressBar(true);
      setGeneratingCertificate(true);
      await downloadCertificate();
    } catch (error) {
      toast.error('An error occured');
    }
    setGeneratingCertificate(false);
  };

  useEffect(() => {
    setShowEditRolesModal(
      settings &&
        !settings.is_user_role_optional &&
        !employeeRole &&
        !pathname.includes('catalog')
    );
  }, [employeeRole, pathname, settings]);

  const onRetakeCalloutButtonClick = () => {
    setShowRetakeTrainingCallout(false);
  };

  if (redirect !== null) {
    navigate(redirect);
    return null;
  }

  if (showLoader) return <Loader />;

  if (showRetakeTrainingCallout) {
    return (
      <Callout
        message={<CalloutMessage training={trainingInstance} />}
        title='Haekka'
        permanent
      >
        <ActionButton type='success' onClick={onRetakeCalloutButtonClick}>
          Retake Training
        </ActionButton>
      </Callout>
    );
  }

  return (
    <div
      id='top'
      className='fixed left-0 right-0 z-20 border-t border-b bottom-4 bg-hka_gray-ultraLight top-10 border-hka_gray-light'
    >
      <section className='flex h-full overflow-hidden bg_hka_gray-ultraLight'>
        <section className='flex-1 overflow-y-auto'>
          <LessonHeader
            setRedirect={setRedirect}
            isAuthenticated={isAuthenticated}
            trainingId={id}
            title={curriculum.title}
          />

          <main className='flex flex-col w-full h-auto grid-cols-6 lg:grid lg:grid-cols-16'>
            <LessonDetails
              trainingComplete={trainingComplete}
              requestCertificate={requestCertificate}
              generatingCertificate={generatingCertificate}
              progress={progress}
              showProgressBar={showProgressBar}
              certificateError={certificateError}
              completedLessons={completedLessons}
              totalLessons={totalLessons}
              subtitle={curriculum.subtitle}
              branchingScenario={branchingScenario}
              employeeTraining={employeeTraining}
            />

            {!showResultsPage && activeTrainingItem?.type === 'lesson' && (
              <LessonBody
                employeeLesson={employeeLesson}
                setEmployeeLesson={setEmployeeLesson}
                employeeLessons={employeeLessons}
                setEmployeeLessons={setEmployeeLessons}
                employeeTraining={employeeTraining}
                setEmployeeTraining={setEmployeeTraining}
                lesson={lesson}
                setLesson={setLesson}
                lessons={lessons}
                setShowResultsPage={setShowResultsPage}
                setRefetchTraining={setRefetchTraining}
                trainingComplete={trainingComplete}
                setTrainingComplete={setTrainingComplete}
                trainingId={id}
                branchingScenario={branchingScenario}
                training={trainingInstance}
                setCompletedLessons={setCompletedLessons}
                activeTrainingItem={activeTrainingItem}
                trainingItems={employeeTraining.training_items}
                setActiveTrainingItem={setActiveTrainingItem}
                completedLessons={completedLessons}
                totalLessons={totalLessons}
              />
            )}
            {!showResultsPage && activeTrainingItem?.type === 'assessment' && (
              <AssessmentBody
                activeTrainingItem={activeTrainingItem}
                assessmentQuestionData={assessmentQuestionData}
                trainingItems={employeeTraining.training_items}
                isLoadingAssessmentQuestion={isLoadingAssessmentQuestion}
                setShowAssessmentQuestion={setShowAssessmentQuestion}
                showAssessmentResults={showAssessmentResults}
                setShowAssessmentResults={setShowAssessmentResults}
                setActiveTrainingItem={setActiveTrainingItem}
                trainingInstance={trainingInstance}
                setEmployeeTraining={setEmployeeTraining}
                setShowResultsPage={setShowResultsPage}
                checkLesson={checkLesson}
                setShowRetakeTrainingCallout={setShowRetakeTrainingCallout}
                setTrainingComplete={setTrainingComplete}
              />
            )}
            {showResultsPage && (
              <LessonCompletedPage
                curriculumDetails={curriculum}
                employeeTraining={employeeTraining}
                trainingComplete={trainingComplete}
                generatingCertificate={generatingCertificate}
                requestCertificate={requestCertificate}
                progress={progress}
                showProgressBar={showProgressBar}
                certificateError={certificateError}
              />
            )}

            <LessonNav
              employeeLesson={employeeLesson}
              setEmployeeLesson={setEmployeeLesson}
              employeeTraining={employeeTraining}
              setLesson={setLesson}
              lessons={lessons}
              showResultsPage={showResultsPage}
              setShowResultsPage={setShowResultsPage}
              getEmployeeLesson={getEmployeeLesson}
              trainingComplete={trainingComplete}
              lessonSelect={lessonSelect}
              branchingScenario={branchingScenario}
              trainingItems={employeeTraining.training_items}
              activeTrainingItem={activeTrainingItem}
              setActiveTrainingItem={setActiveTrainingItem}
              assessmentQuestionData={assessmentQuestionData}
              setShowAssessmentQuestion={setShowAssessmentQuestion}
              setShowAssessmentResults={setShowAssessmentResults}
            />
          </main>
        </section>
      </section>
      <EditRolesModal
        showModal={showEditRolesModal}
        setRoleTitle={setEmployeeRole}
        employee={isAuthenticated}
        onModalHide={() => setShowEditRolesModal(false)}
      />
    </div>
  );
}
