import React from 'react';
import TRAINING_RENEW_OPTIONS from '../utils/renewOptions';

const StepThreeConfirm = ({
  title,
  renewCycle,
  daysTillDue,
  gradingThreshold,
  owners,
  doceboTraining,
}) => {
  return (
    <>
      <div className='space-y-2'>
        <p className='text-sm font-basis-medium'>Confirm Training Selections</p>
        <section>
          <p className='text-sm font-basis-medium'>Training Title</p>
          <p className='text-base2 font-basis-medium text-hka_gray-dark'>
            {title}
          </p>
        </section>
        <section>
          <p className='text-sm font-basis-medium'>Renew Cycle</p>
          <p className='text-base2 font-basis-medium text-hka_gray-dark'>
            {TRAINING_RENEW_OPTIONS.find((el) => el.id === renewCycle).title}
          </p>
        </section>
        <section>
          <p className='text-sm font-basis-medium'>Days Until Due</p>
          <p className='text-base2 font-basis-medium text-hka_gray-dark'>
            {daysTillDue ? daysTillDue : 'Not set'}
          </p>
        </section>
        <section>
          <p className='text-sm font-basis-medium'>Grading Threshold</p>
          <p className='text-base2 font-basis-medium text-hka_gray-dark'>
            {gradingThreshold === null && 'No grading threshold'}
            {gradingThreshold !== null && `${gradingThreshold}%`}
          </p>
        </section>
        <section>
          <p className='text-sm font-basis-medium'>Owners</p>
          <p className='text-base2 font-basis-medium text-hka_gray-dark'>
            {owners.reduce(
              (str, current, index) =>
                str + current.label + (index === owners.length - 1 ? '' : ', '),
              ''
            )}
          </p>
        </section>
        {!!doceboTraining && (
          <section>
            <p className='text-sm font-basis-medium'>
              Docebo Course Connection
            </p>
            <p className='text-base2 font-basis-medium text-hka_gray-dark'>
              {doceboTraining.label}
            </p>
          </section>
        )}
      </div>
    </>
  );
};

export default StepThreeConfirm;
