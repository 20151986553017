import Modal from "../../Modal/Modal";

const PostNotFoundModal = ({ onClose }) => {
  return (
    <Modal
      show={true}
      title={`Post Not Found`}
      onClose={onClose}
      onSubmit={onClose}
      submitButtonText="Okay"
    >
      <p>
        Post could not be found. If you think it's mistake, please contact your
        administators.
      </p>
    </Modal>
  );
};

export default PostNotFoundModal;
