import React from "react";

export default function NoPhishingAttacksSent({ campaignSendOption }) {
  const title =
    campaignSendOption === "once_immediately"
      ? "No attacks have been sent yet."
      : "The first attack is preparing to send.";

  const subtitle =
    campaignSendOption === "once_immediately"
      ? "Publish this campaign to initiate the phishing attack schedule."
      : "Check back periodically to see the attack results.";
  return (
    <div className="flex flex-col p-4 items-center justify-center w-full space-y-2 -ml-0.1">
      <img
        alt="No Phishing Attacks"
        src="https://haekka-product-assets.s3.us-west-2.amazonaws.com/create-first-phishing.svg"
        className="h-8"
      />
      <div className="text-center">
        <p>{title}</p>
        <p>{subtitle}</p>
      </div>
    </div>
  );
}
