import React from "react";
import PhishingMetricsDonutChart from "./PhishingMetricsDonutChart";

export default function AttackSummaryCard({ data, subtitle, padding }) {
  return (
    <div className={`flex-1 lg:max-w-50 ${padding}`}>
      <div className="flex flex-col h-full p-2 bg-white lg:p-3 rounded-xl shadow-light sm:flex-row">
        <div className="flex-1 pr-0 truncate sm:pr-1">
          <p className="mb-1 text-sm truncate">Attacks Sent ({subtitle})</p>
          <div className="flex items-center">
            <p className="mr-1 text-2xl">{data.number_of_attacks}</p>
          </div>
        </div>
        <span className="min-h-full vr w-0.1 bg-hka_gray-light flex"></span>
        <div className="flex items-center flex-1 pt-2 pl-0 truncate sm:pl-1 sm:pt-0 justify-left sm:justify-center">
          <div>
            <PhishingMetricsDonutChart phishingMetrics={data.metrics} />
          </div>
        </div>
      </div>
    </div>
  );
}
