import Modal from '../Modal/Modal';
import PropTypes from 'prop-types';
import AssignEmployeesModalContent from './AssignEmployeesModalContent';
import { useQueryClient } from '@tanstack/react-query';

const AssignEmployeesModal = ({
  showModal,
  onClose,
  assignEmployeesContext,
  setTriggerGetTraining,
}) => {
  const queryClient = useQueryClient();
  const {
    submitIsValid,
    handleTrainingSubmit,
    alertEmployees,
    setAlertEmployees,
    selectedTab,
  } = assignEmployeesContext;

  const handleOnSubmit = async () => {
    await handleTrainingSubmit();
    setTriggerGetTraining();
    queryClient.invalidateQueries(['employee-trainings']);
    setTimeout(() => {
      queryClient.invalidateQueries(['employee-trainings']);
    }, 2000);
  };

  const footerContent = (
    <div className='flex items-center justify-end h-4'>
      <div className='flex items-center space-x-1'>
        <label className='switch'>
          <input
            type='checkbox'
            name='select-all-users'
            checked={alertEmployees}
            onChange={() => setAlertEmployees((p) => !p)}
            className='cursor-pointer'
            disabled={!submitIsValid || selectedTab === 'DOCEBO'}
          />
          <span className='slider'></span>
        </label>
        <div>
          <p className='font-basis'>Alert Employees</p>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      show={showModal}
      title='Assign Employees'
      onClose={onClose}
      onSubmit={handleOnSubmit}
      submitButtonText={'Assign Employees'}
      submitValid={submitIsValid}
      expandable
      additionalFooterContent={footerContent}
    >
      <AssignEmployeesModalContent />
    </Modal>
  );
};

AssignEmployeesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  assignEmployeesContext: PropTypes.object.isRequired,
};

export default AssignEmployeesModal;
