import PropTypes from "prop-types";
import useComponentVisible from "../../utils/custom-hooks/useComponentVisible";

const Filter = ({ label, options, onUpdate, forceListView }) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  if (options.length === 1 && !forceListView) {
    return (
      <div className="flex-1 w-full lg:max-w-25r custom-dropdown-filter-base">
        <label className="checkbox">
          <input
            type="checkbox"
            name="select-answer"
            id={label}
            checked={options[0]}
            onChange={(e) => onUpdate(e)}
          />
          <span className="icon-check"></span>
        </label>
        <label className="custom-dropdown-filter-check-field" htmlFor={label}>
          {label}
        </label>
      </div>
    );
  }

  return (
    <div ref={ref} className="flex-1 w-full lg:max-w-25r">
      <div
        onClick={() => setIsComponentVisible((p) => !p)}
        className="relative custom-dropdown-filter-base"
      >
        <p className="custom-dropdown-filter-label">{label}</p>
        <span
          className={`custom-dropdown-filter-span icon-chevron ${
            isComponentVisible ? "transform rotate-180" : ""
          }`}
        ></span>

        {isComponentVisible && (
          <div className="custom-dropdown-filter-options">
            {options.length > 0 ? (
              options.map((option) => (
                <div
                  className="custom-dropdown-filter-option"
                  key={option.id}
                  onClick={(e) => e.stopPropagation()}
                >
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="select-answer"
                      id={option.id}
                      checked={option.selected}
                      value={option.id}
                      onChange={(e) => onUpdate(e)}
                    />
                    <span className="icon-check"></span>
                  </label>
                  <label
                    className="cursor-pointer custom-dropdown-filter-check-field"
                    htmlFor={option.id}
                  >
                    {option.name}
                  </label>
                </div>
              ))
            ) : (
              <div className="text-base leading-4 text-hka_gray">
                {"There are no options for " + label}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Filter.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default Filter;
