import React from "react";
import { useAppContext } from "../libs/contextLib";
import buildBillingUrl from "../utils/buildBillingUrl";

export default function Billing() {
  const { isAuthenticated } = useAppContext();

  const billingUrl = buildBillingUrl(isAuthenticated);

  window.location.href = billingUrl;

  return <></>;
}
