import { useQuery } from "@tanstack/react-query";
import { checkEngagementData } from "../../../api/engagementsApi";

export const useCheckEngagementData = ({ companyEngagementID, enabled }) => {
  return useQuery({
    queryKey: ["check-engagement-data", companyEngagementID],
    queryFn: () => checkEngagementData({ companyEngagementID }),
    enabled,
  });
};
