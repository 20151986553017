import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateExternalAppSettings } from '../../../api/integrationsApi';

export const useUpdateExternalAppSettings = ({ resourcetype, companyID }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args) =>
      updateExternalAppSettings({
        ...args,
        settings: { ...args.settings, resourcetype, company: companyID },
      }),
    onSuccess: () =>
      queryClient.invalidateQueries(['external-installation', resourcetype]),
  });
};
