import { useEffect, useState } from "react";
import { WORKFLOW_SOURCES } from "../../../utils/workflowsUtils";
import SourceDestinationLogo from "./SourceDestinationLogo";
import { useSelector, useDispatch } from "react-redux";
import { workflowsActions } from "../../../store/workflowsSlice";

const SelectDestination = () => {
  const dispatch = useDispatch();

  const {
    selectedFrequency,
    selectedDestination,
    selectedTraining,
    selectedAction,
  } = useSelector((state) => state.workflows);

  const [enabled, setEnabled] = useState(selectedFrequency !== undefined);
  const selectDestinationHandler = (destination) => {
    if (!enabled) return;
    dispatch(workflowsActions.selectDestination({ destination }));
  };

  useEffect(() => {
    if (selectedAction?.value === "assign_training") {
      setEnabled(selectedTraining !== undefined);
    } else {
      setEnabled(selectedFrequency !== undefined);
    }
  }, [selectedAction?.value, selectedFrequency, selectedTraining]);

  return (
    <div
      className={`flex flex-col sm:flex-row w-full pt-3 ${
        enabled ? "" : "opacity-40"
      }`}
    >
      <div className="flex items-center flex-1 mb-2 sm:mb-0">
        <span
          className={`${enabled ? "workflow-step" : "workflow-step-disabled"}`}
        >
          3
        </span>
        <div>
          <h4 className="font-basis-medium text-hka_gray-dark text-base2">
            Select a Destination
          </h4>
          <p className="text-sm">
            The <span className="font-basis-bold">destination</span> is where
            Haekka will send the action.
          </p>
        </div>
      </div>
      <div className="flex items-center justify-start sm:justify-end">
        <div className="flex items-center">
          {WORKFLOW_SOURCES.map((source) => (
            <span
              key={source.id}
              onClick={() => {
                if (source.active) selectDestinationHandler(source.id);
              }}
            >
              <SourceDestinationLogo
                key={source.id}
                source={source}
                selectedSource={selectedDestination}
              />
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectDestination;
