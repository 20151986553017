import { useEffect, useState } from "react";
import Select from "react-select";

import { useAppContext } from "../../libs/contextLib";

import ActionButton from "../ActionButton";
import GroupsMenu from "../Groups/GroupsMenu";

const active_options = [
  { label: "All Employees", value: null },
  { label: "With assigned training", value: "training_true" },
  { label: "Without assigned training", value: "training_false" },
  { label: "Active Employees", value: "employees_active" },
  { label: "Inactive Employees", value: "employees_inactive" },
];

const EmployeesFilters = ({
  setCurrentPage,
  searchString,
  setSearchString,
  setIsActiveTraining,
  setIsActiveEmployees,
  selectedGroup,
  setSelectedGroup,
  setEditGroupModal,
  groupsLocal,
}) => {
  const { isAuthenticated } = useAppContext();

  const [isAllowedToEditActiveGroup, setIsAllowedToEditActiveGroup] =
    useState(false);

  useEffect(() => {
    if (selectedGroup) {
      setIsAllowedToEditActiveGroup(
        isAuthenticated.is_admin || isAuthenticated.id === selectedGroup.manager
      );
    }
  }, [selectedGroup, isAuthenticated.id, isAuthenticated.is_admin]);

  const filterChangedHandler = (option) => {
    setCurrentPage(1);

    if (option.value === "training_true") {
      setIsActiveTraining(true);
      setIsActiveEmployees(undefined);
    } else if (option.value === "training_false") {
      setIsActiveTraining(false);
      setIsActiveEmployees(undefined);
    } else if (option.value === "employees_active") {
      setIsActiveTraining(undefined);
      setIsActiveEmployees(true);
    } else if (option.value === "employees_inactive") {
      setIsActiveTraining(undefined);
      setIsActiveEmployees(false);
    } else {
      setIsActiveTraining(undefined);
      setIsActiveEmployees(undefined);
    }
  };

  const handleGroupSelect = (group) => {
    setSelectedGroup(group);
    setCurrentPage(1);
  };

  return (
    <div className="flex flex-col lg:flex-row space-x-0 space-y-0.5 lg:space-y-0 mb-2 lg:space-x-1 grow">
      <div className="flex items-center flex-1 pl-0.5 h-4 bg-white rounded-lg lg:flex-none border border-hka_gray-border">
        <span className="icon-search text-hka_gray mt-0.2"></span>
        <input
          className="h-3 text-base border-none min-w-20 max-h-3.8 focus:outline-none"
          type="text"
          value={searchString}
          onChange={(e) => {
            setSearchString(e.target.value);
            setCurrentPage(1);
          }}
          placeholder="Search Employees"
        />
      </div>

      <div className="flex-1 lg:flex-none">
        <Select
          defaultValue={active_options[0]}
          onChange={filterChangedHandler}
          value={active_options.label}
          placeholder={"Filter Employees"}
          options={active_options}
          className="flex-1 w-full h-4 text-hka_gray min-w-25r select--employee-filter"
          maxMenuHeight="20.8rem"
          classNamePrefix="select"
          isSearchable={false}
          unstyled
        />
      </div>

      <div className="flex flex-row flex-1 space-x-1 lg:flex-none">
        <GroupsMenu
          groups={groupsLocal}
          onChange={handleGroupSelect}
          value={selectedGroup}
        />
        {selectedGroup && isAllowedToEditActiveGroup && (
          <ActionButton
            type="default"
            onClick={() => {
              setEditGroupModal(true);
            }}
          >
            <span className="text-hka_blue icon-cog h-3 mb-0.3 sm:-ml-0.5 sm:pr-0.5"></span>
            <span className="hidden sm:flex">Edit Group</span>
          </ActionButton>
        )}
      </div>
    </div>
  );
};

export default EmployeesFilters;
