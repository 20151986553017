import React, { useState, useEffect } from "react";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import * as api from "../../api";
import Modal from "../Modal/Modal";
import WysiwygRichTextEditor from "../WysiwygEditor/WysiwygRichTextEditor";
import { useLocation } from "react-router-dom";

const QuestionModal = ({
  showModal,
  defaultQuestion,
  employeeLesson,
  onClose,
  onDemoClose,
  onDemoSubmit,
  branching,
  questionEditorState,
  trainingId,
}) => {
  const { isAuthenticated } = useAppContext();
  const { pathname } = useLocation();
  const [answers, setAnswers] = useState([]);
  const [pendingSubmit, setPendingSubmit] = useState(false);

  useEffect(() => {
    function getEmployeeLessonAttempt() {
      return api.getEmployeeLessonAttempt({
        auth: isAuthenticated,
        lessonId: employeeLesson.id,
      });
    }
    async function onLoad() {
      try {
        if (!pathname.includes("catalog") && employeeLesson?.finished) {
          const lessonAttempt = await getEmployeeLessonAttempt();
          const selectedAnswers = lessonAttempt.selected_answers;
          if (selectedAnswers) setAnswers(JSON.parse(selectedAnswers));
        }
        if (!pathname.includes("catalog") && !employeeLesson?.finished) {
          setAnswers([]);
        }
      } catch (e) {
        onError(e);
      }
    }
    onLoad();
  }, [employeeLesson?.finished, employeeLesson.id, isAuthenticated, pathname]);

  async function onSubmit() {
    if (pathname.includes("catalog")) {
      setPendingSubmit(true);
      employeeLesson.finished = true;
      onDemoSubmit();
      setAnswers([]);
    } else {
      setPendingSubmit(true);
      employeeLesson.finished = true;
      await api.lessonSubmit({
        auth: isAuthenticated,
        employeeId: isAuthenticated.id,
        trainingId: trainingId,
        lessonId: defaultQuestion.lesson,
        questionId: defaultQuestion.id,
        answersIds: answers,
        branching: branching,
      });
      setPendingSubmit(false);
    }
  }

  const finishlessonHandler = () => {
    onClose("refetch");
    setAnswers([]);
  };

  return (
    <Modal
      show={showModal}
      submitButtonText={
        employeeLesson && !employeeLesson.finished
          ? "Submit Answer(s)"
          : "Finish Lesson"
      }
      onSubmit={
        employeeLesson && !employeeLesson.finished
          ? onSubmit
          : !pathname.includes("catalog")
          ? finishlessonHandler
          : onDemoClose
      }
      submitValid={
        pathname.includes("catalog")
          ? answers.length > 0 || employeeLesson.finished
          : !pendingSubmit && answers.length > 0
      }
      title="Lesson Question"
      onClose={() => {
        setAnswers([]);
        onClose();
      }}
      expandable
    >
      <section>
        <>
          <div className="mb-3 lesson--question text-hka_gray-dark">
            {questionEditorState && (
              <WysiwygRichTextEditor
                editorState={questionEditorState}
                readOnly
                toolbarHidden
              />
            )}
          </div>
          <div className="">
            {defaultQuestion.answers.map((answer) => {
              return (
                <div key={answer.id}>
                  <div className="flex items-start mb-1 space-x-1 ">
                    {employeeLesson?.finished || employeeLesson === null ? (
                      !answer.correct ? (
                        <span className="w-2 h-2 rounded-full icon-close--sm bg-hka_red-light text-hka_red leading-2"></span>
                      ) : (
                        <span className="w-2 h-2 rounded-full icon-check--sm text-hka_green bg-hka_green-light leading-2"></span>
                      )
                    ) : null}
                    <span
                      className={`flex ${
                        employeeLesson?.finished || employeeLesson === null
                          ? "cursor-not-allowed"
                          : ""
                      }`}
                    >
                      <label
                        className={`checkbox ${
                          employeeLesson?.finished || employeeLesson === null
                            ? "cursor-not-allowed"
                            : ""
                        }`}
                      >
                        <input
                          type="checkbox"
                          name="select-answer"
                          id={answer.id}
                          disabled={employeeLesson?.finished}
                          defaultChecked={answers.includes(answer.id)}
                          onChange={() => {
                            if (employeeLesson?.finished) {
                              return;
                            }
                            if (
                              defaultQuestion.type.localeCompare(
                                "multi_choice"
                              ) === 0
                            ) {
                              setAnswers([answer.id]);
                            } else {
                              let answersCopy = [...answers];
                              if (answersCopy.includes(answer.id)) {
                                var index = answersCopy.indexOf(answer.id);
                                answersCopy.splice(index, 1);
                              } else {
                                answersCopy.push(answer.id);
                              }
                              setAnswers(answersCopy);
                            }
                          }}
                        />
                        <span className="icon-check"></span>
                      </label>
                      <label
                        className={`text-base tracking-wide cursor-pointer text-hka_gray font-basis-regular ${
                          employeeLesson?.finished || employeeLesson === null
                            ? "cursor-not-allowed"
                            : ""
                        }`}
                        htmlFor={answer.id}
                      >
                        {answer.answer}
                      </label>
                    </span>
                  </div>
                  {(employeeLesson?.finished || employeeLesson === null) &&
                    answer.explanation && (
                      <p className="px-1.5 inline-flex ml-3 border border-hka_gray-light py-1 mb-3 bg-hka_gray-ultraLight rounded-xl">
                        {answer.explanation}
                      </p>
                    )}
                </div>
              );
            })}
          </div>
        </>
      </section>
    </Modal>
  );
};

export default QuestionModal;
