import { useQuery } from "@tanstack/react-query";
import { getEmployeeEngagements } from "../../../api/employeeEngagementsApi";

export const useEmployeeEngagementsData = ({
  employeeID,
  page,
  pageSize,
  staleTime = 0,
}) => {
  return useQuery({
    queryKey: ["employee-engagements", employeeID, page, pageSize],
    queryFn: () =>
      getEmployeeEngagements({
        employeeID,
        page,
        pageSize,
      }),
    staleTime,
    keepPreviousData: true,
  });
};

export const prefetchEmployeeEngagementsData = ({
  queryClient,
  employeeID,
  page = 1,
  pageSize = 10,
  staleTime = 0,
}) => {
  queryClient.prefetchQuery({
    queryKey: ["employee-engagements", employeeID, page, pageSize],
    queryFn: () =>
      getEmployeeEngagements({
        employeeID,
        page,
        pageSize,
      }),
    staleTime,
  });
};
