import WysiwygRichTextEditor from '../../WysiwygEditor/WysiwygRichTextEditor';

const ClosingMessage = ({
  closingMessage,
  setClosingMessage,
  closingMessageEnabled,
  setClosingMessageEnabled,
  reviewEnabled,
  setReviewEnabled,
  setImageIds,
  isReadOnly,
}) => {
  return (
    <section className='flex flex-col justify-between gap-2'>
      <div className='flex justify-between gap-2 sm:items-center'>
        <div>
          <h4 className='mb-0.2'>
            Closing Message{' '}
            <span className='text-sm text-hka_gray'>(Optional)</span>
          </h4>
          <p className='text-sm'>
            This message gets sent to each user upon completion. Toggle the
            switch to disable the message.
          </p>
        </div>

        <div>
          <label className='switch'>
            <input
              type='checkbox'
              name='acknowledge_button'
              checked={closingMessageEnabled}
              onChange={(e) => setClosingMessageEnabled(e.target.checked)}
              className='cursor-pointer'
              disabled={isReadOnly}
            />
            <span className='slider'></span>
          </label>
        </div>
      </div>

      {closingMessageEnabled && (
        <div>
          <WysiwygRichTextEditor
            editorState={closingMessage}
            onSetEditorState={(state) => setClosingMessage(state)}
            id={undefined}
            onImageAdded={() => {}}
            contentType={'engagement_welcome_message'}
            questionId={undefined}
            disableImageUpload
            readOnly={isReadOnly}
          />
        </div>
      )}

      <div className='flex justify-between gap-2 sm:items-center'>
        <div>
          <h4 className='mb-0.2'>
            Engagement Review{' '}
            <span className='text-sm text-hka_gray'>(Optional)</span>
          </h4>
          <p className='text-sm'>
            Engagement review for users (included as part of the closing
            message).
          </p>
        </div>

        <div>
          <label className='switch'>
            <input
              type='checkbox'
              name='acknowledge_button'
              checked={reviewEnabled}
              onChange={(e) => setReviewEnabled(e.target.checked)}
              className='cursor-pointer'
              disabled={!closingMessageEnabled || isReadOnly}
            />
            <span className='slider'></span>
          </label>
        </div>
      </div>
    </section>
  );
};

export default ClosingMessage;
