import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import ActionButton from '../components/ActionButton';
import Callout from '../components/Callout';
import CreateEditWorkflowModal from '../components/Workflows/CreateEditWorkflowModal/CreateEditWorkflowModal';
import WorkflowsHeader from '../components/Workflows/WorkflowsHeader';
import { useAppContext } from '../libs/contextLib';
import AssignEmployeesContext from '../store/assign-employeees-context';
import * as api from '../api';
import TransitionWrapper from '../components/Base/TransitionWrapper';
import Loader from '../components/Loader';
import WorkflowsList from '../components/Workflows/WorkflowsList';
import Expander from '../components/Expander';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  RISK_LEVEL_OPTIONS,
  SENTIMENT_RATINGS_OPTIONS,
  workflowModalViews,
  workflowsMenuItems,
} from '../utils/workflowsUtils';
import UpgradePlanButton from '../components/UpgradePlanButton';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEventsData, fetchWorkflowsData } from '../store/workflowsActions';
import { workflowsActions } from '../store/workflowsSlice';
import { expanderViewModes } from '../utils/commonUtils';
import { slackVariables } from '../config';

const Workflows = () => {
  const { isAuthenticated } = useAppContext();
  const dispatch = useDispatch();
  const isWorkflowFeaturePaid = isAuthenticated.company_details.has_workflows;
  const { workflows, events, workflowModalOpened } = useSelector(
    (state) => state.workflows
  );

  const { onImportUsers } = useContext(AssignEmployeesContext);

  const [viewMode, setViewMode] = useState(
    localStorage.getItem('workflow_page_view_mode') ||
      expanderViewModes.TILE_MODE
  );

  const location = useLocation();
  let { edit: editParam, id: idParam } = queryString.parse(location.search);

  useEffect(() => {
    onImportUsers();
    dispatch(fetchWorkflowsData({ auth: isAuthenticated }));
    dispatch(fetchEventsData({ auth: isAuthenticated }));
  }, [dispatch, isAuthenticated, onImportUsers]);

  useEffect(() => {
    dispatch(
      workflowsActions.initStore({
        selectedSentimentalRating: SENTIMENT_RATINGS_OPTIONS[1],
        riskLevels: RISK_LEVEL_OPTIONS,
        selectedView: workflowModalViews.WORKFLOW_DETAILS,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadWorkflow = useCallback(
    ({ workflowId }) => {
      return api.getCompanyWorkflow({
        auth: isAuthenticated,
        workflowId,
      });
    },
    [isAuthenticated]
  );

  ReactTooltip.rebuild();

  const editWorflowHandler = useCallback(
    async (workflowId) => {
      const workflow = await loadWorkflow({ workflowId });
      dispatch(workflowsActions.editWorkflow({ workflow }));
    },
    [dispatch, loadWorkflow]
  );

  useEffect(() => {
    if (editParam && idParam) {
      editWorflowHandler(idParam);
    }
  }, [editParam, editWorflowHandler, idParam]);

  const openModalHandler = () => dispatch(workflowsActions.addWorkflow());

  const menuItems = workflowsMenuItems({
    openModalHandler,
    setViewMode,
  });

  const WorkflowsCalloutMessage = () => (
    <span>
      Workflows are a powerful tool that allow you to connect SaaS applications,
      have Haekka look for events, and then trigger a customizable response —
      such as assigning training, sending a message, or alerting an admin. To
      enable Workflows, you must have the{' '}
      <a
        href={slackVariables.shareableSlackAppUrl}
        className='cursor-pointer text-orange link'
      >
        latest version
      </a>{' '}
      of our Slack application installed.
    </span>
  );

  useEffect(() => {
    const menuItems = document.querySelectorAll('[data-value]');

    menuItems.forEach((element) => {
      const value = element.getAttribute('data-value');
      if (value === viewMode) {
        element.firstChild.classList.add('text-orange');
      } else {
        element.firstChild.classList.remove('text-orange');
      }
    });
  });

  if (events.length === 0)
    return (
      <TransitionWrapper show={true}>
        <Loader fullscreen />
      </TransitionWrapper>
    );

  return (
    <Fragment>
      <WorkflowsHeader />

      {isWorkflowFeaturePaid ? (
        <main>
          <div className='flex justify-end -mb-2'>
            {isAuthenticated.is_admin && (
              <div className='flex items-center bg-white border rounded-lg border-hka_gray-border expander__options'>
                {menuItems.map(({ icon, value, onClick, label }, i) => (
                  <span
                    key={i}
                    className='min-w-0 px-0 cursor-pointer text-hka_gray py-0.5 expander__option'
                    onClick={onClick}
                    data-value={value}
                  >
                    <span
                      className={`${icon} ${
                        i !== menuItems.length &&
                        'w-3.8 h-2.8 flex items-center justify-center expander__option-icon'
                      }`}
                      data-tip={label}
                    ></span>
                  </span>
                ))}
              </div>
            )}
          </div>

          <Expander
            title='Workflows'
            // menuItems={menuItems}
            className='mb-3'
          >
            {workflows.length > 0 ? (
              <WorkflowsList
                editWorflowHandler={editWorflowHandler}
                viewMode={viewMode}
              />
            ) : (
              <Fragment>
                <Callout
                  title='Welcome to Workflows!'
                  message={<WorkflowsCalloutMessage />}
                  date={false}
                  permanent
                >
                  <ActionButton
                    onClick={() => dispatch(workflowsActions.addWorkflow())}
                  >
                    <span className='icon-plus text-hka_blue'></span>
                    <span className='hidden sm:inline-block'>
                      Create a Workflow
                    </span>
                  </ActionButton>
                </Callout>
              </Fragment>
            )}
          </Expander>
        </main>
      ) : (
        <div className='text-center space-y-2 mr-0 md:mr-8'>
          <img
            src='https://haekka-public-images.s3.us-west-2.amazonaws.com/hka--upgrade_workflows.svg'
            alt=''
            className='m-auto'
          />
          <p>
            Your plan does not include access to{' '}
            <span className='font-basis-bold'>Workflows.</span>
            <br />
            Please contact our sales team to upgrade your account.
          </p>
          <UpgradePlanButton parentCpmToTrack={'Settings'} />
        </div>
      )}
      {workflowModalOpened && <CreateEditWorkflowModal />}
    </Fragment>
  );
};

export default Workflows;
