import api from './client';

// Note: you don't need to pass headers anymore - it's already configured in the client.js
const getHeaders = (auth) => {
  const employeeSlackId = auth.slack_uid;
  const companySlackId = auth.company_details.slack_workspace;

  const google_token_id = localStorage.getItem('google_token_id');
  const loginToken = google_token_id || auth.login_token;
  return {
    'Slack-User-Id': employeeSlackId,
    'Slack-Cid': companySlackId,
    'Login-Token': loginToken,
    'Google-User-id': auth.google_id,
  };
};

export const checkEmployee = async ({ slackUid }) => {
  const { data } = await api.post(`api/employees/login`, {
    slack_user_id: slackUid,
  });
  return data;
};

export const lessonSubmit = async ({
  auth,
  employeeId,
  trainingId,
  lessonId,
  questionId,
  answersIds,
  branching,
}) => {
  if (branching) {
    const { data } = await api.post(
      `/api/employees/${employeeId}/trainings/${trainingId}/lessons/${lessonId}/question/${questionId}/branching`,
      { answer: answersIds },
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } else {
    const { data } = await api.post(
      `/api/employees/${employeeId}/trainings/${trainingId}/lessons/${lessonId}/question_submit`,
      { answers: answersIds, question_id: questionId },
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  }
};

export const getEmployeeLessons = async ({
  auth,
  employeeId,
  trainingId,
  page = 1,
}) => {
  const { data } = await api.get(
    `/api/employees/${employeeId}/trainings/${trainingId}/lessons?page=${page}`,
    {
      headers: getHeaders(auth),
    }
  );
  return data;
};

export const getEmployeeLesson = async ({
  auth,
  employeeId,
  trainingId,
  lessonId,
}) => {
  const { data } = await api.get(
    `/api/employees/${employeeId}/trainings/${trainingId}/lessons/${lessonId}?lessons_with_editor_state=true`,
    {
      headers: getHeaders(auth),
    }
  );
  return data;
};

export const getEmployeeLessonAttempt = async ({ auth, lessonId }) => {
  const { data } = await api.get(
    `/api/employees/employee_lesson/${lessonId}/lesson_attempt`,
    {
      headers: getHeaders(auth),
    }
  );
  return data;
};

export const getEmployeeQuiz = async ({ auth, employeeId, quizId }) => {
  const { data } = await api.get(
    `/api/employees/${employeeId}/quizzes/${quizId}`,
    {
      headers: getHeaders(auth),
    }
  );
  return data;
};

export const curriculums = async ({
  auth,
  includeActive,
  includeLessons,
  pageSize,
  title,
  authors,
  categories,
  includesVideoContent,
  page = 1,
  includeCustomCurriculums = true,
}) => {
  if (includeActive === undefined) {
    includeActive = false;
  }
  if (pageSize === undefined) {
    pageSize = 10;
  }

  const params = {
    page,
    include_active: includeActive,
    include_lessons: includeLessons,
    page_size: pageSize,
    include_custom_curriculums: includeCustomCurriculums,
  };

  if (title) params.title = title;
  if (authors && authors.length > 0) params.authors = authors;
  if (categories && categories.length > 0) params.categories = categories;
  if (includesVideoContent)
    params.includes_video_content = includesVideoContent;

  const { data } = await api.get(`api/curriculums`, {
    headers: getHeaders(auth),
    params,
  });
  return data;
};

export const allCurriculums = async ({ auth, includeActive }) => {
  if (includeActive === undefined) {
    includeActive = false;
  }
  const { data } = await api.get(`api/curriculums/?no_paginate=${true}`, {
    headers: getHeaders(auth),
  });
  return data;
};

export const curriculumTypes = async ({
  auth,
  pageSize,
  includeTitles = true,
}) => {
  if (pageSize === undefined) {
    pageSize = 10;
  }
  const { data } = await api.get(
    `api/curriculums/types?page_size=${pageSize}&include_titles=${includeTitles}`,
    {
      headers: getHeaders(auth),
    }
  );
  return data;
};

export const curriculumTags = async ({ auth, pageSize }) => {
  if (pageSize === undefined) {
    pageSize = 10;
  }
  const { data } = await api.get(`api/curriculums/tags?page_size=${pageSize}`, {
    headers: getHeaders(auth),
  });
  return data;
};

export const createTraining = async ({
  auth,
  createData,
  create_new_curriculum,
}) => {
  const companyId = auth.company;
  const employeeId = auth.id;
  const params = create_new_curriculum
    ? {
        create_new_curriculum: true,
      }
    : '';
  const { data } = await api.post(
    `api/companies/${companyId}/trainings`,
    {
      ...createData,
      start_date: new Date(),
      end_date: createData.end_date ? createData.end_date : null,
      days_till_due: createData.days_till_due ? createData.days_till_due : null,
      created_by: employeeId,
    },
    {
      headers: getHeaders(auth),
      params: params,
    }
  );
  return data;
};

export const convertToCustom = async ({ auth, createData, training_id }) => {
  const companyId = auth.company;
  const employeeId = auth.id;
  const { data } = await api.post(
    `api/companies/${companyId}/trainings`,
    {
      ...createData,
      created_by: employeeId,
    },
    {
      headers: getHeaders(auth),
      params: {
        convert_to_custom: true,
        training_id,
      },
    }
  );
  return data;
};

export const createEmployee = async ({ auth, createData, isAdmin }) => {
  const companyId = auth.company;
  if (isAdmin) {
    createData.is_admin = true;
  }
  const { data } = await api.post(
    `api/companies/${companyId}/employees`,
    createData,
    {
      headers: getHeaders(auth),
    }
  );
  return data;
};

export const openBulkEmployeesTrainingCompany = async ({
  auth,
  curriculumTrainingId,
  manuallySelectedUsers,
  alertEmployees,
}) => {
  const companyId = auth.company;
  const { data } = await api.post(
    `api/employees/trainings/${curriculumTrainingId}/company`,
    {
      company_id: companyId,
      manually_selected_users: manuallySelectedUsers,
      alert_employees: alertEmployees,
    },
    {
      headers: getHeaders(auth),
    }
  );
  return data;
};

export const openBulkEmployeesTrainingChannel = async ({
  auth,
  curriculumTrainingId,
  channels,
  manuallySelectedUsers,
  alertEmployees,
}) => {
  const companyId = auth.company;
  try {
    const { data } = await api.post(
      `api/employees/trainings/${curriculumTrainingId}/channel`,
      {
        company_id: companyId,
        employee_data: {
          channels: channels,
        },
        manually_selected_employees: manuallySelectedUsers,
        alert_employees: alertEmployees,
      },
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const openBulkEmployeesTrainingSelect = async ({
  auth,
  curriculumTrainingId,
  manuallySelectedUsers,
  alertEmployees,
  copyUsersFromTraining,
}) => {
  const companyId = auth.company;
  try {
    const { data } = await api.post(
      `api/employees/trainings/${curriculumTrainingId}/selection`,
      {
        company_id: companyId,
        manually_selected_employees: manuallySelectedUsers,
        alert_employees: alertEmployees,
        copy_from_training: copyUsersFromTraining,
      },
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const openBulkEmployeesTrainingOkta = async ({
  companyID,
  curriculumTrainingId,
  groups,
  alertEmployees,
}) => {
  try {
    const { data } = await api.post(
      `api/employees/trainings/${curriculumTrainingId}/okta`,
      {
        company_id: companyID,
        selected_groups: groups.map((g) => g.value),
        alert_employees: alertEmployees,
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const openBulkEmployeesTrainingGroups = async ({
  auth,
  curriculumTrainingId,
  groups,
  alertEmployees,
}) => {
  const companyId = auth.company;
  try {
    const { data } = await api.post(
      `api/employees/trainings/${curriculumTrainingId}/groups`,
      {
        company_id: companyId,
        group_ids: groups.map(({ value }) => value),
        alert_employees: alertEmployees,
      },
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const openBulkEmployeesQuizSelect = async ({
  auth,
  companyQuizId,
  manuallySelectedUsers,
  assignNow,
}) => {
  const companyId = auth.company;
  try {
    const { data } = await api.post(
      `api/employees/quizzes/${companyQuizId}/selection`,
      {
        company_id: companyId,
        manually_selected_employees: manuallySelectedUsers,
        assign_now: assignNow,
      },
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

/*
 * Calls Slack API method.
 *
 * apiMethod: string. Slack API method name in Python lib interpetation.
 * For example, instead of 'users.list' use 'users_list'
 */
export const slackApi = async ({ auth, apiMethod, params = {} }) => {
  const { data } = await api.post(
    `api/slack/api`,
    {
      method_name: apiMethod,
      params,
    },
    {
      headers: getHeaders(auth),
    }
  );
  return data;
};

export const companyTop = async ({ auth }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.get(`/api/companies/${companyId}/employee/top`, {
      headers: getHeaders(auth),
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const getActiveCompanyTrainings = async ({ auth }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.get(`api/companies/${companyId}/trainings`, {
      headers: getHeaders(auth),
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const training = async ({ auth, trainingId }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.get(
      `/api/companies/${companyId}/trainings/${trainingId}?no_stats=true`,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getNotifications = async ({ auth }) => {
  try {
    const { data } = await api.get(
      `/api/companies/${auth.company}/notifications/get`,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTrainingDemo = async ({ auth, curriculumId }) => {
  try {
    const { data } = await api.get(
      `/api/curriculums/${curriculumId}/catalogDemo`,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getEmployeeWebTraining = async ({ auth, trainingId }) => {
  try {
    const { data } = await api.get(
      `/api/employees/${auth.id}/trainings/${trainingId}`,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getTrainingResult = async ({ auth, companyId, curriculumId }) => {
  try {
    const { data } = await api.get(
      `/api/companies/${companyId}/trainings/${curriculumId}/getresult`,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const updateTraining = async ({ auth, updateData, trainingId }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.patch(
      `/api/companies/${companyId}/trainings/${trainingId}`,
      updateData,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const updateNotifications = async ({ auth, updateData }) => {
  try {
    const { data } = await api.patch(
      `/api/companies/${auth.company}/notifications/update/`,
      updateData,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteTraining = async ({ auth, trainingId }) => {
  const companyId = auth.company;
  try {
    const { data } = await api.delete(
      `/api/companies/${companyId}/trainings/${trainingId}`,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTrainingEvidence = async ({ auth, trainingId }) => {
  const companyId = auth.company;
  const employeeId = auth.id;

  try {
    const { data } = await api.get(
      `/api/companies/${companyId}/trainings/${trainingId}/${employeeId}/getevidence`,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteEmployeeAdmin = async ({ auth, employeeId }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.patch(
      `/api/companies/${companyId}/employees/${employeeId}/admin`,
      {},
      {
        headers: getHeaders(auth),
      }
    );
    return !!data;
  } catch (error) {
    throw error;
  }
};

export const addEmployeeAdmin = async ({ auth, employeeId }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.put(
      `/api/companies/${companyId}/employees/${employeeId}/admin`,
      {},
      {
        headers: getHeaders(auth),
      }
    );
    return !!data;
  } catch (error) {
    throw error;
  }
};

export const removeAnswer = async ({ auth, answerId }) => {
  try {
    const { data } = await api.delete(`/api/answers/${answerId}`, {
      headers: getHeaders(auth),
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCompanyCurriculumByTitle = async ({ auth, title }) => {
  try {
    const { data } = await api.get(`/api/curriculums/?title=${title}`, {
      headers: getHeaders(auth),
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCurriculum = async ({ auth, id }) => {
  const { data } = await api.get(`/api/curriculums/${id}/global`, {
    headers: getHeaders(auth),
  });
  return data;
};

export const updateCurriculum = async ({ auth, id, updatedData }) => {
  const { data } = await api.patch(
    `/api/curriculums/${id}/global`,
    updatedData,
    {
      headers: getHeaders(auth),
    }
  );
  return data;
};

export const uploadImage = async ({
  auth,
  file,
  id,
  questionId,
  contentType,
}) => {
  try {
    let name = Date.now() + '_' + file.name;
    const extension = name.substring(name.lastIndexOf('.'), name.length);
    name = name.substring(0, name.lastIndexOf('.'));
    name = name.replace(/[^0-9a-z]/gi, '');
    name = name.substring(0, 50);
    name = name + extension;
    const formData = new FormData();
    formData.append('image_name', name);
    formData.append('content_type', contentType);
    formData.append('image_url', file, name);

    if (contentType === 'lesson') {
      formData.append('lesson_id', id);
    } else if (contentType === 'engagement_question') {
      formData.append('quiz_id', id);
      formData.append('question_id', questionId);
    } else if (contentType === 'engagement_message') {
      formData.append('quiz_id', id);
    } else if (contentType === 'workflow') {
      formData.append('workflow_id', id);
    } else if (contentType === 'stream_post') {
      formData.append('stream_post_id', id);
      formData.delete('image_url');
      formData.append('image_url_public', file, name);
    } else if (contentType === 'assessment_question_content') {
      formData.append('assessment_question_id', id);
    }

    const { data } = await api.post(
      `/api/lessons/lesson_image_upload/`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getLesson = async ({ auth, lessonId }) => {
  try {
    const { data } = await api.get(`/api/lessons/${lessonId}`, {
      headers: getHeaders(auth),
      params: {
        no_parse: true,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateLessonImage = async ({
  auth,
  id,
  imagesIds,
  contentType,
}) => {
  let payload = null;
  if (contentType === 'lesson') {
    payload = {
      lessonId: id,
      imagesIds,
    };
  } else if (contentType === 'quiz') {
    payload = {
      quizId: id,
      imagesIds,
    };
  } else if (contentType === 'engagement_message') {
    payload = {
      message_quiz_id: id,
      imagesIds,
    };
  } else if (contentType === 'workflow') {
    payload = {
      workflow_id: id,
      imagesIds,
    };
  } else if (contentType === 'stream_post') {
    payload = {
      stream_post_id: id,
      imagesIds,
    };
  }

  try {
    const { data } = await api.post(
      `/api/lessons/update_lesson_image/`,
      payload,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const availableCurriculums = async ({ auth }) => {
  try {
    const { data } = await api.get(`/api/curriculums`, {
      headers: getHeaders(auth),
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const authenticateWithSlack = async ({ code, redirect_uri }) => {
  try {
    const { data } = await api.get(
      `/authenticate_with_slack?code=${code}&redirect_uri=${redirect_uri}`,
      {}
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const createSlackInstallation = async ({ installationData }) => {
  try {
    const { data } = await api.post(
      `/api/companies/installations`,
      { ...installationData },
      {}
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const loginEmployee = async ({ auth, loginData }) => {
  try {
    const { data } = await api.post(
      `/api/employees/login`,
      { ...loginData },
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkInstalledApps = async ({ auth }) => {
  try {
    const { data } = await api.get(
      `/api/companies/installations/check/${auth.company_details.id}`,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const haekkaPublishTraining = async ({ auth, trainingId }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.post(
      `/api/companies/${companyId}/trainings/${trainingId}/publish`,
      {},
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const mergeUpdate = async ({ auth, updateData }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.patch(
      `/api/companies/${companyId}/merge/update`,
      updateData,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const mergeRemoveIntegration = async ({
  auth,
  integration,
  remove_employees = false,
}) => {
  const companyId = auth.company;

  try {
    const { data } = await api.delete(
      `/api/companies/${companyId}/merge/delete/${integration}?remove_employees=${remove_employees}`,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const openBulkEmployeesQuizCompany = async ({
  auth,
  selectedQuiz,
  manuallySelectedUsers,
  assignNow,
}) => {
  const companyId = auth.company;
  const { data } = await api.post(
    `/api/employees/quizzes/${selectedQuiz}/company`,
    {
      company_id: companyId,
      manually_selected_users: manuallySelectedUsers,
      assign_now: assignNow,
    },
    {
      headers: getHeaders(auth),
    }
  );
  return data;
};

export const openBulkEmployeesQuizChannel = async ({
  auth,
  selectedQuiz,
  channels,
  manuallySelectedUsers,
  assignNow,
}) => {
  const companyId = auth.company;
  try {
    const { data } = await api.post(
      `/api/employees/quizzes/${selectedQuiz}/channel`,
      {
        company_id: companyId,
        channels: channels,
        manually_selected_employees: manuallySelectedUsers,
        assign_now: assignNow,
      },
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const openBulkEmployeesQuizOkta = async ({
  companyID,
  selectedQuiz,
  groups,
  assignNow,
}) => {
  try {
    const { data } = await api.post(
      `api/employees/quizzes/${selectedQuiz}/okta`,
      {
        company_id: companyID,
        selected_groups: groups.map((g) => g.value),
        assign_now: assignNow,
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getEmojisJsonMapper = async ({ auth, file }) => {
  try {
    let { data } = await api.get(`/file/${file}`, {
      headers: getHeaders(auth),
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const logInternalEvent = async ({ auth, message }) => {
  try {
    const { data } = await api.post(
      `/api/internal-event-logger`,
      { message },
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTrainingsStats = async ({ auth }) => {
  try {
    const { data } = await api.get(
      `/api/companies/${auth.company_details.id}/stats`,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkLastEmployeesSync = async ({ auth }) => {
  try {
    const { data } = await api.get(
      `/api/companies/sync/check/${auth.company_details.id}`,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const syncEmployees = async ({ auth }) => {
  try {
    const { data } = await api.post(
      `/api/companies/sync/${auth.company_details.id}`,
      {},
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const signInWithGoogle = async ({ token }) => {
  try {
    const { data } = await api.post(`/api/employees/login/google`, { token });
    return data;
  } catch (error) {
    throw error;
  }
};

export const signUpWithGoogle = async () => {
  try {
    const { data } = await api.get(`/google_login`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getGroups = async ({ auth, params = {} }) => {
  try {
    const { data } = await api.get(
      `/api/companies/${auth.company_details.id}/groups`,
      {
        headers: getHeaders(auth),
        params,
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const createGroup = async ({
  auth,
  name,
  icon = null,
  manager,
  members = null,
  channels = null,
  channelsToSync = null,
  slackUserGroups = null,
}) => {
  try {
    const payload = {
      name,
      icon,
      manager,
      members,
      channels,
      channels_to_sync: channelsToSync,
      slack_user_groups: slackUserGroups,
    };

    const { data } = await api.post(
      `/api/companies/${auth.company_details.id}/groups`,
      payload,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const editGroup = async ({
  auth,
  id,
  name,
  icon = null,
  manager,
  membersToBeAdded = [],
  membersToBeRemoved = [],
  channelsToBeAdded = [],
  channelsToBeRemoved = [],
  channelsToSync = [],
  slackUserGroupsToBeAdded = [],
  slackUserGroupsToBeRemoved = [],
}) => {
  try {
    const payload = {
      name,
      icon,
      manager,
      members_to_be_added: membersToBeAdded,
      members_to_be_removed: membersToBeRemoved,
      channels_to_be_added: channelsToBeAdded,
      channels_to_be_removed: channelsToBeRemoved,
      channels_to_sync: channelsToSync,
      slack_user_groups_to_be_added: slackUserGroupsToBeAdded,
      slack_user_groups_to_be_removed: slackUserGroupsToBeRemoved,
    };
    const { data } = await api.put(
      `/api/companies/${auth.company_details.id}/groups/${id}`,
      payload,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteGroup = async ({ auth, id }) => {
  try {
    const { data } = await api.delete(
      `/api/companies/${auth.company_details.id}/groups/${id}`,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getGroupEmployees = async ({
  auth,
  limit,
  page,
  pageSize = 10,
  group,
  name,
}) => {
  const companyId = auth.company;
  const groupId = group.id;

  try {
    const { data } = await api.get(
      `/api/companies/${companyId}/groups/${groupId}/members/list`,
      {
        headers: getHeaders(auth),
        params: {
          limit,
          page,
          name,
          page_size: pageSize,
        },
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getCurriculumAuthors = async ({ auth }) => {
  try {
    const { data } = await api.get('/api/curriculums/authors', {
      headers: getHeaders(auth),
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const getWistiaRefreshToken = async ({ auth, type = undefined }) => {
  let url = `/api/lessons/wistia/refresh_token`;
  if (!!type) url = url + `?token_type=${type}`;

  try {
    const { data } = await api.get(url, {
      headers: getHeaders(auth),
    });
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCustomVideo = async ({ auth, videoId }) => {
  try {
    const { data } = await api.get(
      `/api/lessons/wistia/update_video_upload?videoId=${videoId}`,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateCustomVideo = async ({ auth, reqData }) => {
  try {
    const { data } = await api.post(
      `/api/lessons/wistia/update_video_upload`,
      reqData,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteCustomVideo = async ({ auth, videoId, type }) => {
  try {
    await api.delete(
      `/api/lessons/wistia/update_video_upload?videoId=${videoId}&type=${type}`,
      {
        headers: getHeaders(auth),
      }
    );
  } catch (error) {
    throw error;
  }
};

export const getSlackUserGroups = async ({ auth }) => {
  const companyId = auth.company;
  const employeeSlackId = auth.slack_uid;
  const companySlackId = auth.company_details.slack_workspace;
  try {
    const { data } = await api.get(`/api/companies/${companyId}/slack-groups`, {
      headers: {
        'Slack-User-Id': employeeSlackId,
        'Slack-Cid': companySlackId,
        'Login-Token': auth.login_token,
      },
    });

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const generateApiKey = async ({ auth, name = '' }) => {
  const companyId = auth.company;
  try {
    const { data } = await api.post(
      `/api/companies/${companyId}/tokens/generate`,
      { name },
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const renewTrainingWithNewCurriculum = async ({
  auth,
  trainingId,
  exludeUsers,
}) => {
  try {
    const { data } = await api.post(
      `/api/companies/${auth.company_details.id}/trainings/${trainingId}/renew`,
      {
        exclude_recently_completed: exludeUsers,
      },
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const migrateTrainingWithArchivedCurriculum = async ({
  auth,
  trainingID,
}) => {
  try {
    const { data } = await api.post(
      `/api/companies/${auth.company_details.id}/trainings/${trainingID}/migrate-training`,
      {},
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getApiKeys = async ({ auth }) => {
  const companyId = auth.company;
  try {
    const { data } = await api.get(`/api/companies/${companyId}/tokens/get`, {
      headers: getHeaders(auth),
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteApiKey = ({ auth, apiKeyId }) => {
  const companyId = auth.company;
  try {
    return api.delete(`/api/companies/${companyId}/tokens/delete`, {
      headers: getHeaders(auth),
      params: {
        api_key_id: apiKeyId,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const getCompanyWorkflows = async ({ auth }) => {
  try {
    const { data } = await api.get(
      `/api/companies/${auth.company_details.id}/workflows`,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCompanyWorkflow = async ({ auth, workflowId }) => {
  try {
    const { data } = await api.get(
      `/api/companies/${auth.company_details.id}/workflows/${workflowId}`,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const createCompanyWorkflow = async ({ auth, bodyData }) => {
  try {
    const { data } = await api.post(
      `/api/companies/${auth.company_details.id}/workflows`,
      bodyData,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateCompanyWorkflow = async ({ auth, workflowId, bodyData }) => {
  try {
    const { data } = await api.patch(
      `/api/companies/${auth.company_details.id}/workflows/${workflowId}`,
      bodyData,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteCompanyWorkflow = async ({ auth, workflowId }) => {
  try {
    const { data } = await api.delete(
      `/api/companies/${auth.company_details.id}/workflows/${workflowId}`,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getEvdtEvents = async ({ auth }) => {
  try {
    const { data } = await api.get(`/api/evdtevents`, {
      headers: getHeaders(auth),
    });

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getEmployeesWithAssignedWorkflow = async ({
  auth,
  workflow_id,
  page = 1,
}) => {
  try {
    const { data } = await api.get(
      `/api/employees/workflow/${workflow_id}?page=${page}`,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw new Error(`ERROR: ${error}`);
  }
};

export const getAssignedEmployeesDataForWorkflows = async ({ auth }) => {
  try {
    const { data } = await api.get(`/api/employees/workflow/assigned_all`, {
      headers: getHeaders(auth),
    });
    return data;
  } catch (error) {
    throw new Error(`ERROR: ${error}`);
  }
};

export const employeeUnassignWorkflow = async ({
  auth,
  employeeId,
  workflow_id,
}) => {
  try {
    const { data } = await api.post(
      `/api/employees/${employeeId}/workflow/${workflow_id}/unassign`,
      {},
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw new Error(`ERROR: ${error}`);
  }
};

export const getCompanyEngagementMetrics = async ({ auth }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.get(
      `/api/companies/${companyId}/engagements/metrics`,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getPhishingTemplates = async ({
  auth,
  page = 1,
  pageSize = 10,
  nameFilter,
  sortOption,
  categoryFilter,
  applicationFilter,
}) => {
  try {
    const params = { page, page_size: pageSize };

    if (nameFilter) {
      params.name = nameFilter;
    }

    if (sortOption) {
      params.sort = sortOption;
    }

    if (categoryFilter) {
      params.category = categoryFilter;
    }

    if (applicationFilter) {
      params.application = applicationFilter;
    }

    const { data } = await api.get(`/api/phishing/templates`, {
      params,
      headers: getHeaders(auth),
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const getVantaIntegrationLink = async ({ auth }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.get(`/api/vanta/oauth/url/${companyId}`, {
      headers: getHeaders(auth),
    });

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const syncVantaManually = async ({ auth }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.post(
      `/api/vanta/sync/${companyId}`,
      {},
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCompanyPhishingCampaigns = async ({ auth, page }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.get(
      `/api/companies/${companyId}/phishing/campaigns`,
      {
        headers: getHeaders(auth),
        params: {
          page,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const createPhishingCampaign = async ({ auth, payload }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.post(
      `/api/companies/${companyId}/phishing/campaigns`,
      payload,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getPhishingWhitelistConfirmation = ({ auth, templateId }) => {
  const companyId = auth.company;
  try {
    return api.get(
      `/api/companies/${companyId}/phishing/whitelist/${templateId}`,
      {
        headers: getHeaders(auth),
      }
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const openBulkEmployeesWorkflowCompany = async ({
  auth,
  selectedWorkflow,
  excludeUsers,
  manuallySelectedUsers,
}) => {
  const companyId = auth.company;
  const { data } = await api.post(
    `/api/employees/workflow/${selectedWorkflow}/company`,
    {
      company_id: companyId,
      employee_data: {
        exclude_users: excludeUsers,
      },
      manually_selected_users: manuallySelectedUsers,
    },
    {
      headers: getHeaders(auth),
    }
  );
  return data;
};

export const openBulkEmployeesWorkflowChannel = async ({
  auth,
  selectedWorkflow,
  channels,
  manuallySelectedUsers,
}) => {
  const companyId = auth.company;
  try {
    const { data } = await api.post(
      `/api/employees/workflow/${selectedWorkflow}/channel`,
      {
        company_id: companyId,
        employee_data: {
          channels: channels,
        },
        manually_selected_employees: manuallySelectedUsers,
      },
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const whitelistPhishingTemplateEmailAddress = ({ auth, templateId }) => {
  const companyId = auth.company;

  try {
    return api.post(
      `/api/companies/${companyId}/phishing/whitelist/${templateId}`,
      {},
      {
        headers: getHeaders(auth),
      }
    );
  } catch (error) {
    throw new Error(`ERROR: ${error}`);
  }
};

export const openBulkEmployeesWorkflowSelect = async ({
  auth,
  selectedWorkflow,
  manuallySelectedUsers,
}) => {
  const companyId = auth.company;
  try {
    const { data } = await api.post(
      `api/employees/workflow/${selectedWorkflow}/selection`,
      {
        company_id: companyId,
        manually_selected_employees: manuallySelectedUsers,
      },
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deletePhishingCampaign = ({ auth, campaignId }) => {
  const companyId = auth.company;

  try {
    return api.delete(
      `/api/companies/${companyId}/phishing/campaigns/${campaignId}/delete`,
      {
        headers: getHeaders(auth),
      }
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const editPhishingCampaign = async ({ auth, campaignId, payload }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.put(
      `/api/companies/${companyId}/phishing/campaigns/${campaignId}/edit`,
      payload,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCompanyPhishingMetrics = async ({ auth }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.get(
      `/api/companies/${companyId}/phishing/metrics`,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const openBulkEmployeesStreamCompany = async ({
  auth,
  selectedStream,
  excludeUsers,
  manuallySelectedUsers,
}) => {
  const companyId = auth.company;
  const { data } = await api.post(
    `/api/employees/streams/${selectedStream}/company`,
    {
      company_id: companyId,
      employee_data: {
        exclude_users: excludeUsers,
      },
      manually_selected_users: manuallySelectedUsers,
    },
    {
      headers: getHeaders(auth),
    }
  );
  return data;
};

export const openBulkEmployeesStreamChannel = async ({
  auth,
  selectedStream,
  channels,
  manuallySelectedUsers,
}) => {
  const companyId = auth.company;
  try {
    const { data } = await api.post(
      `/api/employees/streams/${selectedStream}/channel`,
      {
        company_id: companyId,
        employee_data: {
          channels: channels,
        },
        manually_selected_employees: manuallySelectedUsers,
      },
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw new Error(`ERROR: ${error}`);
  }
};

export const openBulkEmployeesStreamSelect = async ({
  auth,
  selectedStream,
  manuallySelectedUsers,
}) => {
  const companyId = auth.company;
  try {
    const { data } = await api.post(
      `api/employees/streams/${selectedStream}/selection`,
      {
        company_id: companyId,
        manually_selected_employees: manuallySelectedUsers,
      },
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw new Error(`ERROR: ${error}`);
  }
};

export const getEmployeesAssignedToPhishingCampaign = async ({
  auth,
  campaignId,
  page,
  pageSize = 10,
  name = null,
}) => {
  const companyId = auth.company;

  try {
    const params = {
      page,
      page_size: pageSize,
    };

    if (name) {
      params.name = name;
    }

    const { data } = await api.get(
      `/api/companies/${companyId}/phishing/campaigns/${campaignId}/employees`,
      {
        params,
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw new Error(`ERROR: ${error}`);
  }
};

export const removeEmployeeFromPhishingCampaign = async ({
  auth,
  campaignId,
  employeeId,
}) => {
  const companyId = auth.company;

  try {
    await api.delete(
      `/api/companies/${companyId}/phishing/campaigns/${campaignId}/remove/${employeeId}`,
      {
        headers: getHeaders(auth),
      }
    );
  } catch (error) {
    throw new Error(`ERROR: ${error}`);
  }
};

export const getFeatureFlags = async ({ auth }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.get('/feature_flags', {
      headers: {
        'Company-Id': companyId,
      },
    });

    return data;
  } catch (error) {
    throw new Error(`ERROR: ${error}`);
  }
};

export const uninstallVanta = async ({ auth }) => {
  const companyId = auth.company;
  try {
    const { data } = await api.post(`/api/vanta/uninstall/${companyId}`, null, {
      headers: getHeaders(auth),
    });

    return data;
  } catch (error) {
    throw new Error(`ERROR: ${error}`);
  }
};

export const getCompanyNotificationSchedule = async ({ auth }) => {
  const companyId = auth.company;
  try {
    const { data } = await api.get(
      `/api/companies/${companyId}/notifications/schedule`,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    return null;
  }
};

export const updateCompanyNotificationSchedule = async ({ auth, payload }) => {
  const companyId = auth.company;
  try {
    const { data } = await api.patch(
      `/api/companies/${companyId}/notifications/schedule`,
      payload,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getPhishingCampaignDetails = async ({
  auth,
  phishingCampaignId,
}) => {
  const companyId = auth.company;
  try {
    const { data } = await api.get(
      `/api/companies/${companyId}/phishing/campaigns/${phishingCampaignId}/details`,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const validateEmployeesInCSVFile = async ({ auth, formData }) => {
  try {
    const { data } = await api.post(`api/employees/validate_csv`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getPhishingCampaignActionDetails = async ({
  auth,
  phishingCampaignId,
  params,
}) => {
  const queryParams = {
    page: params.page,
    page_size: params.pageSize,
  };

  if (params.name) {
    queryParams.name = params.name;
  }

  if (params.sort) {
    queryParams.sort = params.sort;
  }

  if (params.attacks) {
    queryParams.attacks = params.attacks;
  }

  if (params.dayOverride) {
    queryParams.day_override = params.dayOverride;
  }

  const companyId = auth.company;
  try {
    const { data } = await api.get(
      `/api/companies/${companyId}/phishing/campaigns/${phishingCampaignId}/actions`,
      {
        headers: getHeaders(auth),
        params: queryParams,
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getPhishingCampaignAttacks = async ({
  auth,
  phishingCampaignId,
  params,
}) => {
  const queryParams = {};

  if (params.attacks) {
    queryParams.attacks = params.attacks;
  }

  if (params.sort) {
    queryParams.sort = params.sort;
  }

  const companyId = auth.company;
  try {
    const { data } = await api.get(
      `/api/companies/${companyId}/phishing/campaigns/${phishingCampaignId}/attacks`,
      {
        headers: getHeaders(auth),
        params: queryParams,
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const launchPhishingAttack = async ({ auth, phishingCampaignId }) => {
  const companyId = auth.company;
  try {
    const { data } = await api.post(
      `/api/companies/${companyId}/phishing/campaigns/${phishingCampaignId}/launch`,
      {},
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getTemporaryData = async ({ auth, dataID }) => {
  try {
    const { data } = await api.get(`api/temporary_data/${dataID}`, {
      headers: getHeaders(auth),
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTrainingsByCurriculum = async ({ auth, curriculumID }) => {
  const companyID = auth.company;
  try {
    const { data } = await api.get(
      `/api/companies/${companyID}/trainings/renewed/${curriculumID}`,
      {
        headers: getHeaders(auth),
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getIntegrations = async ({ auth }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.get(`/api/companies/${companyId}/integrations`, {
      headers: getHeaders(auth),
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const removeMergeIntegration = async ({ auth, integration }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.post(
      `/api/integrations/merge/${integration}/company/${companyId}/remove_merge_integration`,
      {},
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getPhishingCampaignActionDetailsTable = async ({
  auth,
  phishingCampaignId,
  params,
}) => {
  const queryParams = {};

  if (params.sort) {
    queryParams.sort = params.sort;
  }

  if (params.attacks) {
    queryParams.attacks = params.attacks;
  }

  if (params.employees) {
    queryParams.employees = params.employees;
  }

  const companyId = auth.company;
  try {
    const { data } = await api.get(
      `/api/companies/${companyId}/phishing/campaigns/${phishingCampaignId}/details/table`,
      {
        headers: getHeaders(auth),
        params: queryParams,
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getPhishingCampaignActionEmployeeDetails = async ({
  auth,
  phishingCampaignId,
  employeeId,
  attacks = null,
  sortOption = null,
}) => {
  const queryParams = {};

  if (attacks) {
    queryParams.attacks = attacks;
  }

  if (sortOption) {
    queryParams.sort = sortOption;
  }

  const companyId = auth.company;
  try {
    const { data } = await api.get(
      `/api/companies/${companyId}/phishing/campaigns/${phishingCampaignId}/details/${employeeId}`,
      {
        headers: getHeaders(auth),
        params: queryParams,
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getLessonIssueReport = async ({
  auth,
  lessonId,
  objectId,
  objectType,
}) => {
  try {
    const { data } = await api.get(`/api/lessons/${lessonId}/report`, {
      headers: getHeaders(auth),
      params: {
        object_id: objectId,
        object_type: objectType,
      },
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const reportLessonIssue = async ({
  auth,
  lessonId,
  objectId,
  objectType,
  content,
}) => {
  try {
    const { data } = await api.post(
      `/api/lessons/${lessonId}/report`,
      {
        content,
      },
      {
        headers: getHeaders(auth),
        params: {
          object_id: objectId,
          object_type: objectType,
        },
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getCompanyPhishingSettings = async ({ auth }) => {
  const companyId = auth.company;

  try {
    const { data } = await api.get(
      `/api/companies/${companyId}/phishing/settings`,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateCompanyPhishingSettings = async ({ auth, payload }) => {
  const companyId = auth.company;

  const requestData = {};

  if (payload.forwardingEmails) {
    requestData.forwarding_emails = payload.forwardingEmails;
  }

  if (payload.phishingEmailForwardedNotificationEmails) {
    requestData.phishing_email_forwarded_notification_emails =
      payload.phishingEmailForwardedNotificationEmails;
  }

  if (payload.imapHost) {
    requestData.imap_host = payload.imapHost;
  }

  if (payload.imapPort) {
    requestData.imap_port = payload.imapPort;
  }

  try {
    const { data } = await api.put(
      `/api/companies/${companyId}/phishing/settings`,
      requestData,
      {
        headers: getHeaders(auth),
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const generatePhishingAttackReport = async ({
  auth,
  attackId,
  startDate,
  endDate,
  onDownloadProgress,
}) => {
  const companyId = auth.company;

  try {
    return await api.get(
      `/api/companies/${companyId}/phishing/attacks/${attackId}/report`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
        onDownloadProgress,
        headers: getHeaders(auth),
        responseType: 'arraybuffer',
      }
    );
  } catch (error) {
    throw error;
  }
};

export const getPhishingTemplateCategories = async ({ auth }) => {
  try {
    const { data } = await api.get(`/api/phishing/categories`, {
      headers: getHeaders(auth),
    });

    return data;
  } catch (error) {
    throw new Error(error);
  }
};
