import React from "react";
import PropTypes from "prop-types";
import Modal from "../Modal/Modal";
import ActionButton from "../ActionButton";
import { Link } from "react-router-dom";
import TrainingOrLessonDuration from "../TrainingOrLessonDuration";

const TrainingPreviewModal = ({
  showModal,
  onClose,
  selectedCurriculum,
  onAddTrainingClick,
}) => {
  const onAddTraining = (curriculumId) => {
    onAddTrainingClick(curriculumId);
    onClose();
  };

  const customHeader = (
    <div className="flex items-center w-full h-full">
      {selectedCurriculum.author_icon_url ? (
        <img
          src={selectedCurriculum.author_icon_url}
          alt=""
          className="w-4 h-4 mr-2 rounded-lg"
        />
      ) : (
        <img
          src="https://haekka-author-icons.s3.us-west-2.amazonaws.com/HKA--UPDATED--Author_Haekka.png"
          alt=""
          className="w-4 h-4 mr-2 rounded-lg"
        />
      )}

      <div className="w-full truncate">
        <p className="leading-2.6 text-hka_gray-dark truncate text-base2 font-basis-bold">
          {selectedCurriculum.title}
        </p>
        <p className="text-sm">
          Written By:{" "}
          {selectedCurriculum.author_name
            ? selectedCurriculum.author_name
            : "Editorial Staff"}
        </p>
      </div>
    </div>
  );

  const submitButton = selectedCurriculum.is_already_in_use ? (
    <ActionButton
      type="default"
      to={`/trainings/${selectedCurriculum.active_training_id}`}
    >
      View
      <span className="icon-arrow right text-hka_blue"></span>
    </ActionButton>
  ) : (
    <button
      className="button button--green button__icon--left"
      onClick={() => onAddTraining(selectedCurriculum.id)}
    >
      <span className="icon-plus text-hka_green"></span>Add Training
    </button>
  );
  const firstLesson = selectedCurriculum.lessons[0];

  return (
    <Modal
      show={showModal}
      title={selectedCurriculum.title}
      expandable
      customHeader={customHeader}
      onClose={onClose}
      noFooter
    >
      <main className="modal__body">
        {selectedCurriculum.subtitle && (
          <section className="mb-2 space-y-1">
            <h4>Training Overview</h4>
            <p>{selectedCurriculum.subtitle}</p>
            <TrainingOrLessonDuration
              duration={selectedCurriculum.average_length}
            />
          </section>
        )}
        <section className="mb-2">
          <div className="w-full sm:w-1/3">
            <ActionButton
              type="primary"
              to={`/catalog/${selectedCurriculum.id}?demoLessonId=${firstLesson.id}`}
            >
              Demo This Training
            </ActionButton>
          </div>
        </section>
        <section>
          <h4 className="pb-1 leading-4 border-b border-hka_gray-light">
            Lessons
          </h4>

          {selectedCurriculum.lessons.map((lesson, i) => {
            const titleTokens = lesson.title.split(" | ");
            return (
              <Link
                to={`/catalog/${selectedCurriculum.id}?demoLessonId=${lesson.id}`}
                className="text-base"
                key={lesson.id}
              >
                <div className="flex items-center h-4 border-b cursor-pointer border-hka_gray-light">
                  <span className="inline-flex w-3.5 pl-0.5 pr-1 text-hka_gray">
                    {i + 1}
                  </span>
                  <p className="flex-1 leading-4 link font-basis-medium">
                    {titleTokens.length === 1 ? titleTokens[0] : titleTokens[1]}
                  </p>
                  {lesson.includes_video && (
                    <span className="ml-auto transition-all icon-video text-hka_gray group-hover:text-orange"></span>
                  )}
                </div>
              </Link>
            );
          })}
        </section>
      </main>
      <footer className="modal__footer">
        <button
          onClick={onClose}
          type="button"
          className="w-full px-2 button button--default sm:w-auto"
        >
          Close
        </button>
        {submitButton}
      </footer>
    </Modal>
  );
};

TrainingPreviewModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedCurriculum: PropTypes.object.isRequired,
  onAddTrainingClick: PropTypes.func.isRequired,
};

export default TrainingPreviewModal;
