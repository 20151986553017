import {
  EVENT_TRACKER,
  internalSlackChannelLogger,
} from "../analytics/eventTracker";

const uploadRequestsReference = [];

export const getUploadRequestReference = (randomId) => {
  return uploadRequestsReference.find((u) => u.randomID === randomId);
};

export const addUploadRequestReference = (urr) => {
  uploadRequestsReference.push(urr);
};

export const getCurrentWistiaUploadPayload = ({
  progressEvent,
  timeStarted,
  id,
  type,
  position,
  parent,
  title,
  randomID,
}) => {
  const stillToLoad = progressEvent.total - progressEvent.loaded;
  const progress = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(
    0
  );
  const timeElapsed = new Date() - timeStarted; // Assuming that timeStarted is a Date Object
  const uploadSpeed = progressEvent.loaded / (timeElapsed / 1000); // Upload speed in second
  let etc = +(stillToLoad / uploadSpeed).toFixed(0);

  if (etc > 3600) {
    etc =
      (etc / 3600).toFixed(0) +
      "h" +
      " " +
      ((etc % 3600) / 60).toFixed(0) +
      "min";
  } else if (etc > 60) {
    etc = (etc / 60).toFixed(0) + "min";
  } else {
    etc = etc.toFixed(0) + "sec";
  }

  return {
    id,
    type,
    position: position,
    parent,
    success: true,
    progress: +progress,
    title,
    etc,
    message: "",
    completed: false,
    randomId: randomID,
    stillToLoad,
  };
};

export const handleVideoUploadTrack = ({ auth, type, reqData }) => {
  internalSlackChannelLogger(EVENT_TRACKER.VIDEO_UPLOADED, auth, {
    Admin: auth.name,
    Company: auth.company_details.title,
    "Company ID": auth.company_details.id,
    "For object":
      type === "lesson"
        ? "Lesson"
        : type === "engagement-question"
        ? "Engagement Question"
        : "Engagement",
    "Video Name": reqData.name,
    "Video Duration": (+reqData.duration).toFixed(2),
    "Video Hashed ID": reqData.hashed_id,
    "Company Link to Admin Panel": `${process.env.REACT_APP_API_URL}/admin/company/company/${auth.company_details.id}/change`,
  });
};

export const handleVideoDeletedTrack = ({ auth, type }) => {
  internalSlackChannelLogger(EVENT_TRACKER.VIDEO_DELETED, auth, {
    Admin: auth.name,
    Company: auth.company_details.title,
    "Company ID": auth.company_details.id,
    "For object":
      type === "lesson"
        ? "Lesson"
        : type === "engagement-question"
        ? "Engagement Question"
        : "Engagement",
    "Company Link to Admin Panel": `${process.env.REACT_APP_API_URL}/admin/company/company/${auth.company_details.id}/change`,
  });
};
