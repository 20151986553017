import React from "react";
import Modal from "../Modal/Modal";

export default function MaxLessonCountWarningModal({ showModal, onClose }) {
  const MAX_LESSON_COUNT = 100;
  return (
    <Modal
      show={showModal}
      title={`Maximum number of lessons reached`}
      onClose={onClose}
      noFooter
    >
      <main>
        <p>
          Haekka currently supports {MAX_LESSON_COUNT} lessons per training.
          Please contact our support team with any questions{" "}
          <a href="mailto:support@haekka.com" className="link">
            support@haekka.com
          </a>
          .
        </p>
      </main>
      <footer>
        <section className="flex space-y-1 sm:space-y-0">
          <button
            className="w-full px-2 ml-auto bg-green-100 button button--default sm:w-auto hover:bg-green-200 text-hka_green"
            onClick={onClose}
          >
            Close
          </button>
        </section>
      </footer>
    </Modal>
  );
}
