import { useCallback, useEffect, useState } from "react";
import * as api from "../../api";
import Select from "react-select";
import { onError } from "../../libs/errorLib";
import { useAppContext } from "../../libs/contextLib";

const CopyUserFromAnotherTraining = ({ assignEmployeesContext }) => {
  const { isAuthenticated } = useAppContext();

  const [trainings, setTrainings] = useState([]);

  const { object, copyUsersFromTraining, setCopyUsersFromTraining } =
    assignEmployeesContext;

  const loadCompanyTrainings = useCallback(() => {
    return api.getActiveCompanyTrainings({
      auth: isAuthenticated,
    });
  }, [isAuthenticated]);

  useEffect(() => {
    const onInit = async () => {
      try {
        const { results } = await loadCompanyTrainings();
        setTrainings(
          results
            .map((t) => ({
              label: t.curriculum_detail.title,
              value: t.id,
            }))
            .filter((t) => t.value !== object?.id)
        );
      } catch (e) {
        onError(e);
      }
    };

    onInit();
  }, [loadCompanyTrainings, object?.id]);

  return (
    <div className="mt-2 mb-3">
      <h4 className="font-basis-bold mb-1.5">
        Copy users from another training{" "}
        <span className="text-hka_gray">(Optional)</span>
      </h4>
      <Select
        options={trainings}
        onChange={(t) => setCopyUsersFromTraining(t)}
        value={copyUsersFromTraining}
        placeholder={"Select Training"}
        isMulti={false}
        classNamePrefix="select"
        isDisabled={false}
        className="select--select-employees-manually"
        unstyled
      />
    </div>
  );
};

export default CopyUserFromAnotherTraining;
