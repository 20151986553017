import { format } from "date-fns";
import React, { Fragment, useState } from "react";
import { formatAvatar } from "../../../utils/formatAvatar";
import { getActionTypeLabelAndColorClass } from "../../../utils/phishingUtils";
import EmployeeActionsCard from "./EmployeeActionsCard";

export function HeaderCell({ columnInfo, sticky = false }) {
  return (
    <div className={`table__cell ${sticky ? "table__cell--employee" : ""}`}>
      <p className=" font-basis-medium text-hka_gray-dark">
        {columnInfo.label}
      </p>
      <div className="flex items-center w-full truncate">
        {columnInfo.label !== "Employee" && (
          <Fragment>
            <img
              className={`rounded inline mr-0.5 ${
                columnInfo.templateIcon ? "" : ""
              }`}
              src={
                columnInfo.templateIcon ||
                "https://haekka-author-icons.s3.us-west-2.amazonaws.com/HKA--UPDATED--Author_Haekka.png"
              }
              alt={columnInfo.id}
              height={16}
              width={16}
            />
            <p className="text-sm truncate font-basis-medium text-hka_gray">
              {columnInfo.templateName}
            </p>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export function EmployeeCell({
  employee,
  setSelectedEmployeeForRemoval,
  noBorder = false,
}) {
  return (
    <div className={`table__cell table__cell--employee ${!noBorder ? "" : ""}`}>
      <div className="mr-1">
        {employee.avatar_image ? (
          <div className="w-2.4 h-2.4 min-w-2.4">
            <img
              className="rounded-md"
              src={employee.avatar_image}
              alt={employee.name}
            />
          </div>
        ) : (
          formatAvatar({
            name: employee.name,
            width: 2.4,
            marginRight: 0,
            textSize: "text-sm",
          })
        )}
      </div>
      <div className="w-full truncate">
        <p className="pr-1 truncate text-hka_gray-dark font-basis-medium">
          {employee.name}
        </p>
        <p className="w-full pr-1 text-sm truncate font-basis-medium">
          {employee.email}
        </p>
      </div>

      <span
        className="transition-all transform rounded-lg cursor-pointer icon-trash text-hka_gray hover:bg-hka_gray-light"
        onClick={() => setSelectedEmployeeForRemoval(employee)}
      ></span>
    </div>
  );
}

export function DataCell({ action, sortOption }) {
  const [showCard, setShowCard] = useState(false);

  const createdDate = action ? new Date(action.created) : null;

  const formattedDate = action ? format(createdDate, "MM/dd/yyyy") : null;
  const formattedTime = action ? format(createdDate, "hh:mm a") : null;

  const actionTypeLabelAndColorClass = getActionTypeLabelAndColorClass(
    action?.action_type
  );

  return (
    <div className="justify-between table__cell">
      <div className="flex flex-col flex-1">
        <p
          className={`font-basis-medium ${
            actionTypeLabelAndColorClass.colorClass
              ? actionTypeLabelAndColorClass.colorClass
              : "text-hka_gray-dark"
          }`}
        >
          {actionTypeLabelAndColorClass.label}
        </p>

        {formattedDate && formattedTime ? (
          <p className="text-sm opacity-60 text-hka_gray">
            <span className="font-basis-bold">{`${formattedDate}`}</span> at{" "}
            <span className="font-basis-bold">{`${formattedTime}`}</span>
          </p>
        ) : (
          <p>—</p>
        )}
      </div>
      {action?.action_type === "breach_credentials" && (
        <div className="flex justify-center w-2 h-2 text-white rounded-full bg-hka_red">
          <span className="m-0 text-base cursor-default font-basis-bold">
            !
          </span>
        </div>
      )}
      {action?.more_actions_exist && (
        <EmployeeActionsCard
          employee={action?.employeeId}
          attack={action?.attackId}
          showCard={showCard}
          setShowCard={setShowCard}
          sortOption={sortOption}
        />
      )}
    </div>
  );
}
