import { Fragment, useEffect, useState } from "react";
import ListItems from "../ListItems";
import WorkflowTile from "./WorkflowTile";
import * as api from "../../api";
import { useAppContext } from "../../libs/contextLib";
import DeleteWorkflowConfirmationModal from "./DeleteWorkflowConfirmationModal";
import WorkflowRow from "./WorkflowRow";
import toast from "react-hot-toast";
import { onError } from "../../libs/errorLib";
import { useSelector, useDispatch } from "react-redux";
import { workflowsActions } from "../../store/workflowsSlice";

const WorkflowsList = ({ editWorflowHandler, viewMode }) => {
  const dispatch = useDispatch();
  const { workflows } = useSelector((state) => state.workflows);
  const { isAuthenticated } = useAppContext();

  const [showDeleteWorkflowModal, setShowDeleteWorkflowModal] = useState(false);
  const [workflowToDelete, setWorkflowToDelete] = useState(null);

  const [assignedEmployeesData, setAssignedEmployeesData] = useState([]);

  useEffect(() => {
    const getCurrentEmployees = async () => {
      try {
        const assignedEmployeesData =
          await api.getAssignedEmployeesDataForWorkflows({
            auth: isAuthenticated,
          });
        setAssignedEmployeesData(assignedEmployeesData);
      } catch (error) {
        onError(error);
      }
    };
    if (workflows.length > 0) getCurrentEmployees();
  }, [isAuthenticated, workflows]);

  const onDeleteConfirmed = async () => {
    await api.deleteCompanyWorkflow({
      auth: isAuthenticated,
      workflowId: workflowToDelete,
    });
    setShowDeleteWorkflowModal(false);
    dispatch(
      workflowsActions.setWorkflows({
        workflows: workflows.filter((wf) => wf.id !== workflowToDelete),
      })
    );
    setWorkflowToDelete(null);
    toast.success("Workflow deleted");
  };

  const deleteWorkflowHandler = async (workflowId) => {
    setShowDeleteWorkflowModal(true);
    setWorkflowToDelete(workflowId);
  };

  return (
    <Fragment>
      <ListItems
        fullWidth
        emptyListText={null}
        flex={viewMode === "TILE_MODE" ? "row" : "column"}
      >
        {workflows.map((workflow) => {
          const workflowActionTitle = workflow.action
            .replace("(custom_response)", "")
            .replace("(assign_training)", "")
            .replace("(admin_message)", "");
          return viewMode === "TILE_MODE" ? (
            <WorkflowTile
              key={workflow.id}
              className="h-4"
              workflow={workflow}
              workflowActionTitle={workflowActionTitle}
              editWorflowHandler={editWorflowHandler}
              onDelete={deleteWorkflowHandler}
              assignedEmployeesData={assignedEmployeesData[workflow.id]}
            />
          ) : (
            <WorkflowRow
              key={workflow.id}
              className="h-4"
              workflow={workflow}
              workflowActionTitle={workflowActionTitle}
              editWorflowHandler={editWorflowHandler}
              onDelete={deleteWorkflowHandler}
              assignedEmployeesData={assignedEmployeesData[workflow.id]}
            />
          );
        })}
      </ListItems>
      {showDeleteWorkflowModal && (
        <DeleteWorkflowConfirmationModal
          onClose={() => {
            setShowDeleteWorkflowModal(false);
            setWorkflowToDelete(null);
          }}
          onSubmit={onDeleteConfirmed}
        />
      )}
    </Fragment>
  );
};

export default WorkflowsList;
