import * as api from '../api';
import toast from 'react-hot-toast';
import { onError } from '../libs/errorLib';
import { useAppContext } from '../libs/contextLib';
import { useState } from 'react';
import ConvertToCustomTrainingModal from './Training/ConvertToCustomTrainingModal';
import { useDispatch } from 'react-redux';
import { trainingEvidenceActions } from '../store/trainingEvidenceSlice';
import { getTrainingEvidenceData } from '../store/trainingEvidenceActions';
import { reportEvidenceSuccessMessage } from '../utils/reportEvidenceMessages';
import DropdownMenu from './Base/DropdownMenu';

const TrainingDropdownMenu = (props) => {
  const { isAuthenticated } = useAppContext();
  const dispatch = useDispatch();
  const [convertToCustomConfirmModal, setConvertToCustomConfirmModal] =
    useState(false);
  const [submitting, setSubmitting] = useState(false);

  const downloadEvidenceHandler = async () => {
    if (props.training.renew_cycle === 'none') {
      try {
        dispatch(
          getTrainingEvidenceData({
            auth: isAuthenticated,
            trainingID: props.training.id,
          })
        );
        toast.success(reportEvidenceSuccessMessage);
      } catch (e) {
        onError(e);
      }
    } else {
      dispatch(trainingEvidenceActions.setModalVisibility({ isOpened: true }));
    }
  };

  const handleAddLesson = () => {
    props.onHandleAddLesson();
  };

  const handleConvertToCustom = () => {
    setConvertToCustomConfirmModal(true);
  };

  const onConvertToCustom = async () => {
    try {
      setSubmitting(true);
      const training = await api.convertToCustom({
        auth: isAuthenticated,
        createData: {
          curriculum: props.training.curriculum_detail.id,
          curriculum_title: props.training.curriculum_detail.title,
          // curriculumType:
          //   props.training.curriculum_detail.curriculum_type_details.id,
          annual: props.training.annual,
          renew_cycle: props.training.renew_cycle,
          passing_grade: props.training.passing_grade,
          has_weekly_quiz: props.training.has_weekly_quiz,
          days_till_due: props.training.days_till_due,
        },
        training_id: props.training.id,
      });
      props.setTraining(training);
      props.setTriggerGetTraining((v) => !v);
      setConvertToCustomConfirmModal(false);
      toast.success(`Training converted to custom! Start editing!`);
    } catch (e) {
      onError(e);
    }
    setSubmitting(false);
  };

  const createGlobalTraining = async () => {
    try {
      await api.haekkaPublishTraining({
        auth: isAuthenticated,
        trainingId: props.training.id,
      });
      props.setTriggerGetTraining((p) => !p);
    } catch (error) {
      onError(error);
    }
  };

  const assignEmployeesHandler = () => {
    props.setModalOpened(true);
  };

  const trainingSettingsHandler = () => {
    props.onSetEditTrainingModalShow(true);
  };

  const createGlobalCurriculumHandler = () => {
    createGlobalTraining();
    toast.success('Global Curriculum created/updated');
    props.setTriggerGetTraining((p) => !p);
  };

  const globalCurriculumSettingsHandler = () => {
    props.setIsGlobalCurriculumSettingsModalOpened(true);
  };

  const dropMenuActions = [
    {
      icon: 'icon-add-lesson',
      label: 'Add Lesson',
      onClick: handleAddLesson,
      disabled: false,
      show: props.displayAddLessonButton,
    },
    {
      icon: 'icon-add-users',
      label: 'Assign Employees',
      onClick: assignEmployeesHandler,
      disabled: false,
    },
    {
      icon: 'icon-cog',
      label: 'Training Settings',
      onClick: trainingSettingsHandler,
      disabled: false,
    },
    {
      icon: 'icon-download',
      label: 'Download Evidence',
      onClick: downloadEvidenceHandler,
      disabled: false,
    },
    {
      icon: 'icon-convert',
      label: 'Convert to Custom',
      onClick: handleConvertToCustom,
      disabled: false,
      show: !props.isCustomTraining,
    },
    {
      icon: 'icon-plus',
      label: `${
        props.globalCurriculum ? 'Update' : 'Create'
      } Global Curriculum`,
      onClick: createGlobalCurriculumHandler,
      disabled: false,
      show: isAuthenticated.company_details.is_global_content_maker,
    },
    {
      icon: 'icon-cog',
      label: 'Global Curriculum Settings',
      onClick: globalCurriculumSettingsHandler,
      disabled: false,
      show: isAuthenticated.company_details.is_global_content_maker,
    },
  ];

  return (
    <>
      <DropdownMenu actions={dropMenuActions} />
      <ConvertToCustomTrainingModal
        showModal={convertToCustomConfirmModal}
        submitting={submitting}
        onSubmit={() => onConvertToCustom()}
        onClose={() => setConvertToCustomConfirmModal(false)}
      />
    </>
  );
};

export default TrainingDropdownMenu;
