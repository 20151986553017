import Modal from '../../Modal/Modal';

const DeleteAnswerConfirmModal = ({ onSubmit, onClose, answer }) => {
  return (
    <Modal
      show={true}
      title='Delete Answer'
      onSubmit={onSubmit}
      onClose={onClose}
      submitButtonType='danger'
      submitButtonText='Delete Answer'
      compact
    >
      <p>
        Are you sure you want to delete this answer?
      </p>
    </Modal>
  );
};

export default DeleteAnswerConfirmModal;
