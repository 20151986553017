import Modal from '../../Modal/Modal';
import Note from '../../Note';

const DeleteQuestionConfirmModal = ({
  onSubmit,
  onClose,
  questionPosition,
  questions,
}) => {
  const questionToDelete = questions.find(
    (q) => q.position === questionPosition
  );
  return (
    <Modal
      show={true}
      title={`Delete Question`}
      onSubmit={onSubmit}
      onClose={onClose}
      submitButtonType='danger'
      submitButtonText='Delete Question'
      compact
    >
      
        <p>
          Are you sure you want to delete <span className='font-basis-bold'>Question {questionPosition + 1}</span>?
        </p>
    </Modal>
  );
};

export default DeleteQuestionConfirmModal;
