import { useState } from 'react';
import Divider from './Divider';

export const TabPanel = ({ children }) => {
  return children;
};

const Tab = ({ item, active, onSelect }) => {
  return (
    <div onClick={onSelect} className={`tab ${active && 'tab--active'}`}>
      <p
        className={`text-base font-basis-medium ${
          active ? 'text-orange' : 'text-hka_gray hover:text-orange'
        }`}
      >
        {item.caption}
      </p>
    </div>
  );
};

const Tabber = ({ items, actions, children }) => {
  const [activeTab, setActiveTab] = useState(
    items.length > 0 ? items[0].type : undefined
  );

  return (
    <>
      <div className='tabs__control-bar'>
        <div className='tabs'>
          {items.map((item) => (
            <Tab
              item={item}
              active={activeTab === item.type}
              onSelect={() => setActiveTab(item.type)}
              key={item.type}
            />
          ))}
        </div>
        {
          <div className=''>
            {actions.find((action) => action.showOn === activeTab)?.jsx}
          </div>
        }
        {/* <Divider /> */}
      </div>

      {children.filter((panel) => panel.props?.type === activeTab)}
    </>
  );
};

export default Tabber;
