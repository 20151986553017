import { useState } from 'react';
import useValidateInputLength from '../../utils/custom-hooks/useValidateInputLength';
import Modal from '../Modal/Modal';
import NextLessonBranch from './NextLessonBranch';
import * as api from '../../api';
import { useAppContext } from '../../libs/contextLib';
import { onError } from '../../libs/errorLib';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { lessonActions } from '../../store/lessonSlice';
import AnswerExplanation from '../AnswerExplanation/AnswerExplanation';

const Answer = ({ currAnswer, provided, aIndex, scenarioType, handle }) => {
  const dispatch = useDispatch();

  const { answers } = useSelector((state) => state.lesson);

  const { showWarning, remainingChars } = useValidateInputLength(
    currAnswer.answer,
    120,
    150
  );

  const [isAnswerDeleteModalVisible, setIsAnswerDeleteModalVisible] =
    useState(false);
  const [answerIndexForDeletion, setAnswerIndexForDeletion] = useState(null);
  const { isAuthenticated } = useAppContext();

  const handleAnswerDeletion = async () => {
    const answerToDelete = answers.find(
      (answer) => answer.id === answerIndexForDeletion
    );
    try {
      if (!answerToDelete?.id.includes('temp_id')) {
        await api.removeAnswer({
          auth: isAuthenticated,
          answerId: answerToDelete.id,
        });
      }
      setIsAnswerDeleteModalVisible(false);
      dispatch(
        lessonActions.setAnswers(
          answers.filter((answer) => answer.id !== answerToDelete.id)
        )
      );
      setAnswerIndexForDeletion(null);
      toast.success(`Answer deleted`);
    } catch (e) {
      onError(e);
    }
  };

  return (
    <div className='mb-2 lesson__answer' key={currAnswer.id}>
      <div className='flex bg-white rounded-lg container__lesson-input shadow-light p-0.5 items-center mb-0.5'>
        {provided && (
          <span className='icon-drag-drop' {...provided.dragHandleProps}></span>
        )}
        {handle && <span className=''>{handle}</span>}
        <div className='relative flex w-full'>
          <input
            type='text'
            maxLength='150'
            value={currAnswer.answer}
            onChange={(e) =>
              dispatch(
                lessonActions.editAnswer({
                  answerIndex: aIndex,
                  property: 'answer',
                  value: e.target.value,
                })
              )
            }
            placeholder={`Answer ${currAnswer.position + 1}`}
            className={`input--question outline-none border-none pl-0 ${
              showWarning ? '' : ''
            }`}
          />
          <p
            className={`absolute  -bottom-0.2 text-xs text-orange ${
              showWarning ? '' : ''
            }`}
          >
            <span className='font-basis-bold'>{remainingChars}</span> characters
            remaining
          </p>
        </div>
        <div className='flex px-1 space-x-1'>
          <p>Correct?</p>
          <label className='switch'>
            <input
              type='checkbox'
              name={`answer-${currAnswer}-correct`}
              checked={currAnswer.correct}
              onChange={(e) =>
                dispatch(
                  lessonActions.editAnswer({
                    answerIndex: aIndex,
                    property: 'correct',
                    value: e.target.checked,
                  })
                )
              }
            />
            <span className='slider'></span>
          </label>
        </div>
        <span
          className='icon-trash text-hka_gray w-2.4'
          onClick={() => {
            setIsAnswerDeleteModalVisible(true);
            setAnswerIndexForDeletion(aIndex);
          }}
        ></span>
      </div>
      <div>
        <AnswerExplanation
          answer={currAnswer}
          removeExplanationHandler={() =>
            dispatch(
              lessonActions.editExplanation({
                answerIndex: aIndex,
                value: null,
              })
            )
          }
          editExplanationHandler={(e) => {
            dispatch(
              lessonActions.editExplanation({
                answerIndex: aIndex,
                value: e.target.value,
              })
            );
          }}
        />
        {scenarioType === 'branching' && (
          <NextLessonBranch
            currAnswer={currAnswer}
            onEdit={(e) => {
              dispatch(
                lessonActions.setNextQuestion({
                  answerIndex: aIndex,
                  value: e.value,
                })
              );
            }}
          />
        )}
      </div>
      {isAnswerDeleteModalVisible && (
        <Modal
          show={true}
          title='Confirm answer deletion'
          submitButtonText='Yes, delete answer'
          submitButtonType='danger'
          onSubmit={handleAnswerDeletion}
          onClose={() => {
            setIsAnswerDeleteModalVisible(false);
            setAnswerIndexForDeletion(null);
          }}
        >
          <p>You are about to delete this answer. Are you sure?</p>
        </Modal>
      )}
    </div>
  );
};

export default Answer;
