import { React } from "react";

const Loader = ({ fullscreen }) => (
  <div
    className={`fixed top-0 left-0 z-20
     flex items-center justify-center flex-grow w-full h-full min-w-full transition-all bg-opacity-30 duration-200 
     `}
  >
    <div
      className={`relative ${
        fullscreen
          ? "w-full h-full flex justify-center items-center"
          : "w-10 h-10 rounded-2xl"
      } bg-hka_gray-light bg-opacity-70 shadow-xl`}
    >
      <img
        src="https://haekka-public-images.s3-us-west-2.amazonaws.com/HKA--loading-animation.gif"
        alt=""
        className="absolute w-10 h-10 p-2 bg-white shadow-xl rounded-xl"
      />
    </div>
  </div>
);

export default Loader;
