import Modal from "../../Modal/Modal";

const DeletePostModal = ({ onSubmit, onClose }) => {
  return (
    <Modal
      show={true}
      title="Delete Post"
      submitButtonText="Delete Post"
      submitButtonType="danger"
      onSubmit={onSubmit}
      onClose={onClose}
      compact
    >
      <p>Are you sure you want to delete this post?</p>
    </Modal>
  );
};

export default DeletePostModal;
