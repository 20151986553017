import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteCompanyEngagement } from "../../../api/engagementsApi";
import { toast } from "react-hot-toast";

export const useDeleteEngagementData = ({
  page,
  companyEngagement,
  setDeleteItemWarning,
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteCompanyEngagement,
    onSuccess: () => {
      setDeleteItemWarning(false);
      queryClient.setQueryData(["engagements", page], (prev) => ({
        ...prev,
        results: prev.results.filter((ce) => ce.id !== companyEngagement.id),
      }));
      queryClient.refetchQueries(["engagements", page]);
      toast.success(`Engagement deleted: ${companyEngagement.quiz_title}`);
    },
  });
};
