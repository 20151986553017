import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Modal from "../Modal/Modal";
import { getLessonIssueReport, reportLessonIssue } from "../../api";
import { onError } from "../../libs/errorLib";
import LoaderInline from "../LoaderInline";

export default function ReportLessonIssueModal({
  auth,
  lessonId,
  objectId,
  objectType,
  showModal,
  setShowModal,
}) {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const title = `Hi there! See an issue with this lesson? Let us know below 👇`;

  useEffect(() => {
    const onLoad = async () => {
      try {
        setIsLoading(true);

        const lessonIssueReport = await getLessonIssueReport({
          auth,
          lessonId,
          objectId,
          objectType,
        });

        setContent(lessonIssueReport.content);
      } catch (error) {
        setContent("");
      } finally {
        setIsLoading(false);
      }
    };

    if (showModal) {
      onLoad();
    }

    return () => {
      setContent("");
    };
  }, [showModal, auth, lessonId, objectId, objectType]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      await reportLessonIssue({
        auth,
        lessonId,
        objectId,
        objectType,
        content,
      });

      setShowModal(false);
      toast.success("Issue reported successfully!");
    } catch (error) {
      onError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      show={showModal}
      onClose={() => setShowModal(false)}
      title="Report Lesson Issue"
      onSubmit={handleSubmit}
      submitButtonText="Report"
      submitValid={!isLoading}
    >
      {isLoading ? (
        <LoaderInline />
      ) : (
        <div>
          <p className="text-hka_gray-dark mb-2">{title}</p>
          <textarea
            type="text"
            value={content}
            className="w-full min-h-7 px-1 py-0.5 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray-dark border-hka_gray-lights hka-input mb-1"
            onChange={(e) => setContent(e.target.value)}
          ></textarea>
        </div>
      )}
    </Modal>
  );
}
