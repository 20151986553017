import React from "react";
import { getActionTypeChartColor } from "../../../../utils/phishingUtils";

export default function DonutChartLegend({ metricsDataMap }) {
  const sortedMap = new Map(
    [...metricsDataMap.entries()].sort((a, b) => b[1] - a[1])
  );

  return (
    <div className="flex flex-col space-y-0.5 justify-center">
      {Array.from(sortedMap.keys()).map((key, i) => (
        <div
          key={i}
          className="pl-1 w-full text-sm flex flex-row justify-between"
        >
          <span className="">{key}</span>
          <span
            className="ml-1 px-0.5 bg-orange text-white rounded-md font-basis-bold"
            style={{ backgroundColor: getActionTypeChartColor(key) }}
          >
            {metricsDataMap.get(key)}
          </span>
        </div>
      ))}
    </div>
  );
}
