import Select from "react-select";
import { FREQUENCY_OPTIONS } from "../../../utils/workflowsUtils";
import { customStyles } from "./CreateEvent";
import { useDispatch, useSelector } from "react-redux";
import { workflowsActions } from "../../../store/workflowsSlice";

const SelectFrequency = () => {
  const dispatch = useDispatch();
  const { selectedFrequency, silenceEvent, selectedAction } = useSelector(
    (state) => state.workflows
  );

  const selectFrequencyHandler = (frequency) => {
    dispatch(workflowsActions.selectFrequency({ frequency }));
  };

  const setSilenceHandler = () => {
    dispatch(workflowsActions.setSilenceEvent({ value: !silenceEvent }));
  };

  return (
    <div className="mt-3">
      <p className="mb-1 text-hka_gray-dark">
        Send Frequency <span className="text-hka_gray">(Required)</span>
      </p>
      <Select
        onChange={selectFrequencyHandler}
        value={selectedFrequency}
        placeholder="Select Send Frequency"
        isMulti={false}
        options={FREQUENCY_OPTIONS}
        classNamePrefix="select"
        styles={customStyles}
        maxMenuHeight={125}
        className="w-full"
        unstyled
      />

      {selectedAction?.value === "custom_response" && (
        <div className="mt-2">
          <span className="relative">
            <label className={`checkbox`}>
              <input
                type="checkbox"
                name="select-answer"
                id={"cbx"}
                disabled={false}
                defaultChecked={silenceEvent}
                onChange={setSilenceHandler}
              />
              <span className="icon-check"></span>
            </label>
            <label
              className={`text-base tracking-wide cursor-pointer text-hka_gray font-basis-regular absolute top-0 left-2`}
              htmlFor={"cbx"}
            >
              Allow users to silence this event
            </label>
          </span>
        </div>
      )}
    </div>
  );
};

export default SelectFrequency;
