import React from "react";
import { format } from "date-fns";

const withPhishingCampaignTemplateDetails =
  (WrappedComponent) =>
  ({
    template,
    lastAttack,
    removable,
    isWhitelisted,
    setSelectedTemplateForRemoval,
    setShowRemoveTemplateFromCampaignModal,
    onPreviewTemplateActionClick,
    onWhitelistTemplateActionClick,
  }) => {
    function handleOnRemoveTemplateClick() {
      setSelectedTemplateForRemoval(template);
      setShowRemoveTemplateFromCampaignModal(true);
    }

    const formattedLastSentDate = lastAttack?.created
      ? format(new Date(lastAttack.created), "MMM. dd, yyyy")
      : null;

    const reportRate = lastAttack?.report_rate
      ? Math.round(lastAttack.report_rate)
      : 0;

    const actions = [];

    if (template.preview_image_url) {
      actions.push({
        icon: "icon-view",
        label: "Preview Template",
        onClick: () => onPreviewTemplateActionClick(template),
      });
    }

    if (!isWhitelisted) {
      actions.push({
        icon: "icon-check",
        label: "Whitelist Template",
        onClick: () => onWhitelistTemplateActionClick(template),
      });
    }

    if (removable) {
      actions.push({
        icon: "icon-trash",
        label: "Remove Template",
        onClick: handleOnRemoveTemplateClick,
      });
    }

    return (
      <WrappedComponent
        template={template}
        lastAttack={lastAttack}
        actions={actions}
        formattedLastSentDate={formattedLastSentDate}
        reportRate={reportRate}
        isWhitelisted={isWhitelisted}
      />
    );
  };

export default withPhishingCampaignTemplateDetails;
