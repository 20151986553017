import { useQuery } from '@tanstack/react-query';
import { getEmployeeTrainingAssessmentSummary } from '../../../api/employeeTrainingsApi';

export const useEmployeeTrainingAssessmentResults = ({
  employeeID,
  assessmentID,
}) => {
  return useQuery({
    queryKey: [
      'employee-trainings-assessment-results',
      employeeID,
      assessmentID,
    ],
    queryFn: () =>
      getEmployeeTrainingAssessmentSummary({
        employeeID,
        assessmentID,
      }),
  });
};
