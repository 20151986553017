import AnswerExplanation from '../AnswerExplanation/AnswerExplanation';
import CheckboxGroup, { checkboxOptions } from '../Base/CheckboxGroup';

const AssessmentQuestionAnswer = ({
  answer,
  updateAnswersHandler,
  setDeleteAnswerModal,
  correctAnswersDisabled,
  handle,
}) => {
  const onAnswerUpdate = (answer, field, value) => {
    updateAnswersHandler({ answer, field, value });
  };

  const onSelectHandler = (option) => {
    onAnswerUpdate(answer, 'correct', checkboxOptions.CORRECT === option);
  };

  const deleteAnswerExplanationHandler = async () => {
    onAnswerUpdate(answer, 'explanation', null);
  };

  return (
    <div className='mb-1'>
      <div className='engagement__answer'>
        <div className='answer__drag-drop'>{handle}</div>
        <div className='answer__input-container'>
          <input
            maxLength={150}
            type='text'
            placeholder='Enter answer'
            className={`input--engagement-answer ${
              150 - answer.answer.length <= 30 ? 'border-none' : ''
            }`}
            value={answer.answer}
            onChange={(e) => onAnswerUpdate(answer, 'answer', e.target.value)}
            autoFocus
          />
          {150 - answer.answer.length <= 30 && (
            <p
              className={`text--warning ${
                150 - answer.answer.length <= 30 ? '' : ''
              }`}
            >
              <span className='font-basis-bold'>
                {150 - answer.answer.length}
              </span>{' '}
              characters remaining
            </p>
          )}
        </div>

        <CheckboxGroup
          selectedOption={
            answer.correct ? checkboxOptions.CORRECT : checkboxOptions.INCORRECT
          }
          onSelect={onSelectHandler}
          disabledCorrect={!answer.correct && correctAnswersDisabled}
        />

        <button
          className='button button--icon icon-trash text-hka_gray'
          onClick={() =>
            setDeleteAnswerModal({
              showConfirmModal: true,
              aPosition: answer.position,
            })
          }
        ></button>
      </div>
      <AnswerExplanation
        answer={answer}
        editExplanationHandler={(e) => {
          onAnswerUpdate(answer, 'explanation', e.target.value);
        }}
        removeExplanationHandler={deleteAnswerExplanationHandler}
      />
    </div>
  );
};

export default AssessmentQuestionAnswer;
