import { Fragment, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AssignEmployeesContext from '../../../store/assign-employeees-context';
import AssingEmployeesOptions from '../../AssignEmployees/AssingEmployeesOptions';
import ManuallyOptions from '../../AssignEmployees/ManuallyOptions';
import SlackOptions from '../../AssignEmployees/SlackOptions';
import Modal from '../../Modal/Modal';
import ListAssignedEmployees from './ListAssignedEmployees';

export const streamModalViews = {
  ASSIGN_EMPLOYEES: 'Assign Employees',
  ASSIGNED_EMPLOYEES: 'Assigned Employees',
};

const SubscribeEmployeesModal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    setModalOpened,
    onModalClose,
    handleSubmit,
    selectedTab,
    submitIsValid,
    object,
    internalModalTab,
  } = useContext(AssignEmployeesContext);

  const [selectedView, setSelectedView] = useState(
    streamModalViews.ASSIGN_EMPLOYEES
  );

  useEffect(() => {
    if (internalModalTab) {
      setSelectedView(internalModalTab);
    }
  }, [internalModalTab]);

  const closeModalHandler = () => {
    setModalOpened(false);
    onModalClose();
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('review_assigned_users');
    navigate(
      {
        pathname: window.location.pathname,
        search: queryParams.toString(),
      },
      { replace: true }
    );
  };

  return (
    <Modal
      show={true}
      title='Manage Employees'
      submitButtonText='Subscribe Employees'
      onClose={closeModalHandler}
      submitValid={submitIsValid}
      onSubmit={handleSubmit}
      expandable
    >
      <section className='modal--create-workflow'>
        {/* Modal tabs */}
        <div className='flex mb-2'>
          {Object.entries(streamModalViews).map(([tabKey, tabLabel]) => {
            return (
              <h4
                key={tabKey}
                className={`tab font-basis-bold mr-2 cursor-pointer ${
                  tabLabel === selectedView
                    ? 'text-orange border-b-2 pb-0.5 border-current'
                    : 'text-hka_gray'
                }`}
                data-tab={tabLabel}
                onClick={() => setSelectedView(tabLabel)}
              >
                {tabLabel}
              </h4>
            );
          })}
        </div>

        {selectedView === streamModalViews.ASSIGN_EMPLOYEES && (
          <Fragment>
            <AssingEmployeesOptions hideForStreams />
            {selectedTab === 'SLACK' && <SlackOptions hideChannelSync />}
            {selectedTab === 'MANUALLY' && <ManuallyOptions />}
          </Fragment>
        )}

        {selectedView === streamModalViews.ASSIGNED_EMPLOYEES && (
          <ListAssignedEmployees stream={object} />
        )}
      </section>
    </Modal>
  );
};

export default SubscribeEmployeesModal;
