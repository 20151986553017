import Modal from '../Modal/Modal';

const RemoveDoceboCourseConfirmModal = ({
  onSubmit,
  onClose,
  removeAssigmentsWhenRemovingDocebo,
  setRemoveAssigmentsWhenRemovingDocebo,
}) => {
  return (
    <Modal
      show={true}
      title='Remove Docebo Course'
      onSubmit={onSubmit}
      onClose={onClose}
      submitButtonType='danger'
      submitButtonText='Remove Association'
      compact
    >
      <p>Are you sure you want to remove the associated Docebo Course?</p>

      <section className='mt-2 mb-1 flex'>
        <section className='flex items-center'>
          <label className='switch'>
            <input
              type='checkbox'
              name='has_weekly_quiz'
              checked={removeAssigmentsWhenRemovingDocebo}
              onChange={() => setRemoveAssigmentsWhenRemovingDocebo((s) => !s)}
              className='cursor-pointer'
            />
            <span className='slider'></span>
          </label>
        </section>
        <p
          className='ml-1 text-sm cursor-pointer'
          onClick={() => setRemoveAssigmentsWhenRemovingDocebo((s) => !s)}
        >
          Do you want to unassign all Docebo users?
        </p>
      </section>
    </Modal>
  );
};

export default RemoveDoceboCourseConfirmModal;
