import { useQueries } from '@tanstack/react-query';
import { getEmployeeAssessmentAttempts } from '../../../api/trainingsApi';

export const useEmployeeAssessmentAttemptsData = ({
  assessments,
  employeeID,
}) => {
  const data = useQueries({
    queries: assessments.map((item) => {
      return {
        queryKey: ['employee-assessment-attempts', item.value.id, employeeID],
        queryFn: () =>
          getEmployeeAssessmentAttempts({
            assessmentID: item.value.id,
            employeeID: employeeID,
          }),
        select: (response) => {
          return { assessmentID: item.value.id, data: response.results };
        },
      };
    }),
  });
  return data.map((q) => ({ ...q.data, isLoading: q.isLoading }));
};
