import { Cell } from "rsuite-table";

import { slackVariables } from "../../../config";

import LoaderInline from "../../LoaderInline";
import { emojisToHtmlRepresentation } from "../../../utils/commonUtils";

const ReactionsCell = ({
  rowData,
  dataKey,
  isLoading,
  channelEngagementReactions,
  reactionScopeIsMissing,
  ...props
}) => {
  const emojis = channelEngagementReactions[rowData[dataKey]["slack_uid"]];
  const formattedText = emojisToHtmlRepresentation(emojis.join(" "), emojis);
  return (
    <Cell {...props} style={{ padding: 0 }}>
      <div className="flex items-center w-full h-3 space-x-0.5 ml-1">
        {reactionScopeIsMissing && (
          <p className="text-sm text-hka_gray">
            Please{" "}
            <a
              href={slackVariables.shareableSlackAppUrl}
              className="cursor-pointer text-orange link"
            >
              reinstall
            </a>{" "}
            the Haekka Slack app in order to see users reactions.
          </p>
        )}
        {isLoading && <LoaderInline loaderSize="3" />}
        {!isLoading && <div className="text-sm">{formattedText}</div>}
      </div>
    </Cell>
  );
};

export default ReactionsCell;
