import { useEffect } from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { trainingEvidenceActions } from "../../../store/trainingEvidenceSlice";
import Modal from "../../Modal/Modal";
import {
  getTrainingEvidenceData,
  getTrainingsByCurriculumData,
} from "../../../store/trainingEvidenceActions";
import { useAppContext } from "../../../libs/contextLib";
import { reportEvidenceSuccessMessage } from "../../../utils/reportEvidenceMessages";

const TrainingEvidenceModal = ({ curriculumID }) => {
  const { isAuthenticated } = useAppContext();
  const dispatch = useDispatch();
  const { curriculumTrainings, selectedTraining } = useSelector(
    (state) => state.trainingEvidence
  );

  useEffect(() => {
    if (curriculumTrainings.length === 0) {
      dispatch(
        getTrainingsByCurriculumData({ auth: isAuthenticated, curriculumID })
      );
    }
  }, [curriculumID, curriculumTrainings.length, dispatch, isAuthenticated]);

  const closeModalHandler = () => {
    dispatch(trainingEvidenceActions.setModalVisibility({ isOpened: false }));
    dispatch(trainingEvidenceActions.setCurriculumTrainings({ trainings: [] }));
  };

  const selectHandler = (selection) => {
    dispatch(
      trainingEvidenceActions.selectTraining({ selectedTraining: selection })
    );
  };

  const submitHandler = () => {
    dispatch(
      getTrainingEvidenceData({
        auth: isAuthenticated,
        trainingID: selectedTraining.value,
      })
    );
    toast.success(reportEvidenceSuccessMessage);
  };

  return (
    <Modal
      show={true}
      title="Download Evidence"
      onSubmit={submitHandler}
      onClose={closeModalHandler}
      submitButtonType="success"
      submitButtonText="Download"
    >
      <h4 className="font-basis-bold mt-1 mb-1.5">Select Renewal Period</h4>

      <section
        className={`${curriculumTrainings.length > 2 ? "mb-13" : "mb-8"}`}
      >
        <Select
          defaultValue={selectedTraining}
          onChange={selectHandler}
          value={selectedTraining}
          isMulti={false}
          options={curriculumTrainings}
          classNamePrefix="select"
          maxMenuHeight={190}
          unstyled
        />
      </section>
    </Modal>
  );
};

export default TrainingEvidenceModal;
