import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import toast from "react-hot-toast";

const isLocal = process.env.NODE_ENV === "development";

export function initSentry() {
  if (isLocal) {
    return;
  }

  Sentry.init({
    dsn: "https://c8cf8743c9054f9a8e3ec03fd64990ec@o560667.ingest.sentry.io/5696411",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

export function logError(error, errorInfo = null) {
  if (isLocal) {
    return;
  }

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}

export function onError(error) {
  if (error.toString().endsWith("401")) {
    alert("Please log in again.");
    localStorage.removeItem("auth");
    localStorage.removeItem("apps_installations");
    localStorage.removeItem("google_token_id");
    localStorage.removeItem("login_token");
    window.location = "/login";
    return;
  }

  let errorInfo = {};
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    error = new Error(message);
    // custom API errors
  } else if (error.response?.data.detail) {
    message = error.response.data.detail;
    // API errors
  } else if (error.response?.data?.error && error.response?.data?.results) {
    message = (error.response.data.results || "").toString();
  } else if (error.response?.data?.errors) {
    try {
      message = error.response.data.errors.join(" ");
    } catch (error2) {
      message = JSON.stringify(error.response.data.errors);
    }
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }

  logError(error, errorInfo);

  toast.error(message);
}
