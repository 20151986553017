const thresholdOptions = [70, 80, 90, 100];

function GradedTrainingFormStep({
  gradingThreshold,
  setGradingThreshold,
  isThresholdCheked,
  setIsThresholdCheked,
  gradingThresholdDisabled,
}) {
  return (
    <>
      <h4 className='font-basis-bold'>
        Do you want this training to be graded?{' '}
        <span className='text-sm text-hka_gray'>(Optional)</span>
      </h4>
      <section className='mb-3'>
        <p className='mb-1 text-sm'>
          Your grading threshold is the percentage an employee must achieve in
          order to pass the training. If they do not reach this threshold, they
          will be required to retake the training.
        </p>
        <div className='flex items-center'>
          <label className='switch'>
            <input
              type='checkbox'
              disabled={gradingThresholdDisabled}
              name='has_weekly_quiz'
              checked={isThresholdCheked}
              onChange={() => setIsThresholdCheked((p) => !p)}
            />
            <span className='slider'></span>
          </label>
        </div>
      </section>

      {isThresholdCheked && (
        <section className='w-full mb-3'>
          <h4 className='mb-1 font-basis-bold'>Grading Threshold</h4>
          <select
            className=''
            value={gradingThreshold}
            onChange={(e) => setGradingThreshold(+e.target.value)}
          >
            <option value={0}>-- Select Grading Treshold --</option>
            {thresholdOptions.map((thresholdValue) => (
              <option
                key={thresholdValue}
                value={thresholdValue}
                title={thresholdValue}
              >
                {thresholdValue}%
              </option>
            ))}
          </select>
        </section>
      )}
    </>
  );
}

export default GradedTrainingFormStep;
