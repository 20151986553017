import { useCallback, useEffect, useState } from 'react';
import { onError } from '../../libs/errorLib';
import { getMergeGoups } from '../../api/integrationsApi';

const useFetchMergeGroups = ({ auth, integration, startLoading }) => {
  const [groups, setGroups] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(undefined);

  const doRequest = useCallback(
    (nextPage) => {
      return getMergeGoups({
        companyID: auth.company,
        integration,
        nextPage,
      });
    },
    [auth, integration]
  );

  useEffect(() => {
    const fetchMergeGroups = async () => {
      try {
        let nextPage = '';
        let response = null;

        do {
          try {
            response = await doRequest(nextPage);
            // eslint-disable-next-line no-loop-func
            setGroups((groups) => [...groups, ...response.groups]);
            nextPage = response.next_page;
          } catch (error) {
            const { status, reason } = error.response.data;
            setError({ status, reason });
          }
        } while (nextPage);

        setCompleted(true);
      } catch (e) {
        onError(e);
      }
    };
    if (startLoading && auth) fetchMergeGroups();
  }, [doRequest, auth, startLoading]);

  return { groups, completed, error };
};

export default useFetchMergeGroups;
