import { formatAvatar } from "../../utils/formatAvatar";

const TopPerformers = ({ topPerformers }) => {
  return (
    <section>
      <h4 className="mb-1 leading-4">Top Training Performers</h4>
      <ul className="border-t border-hka_gray-light">
        {topPerformers.map((user) => (
          <li
            key={user.employee.id}
            className="items-center border-b border-hka_gray-light"
          >
            <div className="flex items-center flex-1">
              {user.employee.avatar_image ? (
                <img
                  className="mr-1 user-img h-2.4 w-2.4"
                  src={user.employee.avatar_image}
                  alt={user.employee.name}
                />
              ) : (
                formatAvatar({
                  name: user.employee.name,
                  width: 2.4,
                  marginRight: 1,
                })
              )}
              <p className="text-hka_gray-dark">{user.employee.name}</p>
            </div>
            <div className="flex items-center mr-2">
              <span className="mr-1 icon-book"></span>
              <p className="w-3">{user.completed_trainings}</p>
            </div>
            <p className="w-8 h-3 leading-3 text-center rounded-md bg-hka_gray-light font-basis-bold">
              {user.completed_percentage} %
            </p>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default TopPerformers;
