import api from "../client";

export const getEmployeeEngagements = async ({
  employeeID,
  page = 1,
  pageSize = 10,
}) => {
  const params = {
    page,
    page_size: pageSize,
  };
  const { data } = await api.get(`/api/employees/${employeeID}/quizzes`, {
    params,
  });
  return data;
};
