import { useEffect, useState } from 'react';
import Modal from '../../Modal/Modal';
import Note from '../../Note';
import { useCreateExternalAppWithBasicAuth } from '../../../hooks/data-hooks/integrations/useCreateExternalAppWithBasicAuth';
import { useAppContext } from '../../../libs/contextLib';

const SeismicConfigurationModal = ({ onClose }) => {
  const { isAuthenticated: auth } = useAppContext();

  const [subdomain, setSubdomain] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [isSubmitValid, setIsSubmitValid] = useState(false);

  const {
    mutate: submitApiKey,
    isLoading: isMutating,
    isError,
  } = useCreateExternalAppWithBasicAuth({
    callbackFn: onClose,
  });

  useEffect(() => {
    setIsSubmitValid(
      subdomain?.length > 3 && apiKey?.length > 31 && !isMutating
    );
  }, [apiKey?.length, subdomain?.length, isMutating]);

  const submitHandler = () => {
    submitApiKey({
      companyID: auth.company,
      integration: 'seismic',
      settings: {
        subdomain,
        api_key: apiKey,
        resourcetype: 'SeismicInstallation',
      },
    });
  };

  return (
    <Modal
      show={true}
      title='Connect your Seismic platform to Haekka'
      onSubmit={submitHandler}
      onClose={onClose}
      submitButtonType='success'
      submitButtonText='Submit'
      submitValid={isSubmitValid}
      compact
    >
      <div className='w-full'>
        <Note type='info'>
          <p>
            In https://
            <span className='font-basis-bold'>haekka</span>.lessonly.com the
            subdomain is <span className='font-basis-bold'>haekka</span>.
          </p>
        </Note>
        <input
          type='text'
          className='mt-2 outline-none'
          placeholder='Enter subdomain'
          value={subdomain}
          onChange={(e) => setSubdomain(e.target.value)}
          autoFocus
        />
        <input
          type='text'
          className='mt-1 outline-none'
          placeholder='Enter API Key'
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
        />

        {isError && (
          <span className='note--danger text-sm text-gray-500 mt-2'>
            The credentials you provided are incorrect. Please provide the
            correct credentials.
          </span>
        )}

        <span className='note text-sm text-gray-500 mt-2'>
          Your sensitive information is secured in transit with SSL/TLS
          encryption, and further safeguarded through encryption prior to being
          stored in the database.
        </span>
      </div>
    </Modal>
  );
};

export default SeismicConfigurationModal;
