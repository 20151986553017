import React, { useMemo, useState } from "react";
import Modal from "../../Modal/Modal";
import { onError } from "../../../libs/errorLib";
import { whitelistPhishingTemplateEmailAddress } from "../../../api";
import { toast } from "react-hot-toast";
import LoaderInline from "../../LoaderInline";

export default function TemplateWhitelistModal({
  showModal,
  auth,
  template,
  onClose,
  triggerReload,
}) {
  const [loading, setLoading] = useState(false);
  const WHITELISTING_EMAILS_ARTICLE_URL =
    "https://support.google.com/a/answer/60752?hl=en";

  const formattedSenderEmailAddress = useMemo(() => {
    if (template.sender_email_address.includes("{COMPANY_DOMAIN}")) {
      const companyDomain = auth.email.split("@").pop();
      const [companyDomainPrefix] = companyDomain.split(".");
      return template.sender_email_address.replace(
        "{COMPANY_DOMAIN}",
        `${companyDomainPrefix}.acount-support.com`
      );
    } else {
      return template.sender_email_address;
    }
  }, [auth, template]);

  const onSubmit = async () => {
    try {
      await whitelistPhishingTemplateEmailAddress({
        auth,
        templateId: template.id,
      });
      toast.success("Template Whitelisted!");
      onClose();
      triggerReload();
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={showModal}
      onClose={onClose}
      onSubmit={onSubmit}
      title="Confirm Template Whitelist"
      submitButtonText="Confirm Whitelist"
      submitButtonType="primary"
    >
      {loading ? (
        <LoaderInline />
      ) : (
        <section className="space-y-1 sm:space-y-2">
          <div className="flex flex-col">
            <p>Sender Email Address:</p>
            <p className="font-basis-bold">{formattedSenderEmailAddress}</p>
          </div>
          <p>
            Please whitelist the above domain for your workspace. If you do not
            properly whitelist this domain, the phishing attack will not get
            sent.
          </p>
          <p>
            Read{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              href={WHITELISTING_EMAILS_ARTICLE_URL}
            >
              this article
            </a>{" "}
            if you are not sure how to whitelist a domain.
          </p>
        </section>
      )}
    </Modal>
  );
}
