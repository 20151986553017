import { useQuery } from "@tanstack/react-query";
import { checkDynamicDistributionForEngagement } from "../../../api/engagementsApi";

export const useCheckDynamicDistributionData = ({
  companyEngagementID,
  enabled,
}) => {
  return useQuery({
    queryKey: ["check-dynimic-distribution", companyEngagementID],
    queryFn: () =>
      checkDynamicDistributionForEngagement({ companyEngagementID }),
    enabled,
  });
};
