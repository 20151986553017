import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  Fragment,
} from 'react';
import {
  getPhishingCampaignDetails,
  getPhishingTemplates,
  editPhishingCampaign,
  getPhishingCampaignAttacks,
  removeEmployeeFromPhishingCampaign,
  launchPhishingAttack,
  deletePhishingCampaign,
  getEmployeesAssignedToPhishingCampaign,
  getPhishingWhitelistConfirmation,
} from '../api';
import {
  CAMPAIGN_STATUS_OPTIONS,
  SEND_OPTIONS,
  SEND_OPTION_CHOICES,
  getCampaignStatus,
} from '../utils/phishingUtils';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../libs/contextLib';
import { onError } from '../libs/errorLib';
import { format } from 'date-fns';
import { highlightExpanderMenuItem } from '../utils/expanderUtils';
import toast from 'react-hot-toast';
import PhishingCampaignTemplateCard from '../components/PhishingCampaignDetails/PhishingCampaignTemplateCard';
import TransitionWrapper from '../components/Base/TransitionWrapper';
import Loader from '../components/Loader';
import Back from '../components/Back';
import ReactTooltip from 'react-tooltip';
import Expander from '../components/Expander';
import AddTemplateModal from '../components/PhishingCampaignDetails/modals/AddTemplateModal';
import RemoveTemplateFromCampaignModal from '../components/PhishingCampaignDetails/modals/RemoveTemplateFromCampaignModal';
import ActivityMonitor from '../components/PhishingCampaignDetails/ActivityMonitor';
import AssignedEmployeesTable from '../components/PhishingCampaignDetails/AssignedEmployeesTable';
import RemoveEmployeeFromCampaignModal from '../components/Phishing/modals/RemoveEmployeeFromCampaignModal';
import AssignEmployeesModal from '../components/PhishingCampaignDetails/modals/AssignEmployeesModal';
import AssignEmployeesContext from '../store/assign-employeees-context';
import PhishingCampaignDetailsSearchAndFilteringOptions from '../components/PhishingCampaignDetails/PhishingCampaignDetailsSearchAndFilteringOptions';
import ActionButton from '../components/ActionButton';
import DeletePhishingCampaignModal from '../components/Phishing/modals/DeletePhishingCampaignModal';
import EditPhishingCampaignModal from '../components/Phishing/modals/EditPhishingCampaignModal';
import withPhishingCampaignTemplateDetails from '../utils/custom-hooks/withPhishingCampaignTemplateDetails';
import PhishingCampaignTemplateRow from '../components/PhishingCampaignDetails/PhishingCampaignTemplateRow';
import DropdownMenu from '../components/Base/DropdownMenu';
import PhishingCampaignStatusIndicator from '../components/Phishing/PhishingCampaignStatusIndicator';
import DownloadReportsModal from '../components/PhishingCampaignDetails/modals/DownloadReportsModal';
import Note from '../components/Note';
import Select from 'react-select';
import Slider from '../components/Base/Slider';
import TemplatePreviewModal from '../components/PhishingCampaignDetails/modals/TemplatePreviewModal';
import TemplateWhitelistModal from '../components/PhishingCampaignDetails/modals/TemplateWhitelistModal';

export default function PhishingCampaignDetails() {
  const VIEW_MODE_KEY = 'PHISHING_TEMPLATES_EXPANDER_VIEW_MODE';
  const SELECTED_TAB_KEY = 'PHISHING_DETAILS_SELECTED_TAB';

  const EXPANDER_VIEW_MODES = {
    TILE_MODE: 'TILE_MODE',
    LIST_MODE: 'LIST_MODE',
  };

  const EXPANDER_VIEW_MODE_STYLES = {
    [EXPANDER_VIEW_MODES.TILE_MODE]:
      'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-cards gap-1 grid--campaign-templates',
    [EXPANDER_VIEW_MODES.LIST_MODE]: 'space-y-0.5 sm:space-y-1',
  };

  const TABS = {
    ASSIGNED_EMPLOYEES: 'Assigned Employees',
    ACTIVITY_MONITOR: 'Activity Monitor',
  };

  const SORT_OPTIONS = [
    {
      value: 'created',
      label: 'Recorded Date (Ascending)',
    },
    {
      value: '-created',
      label: 'Recorded Date (Descending)',
    },
  ];

  const actions = [
    {
      label: 'Edit Campaign Name',
      icon: 'icon-cog',
      onClick: () => setShowEditCampaignModal(true),
    },
    {
      label: 'Delete Campaign',
      icon: 'icon-trash',
      onClick: () => setShowDeleteCampaignModal(true),
    },
  ];

  const ACTIVE_TAB_STYLES =
    'text-orange border-b pb-0.5 border-current -mb-0.1';
  const INACTIVE_TAB_STYLES = 'text-hka_gray';

  const navigate = useNavigate();

  const { isAuthenticated } = useAppContext();
  const { phishingCampaignId } = useParams();
  const { onImportUsers } = useContext(AssignEmployeesContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [triggerReload, setTriggerReload] = useState(false);
  const [phishingTemplatesViewMode, setPhishingTemplatesViewMode] = useState(
    localStorage.getItem(VIEW_MODE_KEY) || EXPANDER_VIEW_MODES.LIST_MODE
  );
  const [phishingCampaignDetails, setPhishingCampaignDetails] = useState(null);
  const [phishingCampaignAttacks, setPhishingCampaignAttacks] = useState(null);
  const [phishingTemplatesExpanded, setPhishingTemplatesExpanded] =
    useState(true);
  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem(SELECTED_TAB_KEY) || TABS.ASSIGNED_EMPLOYEES
  );
  const [templates, setTemplates] = useState([]);
  const [
    showRemoveTemplateFromCampaignModal,
    setShowRemoveTemplateFromCampaignModal,
  ] = useState(false);
  const [selectedTemplateForRemoval, setSelectedTemplateForRemoval] =
    useState(null);
  const [selectedEmployeeForRemoval, setSelectedEmployeeForRemoval] =
    useState(null);
  const [
    showRemoveEmployeeFromCampaignModal,
    setShowRemoveEmployeeFromCampaignModal,
  ] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteringOptions, setFilteringOptions] = useState(null);
  const [selectedFilteringOptions, setSelectedFilteringOptions] =
    useState(null);
  const [selectedSortOption, setSelectedSortOption] = useState(SORT_OPTIONS[1]);
  const [showAssignEmployeesModal, setShowAssignEmployeesModal] =
    useState(false);
  const [showDeleteCampaignModal, setShowDeleteCampaignModal] = useState(false);
  const [showEditCampaignModal, setShowEditCampaignModal] = useState(false);
  const [assignedEmployees, setAssignedEmployees] = useState(null);
  const [whitelistedTemplates, setWhitelistedTemplates] = useState([]);
  const [sendOption, setSendOption] = useState(null);
  const [skipWeekends, setSkipWeekends] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showDownloadReportsModal, setShowDownloadReportsModal] =
    useState(false);
  const [showTemplatePreviewModal, setShowTemplatePreviewModal] =
    useState(false);
  const [showTemplateWhitelistModal, setShowTemplateWhitelistModal] =
    useState(false);

  function renderPhishingTemplateDetails(template) {
    return withPhishingCampaignTemplateDetails(
      phishingTemplatesViewMode === EXPANDER_VIEW_MODES.TILE_MODE
        ? PhishingCampaignTemplateCard
        : PhishingCampaignTemplateRow
    )({
      template,
      lastAttack: templateToAttacksMap.get(template.id)[0],
      removable: phishingCampaignDetails.templates.length > 1,
      isWhitelisted: whitelistedTemplates.includes(template.id),
      setSelectedTemplateForRemoval: setSelectedTemplateForRemoval,
      setShowRemoveTemplateFromCampaignModal:
        setShowRemoveTemplateFromCampaignModal,
      onPreviewTemplateActionClick: (template) => {
        setSelectedTemplate(template);
        setShowTemplatePreviewModal(true);
      },
      onWhitelistTemplateActionClick: (template) => {
        setSelectedTemplate(template);
        setShowTemplateWhitelistModal(true);
      },
    });
  }

  function handleSetViewMode(viewMode) {
    localStorage.setItem(VIEW_MODE_KEY, viewMode);
    setPhishingTemplatesViewMode(viewMode);
  }

  function handleSetSelectedTab(tab) {
    localStorage.setItem(SELECTED_TAB_KEY, tab);
    setSelectedTab(tab);
  }

  async function handleOnRemoveTemplateModalSubmit() {
    try {
      await editPhishingCampaign({
        auth: isAuthenticated,
        campaignId: phishingCampaignDetails.id,
        payload: {
          templates: phishingCampaignDetails.templates
            .map(({ id }) => id)
            .filter((id) => id !== selectedTemplateForRemoval.id),
        },
      });

      setShowRemoveTemplateFromCampaignModal(false);

      setTriggerReload((v) => !v);

      toast.success('Template Removed Successfully!');
    } catch (error) {
      onError(error);
    }
  }

  async function handleOnRemoveEmployeeFromCampaign() {
    try {
      await removeEmployeeFromPhishingCampaign({
        auth: isAuthenticated,
        campaignId: phishingCampaignDetails.id,
        employeeId: selectedEmployeeForRemoval.id,
      });

      toast.success('Employee Removed From Campaign.');

      setTriggerReload((v) => !v);
    } catch (error) {
      onError(error);
    } finally {
      setShowRemoveEmployeeFromCampaignModal(false);
    }
  }

  async function handleOnPublishAttackButtonClick() {
    try {
      await launchPhishingAttack({
        auth: isAuthenticated,
        phishingCampaignId: phishingCampaignDetails.id,
      });

      toast.success('Attack launched!');

      setTriggerReload((v) => !v);
    } catch (error) {
      onError(error);
    }
  }

  async function onEditCampaignModalSubmit(payload) {
    try {
      setIsSubmitting(true);

      await editPhishingCampaign({
        payload,
        auth: isAuthenticated,
        campaignId: phishingCampaignDetails.id,
      });

      toast.success('Campaign edited successfully!');

      setIsSubmitting(false);

      setTriggerReload((v) => !v);
    } catch (error) {
      onError(error);
    } finally {
      setShowEditCampaignModal(false);
    }
  }

  async function onDeleteCampaignModalSubmit(campaign) {
    try {
      setIsSubmitting(true);

      await deletePhishingCampaign({
        campaignId: campaign.id,
        auth: isAuthenticated,
      });

      toast.success('Campaign deleted successfully!');

      setIsSubmitting(false);

      navigate('/phishing');
    } catch (error) {
      onError(error);
    } finally {
      setShowDeleteCampaignModal(false);
    }
  }

  async function onAddTemplateModalSubmit(templates) {
    try {
      setIsLoading(true);
      const templateIds = templates.map(({ id }) => id);

      await editPhishingCampaign({
        auth: isAuthenticated,
        campaignId: phishingCampaignDetails.id,
        payload: {
          templates: templateIds,
        },
      });

      toast.success('Template(s) added successfully!');
    } catch (error) {
      onError(error);
    } finally {
      setIsLoading(false);
      setShowAddTemplateModal(false);

      setTriggerReload((v) => !v);
    }
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const [campaignDetails, templates, campaignAttacks, assignedEmployees] =
          await Promise.all([
            getPhishingCampaignDetails({
              auth: isAuthenticated,
              phishingCampaignId,
            }),
            getPhishingTemplates({ auth: isAuthenticated }),
            getPhishingCampaignAttacks({
              auth: isAuthenticated,
              phishingCampaignId,
              params: {
                attacks:
                  selectedFilteringOptions?.map(({ value }) => value) || null,
                sort: selectedSortOption.value,
              },
            }),
            getEmployeesAssignedToPhishingCampaign({
              auth: isAuthenticated,
              campaignId: phishingCampaignId,
              page: 1,
              pageSize: 1,
            }),
          ]);

        setPhishingCampaignDetails(campaignDetails);
        setTemplates(templates.results);
        setPhishingCampaignAttacks(campaignAttacks);
        setAssignedEmployees(assignedEmployees);

        const whitelistedTemplateIds = [];

        if (campaignDetails) {
          for (let i = 0; i < campaignDetails.templates.length; i++) {
            const template = campaignDetails.templates[i];

            try {
              await getPhishingWhitelistConfirmation({
                auth: isAuthenticated,
                templateId: template.id,
              });
              whitelistedTemplateIds.push(template.id);
            } catch (error) {
              continue;
            }
          }
        }

        setWhitelistedTemplates(whitelistedTemplateIds);

        if (!filteringOptions) {
          setFilteringOptions(
            campaignAttacks.map((attack) => {
              const label = format(new Date(attack.created), 'd/M/Y');

              return {
                label,
                value: attack.id,
                icon: attack.template.icon,
              };
            })
          );
        }
      } catch (error) {
        onError(error);
        navigate('/phishing');
      } finally {
        setIsLoading(false);
      }
    };

    ReactTooltip.rebuild();
    loadData();
  }, [
    isAuthenticated,
    phishingCampaignId,
    triggerReload,
    navigate,
    selectedSortOption,
    selectedFilteringOptions,
    filteringOptions,
  ]);

  useEffect(() => {
    highlightExpanderMenuItem(phishingTemplatesViewMode);
  });

  useEffect(() => {
    highlightExpanderMenuItem(phishingTemplatesViewMode);
  }, [phishingTemplatesViewMode, phishingTemplatesExpanded]);

  useEffect(() => {
    setShowRemoveEmployeeFromCampaignModal(!!selectedEmployeeForRemoval);
  }, [selectedEmployeeForRemoval]);

  useEffect(() => {
    onImportUsers();
  }, [onImportUsers]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [phishingTemplatesViewMode]);

  useEffect(() => {
    if (!phishingCampaignDetails) {
      return;
    }

    const onUpdate = async () => {
      const payload = {
        skip_weekends: skipWeekends,
      };

      if (sendOption) {
        payload['send_option'] = sendOption;
      }

      if (
        payload.send_option === SEND_OPTIONS.ONCE_IMMEDIATELY &&
        phishingCampaignDetails.templates.length > 1
      ) {
        toast.error(
          "Ensure your campaign has only one template before changing it's send schedule to be sent only once immediately."
        );

        return;
      }

      try {
        await editPhishingCampaign({
          auth: isAuthenticated,
          campaignId: phishingCampaignDetails.id,
          payload,
        });

        toast.success('Campaign updated!');
      } catch (error) {
        toast.error('An error has occured.');
      } finally {
        setTriggerReload((v) => !v);
      }
    };

    onUpdate();
  }, [sendOption, skipWeekends]);

  const formattedLastSentDateTime = useMemo(() => {
    if (!phishingCampaignDetails?.last_sent_date) return null;

    const dateObject = new Date(phishingCampaignDetails.last_sent_date);

    return {
      date: format(dateObject, 'MMM. dd, yyyy'),
      time: format(dateObject, 'hh:mm a'),
    };
  }, [phishingCampaignDetails]);

  const campaignStatus = useMemo(() => {
    if (!phishingCampaignDetails) return null;

    return getCampaignStatus(phishingCampaignDetails);
  }, [phishingCampaignDetails]);

  const phishingTemplatesExpanderMenuItems = useMemo(() => {
    return [
      {
        icon: 'icon-list',
        value: EXPANDER_VIEW_MODES.LIST_MODE,
        label: 'List View',
        onClick: () => handleSetViewMode(EXPANDER_VIEW_MODES.LIST_MODE),
        active: phishingTemplatesViewMode === EXPANDER_VIEW_MODES.LIST_MODE,
      },
      {
        icon: 'icon-tile',
        value: EXPANDER_VIEW_MODES.TILE_MODE,
        label: 'Tile View',
        onClick: () => handleSetViewMode(EXPANDER_VIEW_MODES.TILE_MODE),
        active: phishingTemplatesViewMode === EXPANDER_VIEW_MODES.TILE_MODE,
      },
    ];
  }, [
    EXPANDER_VIEW_MODES.LIST_MODE,
    EXPANDER_VIEW_MODES.TILE_MODE,
    phishingTemplatesViewMode,
  ]);

  const actionButtonData = useMemo(() => {
    async function handleOnPauseOrResumeCampaignButtonClick(status) {
      try {
        await editPhishingCampaign({
          auth: isAuthenticated,
          campaignId: phishingCampaignDetails.id,
          payload: {
            active: status,
          },
        });

        toast.success(`Campaign ${status ? 'resumed' : 'paused'}!`);

        setTriggerReload((v) => !v);
      } catch (error) {
        onError(error);
      }
    }

    if (!campaignStatus) return null;

    if (
      phishingCampaignDetails?.send_option === SEND_OPTIONS.ONCE_IMMEDIATELY &&
      phishingCampaignDetails?.last_sent_date
    )
      return;

    const data = {
      title: '',
      onClick: () => {},
      disabled: false,
    };

    if (campaignStatus.value === CAMPAIGN_STATUS_OPTIONS.ACTIVE.value) {
      data.title = 'Pause Campaign';
      data.onClick = () => handleOnPauseOrResumeCampaignButtonClick(false);
    } else if (campaignStatus.value === CAMPAIGN_STATUS_OPTIONS.PAUSED.value) {
      data.title = 'Resume Campaign';
      data.onClick = () => handleOnPauseOrResumeCampaignButtonClick(true);
    } else {
      data.title = 'Publish Campaign';
      data.onClick = handleOnPublishAttackButtonClick;
    }

    return data;
  }, [
    campaignStatus,
    isAuthenticated,
    phishingCampaignDetails?.id,
    phishingCampaignDetails?.last_sent_date,
    phishingCampaignDetails?.send_option,
  ]);

  const templateToAttacksMap = useMemo(() => {
    if (!phishingCampaignDetails || !phishingCampaignAttacks) return null;

    const map = new Map();

    phishingCampaignDetails.templates.forEach((template) => {
      const attacks = phishingCampaignAttacks.filter(
        (attack) => attack.template.id === template.id
      );
      attacks.sort((a, b) => new Date(b.created) > new Date(a.created));
      map.set(template.id, attacks);
    });

    return map;
  }, [phishingCampaignDetails, phishingCampaignAttacks]);

  const showAddTemplatesAndAssignEmployeesNote = useMemo(() => {
    return (
      campaignStatus?.value === CAMPAIGN_STATUS_OPTIONS.UNPUBLISHED.value &&
      (!phishingCampaignDetails.send_option ||
        phishingCampaignDetails.templates.length === 0 ||
        assignedEmployees?.count === 0)
    );
  }, [campaignStatus, phishingCampaignDetails, assignedEmployees]);

  const showWhitelistTemplateDomainsNote = useMemo(() => {
    return (
      whitelistedTemplates.length < phishingCampaignDetails?.templates?.length
    );
  }, [whitelistedTemplates, phishingCampaignDetails]);

  const showManageTemplatesButton = useMemo(() => {
    return (
      phishingCampaignDetails?.send_option !== SEND_OPTIONS.ONCE_IMMEDIATELY ||
      campaignStatus?.value !== CAMPAIGN_STATUS_OPTIONS.ACTIVE.value
    );
  }, [phishingCampaignDetails?.send_option, campaignStatus?.value]);

  const PhishingCampaignDetailsTabs = () => (
    <div className='flex w-full space-x-2 border-b tabs border-hka_gray-light'>
      {Object.keys(TABS).map((key) => (
        <p
          key={key}
          onClick={() => handleSetSelectedTab(TABS[key])}
          className={`tab font-basis-medium cursor-pointer ${
            TABS[key] === selectedTab ? ACTIVE_TAB_STYLES : INACTIVE_TAB_STYLES
          }`}
        >
          {TABS[key]}
        </p>
      ))}
    </div>
  );

  return (
    <div>
      <TransitionWrapper show={isLoading}>
        <Loader fullscreen={true} />
      </TransitionWrapper>
      {!isLoading && (
        <Fragment>
          <section className='flex items-center justify-between mb-2'>
            <Back caption='Phishing' push='/phishing' />
            <div className='flex space-x-1'>
              {actionButtonData && (
                <ActionButton
                  type='primary'
                  onClick={actionButtonData.onClick}
                  disabled={
                    campaignStatus.value ===
                      CAMPAIGN_STATUS_OPTIONS.UNPUBLISHED.value &&
                    (assignedEmployees?.count === 0 ||
                      !phishingCampaignDetails.send_option ||
                      phishingCampaignDetails.templates.length === 0 ||
                      assignedEmployees?.count === 0)
                  }
                >
                  <span className='px-2 sm:px-0 font-basis-medium'>
                    {actionButtonData.title}
                  </span>
                </ActionButton>
              )}
              {campaignStatus.value !==
                CAMPAIGN_STATUS_OPTIONS.UNPUBLISHED.value && (
                <div data-tip='Download Reports'>
                  <ActionButton
                    type='secondary'
                    onClick={() => setShowDownloadReportsModal(true)}
                    customCss='px-0'
                  >
                    <span className='icon-download'></span>
                  </ActionButton>
                </div>
              )}
              <DropdownMenu actions={actions} />
            </div>
          </section>

          {/* Campaign Title, Last attack sent */}
          <section className='flex flex-col justify-between mb-2 space-y-2'>
            <div className='flex items-center space-x-1'>
              <h1 className='text-md md:text-xl'>
                {phishingCampaignDetails.name}
              </h1>
              <PhishingCampaignStatusIndicator
                campaign={phishingCampaignDetails}
              />
            </div>
            {formattedLastSentDateTime && (
              <div className='flex ml-0 lg:ml-0.5 items-center'>
                <span className='icon-calendar -ml-0.5 mr-0.8 text-hka_gray-dark'></span>
                <p>
                  Last Attack Sent on {formattedLastSentDateTime.date} at{' '}
                  {formattedLastSentDateTime.time}
                </p>
              </div>
            )}
            <div className='space-y-1'>
              {showAddTemplatesAndAssignEmployeesNote && (
                <Note type='info'>
                  <h4 className='text-base font-basis-bold text-hka_blue mb-0.5'>
                    Publish Campaign requirements not met.
                  </h4>
                  <span className='text-sm'>
                    Make sure you've selected a template, set the send schedule,
                    and assigned employees before publishing this campaign.
                  </span>
                </Note>
              )}
              {showWhitelistTemplateDomainsNote && (
                <Note type='warning'>
                  <h4 className='text-base font-basis-bold text-orange mb-0.5'>
                    Non-whitelisted template(s).
                  </h4>
                  <span className='text-sm'>
                    Whitelisting does not prevent you from publishing this
                    campaign, however we can't guarantee delivery of
                    non-whitelisted template domains.
                  </span>
                </Note>
              )}
            </div>
          </section>

          {/* Phishing Templates */}
          <section className='mb-2 space-y-1'>
            <Expander
              title='Phishing Template(s)'
              menuItems={phishingTemplatesExpanderMenuItems}
              actionButtons={
                phishingCampaignDetails.templates.length > 0 &&
                showManageTemplatesButton
                  ? [
                      {
                        text: 'Manage Template(s)',
                        onClick: () => setShowAddTemplateModal(true),
                        type: 'primary',
                      },
                    ]
                  : null
              }
              onExpanderToggle={() => setPhishingTemplatesExpanded((v) => !v)}
            >
              <div
                className={`mt-1 ${EXPANDER_VIEW_MODE_STYLES[phishingTemplatesViewMode]}`}
              >
                {phishingCampaignDetails.templates.map((template, i) => (
                  <Fragment key={i}>
                    {renderPhishingTemplateDetails(template)}
                  </Fragment>
                ))}
              </div>
              {phishingCampaignDetails.templates.length === 0 && (
                <div className='flex flex-col items-start justify-between w-full gap-2 p-2 border border-opacity-50 border-dashed rounded-lg md:flex-row sm:items-center border-hka_gray'>
                  <p className='flex-1'>
                    This campaign doesn't have any content yet! Click Add
                    Phishing Template(s) to get started.
                  </p>
                  <ActionButton onClick={() => setShowAddTemplateModal(true)}>
                    <span className='w-full px-2 sm:px-0 font-basis-medium'>
                      Select Phishing Template(s)
                    </span>
                  </ActionButton>
                </div>
              )}
            </Expander>
          </section>

          {/* Send Schedule */}
          <Expander title='Send Schedule'>
            <div className='flex flex-col items-start justify-between w-full gap-2 p-2 mt-1 space-y-1 border border-opacity-50 border-dashed rounded-lg sm:space-y-0 sm:flex-row md:flex-row sm:items-center border-hka_gray'>
              <div className='w-full mr-2 sm:w-30'>
                <Select
                  options={SEND_OPTION_CHOICES}
                  classNamePrefix='select'
                  placeholder='Select Send Schedule'
                  value={SEND_OPTION_CHOICES.find(
                    (option) =>
                      option.value === phishingCampaignDetails.send_option
                  )}
                  onChange={({ value }) => {
                    if (
                      value === SEND_OPTIONS.ONCE_IMMEDIATELY &&
                      phishingCampaignDetails.templates.length > 1
                    ) {
                      toast.error(
                        "Ensure your campaign has only one template before changing it's send schedule to be sent only once immediately."
                      );

                      return;
                    }
                    setSendOption(value);
                  }}
                  unstyled
                />
              </div>
              <Slider
                labelLeft
                disabled={
                  phishingCampaignDetails.send_option ===
                  SEND_OPTIONS.ONCE_IMMEDIATELY
                }
                label='Skip Weekends'
                checked={phishingCampaignDetails.skip_weekends}
                onChange={(e) => setSkipWeekends(e.target.checked)}
              />
            </div>
          </Expander>

          {/* Assigned Employees/Activity Monitor */}
          {assignedEmployees?.count > 0 ? (
            <section className='mb-2 space-y-1 sm:mb-3'>
              <PhishingCampaignDetailsTabs />
              <PhishingCampaignDetailsSearchAndFilteringOptions
                campaignSendOption={phishingCampaignDetails.send_option}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                filteringOptions={filteringOptions}
                sortOptions={SORT_OPTIONS}
                selectedFilteringOptions={selectedFilteringOptions}
                setSelectedFilteringOptions={setSelectedFilteringOptions}
                selectedSortingOption={selectedSortOption}
                setSelectedSortingOption={setSelectedSortOption}
                setShowAssignEmployeesModal={setShowAssignEmployeesModal}
              />
              {selectedTab === TABS.ASSIGNED_EMPLOYEES && (
                <AssignedEmployeesTable
                  auth={isAuthenticated}
                  campaign={phishingCampaignDetails}
                  attacks={phishingCampaignAttacks}
                  setSelectedEmployeeForRemoval={setSelectedEmployeeForRemoval}
                  searchQuery={searchQuery}
                  filteringOptions={selectedFilteringOptions}
                  sortOption={selectedSortOption}
                />
              )}
              {selectedTab === TABS.ACTIVITY_MONITOR && (
                <ActivityMonitor
                  auth={isAuthenticated}
                  campaign={phishingCampaignDetails}
                  searchQuery={searchQuery}
                  filteringOptions={selectedFilteringOptions}
                  sortOption={selectedSortOption}
                />
              )}
            </section>
          ) : (
            <section className='mb-2 space-y-1 sm:mb-3'>
              <Expander title='Assigned Employees'>
                <div className='flex flex-col items-start justify-between w-full gap-2 p-2 border border-opacity-50 border-dashed rounded-lg md:flex-row sm:items-center border-hka_gray'>
                  <p className='flex-1'>
                    This campaign doesn't have any assigned users yet! Click the
                    Assign Employees button to get start adding users.
                  </p>

                  <ActionButton
                    type='primary'
                    onClick={() => setShowAssignEmployeesModal(true)}
                  >
                    <span className='px-2 font-basis-medium sm:px-0'>
                      Assign Employees
                    </span>
                  </ActionButton>
                </div>
              </Expander>
            </section>
          )}
          {/* Modals */}
          <AddTemplateModal
            auth={isAuthenticated}
            campaign={phishingCampaignDetails}
            showModal={showAddTemplateModal}
            onClose={() => setShowAddTemplateModal(false)}
            onSubmit={onAddTemplateModalSubmit}
          />
          <AssignEmployeesModal
            showModal={showAssignEmployeesModal}
            campaign={phishingCampaignDetails}
            setTriggerReload={setTriggerReload}
            onClose={() => setShowAssignEmployeesModal(false)}
          />
          {phishingCampaignDetails.templates.length > 1 &&
            selectedTemplateForRemoval && (
              <RemoveTemplateFromCampaignModal
                showModal={showRemoveTemplateFromCampaignModal}
                template={selectedTemplateForRemoval}
                onSubmit={handleOnRemoveTemplateModalSubmit}
                onClose={() => setShowRemoveTemplateFromCampaignModal(false)}
              />
            )}
          {selectedEmployeeForRemoval && (
            <RemoveEmployeeFromCampaignModal
              show={showRemoveEmployeeFromCampaignModal}
              employee={selectedEmployeeForRemoval}
              onRemove={handleOnRemoveEmployeeFromCampaign}
              onClose={() => setShowRemoveEmployeeFromCampaignModal(false)}
            />
          )}
          <DeletePhishingCampaignModal
            isSubmitting={isSubmitting}
            campaign={phishingCampaignDetails}
            showModal={showDeleteCampaignModal}
            onDeletePhishingCampaign={onDeleteCampaignModalSubmit}
            onClose={() => setShowDeleteCampaignModal(false)}
          />
          <EditPhishingCampaignModal
            isSubmitting={isSubmitting}
            showModal={showEditCampaignModal}
            campaign={phishingCampaignDetails}
            templates={templates}
            onEditCampaign={onEditCampaignModalSubmit}
            onClose={() => {
              setShowEditCampaignModal(false);
            }}
          />
          <DownloadReportsModal
            auth={isAuthenticated}
            showModal={showDownloadReportsModal}
            setShowModal={setShowDownloadReportsModal}
            attacks={phishingCampaignAttacks}
          />
          {selectedTemplate && (
            <Fragment>
              <TemplatePreviewModal
                showModal={showTemplatePreviewModal}
                template={selectedTemplate}
                onClose={() => {
                  setShowTemplatePreviewModal(false);
                }}
              />
              <TemplateWhitelistModal
                auth={isAuthenticated}
                showModal={showTemplateWhitelistModal}
                onClose={() => setShowTemplateWhitelistModal(false)}
                template={selectedTemplate}
                triggerReload={() => setTriggerReload((v) => !v)}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
}
