import React, { useEffect, useState } from "react";
import LoaderInline from "../../LoaderInline";
import Modal from "../../Modal/Modal";
import CampaignDetailsModalFragment from "./fragments/CampaignDetailsModalFragment";

export default function EditPhishingCampaignModal({
  showModal,
  campaign,
  onEditCampaign,
  onClose,
  isSubmitting,
}) {
  const [allowSubmission, setAllowSubmission] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    setName(campaign.name);
  }, [campaign]);

  useEffect(() => {
    setAllowSubmission(!!name);
  }, [name]);

  const resetState = () => {
    setAllowSubmission(false);
    setName("");
  };

  const onSubmit = () => {
    const payload = {
      name,
    };

    onEditCampaign(payload);
    resetState();
  };

  const handleOnClose = () => {
    resetState();
    onClose();
  };

  return (
    <Modal
      expandable
      show={showModal}
      onClose={handleOnClose}
      onSubmit={onSubmit}
      submitValid={allowSubmission}
      title={`Edit Campaign Name`}
      submitButtonText="Save Changes"
      modalClass="modal--edit-phishing-campaign"
    >
      <section>
        {isSubmitting ? (
          <LoaderInline />
        ) : (
          <CampaignDetailsModalFragment name={name} setName={setName} />
        )}
      </section>
    </Modal>
  );
}
