import { useState } from 'react';
import * as api from '../../api';
import { useAppContext } from '../../libs/contextLib';
import { onError } from '../../libs/errorLib';
import ActionButton from '../ActionButton';
import CsvValidatedResult from './CsvValidatedResult';
import Note from '../Note';

const CsvOption = ({ assignEmployeesContext }) => {
  const { isAuthenticated } = useAppContext();
  const [progressTracker, setProgressTracker] = useState(undefined);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  const [file, setFile] = useState(undefined);

  const handleFileChange = (event) => {
    event.stopPropagation(); // trying to prevent the weird bug reproducable by Samsara only
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setSubmitBtnDisabled(false);
    }
  };

  const submitHandler = async () => {
    try {
      setSubmitBtnDisabled(true);
      const formData = new FormData();
      formData.append('file', file, file.name);
      const { progress_tracker } = await api.validateEmployeesInCSVFile({
        auth: isAuthenticated,
        formData,
      });
      setProgressTracker(progress_tracker);
    } catch (error) {
      onError(error);
    }
  };

  return (
    <section className='mt-2'>
      <h4 className='mb-0.5 font-basis-bold'>
        Add users from CSV file{' '}
        <span className='text-sm text-hka_gray'>(Optional)</span>
      </h4>
      <p className='mb-1 text-sm'>
        Only include the user's email, and header labeled "EMAIL" (all caps) in
        the CSV file. All other information will get pulled directly from Slack.
      </p>

      <Note>
        <p className='font-basis-medium'>
          Employee emails within the CSV must correspond to their Slack account
          email, otherwise they will not be included.
        </p>
      </Note>
      <div className='flex items-center p-1 pl-2 mt-1 mb-1 space-x-1 border border-dashed rounded-lg border-hka_gray-border'>
        <div className='flex flex-col justify-center flex-1'>
          <label htmlFor='scv_upload' className='ml-0'>
            <p className='flex-1 leading-4 cursor-pointer link font-basis-medium'>
              {file ? `${file.name}` : 'Select a CSV file'}
            </p>
          </label>
          <input
            id='scv_upload'
            className='block'
            type='file'
            onChange={handleFileChange}
            accept='.csv'
          />
        </div>
        <ActionButton
          type='defaultNoIcon'
          onClick={submitHandler}
          disabled={!file || submitBtnDisabled}
        >
          Upload
        </ActionButton>
      </div>

      {progressTracker && (
        <CsvValidatedResult
          assignEmployeesContext={assignEmployeesContext}
          progressTracker={progressTracker}
          resetFile={() => {
            setProgressTracker(undefined);
            setFile(undefined);
          }}
        />
      )}
    </section>
  );
};

export default CsvOption;
