import { useState } from "react";
import Modal from "../Modal/Modal";
import Tag from "../Tag";

const EditTrainingTagModal = ({
  showModal,
  onClose,
  onSetSelectedTag,
  tag,
  handleTrainingTagUpdate,
}) => {
  const [title, setTitle] = useState(tag.name);
  return (
    <Modal
      show={showModal}
      title="Edit Training Tag"
      submitButtonText="Yes, Rename Tag"
      submitButtonType="danger"
      onSubmit={() => handleTrainingTagUpdate(title)}
      onClose={() => {
        onClose();
        onSetSelectedTag();
      }}
      submitValid={tag.name !== title}
    >
      <section className="mb-2">
        <h4 className="mb-1 font-basis-bold">New Training Tag name</h4>
        <input
          type="text"
          name="days_till_due"
          value={title}
          className={`w-full h-4 pl-1 pr-1 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray  border-hka_gray-lights hka-input mb-1`}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </section>
      <div className="flex items-center">
        <p className="mb-1 mr-1">Selected Tag:</p>
        <Tag tag={tag.name} onRemove={() => {}} noRemoveIcon />
      </div>
    </Modal>
  );
};

export default EditTrainingTagModal;
