import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUserAccountsSettings } from '../../../api/settings/companySettingsApi';

export const useUpdateUserAccountsSettings = ({ companyID }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ updateData }) =>
      updateUserAccountsSettings({ companyID, updateData }),
    onSuccess: () =>
      queryClient.invalidateQueries(['user-accounts-settings', companyID]),
  });
};
