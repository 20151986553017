import { useState } from "react";
import PropTypes from "prop-types";

const Callout = ({
  title,
  message,
  permanent = false,
  date = true,
  children,
  onClose,
}) => {
  const [show, setShow] = useState(true);

  return (
    show && (
      <div className="relative flex justify-center w-full mb-3 callout">
        {date && (
          <p className="px-1.5 absolute -top-1.5 leading-3 bg-white border rounded-full border-hka_gray-light callout--date">
            {new Date().toLocaleString("en-US", {
              month: "long",
              day: "2-digit",
              year: "numeric",
            })}
          </p>
        )}
        <div className="flex w-full p-2 bg-white shadow-light rounded-xl callout--content">
          <img
            className="w-4 h-4 mr-1 rounded-md callout--icon"
            src="https://haekka-author-icons.s3.us-west-2.amazonaws.com/HKA--UPDATED--Author_Haekka.png"
            alt="https://haekka-author-icons.s3.us-west-2.amazonaws.com/HKA--UPDATED--Author_Haekka.png"
          />
          <div className="flex flex-col flex-grow callout--copy">
            <h4>{title}</h4>
            <p>{message}</p>
            {children && (
              <div className="flex flex-row mt-2 callout-actions">
                {children}
              </div>
            )}
          </div>
          {!permanent && (
            <span
              className="items-center h-full cursor-pointer icon-close text-hka_gray"
              onClick={onClose ? onClose : () => setShow(false)}
            ></span>
          )}
        </div>
      </div>
    )
  );
};

Callout.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.node.isRequired,
  permanent: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Callout;
