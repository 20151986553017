import IMAPSettings from "./IMAPSettings";
import LoaderInline from "../../../LoaderInline";
import { PhishingSettingsContext } from "../../../../contexts/PhishingSettingsContext";
import validator from "validator";

import { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { onError } from "../../../../libs/errorLib";
import {
  getCompanyPhishingSettings,
  updateCompanyPhishingSettings,
} from "../../../../api";

const PhishingSettings = ({ auth }) => {
  // Context state fields
  const [imapConfigurationTestResponses, setIMAPConfigurationTestResponses] =
    useState([]);

  // Component state fields
  const [reload, setReload] = useState(false);
  const [settings, setSettings] = useState(null);
  const [forwardingEmails, setForwardingEmails] = useState("");
  useState(false);
  const [
    phishingEmailForwardedNotificationEmails,
    setPhishingEmailForwardedNotificationEmails,
  ] = useState("");

  useEffect(() => {
    const onLoad = async () => {
      try {
        const settings = await getCompanyPhishingSettings({ auth });
        setSettings(settings);
        setForwardingEmails(settings.forwarding_emails || "");
        setPhishingEmailForwardedNotificationEmails(
          settings.phishing_email_forwarded_notification_emails || ""
        );
      } catch (error) {
        onError(error);
      }
    };

    onLoad();
  }, [auth, reload]);

  const validateEmails = (emails) => {
    if (emails[emails.length - 1] === "") {
      emails.pop();
    }

    if (
      !emails ||
      emails.length === 0 ||
      (emails.length === 1 && emails[0] === "")
    ) {
      throw new Error("Please provide at least one email address.");
    }

    emails.forEach((email) => {
      if (!validator.isEmail(email.trim())) {
        throw new Error(`${email} is not a valid email address.`);
      }
    });
  };

  const onSubmit = async () => {
    try {
      validateEmails([
        ...forwardingEmails,
        ...phishingEmailForwardedNotificationEmails,
      ]);

      const payload = {
        forwardingEmails,
        phishingEmailForwardedNotificationEmails,
      };

      const updatedSettings = await updateCompanyPhishingSettings({
        auth,
        payload,
      });

      setSettings(updatedSettings);
      setForwardingEmails(updatedSettings.forwarding_emails || "");
      setPhishingEmailForwardedNotificationEmails(
        updatedSettings.phishing_email_forwarded_notification_emails || ""
      );

      toast.success("Phishing settings updated successfully!");

      setReload((v) => !v);
    } catch (error) {
      onError(error);
    }
  };

  return (
    <PhishingSettingsContext.Provider
      value={{
        imapConfigurationTestResponses,
        setIMAPConfigurationTestResponses,
      }}
    >
      <Fragment>
        <h1 className="mt-1 mb-2 font-basis-bold col-span-full">
          Phishing Settings
        </h1>

        {settings ? (
          <Fragment>
            <section className="flex flex-col items-center p-2 mb-4 space-y-2 bg-white col-span-full shadow-light rounded-xl">
              <div className="w-full">
                <h3 className="setting__title mb-0.5">
                  Reporting Non-campaign Emails
                </h3>{" "}
                <p className="mb-1 text-sm">
                  Forward reported{" "}
                  <span className="font-basis-bold">non-campaign</span> emails
                  to the following address. Use commas to separate addresses
                  (press enter to save).
                </p>
                <input
                  type="text"
                  className="input--non-campaign-emails"
                  placeholder="Set forwarding email(s)"
                  value={forwardingEmails}
                  onChange={(e) =>
                    setForwardingEmails(
                      !e.target.value.includes(",")
                        ? [e.target.value]
                        : e.target.value.split(",").map((email) => email.trim())
                    )
                  }
                  onKeyDown={(e) => e.key === "Enter" && onSubmit()}
                />
              </div>
              <div className="w-full">
                <h3 className="setting__title mb-0.5">
                  Campaign Email Forwarding Notification
                </h3>{" "}
                <p className="mb-1 text-sm">
                  Notify the below addresses when a phishing email gets
                  forwarded. Use commas to separate addresses (press enter to
                  save).
                </p>
                <input
                  type="text"
                  className="input--email-forwarding"
                  placeholder="Add addresses"
                  value={phishingEmailForwardedNotificationEmails}
                  onChange={(e) =>
                    setPhishingEmailForwardedNotificationEmails(
                      !e.target.value.includes(",")
                        ? [e.target.value]
                        : e.target.value.split(",").map((email) => email.trim())
                    )
                  }
                  onKeyDown={(e) => e.key === "Enter" && onSubmit()}
                />
              </div>
            </section>
            <IMAPSettings
              auth={auth}
              phishingCompanySettings={settings}
              phishingSettingsReload={reload}
            />
          </Fragment>
        ) : (
          <LoaderInline />
        )}
      </Fragment>
    </PhishingSettingsContext.Provider>
  );
};

export default PhishingSettings;
