import { useQuery } from '@tanstack/react-query';
import { getTrainingStatistics } from '../../../api/trainingsApi';

export const useTrainingStatistics = ({ companyID, trainingID }) => {
  return useQuery({
    queryKey: ['training-statistics', companyID, trainingID],
    queryFn: () =>
      getTrainingStatistics({
        companyID,
        trainingID,
      }),
  });
};
