import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, forwardRef } from 'react';

const DropdownMenu = forwardRef(
  ({ actions, btnIcon = 'icon-menu-2', btnBg = true }, ref) => {
    return (
      <Menu as='div' className='menu'>
        <Menu.Button
          ref={ref}
          className={`${btnBg ? 'menu__button' : undefined} ${btnIcon}`}
        ></Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='menu__items'>
            {actions.map((action, i) => {
              if (action.show === false) return <span key={i}></span>;
              return (
                <div
                  key={i}
                  className='transition-all cursor-pointer hover:bg-hka_blue-light'
                >
                  <Menu.Item className='menu__item'>
                    <div onClick={action.onClick} disabled={action.disabled}>
                      <span
                        className={`${action.icon} ${
                          action.disabled ? '' : ''
                        }`}
                      ></span>
                      <p
                        className={` ${
                          action.disabled ? 'text-gray-300' : 'text-hka_gray'
                        }`}
                      >
                        {action.label}
                      </p>
                    </div>
                  </Menu.Item>
                </div>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }
);

export default DropdownMenu;
