import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import App from "./App";
import { initSentry } from "./libs/errorLib";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/index";
import { Toaster } from "react-hot-toast";

initSentry();

ReactDOM.render(
  <Toaster
    position="top-center"
    reverseOrder={false}
    // containerStyle={{}}
    toastOptions={{
      className: "toast",
      duration: 5000,
      style: {
        fontSize: "1.5rem",
        minWidth: "200px",
        padding: "1rem",
      },
      success: {
        className: "toast_success",
        style: { color: "#20ac36" },
      },
      error: {
        className: "toast__error",
        style: { color: "#e42323" },
      },
    }}
  />,
  document.getElementById("toaster-container")
);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
