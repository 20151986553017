import React from "react";
import { SETTINGS_VIEWS } from "../../containers/Settings";

export default function SettingsNavigationBar({
  isAdmin,
  selectedView,
  setSelectedView,
}) {
  return (
    <nav className="sm:flex lg:w-auto sm:flex-col sm:block md:pb-0 md:mb-0 md:col-span-3 xl:col-span-3 2xl:col-span-2">
      <h2 className="mb-2 font-basis-bold">Settings</h2>
      {Object.values(SETTINGS_VIEWS)
        .filter((value) =>
          isAdmin ? true : value !== "slack" && value !== "integrations"
        )
        .map((view) => (
          <span
            key={view}
            className={`cursor-pointer text-sm leading-3.6 px-1 rounded-md font-basis-bold capitalize 
          ${selectedView === view ? "text-orange bg-orange-light" : ""}`}
            onClick={() => setSelectedView(view)}
          >
            {view}
          </span>
        ))}
    </nav>
  );
}
