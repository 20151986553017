import * as api from "../api";

const sendEventToSlackInternal = async (eventName, properties) => {
  const isAuthenticated = JSON.parse(localStorage.getItem("auth"));
  if (!isAuthenticated) return;

  const text = `\`${eventName}\` event from ${
    properties.hasOwnProperty("Admin") ? "admin" : "user"
  } \`${properties.Admin}\``;
  const blocks = [
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text,
      },
    },
    {
      type: "section",
      fields: Object.keys(properties).map((key) => ({
        type: "mrkdwn",
        text: `*${key}*: ${properties[key]}`,
      })),
    },
  ];

  blocks.push({
    type: "context",
    elements: [
      {
        type: "mrkdwn",
        text: "To unsubscribe from certain types of events or add new ones <https://github.com/Haekka/haekka-slack-app/blob/develop/src/analytics/index.ts|modify> events for notifying array.",
      },
    ],
  });

  try {
    await api.logInternalEvent({
      auth: isAuthenticated,
      message: JSON.stringify({
        text,
        blocks,
      }),
    });
  } catch (error) {
    console.log("Logging event to the internal Slack channel failed: ", error);
  }
};

export default sendEventToSlackInternal;
