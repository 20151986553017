import { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { format } from 'date-fns';

import { Table, Column, ColumnGroup } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';
import './table.css';

import {
  useCompanyEngagementData,
  useEmployeeEngagementAnynomousResultsData,
  useEngagementSchedulesData,
} from '../../../hooks/data-hooks/engagements';

import {
  composeTitle,
  transformEmployeesAnonimousResults,
} from '../../../utils/engagementsUtils';

import Pagination from '../../Pagination';
import Loader from '../../Loader';
import SortableHeaderCell from './SortableHeaderCell/SortableHeaderCell';
import AnswerCell from './AnswerCell';
import PreviewMessageModal from './PreviewMessageModal';
import { shuffleArray } from '../../../utils/commonUtils';

const dateFormat = 'MMM d, yyyy';

const EmployeeAnonymousResultsTable = () => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [employeeAnonymousResults, setEmployeeAnonymousResults] = useState([]);

  const [globalMessages, setGlobalMessages] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(undefined);

  const [previewMessageModal, setPreviewMessageModal] = useState(undefined);

  const { data: companyEngagement, isLoading: isLoadingEngagments } =
    useCompanyEngagementData({
      id,
    });

  const { data: anonymousResultsData, isLoading: isLoadingResults } =
    useEmployeeEngagementAnynomousResultsData({
      currentPage,
      companyEngagementID: id,
      schedule: selectedSchedule?.value,
    });

  const { data: schedules } = useEngagementSchedulesData({
    companyEngagementID: id,
    transformData: false,
  });

  useEffect(() => {
    const globMessages = companyEngagement.quiz.questions;
    if (anonymousResultsData) {
      setGlobalMessages(
        globMessages.map((m) => ({ title: composeTitle(m), id: m.id }))
      );
      const transformedAnonymousData = transformEmployeesAnonimousResults({
        data: anonymousResultsData,
        questions: globMessages,
      });
      setEmployeeAnonymousResults(shuffleArray(transformedAnonymousData));
    }
  }, [companyEngagement, anonymousResultsData]);

  const mappedSchedules = () => {
    if (schedules) {
      return schedules.map((schedule) => ({
        value: schedule.id,
        label: format(new Date(schedule.send_time), dateFormat),
      }));
    }
    return [];
  };

  const mockSchedule = () => {
    let mock = undefined;
    if (schedules.length > 1) {
      mock = schedules
        .sort((a, b) => new Date(a.created) - new Date(b.created))
        .filter((s) => s.is_sent)[0];
    } else {
      mock = schedules[0];
    }
    return {
      value: mock.id,
      label: format(new Date(mock.send_time), dateFormat),
    };
  };

  if (isLoadingEngagments || isLoadingResults) return <Loader />;

  return (
    <Fragment>
      <div className='justify-end mb-1 space-y-1 sm:flex sm:space-y-0'>
        <div className='w-full sm:ml-1 sm:w-24'>
          <Select
            value={selectedSchedule ?? mockSchedule()}
            options={mappedSchedules()}
            onChange={(schedule) => setSelectedSchedule(schedule)}
            placeholder={'Select Period'}
            classNamePrefix='select'
            unstyled
          />
        </div>
      </div>

      <Table
        classPrefix='rs-table'
        cellBordered
        height={400}
        autoHeight
        affixHeader
        affixHorizontalScrollbar
        headerHeight={60}
        data={employeeAnonymousResults}
        wordWrap='break-word'
        loading={isLoadingEngagments || isLoadingResults}
      >
        <ColumnGroup
          header={
            selectedSchedule?.label ??
            format(new Date(schedules[0].send_time), dateFormat)
          }
          align='left'
          verticalAlign='middle'
          groupHeaderHeight={30}
        >
          {globalMessages.map((item, i) => (
            <Column
              width={200}
              flexGrow={i === globalMessages.length - 1 ? 1 : undefined}
              minWidth={i === globalMessages.length - 1 ? 200 : undefined}
              key={item.id}
            >
              <SortableHeaderCell>
                <div
                  onClick={() => setPreviewMessageModal({ message: item.id })}
                  className='text-sm line-clamp-1 link font-basis-medium'
                >
                  {item.title}
                </div>
              </SortableHeaderCell>
              <AnswerCell dataKey={item.id} />
            </Column>
          ))}
        </ColumnGroup>
      </Table>

      <div className='mt-1'>
        <Pagination
          pageSize={15}
          count={anonymousResultsData?.count}
          currentPage={currentPage}
          onPageChanged={(page) => setCurrentPage(page)}
        />
      </div>

      {!!previewMessageModal && (
        <PreviewMessageModal
          showModal={!!previewMessageModal}
          onClose={() => setPreviewMessageModal(undefined)}
          message={companyEngagement.quiz.questions.find(
            (q) => q.id === previewMessageModal?.message
          )}
        />
      )}
    </Fragment>
  );
};

export default EmployeeAnonymousResultsTable;
