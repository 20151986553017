import { format } from "date-fns";
import React, { Fragment, useEffect, useState } from "react";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import * as api from "../../api";
import ManualUsersSelect from "../AssignEmployees/ManualUsersSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag, faUsers } from "@fortawesome/free-solid-svg-icons";
import { types } from "../../utils/groupModalUtils";

export default function AssignEmployeesToGroup({
  defaultOptions = [],
  employees,
  channels,
  slackUserGroups,
  onChange,
}) {
  const formatOptionLabel = ({ value, label, avatar, type }) => (
    <div className="flex flex-row items-center">
      <div>
        {type === types.EMPLOYEE && (
          <div>
            {avatar && (
              <img
                className="w-2.4 h-2.4 user-img rounded-md mr-0.5"
                src={avatar}
                alt={value}
              />
            )}
          </div>
        )}
        {type === types.CHANNEL && (
          <span className="w-2.4 h-2.4 flex items-center justify-center mr-0.5">
            <FontAwesomeIcon
              className="items-center justify-center border-none select__channel-icon"
              icon={faHashtag}
            />
          </span>
        )}
        {type === types.SLACK_USER_GROUP && (
          <span className="w-2.4 h-2.4 flex items-center justify-center mr-0.5">
            <FontAwesomeIcon
              className="items-center justify-center border-none select__channel-icon"
              icon={faUsers}
            />
          </span>
        )}
      </div>
      <span className="self-center text-hka_gray">
        {label} {type === types.SLACK_USER_GROUP && "(Group)"}
      </span>
    </div>
  );

  const options = [
    ...employees.map((e) => ({ ...e, type: types.EMPLOYEE })),
    ...channels.map((c) => ({ ...c, type: types.CHANNEL })),
    ...slackUserGroups.map((g) => ({ ...g, type: types.SLACK_USER_GROUP })),
  ];

  const { isAuthenticated } = useAppContext();

  const [syncInProgress, setSyncInProgress] = useState(false);
  const [lastSync, setLastSync] = useState(undefined);
  const [lastSlackSync, setLastSlackSync] = useState(undefined);

  useEffect(() => {
    const getLastSyncTime = () => {
      return api.checkLastEmployeesSync({
        auth: isAuthenticated,
      });
    };

    async function onLoad() {
      try {
        const response = await getLastSyncTime();
        setLastSync(response);
        if (response.last_slack_sync) {
          const last_slack_sync = new Date(response.last_slack_sync);
          setLastSlackSync(
            `${format(last_slack_sync, "h:mm b")} on ${format(
              last_slack_sync,
              "eeee MMMM do yyyy"
            )}`
          );
        }
      } catch (e) {
        console.error(e);
        onError(e);
      }
    }
    onLoad();
  }, [isAuthenticated]);

  const onSyncEmployees = async () => {
    await api.syncEmployees({
      auth: isAuthenticated,
    });
    setSyncInProgress(true);
  };

  return (
    <Fragment>
      {lastSync && (
        <div className="flex flex-col items-center mb-2 sm:flex-row sm:space-x-3">
          <p className="flex-1 mb-1 md:mb-0">
            Don't see the user(s) you're looking for? Your account{" "}
            {lastSlackSync ? "was last synced on" : "hasn't been synced"}{" "}
            <span className="font-basis-bold">
              {lastSlackSync ? `at ${lastSlackSync}` : ""}
            </span>
            .
          </p>
          <button
            className="w-full px-2 sm:w-auto button button--blue bg-hka_blue-light"
            type="button"
            onClick={onSyncEmployees}
            disabled={syncInProgress}
          >
            <span>Sync Accounts</span>
          </button>
        </div>
      )}
      {syncInProgress && (
        <p className="px-1 py-0.5 border rounded-lg bg-hka_gray-light border-hka_gray-border border-opacity-20 mb-2">
          We're syncing your accounts now. We'll send you a Slack notification
          shortly. Keep an eye out for it!
        </p>
      )}
      <h4 className="mb-1 font-basis-bold">
        Assign Employees <span className="text-hka_gray">(Optional)</span>
      </h4>
      <ManualUsersSelect
        renderSelectedValues={false}
        defaultOptions={defaultOptions}
        formatOptionLabel={formatOptionLabel}
        isMulti={true}
        labelText="Select Employees, Channels or Slack User Groups"
        options={options}
        onChange={onChange}
      />
    </Fragment>
  );
}
