import DropdownMenu from '../../Base/DropdownMenu';

const StreamActionsMenu = ({
  companySubscribed,
  globalContentMaker,
  openSubscribeUsersModalHandler,
  editStreamHandler,
  deleteStreamModalHandler,
  unsubscribeFromStreamHandler,
  manageStreamOwnersHandler,
}) => {
  const dropMenuActions = [];

  if (companySubscribed) {
    dropMenuActions.push({
      label: 'Manage Users',
      icon: 'icon-users',
      onClick: openSubscribeUsersModalHandler,
    });
  }

  if (globalContentMaker) {
    dropMenuActions.push({
      label: 'Edit Stream',
      icon: 'icon-cog',
      onClick: editStreamHandler,
    });

    dropMenuActions.push({
      label: 'Delete Stream',
      icon: 'icon-trash',
      onClick: deleteStreamModalHandler,
    });
  }

  if (!globalContentMaker && companySubscribed) {
    dropMenuActions.push({
      label: 'Manage Owners',
      icon: 'icon-bell',
      onClick: manageStreamOwnersHandler,
    });

    dropMenuActions.push({
      label: 'Unsubscribe',
      icon: 'icon-close',
      onClick: unsubscribeFromStreamHandler,
    });
  }

  return <DropdownMenu actions={dropMenuActions} />;
};

export default StreamActionsMenu;
