import { useQuery } from "@tanstack/react-query";
import { slackApi } from "../../../api/slackApi";

export const useChannelEngagementReactionsData = ({
  channel,
  timestamp,
  enabled = true,
}) => {
  const apiMethod = "reactions_get";
  const params = { channel, timestamp };

  return useQuery({
    queryKey: ["channel-engagements-reactions", channel, timestamp],
    queryFn: () =>
      slackApi({
        apiMethod,
        params,
      }),
    enabled,
  });
};
