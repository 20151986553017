import React, { Fragment, useCallback, useEffect, useState } from "react";
import ManualUsersSelect from "../AssignEmployees/ManualUsersSelect";
import Modal from "../Modal/Modal";
import ListItems from "../ListItems";
import ListItemGroupModal from "./ListItemGroupModal";
import { editGroup } from "../../api";
import { useAppContext } from "../../libs/contextLib";
import toast from "react-hot-toast";
import { onError } from "../../libs/errorLib";
import AssignEmployeesToGroup from "./AssignEmployeesToGroup";
import ActionButton from "../ActionButton";
import { getGroupEmployees } from "../../api";
import useDebounce from "../../utils/custom-hooks/useDebounce";
import {
  groupIcons,
  modes,
  transformChannel,
  transformMember,
  views,
  formatOptionLabel,
  types,
} from "../../utils/groupModalUtils";

export default function EditGroupModal({
  showModal,
  onCloseModal,
  assignEmployeesContext,
  activeGroup,
  onGroupDelete,
}) {
  const directions = {
    NEXT: 1,
    PREVIOUS: -1,
  };

  const PAGE_SIZE = 10;

  const { onImportUsers } = assignEmployeesContext;

  const [name, setName] = useState("");
  const [icon, setIcon] = useState(null);
  const [manager, setManager] = useState(null);
  const [members, setMembers] = useState([]);
  const [membersToBeAdded, setMembersToBeAdded] = useState([]);
  const [membersToBeRemoved, setMembersToBeRemoved] = useState([]);
  const [channels, setChannels] = useState([]);
  const [channelsToBeAdded, setChannelsToBeAdded] = useState([]);
  const [channelsToBeRemoved, setChannelsToBeRemoved] = useState([]);
  const [view, setView] = useState(views.GROUP_DETAILS);
  const [allowSubmission, setAllowSubmission] = useState(false);
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [count, setCount] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [confirmedDeletion, setConfirmedDeletion] = useState(false);
  const [slackUserGroups, setSlackUserGroups] = useState([]);
  const [slackUserGroupsToBeAdded, setSlackUserGroupsToBeAdded] = useState([]);
  const [slackUserGroupsToBeRemoved, setSlackUserGroupsToBeRemoved] = useState(
    []
  );

  const { isAuthenticated } = useAppContext();

  const debouncedValue = useDebounce(searchQuery, 300);

  const loadMembers = useCallback(
    (page) => {
      return getGroupEmployees({
        page,
        auth: isAuthenticated,
        group: activeGroup,
        pageSize: PAGE_SIZE,
      });
    },
    [isAuthenticated, activeGroup]
  );

  const storeOptions = (options) => {
    const channelOptions = options.filter(
      ({ value, type }) =>
        type === types.CHANNEL && !activeGroup.channels.find((c) => c === value)
    );
    const memberOptions = options.filter(
      ({ value, type }) =>
        type === types.EMPLOYEE && !activeGroup.members.find((m) => m === value)
    );
    const slackUserGroupOptions = options.filter(
      ({ value, type }) =>
        type === types.SLACK_USER_GROUP &&
        !activeGroup.slack_user_groups.find((g) => g === value)
    );

    setChannelsToBeAdded(channelOptions);
    setMembersToBeAdded(memberOptions);
    setSlackUserGroupsToBeAdded(slackUserGroupOptions);
  };

  const handleOnSubmit = async () => {
    const payload = {
      id: activeGroup.id,
      name,
      icon,
      manager: manager.value,
      membersToBeAdded: membersToBeAdded.map((member) => member.value),
      membersToBeRemoved: membersToBeRemoved.map((member) => member.value),
      channelsToBeAdded: channelsToBeAdded.map((channel) => channel.value),
      channelsToBeRemoved: channelsToBeRemoved.map((channel) => channel.value),
      channelsToSync: [...channels, ...channelsToBeAdded]
        .filter((channel) => channel.sync)
        .map((channel) => channel.value),
    };

    try {
      const data = await editGroup({ auth: isAuthenticated, ...payload });

      toast.success("Group Edited!");

      handleOnClose({ data });
    } catch (error) {
      onError(error);
    }
  };

  const handleOnClose = (payload = null) => {
    resetState();
    onCloseModal(payload);
  };

  const handleOnRemoveNewOption = (payload) => {
    const clear = ({ value }) => value !== payload.value;

    // Clear component-level state
    if (payload.type === types.EMPLOYEE) {
      setMembersToBeAdded(membersToBeAdded.filter(clear));
    } else if (payload.type === types.CHANNEL) {
      setChannelsToBeAdded(channelsToBeRemoved.filter(clear));
    } else if (payload.type === types.SLACK_USER_GROUP) {
      setSlackUserGroupsToBeAdded(slackUserGroupsToBeRemoved.filter(clear));
    }
  };

  const handleOnRemove = (payload) => {
    if (payload.type === types.EMPLOYEE) {
      setMembersToBeRemoved([...membersToBeRemoved, payload]);
    } else if (payload.type === types.CHANNEL) {
      setChannelsToBeRemoved([...channelsToBeRemoved, payload]);
    } else if (payload.type === types.SLACK_USER_GROUP) {
      setSlackUserGroupsToBeRemoved([...slackUserGroupsToBeRemoved, payload]);
    }
  };

  const handleOnAdd = (payload) => {
    const clear = ({ value }) => value !== payload.value;
    if (payload.type === types.EMPLOYEE) {
      setMembersToBeRemoved(membersToBeRemoved.filter(clear));
    } else if (payload.type === types.CHANNEL) {
      setChannelsToBeRemoved(channelsToBeRemoved.filter(clear));
    } else if (payload.type === types.SLACK_USER_GROUP) {
      setSlackUserGroupsToBeRemoved(slackUserGroupsToBeRemoved.filter(clear));
    }
  };

  const handleOnPaginationClick = async (direction) => {
    try {
      const { count, next, previous, results } = await loadMembers(
        page + direction
      );

      setPage(page + direction);
      setCount(count);
      setNextPage(next);
      setPreviousPage(previous);
      setMembers(results.map(transformMember));
    } catch (e) {
      setPage(null);
      onError(e);
    }
  };

  const resetState = () => {
    setName("");
    setIcon(null);
    setManager(null);
    setMembers([]);
    setChannels([]);
    setView(views.GROUP_DETAILS);
    setAllowSubmission(false);
    setPage(1);
    setNextPage(null);
    setPreviousPage(null);
    setCount(null);
    setMembersToBeRemoved([]);
    setChannelsToBeRemoved([]);
    setMembersToBeAdded([]);
    setChannelsToBeAdded([]);
    setSearchQuery("");
    setConfirmedDeletion(false);
    setSlackUserGroups([]);
    setSlackUserGroupsToBeAdded([]);
    setSlackUserGroupsToBeRemoved([]);
  };

  useEffect(() => {
    if (name && manager) {
      setAllowSubmission(true);
    } else {
      setAllowSubmission(false);
    }
  }, [name, manager]);

  useEffect(() => {
    onImportUsers();
  }, [onImportUsers]);

  useEffect(() => {
    const load = async () => {
      const payload = await loadMembers(page);

      return payload;
    };

    if (showModal && activeGroup) {
      load().then((payload) => {
        setName(activeGroup.name);
        setIcon(activeGroup.icon || null);
        setManager(transformMember(activeGroup.manager));

        const channelsToSyncOptions = activeGroup.channels_to_sync
          ? assignEmployeesContext.channelOptions
              .filter(({ value }) =>
                activeGroup.channels_to_sync.includes(value)
              )
              .map(transformChannel)
          : [];

        const channelOptions = activeGroup.channels
          ? assignEmployeesContext.channelOptions
              .filter(({ value }) => activeGroup.channels.includes(value))
              .map((channel) => ({
                ...transformChannel(channel),
                sync:
                  !!channelsToSyncOptions.find(
                    (c) => c.value === channel.value
                  ) || false,
              }))
          : [];

        setChannels(channelOptions);
        setMembers(
          payload.count > 0 ? payload.results.map(transformMember) : []
        );
        const slackUserGroups = assignEmployeesContext.slackUserGroups
          ? assignEmployeesContext.slackUserGroups.filter(({ value }) =>
              (activeGroup.slack_user_groups || []).includes(value)
            )
          : [];
        setSlackUserGroups(slackUserGroups);

        setNextPage(payload.next);
        setPreviousPage(payload.previous);
        setCount(payload.count);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    if (!activeGroup) return;
  }, [activeGroup]);

  useEffect(() => {
    if (!activeGroup) return;

    const searchForGroupMembers = async () => {
      try {
        const { count, next, previous, results } = await getGroupEmployees({
          auth: isAuthenticated,
          name: searchQuery,
          group: activeGroup,
          pageSize: PAGE_SIZE,
        });
        setMembers(count > 0 ? results.map(transformMember) : []);

        setNextPage(next);
        setPreviousPage(previous);
        setCount(count);
      } catch (e) {
        onError(e);
      }
    };
    searchForGroupMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, activeGroup]);

  if (!showModal) return null;

  return (
    <Modal
      show={showModal}
      title={`Edit Group: ${name}`}
      submitButtonText="Save"
      onClose={() => handleOnClose(null)}
      onSubmit={handleOnSubmit}
      submitValid={allowSubmission}
      expandable={true}
    >
      <section>
        <div className="flex mb-2">
          {[views.GROUP_DETAILS, views.GROUP_MEMBERS].map((tab) => (
            <h4
              key={tab}
              className={`tab font-basis-bold mr-2 cursor-pointer ${
                tab === view
                  ? "text-orange border-b-2 pb-0.5 border-current"
                  : "text-hka_gray"
              }`}
              data-tab={tab}
              onClick={() => setView(tab)}
            >
              {tab === views.GROUP_DETAILS ? "Group Details" : "Group Members"}
            </h4>
          ))}
        </div>
        {view === views.GROUP_DETAILS ? (
          <Fragment>
            <p className="mb-2">
              Groups make assigning and organizing training incredibly easy.
              With groups, you can cater trainings to specific roles and/or
              teams within your organization.
            </p>
            <div className="mb-2">
              <h4 className="mb-1 font-basis-bold">
                Group Name <span className="text-hka_gray">(Required)</span>
              </h4>
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Group Name"
                value={name}
                className="input focus:outline-none"
                maxLength={30}
              />
            </div>
            <div className="mb-2">
              <h4 className="mb-1 font-basis-bold">
                Assign Manager <span className="text-hka_gray">(Required)</span>
              </h4>
              <ManualUsersSelect
                isMulti={false}
                options={assignEmployeesContext.employees}
                defaultOptions={[manager] || null}
                onChange={setManager}
                formatOptionLabel={formatOptionLabel}
                labelText="Select Manager"
              />
            </div>
            <div className="mb-2">
              <h4 className="mb-1 font-basis-bold">
                Group Icon <span className="text-hka_gray">(Optional)</span>
              </h4>
              <div className="icons">
                {groupIcons.map((groupIcon) => (
                  <span
                    key={groupIcon}
                    className={`cursor-pointer ${groupIcon} ${
                      groupIcon === icon && "text-orange underline"
                    }`}
                    onClick={() => setIcon(groupIcon)}
                  ></span>
                ))}
              </div>
              <div className="my-2">
                <h4 className="mb-1 font-basis-bold">Delete Group</h4>
                <p className="mb-1">
                  Are you sure you want to delete this group?
                </p>
                {!confirmedDeletion ? (
                  <ActionButton
                    type="danger"
                    onClick={() => setConfirmedDeletion(true)}
                  >
                    <span className="hidden sm:block text-hka_red">
                      Delete This Group
                    </span>
                    <span className="sm:hidden icon-trash right text-hka_red"></span>
                  </ActionButton>
                ) : (
                  <ActionButton type="danger" onClick={onGroupDelete}>
                    <span className="hidden sm:block text-hka_red">
                      Yes, I'm sure
                    </span>
                    <span className="sm:hidden icon-trash right text-hka_red"></span>
                  </ActionButton>
                )}
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <AssignEmployeesToGroup
              defaultOptions={[
                ...members,
                ...channels,
                ...membersToBeAdded,
                ...channelsToBeAdded,
                ...slackUserGroups,
                ...slackUserGroupsToBeAdded,
              ]}
              employees={assignEmployeesContext.employees}
              channels={assignEmployeesContext.channelOptions}
              slackUserGroups={assignEmployeesContext.slackUserGroups}
              onChange={storeOptions}
            />
            <div className="flex items-center flex-1 pl-0.5 h-4 bg-white rounded-lg lg:flex-none border border-hka_gray-border my-2">
              <span className="icon-search mt-0.2 text-hka_gray"></span>
              <input
                className="h-3 text-base border-none min-w-20 max-h-3.8 focus:outline-none"
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search Employees"
              />
            </div>
            <ListItems>
              {channelsToBeAdded.length > 0 && (
                <Fragment>
                  <p className="text-hka_gray px-0.5 bg-hka_gray-light text-sm font-basis-bold inline-flex rounded-md">
                    New Channels
                  </p>
                  {channelsToBeAdded.map((channel, i) => (
                    <ListItemGroupModal
                      mode={modes.CREATE}
                      payload={channel}
                      key={`channel-to-be-added-${i}`}
                      onRemove={handleOnRemoveNewOption}
                    />
                  ))}
                </Fragment>
              )}
              {channels.length > 0 && (
                <Fragment>
                  <p className="mt-2 text-hka_gray px-0.5 bg-hka_gray-light text-sm font-basis-bold inline-flex rounded-md">
                    Current Channels
                  </p>
                  {channels.map((channel, i) => (
                    <ListItemGroupModal
                      mode={modes.EDIT}
                      payload={channel}
                      key={`channel-${i}`}
                      onRemove={handleOnRemove}
                      onAdd={handleOnAdd}
                      removable={
                        !channelsToBeRemoved.find(
                          (c) => c.value === channel.value
                        )
                      }
                    />
                  ))}
                </Fragment>
              )}
              {slackUserGroupsToBeAdded.length > 0 && (
                <Fragment>
                  <p className="text-hka_gray px-0.5 bg-hka_gray-light text-sm font-basis-bold inline-flex rounded-md">
                    New Slack User Groups
                  </p>
                  {slackUserGroupsToBeAdded.map((slackUserGroup, i) => (
                    <ListItemGroupModal
                      mode={modes.CREATE}
                      payload={slackUserGroup}
                      key={`slack-user-group-to-be-added-${i}`}
                      onRemove={handleOnRemoveNewOption}
                    />
                  ))}
                </Fragment>
              )}
              {slackUserGroups.length > 0 && (
                <Fragment>
                  <p className="mt-2 text-hka_gray px-0.5 bg-hka_gray-light text-sm font-basis-bold inline-flex rounded-md">
                    Current Slack User Groups
                  </p>
                  {slackUserGroups.map((slackUserGroup, i) => (
                    <ListItemGroupModal
                      mode={modes.EDIT}
                      payload={slackUserGroup}
                      key={`slack-user-group-${i}`}
                      onRemove={handleOnRemove}
                      onAdd={handleOnAdd}
                      removable={
                        !slackUserGroupsToBeRemoved.find(
                          (g) => g.value === slackUserGroup.value
                        )
                      }
                    />
                  ))}
                </Fragment>
              )}
              {membersToBeAdded.length > 0 && (
                <Fragment>
                  <p className="mt-2 text-sm font-basis-medium">New Members</p>
                  {membersToBeAdded.map((member, i) => (
                    <ListItemGroupModal
                      mode={modes.CREATE}
                      payload={member}
                      key={`member-to-be-added-${i}`}
                      onRemove={handleOnRemoveNewOption}
                    />
                  ))}
                </Fragment>
              )}
              {members.length > 0 && (
                <Fragment>
                  <p className="mt-2 text-sm font-basis-medium">
                    Current Members
                  </p>
                  {members.map((member, i) => (
                    <ListItemGroupModal
                      mode={modes.EDIT}
                      payload={member}
                      key={`member-${i}`}
                      onRemove={handleOnRemove}
                      onAdd={handleOnAdd}
                      removable={
                        !membersToBeRemoved.find(
                          (m) => m.value === member.value
                        )
                      }
                    />
                  ))}
                </Fragment>
              )}
              {members.length > 0 && (
                <Fragment>
                  <li className="flex items-center py-1 space-x-1 border-none hka-pagination">
                    {previousPage && (
                      <ActionButton
                        onClick={() =>
                          handleOnPaginationClick(directions.PREVIOUS)
                        }
                        type="secondary"
                      >
                        <span className="flex sm:hidden icon-arrow left"></span>
                        <span className="hidden sm:flex">Previous</span>
                      </ActionButton>
                    )}
                    {nextPage && (
                      <ActionButton
                        onClick={() => handleOnPaginationClick(directions.NEXT)}
                        type="secondary"
                      >
                        <span className="hidden sm:flex">Next</span>
                        <span className="flex sm:hidden icon-arrow right"></span>
                      </ActionButton>
                    )}
                  </li>
                </Fragment>
              )}
            </ListItems>
          </Fragment>
        )}
      </section>
    </Modal>
  );
}
