import React, { useEffect, useState } from 'react';
import useFetchSlackChannels from '../utils/custom-hooks/useFetchSlackChannels';
import { useAppContext } from '../libs/contextLib';
import useFetchEmployees from '../utils/custom-hooks/useFetchEmployees';
import useFetchGroups from '../utils/custom-hooks/useFetchGroups';
import useFetchSlackUserGroups from '../utils/custom-hooks/useFetchSlackUserGroups';
import * as api from '../api';
import toast from 'react-hot-toast';
import { onError } from '../libs/errorLib';
import { logEmployeesAssignedTraining } from '../utils/internal-event-logger';
import {
  handleDoceboOptionsTrainingSubmit,
  handleGoogleOptionsTrainingSubmit,
  handleGroupOptionsTrainingSubmit,
  handleManuallyOptionsTrainingSubmit,
  handleOktaOptionsTrainingSubmit,
  handleSeismicOptionsTrainingSubmit,
  handleSlackOptionsTrainingSubmit,
} from './assign-employees-utils';
import { updateCompanyEngagement } from '../api/engagementsApi';
import { useExternalAppsData } from '../hooks/data-hooks/integrations/useExternalAppsData';
import { updateStreamSubscription } from '../api/streamsApi';
import { useQueryClient } from '@tanstack/react-query';

// creating initial state for better autocompletation
const initialState = {
  manuallySelectedEmployees: [],
  setManuallySelectedEmployees: () => {},
  slackAddAllUsers: [],
  setSlackAddAllUsers: () => {},
  slackAutoEnroll: false,
  setSlackAutoEnroll: () => {},
  selectedChannels: [],
  setSelectedChannels: () => {},
  selectedChannelsSync: [],
  setSelectedChannelsSync: () => {},
  onImportUsers: () => {},
  channelOptions: [],
  loadingChannelsCompleted: false,
  employees: [],
  employeesImportProgress: 0,
  submitIsValid: true,
  setSubmitIsValid: () => {},
  handleSubmit: () => {},
  handleTrainingSubmit: () => {},
  setObjectType: () => {},
  object: {},
  setObject: () => {},
  setFetchedChannels: () => {},
  modalOpened: false,
  setModalOpened: () => {},
  syncInProgress: false,
  setSyncInProgress: () => {},
  onModalClose: () => {},
  manualUsersError: undefined,
  setManualUsersError: () => {},
  refetchEmployees: false,
  groups: [],
  setGroups: () => {},
  loadGroups: false,
  loadingGroupsCompleted: false,
  activeGroup: null,
  setActiveGroup: () => {},
  selectedGroupsForTraining: [],
  setSelectedGroupsForTraining: () => {},
  clearContext: () => {},
  selectedTab: undefined,
  setSelectedTab: () => {},
  googleAddAllUsers: false,
  setGoogleAddAllUsers: () => {},
  googleAutoEnroll: false,
  setGoogleAutoEnroll: () => {},
  syncGroups: [],
  setSyncGroups: () => {},
  addAllCompanyUsersToWorkflow: false,
  setAddAllCompanyUsersToWorkflow: () => {},
  slackUserGroups: [],
  setSlackUserGroups: () => {},
  loadingSlackUserGroupsCompleted: false,
  assignEngagementNow: false,
  setAssignEngagementNow: () => {},
  missingScopesError: false,
  setMissingScopesError: () => {},
  internalModalTab: undefined,
  setInternalModalTab: () => {},
  uploadedCsvUsers: [],
  setUploadedCsvUsers: () => {},
  alertEmployees: true,
  setAlertEmployees: () => {},
  copyUsersFromTraining: undefined,
  setCopyUsersFromTraining: () => {},
  oktaGroupsSync: [],
  setOktaGroupsSync: () => {},
  syncOktaGroupsIDs: undefined,
  setSyncOktaGroupsIDs: () => {},
  reloadChannelsHandler: () => {},
  allDoceboUsers: false,
  setAllDoceboUsers: () => {},
  addNewDoceboUsers: false,
  setAddNewDoceboUsers: () => {},
  activeDoceboIntegration: undefined,
  setActiveDoceboIntegration: () => {},
  activeSeismicIntegration: undefined,
  setActiveSeismicIntegration: () => {},
  selectedGoogleGroups: [],
  setSelectedGoogleGroups: () => {},
  googleGroupsToSyncIDs: [],
  setGoogleGroupsToSyncIDs: () => {},
  googleGroupsToSync: [],
  setGoogleGroupsToSync: () => {},
  selectedOktaGroups: [],
  setSelectedOktaGroups: () => {},
  allSeismicUsers: false,
  setAllSeismicUsers: () => {},
  addNewSeismicUsers: false,
  setAddNewSeismicUsers: () => {},
};

const AssignEmployeesContext = React.createContext(initialState);

export const OBJECT_TYPES = {
  TRAINING: 'TRAINING',
  QUIZ: 'QUIZ',
  ADMIN: 'ADMIN',
  WORKFLOW: 'WORKFLOW',
  STREAM: 'STREAM',
};

export const AssignEmployeesProvider = ({ children }) => {
  const [missingScopesError, setMissingScopesError] = useState(false);

  const {
    isAuthenticated,
    connectedWithSlack,
    // , isGoogleAppInstalled
  } = useAppContext();
  const queryClient = useQueryClient();

  const { data: externalIntegrations } = useExternalAppsData(!!isAuthenticated);

  const activeGoogleApp = externalIntegrations?.results.find(
    (ei) => ei.resourcetype === 'GoogleInstallation' && ei.is_active
  );
  const {
    channels: channelOptions,
    completed: loadingChannelsCompleted,
    setTrigger: loadChannels,
    reloadChannelsHandler,
  } = useFetchSlackChannels({ auth: isAuthenticated });

  const {
    employees,
    employeesImportProgress,
    setTrigger: loadEmployees,
    completed: loadingEmployeesCompleted,
  } = useFetchEmployees({
    auth: isAuthenticated,
  });

  const {
    response,
    setTrigger: loadGroups,
    completed: loadingGroupsCompleted,
  } = useFetchGroups({ auth: isAuthenticated });

  const {
    response: fetchedSlackUserGroups,
    setTrigger: loadSlackUserGroups,
    completed: loadingSlackUserGroupsCompleted,
  } = useFetchSlackUserGroups({
    auth: isAuthenticated,
    setMissingScopesError,
  });

  const [modalOpened, setModalOpened] = useState(false);
  const [selectedTab, setSelectedTab] = useState(undefined);

  const [fetchedChannels, setFetchedChannels] = useState([]);
  const [manuallySelectedEmployees, setManuallySelectedEmployees] = useState(
    []
  );
  const [slackAddAllUsers, setSlackAddAllUsers] = useState(false);
  const [slackAutoEnroll, setSlackAutoEnroll] = useState(false);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedChannelsSync, setSelectedChannelsSync] = useState([]);

  const [loadingStarted, setLoadingStarted] = useState(false);
  const [submitIsValid, setSubmitIsValid] = useState(true);
  const [syncInProgress, setSyncInProgress] = useState(false);

  const [objectType, setObjectType] = useState(undefined);
  const [object, setObject] = useState(undefined);

  const [manualUsersError, setManualUsersError] = useState(undefined);
  const [refetchEmployees, setRefetchEmployees] = useState(false);

  const [groups, setGroups] = useState([]);
  const [activeGroup, setActiveGroup] = useState(null);
  const [selectedGroupsForTraining, setSelectedGroupsForTraining] = useState(
    []
  );
  const [syncGroups, setSyncGroups] = useState([]);

  const [googleAddAllUsers, setGoogleAddAllUsers] = useState(false);
  const [googleAutoEnroll, setGoogleAutoEnroll] = useState(false);

  const [addAllCompanyUsersToWorkflow, setAddAllCompanyUsersToWorkflow] =
    useState(false);

  const [slackUserGroups, setSlackUserGroups] = useState([]);
  const [assignEngagementNow, setAssignEngagementNow] = useState(false);
  const [internalModalTab, setInternalModalTab] = useState(undefined);
  const [uploadedCsvUsers, setUploadedCsvUsers] = useState([]);
  const [alertEmployees, setAlertEmployees] = useState(true);
  const [copyUsersFromTraining, setCopyUsersFromTraining] = useState(undefined);
  const [oktaGroupsSync, setOktaGroupsSync] = useState([]);
  const [syncOktaGroupsIDs, setSyncOktaGroupsIDs] = useState(undefined);
  const [allDoceboUsers, setAllDoceboUsers] = useState(false);
  const [addNewDoceboUsers, setAddNewDoceboUsers] = useState(false);
  const [activeDoceboIntegration, setActiveDoceboIntegration] =
    useState(undefined);
  const [activeSeismicIntegration, setActiveSeismicIntegration] =
    useState(undefined);
  const [selectedGoogleGroups, setSelectedGoogleGroups] = useState([]);
  const [googleGroupsToSyncIDs, setGoogleGroupsToSyncIDs] = useState([]);
  const [googleGroupsToSync, setGoogleGroupsToSync] = useState([]);
  const [selectedOktaGroups, setSelectedOktaGroups] = useState([]);
  const [allSeismicUsers, setAllSeismicUsers] = useState(false);
  const [addNewSeismicUsers, setAddNewSeismicUsers] = useState(false);

  useEffect(() => {
    if (!loadingGroupsCompleted) loadGroups(true);
    setGroups(response);
  }, [loadGroups, loadingGroupsCompleted]);

  useEffect(() => {
    if (!loadingSlackUserGroupsCompleted) loadSlackUserGroups(true);
    setSlackUserGroups(fetchedSlackUserGroups);
  }, [loadSlackUserGroups, loadingSlackUserGroupsCompleted]);

  useEffect(() => {
    if (channelOptions.length > 0) {
      const ch = channelOptions.filter((co) =>
        (fetchedChannels ?? []).includes(co.value)
      );
      setSelectedChannelsSync(ch);
    }
  }, [fetchedChannels, channelOptions]);

  useEffect(() => {
    if (selectedTab === 'GOOGLE' && !activeGoogleApp) {
      setSubmitIsValid(false);
    } else {
      const valid = !!selectedTab;
      setSubmitIsValid(valid);
    }
  }, [activeGoogleApp, selectedTab]);

  const onImportUsers = () => {
    if (isAuthenticated.is_admin) {
      const e = setTimeout(() => {
        if (!loadingStarted) {
          if (connectedWithSlack) loadChannels(true);
          loadEmployees(true);
        }
        setLoadingStarted(true);
        clearTimeout(e);
      }, 2000);
    }
  };

  const handleTrainingSubmit = async () => {
    try {
      if (selectedTab === 'SLACK') {
        await handleSlackOptionsTrainingSubmit({
          slackAutoEnroll,
          selectedChannelsSync,
          isAuthenticated,
          object,
          setSlackAutoEnroll,
          channelOptions,
          setSelectedChannelsSync,
          slackAddAllUsers,
          manuallySelectedEmployees,
          selectedChannels,
          logEmployeesAssignedTraining,
          alertEmployees,
        });
      } else if (selectedTab === 'GOOGLE') {
        await handleGoogleOptionsTrainingSubmit({
          isAuthenticated,
          object,
          googleAutoEnroll,
          googleAddAllUsers,
          alertEmployees,
          selectedGoogleGroups,
          setSelectedGoogleGroups,
          googleGroupsToSync,
        });
      } else if (selectedTab === 'MANUALLY') {
        await handleManuallyOptionsTrainingSubmit({
          isAuthenticated,
          object,
          manuallySelectedEmployees,
          uploadedCsvUsers,
          alertEmployees,
          copyUsersFromTraining: copyUsersFromTraining?.value,
          trainingTitle: copyUsersFromTraining?.label,
        });
      } else if (selectedTab === 'GROUPS') {
        await handleGroupOptionsTrainingSubmit({
          isAuthenticated,
          object,
          selectedGroupsForTraining,
          syncGroups,
          alertEmployees,
        });
      } else if (selectedTab === 'OKTA') {
        await handleOktaOptionsTrainingSubmit({
          isAuthenticated,
          object,
          oktaGroupsSync,
          alertEmployees,
          selectedOktaGroups,
        });
      } else if (selectedTab === 'DOCEBO') {
        await handleDoceboOptionsTrainingSubmit({
          isAuthenticated,
          object,
          activeDoceboIntegration,
          allDoceboUsers,
          setAllDoceboUsers,
          addNewDoceboUsers,
        });
      } else if (selectedTab === 'SEISMIC') {
        await handleSeismicOptionsTrainingSubmit({
          isAuthenticated,
          object,
          activeSeismicIntegration,
          addNewSeismicUsers,
          allSeismicUsers,
          setAllSeismicUsers,
        });
      }
    } catch (error) {
      onError(error);
    }
    setModalOpened(false);
    onModalClose();
  };

  const handleQuizSubmit = async () => {
    try {
      const res = await updateCompanyEngagement({
        auth: isAuthenticated,
        sumbitData: {
          id: object.id,
          channels: selectedChannelsSync.map((c) => c.value),
          auto_enroll: slackAutoEnroll,
          okta_groups_sync: oktaGroupsSync.map((c) => c.value),
        },
      });
      setSlackAutoEnroll(res.auto_enroll);
      const ch = channelOptions.filter((co) => res.channels.includes(co.value));
      setSelectedChannelsSync(ch);

      if (selectedTab === 'OKTA') {
        await api.openBulkEmployeesQuizOkta({
          companyID: isAuthenticated.company,
          selectedQuiz: object.id,
          groups: selectedOktaGroups,
          assignNow: assignEngagementNow,
        });
        toast.success(
          "We will start importing users from your Okta Groups. Depending on the number of users in selected groups it can take a while. We will send you a message in Slack once we're done."
        );
      } else {
        if (slackAddAllUsers) {
          const res = await api.openBulkEmployeesQuizCompany({
            auth: isAuthenticated,
            selectedQuiz: object.id,
            manuallySelectedUsers: manuallySelectedEmployees,
            assignNow: assignEngagementNow,
          });
          toast.success(res.results);
        } else if (selectedChannels.length > 0) {
          const res = await api.openBulkEmployeesQuizChannel({
            auth: isAuthenticated,
            selectedQuiz: object.id,
            channels: selectedChannels.map((c) => c.value),
            manuallySelectedUsers: manuallySelectedEmployees,
            assignNow: assignEngagementNow,
          });

          toast.success(res.results);
        } else {
          // Add just manually selected users
          const res = await api.openBulkEmployeesQuizSelect({
            auth: isAuthenticated,
            companyQuizId: object.id,
            manuallySelectedUsers: [
              ...manuallySelectedEmployees,
              ...uploadedCsvUsers,
            ],
            assignNow: assignEngagementNow,
          });
          toast.success(res.results);
        }
      }
    } catch (error) {
      onError(error);
    }
    setModalOpened(false);
    onModalClose();
  };

  const handleAdminSubmit = async () => {
    try {
      const employees = uploadedCsvUsers.map((u) => u.value);
      if (object) {
        employees.push(object.value);
      }
      for (const employeeId of employees) {
        await api.addEmployeeAdmin({
          auth: isAuthenticated,
          employeeId,
        });
      }
      toast.success(`Admin added`);
    } catch (error) {
      onError(error);
    }
    setModalOpened(false);
    onModalClose();
  };

  const handleWorkflowSubmit = async (e) => {
    try {
      const res = await api.updateCompanyWorkflow({
        auth: isAuthenticated,
        workflowId: object ? object.id : e.id,
        bodyData: {
          auto_enroll: slackAutoEnroll,
          channels: selectedChannelsSync.map((c) => c.value),
        },
      });
      setSlackAutoEnroll(res.auto_enroll);
      const ch = channelOptions.filter((co) => res.channels.includes(co.value));
      setSelectedChannelsSync(ch);

      if (slackAddAllUsers) {
        await api.openBulkEmployeesWorkflowCompany({
          auth: isAuthenticated,
          selectedWorkflow: object ? object.id : e.id,
          excludeUsers: [],
          manuallySelectedUsers: manuallySelectedEmployees,
        });
      } else if (selectedChannels.length > 0) {
        await api.openBulkEmployeesWorkflowChannel({
          auth: isAuthenticated,
          selectedWorkflow: object ? object.id : e.id,
          channels: selectedChannels.map((c) => ({
            slack_channel: c.value,
          })),
          manuallySelectedUsers: manuallySelectedEmployees,
        });
      } else {
        // Add just manually selected users
        await api.openBulkEmployeesWorkflowSelect({
          auth: isAuthenticated,
          selectedWorkflow: object ? object.id : e.id,
          manuallySelectedUsers: [
            ...manuallySelectedEmployees,
            ...uploadedCsvUsers,
          ],
        });
      }
    } catch (error) {
      onError(error);
    }
    setModalOpened(false);
    onModalClose();
  };

  const handleStreamSubmit = async (e) => {
    try {
      const res = updateStreamSubscription({
        companyID: isAuthenticated.company,
        streamID: object.id,
        payload: {
          auto_enroll: slackAutoEnroll,
        },
      });
      setSlackAutoEnroll(res.auto_enroll);
      // const ch = channelOptions.filter((co) => res.channels.includes(co.value));
      // setSelectedChannelsSync(ch);

      if (slackAddAllUsers) {
        await api.openBulkEmployeesStreamCompany({
          auth: isAuthenticated,
          selectedStream: object.companyStreamID,
          excludeUsers: [],
          manuallySelectedUsers: manuallySelectedEmployees,
        });
      } else if (selectedChannels.length > 0) {
        await api.openBulkEmployeesStreamChannel({
          auth: isAuthenticated,
          selectedStream: object.companyStreamID,
          channels: selectedChannels.map((c) => ({
            slack_channel: c.value,
          })),
          manuallySelectedUsers: manuallySelectedEmployees,
        });
      } else {
        // // Add just manually selected users
        await api.openBulkEmployeesStreamSelect({
          auth: isAuthenticated,
          selectedStream: object.companyStreamID,
          manuallySelectedUsers: [
            ...manuallySelectedEmployees,
            ...uploadedCsvUsers,
          ],
        });
      }
    } catch (error) {
      onError(error);
    }
    toast.success(
      'Assignment in progress. We will send you a notification in Slack once were done.'
    );
    setModalOpened(false);
    onModalClose();
  };

  const handleSubmit = (e) => {
    if (objectType === OBJECT_TYPES.TRAINING) {
      handleTrainingSubmit();
    } else if (objectType === OBJECT_TYPES.QUIZ) {
      handleQuizSubmit();
    } else if (objectType === OBJECT_TYPES.ADMIN) {
      handleAdminSubmit();
      setTimeout(() => {
        queryClient.invalidateQueries(['employees']);
      }, 300);
    } else if (objectType === OBJECT_TYPES.WORKFLOW) {
      handleWorkflowSubmit(e);
    } else if (objectType === OBJECT_TYPES.STREAM) {
      handleStreamSubmit(e);
    }
  };

  const onModalClose = () => {
    clearContext();
  };

  const clearContext = () => {
    setSlackAddAllUsers(false);
    setManuallySelectedEmployees([]);
    setSelectedChannels([]);
    setManualUsersError(undefined);
    setRefetchEmployees((p) => !p);
    setSelectedGroupsForTraining([]);
    setSelectedTab(undefined);
    setGoogleAddAllUsers(false);
    setAddAllCompanyUsersToWorkflow(false);
    setAssignEngagementNow(false);
    setUploadedCsvUsers([]);
    setAlertEmployees(true);
    setCopyUsersFromTraining(undefined);
    setOktaGroupsSync([]);
    setSelectedOktaGroups([]);
  };

  return (
    <AssignEmployeesContext.Provider
      value={{
        manuallySelectedEmployees,
        setManuallySelectedEmployees,
        slackAddAllUsers,
        setSlackAddAllUsers,
        slackAutoEnroll,
        setSlackAutoEnroll,
        selectedChannels,
        setSelectedChannels,
        selectedChannelsSync,
        setSelectedChannelsSync,
        onImportUsers,
        channelOptions,
        loadingChannelsCompleted,
        employees,
        employeesImportProgress,
        submitIsValid,
        setSubmitIsValid,
        handleSubmit,
        handleTrainingSubmit,
        setObjectType,
        object,
        setObject,
        setFetchedChannels,
        modalOpened,
        setModalOpened,
        syncInProgress,
        setSyncInProgress,
        onModalClose,
        manualUsersError,
        setManualUsersError,
        refetchEmployees,
        groups,
        setGroups,
        loadGroups,
        loadingGroupsCompleted,
        activeGroup,
        setActiveGroup,
        selectedGroupsForTraining,
        setSelectedGroupsForTraining,
        clearContext,
        selectedTab,
        setSelectedTab,
        googleAddAllUsers,
        setGoogleAddAllUsers,
        googleAutoEnroll,
        setGoogleAutoEnroll,
        syncGroups,
        setSyncGroups,
        addAllCompanyUsersToWorkflow,
        setAddAllCompanyUsersToWorkflow,
        slackUserGroups,
        setSlackUserGroups,
        loadingSlackUserGroupsCompleted,
        assignEngagementNow,
        setAssignEngagementNow,
        missingScopesError,
        setMissingScopesError,
        internalModalTab,
        setInternalModalTab,
        uploadedCsvUsers,
        setUploadedCsvUsers,
        alertEmployees,
        setAlertEmployees,
        copyUsersFromTraining,
        setCopyUsersFromTraining,
        oktaGroupsSync,
        setOktaGroupsSync,
        syncOktaGroupsIDs,
        setSyncOktaGroupsIDs,
        reloadChannelsHandler,
        allDoceboUsers,
        setAllDoceboUsers,
        addNewDoceboUsers,
        setAddNewDoceboUsers,
        activeDoceboIntegration,
        setActiveDoceboIntegration,
        activeSeismicIntegration,
        setActiveSeismicIntegration,
        selectedGoogleGroups,
        setSelectedGoogleGroups,
        googleGroupsToSyncIDs,
        setGoogleGroupsToSyncIDs,
        googleGroupsToSync,
        setGoogleGroupsToSync,
        selectedOktaGroups,
        setSelectedOktaGroups,
        allSeismicUsers,
        setAllSeismicUsers,
        addNewSeismicUsers,
        setAddNewSeismicUsers,
      }}
    >
      {children}
    </AssignEmployeesContext.Provider>
  );
};

export default AssignEmployeesContext;
