import * as api from '../api';
import React, { useState, useEffect, Fragment } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../libs/contextLib';
import { onError } from '../libs/errorLib';
import toast from 'react-hot-toast';
import SettingsNavigationBar from '../components/Settings/SettingsNavigationBar';
import ProfileSettings from '../components/Settings/ProfileSettings/ProfileSettings';
import SlackSettings from '../components/Settings/SlackSettings';
import IntegrationSettings from '../components/Settings/IntegrationSettings';
import TransitionWrapper from '../components/Base/TransitionWrapper';
import Loader from '../components/Loader';
import { getLoggedInUserDetails } from '../utils/commonUtils';
import { updateEmployee } from '../api/settings/usersSettingsApi';
import { useRolesData } from '../hooks/data-hooks/settings';
import {
  getUserAccountsSettings,
  updateUserAccountsSettings,
} from '../api/settings/companySettingsApi';

export const SETTINGS_VIEWS = {
  PROFILE: 'settings',
  SLACK: 'slack',
  INTEGRATIONS: 'integrations',
};

export default function Settings() {
  const { id } = useParams();
  const { isAuthenticated, userHasAuthenticated, featureFlags } =
    useAppContext();

  const navigate = useNavigate();
  const location = useLocation();

  const { data: roles } = useRolesData({
    page: 1,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [guestAccountsIncluded, setGuestAccountsIncluded] = useState(true);
  const [keepGuestAccounts, setKeepGuestAccounts] = useState(false);
  const [newUsersOnboarding, setNewUsersOnboarding] = useState(true);
  const [weeklyReport, setWeeklyReport] = useState(true);
  const [overdueTrainingReminder, setOverdueTrainingReminder] = useState(true);
  const [slackNotifications, setSlackNotifications] = useState(null);
  const [emailNotifications, setEmailNotifications] = useState(null);
  const [payPlanIncludesHRIS, setPayPlanIncludesHRIS] = useState(null);
  const [vantaOAuthLink, setVantaOAuthLink] = useState('');
  const [isVantaInstalled, setIsVantaInstalled] = useState(false);
  const [hasDocebo, setHasDocebo] = useState(false);
  const [hasSeismic, setHasSeismic] = useState(false);
  const [autoSyncGoogleUsers, setAutoSyncGoogleUsers] = useState(false);
  const [showGenerateApiKeyModal, setShowGenerateApiKeyModal] = useState(false);
  const [showDeleteApiKeyModal, setShowDeleteApiKeyModal] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const [selectedApiKey, setSelectedApiKey] = useState(null);
  const [selectedView, setSelectedView] = useState(SETTINGS_VIEWS.PROFILE);
  const [selectedRole, setSelectedRole] = useState(null);
  const [notificationSchedule, setNotificationSchedule] = useState(null);
  const [notificationScheduleLoadTrigger, triggerLoadNotificationSchedule] =
    useState(false);
  const [isGoogleWorkspaceIntegrated, setIsGoogleWorkspaceIntegrated] =
    useState(false);
  const [newUsersOnboardingAdmins, setNewUsersOnboardingAdmins] =
    useState(true);
  const [usersCompletedTrainingAdmin, setUsersCompletedTrainingAdmin] =
    useState(true);
  const [usersSubscribedToTipsAndTopics, setUsersSubscribedToTipsAndTopics] =
    useState(true);
  const [usersAssignedTrainingAdmin, setUsersAssignedTrainingAdmin] =
    useState(true);
  const [payPlanIncludesAPIAccess, setPayPlanIncludesAPIAccess] =
    useState(null);
  const [payPlanIncludesPhishing, setPayPlanIncludesPhishing] = useState(false);
  const [phishingMessagesAdmin, setPhishingMessagesAdmin] = useState(true);
  const [phishingReminderOnReport, setPhishingReminderOnReport] =
    useState(true);
  const [hasPhishingAccess, setHasPhishingAccess] = useState(false);

  function loadApiKeys() {
    return api.getApiKeys({ auth: isAuthenticated });
  }

  function loadNotificationSchedule() {
    return api.getCompanyNotificationSchedule({
      auth: isAuthenticated,
    });
  }

  useEffect(() => {
    async function onNotificationScheduleLoadTrigger() {
      try {
        const schedule = await loadNotificationSchedule();
        setNotificationSchedule(schedule);
      } catch (error) {
        onError(error);
      }
    }

    onNotificationScheduleLoadTrigger();
  }, [notificationScheduleLoadTrigger]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const view = params.get('view');

    if (view) {
      setSelectedView(view);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated.role) {
      const role = roles?.results.find(
        (role) => role.id === isAuthenticated.role
      );
      setSelectedRole(
        role
          ? {
              value: role.id,
              label: role.title,
            }
          : null
      );
    }
  }, [roles, isAuthenticated]);

  useEffect(() => {
    function loadNotifications() {
      return api.getNotifications({ auth: isAuthenticated });
    }
    function loadAccountsSettings() {
      // TO-DO: replace this with the useUserAccountsSettingsData
      return getUserAccountsSettings({ companyID: isAuthenticated.company });
    }

    async function onLoad() {
      async function getEmployeeDetails() {
        return await getLoggedInUserDetails({
          auth: isAuthenticated,
        });
      }
      const checkInstalledApps = () => {
        return api.checkInstalledApps({
          auth: isAuthenticated,
          companyId: JSON.parse(localStorage.getItem('auth')).company_details
            .id,
        });
      };
      try {
        const employeeDetails = await getEmployeeDetails();
        setEmailNotifications(
          employeeDetails.notifications?.email_notification
        );
        setSlackNotifications(
          employeeDetails.notifications?.slack_notification
        );
        setPayPlanIncludesHRIS(
          employeeDetails.company_details.hris_integration
        );
        setPayPlanIncludesPhishing(
          employeeDetails.company_details.has_phishing_access
        );
        setIsVantaInstalled(employeeDetails.company_details.has_vanta);
        setHasDocebo(employeeDetails.company_details.has_docebo);
        setHasSeismic(employeeDetails.company_details.has_seismic);
        setPayPlanIncludesAPIAccess(
          employeeDetails.company_details.has_api_access
        );
        setHasPhishingAccess(
          employeeDetails.company_details.has_phishing_access
        );
        if (isAuthenticated.is_admin) {
          const {
            guest_accounts_included,
            keep_guest_accounts,
            google_auto_sync,
          } = await loadAccountsSettings();
          const {
            new_users_onboarding: newUsersOnboarding,
            new_users_onboarding_admin: newUsersOnboardingAdmins,
            users_completed_training_admin: usersCompletedTrainingAdmin,
            users_assigned_training_admin: usersAssignedTrainingAdmin,
            weekly_report: weeklyReport,
            overdue_training_reminder: overdueTrainingReminder,
            phishing_messages_admin: phishingMessagesAdmin,
            phishing_reminder_to_report: _phishingReminderOnReport,
          } = await loadNotifications();
          setGuestAccountsIncluded(guest_accounts_included);
          setKeepGuestAccounts(keep_guest_accounts);
          setAutoSyncGoogleUsers(google_auto_sync);
          setNewUsersOnboarding(newUsersOnboarding);
          setNewUsersOnboardingAdmins(newUsersOnboardingAdmins);
          setUsersCompletedTrainingAdmin(usersCompletedTrainingAdmin);
          setUsersSubscribedToTipsAndTopics(usersSubscribedToTipsAndTopics);
          setUsersAssignedTrainingAdmin(usersAssignedTrainingAdmin);
          setWeeklyReport(weeklyReport);
          setOverdueTrainingReminder(overdueTrainingReminder);
          setPhishingMessagesAdmin(phishingMessagesAdmin);
          setPhishingReminderOnReport(_phishingReminderOnReport);
          const { is_google_workspace_integrated } = await checkInstalledApps();
          setIsGoogleWorkspaceIntegrated(is_google_workspace_integrated);
          const companyApiKeys = await loadApiKeys();

          if (companyApiKeys.count > 0) {
            setApiKeys(companyApiKeys.results);
          }

          const vantaLink = await api.getVantaIntegrationLink({
            auth: isAuthenticated,
          });

          setVantaOAuthLink(vantaLink);
        }
        const schedule = await loadNotificationSchedule();
        setNotificationSchedule(schedule);
        setIsLoading(false);
      } catch (e) {
        onError(e);
      }
    }
    onLoad();
  }, [id]);

  useEffect(() => {
    if (!isAuthenticated.is_admin && selectedView !== SETTINGS_VIEWS.PROFILE) {
      setSelectedView(SETTINGS_VIEWS.PROFILE);

      return;
    }

    // Set the view query parameter every time the selectedView state field changes
    navigate(
      {
        pathname: window.location.pathname,
        search: `?view=${selectedView}`,
      },
      { replace: true }
    );
  }, [selectedView]);

  const updateSetting = async (type, property, value) => {
    try {
      if (type === 'notifications') {
        const updateData = {
          new_users_onboarding: newUsersOnboarding,
          new_users_onboarding_admin: newUsersOnboardingAdmins,
          users_completed_training_admin: usersCompletedTrainingAdmin,
          users_assigned_training_admin: usersAssignedTrainingAdmin,
          weekly_report: weeklyReport,
          overdue_training_reminder: overdueTrainingReminder,
          phishing_messages_admin: phishingMessagesAdmin,
          phishing_reminder_to_report: phishingReminderOnReport,
        };
        updateData[property] = value;

        await api.updateNotifications({
          auth: isAuthenticated,
          updateData,
        });
      }

      if (type === 'account') {
        const updateData = {
          guest_accounts_included: guestAccountsIncluded,
          keep_guest_accounts: keepGuestAccounts,
        };
        updateData[property] = value;

        // TO-DO: reuse useUpdateUserAccountsSettings here
        await updateUserAccountsSettings({
          companyID: isAuthenticated.company,
          updateData,
        });
        // we are using info from this object in strage in the Home view
        // which is the reason why we are updating it
        const authObject = JSON.parse(localStorage.getItem('auth'));
        authObject.company_details.guest_accounts_included =
          updateData.guest_accounts_included;
        localStorage.setItem('auth', JSON.stringify(authObject));
        userHasAuthenticated(authObject);
      }
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    async function submitNotificationUpdates() {
      try {
        await updateEmployee({
          employeeID: isAuthenticated.id,
          updateData: {
            notifications: {
              email_notification: emailNotifications,
              slack_notification: slackNotifications,
            },
          },
        });
        toast.success(`Settings updated`);
      } catch (e) {
        onError(e);
      }
    }

    if (!isLoading) {
      submitNotificationUpdates();
    }
  }, [emailNotifications, slackNotifications]);

  const handleOnGenerateApiKeyButtonClick = () => {
    setShowGenerateApiKeyModal(true);
  };

  const handleOnGenerateApiKeyModalClose = async (triggerReload) => {
    setShowGenerateApiKeyModal(false);

    if (triggerReload) {
      const companyApiKeys = await loadApiKeys();
      setApiKeys(companyApiKeys.results);
    }
  };

  const handleOnDeleteApiKeyModalSubmit = async () => {
    try {
      await api.deleteApiKey({
        auth: isAuthenticated,
        apiKeyId: selectedApiKey.id,
      });

      toast.success(`API Key Deleted!`);

      const companyApiKeys = await loadApiKeys();
      setApiKeys(companyApiKeys.results);

      setShowDeleteApiKeyModal(false);
      setSelectedApiKey(null);
    } catch (error) {
      onError(error);
    }
  };

  const handleOnNotificationScheduleUpdate = async (payload) => {
    try {
      const updatedSchedule = await api.updateCompanyNotificationSchedule({
        auth: isAuthenticated,
        payload,
      });
      setNotificationSchedule(updatedSchedule);
    } catch (error) {
      onError(error);
    }
  };

  const updateroleInLocalStorage = (role) => {
    const authObject = JSON.parse(localStorage.getItem('auth'));
    authObject.role_serialized = role;
    authObject.role = role.id;
    localStorage.setItem('auth', JSON.stringify(authObject));
  };

  const handleRoleChange = async (newRole) => {
    try {
      setSelectedRole(newRole);

      await updateEmployee({
        employeeID: isAuthenticated.id,
        updateData: { role: newRole.value },
      });

      toast.success('Role updated!');

      const targetRole = roles?.results.find(
        (role) => role.id === newRole.value
      );

      updateroleInLocalStorage(targetRole);
    } catch (error) {
      onError(error);
    }
  };

  if (isLoading) {
    return (
      <TransitionWrapper show={true}>
        <Loader fullscreen />
      </TransitionWrapper>
    );
  }

  return (
    <div className='fixed left-0 right-0 z-20 flex justify-center p-2 overflow-y-auto border-t border-b 2xl:px-0 bottom-4 bg-hka_gray-ultraLight top-10 border-hka_gray-light'>
      <div className='grid max-w-screen-xl grid-cols-12'>
        <div className='pr-0 sm:pr-1 xl:pr-3 sm:col-span-3 col-span-full lg:col-span-2'>
          <SettingsNavigationBar
            isAdmin={isAuthenticated.is_admin}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
        </div>
        <div className='pl-0 sm:pl-1 xl:pl-3 sm:col-span-9 col-span-full lg:col-span-10'>
          <main className='relative grid grid-cols-10 mt-4'>
            <button
              className='absolute right-0 w-4 h-4 px-0 rounded-lg -top-20 sm:-top-4 button hover:bg-hka_gray-light'
              onClick={() => navigate('/')}
            >
              <span className='icon-close'></span>
            </button>
            {selectedView === SETTINGS_VIEWS.PROFILE && (
              <ProfileSettings
                name={isAuthenticated.name}
                email={isAuthenticated.email}
                selectedRole={selectedRole}
                handleRoleChange={handleRoleChange}
                slackNotifications={slackNotifications}
                emailNotifications={emailNotifications}
                setSlackNotifications={setSlackNotifications}
                setEmailNotifications={setEmailNotifications}
                hasPhishingAccess={hasPhishingAccess}
              />
            )}

            {isAuthenticated.is_admin && (
              <Fragment>
                {selectedView === SETTINGS_VIEWS.SLACK && (
                  <SlackSettings
                    updateSetting={updateSetting}
                    guestAccountsIncluded={guestAccountsIncluded}
                    setGuestAccountsIncluded={setGuestAccountsIncluded}
                    keepGuestAccounts={keepGuestAccounts}
                    setKeepGuestAccounts={setKeepGuestAccounts}
                    newUsersOnboarding={newUsersOnboarding}
                    setNewUsersOnboarding={setNewUsersOnboarding}
                    newUsersOnboardingAdmins={newUsersOnboardingAdmins}
                    setNewUsersOnboardingAdmins={setNewUsersOnboardingAdmins}
                    usersCompletedTrainingAdmin={usersCompletedTrainingAdmin}
                    setUsersCompletedTrainingAdmin={
                      setUsersCompletedTrainingAdmin
                    }
                    usersAssignedTrainingAdmin={usersAssignedTrainingAdmin}
                    setUsersAssignedTrainingAdmin={
                      setUsersAssignedTrainingAdmin
                    }
                    weeklyReport={weeklyReport}
                    setWeeklyReport={setWeeklyReport}
                    overdueTrainingReminder={overdueTrainingReminder}
                    setOverdueTrainingReminder={setOverdueTrainingReminder}
                    notificationSchedule={notificationSchedule}
                    onNotificationScheduleUpdate={
                      handleOnNotificationScheduleUpdate
                    }
                    triggerLoadNotificationSchedule={
                      triggerLoadNotificationSchedule
                    }
                    phishingFeatureFlagActive={!!featureFlags?.PHISHING}
                    payPlanIncludesPhishing={payPlanIncludesPhishing}
                    phishingMessagesAdmin={phishingMessagesAdmin}
                    setPhishingMessagesAdmin={setPhishingMessagesAdmin}
                    phishingReminderOnReport={phishingReminderOnReport}
                    setPhishingReminderOnReport={setPhishingReminderOnReport}
                  />
                )}

                {selectedView === SETTINGS_VIEWS.INTEGRATIONS && (
                  <IntegrationSettings
                    isAdmin={isAuthenticated.is_admin}
                    apiKeys={apiKeys}
                    selectedApiKey={selectedApiKey}
                    setSelectedApiKey={setSelectedApiKey}
                    payPlanIncludesAPIAccess={payPlanIncludesAPIAccess}
                    payPlanIncludesHRIS={payPlanIncludesHRIS}
                    vantaOAuthLink={vantaOAuthLink}
                    isVantaInstalled={isVantaInstalled}
                    hasDocebo={hasDocebo}
                    hasSeismic={hasSeismic}
                    showDeleteApiKeyModal={showDeleteApiKeyModal}
                    setShowDeleteApiKeyModal={setShowDeleteApiKeyModal}
                    handleOnDeleteApiKeyModalSubmit={
                      handleOnDeleteApiKeyModalSubmit
                    }
                    showGenerateApiKeyModal={showGenerateApiKeyModal}
                    setIsLoading={setIsLoading}
                    isGoogleWorkspaceIntegrated={isGoogleWorkspaceIntegrated}
                    autoSyncGoogleUsers={autoSyncGoogleUsers}
                    setAutoSyncGoogleUsers={setAutoSyncGoogleUsers}
                    handleOnGenerateApiKeyButtonClick={
                      handleOnGenerateApiKeyButtonClick
                    }
                    handleOnGenerateApiKeyModalClose={
                      handleOnGenerateApiKeyModalClose
                    }
                  />
                )}
              </Fragment>
            )}
          </main>
        </div>
      </div>
    </div>
  );
}
