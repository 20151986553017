import TrainingAttemptResults from './TrainingAttemptResults';
import AssessmentAttemptsResults from './AssessmentAttemptsResults';

const ListEmployeeCurriculumItemAttempts = ({
  curriculumContent,
  attempts,
  assessmentsAttemptsData,
  isLoadingData,
  selectedTrainingAttempt,
  setSelectedTrainingAttempt,
  setSelecteItemAttempt,
  isTheFirstTrainingAttemptSelected,
}) => {
  const lessonItems = curriculumContent.filter(
    (item) => item.type === 'Lesson'
  );
  const assessmentsItems = curriculumContent.filter(
    (item) => item.type === 'Quiz'
  );

  const selectAttemptHandler = (attempt) => {
    setSelecteItemAttempt(attempt);
  };

  return (
    <section>
      {lessonItems.length > 0 && (
        <TrainingAttemptResults
          attempts={attempts}
          setSelectedTrainingAttempt={setSelectedTrainingAttempt}
          selectedTrainingAttempt={selectedTrainingAttempt}
          lessonItems={lessonItems}
          isLoadingData={isLoadingData}
          selectAttemptHandler={selectAttemptHandler}
        />
      )}

      {isTheFirstTrainingAttemptSelected &&
        assessmentsItems.length > 0 &&
        !assessmentsAttemptsData.some((i) => i.isLoading) &&
        assessmentsItems.map((assessmentItem) => {
          let assessmentAttempts = assessmentsAttemptsData.find(
            (ad) => ad?.assessmentID === assessmentItem.value.id
          );
          assessmentAttempts = assessmentAttempts.data.filter(
            (ad) => ad.training_attempt_id === selectedTrainingAttempt?.data.id
          );
          assessmentAttempts = assessmentAttempts.sort(
            (a, b) => new Date(a.finish_date) - new Date(b.finish_date)
          );
          assessmentAttempts = assessmentAttempts.map((attempt, index) => ({
            label: `Attempt ${index + 1} ${
              !attempt.finish_date ? '(In Progress)' : ''
            }`,
            value: attempt.id,
            data: attempt,
          }));

          return (
            <AssessmentAttemptsResults
              key={assessmentItem.value.id}
              assessmentItem={assessmentItem}
              isLoadingData={isLoadingData}
              selectAttemptHandler={selectAttemptHandler}
              assessmentAttempts={assessmentAttempts}
            />
          );
        })}

      {!isTheFirstTrainingAttemptSelected && assessmentsItems.length > 0 && (
        <section className='mt-3 mb-2 flex flex-col gap-1'>
          <p>
            There were no quiz attempts within the selected training attempt
          </p>
          <p>
            Users are allowed to attempt quizzes in the first training attempt
            only.
          </p>
        </section>
      )}
    </section>
  );
};

export default ListEmployeeCurriculumItemAttempts;
