import { useQuery } from '@tanstack/react-query';
import { getStreamPosts } from '../../../../api/streamsApi';

export const useStreamPostsData = ({
  comapnyID,
  streamID,
  enabled,
  sent = false,
  page,
}) => {
  return useQuery({
    queryKey: ['streams', 'posts', comapnyID, streamID, sent, page],
    queryFn: () => getStreamPosts({ comapnyID, streamID, sent, page }),
    enabled,
  });
};
