import { useEffect, useState } from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';

import {
  formatOptionLabel,
  isCorrectOptions,
} from '../../../utils/commonUtils';
import RemoveAnswerConfirmationModal from './confirmationModals/RemoveAnswerConfirmationModal';
import AnswerExplanation from '../../AnswerExplanation/AnswerExplanation';

const EngagementMessageAnswer = ({
  answer,
  answerType,
  setAnswers,
  handle,
  isReadOnly,
}) => {
  const [isCorrect, setIsCorrect] = useState(
    isCorrectOptions.find((option) => option.correct === answer.correct)
  );
  const [showDeleteAnswerModal, setShowDeleteAnswerModal] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const answerDeleteHandler = async () => {
    setAnswers((answers) => answers.filter((a) => a.id !== answer.id));
    setShowDeleteAnswerModal(false);
  };

  const deleteAnswerExplanationHandler = async () => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((a) => {
        if (a.id === answer.id) {
          return { ...a, explanation: null };
        }
        return a;
      })
    );
  };

  const onAnswerUpdate = (answer, field, value) => {
    setAnswers((answers) =>
      answers.map((a) => (a.id === answer.id ? { ...a, [field]: value } : a))
    );
  };

  const correctnessChangedHandler = (value) => {
    onAnswerUpdate(answer, 'correct', value.value === 'correct');
    setIsCorrect(value);
  };

  return (
    <div>
      <div className='engagement__answer'>
        <div className='answer__drag-drop'>{handle}</div>
        <div className='answer__input-container'>
          <input
            maxLength={150}
            type='text'
            placeholder='Enter answer'
            className={`input--engagement-answer ${
              150 - answer.answer.length <= 30 ? 'border-none' : ''
            }`}
            value={answer.answer}
            onChange={(e) => onAnswerUpdate(answer, 'answer', e.target.value)}
            disabled={isReadOnly}
          />
          {150 - answer.answer.length <= 30 && (
            <p
              className={`text--warning ${
                150 - answer.answer.length <= 30 ? '' : ''
              }`}
            >
              <span className='font-basis-bold'>
                {150 - answer.answer.length}
              </span>{' '}
              characters remaining
            </p>
          )}
        </div>

        {answerType === 'multiple_choice' && (
          <Select
            onChange={correctnessChangedHandler}
            defaultValue={{
              value: 'correct',
              label: '',
            }}
            value={isCorrect}
            isMulti={false}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                width: '12rem',
                right: 0,
              }),
            }}
            options={isCorrectOptions}
            classNamePrefix='dropdown--set-correct-incorrect select'
            formatOptionLabel={formatOptionLabel}
            unstyled
            menuPortalTarget={document.body}
            maxMenuHeight='17rem'
            menuPlacement='auto'
            isDisabled={isReadOnly}
          />
        )}
        <button
          className='button button--icon icon-trash text-hka_gray'
          onClick={() => setShowDeleteAnswerModal(true)}
          disabled={isReadOnly}
        ></button>
        {showDeleteAnswerModal && (
          <RemoveAnswerConfirmationModal
            showModal={showDeleteAnswerModal}
            onSubmit={answerDeleteHandler}
            onClose={() => setShowDeleteAnswerModal(false)}
          />
        )}
      </div>
      <AnswerExplanation
        answer={answer}
        editExplanationHandler={(e) => {
          onAnswerUpdate(answer, 'explanation', e.target.value);
        }}
        removeExplanationHandler={deleteAnswerExplanationHandler}
        isReadOnly={isReadOnly}
      />
    </div>
  );
};

export default EngagementMessageAnswer;
