import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import TRAINING_RENEW_OPTIONS from '../utils/renewOptions';
import { useAppContext } from '../libs/contextLib';
import { onError } from '../libs/errorLib';
import * as api from '../api';
import Modal from './Modal/Modal';
import Loader from './Loader';
import { formatDaysTillDue } from '../utils/formatTrainingDate';
import useDebounce from '../utils/custom-hooks/useDebounce';
import TrainingTagInput from './TrainingTag/TrainingTagInput';
import { formatTrainingEndDate } from '../utils/formatTrainingEndDate';
import { format } from 'date-fns';
import RenewTrainingDatePicker from './RenewTrainingDatePicker';
import addMonths from 'date-fns/addMonths';
import RenewTrainingSection from './RenewTrainingSection';
import { convertRenewalPeriodToText } from '../utils/training';
import DoceboInput from './Training/DoceboInput';
import ShowCorrectnessIndicatorOnResults from './Training/ShowCorrectnessIndicatorOnResults';
import OwnersInput from './Base/OwnersInput';
import { useTrainingsTagsData } from '../hooks/data-hooks/trainings';
import { useQueryClient } from '@tanstack/react-query';
import SeismicInput from './Training/SeismicInput';

export const DOCEBO_MANAGERS_NOTIFICATION_OPTIONS = [
  {
    label: 'Daily',
    value: 'daily',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
];

const EditTrainingModal = ({
  showModal,
  onModalHide,
  setTriggerGetTraining,
  isHaekkaOwner,
  isCustomContent,
  training,
  setRenewedTrainingId,
  renewedTrainingId,
  onDeleteSuccess,
}) => {
  const { id } = useParams();
  const { isAuthenticated } = useAppContext();
  const queryClient = useQueryClient();
  const [passingGrade, setPassingGrade] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [renewCycle, setRenewCycle] = useState(null);
  const [daysTillDue, setDaysTillDue] = useState('');
  const [title, setTitle] = useState();
  const [subtitle, setSubtitle] = useState();
  const [isTitleAvailable, setIsTitleAvailable] = useState(true);
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [hasBranchingScenario, setHasBranchingScenario] = useState(false);
  const [companySuggestions, setCompanySuggestion] = useState([]);
  const [renewDate, setRenewDate] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [isAvailableForFree, setIsAvailableForFree] = useState(false);
  const [owners, setOwners] = useState(
    training.owners?.map((o) => ({ value: o.id, label: o.name }))
  );
  const [renewOnTheSameDayForEveryone, setRenewOnTheSameDayForEveryone] =
    useState(true);
  const [doceboTraining, setDoceboTraining] = useState('');
  const [seismicContent, setSeismicContent] = useState('');
  const [doceboManagerNotifications, setDoceboManagerNotifications] =
    useState(true);
  const [
    doceboManagerNotificationsSchedule,
    setDoceboManagerNotificationsSchedule,
  ] = useState(undefined);
  const [
    removeAssigmentsWhenRemovingDocebo,
    setRemoveAssigmentsWhenRemovingDocebo,
  ] = useState(true);
  const [
    removeAssigmentsWhenRemovingSeismic,
    setRemoveAssigmentsWhenRemovingSeismic,
  ] = useState(false);
  const [
    showCorrectnessIndicatorOnResults,
    setShowCorrectnessIndicatorOnResults,
  ] = useState(false);

  const tresholdOptions = [0, 70, 80, 90, 100];

  const { data: trainingTags } = useTrainingsTagsData({
    companyID: isAuthenticated.company,
    enabled: isAuthenticated.is_admin,
  });

  useEffect(() => {
    if (!trainingTags) return;
    if (companySuggestions.length === 0 && suggestions) {
      setCompanySuggestion([...trainingTags.results]);
    }
    const filteredSug = trainingTags.results.filter((s) => {
      const c = training.tags.find((t) => t.name === s.name);
      if (!c) return s;
      else return null;
    });
    setSuggestions(
      filteredSug.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [training.tags, trainingTags]);

  useEffect(() => {
    async function onLoad() {
      try {
        const {
          passing_grade: passingGrade,
          renew_cycle: renewCycle,
          days_till_due: daysTillDue,
        } = training;
        setPassingGrade(passingGrade);
        setRenewCycle(renewCycle);
        setDaysTillDue(daysTillDue);
        setTags(training.tags);
        setTitle(training.curriculum_detail.title);
        setSubtitle(training.curriculum_detail.subtitle);
        setIsEditable(training.curriculum_detail.is_editable);
        setIsAvailableForFree(training.curriculum_detail.is_available_for_free);
        setRenewOnTheSameDayForEveryone(!training.obey_assignment_dates);
        setShowCorrectnessIndicatorOnResults(
          training.show_correctness_indicator_on_results
        );
        if (training.docebo_course) {
          setDoceboTraining({
            label: training.docebo_course.title,
            value: training.docebo_course.course_id,
            rawData: {
              code: training.docebo_course.code,
              id: training.docebo_course.course_id,
              title: training.docebo_course.title,
              type: training.docebo_course.type,
              uidCourse: training.docebo_course.uidCourse,
            },
          });
          setDoceboManagerNotifications(
            training.docebo_course.docebo_manager_notifications
              ?.is_over_due_on ?? true
          );
          setDoceboManagerNotificationsSchedule(
            training.docebo_course.docebo_manager_notifications
              ? DOCEBO_MANAGERS_NOTIFICATION_OPTIONS.find(
                  (i) =>
                    i.value ===
                    training.docebo_course.docebo_manager_notifications
                      .over_due_schedule
                )
              : DOCEBO_MANAGERS_NOTIFICATION_OPTIONS[0]
          );
        }
        if (training.seismic_content) {
          setSeismicContent({
            label: training.seismic_content.title,
            value: training.seismic_content.content_id,
            rawData: {
              id: training.seismic_content.id,
              content_id: training.seismic_content.content_id,
              resource_type: training.seismic_content.resource_type,
              title: training.seismic_content.title,
              is_certification: training.seismic_content.is_certification,
            },
          });
        }
        setHasBranchingScenario(
          training.curriculum_detail.action_id === 'branching'
        );
        setRenewDate(
          training.renew_cycle === 'none'
            ? format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
            : training.renew_date
        );
      } catch (e) {
        onError(e);
      }
    }
    if (showModal) {
      onLoad();
    }
  }, [id, showModal]);

  useEffect(() => {
    const filteredSug = trainingTags?.results.filter((s) => {
      const c = tags.find((t) => t.name === s.name);
      if (!c) return s;
      else return null;
    });
    if (!filteredSug) return;
    setSuggestions(
      filteredSug.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    );
  }, [tags]);

  async function handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    try {
      await api.updateTraining({
        auth: isAuthenticated,
        updateData: {
          renew_cycle: renewCycle,
          renew_date: format(new Date(renewDate), 'yyyy-MM-dd'),
          passing_grade: passingGrade,
          days_till_due: daysTillDue,
          start_date: training.start_date,
          title,
          subtitle,
          tags,
          action_id: hasBranchingScenario ? 'branching' : 'lesson_submit',
          is_editable: isEditable,
          is_available_for_free: isAvailableForFree,
          owners: owners.map((o) => o.value),
          obey_assignment_dates: !renewOnTheSameDayForEveryone,
          docebo_course: doceboTraining?.rawData ?? undefined,
          docebo_manager_notifications: doceboTraining
            ? {
                is_over_due_on: doceboManagerNotifications,
                over_due_schedule: doceboManagerNotificationsSchedule.value,
              }
            : undefined,
          unassign_docebo_users: doceboTraining?.rawData
            ? undefined
            : removeAssigmentsWhenRemovingDocebo,
          show_correctness_indicator_on_results:
            showCorrectnessIndicatorOnResults,
          seismic_content: seismicContent?.rawData ?? undefined,
          unassign_seismic_users: seismicContent?.rawData
            ? undefined
            : removeAssigmentsWhenRemovingSeismic,
          removeAssigmentsWhenRemovingSeismic,
        },
        trainingId: id,
      });
      toast.success(`Training updated`);
      setTriggerGetTraining();
      onModalHide();
      queryClient.invalidateQueries(['trainings-tags']);
    } catch (e) {
      onError(e);
    }
  }

  function handleDeletingTraining() {
    if (isDeleting) {
      setIsDeleting(false);
    } else {
      setIsDeleting(true);
    }
  }

  async function handleDeleteTraining(event) {
    event.preventDefault();
    try {
      await api.deleteTraining({
        auth: isAuthenticated,
        trainingId: id,
      });
      toast.success(`Training deleted`);
      setIsDeleting(false);
      onModalHide();
      onDeleteSuccess();
      navigate('/trainings');
    } catch (e) {
      onError(e);
    }
  }

  const debouncedTitle = useDebounce(title, 300);

  useEffect(() => {
    const getCurriculumsByTitle = async () => {
      try {
        const { results } = await api.getCompanyCurriculumByTitle({
          auth: isAuthenticated,
          title,
        });
        const isAvailable = results.every((curriculum) => {
          if (
            curriculum.company != null &&
            curriculum.title === title.trim() &&
            // TO-DO: bring this back with HKA-700
            // curriculum.curriculum_type ===
            //   training.curriculum_detail.curriculum_type_details.id &&
            curriculum.is_technical === training.curriculum_detail.is_technical
          ) {
            return false;
          } else {
            return true;
          }
        });
        if (training && title === training.curriculum_detail.title) {
          setIsTitleAvailable(true);
        } else {
          setIsTitleAvailable(isAvailable);
        }
      } catch (error) {
        onError(error);
      }
    };
    getCurriculumsByTitle();
  }, [debouncedTitle]);

  const handleUpdateSuggestions = (suggestion) => {
    if (companySuggestions.find((s) => s.name === suggestion.name)) {
      setSuggestions(
        [...suggestions, suggestion].sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        )
      );
    }
  };

  if (training === null) return <Loader />;

  return (
    <Modal
      show={showModal}
      title='Training Settings'
      onClose={onModalHide}
      onSubmit={handleSubmit}
      submitButtonText='Save Changes'
      submitValid={!renewedTrainingId && isTitleAvailable}
      expandable
    >
      <section>
        {!isHaekkaOwner && (
          <>
            <section className='mb-2'>
              <h4 className='mb-0.5 font-basis-bold'>
                Training Title{' '}
                <span className='text-sm text-hka_gray'>(Required)</span>
              </h4>
              <input
                type='text'
                name='days_till_due'
                value={title}
                className={`input ${
                  !isTitleAvailable
                    ? 'text-red-500 bg-red-50 border-red-500 border p-1'
                    : 'text-hka_gray-dark border-hka_gray-lights hka-input mb-1'
                }`}
                onChange={(e) => setTitle(e.target.value)}
              />
              <h5>
                {!isTitleAvailable && (
                  <span className='text-red-500'>
                    Training named {title} already exists.
                  </span>
                )}
              </h5>
            </section>
            <section className='mb-2'>
              <h4 className='mb-0.5 font-basis-bold'>
                Training Sub-Title{' '}
                <span className='text-sm text-hka_gray'>(Optional)</span>
              </h4>
              <input
                type='text'
                name='days_till_due'
                value={subtitle}
                className={`w-full h-4 pl-1 pr-1 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray-dark border-hka_gray-lights hka-input mb-1`}
                onChange={(e) => setSubtitle(e.target.value)}
              />
            </section>
          </>
        )}
        <section className='mb-2'>
          <h4 className='font-basis-bold'>
            Days Until Due{' '}
            <span className='text-sm text-hka_gray'>(Optional)</span>
          </h4>
          <p className='mb-1 text-sm'>
            Changing Days Until Due for this training will take effect on future
            employee training assignments.
          </p>
          <input
            type='number'
            name='days_till_due'
            value={formatDaysTillDue(daysTillDue)}
            className='w-full h-4 pl-1 pr-1 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray'
            onChange={(e) => setDaysTillDue(e.target.value)}
            autoComplete={'off'}
            onWheel={(event) => event.currentTarget.blur()}
            min={1}
          />
        </section>
        <section className='mb-2'>
          <OwnersInput
            owners={owners}
            setOwners={setOwners}
            instanceOf={'training'}
          />
        </section>
        <section className='w-full mb-2'>
          <h4 className='mb-0.5 font-basis-bold'>
            Renew Options{' '}
            <span className='text-sm text-hka_gray'>(Optional)</span>
          </h4>
          <select
            value={renewCycle}
            onChange={(e) => {
              setRenewCycle(e.target.value);
              setRenewDate(
                e.target.value === 'none'
                  ? new Date(training.start_date)
                  : renewCycle === 'none'
                  ? format(
                      addMonths(
                        new Date(renewDate),
                        e.target.value === 'quarterly'
                          ? 3
                          : e.target.value === 'semi-annual'
                          ? 6
                          : e.target.value === 'annual'
                          ? 12
                          : 0
                      ),
                      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                    )
                  : format(new Date(renewDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
              );
            }}
          >
            {TRAINING_RENEW_OPTIONS.map((renewOption) => (
              <option key={renewOption.id} value={renewOption.id}>
                {renewOption.title}
              </option>
            ))}
          </select>
          <p className='text-sm my-0.5 ml-0.1'>
            {formatTrainingEndDate(
              renewDate ? renewDate : training.start_date,
              renewCycle,
              training.end_date,
              daysTillDue
            )}
          </p>

          {!renewOnTheSameDayForEveryone &&
            renewCycle !== 'none' &&
            training.renew_cycle !== renewCycle && (
              <div>
                <p className='text-sm text-red-500'>
                  Please note: Adjusting the training renewal by assignment date
                  may cause some to be overdue. Our system will address and
                  correct any impacted assignments promptly.
                </p>
              </div>
            )}

          {renewCycle !== 'none' && (
            <div className='flex justify-between my-3'>
              <div>
                <section className='mb-1.5 flex'>
                  <section className='flex items-center'>
                    <label className='switch'>
                      <input
                        type='checkbox'
                        name='has_weekly_quiz'
                        checked={renewOnTheSameDayForEveryone}
                        onChange={() =>
                          setRenewOnTheSameDayForEveryone((p) => !p)
                        }
                        className='cursor-pointer'
                      />
                      <span className='slider'></span>
                    </label>
                  </section>
                  <p className='ml-1 text-sm'>
                    This training will renew for everyone on the same date.
                  </p>
                </section>
                <section className='flex'>
                  <section className='flex items-center'>
                    <label className='switch'>
                      <input
                        type='checkbox'
                        name='has_weekly_quiz'
                        checked={!renewOnTheSameDayForEveryone}
                        onChange={() =>
                          setRenewOnTheSameDayForEveryone((p) => !p)
                        }
                        className='cursor-pointer'
                      />
                      <span className='slider'></span>
                    </label>
                  </section>
                  <div className='flex flex-col'>
                    <p className='ml-1 text-sm'>
                      This training will renew{' '}
                      {convertRenewalPeriodToText(renewCycle)} months from when
                      an individual is assigned.
                    </p>
                    {!training.obey_assignment_dates &&
                      !renewOnTheSameDayForEveryone && (
                        <div>
                          <p className='ml-1 text-sm text-red-500'>
                            Please note: Activating this feature for ongoing
                            training will immediately re-assign any employees
                            who haven't completed their training in the last{' '}
                            {convertRenewalPeriodToText(training.renew_cycle)} .
                          </p>
                        </div>
                      )}
                  </div>
                </section>
              </div>
              <RenewTrainingDatePicker
                renewDate={renewDate}
                renewCycle={renewCycle}
                setRenewDate={(date) =>
                  setRenewDate(format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"))
                }
                renewOnTheSameDayForEveryone={renewOnTheSameDayForEveryone}
              />
            </div>
          )}
        </section>
        <section className='w-full my-2'>
          <h4 className='font-basis-bold pb-1'>Grading Threshold</h4>
          <select
            className=''
            value={passingGrade}
            onChange={(e) => setPassingGrade(e.target.value)}
          >
            {tresholdOptions.map((tresholdValue) => (
              <option
                key={tresholdValue}
                value={tresholdValue}
                title={tresholdValue}
              >
                {tresholdValue === 0 ? 'No treshold' : `${tresholdValue}%`}
              </option>
            ))}
          </select>
        </section>
        <section className='w-full mb-2'>
          <h4 className='mb-0.5 font-basis-bold'>
            Training Tags{' '}
            <span className='text-sm text-hka_gray'>(Optional)</span>
          </h4>
          <TrainingTagInput
            tags={tags}
            setTags={setTags}
            suggestions={suggestions}
            updateSuggestions={handleUpdateSuggestions}
          />
        </section>
        {isAuthenticated?.company_details.has_docebo &&
          !!training.curriculum_detail.company && (
            <DoceboInput
              doceboCourse={doceboTraining}
              setDoceboCourse={setDoceboTraining}
              doceboManagerNotifications={doceboManagerNotifications}
              setDoceboManagerNotifications={setDoceboManagerNotifications}
              doceboManagerNotificationsSchedule={
                doceboManagerNotificationsSchedule
              }
              setDoceboManagerNotificationsSchedule={
                setDoceboManagerNotificationsSchedule
              }
              removeAssigmentsWhenRemovingDocebo={
                removeAssigmentsWhenRemovingDocebo
              }
              setRemoveAssigmentsWhenRemovingDocebo={
                setRemoveAssigmentsWhenRemovingDocebo
              }
            />
          )}

        {isAuthenticated?.company_details.has_seismic &&
          !!training.curriculum_detail.company && (
            <SeismicInput
              seismicContent={seismicContent}
              setSeismicContent={setSeismicContent}
              removeAssigmentsWhenRemovingSeismic={
                removeAssigmentsWhenRemovingSeismic
              }
              setRemoveAssigmentsWhenRemovingSeismic={
                setRemoveAssigmentsWhenRemovingSeismic
              }
            />
          )}
        {/* {!isHaekkaOwner && isCustomContent && (
          <section className="mb-2">
            <h4 className="font-basis-bold">Branching scenarios?</h4>
            <p className="mb-1 text-sm">
              Branching scenarios allow you to choose the lesson that each
              answer leads to.
            </p>
            <section className="flex items-center">
              <label className="switch">
                <input
                  type="checkbox"
                  name="has_weekly_quiz"
                  checked={hasBranchingScenario}
                  onChange={() => setHasBranchingScenario((p) => !p)}
                  className="cursor-pointer"
                />
                <span className="slider"></span>
              </label>
            </section>
          </section>
        )} */}
        {training.renew_cycle !== 'none' && (
          <RenewTrainingSection
            training={training}
            onRenewSuccess={(newTrainingId) =>
              setRenewedTrainingId(newTrainingId)
            }
          />
        )}
        <ShowCorrectnessIndicatorOnResults
          showCorrectnessIndicatorOnResults={showCorrectnessIndicatorOnResults}
          setShowCorrectnessIndicatorOnResults={
            setShowCorrectnessIndicatorOnResults
          }
        />
        <section className='w-full'>
          <h4 className='mb-1 font-basis-bold'>Delete Training</h4>
          <button
            onClick={handleDeletingTraining}
            className={
              'button button--remove w-full sm:w-auto ' +
              (isDeleting ? 'hidden' : 'visible')
            }
          >
            Delete This Training
          </button>
          {isDeleting && (
            <>
              <p className='mb-1'>
                Are you sure you want to delete this training?
              </p>
              <button
                onClick={handleDeleteTraining}
                className='w-full button button--remove sm:w-auto'
              >
                Yes, I'm sure
              </button>
            </>
          )}
        </section>
      </section>
    </Modal>
  );
};

export default EditTrainingModal;
