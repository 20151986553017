import React from "react";
import ActionButton from "../ActionButton";

export default function UpgradePlanToIncludePhishing() {
  const onUpgradeButtonClick = () => {
    const url = "https://www.haekka.com/billing-support";
    window.open(url, "_blank");
  };
  return (
    <div className="space-y-2 md:mr-8">
      <h1 className="mb-4">Phishing</h1>
      <div className="flex flex-col items-center justify-center">
        <img
          alt="Upgrade"
          src="https://haekka-product-assets.s3.us-west-2.amazonaws.com/upgrade_phishing.svg"
          className="mb-2 ml-5"
        />
        <p>
          Your plan does not include access to{" "}
          <span className="font-basis-bold">Phishing</span>.
        </p>
        <p className="mb-2">
          Please contact our sales team to upgrade your account.
        </p>
        <ActionButton type="primary" onClick={onUpgradeButtonClick}>
          <span className="px-2">Upgrade your Plan</span>
        </ActionButton>
      </div>
    </div>
  );
}
