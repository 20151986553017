import React, { useEffect, useMemo, useState } from "react";
import Modal from "../../../../../Modal/Modal";
import TestRow, { ROW_TYPE } from "./TestRow";
import Note from "../../../../../Note";
import { testPhishingIMAPConfiguration } from "../../../../../../api/phishing";
import { onError } from "../../../../../../libs/errorLib";

function StatusNote({ status }) {
  const statusToText = {
    loading: "Running the IMAP configuration test. Please stand by.",
    success: "IMAP settings are valid!",
    danger:
      "IMAP settings are invalid, please check your settings and try again.",
  };

  return (
    <Note type={status}>
      <span className="text-base">{statusToText[status]}</span>
    </Note>
  );
}

export default function TestIMAPConfigurationModal({
  showModal,
  onSubmit,
  onClose,
  imapConfiguration,
}) {
  const [loading, setLoading] = useState(false);
  const [testResponse, setTestResponse] = useState(null);
  const [isSubmitValid, setIsSubmitValid] = useState(false);

  const statusNoteData = useMemo(() => {
    if (loading) {
      return "loading";
    }

    if (testResponse) {
      const { imapConfigResult, imapBindingResults } = testResponse;

      if (!imapConfigResult) {
        return "danger";
      } else {
        const allBindingsValid = imapBindingResults.reduce(
          (acc, imapBindingResult) => {
            return acc && imapBindingResult.status;
          },
          true
        );

        if (allBindingsValid) {
          return "success";
        } else {
          return "danger";
        }
      }
    }
  }, [loading, testResponse]);

  useEffect(
    () => setIsSubmitValid(statusNoteData === "success"),
    [statusNoteData]
  );

  useEffect(() => {
    const onModalOpen = async () => {
      try {
        setLoading(true);

        const response = await testPhishingIMAPConfiguration(imapConfiguration);
        setTestResponse(response);
      } catch (error) {
        onError(error);
      } finally {
        setLoading(false);
      }
    };

    if (showModal && imapConfiguration) {
      onModalOpen();
    }

    return () => {
      setTestResponse(null);
    };
  }, [imapConfiguration, showModal]);

  return (
    <Modal
      compact
      submitValid={isSubmitValid}
      show={showModal}
      onClose={onClose}
      title="Test IMAP Configuration"
      submitButtonText={"Save IMAP Configuration"}
      onSubmit={() => {
        onSubmit();
        onClose();
      }}
    >
      {showModal && (
        <div className="modal-body">
          <TestRow
            type={ROW_TYPE.CONFIG}
            value={{
              host: imapConfiguration.host,
              port: imapConfiguration.port,
            }}
            loading={loading}
            status={testResponse?.imapConfigResult}
          />
          {imapConfiguration.bindings.map((binding, index) => (
            <TestRow
              key={index}
              type={ROW_TYPE.BINDING}
              value={binding}
              loading={loading}
              status={
                testResponse?.imapBindingResults[index]?.status ?? undefined
              }
            />
          ))}
          <StatusNote status={statusNoteData} />
        </div>
      )}
    </Modal>
  );
}
