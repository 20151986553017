import api from "../../client";
import { getWistiaRefreshToken } from "../../api";
import { useEffect, useState } from "react";

const getExpiringToken = async (auth) => {
  const { id: expiringToken } = await getWistiaRefreshToken({
    auth,
    type: "crud_anything",
  });

  return expiringToken;
};

const loadCaptions = async (token, mediaID) => {
  const { data: captions } = await api.get(
    `https://api.wistia.com/v1/medias/${mediaID}/captions.json`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return captions;
};

const uploadSrtFileHandler = async ({ file, language, token, mediaID }) => {
  const formData = new FormData();
  formData.append("caption_file", file, file.name);
  formData.append("language", language);

  await api.post(
    `https://api.wistia.com/v1/medias/${mediaID}/captions.json`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return await loadCaptions(token, mediaID);
};

const deleteSrtFileHandler = async ({ language, token, mediaID }) => {
  return await api.delete(
    `https://api.wistia.com/v1/medias/${mediaID}/captions/${language}.json`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const showSrtFileHandler = async ({ language, token, mediaID }) => {
  return await api.get(
    `https://api.wistia.com/v1/medias/${mediaID}/captions/${language}.json`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const checkCustomizations = async ({ token, mediaID }) => {
  const { data: customizations } = await api.get(
    `https://api.wistia.com/v1/medias/${mediaID}/customizations.json`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return customizations;
};

const updateCustomizationsHandler = async ({ token, mediaID, body }) => {
  const { data: customizations } = await api.put(
    `https://api.wistia.com/v1/medias/${mediaID}/customizations.json`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return customizations;
};

const useVideoCaptions = ({ auth, mediaID }) => {
  const [loading, setLoading] = useState(true);
  const [captions, setCaptions] = useState([]);
  const [token, setToken] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [captionsPlugin, setCaptionsPlugin] = useState(undefined);

  const uploadSrtFile = async ({ file, language }) => {
    setError(undefined);

    try {
      const captions = await uploadSrtFileHandler({
        file,
        language,
        token,
        mediaID,
      });
      setCaptions(captions);
    } catch (error) {
      let errorMsg =
        "Wistia rejected your request. Make sure that you are using the correct three-character code for the language and try again. If the issue persist, contact us!";
      if (!!error.response.data) {
        errorMsg = error.response.data;
      }
      setError(errorMsg);
    }
  };

  const deleteSrtFile = async ({ language }) => {
    try {
      await deleteSrtFileHandler({
        language,
        token,
        mediaID,
      });
      setCaptions((captions) =>
        captions.filter((cap) => cap.language !== language)
      );
    } catch (error) {
      throw error;
    }
  };

  const showSrtFile = async ({ language }) => {
    try {
      return await showSrtFileHandler({
        language,
        token,
        mediaID,
      });
    } catch (error) {
      throw error;
    }
  };

  const updateCustomizations = async ({ body }) => {
    try {
      return await updateCustomizationsHandler({ token, mediaID, body });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const init = async () => {
      const expiringToken = await getExpiringToken(auth);
      setToken(expiringToken);
      const captions = await loadCaptions(expiringToken, mediaID);
      const customizations = await checkCustomizations({
        token: expiringToken,
        mediaID,
      });

      const capPlugin = customizations?.plugin
        ? customizations.plugin["captions-v1"]
        : undefined;
      setCaptionsPlugin(capPlugin);
      setCaptions(captions);
      setLoading(false);
    };

    if (auth && mediaID) init();
  }, [auth, mediaID]);

  return {
    loading,
    captions,
    uploadSrtFile,
    showSrtFile,
    deleteSrtFile,
    captionsPlugin,
    updateCustomizations,
    error,
  };
};

export default useVideoCaptions;
