import { useState } from 'react';
import { useAppContext } from '../../../libs/contextLib';
import {
  useExternalAppOAuthLink,
  useRemoveExternalAppSettings,
  useUpdateExternalAppSettings,
} from '../../../hooks/data-hooks/integrations';
import LoaderInline from '../../LoaderInline';
import { useEffect } from 'react';
import Note from '../../Note';

import EnterDoceboConfigurationModal from './EnterDoceboConfigurationModal';
import RemoveExternalAppConfirmModal from './RemoveExternalAppConfirmModal';

const DoceboIntegration = ({ activeDoceboApp, isLoadingExternalApps }) => {
  const { isAuthenticated: auth } = useAppContext();
  const [doceboSubdomainModal, setDoceboSubdomainModal] = useState(false);
  const [subdomain, setSubdomain] = useState(undefined);
  const [clientID, setClientID] = useState(undefined);
  const [secret, setSecret] = useState(undefined);
  const [syncEmployees, setSyncEmployees] = useState(undefined);
  const [changedWarning, setChangeWarning] = useState(false);
  const [removeAppWarning, setRemoveAppWarning] = useState(false);

  useEffect(() => {
    if (!isLoadingExternalApps && !!activeDoceboApp) {
      setSyncEmployees(activeDoceboApp.sync_employees);
    }
  }, [activeDoceboApp, isLoadingExternalApps]);

  const onOAuthLinkFetched = (doceboOAuthLink) => {
    window.open(doceboOAuthLink, '_self');
  };

  const { refetch } = useExternalAppOAuthLink({
    companyID: auth.company,
    integration: 'docebo',
    config: {
      method: 'POST',
      subdomain,
      client_id: clientID,
      secret,
    },
    enabled: false,
    onSuccess: onOAuthLinkFetched,
  });

  const { mutate: updateDoceboInstallation, isLoading: submittingSettings } =
    useUpdateExternalAppSettings({
      resourcetype: 'DoceboInstallation',
      companyID: auth.company,
    });

  const { mutate: removeDoceboInstallation, isLoading: removingDoceboApp } =
    useRemoveExternalAppSettings({
      resourcetype: 'DoceboInstallation',
      integration: 'docebo',
      companyID: auth.company,
    });

  const updateDoceboInstallationHandler = () => {
    updateDoceboInstallation({
      companyID: auth.company,
      integrationID: activeDoceboApp.id,
      settings: {
        sync_employees: syncEmployees,
      },
    });
    setChangeWarning(false);
  };

  const removeDoceboInstallationHandler = () => {
    removeDoceboInstallation({
      companyID: auth.company,
      integrationID: activeDoceboApp.id,
    });
    setRemoveAppWarning(false);
  };

  const syncEmployeesHandler = () => {
    setSyncEmployees((s) => !s);
    setChangeWarning(true);
  };

  if (
    isLoadingExternalApps ||
    (syncEmployees === undefined && !!activeDoceboApp)
  ) {
    return <LoaderInline wrapperClasses='m-3' />;
  }

  return (
    <div className='p-2 mb-4 bg-white shadow-light rounded-xl col-span-full'>
      <h3 className='mb-1 setting__title'>Docebo Integration</h3>
      {!activeDoceboApp && (
        <section className='w-full md:w-max-content mt-2 flex flex-col rounded-md border border-hka_gray-light p-1.5 min-w-20 relative'>
          <div className='h-4 mb-3 overflow-hidden'>
            <img
              src='https://haekka-public-images.s3.us-west-2.amazonaws.com/docebo-logo-vector.svg'
              alt='Docebo Logo'
              className='w-10'
            />
          </div>
          <button
            className='w-full button button--save'
            onClick={() => setDoceboSubdomainModal(true)}
          >
            Link with Docebo
          </button>
        </section>
      )}

      {!!activeDoceboApp && (
        <section>
          <img
            src='https://haekka-public-images.s3.us-west-2.amazonaws.com/docebo-logo-vector.svg'
            alt='Docebo Logo'
            className='w-10 pt-0.5'
          />

          <section className='flex w-full p-2 gap-2 items-start justify-start mt-1'>
            <label className='switch'>
              <input
                type='checkbox'
                name='acknowledge_button'
                checked={syncEmployees}
                onChange={syncEmployeesHandler}
                className='cursor-pointer'
              />
              <span className='slider'></span>
            </label>
            <section className='flex flex-col gap-1'>
              <h4 className='cursor-pointer' onClick={syncEmployeesHandler}>
                Sync Employees
              </h4>
              <p>
                <span className='font-basis-bold'>On by default</span>, Haekka
                will automatically sync users from your Docebo account on a
                daily basis.
              </p>
            </section>
          </section>

          {changedWarning && (
            <section className='mb-2'>
              <Note type='warning'>
                <p className='py-0.2'>
                  Your changes have not been saved. Please click the save button
                  below.
                </p>
              </Note>
            </section>
          )}

          <div className='flex flex-col items-center justify-start w-full gap-1 md:flex-row'>
            <button
              className={`w-full button button--save md:w-auto`}
              onClick={() => {}}
              type='secondary'
              disabled
            >
              Docebo Installed
            </button>

            <button
              className={`w-full button button--remove md:w-auto`}
              onClick={() => setRemoveAppWarning(true)}
              type='danger'
              disabled={removingDoceboApp}
            >
              Disconnect Docebo
            </button>

            <button
              className={`w-full button button--save md:w-auto`}
              onClick={updateDoceboInstallationHandler}
              type='success'
              disabled={submittingSettings}
            >
              Save
            </button>
          </div>
        </section>
      )}

      {doceboSubdomainModal && (
        <EnterDoceboConfigurationModal
          subdomain={subdomain}
          setSubdomain={setSubdomain}
          clientID={clientID}
          setClientID={setClientID}
          secret={secret}
          setSecret={setSecret}
          onSubmit={() => refetch()}
          onClose={() => setDoceboSubdomainModal(false)}
        />
      )}

      {removeAppWarning && (
        <RemoveExternalAppConfirmModal
          onSubmit={removeDoceboInstallationHandler}
          onClose={() => setRemoveAppWarning(false)}
          integrationCaption='Docebo'
        />
      )}
    </div>
  );
};

export default DoceboIntegration;
