import Back from "../Back";

import { formatAvatar } from "../../utils/formatAvatar";

const EmployeeHeader = ({
  employeeDetails,
  roleTitle,
  setShowDeleteEmployeeModal,
  setShowEditRolesModal,
}) => {
  return (
    <section className="mb-4">
      <section className="flex justify-between">
        <Back caption="Employees" />
        {/* Remove Employee */}
        <button
          type="button"
          className="button button--red bg-hka_red-light button__icon--left"
          onClick={() => setShowDeleteEmployeeModal(true)}
        >
          <div className="pl-1.2 sm:px-0 pr-2 flex items-center">
            <span className="icon-trash text-hka_red -mt-0.2"></span>
            <span>Remove Employee</span>
          </div>
        </button>
      </section>

      <section className="flex flex-grow mt-2">
        {employeeDetails.avatar_image ? (
          <img
            className="mr-1 rounded-md h-7 lg:mr-2"
            src={employeeDetails.avatar_image}
            alt={employeeDetails.name}
          />
        ) : (
          formatAvatar({
            name: employeeDetails.name,
            width: 7,
            marginRight: 1,
            textSize: "text-md",
          })
        )}

        {/* Employee name */}
        <div className="w-full">
          <div className="flex mb-1">
            <p className="mr-1 text-md font-basis-medium text-hka_gray-dark">
              {employeeDetails.name}
            </p>
            <p className="text-sm px-1 py-0.5 bg-hka_gray-light rounded-md font-basis-medium text-hka_gray-dark">
              Level {employeeDetails.level}
            </p>
          </div>
          <div className="flex flex-col items-start space-y-1 sm:items-center sm:space-x-3 sm:flex-row sm:space-y-0">
            <div className="flex flex-col lg:w-1/4">
              <p className="text-sm font-basis-medium">Email</p>
              <p className="truncate font-basis-medium text-hka_gray-dark">
                {employeeDetails.email}
              </p>
            </div>

            {/* Role */}
            <div className="flex flex-col lg:w-1/2">
              <p className="text-sm font-basis-medium">Role</p>
              <div className="flex">
                <p className="mr-1 truncate text-base2 font-basis-medium text-hka_gray-dark">
                  {roleTitle}
                </p>
                <button
                  data-tip="Change Role"
                  type="button"
                  className="px-0 button--gray button--sm icon-cog"
                  onClick={() => setShowEditRolesModal(true)}
                ></button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default EmployeeHeader;
