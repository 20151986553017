import React from "react";
import ActionButton from "../../ActionButton";

export default function EmployeeMetrics({
  employeesWithTrainingAssigned,
  totalEmployees,
  isAuthenticated,
}) {
  return (
    <section className="mb-2 space-y-1">
      <header className="flex flex-col lg:items-center lg:justify-between lg:flex-row">
        <h4 className="flex items-center mb-2 lg:mb-0">Employee Summary</h4>
        <ActionButton
          className="w-full sm:w-auto button--blue button"
          to="/employees"
        >
          View Employees
          <span className="icon-arrow right text-hka_blue -mr-0.5 ml-1"></span>
        </ActionButton>
      </header>
      <div className="flex flex-col space-y-2 lg:flex-row lg:space-y-0">
        <div className="flex-1 lg:pr-2 lg:max-w-50">
          <div className="flex h-full p-2 space-x-2 bg-white rounded-xl lg:space-x-4 lg:p-4 shadow-light">
            <div className="flex-1 truncate">
              <p className="mb-1 text-sm truncate">
                Employees with Assigned Training
              </p>
              <div className="flex items-center">
                <p className="mr-1 text-2xl">
                  {employeesWithTrainingAssigned.toLocaleString()}
                </p>
                {/* <p className="text-sm font-basis-bold text-hka_green">+ 1.4%</p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 lg:pl-2 lg:max-w-50">
          <div className="flex h-full p-2 space-x-2 bg-white rounded-xl lg:space-x-4 lg:p-4 shadow-light">
            <div className="flex-1">
              <p className="mb-1 text-sm truncate">
                Employees without Assigned Training
              </p>
              {isAuthenticated.company_details.guest_accounts_included ? (
                <p className="text-2xl">
                  {(
                    totalEmployees - employeesWithTrainingAssigned
                  ).toLocaleString()}
                </p>
              ) : (
                <p className="p-1 text-sm text-yellow-600 border border-yellow-400 rounded-lg bg-yellow-50">
                  {" "}
                  Sorry about that. We cannot display this number because you've
                  excluded guest accounts.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
