import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

import { useAppContext } from '../../../../../libs/contextLib';
import { settingsActions } from '../../../../../store/settingsSlice';
import useInitiateMerge from '../../../../../hooks/utility-hooks/useInitiateMerge';

import LoaderInline from '../../../../LoaderInline';
import IntegrationCard from '../../IntegrationCard';

const Okta = () => {
  const { isAuthenticated } = useAppContext();
  const dispatch = useDispatch();

  const { integrations } = useSelector((state) => state.settings);

  const cb = () => {
    dispatch(
      settingsActions.setIntegrations({
        integrations: { ...integrations, okta: true },
      })
    );
    toast.success('You have successfully connected with Okta.');
  };

  const [isReady, open] = useInitiateMerge({
    isAuthenticated,
    integration: 'okta',
    cb,
  });

  if (!isReady) return <LoaderInline wrapperClasses={'w-20'} loaderSize={3} />;

  return (
    <>
      <IntegrationCard
        integration='okta'
        src='https://haekka-public-images.s3.us-west-2.amazonaws.com/HKA--logo-okta.png'
        alt='Okta Logo'
        imgCssClasses='w-7 mt-0.5'
        btnClick={open}
        connected={integrations?.okta}
      />
    </>
  );
};

export default Okta;
