import React from "react";

export default function PhishingCampaignButton({ icon, text, onClick }) {
  return (
    <button onClick={onClick} className="px-2 button--blue button">
      {/* <span className={`icon-` + icon}></span> */}
      {text}
    </button>
  );
}
