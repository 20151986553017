import { useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';

import Note from '../Note';

const classNamePrefix = 'select';
const className = 'select--select-employees-manually';

const SelectInput = ({
  label = { text: '', optional: false },
  value,
  onChange,
  options = [],
  placeholder = '',
  isDisabled = false,
  isMultiSelect = false,
  isAsync,
  infoMessages,
}) => {
  const [error, setError] = useState({ disabled: false, messages: [] });

  let selectComponent = null;
  const isInputDisabled = isDisabled || error.disabled;

  if (!isAsync) {
    selectComponent = (
      <Select
        value={value}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        isDisabled={isInputDisabled}
        isMulti={isMultiSelect}
        className={className}
        classNamePrefix={classNamePrefix}
        unstyled
      />
    );
  }

  if (!!isAsync) {
    const loadOptions = (inputValue, callback) => {
      isAsync['apiFunction']({
        search_term: inputValue,
      })
        .then((response) => {
          const options = [];
          response[isAsync['responseProperty']].forEach((item) => {
            options.push({
              label: item[isAsync['responseItemName']],
              value: item[isAsync['itemID'] ?? 'id'],
              rawData: item,
            });
          });
          if (isAsync.remoteSearchSupported === false) {
            callback(options.filter((o) => o.label.includes(inputValue)));
            return;
          }
          callback(options);
        })
        .catch((err) => {
          callback(null);
          const message =
            err.response.data.reason ??
            'Something went wrong. Please get in touch with the Haekka administrator for more information.';
          setError({ disabled: true, messages: [message] });
        });
    };

    const loadOptionsDebounced = debounce(loadOptions, 500);

    selectComponent = (
      <AsyncSelect
        value={value}
        onChange={onChange}
        loadOptions={loadOptionsDebounced}
        isDisabled={isInputDisabled}
        placeholder={placeholder}
        isMulti={isMultiSelect}
        cacheOptions
        defaultOptions
        className={className}
        classNamePrefix={classNamePrefix}
        unstyled
      />
    );
  }

  return (
    <section className='mb-3'>
      {!!label.text && (
        <h4 className='mb-1 font-basis-bold'>
          {label.text}{' '}
          {label.optional && <span className='text-hka_gray'>(Optional)</span>}
        </h4>
      )}

      {selectComponent}

      {error.disabled && (
        <section className='mb-2 mt-1'>
          <Note type='danger'>
            {error.messages.map((message) => (
              <p className='py-0.2'>{message}</p>
            ))}
          </Note>
        </section>
      )}

      {infoMessages?.length > 0 && (
        <section className='my-1'>
          {infoMessages
            .filter((message) => message.show)
            .map((message) => {
              let checkedMessage = undefined;
              if (typeof message.message === 'string') {
                checkedMessage = <p>{message.message}</p>;
              } else {
                checkedMessage = message.message;
              }
              return (
                <Note type={message.type} key={message.message}>
                  {checkedMessage}
                </Note>
              );
            })}
        </section>
      )}
    </section>
  );
};

export default SelectInput;
