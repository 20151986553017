import { EditorState, SelectionState } from "draft-js";
import ReactTooltip from "react-tooltip";

const LinkDecorator = ({
  contentState,
  children,
  entityKey,
  start,
  blockKey,
  decoratedText,
  editorStateRef,
  setEditorState,
  setShowPopup,
  setText,
  setUrl,
  readOnly,
}) => {
  return (
    <div
      className="relative inline-block cursor-pointer link--wysiwyg group text-hka_blue font-basis-bold"
      onClick={() => {
        const { url } = contentState.getEntity(entityKey).getData();
        if (readOnly) {
          window.open(url, "_blank");
          return;
        }
        const offset = start;
        let newSelection = new SelectionState({
          anchorKey: blockKey,
          anchorOffset: offset,
          focusKey: blockKey,
          focusOffset: decoratedText.length + offset,
        });
        const nextEditorState = EditorState.forceSelection(
          editorStateRef.current,
          newSelection
        );
        setEditorState(nextEditorState);
        setShowPopup(true);
        setText(decoratedText);
        setUrl(url);
      }}
    >
      <ReactTooltip id="link-tooltip" delayHide={500} delayUpdate={500}>
        <div className="flex items-center justify-center px-0.5 py-0.4 w-full">
          <p
            className="inline-block text-white whitespace-nowrap"
            onClick={(e) => {
              let { url } = contentState.getEntity(entityKey).getData();
              if (!url.startsWith("http")) url = "https://" + url;
              e.stopPropagation();
              window.open(url, "_blank");
            }}
          >
            Visit URL{" "}
          </p>
          <span className="text-white icon-cog"></span>
        </div>
      </ReactTooltip>
      <span data-tip data-for="link-tooltip">
        {children}
      </span>
    </div>
  );
};

export default LinkDecorator;
