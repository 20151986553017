import { useCallback, useEffect, useState } from "react";
import { onError } from "../../libs/errorLib";
import { sleep } from "../sleep";
import queryString from "query-string";
import { getEmployees } from "../../api/employeesApi";

const useFetchEmployees = ({ auth }) => {
  const [trigger, setTrigger] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [employeesImportProgress, setEmployeesImportProgress] = useState(0);

  const doRequest = useCallback(
    (page, page_size) => {
      return getEmployees({
        auth,
        page,
        page_size,
        slackUsersOnly: true
      });
    },
    [auth]
  );

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        let page = 1;
        let page_size = 1500;
        let fetchedEmployees = [];
        let response = null;
        let count = 0;

        let loadMore = true;
        do {
          response = await doRequest(page, page_size);
          count = response.count;
          const next = response.next;
          let params = queryString.parse(
            next?.substring(next.indexOf("?"), next.length)
          );
          page = +params.page;
          page_size = +params.page_size;

          fetchedEmployees = fetchedEmployees.concat(response.results);
          setEmployeesImportProgress(
            ((fetchedEmployees.length / count) * 100).toFixed(0)
          );
          if (response.next) await sleep(100);
          if (!response.next) loadMore = false;
        } while (response && loadMore);

        const options = fetchedEmployees.map((item) => ({
          value: item.id,
          label: item.name,
          avatar: item.avatar_image,
        }));
        setEmployees([...options]);
        setCompleted(true);
      } catch (e) {
        onError(e);
      }
    };
    if (trigger && auth) fetchEmployees();
  }, [doRequest, trigger, auth]);

  return { employees, completed, employeesImportProgress, setTrigger };
};

export default useFetchEmployees;
