// import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import useComponentVisible from "../../utils/custom-hooks/useComponentVisible";

const WysiwygEmojisPopup = (props) => {
  const onInsertEmoji = (emojimark) => {
    props.onInsertEmoji(emojimark);
    setIsComponentVisible(false);
  };

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <div className="slack-emoji">
      <div style={{ position: "relative" }} className={"rdw-inline-wrapper"}>
        <div
          className={"rdw-option-wrapper"}
          onClick={() => setIsComponentVisible(true)}
        >
          <span className="icon-emoji"></span>
        </div>
        {isComponentVisible && (
          <div ref={ref}>
            <Picker
              // showSkinTones={false}
              // showPreview={false}
              autoFocus
              title={""}
              emoji={""}
              style={{
                position: "absolute",
                bottom: "3rem",
                right: "0",
                zIndex: "100",
                width: "32rem",
                overflow: "hidden",
              }}
              set="apple"
              size={48}
              onSelect={(e) => {
                onInsertEmoji(e);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WysiwygEmojisPopup;
