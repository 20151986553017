import { useQuery } from '@tanstack/react-query';
import { getEmployeesInStream } from '../../../../api/streamsApi';

export const useEmployeesInStreamData = ({ streamID, page = 1 }) => {
  return useQuery({
    queryKey: ['employees-in-stream', streamID, page],
    queryFn: () => getEmployeesInStream({ streamID, page }),
    keepPreviousData: true,
  });
};
