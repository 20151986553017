import React, { useState, useEffect } from 'react';
import TRAINING_RENEW_OPTIONS from '../utils/renewOptions';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useAppContext } from '../libs/contextLib';
import { onError } from '../libs/errorLib';
import * as api from '../api';
import Modal from './Modal/Modal';
import StepOneTraining from './StepOneTraining';
import StepThreeConfirm from './StepThreeConfirm';
import UpgradePlanButton from './UpgradePlanButton';
import {
  EVENT_TRACKER,
  internalSlackChannelLogger,
} from '../analytics/eventTracker';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { DOCEBO_MANAGERS_NOTIFICATION_OPTIONS } from './EditTrainingModal';
import { useEmployeesData } from '../hooks/data-hooks/employees/useEmployeesData';
import { useNavigate } from 'react-router-dom';

const CreateTrainingModal = ({
  showModal,
  defaultCurriculum,
  allCurriculums,
  curriculumTypes,
  trainingTags,
  onTrainingCreate,
  onClose,
}) => {
  const { isAuthenticated } = useAppContext();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formState, setFormState] = useState('step_one_training'); // or 'step_two_users' or 'step_three_confirm'

  // form values
  const [curriculum, setCurriculum] = useState(undefined);
  const [curriculumType, setCurriculumType] = useState(undefined);
  const [annual, setAnnual] = useState(false);
  const [dueDate, setDueDate] = useState(null);
  const [renewCycle, setRenewCycle] = useState(TRAINING_RENEW_OPTIONS[0].id);
  const [daysTillDue, setDaysTillDue] = useState('');
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [gradingThreshold, setGradingThreshold] = useState(0);

  const [curriculums, setCurriculums] = useState(null);
  const [customizeTraining, setCustomizeTraining] = useState(false);
  const [calloutMessage, setCalloutMessage] = useState(null);
  const [tags, setTags] = useState([]);
  const [hasBranchingScenario, setHasBranchingScenario] = useState(false);
  const [isThresholdCheked, setIsThresholdCheked] = useState(false);

  const [isTitleCurriculumTechincalValid, setIsTitleCurriculumTechincalValid] =
    useState(true);
  const [companySuggestions, setCompanySuggestion] = useState([]);
  const [renewDate, setRenewDate] = useState(new Date());
  const [suggestions, setSuggestions] = useState(trainingTags || []);
  const [renewOnTheSameDayForEveryone, setRenewOnTheSameDayForEveryone] =
    useState(true);
  const [owners, setOwners] = useState([
    { label: isAuthenticated.name, value: isAuthenticated.id },
  ]);
  const [gradingThresholdDisabled, setGradingThresholdDisabled] =
    useState(false);
  const [doceboTraining, setDoceboTraining] = useState('');
  const [doceboManagerNotifications, setDoceboManagerNotifications] =
    useState(true);
  const [
    doceboManagerNotificationsSchedule,
    setDoceboManagerNotificationsSchedule,
  ] = useState(DOCEBO_MANAGERS_NOTIFICATION_OPTIONS[0]);
  const [seismicContent, setSeismicContent] = useState('');

  const [
    showCorrectnessIndicatorOnResults,
    setShowCorrectnessIndicatorOnResults,
  ] = useState(false);

  const { data: admins } = useEmployeesData({
    auth: isAuthenticated,
    page: 1,
    pageSize: 100,
    isAdmin: true,
  });

  useEffect(() => {
    if (allCurriculums && isAuthenticated) {
      let curriculums = null;
      if (
        !isAuthenticated.company_details.all_content &&
        !isAuthenticated.company_details.custom_content
      ) {
        const freeTrainings = allCurriculums.filter(
          (c) => c.is_available_for_free && !c.company && !c.is_already_in_use
        );
        let trainingTitles = freeTrainings.reduce(
          (a, b) => a + b.title + ', ',
          ''
        );
        trainingTitles =
          freeTrainings.length > 1
            ? ' the following: ' +
              trainingTitles.substring(0, trainingTitles.length - 2)
            : ' ' +
              trainingTitles.substring(0, trainingTitles.length - 2) +
              ' training';
        setCalloutMessage(
          String.fromCodePoint('0x1F44B') +
            ` Hey! Your plan only includes access to ${trainingTitles}. Upgrade your plan to access Haekka's complete library of content, as well the ability to customize and create your own content.`
        );
        curriculums = freeTrainings;
      } else if (
        isAuthenticated.company_details.all_content &&
        !isAuthenticated.company_details.custom_content
      ) {
        setCalloutMessage(
          String.fromCodePoint('0x1F44B') +
            ' Hey! Interested in customizing this content? Upgrade now to make changes, add new content, or write your very own training.'
        );
        curriculums = allCurriculums.filter(
          (c) => !c.company && !c.is_already_in_use
        );
      } else if (
        isAuthenticated.company_details.all_content &&
        isAuthenticated.company_details.custom_content
      ) {
        if (customizeTraining) {
          curriculums = allCurriculums.filter(
            (c) => c.is_editable && !c.company
          );
        } else {
          curriculums = allCurriculums.filter(
            (c) => !c.company && !c.is_already_in_use
          );
        }
      }
      setCurriculums(curriculums);

      setGradingThresholdDisabled(
        allCurriculums.find((c) => c.id === curriculum)
          ?.is_the_latest_item_an_graded_assessment ?? false
      );
      if (defaultCurriculum) {
        setCurriculum(defaultCurriculum.id);
        setTitle(defaultCurriculum.title);
        setCurriculumType(defaultCurriculum.curriculum_type);
      }
    }
  }, [
    allCurriculums,
    curriculum,
    customizeTraining,
    defaultCurriculum,
    isAuthenticated,
  ]);

  useEffect(() => {
    const filteredSug = suggestions.filter((s) => {
      const c = tags.find((t) => t.name === s.name);
      if (!c) return s;
      else return null;
    });
    setSuggestions(
      filteredSug.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    );
    if (companySuggestions.length === 0 && suggestions) {
      setCompanySuggestion([...suggestions]);
    }
  }, [tags]);

  async function handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);
    try {
      const createResponse = await api.createTraining({
        auth: isAuthenticated,
        createData: {
          curriculum,
          curriculum_title: title,
          curriculum_subtitle: subtitle,
          curriculum_type: curriculumType,
          annual,
          renew_cycle: renewCycle,
          renew_date: format(new Date(renewDate), 'yyyy-MM-dd'),
          passing_grade: gradingThreshold !== null ? gradingThreshold : 0,
          days_till_due: daysTillDue,
          tags,
          action_id: hasBranchingScenario ? 'branching' : 'lesson_submit',
          owners: owners.map((o) => o.value),
          obey_assignment_dates: !renewOnTheSameDayForEveryone,
          docebo_course: doceboTraining?.rawData ?? null,
          docebo_manager_notifications: doceboTraining
            ? {
                is_over_due_on: doceboManagerNotifications,
                over_due_schedule: doceboManagerNotificationsSchedule.value,
              }
            : undefined,
          show_correctness_indicator_on_results:
            showCorrectnessIndicatorOnResults,
          seismic_content: seismicContent?.rawData ?? null,
        },
        create_new_curriculum: customizeTraining,
      });
      onTrainingCreate(createResponse);

      internalSlackChannelLogger(EVENT_TRACKER.ADD_TRAINING, isAuthenticated, {
        Admin: isAuthenticated.name,
        distinct_id: isAuthenticated.slack_uid,
        Company: isAuthenticated.company_details.title,
        'Company ID': isAuthenticated.company_details.id,
        'Curriculum Title': createResponse.curriculum_detail.title,
        'Is Custom': !!createResponse.curriculum_detail.company,
        'Renew Cycle': renewCycle,
        'Company Link to Admin Panel': `${process.env.REACT_APP_API_URL}/admin/company/company/${isAuthenticated.company_details.id}/change`,
      });

      setIsSubmitting(false);
      navigate(`/trainings/${createResponse.id}`);
      toast.success(`Training created`);
    } catch (e) {
      onError(e);
      setIsSubmitting(false);
    }
  }

  const handleUpdateSuggestions = (suggestion) => {
    if (companySuggestions.find((s) => s.name === suggestion.name)) {
      setSuggestions(
        [...suggestions, suggestion].sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        )
      );
    }
  };

  const clearData = () => {
    setCurriculum(undefined);
    setCurriculumType(undefined);
    setTitle('');
    setAnnual(false);
    setDaysTillDue('');
    setFormState('step_one_training');
    setRenewCycle(TRAINING_RENEW_OPTIONS[0].id);
  };

  const closeModal = () => {
    clearData();
    onClose();
  };

  const curriculumObject = curriculums?.find((item) => item.id === curriculum);

  const needsUpgrade =
    curriculumObject &&
    curriculumObject.is_available_for_free === false &&
    isAuthenticated &&
    !isAuthenticated.company_details?.all_content;

  if (defaultCurriculum && needsUpgrade) {
    return (
      <Modal
        show={showModal}
        title='Upgrade Required'
        onClose={onClose}
        noFooter
      >
        <div className='flex w-full p-2 '>
          <img
            className='w-4 h-4 mr-1 rounded-md'
            alt='HKA--Icon_Light.png'
            src='https://haekka-author-icons.s3.us-west-2.amazonaws.com/HKA--UPDATED--Author_Haekka.png'
          />
          <div className='flex flex-col'>
            <h3 className='font-basis-bold'>Haekka</h3>
            <p className='text-hka_gray'>
              <span className='text-md'>&#128075;</span> This training is not
              available at Free tier plan
              <br />
              <br />
              <UpgradePlanButton parentCpmToTrack={'Create Training Modal'} />
            </p>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      show={showModal}
      title='Create Training'
      onClose={onClose}
      expandable
      noFooter
    >
      <main className='modal__body'>
        <section>
          {formState === 'step_one_training' && (
            <StepOneTraining
              isAuthenticated={isAuthenticated}
              isTitleCurriculumTechincalValid={isTitleCurriculumTechincalValid}
              setIsTitleCurriculumTechincalValid={
                setIsTitleCurriculumTechincalValid
              }
              curriculums={curriculums}
              curriculum={curriculum}
              employeeDetails={isAuthenticated}
              setCurriculum={setCurriculum}
              curriculumTypes={curriculumTypes}
              curriculumType={curriculumType}
              setCurriculumType={setCurriculumType}
              title={title}
              setTitle={setTitle}
              subtitle={subtitle}
              setSubtitle={setSubtitle}
              annual={annual}
              setAnnual={setAnnual}
              dueDate={dueDate}
              setDueDate={setDueDate}
              renewCycle={renewCycle}
              setRenewCycle={setRenewCycle}
              daysTillDue={daysTillDue}
              setDaysTillDue={setDaysTillDue}
              customizeTraining={customizeTraining}
              setCustomizeTraining={setCustomizeTraining}
              calloutMessage={calloutMessage}
              tags={tags}
              setTags={setTags}
              suggestions={suggestions}
              updateSuggestions={handleUpdateSuggestions}
              hasBranchingScenario={hasBranchingScenario}
              setHasBranchingScenario={setHasBranchingScenario}
              renewDate={renewDate}
              setRenewDate={setRenewDate}
              gradingThreshold={gradingThreshold}
              setGradingThreshold={setGradingThreshold}
              isThresholdCheked={isThresholdCheked}
              setIsThresholdCheked={setIsThresholdCheked}
              owners={owners}
              setOwners={setOwners}
              admins={admins?.results.map((admin) => ({
                label: admin.name,
                value: admin.id,
              }))}
              renewOnTheSameDayForEveryone={renewOnTheSameDayForEveryone}
              setRenewOnTheSameDayForEveryone={setRenewOnTheSameDayForEveryone}
              gradingThresholdDisabled={gradingThresholdDisabled}
              doceboTraining={doceboTraining}
              setDoceboTraining={setDoceboTraining}
              doceboManagerNotifications={doceboManagerNotifications}
              setDoceboManagerNotifications={setDoceboManagerNotifications}
              doceboManagerNotificationsSchedule={
                doceboManagerNotificationsSchedule
              }
              setDoceboManagerNotificationsSchedule={
                setDoceboManagerNotificationsSchedule
              }
              showCorrectnessIndicatorOnResults={
                showCorrectnessIndicatorOnResults
              }
              setShowCorrectnessIndicatorOnResults={
                setShowCorrectnessIndicatorOnResults
              }
              seismicContent={seismicContent}
              setSeismicContent={setSeismicContent}
            />
          )}

          {formState === 'step_three_confirm' && (
            <StepThreeConfirm
              title={title}
              annual={annual}
              daysTillDue={daysTillDue}
              renewCycle={renewCycle}
              gradingThreshold={gradingThreshold}
              owners={owners}
              doceboTraining={doceboTraining}
            />
          )}
        </section>
      </main>

      <footer className='modal__footer'>
        {formState === 'step_one_training' && (
          <>
            <button className='button button--gray' onClick={closeModal}>
              Cancel
            </button>
            <button
              className='button button--green button__icon--right'
              onClick={() => setFormState('step_three_confirm')}
              disabled={
                !curriculum ||
                (isThresholdCheked ? gradingThreshold === 0 : false) ||
                (customizeTraining
                  ? !curriculumType ||
                    !title ||
                    !isTitleCurriculumTechincalValid
                  : false)
              }
            >
              Confirm Selections
              <span className='icon-arrow right'></span>
            </button>
          </>
        )}

        {formState === 'step_three_confirm' && (
          <>
            <button
              className='button button--gray'
              onClick={() => setFormState('step_one_training')}
            >
              Back
            </button>
            <button className='button button--green' onClick={handleSubmit}>
              {isSubmitting ? 'Creating...' : 'Create Training'}
            </button>
          </>
        )}
      </footer>
    </Modal>
  );
};

CreateTrainingModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  allCurriculums: PropTypes.array.isRequired,
  curriculumTypes: PropTypes.array.isRequired,
  trainingTags: PropTypes.array.isRequired,
  onTrainingCreate: PropTypes.func.isRequired,
  defaultCurriculum: PropTypes.object,
};

export default CreateTrainingModal;
