import { Fragment, useState } from "react";

import { useEmployeeEngagementsData } from "../../hooks/data-hooks/employeeEngagements.js/useEmployeeEngagementsData";

import LoaderInline from "../LoaderInline";
import Callout from "../Callout";
import ListItems from "../ListItems";
import EmployeeEngagementItem from "./EmployeeEngagementItem";
import Pagination from "../Pagination";

const ListEmployeeEngagementsSection = ({
  employeeID,
  pageSize = 5,
  staleTime = 30000,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data: employeeEngagements, isLoading: isLoadingEngagements } =
    useEmployeeEngagementsData({
      employeeID,
      page: currentPage,
      pageSize,
      staleTime,
    });

  return (
    <section className="my-4">
      <h3 className="mb-1 font-basis-medium">Assigned Engagements</h3>
      {isLoadingEngagements && <LoaderInline wrapperClasses="h-12 py-2" />}
      {!isLoadingEngagements && employeeEngagements.results.length === 0 && (
        <Callout
          title="Haekka"
          message={
            <>
              <span className="text-md">&#128075;</span> It looks like this
              employee doesn't have assigned engagements.
            </>
          }
        />
      )}

      {employeeEngagements?.results.length > 0 && (
        <Fragment>
          <ListItems>
            {employeeEngagements.results.map((item) => (
              <EmployeeEngagementItem key={item.id} employeeEngagement={item} />
            ))}
          </ListItems>
          <Pagination
            pageSize={pageSize}
            count={employeeEngagements.count}
            currentPage={currentPage}
            onPageChanged={(page) => setCurrentPage(page)}
          />
        </Fragment>
      )}
    </section>
  );
};

export default ListEmployeeEngagementsSection;
