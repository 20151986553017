import { useQuery } from '@tanstack/react-query';
import { getCompanyStream } from '../../../../api/streamsApi';

export const useCompanyStreamData = ({ companyID, streamID }) => {
  return useQuery({
    queryKey: ['company-stream', companyID, streamID],
    queryFn: () => getCompanyStream({ companyID, streamID }),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!streamID,
  });
};
