import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteStream } from '../../../../api/streamsApi';

export const useDeleteStream = ({ onSuccess = () => {}, currentPage }) => {
  const queryClient = useQueryClient();
  const queryKey = ['streams', currentPage];

  return useMutation({
    mutationFn: deleteStream,
    onMutate: async ({ streamID }) => {
      await queryClient.cancelQueries({ queryKey });

      const previousStreams = queryClient.getQueriesData(queryKey);

      queryClient.setQueriesData(queryKey, (old) => ({
        ...old,
        results: old.results.filter((item) => item.id !== streamID),
      }));

      return { previousStreams };
    },
    onError: (_error, _data, context) => {
      queryClient.setQueryData(queryKey, context.previousStreams);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onSuccess,
  });
};
