import api from '../client';

// Company Engagements

export const getCompanyEngagements = async ({
  page = 1,
  simple_data = false,
}) => {
  const { data } = await api.get('/api/quizzes/company', {
    params: { page, simple_data },
  });
  return data;
};

export const getCompanyEngagement = async ({ companyEngagementID }) => {
  const { data } = await api.get(`/api/quizzes/company/${companyEngagementID}`);
  return data;
};

export const createCompanyEngagement = async ({ sumbitData }) => {
  const { data } = await api.post('/api/quizzes/company', sumbitData);
  return data;
};

export const updateCompanyEngagement = async ({ sumbitData }) => {
  const { data } = await api.patch(`/api/quizzes/company/${sumbitData.id}`, {
    ...sumbitData,
  });
  return data;
};

export const deleteCompanyEngagement = async ({ companyEngagementID }) => {
  const { data } = await api.delete(
    `/api/quizzes/company/${companyEngagementID}`
  );
  return data;
};

// Engagements

export const createEngagement = async ({ sumbitData }) => {
  const { data } = await api.post(`/api/quizzes/`, { ...sumbitData });
  return data;
};

export const updateEngagement = async ({ sumbitData }) => {
  const { data } = await api.patch(`/api/quizzes/${sumbitData.id}`, {
    ...sumbitData,
  });
  return data;
};

// Engagement Messages

export const addEngagementMessage = async (messageData) => {
  const { data } = await api.put('/api/questions/quiz', messageData);
  return data;
};

export const updateEngagementMessage = async (messageData) => {
  const { data } = await api.patch(`/api/questions/quiz`, messageData);
  return data;
};

export const deleteEngagementQuestion = async ({ messageID }) => {
  const { data } = await api.delete(`/api/questions/quiz/${messageID}`);
  return data;
};

// Statistics

export const getEngagementStatistics = async ({ companyEngagementID }) => {
  const { data } = await api.get(
    `/api/quizzes/${companyEngagementID}/question_statistics`
  );
  return data;
};

// Employee Results

export const getCompanyEngagmentSchedules = async ({ companyEngagementID }) => {
  const { data } = await api.get(
    `/api/quizzes/${companyEngagementID}/schedules`
  );
  return data;
};

export const getEngagementResults = async ({
  page = 1,
  companyEngagementID,
  schedule,
  name = '',
  sortColumn,
  sortType,
}) => {
  const { data } = await api.get(
    `/api/employees/quizzes/${companyEngagementID}/results`,
    {
      params: {
        page,
        schedule,
        name,
        sort_column: sortColumn === 'status' ? 'completed' : sortColumn,
        sort_type: sortType === 'desc' ? '' : '-',
      },
    }
  );
  return data;
};

export const getEngagementAnonymousResults = async ({
  page = 1,
  companyEngagementID,
  schedule,
}) => {
  const { data } = await api.get(
    `/api/employees/quizzes/${companyEngagementID}/anonymous_results`,
    {
      params: {
        page,
        schedule,
      },
    }
  );
  return data;
};

export const getChannelEngagementResults = async ({
  page = 1,
  companyEngagementID,
  schedule,
  name,
  sortColumn,
  sortType,
}) => {
  const { data } = await api.get(
    `/api/employees/channel_engagement/${companyEngagementID}/results`,
    {
      params: {
        page,
        schedule,
        name,
        sort_column: sortColumn,
        sort_type: sortType === 'desc' ? '' : '-',
      },
    }
  );
  return data;
};

// Reports

export const generateEngagementOverviewReport = async ({
  auth,
  scheduleID,
  onDownloadProgress,
}) => {
  const companyId = auth.company;

  return await api.get(
    `/api/quizzes/company/${companyId}/schedule/${scheduleID}/overviewreport`,
    {
      onDownloadProgress,
      responseType: 'arraybuffer',
    }
  );
};

export const generateEngagementEvidenceReport = async ({
  auth,
  scheduleID,
}) => {
  const companyId = auth.company;
  return await api.get(
    `/api/quizzes/company/${companyId}/schedule/${scheduleID}/evidencereport`
  );
};

// MISC

export const sendReminderAboutEngagement = async ({
  receiverUserId,
  companyEngagementID,
}) => {
  const { data } = await api.post(
    `/api/employees/quizzes/${companyEngagementID}/send_reminder`,
    { receiver_user_id: receiverUserId }
  );

  return data;
};

export const engagementEmployeeUnassign = async ({
  employeeID,
  employeeEngagmentID,
}) => {
  const { data } = await api.post(
    `/api/employees/${employeeID}/quizzes/${employeeEngagmentID}/unassign`,
    {}
  );
  return data;
};

export const sendEngagementNow = async ({ auth, scheduleID }) => {
  const companyId = auth.company;
  return await api.post(
    `/api/quizzes/company/${companyId}/schedule/${scheduleID}/send_engagement_now`
  );
};

export const checkDynamicDistributionForEngagement = async ({
  companyEngagementID,
}) => {
  const { data } = await api.get(
    `/api/quizzes/${companyEngagementID}/check_dynamic_distribution`
  );
  return data;
};

export const checkEngagementData = async ({ companyEngagementID }) => {
  const { data } = await api.get(
    `/api/quizzes/${companyEngagementID}/check_engagement_data`
  );
  return data;
};
