import React from "react";
import Modal from "../Modal/Modal";
import Note from "../Note";

export default function RemoveAdminModal({
  showModal,
  onSubmit,
  onClose,
  submitValid,
}) {
  return (
    <Modal
      show={showModal}
      title="Remove Admin"
      submitButtonText="Remove Admin"
      submitButtonType="danger"
      onSubmit={onSubmit}
      onClose={onClose}
      submitValid={submitValid}
      compact
    >
      <p className="mb-1">Are you sure you want to remove this admin?</p>
      <Note type="danger">
        <p>
          This action will only revoke this employee's admin permissions. It
          will not remove the employee from Haekka.
        </p>
      </Note>
    </Modal>
  );
}
