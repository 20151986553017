import Modal from "../../Modal/Modal";

export default function RemoveEmployeeConfirmationModal({
  showModal,
  onSumbit,
  onClose,
  employeeData,
}) {
  return (
    <Modal
      show={showModal}
      id="delete-quiz-answer-modal"
      title="Remove Employee"
      onSubmit={onSumbit}
      onClose={onClose}
      submitButtonText="Remove Employee"
      submitButtonType="danger"
      compact
    >
      <p>
        Are you sure you want to remove{" "}
        <span className="font-basis-bold">{employeeData?.name}</span> from this
        Engagement?
      </p>
    </Modal>
  );
}
