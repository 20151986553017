import React from "react";
import Modal from "../Modal/Modal";

export default function ConvertToCustomTrainingModal({
  showModal,
  submitting,
  onSubmit,
  onClose,
}) {
  return (
    <Modal
      show={showModal}
      title="Convert to Custom"
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonText="Yes, convert"
      submitButtonType="danger"
      submitValid={!submitting}
    >
      <p>
        Are you sure you want to convert this to a custom training? Any edits
        that Haekka makes to this training will not be picked up if you convert.
      </p>
    </Modal>
  );
}
