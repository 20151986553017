import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateStreamSubscription } from '../../../../api/streamsApi';

export const useUpdateStreamSubscription = ({
  companyID,
  streamID,
  onSuccess,
}) => {
  const queryClient = useQueryClient();
  const queryKey = ['company-stream', companyID, streamID];

  return useMutation({
    mutationFn: updateStreamSubscription,
    onMutate: async ({ payload }) => {
      await queryClient.cancelQueries({ queryKey });

      const previousStreams = queryClient.getQueriesData(queryKey);

      queryClient.setQueriesData(queryKey, (data) => ({ ...data, ...payload }));

      return { previousStreams };
    },
    onError: (_error, _data, context) => {
      queryClient.setQueryData(queryKey, context.previousStreams);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onSuccess,
  });
};
