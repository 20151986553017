import { getTrainingsByCurriculum, getTrainingEvidence } from "../api";
import { onError } from "../libs/errorLib";
import { format } from "date-fns";
import { trainingEvidenceActions } from "./trainingEvidenceSlice";

const formatLabel = (date) => {
  return `${format(new Date(date), "MMMM do, yyyy")}`;
};

export const getTrainingsByCurriculumData = ({ auth, curriculumID }) => {
  return async (dispatch) => {
    const trainingsByCurriculum = async () => {
      return await getTrainingsByCurriculum({
        auth,
        curriculumID,
      });
    };

    try {
      const trainings = await trainingsByCurriculum();
      const mappedData = trainings.map((t) => {
        return {
          value: t.id,
          label: `${formatLabel(t.start_date)} to ${formatLabel(t.renew_date)}`,
        };
      });
      dispatch(
        trainingEvidenceActions.setCurriculumTrainings({
          trainings: mappedData,
        })
      );
    } catch (error) {
      onError(error);
    }
  };
};

export const getTrainingEvidenceData = ({ auth, trainingID }) => {
  return async (dispatch) => {
    const trainingEvidence = async () => {
      return await getTrainingEvidence({
        auth,
        trainingId: trainingID,
      });
    };

    try {
      trainingEvidence();
      dispatch(
        trainingEvidenceActions.markTrainingAsDownloaded({ trainingID })
      );
    } catch (error) {
      onError(error);
    }
  };
};
