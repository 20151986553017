export const modes = {
  CREATE: "CREATE",
  EDIT: "EDIT",
};

export const actions = {
  GROUP_CREATE: "GROUP_CREATE",
  GROUP_EDIT: "GROUP_EDIT",
  GROUP_DELETE: "GROUP_DELETE",
};

export const views = {
  GROUP_DETAILS: "GROUP_DETAILS",
  GROUP_MEMBERS: "GROUP_MEMBERS",
};

export const directions = {
  NEXT: 1,
  PREVIOUS: -1,
};

export const types = {
  EMPLOYEE: "employee",
  CHANNEL: "channel",
  SLACK_USER_GROUP: "slack_user_group",
};

export const groupIcons = [
  "icon-blocks",
  "icon-sun",
  "icon-gauge",
  "icon-frequency",
  "icon-admin",
  "icon-bitcoin",
  "icon-target",
  "icon-chart",
  "icon-code",
  "icon-hammer",
  "icon-ant",
  "icon-cyclone",
  "icon-cube",
];

export const formatOptionLabel = ({ value, label, avatar }) => (
  <div className="flex flex-row items-center">
    <div>
      {avatar && (
        <img
          className="w-2.4 h-2.4 user-img rounded-md mr-0.5"
          src={avatar}
          alt={value}
        />
      )}
    </div>
    <span className={`self-center ${!avatar && "text-hka_gray-dark"}`}>
      {label}
    </span>
  </div>
);

export const transformMember = (member) => ({
  value: member.id,
  label: member.name,
  avatar: member.avatar_image,
  type: types.EMPLOYEE,
});

export const transformChannel = (channel) => ({
  value: channel.value,
  label: channel.label,
  type: types.CHANNEL,
});

export const transformSlackUserGroup = (slackUserGroup) => ({
  value: slackUserGroup.id,
  label: slackUserGroup.name,
  type: types.SLACK_USER_GROUP,
});
