import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateStream } from '../../../../api/streamsApi';

export const useUpdateStream = ({ onSuccess = () => {}, currentPage }) => {
  const queryClient = useQueryClient();
  const queryKey = ['streams', currentPage];

  return useMutation({
    mutationFn: updateStream,
    onMutate: async ({ payload }) => {
      await queryClient.cancelQueries({ queryKey });

      const previousStreams = queryClient.getQueriesData(queryKey);

      queryClient.setQueriesData(queryKey, (old) => ({
        ...old,
        results: old.results.map((item) =>
          item.id === payload.id ? payload : item
        ),
      }));

      return { previousStreams };
    },
    onError: (_error, _data, context) => {
      queryClient.setQueryData(queryKey, context.previousStreams);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onSuccess,
  });
};
