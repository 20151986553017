import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateEngagementMessage } from "../../../api/engagementsApi";
import { onUpdateMessageSuccess } from "../../../utils/engagementsUtils";

export const useUpdateMessageData = ({
  companyEngagement,
  selectedQuestion,
  onClose,
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateEngagementMessage,
    onSuccess: (newMessageData) => {
      onClose();
      queryClient.setQueryData(["engagement", companyEngagement.id], (curr) =>
        onUpdateMessageSuccess(curr, selectedQuestion, newMessageData)
      );
      queryClient.refetchQueries(["engagement", companyEngagement.id]);
    },
  });
};
