const EmptyListRow = ({ text, buttonText, buttonOnClick, noButton }) => {
  return (
    <div className="relative flex flex-col w-full sm:items-center sm:flex-row">
      <p className="flex-1 mb-1 sm:mb-0">{text}</p>
      {!noButton && (
        <button
          className="w-full transition-all transform button sm:w-auto text-hka_blue bg-hka_blue-light hover:bg-blue-200"
          onClick={buttonOnClick}
        >
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default EmptyListRow;
