import { useQuery } from "@tanstack/react-query";
import { getCompanyEngagements } from "../../../api/engagementsApi";

export const useEngagementsData = ({ currentPage }) => {
  return useQuery({
    queryKey: ["engagements", currentPage],
    queryFn: () =>
      getCompanyEngagements({ page: currentPage, simple_data: true }),
    keepPreviousData: true,
  });
};

export const prefetchEngagementsData = (queryClient) => {
  queryClient.prefetchQuery({
    queryKey: ["engagements", 1],
    queryFn: () => getCompanyEngagements({ page: 1, simple_data: true }),
  });
};
