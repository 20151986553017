import { useRef, useState } from "react";
import { useAppContext } from "../../libs/contextLib";
import "./VideoUploader.css";
import { onError } from "../../libs/errorLib";
import { useDispatch } from "react-redux";
import { sendVideo } from "../../store/video-actions";
import { useSelector } from "react-redux";

const VideoUploader = ({
  type,
  id,
  parent,
  position,
  compact,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAppContext();
  const { uploadsInProgress } = useSelector((state) => state.videoUpload);

  const fileRef = useRef();

  const [file, setFile] = useState(null);
  const [labal, setLabel] = useState("Choose Video File");
  const isUploadProgress = uploadsInProgress.find(
    (upload) => upload.type === type && upload.id === id
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setLabel(file.name);
    }
  };

  const onSubmit = async () => {
    try {
      dispatch(sendVideo(file, isAuthenticated, id, type, position, parent));
    } catch (error) {
      onError(error);
    }
  };
  if (isUploadProgress) {
    return <p className="text-sm italic">Video upload is in progress.</p>;
  }

  const typeString =
    type === "engagement"
      ? "engagement"
      : type === "engagement-question"
      ? "question"
      : "lesson";

  if (compact) {
    return (
      <section>
        <div className="flex flex-col truncate md:flex-row md:items-end md:justify-end">
          <label
            htmlFor="video_upload"
            className="flex items-center w-full h-4 px-1 m-0 mb-1 text-sm truncate border border-dashed rounded-lg cursor-pointer md:mb-0 border-hka_gray-border"
          >
            <span className="truncate font-basis-medium">{labal}</span>
          </label>
          <input
            id="video_upload"
            ref={fileRef}
            className=""
            type="file"
            onChange={handleFileChange}
            accept="video/*"
            disabled={disabled}
          />
          <button
            onClick={onSubmit}
            disabled={!file}
            className={`${!file ? "hidden" : "flex"} button button--blue ml-1`}
          >
            Upload
          </button>
        </div>
      </section>
    );
  }

  return (
    <section className="w-full mb-3">
      <p className="mb-1 text-sm">
        Upload your own video file to this {typeString} (MP4).
      </p>
      <div className="flex flex-col items-end justify-end sm:flex-row">
        <label
          htmlFor="video_upload"
          className="flex items-center justify-center w-full h-4 m-0 mb-1 text-sm truncate border border-dashed rounded-lg cursor-pointer md:mb-0 border-hka_gray-border"
        >
          <span className="flex items-center justify-center icon-arrow up"></span>
          <span>{labal}</span>
        </label>
        <input
          id="video_upload"
          ref={fileRef}
          className=""
          type="file"
          onChange={handleFileChange}
          accept="video/*"
        />
        <button
          onClick={onSubmit}
          disabled={!file}
          className={`${!file ? "hidden" : "flex"} button button--blue ml-1`}
        >
          Upload
        </button>
      </div>
    </section>
  );
};

export default VideoUploader;
