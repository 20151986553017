import { useAppContext } from "../libs/contextLib";
import {
  EVENT_TRACKER,
  internalSlackChannelLogger,
} from "../analytics/eventTracker";

const UpgradePlanButton = ({ parentCpmToTrack, inline }) => {
  const { isAuthenticated } = useAppContext();

  const handleUpgradeButtonClick = () => {
    const properties = {
      Admin: isAuthenticated.name,
      "Interaction Origin": "Web Dashboard",
    };
    if (parentCpmToTrack) {
      properties["Clicked At"] = parentCpmToTrack;
    }
    internalSlackChannelLogger(
      EVENT_TRACKER.UPGRADE_BUTTON_CLICK,
      isAuthenticated,
      properties
    );
    // const url = subscriptionUrl(isAuthenticated);
    const url = "https://www.haekka.com/billing-support";
    window.open(url, "_blank");
  };

  const subscriptionUrl = (auth) => {
    const maxUsers = auth.company_details.max_users;
    // const subscriptionUrl = auth.company_details.subscription_url;
    const subscriptionUrl = "https://www.haekka.com/billing-support";
    if (maxUsers > 10) {
      return subscriptionUrl;
    } else {
      return `${process.env.REACT_APP_API_URL}${subscriptionUrl}`;
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={`${
        inline
          ? "inline-flex cursor-pointer font-basis-bold link"
          : "inline-flex w-full leading-4 cursor-pointer sm:w-17 button bg-hka_blue-light hover:bg-blue-200 text-hka_blue"
      }`}
      onClick={handleUpgradeButtonClick}
      target="_blank"
      rel="noreferrer"
    >
      Upgrade your Plan
    </a>
  );
};

export default UpgradePlanButton;
