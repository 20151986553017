import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import videoUploaderReducer from './video-upload';
import trainingEvidenceReducer from './trainingEvidenceSlice';
import lessonReducer from './lessonSlice';
import workflowsReducer from './workflowsSlice';
import settingsReducer from './settingsSlice';

import { create } from 'zustand';
import { streamsSlice } from './streamsSlice';

export const useGlobalStore = create((...a) => {
  return {
    streams: { ...streamsSlice(...a) },
  };
});

// eventually, we will move the complete global store to zustand
const store = configureStore({
  reducer: {
    auth: authReducer,
    settings: settingsReducer,
    trainingEvidence: trainingEvidenceReducer,
    lesson: lessonReducer,
    workflows: workflowsReducer,
    videoUpload: videoUploaderReducer,
  },
});

export default store;
