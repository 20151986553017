import { useQuery } from '@tanstack/react-query';
import { listExternalApps } from '../../../api/integrationsApi';

export const useExternalAppsData = (enabled = true) => {
  return useQuery({
    queryKey: ['external-installations-all'],
    queryFn: listExternalApps,
    enabled: enabled,
  });
};
