import { useQuery } from '@tanstack/react-query';
import { getExternalAppIntegrationLink } from '../../../api/integrationsApi';

export const useExternalAppOAuthLink = ({
  companyID,
  integration,
  config,
  enabled = true,
  onSuccess,
}) => {
  return useQuery({
    queryKey: ['external-installation', integration, companyID],
    queryFn: () =>
      getExternalAppIntegrationLink({
        companyID,
        integration,
        config,
      }),
    onSuccess,
    staleTime: Infinity,
    enabled,
  });
};
