import { Fragment, useState } from 'react';
import Select from 'react-select';

import {
  engagementAnswerTypeOptions,
  validateAnswerTypeChangeAttempt,
} from '../../../utils/engagementsUtils';
import ChangeAnswerTypeWarningModal from './confirmationModals/ChangeAnswerTypeWarningModal';
import AnswersSection from './AnswersSection';

const AnswerTypeSection = ({
  companyEngagement,
  answers,
  setAnswers,
  answerType,
  setAnswerType,
  externalUrl,
  setExternalUrl,
  externalUrlBtnText,
  setExternalUrlBtnText,
  create,
  isSingleSelect,
  setIsSingleSelect,
  isReadOnly,
}) => {
  const [answerTypeChangeWarning, setAnswerTypeChangeWarning] =
    useState(undefined);

  const onAnswerTypeChangeAttempt = (value) => {
    let message = undefined;
    if (answers.length > 0) {
      message = validateAnswerTypeChangeAttempt({
        answerType,
        selectedValue: value,
      });
    }
    if (message) {
      setAnswerTypeChangeWarning({ message, from: answerType, to: value });
      return;
    }
    setAnswerType(value);
  };

  const confirmAnswerTypeChangeHandler = () => {
    const from = answerTypeChangeWarning.from;
    const to = answerTypeChangeWarning.to;
    setAnswerType(to);
    setAnswerTypeChangeWarning(undefined);

    if (
      (from?.value === 'multiple_choice' || from?.value === 'neutral') &&
      (to.value === 'no_action' ||
        to.value === 'user_acknowledged' ||
        to.value === 'written_response' ||
        to.value === 'external_url')
    ) {
      setAnswers([]);
    } else if (from?.value === 'multiple_choice' && to.value === 'neutral') {
      setAnswers((answers) =>
        answers.map((answer) => ({ ...answer, correct: null }))
      );
    } else if (from?.value === 'neutral' && to.value === 'multiple_choice') {
      setAnswers((answers) =>
        answers.map((answer) => ({ ...answer, correct: true }))
      );
    }
  };

  const actionOptions = () => {
    if (companyEngagement.quiz.anonymize_user_results) {
      return engagementAnswerTypeOptions.filter(
        (o) =>
          o.value === 'multiple_choice' ||
          o.value === 'neutral' ||
          o.value === 'written_response'
      );
    }
    if (companyEngagement.quiz.engagement_type === 'channel') {
      return engagementAnswerTypeOptions.filter(
        (o) =>
          o.value === 'no_action' ||
          o.value === 'user_acknowledged' ||
          o.value === 'external_url'
      );
    }
    if (create && companyEngagement.quiz.questions.length > 0) {
      return engagementAnswerTypeOptions.filter(
        (o) => o.value !== 'no_action' && o.value !== 'external_url'
      );
    }
    if (!create && companyEngagement.quiz.questions.length > 1) {
      return engagementAnswerTypeOptions.filter(
        (o) => o.value !== 'no_action' && o.value !== 'external_url'
      );
    }
    return engagementAnswerTypeOptions;
  };

  return (
    <Fragment>
      <section className='section--answer-type'>
        <div className='w-full mb-1 sm:mb-0 sm:w-2/3'>
          <h4>
            Action <span className='text-sm text-hka_gray'>(Required)</span>
          </h4>
          <p className='text-sm'>
            Select the action for this Engagement message.
          </p>
        </div>

        <div className='flex flex-col flex-1 w-full gap-1 sm:w-auto'>
          <Select
            onChange={onAnswerTypeChangeAttempt}
            value={answerType}
            placeholder='Select Answer Type'
            isMulti={false}
            options={actionOptions()}
            classNamePrefix='select'
            unstyled
            menuPortalTarget={document.body}
            maxMenuHeight='17rem'
            menuPlacement='auto'
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            isDisabled={isReadOnly}
          />
        </div>
      </section>

      <AnswersSection
        companyEngagement={companyEngagement}
        answerType={answerType}
        answers={answers}
        setAnswers={setAnswers}
        externalUrl={externalUrl}
        setExternalUrl={setExternalUrl}
        externalUrlBtnText={externalUrlBtnText}
        setExternalUrlBtnText={setExternalUrlBtnText}
        isSingleSelect={isSingleSelect}
        setIsSingleSelect={setIsSingleSelect}
        isReadOnly={isReadOnly}
      />

      <ChangeAnswerTypeWarningModal
        show={!!answerTypeChangeWarning}
        message={answerTypeChangeWarning?.message}
        to={answerTypeChangeWarning?.to}
        onSubmit={confirmAnswerTypeChangeHandler}
        onClose={() => setAnswerTypeChangeWarning(undefined)}
      />
    </Fragment>
  );
};

export default AnswerTypeSection;
