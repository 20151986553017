const TRAINING_RENEW_OPTIONS = [
  {
    id: "none", // this is default value when creating a training
    title: "Does not renew",
  },
  {
    id: "quarterly",
    title: "Renews every 3 months",
  },
  {
    id: "semi-annual",
    title: "Renews every 6 months",
  },
  {
    id: "annual",
    title: "Renews every 12 months",
  },
];

export default TRAINING_RENEW_OPTIONS;
