import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import Modal from '../../Modal/Modal';

const BulkDueDateConfirmModal = ({ showModal, onSubmit, onClose }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  return (
    <Modal
      show={showModal}
      onSubmit={() => onSubmit(selectedDate)}
      onClose={onClose}
      title='Update Due Date'
      submitButtonText='Save'
      modalClass='w-40'
    >
      <section className='flex w-full justify-center py-1'>
        <DatePicker
          className='items-center focus:outline-none text-hka_gray datepicker w-37r '
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat='PP'
          inline
        />
      </section>
    </Modal>
  );
};

export default BulkDueDateConfirmModal;
