import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../libs/contextLib";
import { toast } from "react-hot-toast";
import Modal from "../Modal/Modal";
import useVideoCaptions from "../../utils/custom-hooks/useVideoCaptions";
import LoaderInline from "../LoaderInline";
import DeleteCaptionConfirmModal from "./DeleteCaptionConfirmModal";
import Select from "react-select";
import Note from "../Note";

const CaptionsModal = ({ videoURL, onClose }) => {
  const { isAuthenticated: auth } = useAppContext();

  const [mediaID, setMediaID] = useState(undefined);
  const [newCaptionUpload, setNewCaptionUpload] = useState(false);
  const [language, setLanguage] = useState("eng");
  const [languageForDeletion, setLanguageForDeletion] = useState(undefined);
  const [warning, setWarning] = useState(undefined);
  const [file, setFile] = useState(null);
  const [labal, setLabel] = useState(undefined);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [captionsPluginEnabled, setCaptionPluginEnabled] = useState(false);
  const [captionsOnByDefault, setCaptionsOnByDefault] = useState(false);
  const [defaultLanguage, setDefaultLanguage] = useState(undefined);

  const {
    loading,
    captions,
    uploadSrtFile,
    showSrtFile,
    deleteSrtFile,
    captionsPlugin,
    updateCustomizations,
    error,
  } = useVideoCaptions({
    auth,
    mediaID,
  });

  const fileRef = useRef();

  useEffect(() => {
    if (!videoURL) return;

    const mediaFromUrl = videoURL.substring(
      videoURL.lastIndexOf("/") + 1,
      videoURL.length
    );
    setMediaID(mediaFromUrl);
  }, [videoURL]);

  useEffect(() => {
    setCaptionPluginEnabled(captionsPlugin?.on === "true");
    setCaptionsOnByDefault(captionsPlugin?.onByDefault === "true");
    const defaultLang = captions.find(
      (caption) => caption.language === captionsPlugin?.language
    );
    if (defaultLang) {
      setDefaultLanguage({
        label: defaultLang.english_name,
        value: defaultLang.language,
      });
    }
  }, [captions, captionsPlugin]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setLabel(file.name);
    }
  };

  const onUploadHandler = async () => {
    try {
      const doesCaptionAlreadyExist = captions.some(
        (caption) => caption.language === language
      );

      if (doesCaptionAlreadyExist) {
        setWarning(
          `Caption for "${language}" language already exists. Delete it first and try again.`
        );
        return;
      }

      await uploadSrtFile({ file, language });
      setNewCaptionUpload(false);
      toast.success(`'${file.name}' uploaded!`);
    } catch (error) {}
  };

  const deleteCaptionSelectHandler = (caption) => {
    setLanguageForDeletion(caption.english_name);
    setShowDeleteConfirmModal(true);
  };

  const deleteCaptionHandler = async () => {
    try {
      await deleteSrtFile({
        language: captions.find((c) => c.english_name === languageForDeletion)
          .language,
      });
      setShowDeleteConfirmModal(false);
      toast.success(`'${languageForDeletion}' deleted!`);
    } catch (error) {
      toast.error(error.response.data);
    }
  };

  const downloadSrtFileHandler = async (language) => {
    try {
      const { data } = await showSrtFile({ language });
      const blob = new Blob([data.text], {
        type: "text",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = data.english_name;
      link.click();
    } catch (error) {
      toast.error(error.response.data);
    }
  };

  const onSubmitSettingsHandler = async () => {
    try {
      await updateCustomizations({
        body: {
          plugin: {
            "captions-v1": {
              on: captionsPluginEnabled,
              onByDefault: captionsOnByDefault,
              language: defaultLanguage?.value,
            },
          },
        },
      });
      onClose();
      toast.success("Captions settings saved.");
    } catch (error) {
      toast.error(error.response.data);
    }
  };

  return (
    <Modal
      show={true}
      title="Caption Settings"
      onClose={onClose}
      onSubmit={onSubmitSettingsHandler}
      submitButtonText="Save Caption Settings"
      submitValid={!loading}
      compact
    >
      {loading && <LoaderInline />}
      {!loading && (
        <>
          <section className="mb-2">
            <header className="flex items-center justify-between mb-1">
              <h4 className="">Available Captions </h4>
              {!newCaptionUpload && (
                <p
                  className="flex items-center font-basis-bold link"
                  onClick={() => setNewCaptionUpload(true)}
                >
                  Upload new .srt file
                </p>
              )}
            </header>
            {captions.length === 0 && (
              <p className="leading-4 text-center bg-hka_gray-ultraLight font-basis-medium">
                This video does not contain any captions.
              </p>
            )}
            {captions.map((caption) => (
              <div
                key={caption.language}
                className="flex items-center leading-4 border-t border-hka_gray-light"
              >
                <div className="flex-1">
                  <span
                    className="w-auto text-base letter link--bold font-basis-bold"
                    onClick={() => downloadSrtFileHandler(caption.language)}
                  >
                    {caption.english_name}
                  </span>
                </div>
                <button
                  className="min-w-0 px-0 cursor-pointer h-3.2 w-3.2 transition-all transform bg-hka_gray-light hover:bg-gray-200 mr-0.5"
                  onClick={() => downloadSrtFileHandler(caption.language)}
                >
                  <span className="icon-download" data-tip="Download"></span>
                </button>
                <button
                  className="min-w-0 px-0 cursor-pointer h-3.2 w-3.2 transition-all transform bg-hka_gray-light hover:bg-gray-200"
                  onClick={() => deleteCaptionSelectHandler(caption)}
                >
                  <span className="icon-trash" data-tip="Remove"></span>
                </button>
              </div>
            ))}
          </section>

          {newCaptionUpload && (
            <section className="pb-2 mb-2 border-b border-hka_gray-light">
              <h4>Upload SRT file</h4>
              {!!warning && (
                <Note type="warning">
                  <p>Warning: {warning}</p>
                </Note>
              )}
              {!!error && (
                <Note type="danger">
                  <p>
                    <span className="font-basis-bold">Error:</span> {error}
                  </p>
                </Note>
              )}
              <div className="flex flex-col justify-end mt-0.5">
                <label
                  htmlFor="caption_upload"
                  className="flex flex-col items-center justify-center w-full p-2 m-auto mb-1 text-base border border-dashed rounded-lg cursor-pointer md:min-w-20 border-hka_gray-border"
                >
                  <span className="flex items-center justify-center icon-arrow up"></span>
                  <span>{labal ? labal : "Select .srt file to upload"}</span>
                </label>
                <input
                  id="caption_upload"
                  ref={fileRef}
                  className=""
                  type="file"
                  onChange={handleFileChange}
                  accept=".srt"
                />
                <div className="flex space-x-1">
                  <div className="flex items-center flex-1 space-x-1">
                    <p>Language</p>
                    <input
                      className={
                        "h-4 flex-1 pl-1 pr-1 text-base rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray border p-1"
                      }
                      value={language}
                      onChange={(e) => setLanguage(e.target.value)}
                    />
                  </div>
                  <button
                    className={`button button--default`}
                    onClick={() => setNewCaptionUpload(false)}
                  >
                    <p className="hidden sm:flex">Cancel</p>
                    <span className="icon-close sm:hidden"></span>
                  </button>

                  <button
                    onClick={onUploadHandler}
                    disabled={!file}
                    className={`${
                      !file
                        ? "text-hka_gray-light"
                        : "button bg-green-100 hover:bg-green-200 text-hka_green"
                    } flex-none button`}
                  >
                    <span className="hidden sm:flex">Upload</span>
                    <span className="icon-arrow up sm:hidden"></span>
                  </button>
                </div>
                <a
                  className="text-xs link font-basis-bold"
                  href="https://en.wikipedia.org/wiki/ISO_639-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  Use ISO-639–2 codes
                </a>
              </div>
            </section>
          )}

          <section className="flex justify-between pb-2 mb-2 border-b border-hka_gray-light">
            <div className="w-3/4">
              <h4>Enable Captions</h4>
              <p className="text-sm">
                Enable or disable video captions for this lesson.
              </p>
            </div>
            <section className="flex items-center">
              <label className="switch">
                <input
                  type="checkbox"
                  name="has_weekly_quiz"
                  disabled={captions.length === 0}
                  checked={captionsPluginEnabled}
                  onChange={(e) => setCaptionPluginEnabled(e.target.checked)}
                  className="cursor-pointer"
                />
                <span className="slider"></span>
              </label>
            </section>
          </section>

          <section className="flex justify-between pb-2 mb-2 border-b border-hka_gray-light">
            <div className="w-3/4">
              <h4 className="mb-0.5">Captions on by default?</h4>
              <p className="text-sm">
                This determines whether captions will display automatically for
                this video when it loads.
              </p>
            </div>
            <section className="flex items-center">
              <label className="switch">
                <input
                  type="checkbox"
                  name="has_weekly_quiz"
                  disabled={captions.length === 0}
                  checked={captionsOnByDefault}
                  onChange={(e) => setCaptionsOnByDefault(e.target.checked)}
                  className="cursor-pointer"
                />
                <span className="slider"></span>
              </label>
            </section>
          </section>

          <section className="flex justify-between space-x-2">
            <div className="w-1/2">
              <h4 className="mb-0.5">Default language</h4>
              <p className="text-sm">
                Select the default language for your video captions.
              </p>
            </div>
            <Select
              onChange={(value) => setDefaultLanguage(value)}
              value={defaultLanguage}
              placeholder="Select Default Language"
              isMulti={false}
              options={captions.map((caption) => ({
                label: caption.english_name,
                value: caption.language,
              }))}
              disabled={captions.length === 0}
              classNamePrefix="select"
              className="w-1/2 dropdown--default-language"
              maxMenuHeight={190}
              unstyled
            />
          </section>

          <DeleteCaptionConfirmModal
            showModal={showDeleteConfirmModal}
            languageForDeletion={languageForDeletion}
            onSubmit={deleteCaptionHandler}
            onClose={() => setShowDeleteConfirmModal(false)}
          />
        </>
      )}
    </Modal>
  );
};

export default CaptionsModal;
