import { useParams } from "react-router-dom";

import Modal from "../../Modal/Modal";
import EngagementMessageCard from "../ListEngagementsMessages/EngagementMessageCard";

import { composeTitle } from "../../../utils/engagementsUtils";

import { useEngagmentStatisticsData } from "../../../hooks/data-hooks/engagements";

const PreviewMessageModal = ({ showModal, onClose, message }) => {
  const { id } = useParams();
  const title = composeTitle(message);
  const { isLoading: isLoadingStatistics, data: engagmentStatistics } =
    useEngagmentStatisticsData({ id });
  return (
    <Modal
      show={showModal}
      id="preview-engagement-message-modal"
      onClose={onClose}
      title="Message Preview"
      compact
      noFooter
    >
      <EngagementMessageCard
        preview
        question={message}
        questionTitle={title}
        onQuestionDeleteAttempt={() => {}}
        onQuestionEdit={() => {}}
        messageStatistics={engagmentStatistics?.find(
          (data) => data.question === message.id
        )}
        isLoadingStatistics={isLoadingStatistics}
      />
    </Modal>
  );
};

export default PreviewMessageModal;
