import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const VantaError = () => {
  const navigate = useNavigate();

  useEffect(() => {
    toast.error(
      'There was a trouble with adding Vanta. Please contant your administrator.'
    );
    navigate('/settings?view=integrations');
  }, [navigate]);

  return null;
};

export default VantaError;
