import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  evidenceModalOpened: false,
  curriculumTrainings: [],
  selectedTraining: undefined,
};

const trainingEvidenceSlice = createSlice({
  name: "trainingEvidence",
  initialState,
  reducers: {
    setModalVisibility(state, action) {
      state.evidenceModalOpened = action.payload.isOpened;
    },
    setCurriculumTrainings(state, action) {
      state.curriculumTrainings = action.payload.trainings;
      if (action.payload.trainings.length > 0) {
        state.selectedTraining = action.payload.trainings[0];
      }
    },
    selectTraining(state, action) {
      state.selectedTraining = action.payload.selectedTraining;
    },
    markTrainingAsDownloaded(state, action) {
      const suffix = " - (Downloaded)";
      state.curriculumTrainings = state.curriculumTrainings.map((t) => {
        if (
          t.value === action.payload.trainingID &&
          !t.label.endsWith(suffix)
        ) {
          const data = {
            value: t.value,
            label: t.label + suffix,
          };
          state.selectedTraining = data;
          return data;
        }
        return t;
      });
    },
  },
});

export const trainingEvidenceActions = trainingEvidenceSlice.actions;

export default trainingEvidenceSlice.reducer;
