import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import validator from 'validator';

import {
  useCompanyStreamData,
  useCreateStream,
  useUpdateStream,
  useUpdateStreamSubscription,
} from '../../../hooks/data-hooks/streams/index.js';

import Modal from '../../Modal/Modal';
import { useAppContext } from '../../../libs/contextLib.js';

import OwnersInput from '../../Base/OwnersInput.js';

const StreamModal = ({ show, mode, selectedStream, currentPage, onClose }) => {
  const { isAuthenticated } = useAppContext();
  const queryClient = useQueryClient();

  const [title, setTile] = useState('');
  const [iconURL, setIconURL] = useState('');
  const [isIconURLValid, setIsIconURLValid] = useState(true);
  const [description, setDescription] = useState('');
  const [isFree, setIsFree] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [owners, setOwners] = useState([]);

  const { data: companyStream } = useCompanyStreamData({
    companyID: isAuthenticated.company,
    streamID: selectedStream?.id,
  });

  const { mutate: updateStreamSubscription, isLoading: submittingOwners } =
    useUpdateStreamSubscription({
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          'company-stream',
          isAuthenticated.company,
          data.id,
        ]);
        onClose();
      },
    });

  useEffect(() => {
    if (!!selectedStream) {
      setTile(selectedStream.title);
      setIconURL(selectedStream.icon_url);
      setIsIconURLValid(validator.isURL(selectedStream.icon_url));
      setDescription(selectedStream.description);
      setIsFree(selectedStream.is_free);
    }
    // employees context causing reevaluating. keep commented for now.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!companyStream) {
      setOwners(
        companyStream.owners.map((o) => ({ value: o.id, label: o.name }))
      );
    }
  }, [companyStream]);

  const { mutate: createStream, isLoading: submittingCreate } = useCreateStream(
    {
      currentPage,
      onSuccess: (res) => {
        updateStreamSubscription({
          companyID: isAuthenticated.company,
          streamID: res.id,
          payload: { owners: owners.map((o) => o.value) },
        });
        toast.success(`Haekka Stream created`);
      },
    }
  );
  const { mutate: updateStream, isLoading: submittingUpdate } = useUpdateStream(
    {
      currentPage,
      onSuccess: () => {
        toast.success(`Haekka Stream updated`);
        updateStreamSubscription({
          companyID: isAuthenticated.company,
          streamID: selectedStream.id,
          payload: { owners: owners.map((o) => o.value) },
        });
      },
    }
  );

  useEffect(() => {
    setIsFormValid(
      title?.length > 0 &&
        iconURL?.length > 0 &&
        description?.length > 0 &&
        isIconURLValid &&
        owners.length > 0 &&
        !submittingCreate &&
        !submittingUpdate &&
        !submittingOwners
    );
  }, [
    description?.length,
    iconURL?.length,
    isIconURLValid,
    owners.length,
    submittingCreate,
    submittingOwners,
    submittingUpdate,
    title?.length,
  ]);

  const modalTitle = mode === 'EDIT_STREAM' ? 'Edit Stream' : 'Create Stream';

  const submitHandler = () => {
    setIsFormValid(false);
    const payload = {
      title,
      icon_url: iconURL,
      description,
      is_free: isFree,
    };
    if (mode === 'CREATE_STREAM') {
      createStream({
        payload,
      });
    }
    if (mode === 'EDIT_STREAM') {
      updateStream({
        streamID: selectedStream.id,
        payload: { ...payload, id: selectedStream.id },
      });
    }
  };

  return (
    <Modal
      show={show}
      title={modalTitle}
      onClose={onClose}
      onSubmit={submitHandler}
      submitButtonText='Save Stream'
      submitValid={isFormValid}
      expandable
    >
      <section className='mb-2.5'>
        <h4 className='mb-1 font-basis-bold'>
          Stream Name <span className='text-hka_gray'>(Required)</span>
        </h4>
        <input
          type='text'
          name='title'
          value={title}
          placeholder='Enter Stream Name'
          className='w-full h-4 pl-1 pr-1 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray-dark border-hka_gray-lights hka-input mb-1'
          onChange={(e) => setTile(e.target.value)}
        />
      </section>

      <section className='mb-2.5'>
        <h4 className='mb-1 font-basis-bold'>
          Icon URL <span className='text-hka_gray'>(Required)</span>
        </h4>
        <input
          type='text'
          name='icon_url'
          value={iconURL}
          placeholder='Enter Icon URL'
          className='w-full h-4 pl-1 pr-1 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray-dark border-hka_gray-lights hka-input mb-1'
          onChange={(e) => {
            setIconURL(e.target.value);
            setIsIconURLValid(validator.isURL(e.target.value));
          }}
        />
        {!isIconURLValid && (
          <span className='block text-xs text-hka_red top-full'>
            Please enter a valid URL
          </span>
        )}
      </section>

      <section className='mb-3'>
        <h4 className='mb-1 font-basis-bold'>
          Stream Description <span className='text-hka_gray'>(Required)</span>
        </h4>
        <textarea
          type='text'
          name='description'
          value={description}
          className='w-full min-h-7 pl-1 pr-1 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray-dark border-hka_gray-lights hka-input mb-1'
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
      </section>

      <section className='mb-3'>
        <OwnersInput
          owners={owners}
          setOwners={setOwners}
          instanceOf={'stream'}
        />
      </section>

      <section className='mb-2.5 flex'>
        <section className='flex items-center'>
          <label className='switch'>
            <input
              type='checkbox'
              name='is_free'
              checked={!isFree}
              onChange={(e) => setIsFree(!e.target.checked)}
              className='cursor-pointer'
            />
            <span className='slider'></span>
          </label>
        </section>
        <h4 className='ml-1 font-basis-bold'>Is this a paid stream?</h4>
      </section>
    </Modal>
  );
};

export default StreamModal;
