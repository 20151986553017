import { useDispatch, useSelector } from 'react-redux';
import Callout from '../../../Callout';
import UpgradePlanButton from '../../../UpgradePlanButton';
import Gusto from './Gusto/Gusto';
import Okta from './Okta/Okta';
import { useAppContext } from '../../../../libs/contextLib';
import { getIntegrationsAction } from '../../../../store/settingsActions';

const calloutMessage =
  String.fromCodePoint('0x1F44B') +
  ` Your plan does not include access to HRIS integrations. Upgrade your plan to be able to integrate your HR tools with Haekka.`;

const HrisIntegrations = ({ payPlanIncludesHRIS }) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAppContext();

  const { integrations } = useSelector((state) => state.settings);
  if (integrations === undefined) {
    dispatch(
      getIntegrationsAction({
        auth: isAuthenticated,
      })
    );
  }

  // this one is relic - it should be refactored together with Gusto (gusto removal, syncing options)
  // api: mergeUpdate, mergeRemoveIntegration,
  // const [gustoRemoveEmployees, setGustoRemoveEmployees] = useState(false);

  // const onGustoRemove = async (e) => {
  //   e.stopPropagation();
  //   await api.mergeRemoveIntegration({
  //     auth: isAuthenticated,
  //     integration: 'gusto',
  //     remove_employees: gustoRemoveEmployees,
  //   });
  // };

  return payPlanIncludesHRIS ? (
    <div className='p-2 mb-4 bg-white shadow-light rounded-xl col-span-full'>
      <h3 className='mb-1 setting__title'>HRIS Integrations</h3>
      <div className='flex gap-2.2'>
        {/* <Gusto /> */}
        <Okta />
      </div>
    </div>
  ) : (
    <div className='col-span-full'>
      <Callout title={'Haekka'} message={calloutMessage} permanent date={false}>
        <UpgradePlanButton parentCpmToTrack={'Settings'} />
      </Callout>
    </div>
  );
};

export default HrisIntegrations;
