import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import TRAINING_RENEW_OPTIONS from '../utils/renewOptions';
import UpgradePlanButton from './UpgradePlanButton';
import useDebounce from '../utils/custom-hooks/useDebounce';
import Callout from '../components/Callout';
import * as api from '../api';
import { onError } from '../libs/errorLib';
import TrainingTagInput from './TrainingTag/TrainingTagInput';
import { formatTrainingEndDate } from '../utils/formatTrainingEndDate';
import { addMonths, format } from 'date-fns';
import RenewTrainingDatePicker from './RenewTrainingDatePicker';
import GradedTrainingFormStep from './GradedTrainingFormStep';
import { convertRenewalPeriodToText } from '../utils/training';
import DoceboInput from './Training/DoceboInput';
import ShowCorrectnessIndicatorOnResults from './Training/ShowCorrectnessIndicatorOnResults';
import OwnersInput from './Base/OwnersInput';
import SeismicInput from './Training/SeismicInput';

const createNote = (
  <p className='my-1 text-sm'>
    When creating a training you'll automatically be added as an owner. You can
    also add other owners so they can be notified when users complete this
    training.
  </p>
);

function StepOneTraining({
  isTitleCurriculumTechincalValid,
  setIsTitleCurriculumTechincalValid,
  isAuthenticated,
  curriculums,
  curriculum,
  employeeDetails,
  setCurriculum,
  curriculumTypes,
  curriculumType,
  setCurriculumType,
  title,
  setTitle,
  subtitle,
  setSubtitle,
  renewCycle,
  setRenewCycle,
  daysTillDue,
  setDaysTillDue,
  customizeTraining,
  setCustomizeTraining,
  calloutMessage,
  tags,
  setTags,
  suggestions,
  updateSuggestions,
  hasBranchingScenario,
  setHasBranchingScenario,
  renewDate,
  setRenewDate,
  gradingThreshold,
  setGradingThreshold,
  isThresholdCheked,
  setIsThresholdCheked,
  owners,
  setOwners,
  admins,
  renewOnTheSameDayForEveryone,
  setRenewOnTheSameDayForEveryone,
  gradingThresholdDisabled,
  doceboTraining,
  setDoceboTraining,
  doceboManagerNotifications,
  setDoceboManagerNotifications,
  doceboManagerNotificationsSchedule,
  setDoceboManagerNotificationsSchedule,
  showCorrectnessIndicatorOnResults,
  setShowCorrectnessIndicatorOnResults,
  seismicContent,
  setSeismicContent,
}) {
  const curriculumObject = curriculums?.find((item) => item.id === curriculum);
  const [nameParamRetrievedCurriculums, setNameParamRetrievedCurriculums] =
    useState([]);
  const [chosenCurriculumType, setChosenCurriculumType] = useState(null);

  const updateCurriculum = (e) => {
    setCurriculum(e.target.value);
    const chosenCurriculum = curriculums?.find(
      (item) => item.id === e.target.value
    );
    if (chosenCurriculum) {
      setTitle(chosenCurriculum.title);
      setCurriculumType(chosenCurriculum.curriculum_type);
    }
  };

  const debouncedTitle = useDebounce(title, 300);

  useEffect(() => {
    const getCurriculumsByTitle = async () => {
      try {
        const { results } = await api.getCompanyCurriculumByTitle({
          auth: isAuthenticated,
          title,
        });
        setNameParamRetrievedCurriculums(results);
      } catch (error) {
        onError(error);
      }
    };
    getCurriculumsByTitle();
  }, [debouncedTitle]);

  useEffect(() => {
    const chosenCurriculum = curriculums?.find(
      (item) => item.id === curriculum
    );
    nameParamRetrievedCurriculums.every((curriculum) => {
      if (
        curriculum != null &&
        chosenCurriculum != null &&
        curriculum.company != null &&
        curriculum.title === title.trim() &&
        curriculum.curriculum_type === curriculumType &&
        curriculum.is_technical === chosenCurriculum.is_technical
      ) {
        setIsTitleCurriculumTechincalValid(false);
        return false;
      } else {
        setIsTitleCurriculumTechincalValid(true);
        return true;
      }
    });
  }, [
    nameParamRetrievedCurriculums,
    curriculumType,
    title,
    curriculums,
    curriculum,
    setIsTitleCurriculumTechincalValid,
  ]);

  useEffect(() => {
    const chosenCurriculumType = curriculumTypes?.find(
      (item) => item.id === curriculumType
    );
    setChosenCurriculumType(chosenCurriculumType);
  }, [curriculumType, curriculumTypes]);

  return (
    <>
      {employeeDetails &&
        (!employeeDetails.company_details?.all_content ||
          !employeeDetails.company_details?.custom_content) &&
        curriculums?.length !== 0 &&
        calloutMessage && (
          <Callout title={'Haekka'} message={calloutMessage}>
            <UpgradePlanButton parentCpmToTrack={'Create Training Modal'} />
          </Callout>
        )}
      <section className='mb-3'>
        <h4>Customize this training?</h4>
        <p className='mb-1 text-sm'>
          Haekka allows full training customization. Tailor lessons, questions,
          and more to fit your specific needs.
        </p>
        <div className='flex items-center'>
          <label className='switch'>
            <input
              type='checkbox'
              name='users_completed_training_admin'
              checked={customizeTraining}
              onChange={() => {
                setCustomizeTraining((p) => !p);
              }}
              disabled={
                !employeeDetails?.company_details.all_content ||
                !employeeDetails?.company_details.custom_content
              }
            />
            <span
              className={`slider ${
                !employeeDetails?.company_details.all_content ||
                !employeeDetails?.company_details.custom_content
                  ? 'bg-gray-300'
                  : ''
              }`}
            ></span>
          </label>
        </div>
      </section>
      <section className='w-full mb-3'>
        <h4 className='mb-0.5'>
          Training Template{' '}
          <span className='text-sm text-hka_gray'>(Required)</span>
        </h4>
        <select
          value={curriculum}
          onChange={updateCurriculum}
          disabled={curriculums?.length === 0}
        >
          <option value=''>-- Select Training Template --</option>
          {curriculums?.map((item) => (
            <option key={item.id} value={item.id} title={item.title}>
              {item.title}
              {item.is_available_for_free && ' 🆓'}
            </option>
          ))}
        </select>
        {curriculums?.length === 0 && (
          <div className='mt-3'>
            <Callout
              title={'Haekka'}
              message={
                'You already have purchased all trainings available for your plan.'
              }
            >
              <UpgradePlanButton parentCpmToTrack={'Create Training Modal'} />
            </Callout>
          </div>
        )}
        {employeeDetails &&
          employeeDetails.company_details.all_content === false &&
          curriculumObject?.is_available_for_free === false && (
            <Callout
              title='Haekka'
              message={
                <>
                  <span className='text-md'>&#128075;</span> Hey! It looks like
                  your plan doesn't include this training. Upgrade your plan to
                  access Haekka's entire library of content.
                </>
              }
            >
              <UpgradePlanButton parentCpmToTrack={'Create Training Modal'} />
            </Callout>
          )}
      </section>
      {customizeTraining && (
        <>
          {' '}
          <section className='w-full mb-2'>
            <h4 className='mb-0.5'>
              Training Title{' '}
              <span className='text-sm text-hka_gray'>(Required)</span>
            </h4>
            <input
              type='text'
              placeholder='Enter Training Title'
              className={`text-base w-full rounded-lg border p-1 outline-none ${
                !isTitleCurriculumTechincalValid
                  ? 'text-red-500 bg-red-50 border-red-500 border p-1'
                  : 'text-hka_gray-dark border-hka_gray-lights hka-input'
              }`}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <h5>
              {!isTitleCurriculumTechincalValid && (
                <span className='text-red-500'>
                  Training named {title} with category{' '}
                  {chosenCurriculumType?.name} already exists.
                </span>
              )}
            </h5>
          </section>
          {/* Training Sub-title */}
          <section className='w-full mb-2'>
            <h4 className='mb-0.5'>
              Training Sub-Title{' '}
              <span className='text-sm text-hka_gray'>(Optional)</span>
            </h4>
            <input
              type='text'
              placeholder='Enter Training Subtitle'
              className={`text-base w-full rounded-lg border p-1 outline-none text-hka_gray-dark border-hka_gray-lights hka-input`}
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              maxLength={254}
            />
          </section>
          <section className='w-full mb-2'>
            <h4 className='mb-0.5'>
              Training Category{' '}
              <span className='text-sm text-hka_gray'>(Required)</span>
            </h4>
            <select
              className={`hka-dropdown outline-none ${
                !isTitleCurriculumTechincalValid
                  ? 'text-red-500 bg-red-50 border-red-500 border p-1'
                  : 'text-hka_gray-dark border-hka_gray-lights hka-input'
              }`}
              name='category'
              id='category-select'
              value={curriculumType}
              onChange={(e) => setCurriculumType(e.target.value)}
            >
              <option value='' className='bg-white text-hka_gray-dark'>
                -- Select Training Category --
              </option>
              {curriculumTypes &&
                curriculumTypes.map((item) => (
                  <option
                    key={item.id}
                    value={item.id}
                    title={item.name}
                    className='bg-white text-hka_gray-dark'
                  >
                    {item.name}
                  </option>
                ))}
            </select>
            <h5>
              {!isTitleCurriculumTechincalValid && (
                <span className='text-red-500'>
                  Training named {title} with category{' '}
                  {chosenCurriculumType?.name} already exists.
                </span>
              )}
            </h5>
          </section>
        </>
      )}

      {/* Days Until Due */}
      <section className='mb-2'>
        <h4 className='font-basis-bold'>
          Days Until Training is Due{' '}
          <span className='text-sm text-hka_gray'>(Optional)</span>
        </h4>
        <p className='mb-1 text-sm'>
          This is the number of days an employee has to complete this training
          from the time you assign it.
        </p>
        <input
          type='number'
          autoComplete={'off'}
          onWheel={(event) => event.currentTarget.blur()}
          name='days_till_due'
          value={daysTillDue}
          className='w-full h-4 pl-1 pr-1 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray'
          onChange={(e) => setDaysTillDue(e.target.value)}
          placeholder='Days'
          min={1}
        />
      </section>

      {/* Owners  */}
      <section className='mb-2'>
        <OwnersInput
          owners={owners}
          setOwners={setOwners}
          instanceOf={'training'}
          createNote={createNote}
        />
      </section>

      {/* Tags  */}
      <section className='mb-2'>
        <h4 className='mb-0.5 font-basis-bold'>
          Training Tags{' '}
          <span className='text-sm text-hka_gray'>(Optional)</span>
        </h4>
        <TrainingTagInput
          tags={tags}
          setTags={setTags}
          suggestions={suggestions}
          updateSuggestions={updateSuggestions}
        />
      </section>

      {isAuthenticated?.company_details.has_docebo && customizeTraining && (
        <DoceboInput
          doceboCourse={doceboTraining}
          setDoceboCourse={setDoceboTraining}
          doceboManagerNotifications={doceboManagerNotifications}
          setDoceboManagerNotifications={setDoceboManagerNotifications}
          doceboManagerNotificationsSchedule={
            doceboManagerNotificationsSchedule
          }
          setDoceboManagerNotificationsSchedule={
            setDoceboManagerNotificationsSchedule
          }
        />
      )}

      {isAuthenticated?.company_details.has_seismic && customizeTraining && (
        <SeismicInput
          seismicContent={seismicContent}
          setSeismicContent={setSeismicContent}
        />
      )}

      {/* Renew Options */}
      <section className='mb-3'>
        <h4 className='mb-0.5 font-basis-bold'>
          Renew Options{' '}
          <span className='text-sm text-hka_gray'>(Optional)</span>
        </h4>
        <select
          value={renewCycle}
          onChange={(e) => {
            setRenewCycle(e.target.value);
            setRenewDate(
              addMonths(
                new Date(),
                e.target.value === 'quarterly'
                  ? 3
                  : e.target.value === 'semi-annual'
                  ? 6
                  : e.target.value === 'annual'
                  ? 12
                  : 0
              )
            );
          }}
        >
          {TRAINING_RENEW_OPTIONS.map((renewOption) => (
            <option key={renewOption.id} value={renewOption.id}>
              {renewOption.title}
            </option>
          ))}
        </select>
        <p className='text-sm text-hka_gray ml-0.1 mt-0.1 font-normal'>
          {formatTrainingEndDate(
            format(renewDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
            renewCycle,
            null,
            daysTillDue
          )}
        </p>
        {renewCycle !== 'none' && (
          <div className='flex justify-between my-3'>
            <div>
              <section className='mb-1.5 flex'>
                <section className='flex items-center'>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      name='has_weekly_quiz'
                      checked={renewOnTheSameDayForEveryone}
                      onChange={() =>
                        setRenewOnTheSameDayForEveryone((p) => !p)
                      }
                      className='cursor-pointer'
                    />
                    <span className='slider'></span>
                  </label>
                </section>
                <p className='ml-1 text-sm'>
                  This training will renew for everyone on the same date.
                </p>
              </section>
              <section className='flex'>
                <section className='flex items-center'>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      name='has_weekly_quiz'
                      checked={!renewOnTheSameDayForEveryone}
                      onChange={() =>
                        setRenewOnTheSameDayForEveryone((p) => !p)
                      }
                      className='cursor-pointer'
                    />
                    <span className='slider'></span>
                  </label>
                </section>
                <p className='ml-1 text-sm'>
                  This training will renew{' '}
                  {convertRenewalPeriodToText(renewCycle)} months from when an
                  individual is assigned.
                </p>
              </section>
            </div>
            <RenewTrainingDatePicker
              renewDate={renewDate}
              renewCycle={renewCycle}
              setRenewDate={(date) => {
                setRenewDate(date);
              }}
              renewOnTheSameDayForEveryone={renewOnTheSameDayForEveryone}
            />
          </div>
        )}
      </section>

      {/* Branching Toggle */}
      {/* {customizeTraining && (
        <section className="mb-2">
          <h4 className="font-basis-bold">Branching scenarios?</h4>
          <p className="mb-1 text-sm">
            Branching scenarios allow you direct users to a different lesson
            depending on how they answer the scenario question.
          </p>
          <section className='flex items-center'>
            <label className='switch'>
              <input
                type='checkbox'
                name='has_weekly_quiz'
                checked={hasBranchingScenario}
                onChange={() => setHasBranchingScenario((p) => !p)}
                className='cursor-pointer'
              />
              <span className='slider'></span>
            </label>
          </section>
        </section>
      )} */}

      <GradedTrainingFormStep
        gradingThreshold={gradingThreshold}
        setGradingThreshold={setGradingThreshold}
        isThresholdCheked={isThresholdCheked}
        setIsThresholdCheked={setIsThresholdCheked}
        gradingThresholdDisabled={gradingThresholdDisabled}
      />

      <ShowCorrectnessIndicatorOnResults
        showCorrectnessIndicatorOnResults={showCorrectnessIndicatorOnResults}
        setShowCorrectnessIndicatorOnResults={
          setShowCorrectnessIndicatorOnResults
        }
      />
    </>
  );
}

export default StepOneTraining;
