import { createContext, useContext } from "react";

const initialState = {
  imapConfigurationTestResponses: [],
  setIMAPConfigurationTestResponses: () => {},
};

export const PhishingSettingsContext = createContext(initialState);

export default function usePhishingSettingsContext() {
  return useContext(PhishingSettingsContext);
}
