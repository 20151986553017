import { lessonProperties } from "../../utils/trainingUtils";
import { useSelector, useDispatch } from "react-redux";
import { lessonActions } from "../../store/lessonSlice";

const SimpleLessonSection = () => {
  const dispatch = useDispatch();

  const { isSimpleLesson } = useSelector((state) => state.lesson);

  return (
    <section className="flex pb-2 mb-2 space-x-2 border-b border-hka_gray-light">
      <div>
        <h4 className="">Enable Simple Lesson</h4>
        <p className="text-sm">
          You have the option of creating a simple (certain fields removed), or
          advanced lesson. To see how a Simple and Advanced lesson differ, see
          <a
            className="inline-flex items-center link ml-0.5"
            href="https://www.haekka.com/documentation/2021-09-02-haekka-release-1-0-0-and-1-1-0"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            this article.
          </a>
        </p>
      </div>
      <div className="flex items-center">
        <label className="switch">
          <input
            type="checkbox"
            name="users_completed_training_admin"
            checked={isSimpleLesson}
            onChange={(e) =>
              dispatch(
                lessonActions.updateLesson({
                  property: lessonProperties.IS_SIMPLE_LESSON,
                  value: e.target.checked,
                })
              )
            }
          />
          <span className="slider"></span>
        </label>
      </div>
    </section>
  );
};

export default SimpleLessonSection;
