import { useQuery } from '@tanstack/react-query';
import { getSimpleEmployeeTrainings } from '../../../api/employeeTrainingsApi';

export const useSimpleEmployeeTrainingsData = ({
  employeeID,
  page,
  pageSize,
  staleTime = 0,
}) => {
  return useQuery({
    queryKey: ['simple-employee-trainings', employeeID, page, pageSize],
    queryFn: () =>
      getSimpleEmployeeTrainings({
        employeeID,
        page,
        pageSize,
      }),
    staleTime,
    keepPreviousData: true,
  });
};

export const prefetchSimpleEmployeeTrainingsData = ({
  queryClient,
  employeeID,
  page = 1,
  pageSize = 10,
  staleTime = 0,
}) => {
  queryClient.prefetchQuery({
    queryKey: ['simple-employee-trainings', employeeID, page, pageSize],
    queryFn: () =>
      getSimpleEmployeeTrainings({
        employeeID,
        page,
        pageSize,
      }),
    staleTime,
  });
};
