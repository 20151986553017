import { useState } from 'react';
import useCopyToClipboard from '../../../utils/custom-hooks/useCopyToClipboard';
import { useAppContext } from '../../../libs/contextLib';
import {
  useConfigureExternalAppWebhook,
  useWebhookIntegrationAuthHeaderData,
} from '../../../hooks/data-hooks/integrations';
import toast from 'react-hot-toast';

const backendUrl = 'https://app.haekka.com/api/integrations/webhook/seismic';

const SeismicWebHook = ({
  seismicApp,
  addNewSeismicUsers,
  setAddNewSeismicUsers,
}) => {
  const { isAuthenticated } = useAppContext();

  const [copyToClipboardBackendURL, { success: backendURLcopied }] =
    useCopyToClipboard();

  const [hookConfigOption, setHookConfigOption] = useState('automatic');

  const { refetch: getAuthToken } = useWebhookIntegrationAuthHeaderData({
    companyID: isAuthenticated.company,
    integration: 'SeismicInstallation',
    onSuccess: (response) => {
      const { token } = response;
      copyToClipboardBackendURL(token);
    },
  });

  const onConfigureSuccess = (res) => {
    if (res.success) {
      toast.success('Seismic Webhook configured successfully');
    }
    if (!res.success) {
      toast.error(
        <section>
          <p>
            Configuring webhook failed with following message:{' '}
            <b>{res.messages[0]}</b>
          </p>
        </section>
      );
    }
  };

  const { mutate: configureWebhook, isLoading: submittingConfigAction } =
    useConfigureExternalAppWebhook({ callbackFn: onConfigureSuccess });

  const configureWebhookHandler = () => {
    configureWebhook({
      integrationName: 'seismic',
      companyID: isAuthenticated.company,
    });
  };

  return (
    <section className='mt-2'>
      <div className='flex justify-between py-2'>
        <p className='text-hka_gray-dark'>
          Automatically add{' '}
          <span className='font-basis-bold'>
            new users and sync seismic content
          </span>
        </p>
        <label className='switch'>
          <input
            type='checkbox'
            name='select-all-users'
            checked={addNewSeismicUsers}
            onChange={(e) => setAddNewSeismicUsers(e.target.checked)}
            className='cursor-pointer'
          />
          <span className='slider'></span>
        </label>
      </div>
      {!addNewSeismicUsers && (
        <p>To sync your Seismic assignments, keep the switch above ON.</p>
      )}

      {addNewSeismicUsers && !seismicApp.webhook_verified && (
        <section className='text-base text-hka_gray-dark'>
          This feature allows training to sync with Seismic assignments in real
          time. To use it, you have to setup a webhook in Seismic platform. You
          can do this in two ways:
          <section>
            <section className='p-2 bg-gray-100 rounded-md border border-gray-200 mt-2'>
              <input
                type='radio'
                id='automatic'
                value='automatic'
                checked={hookConfigOption === 'automatic'}
                onChange={(e) => setHookConfigOption(e.target.value)}
              />
              <label
                htmlFor='automatic'
                className='text-base font-bold text-gray-500 cursor-pointer'
              >
                Let us configure it for you (recommended):
              </label>
            </section>

            {hookConfigOption === 'automatic' && (
              <section className='mt-2 p-2 bg-white border border-gray-100 rounded-md'>
                <section className='flex flex-col'>
                  <section className='flex flex-col'>
                    <p className='text-base text-gray-500'>
                      <b>IMPORTANT:</b> Before clicking the "Create Webhook"
                      button below, go to your Seismic platform {'->'} Settings{' '}
                      {'->'} API & Webhook settings, and make sure that you
                      don't have the "Reporting Webhook" specified. The two
                      fields in the Reporting Webhook section should be empty.
                      Be aware that clicking the "Create Webhook" button will
                      overwrite those fields.
                    </p>
                  </section>

                  <section className='w-full mt-2'>
                    <button
                      onClick={configureWebhookHandler}
                      disabled={submittingConfigAction}
                      className='w-full px-4 py-2 text-blue-500 font-basis-bold bg-blue-100 hover:bg-blue-200 disabled:opacity-50'
                    >
                      Create Webhook
                    </button>
                  </section>
                </section>
              </section>
            )}

            <section>
              <div className='p-2 bg-gray-100 rounded-md border border-gray-200 mt-2'>
                <input
                  type='radio'
                  id='manual'
                  value='manual'
                  checked={hookConfigOption === 'manual'}
                  onChange={(e) => setHookConfigOption(e.target.value)}
                />
                <label
                  htmlFor='manual'
                  className='text-base font-bold text-gray-500 cursor-pointer'
                >
                  Configure it manually by yourself:
                </label>
              </div>
              {hookConfigOption === 'manual' && (
                <section className='mt-1'>
                  <div className='flex mb-1'>
                    <p className='w-2'>1.</p>
                    <p>
                      Go to your Seismic Platform {'->'} Settings {'->'} API &
                      Webhook. You need to fill out the Reporting Webhook
                      section.
                    </p>
                  </div>
                  <div className='flex mb-1'>
                    <p className='w-2'>2.</p>
                    <p>
                      In the{' '}
                      <b>
                        Upon completion of any lesson by any user, post the
                        results to this URL
                      </b>{' '}
                      field enter the Haekka's backend URL{' '}
                      <span
                        className='text-orange cursor-pointer'
                        onClick={() => copyToClipboardBackendURL(backendUrl)}
                      >
                        ({backendURLcopied ? 'copied' : 'copy to clipboard'})
                      </span>
                    </p>
                  </div>
                  <div>
                    <div className='flex mb-1'>
                      <p className='w-2'>3.</p>
                      <p>
                        In the{' '}
                        <b>Add this value to the “Authorization” header</b>{' '}
                        field enter the auth token{' '}
                        <span
                          className='text-orange cursor-pointer'
                          onClick={getAuthToken}
                        >
                          ({backendURLcopied ? 'copied' : 'copy to clipboard'})
                        </span>
                      </p>
                    </div>
                    <div className='flex mb-1'>
                      <section className='w-2 mr-1'></section>
                      <p>
                        Note: This secret is dynamically generated and specific
                        to your organization. We're using it to authenticate
                        your webhook events.
                      </p>
                    </div>
                  </div>
                </section>
              )}

              <section className='mt-2.5'>
                <p className='text-base'>
                  Once your webhook is verified, we will hide this note.{' '}
                  {hookConfigOption === 'manual'
                    ? 'For this option, we will verify your webhook configuration as soon as we receive the first event.'
                    : ''}
                </p>
              </section>
            </section>
          </section>
        </section>
      )}
      {addNewSeismicUsers && seismicApp.webhook_verified && (
        <p>
          Webhook verified! Assignments in your Seismic platform will sync in
          real time.
        </p>
      )}
    </section>
  );
};

export default SeismicWebHook;
