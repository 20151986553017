import { useGlobalStore } from '../../../store';
import { useAppContext } from '../../../libs/contextLib';
import {
  useStreamPostsData,
  useUpdateStreamSubscription,
} from '../../../hooks/data-hooks/streams';

import PostContainer from '../Posts/PostContainer';

const StreamExtendedArea = ({ stream, companyStream }) => {
  const { isAuthenticated } = useAppContext();

  const { setPostModal } = useGlobalStore((state) => state.streams);

  const { mutate: updateStreamSubscription } = useUpdateStreamSubscription({
    companyID: isAuthenticated.company,
    streamID: stream.id,
  });

  const { data: sentPostsData } = useStreamPostsData({
    comapnyID: isAuthenticated.company,
    streamID: stream.id,
    sent: true,
    page: 1,
  });

  const { data: queuedPostsData } = useStreamPostsData({
    comapnyID: isAuthenticated.company,
    streamID: stream.id,
    sent: false,
    page: 1,
  });

  const showQueueWarning =
    (!companyStream?.is_active &&
      !isAuthenticated.company_details.is_global_content_maker) ||
    queuedPostsData?.count === 0;

  const reviewPostsupdateHandler = (e) => {
    updateStreamSubscription({
      companyID: isAuthenticated.company,
      streamID: stream.id,
      payload: { review_posts: e.target.checked },
    });
  };

  return (
    <div className='flex flex-col justify-between w-full p-2 border-t xl:flex-row text-hka_gray-dark border-hka_gray-light'>
      <div className='flex flex-col w-full xl:w-1/3'>
        <h4 className='title text-base mb-0.5 font-basis-bold'>
          Stream Summary
        </h4>
        <p className='text-base'>{stream.description}</p>

        {companyStream?.is_active && (
          <div className='mt-2'>
            <section className='mb-0.5 flex'>
              <section className='flex items-center'>
                <label className='switch'>
                  <input
                    type='checkbox'
                    name='admins_only'
                    checked={companyStream?.review_posts}
                    onChange={reviewPostsupdateHandler}
                    className='cursor-pointer'
                  />
                  <span className='slider'></span>
                </label>
              </section>
              <h4 className='ml-1 font-basis-bold'>
                Review posts before they are sent?
              </h4>
            </section>
            <p className='text-base'>
              One day before each post is sent we will send you a Slack message
              to either Approve, Skip, or Edit the post.
            </p>
            {companyStream?.review_posts && (
              <p className='mt-1 text-base'>
                <b>Note:</b> posts will not send without admin's approval.
              </p>
            )}
          </div>
        )}
        {isAuthenticated.company_details.is_global_content_maker && (
          <div className='mt-2'>
            <button
              type='button'
              className='w-full pl-1 pr-2 button--blue sm:w-auto bg-hka_blue-light'
              onClick={() =>
                setPostModal({
                  show: true,
                  mode: 'CREATE_POST',
                  post: undefined,
                  stream: stream.id,
                })
              }
            >
              <span className='icon-plus text-hka_blue'></span>
              <p className='text-hka_blue'>Create Post</p>
            </button>
          </div>
        )}
      </div>

      <div className='flex flex-col w-full xl:w-1/2'>
        <div className='w-full mt-2 xl:mt-0'>
          <PostContainer
            sent={false}
            isFreeStream={stream.is_free}
            companyStream={companyStream}
            showWarning={showQueueWarning}
            stream={stream}
          />
        </div>

        <div className='w-full mt-2'>
          <PostContainer
            sent={true}
            isFreeStream={stream.is_free}
            companyStream={companyStream}
            showWarning={
              !showQueueWarning &&
              ((!companyStream?.is_active &&
                !isAuthenticated.company_details.is_global_content_maker) ||
                sentPostsData?.count === 0)
            }
            stream={stream}
          />
        </div>
      </div>
    </div>
  );
};

export default StreamExtendedArea;
