import Modal from "../Modal/Modal";

const DeleteWorkflowConfirmationModal = ({ onClose, onSubmit }) => {
  return (
    <Modal
      show={true}
      title="Delete Workflow"
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonText="Delete Workflow"
      submitButtonType="danger"
      compact
    >
      <p>Are you sure you want to delete this workflow?</p>
    </Modal>
  );
};
export default DeleteWorkflowConfirmationModal;
