import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteAssessmentQuestion } from '../../../api/assessmentApi';

export const useDeleteAssessmentQuestion = ({ curriculumID }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteAssessmentQuestion,
    onSuccess: () =>
      queryClient.invalidateQueries(['curriculum-content', curriculumID]),
  });
};
