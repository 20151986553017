import { EditorState, Modifier } from 'draft-js';
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { onError } from '../../libs/errorLib';
import * as api from '../../api';
import toast from 'react-hot-toast';

import './WysiwygRichTextEditor.css';
import { findLinkEntities } from '../../utils/wysiwygEditorHelpers';
import WysiwygEmojisPopup from './WysiwygEmojiPopup';
import { useAppContext } from '../../libs/contextLib';
import WysiwygLinkPopup from './WysiwygLinkPopup';
import WysiwygEditorInnerModal from './WysiwygEditorInnerModal';
import LinkDecorator from './LinkDecorator';

const WysiwygRichTextEditor = ({
  readOnly,
  id,
  questionId,
  contentType,
  onImageAdded,
  editorState,
  onSetEditorState,
  toolbarHidden,
  disableImageUpload = false,
}) => {
  const { isAuthenticated } = useAppContext();
  const editorStateRef = useRef();
  const [showPopup, setShowPopup] = useState(false);
  const [mode, setMode] = useState('');
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');
  const [edit, setEdit] = useState(false);

  if (readOnly) {
    toolbarHidden = true;
  }

  useEffect(() => {
    if (readOnly) {
      document.documentElement.classList.remove('admin-section');
    } else {
      document.documentElement.classList.add('admin-section');
    }
  }, [readOnly]);

  async function uploadImageCallBack(file) {
    try {
      let res = await api.uploadImage({
        auth: isAuthenticated,
        file,
        id: id,
        questionId: questionId ? questionId : '',
        contentType: contentType,
      });
      // setImageIds((prev) => [...prev, res.id]);
      onImageAdded(res.id);

      const obj = { data: { link: res.image_url || res.image_url_public } };
      return obj;
    } catch (e) {
      onError(e);
      toast.error('Error ocured');
    }
  }

  useEffect(() => {
    editorStateRef.current = editorState;
  });

  const onInsertEmoji = (emojimark) => {
    const unified = emojimark.unified.includes('-')
      ? emojimark.unified.substring(0, emojimark.unified.indexOf('-'))
      : emojimark.unified;

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      // emojimark.native,
      String.fromCodePoint('0x' + unified),
      editorState.getCurrentInlineStyle()
    );
    onSetEditorState(
      EditorState.push(editorState, contentState, 'insert-characters')
    );
  };

  const editorStyle = {
    fontSize: '1.4rem',
    lineHeight: '2rem',
  };

  const styleMap = {
    CODE: {
      color: '#e01e5a',
      backgroundColor: 'rgba(var(--sk_foreground_min,29,28,29),.04)',
      border: '1px solid rgba(var(--sk_foreground_low,29,28,29),0.13)',
      borderRadius: '3px',
      fontFamily: 'Monaco,Menlo,Consolas,Courier New,monospace!important',
      lineHeight: '2.5rem',
    },
  };

  function myBlockStyleFn(contentBlock) {
    const type = contentBlock.getType();
    if (type === 'blockquote') {
      return 'blockQuite';
    } else if (type === 'code') {
      return 'blockCode';
    }
  }

  let decorators = [
    {
      strategy: findLinkEntities,
      component: LinkDecorator,
      props: {
        editorStateRef: editorStateRef,
        setEditorState: onSetEditorState,
        setShowPopup,
        setText,
        setUrl,
        readOnly,
      },
    },
  ];

  const toolbarOptions = [
    'inline',
    'list',
    // 'blockType',
  ];

  if (!disableImageUpload) {
    toolbarOptions.push('image');
  }

  return (
    <div>
      {editorState && (
        <>
          {showPopup && (
            <WysiwygEditorInnerModal
              edit={edit}
              setEdit={setEdit}
              setShowPopup={setShowPopup}
              text={text}
              setText={setText}
              url={url}
              setUrl={setUrl}
              editorState={editorState}
              setEditorState={onSetEditorState}
              mode={mode}
              setMode={setMode}
            />
          )}
          <Editor
            customDecorators={decorators}
            blockStyleFn={myBlockStyleFn}
            customStyleMap={styleMap}
            editorStyle={editorStyle}
            // toolbarOnFocus
            handlePastedText={() => false}
            stripPastedStyles={true}
            // wrapperClassName='demo-wrapper'
            editorClassName='wysiwyg_editor'
            toolbarCustomButtons={[
              <WysiwygEmojisPopup onInsertEmoji={onInsertEmoji} />,
              <WysiwygLinkPopup
                setShowPopup={setShowPopup}
                setMode={setMode}
              />,
            ]}
            readOnly={readOnly}
            toolbarHidden={toolbarHidden}
            toolbar={{
              options: toolbarOptions,
              inline: {
                options: ['bold', 'italic', 'strikethrough', 'monospace'],
                bold: { icon: '', className: 'option-inline-bold icon-b' },
                italic: {
                  icon: '',
                  className: 'option-inline-italic icon-italic',
                },
                strikethrough: {
                  icon: '',
                  className: 'option-inline-strikethrough icon-strikethrough',
                },
                monospace: {
                  icon: '',
                  className: 'option-blocktype-code icon-mono',
                },
              },
              list: {
                options: ['ordered', 'unordered'],
                ordered: {
                  icon: '',
                  className: 'option-list-ordered icon-numbered',
                },
                unordered: {
                  icon: '',
                  className: 'option-list-unordered icon-bulleted',
                },
              },
              // blockType: {
              //   inDropdown: false,
              //   options: ['Blockquote', 'Code'],
              //   className: 'block-code',
              //   blockquote: { className: 'option-blocktype-blockquote' },
              // },
              image: {
                uploadCallback: uploadImageCallBack,
                urlEnabled: false,
                uploadEnabled: true,
                previewImage: true,
                alignmentEnabled: false,
                alt: { present: true, mandatory: false },
                inputAccept:
                  'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                defaultSize: { height: 'auto', width: '800px' },
                icon: '',
                className: 'option-image-uploader icon-attachment',
              },
            }}
            editorState={editorState}
            onEditorStateChange={(editorState) => {
              onSetEditorState(editorState);
            }}
          />
        </>
      )}
    </div>
  );
};

export default WysiwygRichTextEditor;
