import { convertToRaw, EditorState } from 'draft-js';
import { convertToSlackNotation } from './wysiwygEditorHelpers';

export const initQuestion = (position = 0) => {
  const emptyEditorState = EditorState.createEmpty();
  return {
    position,
    editorState: emptyEditorState,
    single_select: false,
    single_correct: false,
    answers: [],
    imageIDs: [],
  };
};

export const assessmentRetakesOptions = [
  { value: 'optional', label: 'Optional', isDisabled: false },
  { value: 'force_retake', label: 'Yes', isDisabled: false },
  { value: 'force_continue', label: 'No', isDisabled: false },
];

const isAnswerValid = (answer) => answer.answer.length > 0;

const isQuestionValid = (question) => {
  const answersAreValid =
    question.answers.length > 0 && question.answers.every(isAnswerValid);
  const hasAtLeastOneCorrectAnswer = question.answers.some((a) => a.correct);
  return (
    question.editorState?.getCurrentContent().getPlainText('\u0001') !== '' &&
    answersAreValid &&
    hasAtLeastOneCorrectAnswer
  );
};

export const validateAssessmentModalForm = ({
  title,
  questions,
  gradingThreshold,
  retakes,
  isThisAssessmentTheLastItemInCurriculum,
}) => {
  if (
    gradingThreshold > 0 &&
    isThisAssessmentTheLastItemInCurriculum &&
    retakes.value !== 'force_retake'
  ) {
    return false;
  }
  const questionsAreValid = questions.every(isQuestionValid);
  return (
    title.length > 0 &&
    questionsAreValid &&
    gradingThreshold >= 0 &&
    gradingThreshold <= 100
  );
};

export const getAssessmentPayload = async ({
  title,
  isAuthenticated,
  questions,
}) => ({
  title: title,
  company: isAuthenticated.company,
  questions: await Promise.all(
    questions.map(async (question) => {
      const questionEditorStateRaw = JSON.stringify(
        convertToRaw(question.editorState.getCurrentContent())
      );
      const slack_markup = await convertToSlackNotation(
        question.editorState.getCurrentContent()
      );
      return {
        ...question,
        editorState: undefined, // this is to undefind to omit sending this object in the payload
        editor_content: {
          quiz_editor_state: questionEditorStateRaw,
        },
        slack_markup,
        answers: question.answers.map((answer) => ({
          ...answer,
          id: answer.id.startsWith('question-') ? undefined : answer.id,
        })),
        image_ids: question.imageIDs,
      };
    })
  ),
});
