import { toast } from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import { onError } from "../../../libs/errorLib";
import { generateEngagementEvidenceReport } from "../../../api/engagementsApi";
import { reportEvidenceSuccessMessage } from "../../../utils/reportEvidenceMessages";

export const useRequestEngagementEvidenceReport = ({ onClose }) => {
  return useMutation({
    mutationFn: generateEngagementEvidenceReport,
    onSuccess: () => {
      onClose();
      toast.success(reportEvidenceSuccessMessage);
    },
    onError: (_error) => onError(_error),
  });
};
