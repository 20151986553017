import React, { useState, useEffect, Fragment } from 'react';
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import ErrorBoundary from './components/ErrorBoundary';
import { AppContext } from './libs/contextLib';
import { onError } from './libs/errorLib';
import './App.css';
import { getAuthData, clearAuthData } from './libs/localStorageLib';
import * as api from './api';

import UserNavItem from './components/UserNavItem';
import ReactTooltip from 'react-tooltip';
import { AssignEmployeesProvider } from './store/assign-employeees-context';
import UploadsProgress from './components/VideoUpload/UploadsProgress';

import { useSelector } from 'react-redux';

import { Chart as ChartJS, ArcElement } from 'chart.js';
import { getLoggedInUserDetails } from './utils/commonUtils';
import Footer from './components/Footer';
import AppRoutes from './Routes';
ChartJS.register(ArcElement);

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (_error) => {
      // temporary solution to silence the 404 error
      if (_error.response.status !== 404) {
        onError(_error);
      }
    },
  }),
});

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
);

function App() {
  const navigate = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [connectedWithSlack, setIsConnectedWithSlack] = useState(false);
  const [isGoogleAppInstalled, setIsConnectedWithGoogle] = useState(false);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const [appInstalled, setAppInstalled] = useState(false);
  const [globalModalOpenedState, setGlobalModalOpenedState] = useState(false);
  const [featureFlags, setFeatureFlags] = useState(null);
  const [numberOfOpenModals, setNumberOfOpenModals] = useState(0);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const location = useLocation().pathname;
  const [showDevtools, setShowDevtools] = React.useState(false);

  const { uploadsInProgress } = useSelector((state) => state.videoUpload);

  React.useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  useEffect(() => {
    setGlobalModalOpenedState(numberOfOpenModals > 0);
  }, [numberOfOpenModals]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [globalModalOpenedState]);

  useEffect(() => {
    const checkInstalledApps = () => {
      const auth = JSON.parse(localStorage.getItem('auth'));
      return api.checkInstalledApps({
        auth: auth,
      });
    };

    async function onLoad() {
      try {
        // await Auth.currentSession();

        // check if local storage has auth item
        const authData = getAuthData();
        if (authData) {
          userHasAuthenticated(authData);
        }

        const auth = JSON.parse(localStorage.getItem('auth'));
        if (auth) {
          const { is_app_installed_in_slack, is_google_workspace_integrated } =
            await checkInstalledApps();
          localStorage.setItem(
            'apps_installations',
            JSON.stringify({
              ts: new Date(),
              slack: is_app_installed_in_slack,
              google: is_google_workspace_integrated,
            })
          );
          setIsConnectedWithSlack(is_app_installed_in_slack);
          setIsConnectedWithGoogle(is_google_workspace_integrated);

          const employeeDetails = await getLoggedInUserDetails({
            auth,
          });
          setEmployeeDetails(employeeDetails);
        }
      } catch (e) {
        if (e !== 'No current user') {
          onError(e);
        }
      }

      setIsAuthenticating(false);
    }
    onLoad();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;

    (async () => {
      const featureFlags = await api.getFeatureFlags({ auth: isAuthenticated });
      setFeatureFlags(featureFlags);
    })();
  }, [isAuthenticated]);

  async function handleLogout() {
    clearAuthData();
    // await Auth.signOut();

    userHasAuthenticated(false);
    setIsConnectedWithSlack(false);
    setIsConnectedWithGoogle(false);

    navigate('/login');
  }

  const handleBurgerMenuClick = () => {
    if (isBurgerMenuOpen) {
      setIsBurgerMenuOpen(false);
    } else {
      setIsBurgerMenuOpen(true);
    }
  };

  const NavItem = ({
    children,
    to,
    onClick,
    activeOnlyWhenExact,
    icon,
    linkClass,
    label,
    href,
    target,
    rel,
  }) => {
    const isActive = useMatch({
      path: to === '/' ? '(/)' : to,
      end: activeOnlyWhenExact,
    });

    if (isActive) {
      return (
        <Link // this should be a NavLink potentially
          to={to}
          onClick={() => setIsBurgerMenuOpen(false)}
          className={`active ${label}`}
        >
          <span className={icon + ` icon-lg`}></span>
          {isBurgerMenuOpen ? (
            <label className='ml-1.5 leading-3'>{label}</label>
          ) : (
            <label className='ml-1 text-sm leading-3 font-basis-bold'>
              {label}
            </label>
          )}
        </Link>
      );
    }

    if (!to && onClick) {
      return <button onClick={onClick}>{children}</button>;
    }

    if (!to && href) {
      return (
        <a
          href={href}
          target={target}
          rel={rel}
          onClick={() => setIsBurgerMenuOpen(false)}
        >
          <span className={icon + ` icon-lg opacity-50`}></span>
          {isBurgerMenuOpen ? (
            <label className='ml-1.5 leading-3'>{label}</label>
          ) : (
            <label className='ml-1 text-sm leading-3 font-basis-bold'>
              {label}
            </label>
          )}
        </a>
      );
    }

    return (
      <Link
        to={to}
        onClick={() => setIsBurgerMenuOpen(false)}
        className={label}
      >
        <span className={icon + ` icon-lg opacity-50`}></span>
        {isBurgerMenuOpen ? (
          <label className='ml-1.5 leading-3'>{label}</label>
        ) : (
          <label className='ml-1 text-sm leading-3 font-basis-bold'>
            {label}
          </label>
        )}
      </Link>
    );
  };

  const navLinks = ({ user, controlBurgerMenu }) => {
    return (
      <>
        {user.is_admin ? (
          <Fragment>
            <NavItem
              to='/'
              activeOnlyWhenExact={false}
              icon='icon-dashboard'
              label='Dashboard'
            ></NavItem>
            <NavItem
              to='/workflows'
              icon='icon-workflows'
              label='Workflows'
            ></NavItem>
          </Fragment>
        ) : (
          <></>
        )}

        <NavItem to='/trainings' icon='icon-book' label='Trainings'></NavItem>
        {user.is_admin && (
          <Fragment>
            <NavItem
              to='/engagements'
              icon='icon-engagement'
              label='Engagements'
            ></NavItem>
            {featureFlags?.PHISHING && (
              <NavItem to='/phishing' label='Phishing' icon='icon-phishing' />
            )}
            <NavItem
              to='/streams'
              icon='icon-streams'
              label='Streams'
            ></NavItem>
          </Fragment>
        )}
        {(user.is_admin || user.is_manager) && (
          <NavItem
            to='/employees'
            icon='icon-users'
            label='Employees'
          ></NavItem>
        )}
        {user.is_admin && (
          <Fragment>
            <NavItem
              to='/catalog'
              icon='icon-catalog'
              label='Catalog'
            ></NavItem>
            <NavItem to='/admins' icon='icon-lock' label='Admins'></NavItem>
            <NavItem to='/billing' label='Billing' icon='icon-billing' />
          </Fragment>
        )}
      </>
    );
  };

  return (
    !isAuthenticating && (
      <div
        className={`grid min-h-0 grid-cols-1 w-full min-h-content-area max-h-content-area antialiased grid-rows-layout bg-hka_gray-ultraLight ${
          !isAuthenticated ? 'hka-sign-in' : ''
        } ${globalModalOpenedState ? 'filter blur-sm' : ''}`}
      >
        <header className='px-2 bg-white border-b sm:px-3 border-hka_gray-light col-span-full'>
          <div className='relative flex items-center h-full py-2 mx-auto sm:justify-between sm:py-3'>
            <Link
              className='relative w-3 h-3 overflow-hidden sm:overflow-visible hka-logo sm:w-auto'
              to='/'
            >
              <img
                src='https://haekka-public-images.s3.us-west-2.amazonaws.com/hka--new-logo.svg'
                alt='Haekka Logo'
                className='h-3 min-w-12 sm:w-auto'
              />
            </Link>
            {isAuthenticated && !connectedWithSlack && (
              <span className='mr-auto ml-3 bg-green-100 button hover:bg-green-200 text-hka_green h-3.4 hidden sm:flex'>
                <a
                  href={`https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&scope=${process.env.REACT_APP_SCOPES}&user_scope=`}
                >
                  <button>
                    <span className='pr-0.5'>
                      <img
                        src='https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://slack.com&size=16'
                        alt='Slack logo'
                      />
                    </span>
                    <span className='text-sm'>Install our Slack App</span>
                  </button>
                </a>
              </span>
            )}
            {isAuthenticated && (
              <UserNavItem user={isAuthenticated} onLogout={handleLogout} />
            )}
            {isAuthenticated && (
              <button
                type='button'
                onClick={handleBurgerMenuClick}
                className='sm:hidden focus:outline-none'
              >
                {!isBurgerMenuOpen && <span className='icon-menu'></span>}
                {isBurgerMenuOpen && <span className='icon-close'></span>}
              </button>
            )}
            {isAuthenticated && isBurgerMenuOpen && (
              <nav className='absolute z-30 w-screen px-1.5 pb-2 -mx-2 bg-white shadow-xl sm:hidden top-full menu--mobile'>
                {navLinks({ user: isAuthenticated })}
                <Link
                  to='/settings'
                  onClick={() => {
                    setIsBurgerMenuOpen(false);
                  }}
                  className={`${location === '/settings' ? 'active' : ''}`}
                >
                  <span className='opacity-50 icon-cog icon-lg'></span>
                  <label className='ml-1.5 leading-3'>Settings</label>
                </Link>
                <Link to={''} onClick={handleLogout} className='logout'>
                  <span className='opacity-50 icon-logout icon-lg'></span>
                  <label className='ml-1.5 leading-3'>Logout</label>
                </Link>
              </nav>
            )}
          </div>
        </header>
        <main className='flex grid-cols-1 overflow-x-hidden overflow-y-auto lg:grid lg:grid-cols-16'>
          {(connectedWithSlack || isGoogleAppInstalled) && (
            <nav className='hidden w-10 col-span-1 px-3 lg:w-auto sm:flex sm:flex-col sm:block md:pb-0 md:mb-0 md:col-span-3 xl:col-span-3 2xl:col-span-2'>
              <section className='flex flex-col pt-2 mb-3 sm:items-center md:flex-row'>
                <img
                  className='w-4 h-4 user-img md:mr-1 md:mb-0'
                  src={
                    isAuthenticated.company_details.icon
                      ? isAuthenticated.company_details.icon
                      : 'https://haekka-author-icons.s3.us-west-2.amazonaws.com/HKA--UPDATED--Author_Haekka.png'
                  }
                  alt={
                    isAuthenticated.company_details.title
                      ? isAuthenticated.company_details.title
                      : 'Haekka'
                  }
                />
                <div className='flex-grow hidden truncate md:block'>
                  <p className='truncate font-basis-bold text-hka_gray-dark'>
                    {isAuthenticated.company_details.title
                      ? isAuthenticated.company_details.title
                      : 'Haekka'}
                  </p>
                  <p className='truncate'>
                    {isAuthenticated.company_details.domain
                      ? isAuthenticated.company_details.domain + '.slack.com'
                      : 'haekka.slack.com'}
                  </p>
                </div>
                {/* <Link to="/settings" data-tip="Settings">
                  <span className="items-center justify-center w-4 h-4 m-0 icon-cog text-hka_gray"></span>
                </Link> */}
              </section>

              <section className='flex flex-col items-center container--nav-links md:items-start'>
                {navLinks({ user: isAuthenticated })}
              </section>
            </nav>
          )}

          <section className='w-full col-span-1 px-2 sm:w-md-auto lg:w-full sm:pr-3 sm:pl-0 lg:px-3 lg:col-span-13 xl:col-span-13 2xl:col-span-14'>
            <section className='h-full py-2'>
              <ErrorBoundary>
                <AppContext.Provider
                  value={{
                    isAuthenticated,
                    userHasAuthenticated,
                    connectedWithSlack,
                    setIsConnectedWithSlack,
                    setIsConnectedWithGoogle,
                    appInstalled,
                    setAppInstalled,
                    featureFlags,
                    employeeDetails,
                    numberOfOpenModals,
                    setNumberOfOpenModals,
                  }}
                >
                  <QueryClientProvider client={queryClient}>
                    <AssignEmployeesProvider>
                      {uploadsInProgress.length > 0 && <UploadsProgress />}
                      <AppRoutes />
                    </AssignEmployeesProvider>
                    {showDevtools && (
                      <React.Suspense fallback={null}>
                        <ReactQueryDevtoolsProduction />
                      </React.Suspense>
                    )}
                  </QueryClientProvider>
                </AppContext.Provider>
              </ErrorBoundary>
            </section>
          </section>
        </main>
        {isAuthenticated && <Footer />}
        <ReactTooltip place='bottom' effect='solid' />
      </div>
    )
  );
}

export default App;
