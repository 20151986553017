import React from 'react';

const Note = ({ type, children }) => {
  const SUPPORTED_TYPES = ['info', 'warning', 'danger', 'success'];

  const noteClass = (() => {
    if (!SUPPORTED_TYPES.includes(type)) {
      return 'note';
    } else {
      return 'note--' + type;
    }
  })();

  return (
    <div className={`flex items-center ${noteClass}`}>
      <span className='icon-warning'></span>
      <div className='space-y-1'>{children}</div>
    </div>
  );
};

export default Note;
