import { Transition } from "@headlessui/react";
import React from "react";

export default function TransitionWrapper({ show, children, afterLeave }) {
  return (
    <div className="z-50">
      <Transition
        show={show}
        enter="transition duration-400 z-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-700 transition ease-in-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={afterLeave && afterLeave}
      >
        {children}
      </Transition>
    </div>
  );
}
