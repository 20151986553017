import { useQuery } from '@tanstack/react-query';
import { getEmployeeTrainingAssessmentQuestion } from '../../../api/employeeTrainingsApi';

export const useEmployeeTrainingAssessmentQuestion = ({
  employeeID,
  assessmentID,
  questionID,
  enabled,
}) => {
  return useQuery({
    queryKey: [
      'employee-trainings-assessments',
      employeeID,
      assessmentID,
      questionID,
    ],
    queryFn: () =>
      getEmployeeTrainingAssessmentQuestion({
        employeeID,
        assessmentID,
        questionID,
      }),
    enabled,
    staleTime: Infinity,
  });
};
