import React, { Fragment } from 'react';
import Slider from '../Base/Slider';
import NotificationSchedule from './SlackSettings/NotificationSchedule';

export default function SlackSettings({
  updateSetting,
  guestAccountsIncluded,
  setGuestAccountsIncluded,
  keepGuestAccounts,
  setKeepGuestAccounts,
  newUsersOnboarding,
  setNewUsersOnboarding,
  newUsersOnboardingAdmins,
  setNewUsersOnboardingAdmins,
  usersCompletedTrainingAdmin,
  setUsersCompletedTrainingAdmin,
  usersAssignedTrainingAdmin,
  setUsersAssignedTrainingAdmin,
  weeklyReport,
  setWeeklyReport,
  overdueTrainingReminder,
  setOverdueTrainingReminder,
  notificationSchedule,
  onNotificationScheduleUpdate,
  triggerLoadNotificationSchedule,
  phishingFeatureFlagActive,
  payPlanIncludesPhishing,
  phishingMessagesAdmin,
  setPhishingMessagesAdmin,
  phishingReminderOnReport,
  setPhishingReminderOnReport,
}) {
  const renderPhishingNotificationSettings =
    payPlanIncludesPhishing && phishingFeatureFlagActive;
  return (
    <Fragment>
      <header className='flex items-center mb-2'>
        <img
          src='https://haekka-lesson-images.s3.us-west-2.amazonaws.com/hka--slack-icon.svg'
          alt='Slack Logo'
          className='w-3 h-3'
        />
        <h1 className='ml-1.5 font-basis-bold'>Slack</h1>
      </header>

      {/* User Account Settings */}
      <section className='p-2 mb-2 bg-white shadow-light rounded-xl col-span-full'>
        {/* <h4 className="mb-3 text-hka_gray">User Accounts</h4> */}
        <section
          className={`flex items-center space-x-2 ${
            !guestAccountsIncluded
              ? 'pb-2 mb-2 border-b border-hka_gray-light'
              : ''
          }`}
        >
          <div className='flex-1'>
            <h3 className='mb-0.5 setting__title'>Include Guest Accounts</h3>
            <p className='text-sm'>
              <span className='font-basis-bold'>On by default</span>, Haekka
              will automatically add all accounts, including guests. If you do
              not want to include guests, turn this setting off.
            </p>
          </div>
          <Slider
            checked={guestAccountsIncluded}
            onChange={(e) => {
              setGuestAccountsIncluded((p) => !p);
              setKeepGuestAccounts(true);
              updateSetting(
                'account',
                'guest_accounts_included',
                e.target.checked
              );
            }}
          />
        </section>
        {!guestAccountsIncluded && (
          <section className='flex items-center space-x-2'>
            <div className='flex-1'>
              <h3 className='mb-0.5 setting__title'>
                Keep existing guest users?
              </h3>
              <p className='text-sm'>
                You're about to exclude guests from your Haekka account. Do you
                want us to keep existing guest users, or remove them as well?
              </p>
            </div>
            <Slider
              checked={keepGuestAccounts}
              onChange={(e) => {
                setKeepGuestAccounts((v) => !v);
                updateSetting(
                  'account',
                  'keep_guest_accounts',
                  e.target.checked
                );
              }}
            />
          </section>
        )}
      </section>

      {/* Notification Schedule */}
      <NotificationSchedule
        notificationSchedule={notificationSchedule}
        onNotificationScheduleUpdate={onNotificationScheduleUpdate}
        triggerLoadNotificationSchedule={triggerLoadNotificationSchedule}
      />

      {/* Employee Notification Settings */}
      <section className='p-2 mb-2 bg-white shadow-light rounded-xl col-span-full'>
        <section className='flex items-center pb-2 mb-2 space-x-2 border-b border-hka_gray-light'>
          <div className='flex-1'>
            <h3 className='mb-0.5 setting__title'>
              Employee Onboarding Experience
            </h3>
            <p className='text-sm'>
              An onboarding message{' '}
              <span className='font-basis-bold'>sent to employees</span> that
              join your workspace — message contains a one-time security hygiene
              tip.
            </p>
          </div>
          <Slider
            checked={newUsersOnboarding}
            onChange={(e) => {
              setNewUsersOnboarding(e.target.checked);
              updateSetting(
                'notifications',
                'new_users_onboarding',
                e.target.checked
              );
            }}
          />
        </section>

        <section className='flex items-center space-x-2'>
          <div className='flex-1'>
            <h3 className='mb-0.5 setting__title'>
              Employee Overdue Training Notification
            </h3>
            <p className='text-sm'>
              A message{' '}
              <span className='font-basis-bold'>sent to employees</span> —
              message contains information on training that is overdue, with an
              action to complete the training.
            </p>
          </div>
          <Slider
            checked={overdueTrainingReminder}
            onChange={(e) => {
              setOverdueTrainingReminder(e.target.checked);
              updateSetting(
                'notifications',
                'overdue_training_reminder',
                e.target.checked
              );
            }}
          />
        </section>
      </section>

      {/* Admin Notification Settings */}
      <section className='p-2 mb-4 bg-white shadow-light rounded-xl col-span-full'>
        {/* <h4 className="mb-3 text-hka_gray">Messaging to Admins</h4> */}
        <section className='flex items-center pb-2 mb-2 space-x-2 border-b border-hka_gray-light'>
          <div className='flex-1'>
            <h3 className='mb-0.5 setting__title'>
              Employee Onboarding Experience
            </h3>
            <p className='text-sm'>
              A message <span className='font-basis-bold'>sent to admins</span>{' '}
              when a new user joins your workspace — message includes a list of
              recommended trainings.
            </p>
          </div>
          <Slider
            checked={newUsersOnboardingAdmins}
            onChange={(e) => {
              setNewUsersOnboardingAdmins(e.target.checked);
              updateSetting(
                'notifications',
                'new_users_onboarding_admin',
                e.target.checked
              );
            }}
          />
        </section>

        <section className='flex items-center pb-2 mb-2 space-x-2 border-b border-hka_gray-light'>
          <div className='flex-1'>
            <h3 className='mb-0.5 setting__title'>
              Employee Completed Training
            </h3>
            <p className='text-sm'>
              A message <span className='font-basis-bold'>sent to admins</span>{' '}
              when an employee completes a training — message contains the
              employees name, and the training they completed.
            </p>
          </div>
          <Slider
            checked={usersCompletedTrainingAdmin}
            onChange={(e) => {
              setUsersCompletedTrainingAdmin(e.target.checked);
              updateSetting(
                'notifications',
                'users_completed_training_admin',
                e.target.checked
              );
            }}
          />
        </section>

        <section className='flex items-center pb-2 mb-2 space-x-2 border-b border-hka_gray-light'>
          <div className='flex-1'>
            <h3 className='mb-0.5 setting__title'>
              Employee Assigned Training
            </h3>
            <p className='text-sm'>
              A message <span className='font-basis-bold'>sent to admins</span>{' '}
              when an employee is assigned a training — message contains the
              employees name, and the training they've been assigned.
            </p>
          </div>
          <Slider
            checked={usersAssignedTrainingAdmin}
            onChange={(e) => {
              setUsersAssignedTrainingAdmin(e.target.checked);
              updateSetting(
                'notifications',
                'users_assigned_training_admin',
                e.target.checked
              );
            }}
          />
        </section>

        <section
          className={`flex items-center space-x-2 ${
            renderPhishingNotificationSettings
              ? 'pb-2 mb-2 border-b border-hka_gray-light'
              : ''
          }`}
        >
          <div className='flex-1'>
            <h3 className='mb-0.5 setting__title'>
              Weekly Report Notification
            </h3>
            <p className='text-sm'>
              A message <span className='font-basis-bold'>sent to admins</span>{' '}
              every Monday — message contains information and actions based on
              previous week's training activity.
            </p>
          </div>
          <Slider
            checked={weeklyReport}
            onChange={(e) => {
              setWeeklyReport(e.target.checked);
              updateSetting('notifications', 'weekly_report', e.target.checked);
            }}
          />
        </section>
        {renderPhishingNotificationSettings && (
          <>
            <section className='flex items-center pb-2 mb-2 space-x-2 border-b border-hka_gray-light'>
              <div className='flex-1'>
                <h3 className='mb-0.5 setting__title'>Phishing Messages</h3>
                <p className='text-sm'>
                  A message{' '}
                  <span className='font-basis-bold'>sent to admins</span> when
                  employees fail a phishing campaign.
                </p>
              </div>
              <Slider
                checked={phishingMessagesAdmin}
                onChange={(e) => {
                  setPhishingMessagesAdmin(e.target.checked);
                  updateSetting(
                    'notifications',
                    'phishing_messages_admin',
                    e.target.checked
                  );
                }}
              />
            </section>

            <section className='flex items-center space-x-2'>
              <div className='flex-1'>
                <h3 className='mb-0.5 setting__title'>
                  Phishing Reminder to Report
                </h3>
                <p className='text-sm'>
                  On by default, this notification will remind a user to report
                  any phishing emails they receive.
                </p>
              </div>
              <Slider
                checked={phishingReminderOnReport}
                onChange={(e) => {
                  setPhishingReminderOnReport(e.target.checked);
                  updateSetting(
                    'notifications',
                    'phishing_reminder_to_report',
                    e.target.checked
                  );
                }}
              />
            </section>
          </>
        )}
      </section>
    </Fragment>
  );
}
