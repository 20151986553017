import WysiwygRichTextEditor from '../WysiwygEditor/WysiwygRichTextEditor';
import VideoSection from './VideoSection';
import EditQuestion from './EditQuestion';
import LoaderInline from '../LoaderInline';
import SimpleLessonSection from './SimpleLessonSection';
import TitleSection from './TitleSection';
import { lessonProperties } from '../../utils/trainingUtils';
import { useSelector, useDispatch } from 'react-redux';
import { lessonActions } from '../../store/lessonSlice';

const EditLesson = ({
  nextLessonPosition,
  notesEditorState,
  summaryEditorState,
  questionEditorState,
  updateEditorState,
  setEditLessonOpened,
  scenarioType,
}) => {
  const dispatch = useDispatch();
  const {
    isSimpleLesson,
    externalResources,
    fetchedLesson,
    loadingLesson,
    externalResourcesValid,
  } = useSelector((state) => state.lesson);

  const addImageHandler = (id) => {
    dispatch(lessonActions.addImage(id));
  };

  if (loadingLesson)
    return (
      <div className='flex justify-center pt-8'>
        <LoaderInline />
      </div>
    );

  return (
    <section>
      <SimpleLessonSection />
      <TitleSection />
      {!isSimpleLesson && (
        <section className='w-full mb-2'>
          <h4 className='mb-1 font-basis-bold'>
            Notes <span className='text-sm text-hka_gray'>(Optional)</span>
          </h4>
          <WysiwygRichTextEditor
            editorState={notesEditorState}
            onSetEditorState={(state) => updateEditorState('notes', state)}
            id={fetchedLesson?.id}
            onImageAdded={addImageHandler}
            contentType={'lesson'}
          />
        </section>
      )}
      <section className='w-full mb-2'>
        <h4 className='mb-1 font-basis-bold'>
          Content <span className='text-sm text-hka_gray'>(Required)</span>
        </h4>
        <WysiwygRichTextEditor
          editorState={summaryEditorState}
          onSetEditorState={(state) => updateEditorState('summary', state)}
          id={fetchedLesson?.id}
          onImageAdded={addImageHandler}
          contentType={'lesson'}
        />
      </section>

      <EditQuestion
        questionEditorState={questionEditorState}
        updateEditorState={updateEditorState}
        scenarioType={scenarioType}
      />

      <VideoSection
        position={nextLessonPosition}
        setEditLessonOpened={setEditLessonOpened}
      />

      {!isSimpleLesson && (
        <section className='w-full pb-2'>
          <h4 className='font-basis-bold'>
            External Resources{' '}
            <span className='text-sm text-hka_gray'>(Optional)</span>
          </h4>
          <p className='mb-1 text-sm'>
            You can add an external link to this lesson to give users more
            context. This could be a link to an internal wiki, an article
            online, or any other URL.
          </p>
          <input
            type='text'
            value={externalResources}
            onChange={(e) =>
              dispatch(
                lessonActions.updateLesson({
                  property: lessonProperties.EXTERNAL_RESOURCES,
                  value: e.target.value,
                })
              )
            }
            placeholder='Enter URL'
            className={`w-full p-1 font-semibold border outline-none rounded-xl text-base2 text-hka_gray-dark border-hka_gray-light ${
              !externalResourcesValid
                ? 'text-red-500 bg-red-50 border-red-500 border p-1'
                : 'text-hka_gray-dark border-hka_gray-lights hka-input'
            }`}
          />
          <h5>
            {!externalResourcesValid && (
              <span className='text-red-500'>Pleasse enter a valid URL</span>
            )}
          </h5>
        </section>
      )}
    </section>
  );
};

export default EditLesson;
