import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import {
  useEmployeesInStreamData,
  useRemoveEmployee,
} from '../../../hooks/data-hooks/streams';

import LoaderInline from '../../LoaderInline';
import ListItems from '../../ListItems';
import RemoveAssignedEmployee from './RemoveAssignedEmployee';
import Paginator from '../../Paginator';

const ListAssignedEmployees = ({ stream }) => {
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedEmployeeForRemoval, setSelectedEmployeeForRemoval] =
    useState(null);

  const { data: currentEmployees, isLoading } = useEmployeesInStreamData({
    streamID: stream.companyStreamID,
    page: currentPage,
  });

  const { mutate: removeEmployee } = useRemoveEmployee({
    onSuccess: () => {
      queryClient.invalidateQueries([
        'employees-in-stream',
        stream.companyStreamID,
        currentPage,
      ]);
      setShowRemoveModal(false);
      toast.success('Employee removed');
    },
  });

  if (isLoading) return <LoaderInline wrapperClasses='w-full min-h-30' />;

  if (currentEmployees.length === 0) {
    return (
      <section>
        <p>There are no assigned employees to this workflow.</p>
      </section>
    );
  }

  const removeEmployeeHandler = async () => {
    removeEmployee({
      employeeID: selectedEmployeeForRemoval.id,
      streamID: stream.companyStreamID,
    });
  };

  const closeModalHandler = () => {
    setShowRemoveModal(false);
    setSelectedEmployeeForRemoval(null);
  };

  return (
    <>
      <ListItems>
        {currentEmployees.results.map((employee) => (
          <li
            key={employee.id}
            className='relative items-start border-b lg:items-center hover:z-10 focus-within:z-10 border-hka_gray-light'
          >
            <img
              className='mr-2 user-img'
              src={employee.avatar_image}
              alt={employee.avatar_image}
            />
            <div className='flex flex-col items-start flex-1 flex-grow lg:flex-row flex-nowrap lg:items-center'>
              <p className='flex flex-1 leading-3 flex-nowrap text-hka_gray-dark'>
                {employee.name}
              </p>
            </div>
            <div className='flex justify-end space-x-1 lg:pl-4'>
              <button
                onClick={() => {
                  setSelectedEmployeeForRemoval(employee);
                  setShowRemoveModal(true);
                }}
                className='relative px-0 bg-hka_red-light hover:bg-red-200 '
                data-tip='Remove User'
              >
                <span className='text-hka_red icon-trash'></span>
              </button>
            </div>
          </li>
        ))}
        <div className='mt-2'>
          <Paginator
            pageSize={10}
            count={currentEmployees.count}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </ListItems>
      {showRemoveModal && (
        <RemoveAssignedEmployee
          onClose={closeModalHandler}
          onSubmit={removeEmployeeHandler}
          selectedEmployeeForRemoval={selectedEmployeeForRemoval}
        />
      )}
    </>
  );
};

export default ListAssignedEmployees;
