import { Fragment } from "react";
import TrainingTagSettings from "./TrainingTagSettings";
import RolesSettings from "./RolesSettings";

const AdminSettings = () => {
  return (
    <Fragment>
      <h1 className="mb-2 mt-1 font-basis-bold col-span-full">
        Admin Settings
      </h1>

      <RolesSettings />

      <TrainingTagSettings />
    </Fragment>
  );
};

export default AdminSettings;
