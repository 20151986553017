import React, { useMemo } from "react";

export const ROW_TYPE = {
  CONFIG: "CONFIG",
  BINDING: "BINDING",
};

function StatusIcon({ loading, success }) {
  if (loading) {
    return <div className="flex justify-center w-2 h-2">⏳</div>;
  } else {
    if (success) {
      return (
        <div className="imap-test--passed">
          <span className="icon-check"></span>
        </div>
      );
    } else {
      return (
        <div className="imap-test--failed" data-tip="Invalid Credentials">
          <span className="icon-close"></span>
        </div>
      );
    }
  }
}

export default function TestRow({ type, value, loading, status }) {
  const title = useMemo(() => {
    switch (type) {
      case ROW_TYPE.CONFIG:
        return `IMAP Configuration`;
      case ROW_TYPE.BINDING:
        return `IMAP Binding`;
      default:
        return "";
    }
  }, [type]);

  const subtitle = useMemo(() => {
    if (type === ROW_TYPE.CONFIG) {
      return `${value.host}:${value.port}`;
    } else {
      return value.email;
    }
  }, [type, value]);

  return (
    <div className="flex flex-row items-center w-full py-0.5 pb-1 border-b border-b-hka_gray-light justify-between mb-1">
      <p className="w-4/5 text-base2 truncate font-basis-bold">{title}</p>
      <div className="flex flex-row items-center space-x-1">
        <p className="text-base text-hka_gray-dark">{subtitle}</p>
        <StatusIcon loading={loading} success={status} />
      </div>
    </div>
  );
}
