import { Cell } from 'rsuite-table';

const CompletedDateCell = ({ rowData, dataKey, ...props }) => {
  return (
    <Cell {...props} style={{ padding: 0 }}>
      <div className='flex items-center justify-center w-full h-3 ml-auto space-x-1 px-1'>
        <div className='text-sm'>{rowData[dataKey]}</div>
      </div>
    </Cell>
  );
};

export default CompletedDateCell;
