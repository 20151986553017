import React, { useEffect, useState } from "react";
import Divider from "../../Base/Divider";
import Slider from "../../Base/Slider";
import NotificationScheduleDayRow from "./NotificationScheduleDayRow";
import NotificationScheduleTimepickerRow from "./NotificationScheduleTimepickerRow";

export default function NotificationSchedule({
  notificationSchedule,
  onNotificationScheduleUpdate,
}) {
  const HIDDEN_ROW_KEYS = [
    "id",
    "active",
    "created",
    "modified",
    "company",
    "send_from",
    "send_to",
  ];
  const [active, setActive] = useState(notificationSchedule.active);
  const [sendFrom, setSendFrom] = useState(null);
  const [sendTo, setSendTo] = useState(null);

  useEffect(() => {
    if (notificationSchedule.send_from) {
      const sendFromWrapperDate = new Date();
      const [hours, minutes, seconds] = extractTime(
        notificationSchedule.send_from
      );
      sendFromWrapperDate.setHours(hours, minutes, seconds);

      setSendFrom(sendFromWrapperDate);
    }

    if (notificationSchedule.send_to) {
      const sendToWrapperDate = new Date();
      const [hours, minutes, seconds] = extractTime(
        notificationSchedule.send_to
      );
      sendToWrapperDate.setHours(hours, minutes, seconds);

      setSendTo(sendToWrapperDate);
    }
  }, [notificationSchedule]);

  useEffect(() => {
    onNotificationScheduleUpdate({ active });
  }, [active]);

  const capitalizeDay = (dayAsString) => {
    const [firstLetter, ...remainder] = dayAsString;
    return `${firstLetter.toUpperCase()}${remainder.join("").toLowerCase()}`;
  };

  const extractTime = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":");
    return [Number(hours), Number(minutes), Number(seconds)];
  };

  const handleOnTimeChange = async (key, value) => {
    function padTo2Digits(num) {
      return String(num).padStart(2, "0");
    }

    if (key === "send_from") {
      setSendFrom(value);
    } else {
      setSendTo(value);
    }

    const formattedTime = `${padTo2Digits(value.getHours())}:${padTo2Digits(
      value.getMinutes()
    )}:00`;

    onNotificationScheduleUpdate({
      [key]: formattedTime,
    });
  };

  return (
    <section className="p-2 mb-2 bg-white shadow-light rounded-xl col-span-full">
      <div
        className={`flex flex-row align-center justify-between ${
          active ? "mb-2" : ""
        }`}
      >
        <div>
          <h3 className="mb-0.5 setting__title">Notification Schedule</h3>
          <p className="text-sm">
            Enable this setting to manage when Notifications are sent.
          </p>
        </div>
        <Slider checked={active} onChange={() => setActive((v) => !v)} />
      </div>

      {notificationSchedule && active && (
        <div>
          {Object.keys(notificationSchedule)
            .filter((key) => !HIDDEN_ROW_KEYS.includes(key))
            .map((key, i) => (
              <div key={i}>
                {i === 0 && <Divider />}
                <NotificationScheduleDayRow
                  day={capitalizeDay(key)}
                  enabled={notificationSchedule[key] || false}
                  onUpdate={onNotificationScheduleUpdate}
                />
              </div>
            ))}
          <NotificationScheduleTimepickerRow
            sendFrom={sendFrom}
            sendTo={sendTo}
            onUpdate={handleOnTimeChange}
          />
          <p className="mt-2 text-sm text-hka_gray-dark">
            <span className="font-basis-bold">Please Note:</span> These times
            are local to each user.
          </p>
        </div>
      )}
    </section>
  );
}
