const SourceDestinationLogo = ({ source, selectedSource }) => {
  const { id, alt, size, src, active } = source;

  let customClasses = "bg-white";
  if (selectedSource === id) {
    customClasses = "bg-hka_blue-light border-hka_blue";
  } else if (!active) {
    customClasses = "bg-gray-100 opacity-30";
  }

  return (
    <span
      className={`${
        active ? "cursor-pointer" : ""
      } border border-hka_gray-border rounded-md h-5 w-5 flex justify-center items-center mr-1 ${customClasses}`}
    >
      <img alt={alt} height={size} width={size} src={src} />
    </span>
  );
};

export default SourceDestinationLogo;
