const ACTION_TYPES = {
  REPORT_PHISHING: "report_phishing",
  DO_NOTHING: "do_nothing",
  OPEN_PHISHING_MAIL: "open_phishing_mail",
  CLICK_PHISHING_LINK: "click_phishing_link",
  BREACH_CREDENTIALS: "breach_credentials",
  FORWARDED_PHISHING_MAIL: "forwarded_phishing_mail",
};

const SEND_OPTIONS = {
  ONCE_IMMEDIATELY: "once_immediately",
  RANDOMLY_ONCE_EVERY_TWO_WEEKS: "randomly_once_every_two_weeks",
  RANDOMLY_ONCE_MONTHLY: "randomly_once_monthly",
  RANDOMLY_ONCE_QUARTERLY: "randomly_once_quarterly",
  RANDOMLY_ONCE_EVERY_SIX_MONTHS: "randomly_once_every_six_months",
  RANDOMLY_ONCE_YEARLY: "randomly_once_yearly",
};

const SEND_OPTION_CHOICES = [
  { value: SEND_OPTIONS.ONCE_IMMEDIATELY, label: "Send once immediately" },
  {
    value: SEND_OPTIONS.RANDOMLY_ONCE_EVERY_TWO_WEEKS,
    label: "Send randomly once every two weeks",
  },
  {
    value: SEND_OPTIONS.RANDOMLY_ONCE_MONTHLY,
    label: "Send randomly once every month",
  },
  {
    value: SEND_OPTIONS.RANDOMLY_ONCE_QUARTERLY,
    label: "Send randomly once every quarter",
  },
  {
    value: SEND_OPTIONS.RANDOMLY_ONCE_EVERY_SIX_MONTHS,
    label: "Send randomly once every six months",
  },
  {
    value: SEND_OPTIONS.RANDOMLY_ONCE_YEARLY,
    label: "Send randomly once every year",
  },
];

const ACTION_TYPE_LABELS = {
  REPORTED: "Reported",
  NO_ACTION: "No Action",
  FORWARDED: "Forwarded",
  OPENED: "Opened",
  CLICKED: "Clicked",
  BREACHED: "Breached",
};

const ACTION_TYPE_CHART_COLORS = {
  DARK_BLUE: "#0094FF",
  LIGHT_BLUE: "#99D4FF",
  GRAY: "#C2C4CC",
  DARK_ORANGE: "#FF9243",
  LIGHT_ORANGE: "#FFC896",
  RED: "#FF0200",
};

const CAMPAIGN_STATUS_OPTIONS = {
  ACTIVE: {
    value: "Active",
    styleClasses: "text-hka_blue bg-hka_blue-light",
  },
  PAUSED: {
    value: "Paused",
    styleClasses: "text-hka_red bg-hka_red-light",
  },
  UNPUBLISHED: {
    value: "Unpublished",
    styleClasses: "text-hka_gray bg-hka_gray-light",
  },
};

const IMAP_SETTINGS = {
  GOOGLE: {
    host: "imap.gmail.com",
    port: 993,
  },
  OUTLOOK: {
    host: "outlook.office365.com",
    port: 993,
  },
  YAHOO: {
    host: "imap.mail.yahoo.com",
    port: 993,
  },
};

const getActionTypeLabel = (actionType) => {
  switch (actionType) {
    case ACTION_TYPES.REPORT_PHISHING:
      return ACTION_TYPE_LABELS.REPORTED;
    case ACTION_TYPES.DO_NOTHING:
      return ACTION_TYPE_LABELS.NO_ACTION;
    case ACTION_TYPES.BREACH_CREDENTIALS:
      return ACTION_TYPE_LABELS.BREACHED;
    case ACTION_TYPES.CLICK_PHISHING_LINK:
      return ACTION_TYPE_LABELS.CLICKED;
    case ACTION_TYPES.FORWARDED_PHISHING_MAIL:
      return ACTION_TYPE_LABELS.FORWARDED;
    case ACTION_TYPES.OPEN_PHISHING_MAIL:
      return ACTION_TYPE_LABELS.OPENED;
    // This case should never happen.
    default:
      return null;
  }
};

const getActionTypeChartColor = (label) => {
  switch (label) {
    case ACTION_TYPE_LABELS.REPORTED:
      return ACTION_TYPE_CHART_COLORS.DARK_BLUE;
    case ACTION_TYPE_LABELS.NO_ACTION:
      return ACTION_TYPE_CHART_COLORS.GRAY;
    case ACTION_TYPE_LABELS.FORWARDED:
      return ACTION_TYPE_CHART_COLORS.LIGHT_BLUE;
    case ACTION_TYPE_LABELS.OPENED:
      return ACTION_TYPE_CHART_COLORS.LIGHT_ORANGE;
    case ACTION_TYPE_LABELS.CLICKED:
      return ACTION_TYPE_CHART_COLORS.DARK_ORANGE;
    case ACTION_TYPE_LABELS.BREACHED:
      return ACTION_TYPE_CHART_COLORS.RED;
    // This case should never happen.
    default:
      return null;
  }
};

const getActionTypeLabelAndColorClass = (actionType) => {
  switch (actionType) {
    case "do_nothing":
      return {
        label: "Received Email",
      };
    case "open_phishing_mail":
      return {
        label: "Opened",
      };
    case "click_phishing_link":
      return {
        label: "Clicked",
      };
    case "breach_credentials":
      return {
        label: "Breached",
        colorClass: "text-hka_red",
      };
    case "report_phishing":
      return {
        label: "Reported",
        colorClass: "text-hka_blue",
      };
    case "forwarded_phishing_mail":
      return {
        label: "Forwarded",
      };
    default:
      return {
        label: "No Action",
        colorClass: "text-hka_gray opacity-60",
      };
  }
};

const getCampaignStatus = (campaign) => {
  if (!campaign.last_sent_date) {
    return CAMPAIGN_STATUS_OPTIONS.UNPUBLISHED;
  } else {
    return campaign.active
      ? CAMPAIGN_STATUS_OPTIONS.ACTIVE
      : CAMPAIGN_STATUS_OPTIONS.PAUSED;
  }
};

const getSendOptionDescription = (campaign, shortVariant = false) => {
  let title = "Attacks sent";

  switch (campaign.send_option) {
    case SEND_OPTIONS.ONCE_IMMEDIATELY:
      title = !shortVariant
        ? `${title} once immediately.`
        : "Once immediately.";
      break;
    case SEND_OPTIONS.RANDOMLY_ONCE_EVERY_TWO_WEEKS:
      title = !shortVariant
        ? `${title} randomly, once every two weeks.`
        : "Every two weeks.";
      break;
    case SEND_OPTIONS.RANDOMLY_ONCE_MONTHLY:
      title = !shortVariant
        ? `${title} randomly, once every month.`
        : "Every month.";
      break;
    case SEND_OPTIONS.RANDOMLY_ONCE_QUARTERLY:
      title = !shortVariant
        ? `${title} randomly, once every quarter.`
        : "Every quarter.";
      break;
    case SEND_OPTIONS.RANDOMLY_ONCE_EVERY_SIX_MONTHS:
      title = !shortVariant
        ? `${title} randomly, once every six months.`
        : "Every six months.";
      break;
    case SEND_OPTIONS.RANDOMLY_ONCE_YEARLY:
      title = !shortVariant
        ? `${title} randomly, once every year.`
        : "Every year.";
      break;
    // This case should never happen.
    default:
      return null;
  }
  return title;
};

export {
  SEND_OPTIONS,
  SEND_OPTION_CHOICES,
  ACTION_TYPES,
  CAMPAIGN_STATUS_OPTIONS,
  IMAP_SETTINGS,
  getActionTypeLabel,
  getActionTypeChartColor,
  getCampaignStatus,
  getActionTypeLabelAndColorClass,
  getSendOptionDescription,
};
