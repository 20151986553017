import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Divider from "./Divider";
import DropdownMenu from "./DropdownMenu";
export default function Card({
  imageUrlOrIconClass,
  title,
  actions,
  content,
  footer,
  renderActionsAsMenu,
  warningMessage = "",
}) {
  const iconOrImageElement = imageUrlOrIconClass ? (
    imageUrlOrIconClass.includes("icon-") &&
    !imageUrlOrIconClass.startsWith("http") ? (
      <span className={imageUrlOrIconClass}></span>
    ) : (
      <img
        className="w-2.4 h-2.4 mr-1 rounded"
        src={imageUrlOrIconClass}
        alt="Icon"
      ></img>
    )
  ) : null;

  return (
    <div className="card">
      <header className="card__header">
        <div className="card__title">
          {iconOrImageElement && iconOrImageElement}
          {title && <p className="font-basis-medium">{title}</p>}
        </div>
        <div className="flex items-center space-x-1">
          {warningMessage && (
            <span
              className="rounded-full icon-warning bg-orange text-orange-light"
              data-tip={warningMessage}
            ></span>
          )}
          {actions?.length > 0 && (
            <Fragment>
              {renderActionsAsMenu ? (
                <DropdownMenu actions={actions} />
              ) : (
                <div className="card__actions">
                  {actions.reverse().map((action, i) => (
                    <span
                      key={i}
                      className={`card__action ${action.icon}`}
                      data-tip={action.label}
                      onClick={action.onClick}
                    ></span>
                  ))}
                </div>
              )}
            </Fragment>
          )}
        </div>
      </header>
      {/* <Divider /> */}
      <section className="card__content">{content}</section>
      {footer && (
        <Fragment>
          <Divider />
          <section className="card__footer">{footer}</section>
        </Fragment>
      )}
    </div>
  );
}

Card.propTypes = {
  imageUrlOrIconClass: PropTypes.string,
  title: PropTypes.string,
  actions: PropTypes.array,
  content: PropTypes.element.isRequired,
  footer: PropTypes.element,
  renderActionsAsMenu: PropTypes.bool,
  warningMessage: PropTypes.string,
};
