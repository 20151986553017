import { useCallback, useState, useEffect } from 'react';
import { useMergeLink } from '@mergeapi/react-merge-link';
import toast from 'react-hot-toast';

import {
  mergeGetLinkToken,
  storeMergeAccessToken,
} from '../../api/integrationsApi';

import { onError } from '../../libs/errorLib';

export default function useInitiateMerge({ isAuthenticated, integration, cb }) {
  const [linkToken, setLinkToken] = useState(undefined);

  const onSuccess = useCallback(
    (public_token) => {
      const storeToken = async () => {
        try {
          await storeMergeAccessToken({
            companyID: isAuthenticated.company,
            integration,
            public_token,
          });
          cb();
        } catch (error) {
          if (error.message.endsWith('404')) {
            toast.error(
              'An integration service you chose returned an error. Please try again.'
            );
          } else {
            onError(error);
          }
        }
      };
      storeToken();
    },
    [cb, integration, isAuthenticated]
  );

  const { open, isReady } = useMergeLink({
    linkToken,
    onSuccess,
  });

  useEffect(() => {
    const initiateMergeLink = async () => {
      try {
        const { link_token } = await mergeGetLinkToken({
          companyID: isAuthenticated.company,
          employeeID: isAuthenticated.id,
          integration,
        });
        setLinkToken(link_token);
      } catch (error) {
        onError(error);
      }
    };

    initiateMergeLink();
  }, [integration, isAuthenticated]);

  return [isReady, open];
}
