import React from "react";
import Modal from "../../../Modal/Modal";

export default function RemoveMessageConfirmationModal({
  showModal,
  onSubmit,
  onClose,
  sumbitValid,
}) {
  return (
    <Modal
      id="confirm-engagement-message-deletion-modal"
      title="Remove Engagement Message"
      submitButtonText="Remove Message"
      submitButtonType="danger"
      onSubmit={onSubmit}
      onClose={onClose}
      show={showModal}
      submitValid={sumbitValid}
      compact
    >
      <p>Are you sure you want to remove this Engagement message?</p>
    </Modal>
  );
}
