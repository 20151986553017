import Modal from "../../Modal/Modal";
import Note from "../../Note";

const DeleteEngagementConfirmationModal = ({
  show,
  onSubmit,
  onClose,
  engagementTitle,
}) => {
  return (
    <Modal
      show={show}
      id="delete-quiz-confirmation"
      title="Delete Engagement"
      onSubmit={onSubmit}
      onClose={onClose}
      submitButtonText="Delete Engagement"
      submitButtonType="danger"
      compact
    >
      <p className="mb-1">
        Are you sure you want to delete{" "}
        <span className="font-basis-bold">{engagementTitle}</span>?
      </p>
      <Note type="danger">
        <p>All associated data will be permanently removed.</p>
      </Note>
    </Modal>
  );
};

export default DeleteEngagementConfirmationModal;
