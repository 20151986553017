const Footer = () => {
  const currentYear = new Date().getFullYear();

  const releaseLink = process.env.REACT_APP_RELEASE ?? '';
  let releaseVersion = 'Latest version';

  const regex = /haekka-release-(.*)$/;
  const match = releaseLink.match(regex);
  if (match) {
    releaseVersion = match[1].replaceAll('-', '.');
  }

  return (
    <footer className='bg-white col-span-full'>
      <section className='flex items-center h-4 px-2 space-x-2'>
        <p className='text-xs leading-4 sm:leading-2 text-hka_gray'>
          {' '}
          © {currentYear} DayZero, Inc |{' '}
          <a
            href={releaseLink}
            target='_blank'
            rel='noreferrer'
            className='font-basis-regular hover:text-orange'
          >
            v{releaseVersion}
          </a>
        </p>{' '}
        <div className='flex items-center sm:inline-flex'>
          <a
            href='https://www.haekka.com/privacy-policy'
            target='_blank'
            rel='noreferrer'
            className='mr-2 leading-4 transition-all transform hover:text-orange'
          >
            Privacy Policy
          </a>
          <a
            href='https://www.haekka.com/terms-of-service'
            target='_blank'
            rel='noreferrer'
            className='leading-4 transition-all transform hover:text-orange'
          >
            Terms of Service
          </a>
        </div>
        <div className='hidden sm:flex sm:space-x-2'>
          <a
            href='https://www.haekka.com/contact-us'
            target='_blank'
            rel='noreferrer'
            className='transition-all transform hover:text-orange'
          >
            Support
          </a>
          <a
            href='https://www.haekka.com/documentation'
            target='_blank'
            rel='noreferrer'
            className='transition-all transform hover:text-orange'
          >
            Documentation
          </a>
          <a
            href='https://www.haekka.com/about-us'
            target='_blank'
            rel='noreferrer'
            className='transition-all transform hover:text-orange'
          >
            About Us
          </a>
          <a
            href='https://www.haekka.com/guides'
            target='_blank'
            rel='noreferrer'
            className='transition-all transform hover:text-orange'
          >
            Guides
          </a>
          <a
            href='https://www.haekka.com/blog'
            target='_blank'
            rel='noreferrer'
            className='transition-all transform hover:text-orange'
          >
            Blog
          </a>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
