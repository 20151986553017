import { Fragment, useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";
import UploadProgress from "./UploadProgress";
import { useSelector, useDispatch } from "react-redux";
import { videoUploadActions } from "../../store/video-upload";

const UploadsProgress = () => {
  const [expanded, setExpanded] = useState(true);
  const { uploadsInProgress } = useSelector((state) => state.videoUpload);

  const dispatch = useDispatch();

  const clearCompletedUploads = () => {
    dispatch(videoUploadActions.clearCompletedUploads());
  };

  const clearAllUploads = (e) => {
    e.stopPropagation();
    dispatch(videoUploadActions.clearAllUploads());
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const isThereCompletedUploads = uploadsInProgress.find(
    (upload) => upload.completed
  );

  const uploadInProgressLength = uploadsInProgress.filter(
    (item) => !item.completed
  ).length;

  const headerJSX = isThereCompletedUploads ? (
    <Fragment>
      <p className="mt-1">
        <span className="text-base">🙌</span> Your video uploads are complete!
        Feel free to close this dialog.
      </p>
    </Fragment>
  ) : (
    <Fragment>
      <p className="mt-1">
        Please do not close this dialog until your videos uploads complete.{" "}
      </p>
    </Fragment>
  );

  return ReactDOM.createPortal(
    <div
      className="absolute right-auto w-11/12 px-3 py-2 space-y-2 bg-white shadow-2xl rounded-xl left-1 sm:left-auto sm:right-3 top-1 sm:top-3 sm:w-40"
      style={{ zIndex: "100" }}
    >
      <div
        className="text-base cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="flex items-center">
          <h4 className="flex-1">Video Uploads</h4>
          <button
            className="button--sm hover:bg-hka_gray-light"
            data-tip={expanded ? "Collapse" : "Expand"}
          >
            <span
              className={`icon-arrow transition-all ${
                expanded ? "bottom-left" : "top-right"
              }`}
            ></span>
          </button>
          {uploadInProgressLength === 0 && (
            <button
              className="cursor-pointer button--sm hover:bg-hka_gray-light ml-0.5"
              onClick={clearAllUploads}
              data-tip={`Hide popup`}
            >
              <span className="icon-close text-hka_gray"></span>
            </button>
          )}
        </div>
        {expanded && headerJSX}
      </div>
      {expanded && (
        <Fragment>
          {uploadsInProgress.map((upload) => (
            <UploadProgress key={upload.id + upload.type} upload={upload} />
          ))}
          {isThereCompletedUploads && (
            <div className="flex">
              <p
                className="text-sm border rounded-full flex items-center pl-0.5 pr-1.5 py-0.2 cursor-pointer bg-hka_gray-light"
                onClick={clearCompletedUploads}
              >
                <span className="mr-1 icon-close icon-sm"></span>
                Clear finished uploads
              </p>
            </div>
          )}
        </Fragment>
      )}
    </div>,
    document.body
  );
};

export default UploadsProgress;
