import { format } from "date-fns";

const ScheduleDate = ({ title, date }) => {
  if (!date) {
    return null;
  }

  const day = format(new Date(date), "MMM dd, yyyy");
  const time = format(new Date(date), "h:mm aa");

  return (
    <div className="flex items-center pr-2 mr-2 lg:border-r border-hka_gray-border">
      <span className="icon-calendar -ml-0.5 mr-0.2"></span>
      <p className="mr-0.5">
        {title} {day} at {time}
      </p>
    </div>
  );
};

export default ScheduleDate;
