import { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom';
import { getEditorState } from '../../utils/wysiwygEditorHelpers';
import Expander from '../Expander';
import WysiwygRichTextEditor from '../WysiwygEditor/WysiwygRichTextEditor';
import QuestionModel from './QuestionModal';
import * as api from '../../api';
import { useAppContext } from '../../libs/contextLib';
import { sleep } from '../../utils/sleep';
import ReportLessonIssueModal from './ReportLessonIssueModal';
import { normilizeVideoURL } from '../../utils/commonUtils';
import TrainingOrLessonDuration from '../TrainingOrLessonDuration';

const LessonBody = ({
  employeeLesson,
  setEmployeeLesson,
  employeeLessons,
  setEmployeeLessons,
  employeeTraining,
  setEmployeeTraining,
  lesson,
  setLesson,
  setShowResultsPage,
  setRefetchTraining,
  trainingComplete,
  setTrainingComplete,
  trainingId,
  branchingScenario,
  training,
  setCompletedLessons,
  activeTrainingItem,
  trainingItems,
  setActiveTrainingItem,
  completedLessons,
  totalLessons,
}) => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAppContext();

  const [notesEditorState, setNotesEditorState] = useState(null);
  const [summaryEditorState, setSummaryEditorState] = useState(null);
  const [questionEditorState, setQuestionEditorState] = useState(null);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scrollToResults, setScrollToResults] = useState(false);
  const [showReportLessonIssueModal, setShowReportLessonIssueModal] =
    useState(false);
  const [videoURL, setVideoURL] = useState(undefined);

  const trainingLessons = trainingItems.filter(
    (item) => item.type === 'lesson'
  );

  const footerRef = useRef(null);

  useEffect(() => {
    const scroll = async () => {
      await sleep(300);
      if (footerRef.current && scrollToResults)
        footerRef.current.scrollIntoView();
      setScrollToResults(false);
    };
    async function onLoad() {
      await scroll();
    }
    if (!showQuestionModal) onLoad();
  }, [showQuestionModal]);

  function getEmployeeLesson(lessonId) {
    return api.getEmployeeLesson({
      auth: isAuthenticated,
      employeeId: isAuthenticated.id,
      trainingId: trainingId,
      lessonId: lessonId,
    });
  }

  useEffect(() => {
    const getState = async (editor_content, editor_state, markup, variant) => {
      const res = await getEditorState(
        editor_state,
        editor_content,
        markup,
        false
      );
      switch (variant) {
        case 'note':
          setNotesEditorState(res);
          break;
        case 'summary':
          setSummaryEditorState(res);
          break;
        case 'question':
          setQuestionEditorState(res);
          break;
        default:
          break;
      }
    };

    if (lesson) {
      if (!lesson.is_simple_form) {
        getState(
          lesson.editor_content,
          lesson.editor_content?.note_editor_state,
          lesson.note,
          'note'
        );
      }
      getState(
        lesson.editor_content,
        lesson.editor_content?.summary_editor_state,
        lesson.summary,
        'summary'
      );
      if (lesson.questions[0]) {
        getState(
          lesson.editor_content,
          lesson.editor_content?.question_editor_state,
          lesson.questions[0].title,
          'question'
        );
      }

      // set Video URL
      if (lesson.lesson_custom_video.length > 0) {
        setVideoURL(normilizeVideoURL(lesson.lesson_custom_video[0].hashed_id));
      } else if (lesson.video_url) {
        setVideoURL(normilizeVideoURL(lesson.video_url));
      } else {
        setVideoURL(undefined);
      }
    }
  }, [lesson]);

  const onDemoClose = () => {
    let c = 0;
    employeeLessons.results.forEach((l) => {
      if (l.lesson.finished) c++;
    });
    setEmployeeTraining({
      completed_percentage: (c / employeeLessons.results.length) * 100,
      training_items: employeeTraining.training_items,
    });
    if (c === employeeLessons.results.length) {
      setTrainingComplete(true);
    }
    setShowQuestionModal(false);
  };

  const onDemoSubmit = () => {
    const employeeLessonsTemp = employeeLessons.results.map((l) => {
      if (l.lesson.id === employeeLesson.id) {
        l.lesson.finished = true;
        l.finished = true;
      }
      return l;
    });
    employeeLessons.results = employeeLessonsTemp;
    setEmployeeLessons(employeeLessons);

    let completedLessons = 0;
    employeeLessons.results.forEach((less) => {
      if (less.finished) {
        completedLessons++;
      }
    });
    setCompletedLessons(completedLessons);
    const tempItems = employeeTraining.training_items;
    setActiveTrainingItem(tempItems[0]);
    setEmployeeTraining((ps) => ({
      ...ps,
      training_items: ps.training_items.map((i) => ({
        ...i,
        finished: i.employee_item_id === employeeLesson.id ? true : i.finished,
      })),
    }));
  };

  const onFinishLesson = async () => {
    if (activeTrainingItem.finished) {
      setShowResultsPage(true);
      return;
    }
    setIsSubmitting(true);
    await api.lessonSubmit({
      auth: isAuthenticated,
      employeeId: isAuthenticated.id,
      trainingId: trainingId,
      lessonId: lesson.id,
      answersIds: [],
      branching: branchingScenario,
    });
    setRefetchTraining((p) => !p);
    setIsSubmitting(false);
    employeeLesson.finished = true;
    setEmployeeLesson(employeeLesson);
  };

  const lessonButtonDisabled = (employeeLesson) => {
    if (isSubmitting) return true;

    return employeeLesson.finished && employeeLesson.questions?.length === 0;
  };

  const onFinishLessonButtonDemoTrainingButtonClick = () => {
    const employeeLessonsTemp = employeeLessons.results.map((l) => {
      if (l.lesson.id === employeeLesson.id) {
        l.lesson.finished = true;
        l.finished = true;
      }
      return l;
    });
    employeeLessons.results = employeeLessonsTemp;
    setEmployeeLessons(employeeLessons);

    let completedLessons = 0;
    employeeLessons.results.forEach((less) => {
      if (less.finished) {
        completedLessons++;
      }
    });
    setCompletedLessons(completedLessons);

    let nextLesson = employeeLesson;
    let found = false;
    employeeLessons.results.forEach((l) => {
      if (!found) {
        if (!l.lesson.finished && l.lesson.id !== employeeLesson.id) {
          nextLesson = l.lesson;
          found = true;
        }
      }
    });
    if (found) {
      setLesson(nextLesson);
      setEmployeeLesson({
        id: nextLesson.id,
        finished: false,
        lesson: nextLesson,
      });
    } else {
      setShowResultsPage(true);
    }

    const tempItems = employeeTraining.training_items;
    setActiveTrainingItem(tempItems[0]);
    setEmployeeTraining((ps) => ({
      ...ps,
      training_items: ps.training_items.map((i) => ({
        ...i,
        finished: i.employee_item_id === employeeLesson.id ? true : i.finished,
      })),
    }));
  };

  const itemPosition = () => {
    const itemIndex = trainingLessons.findIndex(
      (i) => i.curriculum_item_id === activeTrainingItem.curriculum_item_id
    );
    return `Lesson ${itemIndex + 1}`;
  };

  const itemTitle = () => {
    return lesson && lesson.title.indexOf('|') !== -1
      ? lesson.title.split('|')[1]
      : lesson?.title;
  };

  const itemSubtitle = () => {
    if (!lesson?.is_simple) {
      return lesson?.subtitle;
    }
    return '';
  };

  return (
    <>
      <section className='relative flex-grow order-2 col-span-6 mx-2 sm:mx-3 lg:order-1 lg:col-span-10'>
        <>
          <section className='p-2 mb-2 bg-white sm:px-2 shadow-light sm:p-3 rounded-2xl 2xl:px-6 2xl:py-4 xl:px-3'>
            {/* Lesson Title */}
            <section className='mb-2 lg:mb-4'>
              <section className='flex items-center mb-1 sm:block'>
                <p>{itemPosition()}</p>
                <h2 className='font-basis-bold'>{itemTitle()}</h2>
              </section>
              <p>{itemSubtitle()}</p>
              {/* TO-DO: Check what is lesson.week - not sure if it exists */}
              <p>{lesson?.week && `Week ${lesson.week}`}</p>
              <TrainingOrLessonDuration
                duration={
                  employeeLesson.average_length || lesson.average_length
                }
              />
            </section>
            {/* Lesson Video */}
            {videoURL && (
              <Expander title='Lesson Video'>
                <ReactPlayer
                  url={videoURL}
                  controls
                  className='react-player'
                  height='100%'
                  width='100%'
                />
              </Expander>
            )}

            {/* Lesson Notes */}
            {notesEditorState && (
              <section className='mb-2 lesson--ln'>
                {notesEditorState && (
                  <Expander title='Lesson Notes'>
                    <WysiwygRichTextEditor
                      editorState={notesEditorState}
                      readOnly
                      toolbarHidden
                    />
                  </Expander>
                )}
              </section>
            )}

            {/* Lesson Summary */}
            {lesson && lesson.summary && (
              <section className='mb-2 lesson--summary'>
                {summaryEditorState && (
                  <Expander title='Lesson Content'>
                    <WysiwygRichTextEditor
                      editorState={summaryEditorState}
                      readOnly
                      toolbarHidden
                    />
                  </Expander>
                )}
              </section>
            )}
            {/* Lesson External Resources */}
            <section className='mb-2 lg:mb-4'>
              {lesson && lesson.written_content && !lesson.is_simple && (
                <a
                  href={lesson.written_content}
                  className='inline-flex px-2 button button--blue'
                  target='_blank'
                  rel='noreferrer'
                >
                  {' '}
                  External Resources
                </a>
              )}
            </section>
            <section className='sticky bottom-0 z-10'>
              <section className='flex items-center justify-between px-2 py-1.5 -mx-2 -mb-2 bg-white border-t rounded-b-2xl sm:-mb-3 lg:px-2 xl:px-3 xl:-mx-3 lg:-mx-2 2xl:px-6 2xl:-mx-6 xl:-mb-3 2xl:-mb-4 border-hka_gray-light '>
                <p className='text-base2 font-basis-medium text-hka_gray-dark'>
                  {employeeLesson.lesson.questions.length === 0
                    ? 'This lesson does not have a question.'
                    : employeeLesson?.finished
                    ? 'Review your lesson answers'
                    : 'Ready to answer the lesson question?'}
                </p>
                <button
                  disabled={lessonButtonDisabled(employeeLesson)}
                  onClick={() => {
                    if (employeeLesson.lesson.questions.length === 0) {
                      if (!pathname.includes('catalog')) {
                        onFinishLesson();
                      } else {
                        onFinishLessonButtonDemoTrainingButtonClick();
                      }
                    } else {
                      setShowQuestionModal(true);
                    }
                  }}
                  className='sticky bottom-0 px-2 button button--blue'
                >
                  {employeeLesson.lesson.questions.length === 0
                    ? 'Finish Lesson'
                    : employeeLesson?.finished
                    ? 'Lesson Answers'
                    : 'Lesson Question'}
                </button>
              </section>
            </section>
          </section>
          <section>
            {employeeLesson?.finished && (
              <div
                ref={footerRef}
                className={`${
                  employeeLesson?.finished ? '' : 'hidden'
                } flex flex-col p-2 mb-1 space-y-1 bg-white lg:mb-2 sm:space-y-0 md:items-center rounded-2xl shadow-light 2xl:px-6 2xl:py-3 sm:flex-row xl:px-3`}
              >
                <div className='flex-grow'>
                  <h4 className='text-hka_green'>
                    <span className='text-md mr-0.5'>&#128588;</span> Lesson
                    Complete
                  </h4>
                  <p>
                    You've completed{' '}
                    {!!employeeTraining?.finish_date
                      ? 100
                      : ((completedLessons / totalLessons) * 100).toFixed(0)}
                    % of this training.
                  </p>
                </div>
                <button
                  className='w-full pl-2 pr-1 button button--blue sm:w-auto'
                  onClick={async () => {
                    if (pathname.includes('catalog')) {
                      let nextLesson = employeeLesson;
                      let found = false;
                      employeeLessons.results.forEach((l) => {
                        if (!found) {
                          if (!l.lesson.finished) {
                            nextLesson = l.lesson;
                            found = true;
                          }
                        }
                      });
                      if (found) {
                        setLesson(nextLesson);
                        setEmployeeLesson({
                          id: nextLesson.id,
                          finished: false,
                          lesson: nextLesson,
                        });
                      } else {
                        setShowResultsPage(true);
                      }
                    } else {
                      if (trainingComplete) {
                        setShowResultsPage(true);
                      } else {
                        let activeLesson = employeeLessons.results[0].lesson;
                        for (
                          let i = 0;
                          i < employeeLessons.results.length;
                          i++
                        ) {
                          if (employeeLessons.results[i].finished === false) {
                            activeLesson = employeeLessons.results[i].lesson;
                            break;
                          }
                        }
                        const employeeLesson = await getEmployeeLesson(
                          activeLesson.id
                        );
                        setLesson(employeeLesson.lesson);
                        setEmployeeLesson(employeeLesson);
                      }
                    }
                  }}
                >
                  {trainingComplete ? 'Training Results' : 'Next Lesson'}
                  <span className='ml-1 icon-arrow right'></span>
                </button>
              </div>
            )}
          </section>
        </>
        <section className='flex items-center justify-between px-2 pb-1.5 -mx-2 lg:px-2 xl:px-3 xl:-mx-3 lg:-mx-2 2xl:px-6 2xl:-mx-6 border-hka_gray-light'>
          <p className='text-base font-basis-medium text-hka_gray'>
            See an issue with this lesson? Let us know 👉
          </p>
          <button
            onClick={() => setShowReportLessonIssueModal(true)}
            className='sticky bottom-0 px-2 button button--gray'
          >
            Report Issue
          </button>
        </section>
      </section>

      {lesson.questions.length > 0 && (
        <QuestionModel
          showModal={showQuestionModal}
          defaultQuestion={lesson.questions[0]}
          employeeLesson={employeeLesson}
          onClose={(refetch) => {
            setShowQuestionModal(false);
            if (refetch === 'refetch') {
              setRefetchTraining((p) => !p);
              setScrollToResults(true);
            }
          }}
          onDemoClose={onDemoClose}
          onDemoSubmit={onDemoSubmit}
          branching={branchingScenario}
          questionEditorState={questionEditorState}
          trainingId={trainingId}
        />
      )}
      <ReportLessonIssueModal
        auth={isAuthenticated}
        lessonId={lesson.id}
        objectId={training.id}
        objectType='training'
        showModal={showReportLessonIssueModal}
        setShowModal={setShowReportLessonIssueModal}
      />
    </>
  );
};

export default LessonBody;
