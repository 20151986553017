import { useState } from 'react';
import IntegrationDeleteConfirmationModal from './HrisIntegrations/IntegrationDeleteConfirmationModal';
import * as api from '../../../api';
import { useAppContext } from '../../../libs/contextLib';
import { useSelector, useDispatch } from 'react-redux';
import { settingsActions } from '../../../store/settingsSlice';

const IntegrationCard = ({
  integration,
  btnClick,
  src,
  alt,
  imgCssClasses,
  connected,
}) => {
  const { isAuthenticated } = useAppContext();
  const dispatch = useDispatch();
  const { integrations } = useSelector((state) => state.settings);
  const btnText = integration.charAt(0).toUpperCase() + integration.slice(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const removeIntegrationHandler = async () => {
    await api.removeMergeIntegration({
      auth: isAuthenticated,
      integration,
    });
    dispatch(
      settingsActions.setIntegrations({
        integrations: { ...integrations, [integration]: false },
      })
    );
    setShowDeleteModal(false);
  };

  return (
    <section className='rounded-md border border-hka_gray-light p-1.5 w-20 relative'>
      {connected && (
        <div className='absolute card__actions top-1 right-1'>
          <span
            className='card__action icon-close'
            onClick={() => setShowDeleteModal(true)}
            data-tip='Remove Integration'
          ></span>
        </div>
      )}

      <div className='h-4 mb-3 overflow-hidden'>
        <img src={src} alt={alt} className={imgCssClasses} />
      </div>
      <button
        className='w-full button button--save'
        disabled={false}
        onClick={btnClick}
      >
        {connected ? `Update ${btnText}` : `Link with ${btnText}`}
      </button>

      <IntegrationDeleteConfirmationModal
        showModal={showDeleteModal}
        integration={integration}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={removeIntegrationHandler}
      />
    </section>
  );
};

export default IntegrationCard;
