import { useQuery } from '@tanstack/react-query';
import { getTrainingsWithAssigmentsData } from '../../../api/trainingsApi';

export const useTraningsWithAssigmentsData = ({
  companyID,
  selectedTags,
  title,
  page,
  pageSize,
}) => {
  return useQuery({
    queryKey: [
      'trainings-with-assigments-list',
      companyID,
      page,
      pageSize,
      title,
      ...selectedTags,
    ],
    queryFn: () =>
      getTrainingsWithAssigmentsData({
        companyID,
        selectedTags,
        title,
        page,
        pageSize,
      }),
    keepPreviousData: true,
  });
};

export const prefetchTrainingsWithAssigmentData = ({
  queryClient,
  companyID,
}) => {
  queryClient.prefetchQuery({
    queryKey: ['trainings-with-assigments-list', companyID, 1, 10, '', ...[]],
    queryFn: () =>
      getTrainingsWithAssigmentsData({
        companyID,
        selectedTags: [],
        title: '',
        page: 1,
        pageSize: 10,
      }),
  });
};
