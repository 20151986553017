import React, { useEffect } from "react";

import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  closestCenter,
} from "@dnd-kit/core";
import {
  SortableContext,
  rectSortingStrategy,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
  rectSwappingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { useSortable } from "@dnd-kit/sortable";

import ListItems from "./ListItems";

export const SORTING_STRATEGIES = {
  RECTANGULAR: rectSortingStrategy,
  VERTICAL: verticalListSortingStrategy,
  HORIZONTAL: horizontalListSortingStrategy,
  SWAPPING: rectSwappingStrategy,
};

const SortableItem = ({
  id,
  containerName,
  isDisabled,
  customHandle,
  children,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id, disabled: isDisabled });

  const style = {
    transform: CSS.Transform.toString({ ...transform, scaleX: 1, scaleY: 1 }),
    transition,
  };

  const handle = (
    <span
      className={
        customHandle
          ? ""
          : "cursor-move card__action icon-drag-drop text-hka_gray"
      }
      {...listeners}
      ref={setActivatorNodeRef}
    >
      {customHandle}
    </span>
  );

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className={`${containerName}`}
    >
      {React.cloneElement(children, { handle })}
    </div>
  );
};

const SortableContainer = ({
  children,
  onDragEndHandler,
  useListItemsComponent,
  sortingStrategy = SORTING_STRATEGIES.RECTANGULAR,
  emptyListText,
  containerName,
  customHandle,
  isDisabled = false,
}) => {
  // Override the z-index of the draggable elements
  useEffect(() => {
    if (!containerName) return;
    const elements = Array.from(document.getElementsByClassName(containerName));

    elements.forEach((element, i) =>
      element.setAttribute("style", `z-index: ${99999 - i} !important`)
    );
  }, [children, containerName]);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);

  const isMobileViewPort = window.innerWidth < 640;
  const modifiers = [];
  if (isMobileViewPort) {
    modifiers.push(restrictToParentElement);
  }

  let sortableItems = children.map((child) => (
    <SortableItem
      id={child.props.sortableID}
      key={child.props.sortableID}
      containerName={containerName}
      customHandle={customHandle}
      isDisabled={isDisabled}
    >
      {child}
    </SortableItem>
  ));

  if (useListItemsComponent) {
    sortableItems = (
      <ListItems
        fullWidth
        emptyListText={
          emptyListText ||
          "Oops! It looks like you don't have any item here. Start creating one!"
        }
        flex={
          sortingStrategy === SORTING_STRATEGIES.RECTANGULAR ? "row" : "column"
        }
      >
        {sortableItems}
      </ListItems>
    );
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEndHandler}
      modifiers={modifiers}
    >
      <SortableContext
        items={children.map((child) => child.props.sortableID)}
        strategy={sortingStrategy}
      >
        {sortableItems}
      </SortableContext>
    </DndContext>
  );
};

export default SortableContainer;
