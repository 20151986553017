import React from "react";
import Select from "react-select";

export default function TrainingGroupSelector({
  loadingGroupsCompleted,
  options,
  defaultValue,
  onChange,
}) {
  const formatOptionLabel = ({ icon, name }) => (
    <div className="flex items-center">
      {icon && <span className={`${icon} h-2.4 w-3 mt-0.3 -ml-0.3`}></span>}
      <span className="self-center">{name}</span>
    </div>
  );

  return (
    <Select
      isClearable
      isMulti={true}
      onChange={onChange}
      placeholder="Select group"
      options={options}
      defaultValue={defaultValue}
      classNamePrefix="select"
      className="select--select-groups"
      // maxMenuHeight="20.8rem"
      formatOptionLabel={formatOptionLabel}
      disabled={!loadingGroupsCompleted}
      unstyled
    />
  );
}
