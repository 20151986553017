import React, { Fragment } from 'react';
import Select from 'react-select';
import Slider from '../../Base/Slider';
import AdminSettings from './AdminSettings/AdminSettings';
import { useAppContext } from '../../../libs/contextLib';
import PhishingSettings from './PhishingSettings';
import { useRolesData } from '../../../hooks/data-hooks/settings';

export default function ProfileSettings({
  name,
  email,
  selectedRole,
  handleRoleChange,
  slackNotifications,
  emailNotifications,
  setSlackNotifications,
  setEmailNotifications,
  hasPhishingAccess,
}) {
  const { isAuthenticated } = useAppContext();
  const { data: roles } = useRolesData({ page: 1 });

  const roleOptions = roles?.results.map((role) => ({
    value: role.id,
    label: role.title,
  }));

  const customStyles = {
    control: (base, state) => {
      return {
        ...base,
        border: 'none',
        boxShadow: `0 0 0 1px ${
          state.selectProps.error ? '#f2181f' : base.borderColor
        }`,
      };
    },
    singleValue: (base, state) => {
      return {
        ...base,
        color: `${state.selectProps.error ? '#f2181f' : base.borderColor}`,
      };
    },
  };
  return (
    <Fragment>
      <h1 className='mb-2 font-basis-bold col-span-full'>Profile Settings</h1>
      <section className='p-2 mb-2 bg-white col-span-full shadow-light rounded-xl'>
        <p className='mb-1 text-base2 text-hka_gray-dark'>
          Hi <span className='font-basis-bold'>{name}!</span> here are your
          account settings:
        </p>
        <hr />
        <p className='mt-1 text-hka_gray-dark'>
          <span className='text-md mr-0.5'>&#128075;</span> Your registered name
          is <span className='font-basis-bold'>{name}</span>. You can update
          this setting in Slack and Haekka will update your Haekka account
          automatically.
        </p>
        <p className='mt-1 text-hka_gray-dark'>
          <span className='text-md mr-0.5'>&#128231;</span> Your registered
          email is <span className='font-basis-bold'>{email}</span> You can
          change this in your Slack settings and Haekka will update your account
          automatically.
        </p>
        <div className='mt-3'>
          <h3 className='text-hka_gray-dark setting__title mb-0.5'>
            <span className='text-md'>&#128188;</span> Role
          </h3>
          <p className='mb-1 text-sm'>
            Your current role is set below. You can update your role at anytime.
          </p>
          <Select
            defaultValue={selectedRole}
            value={selectedRole}
            onChange={handleRoleChange}
            placeholder='Select Role'
            isMulti={false}
            options={roleOptions}
            classNamePrefix='select'
            styles={customStyles}
            maxMenuHeight={125}
            unstyled
          />
        </div>
      </section>
      <section className='p-2 mb-2 bg-white col-span-full shadow-light rounded-xl'>
        {slackNotifications !== null ? (
          <section
            className={`flex items-center pb-2 ${
              emailNotifications !== null
                ? 'pb-2 mb-2 space-x-2 border-b border-hka_gray-light'
                : ''
            }`}
          >
            <div className='flex-1'>
              <h3 className='setting__title mb-0.5'>Slack Notifications</h3>
              <p className='text-sm'>
                <span className='font-basis-bold'>On by default</span>, Haekka
                will automatically send you notifications via the Haekka Slack
                Application.
              </p>
            </div>
            <Slider
              checked={slackNotifications}
              onChange={() => {
                setSlackNotifications(!slackNotifications);
              }}
            />
          </section>
        ) : (
          <></>
        )}
        {emailNotifications !== null ? (
          <section
            className={`flex items-center ${
              slackNotifications === null
                ? 'pb-2 mb-2 space-x-2 border-b border-hka_gray-light'
                : ''
            }`}
          >
            <div className='flex-1'>
              <h3 className='setting__title mb-0.5'>Email Notifications</h3>
              <p className='text-sm'>
                <span className='font-basis-bold'>Off by default</span>, Haekka
                will automatically send you notifications via the email
                associated with your Slack account.
              </p>
            </div>
            <Slider
              checked={emailNotifications}
              onChange={() => {
                setEmailNotifications(!emailNotifications);
              }}
            />
          </section>
        ) : (
          <></>
        )}
      </section>

      {hasPhishingAccess && <PhishingSettings auth={isAuthenticated} />}
      {isAuthenticated.is_admin && <AdminSettings />}
    </Fragment>
  );
}
