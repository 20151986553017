const TitleSection = ({ title, setTitle }) => {
  return (
    <section className='flex flex-col items-center justify-between pb-2 sm:flex-row'>
      <h4 className='w-full mb-1 sm:mb-0 sm:w-3/5'>
        Quiz Title <span className='text-sm text-hka_gray'>(Required)</span>
      </h4>

      <div className='relative flex flex-col flex-1 w-full sm:w-2/5'>
        <input
          type='text'
          placeholder='Enter Quiz Title'
          className={`input input--text`}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          maxLength={200}
        />
        {200 - title.length <= 30 && (
          <p
            className={`absolute -bottom-1.4 left-0 text-xs italic text-orange ${
              200 - title.length <= 30 ? '' : ''
            }`}
          >
            <span className='font-basis-bold'>{200 - title.length} </span>
            characters remaining
          </p>
        )}
      </div>
    </section>
  );
};

export default TitleSection;
