import { formatAvatar } from '../../../utils/formatAvatar';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { formatTrainingDate } from '../../../utils/formatTrainingDate';
import toast from 'react-hot-toast';
import DatePicker from 'react-datepicker';
import SingleTrainingDropdownOptions from './SingleTrainingDropdownOptions';
import { useEmployeeTrainingsBulkUpdate } from '../../../hooks/data-hooks/employeeTrainings';

const EmployeeTrainingItem = ({
  user,
  selectedEmployees,
  setSelectedEmployees,
  setAllEmployeesSelected,
  training,
  listChangedHandler,
  trainingLessons,
  curriculumContent,
  assessmentOnlyTraining,
}) => {
  const [changeDueDate, setChangeDueDate] = useState(false);
  const [dueDate, setDueDate] = useState();

  useEffect(() => {
    setDueDate(handleNoDueDate(user.due_date));
  }, [user.due_date]);

  const selectEmployeeHandler = (employee) => {
    const employeeID = employee.employee;
    if (selectedEmployees.includes(employeeID)) {
      setSelectedEmployees((employeeIDs) =>
        employeeIDs.filter((id) => id !== employeeID)
      );
    } else {
      setSelectedEmployees((employeeIDs) => [...employeeIDs, employeeID]);
    }
    setAllEmployeesSelected(false);
  };

  const formatStartDateString = (startDate) => {
    const dateFormat = 'MMMM d, yyyy'; // example: May, 20, 2022

    return format(new Date(startDate), dateFormat);
  };

  function handleNoDueDate(due_date) {
    if (due_date) {
      return formatTrainingDate(new Date(due_date), 'admin');
    } else {
      return 'No Due Date';
    }
  }

  const { mutate: updateTrainings } = useEmployeeTrainingsBulkUpdate({
    onSuccess: (res) => {
      const { action } = res.data;
      if (action === 'due_date_update') {
        setChangeDueDate();
        toast.success(
          `Employee ${user.employee_name}'s due date was changed to ${dueDate}!`
        );
      }
      listChangedHandler();
    },
  });

  async function handleDueDateChange(user, due_date) {
    setDueDate(handleNoDueDate(due_date));

    updateTrainings({
      trainingID: training.id,
      employees: [user.employee],
      allEmployees: false,
      action: 'due_date_update',
      updateData: {
        due_date,
      },
    });
  }

  const formatGradeLabel = () => {
    if (!assessmentOnlyTraining && training.passing_grade > 0) {
      return `Grade (${user.number_of_attempts} attempts)`;
    }
    if (!assessmentOnlyTraining && training.passing_grade === 0) {
      return 'Grade';
    }
    return 'Combined Quiz Grade';
  };

  return (
    <li className='training--assigned-employee'>
      <label className='mr-2 checkbox'>
        <input
          type='checkbox'
          checked={selectedEmployees.includes(user.employee)}
          onChange={() => selectEmployeeHandler(user)}
          className='cursor-pointer'
        />
        <span className='icon-check'></span>
      </label>

      {user.avatar_image ? (
        <img
          className='mr-1 user-img h-2.4 w-2.4'
          src={user.avatar_image}
          alt={user.employee_name}
        />
      ) : (
        formatAvatar({
          name: user.employee_name,
          width: 3.2,
          marginRight: 1,
        })
      )}
      <div className='flex flex-col space-y-0.5 items-start flex-1 flex-grow lg:flex-row flex-nowrap lg:items-center'>
        <div className='flex flex-col flex-1 leading-3 xl:items-center xl:flex-row flex-nowrap text-hka_gray-dark'>
          <div className='flex flex-col'>
            <div className='flex flex-col flex-initial xl:flex-row '>
              <p className='font-basis-medium text-hka_gray-dark'>
                {user.employee_name}
              </p>
              {user.is_attempt_active && user.completed_percentage < 100 ? (
                <p className='2xl:my-0 my-0.2'>
                  <span className='px-0.5 py-0.2 text-sm 2xl:ml-1 font-basis-bold text-white rounded-md bg-orange truncate'>
                    In Progress
                  </span>
                </p>
              ) : (
                ''
              )}
            </div>
            {user.start_date && (
              <p className='text-sm'>
                Assigned {formatStartDateString(user.start_date)}
              </p>
            )}
          </div>
        </div>

        <section className='flex flex-1'>
          {changeDueDate?.user === user ? (
            <DatePicker
              className='items-center focus:outline-none text-hka_gray datepicker'
              selected={user.due_date === null ? null : new Date(user.due_date)}
              onChange={(date) => handleDueDateChange(user, date)}
              dateFormat='PP'
              placeholderText=''
            />
          ) : (
            <p
              className='relative flex lg:ml-1.3 items-center w-auto leading-3.9 bg-white border-b border-dotted cursor-pointer border-hka_gray'
              onClick={() => setChangeDueDate({ user: user })}
            >
              <span className='-ml-0.5 mr-0.5 icon-calendar'></span>
              Due: {dueDate}
            </p>
          )}
        </section>

        <section className='flex items-center justify-center flex-1 sm:flex-col sm:w-auto'>
          <p className='mr-1 font-basis-bold sm:mr-0'>
            {user.latest_grade === null ? '—' : `${user.latest_grade}% `}
          </p>

          {formatGradeLabel()}
        </section>

        <section className='flex items-center justify-center sm:flex-col sm:w-auto'>
          <p className='mr-1 font-basis-bold sm:mr-0'>
            {Math.round(user.completed_percentage)}%
          </p>
          <p className='text-sm'>Completion Rate</p>
        </section>
      </div>
      <div className='flex justify-end space-x-1 lg:pl-4'>
        <SingleTrainingDropdownOptions
          employee={user}
          training={training}
          updateEmployeeList={listChangedHandler}
          trainingLessons={trainingLessons}
          curriculumContent={curriculumContent}
        />
      </div>
    </li>
  );
};

export default EmployeeTrainingItem;
