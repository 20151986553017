import { useState } from 'react';
import useCopyToClipboard from '../../../utils/custom-hooks/useCopyToClipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from '../../../libs/contextLib';
import * as api from '../../../api';
import { settingsActions } from '../../../store/settingsSlice';
import toast from 'react-hot-toast';
import { useWebhookIntegrationAuthHeaderData } from '../../../hooks/data-hooks/integrations';

const backendUrl = 'https://app.haekka.com/api/integrations/webhook/okta';
const oktaAuthHeader = 'okta-webhook-auth';
const copyClasses =
  'bg-orange-dark text-white p-0.5 rounded-md cursor-pointer ml-auto mr-1 -mt-0.5';

const OktaWebHook = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAppContext();

  const { integrations } = useSelector((state) => state.settings);

  const [showWebhookSetupSteps, setShowWebhookSetupSteps] = useState(false);
  const [authToken, setAuthToken] = useState(undefined);

  const [copyToClipboardBackendURL, { success: backendURLcopied }] =
    useCopyToClipboard();
  const [copyToClipboardAuthHeader, { success: authHeaderCopied }] =
    useCopyToClipboard();
  const [copyToClipboardAuthHeaderValue, { success: authHeaderValueCopied }] =
    useCopyToClipboard();
  const [copyToClipboardEventType, { success: eventTypeCopied }] =
    useCopyToClipboard();

  const { refetch: getAuthToken } = useWebhookIntegrationAuthHeaderData({
    companyID: isAuthenticated.company,
    integration: 'okta',
    onSuccess: (response) => {
      const { token } = response;
      setAuthToken(token);
    },
  });

  const showSetupStepsHandler = async () => {
    setShowWebhookSetupSteps(true);
    getAuthToken();
  };

  const checkWebhookSuccess = async () => {
    const integrations = await api.getIntegrations({
      auth: isAuthenticated,
    });
    if (integrations.okta_webhook_verified) {
      toast.success('You were successful!');
      setShowWebhookSetupSteps(false);
      dispatch(settingsActions.setIntegrations({ integrations }));
    } else {
      toast.custom(
        <div className='p-2 text-base text-white bg-orange rounded-xl'>
          Webhook is not set up yet.
        </div>
      );
    }
  };

  return (
    <section className='mt-2'>
      <h4 className='mb-1 font-basis-bold'>
        Webhook settings <span className='text-hka_gray'>(Optional)</span>
      </h4>
      {integrations.okta_webhook_verified && (
        <p>Webhook vefiried! Selected Okta groups will sync in real time.</p>
      )}

      {!integrations.okta_webhook_verified && (
        <>
          <p>
            This feature allows training to sync with Okta groups in real time.
            To use it, you have to setup a webhook in your Okta dashboard. Not
            to worry, it's quite easy - start{' '}
            <span
              className='cursor-pointer text-orange-dark'
              onClick={showSetupStepsHandler}
            >
              here
            </span>
            .
          </p>
          {showWebhookSetupSteps && (
            <section className='mt-1'>
              <div className='flex mb-1'>
                <p className='w-2'>1.</p>
                <p>
                  Go to your Okta dashboard {'->'} Workflows {'->'} Event Hooks
                  and click to the <b>Create Event Hook</b> button
                </p>
              </div>
              <div className='flex mb-1'>
                <p className='w-2'>2.</p>
                <p>Enter a Webhook name of your choice</p>
              </div>
              <div className='flex mb-1'>
                <p className='w-2'>3.</p>
                <p>
                  In the URL field enter Haekka's backend URL:{' '}
                  <b>{backendUrl}</b>
                </p>
                <p
                  className={copyClasses}
                  onClick={() => copyToClipboardBackendURL(backendUrl)}
                >
                  {backendURLcopied ? 'copied' : 'copy'}
                </p>
              </div>
              <div className='flex mb-1'>
                <p className='w-2'>4.</p>
                <p>
                  In the Authentication field enter: <b>{oktaAuthHeader}</b>
                </p>
                <p
                  className={copyClasses}
                  onClick={() => copyToClipboardAuthHeader(oktaAuthHeader)}
                >
                  {authHeaderCopied ? 'copied' : 'copy'}
                </p>
              </div>
              <div>
                <div className='flex mb-1'>
                  <p className='w-2'>5.</p>
                  <p>
                    In the Authentication secret field enter: <b>{authToken}</b>
                  </p>
                  <p
                    className={copyClasses}
                    onClick={() => copyToClipboardAuthHeaderValue(authToken)}
                  >
                    {authHeaderValueCopied ? 'copied' : 'copy'}
                  </p>
                </div>
                <div className='flex mb-1'>
                  <section className='w-2 mr-1'></section>
                  <p>
                    Note: This secret is dynamically generated and specific to
                    your organization. We're using it to authenticate your
                    webhook events. We're storing HMAC hash value of this secret
                    in our database.
                  </p>
                </div>
              </div>

              <div className='flex mb-1'>
                <p className='w-2'>6.</p>
                <p>
                  In the Subscribe to events field select the{' '}
                  <b>User added to group</b> event
                </p>
                <p
                  className={copyClasses}
                  onClick={() =>
                    copyToClipboardEventType('User added to group')
                  }
                >
                  {eventTypeCopied ? 'copied' : 'copy'}
                </p>
              </div>
              <div className='flex mb-1'>
                <p className='w-2'>7.</p>
                <p>
                  Click to <b>Save & Continue</b> button
                </p>
              </div>
              <div className='flex mb-1'>
                <p className='w-2'>8.</p>
                <p>
                  Click to <b>Verify</b> button
                </p>
              </div>

              <p className='mt-2'>
                If you have set up everything correctly, your webhook should get
                verified and Haekka will assign this training to every user you
                add in any group you select in{' '}
                <b>Add users from an Okta group</b> field above. (
                <span
                  className='cursor-pointer text-orange-dark'
                  onClick={checkWebhookSuccess}
                >
                  click here
                </span>{' '}
                to check if your setup was registered correctly)
              </p>
            </section>
          )}
        </>
      )}
    </section>
  );
};

export default OktaWebHook;
