import WysiwygRichTextEditor from '../../WysiwygEditor/WysiwygRichTextEditor';

const WelcomeMessage = ({
  welcomeMessage,
  setWelcomeMessage,
  welcomeMessageEnabled,
  setWelcomeMessageEnabled,
  welcomeMessageButtonText,
  setWelcomeMessageButtonText,
  isReadOnly,
}) => {
  return (
    <section className='relative z-0 flex flex-col justify-between gap-2 my-2'>
      <div className='flex justify-between gap-2 sm:items-center'>
        <div>
          <h4 className='mb-0.2'>
            Welcome Message{' '}
            <span className='text-sm text-hka_gray'>(Optional)</span>
          </h4>
          <p className='text-sm'>
            This message gets sent to each user upon assignment. Toggle the
            switch to disable the message.
          </p>
        </div>

        <div>
          <label className='switch'>
            <input
              type='checkbox'
              name='acknowledge_button'
              checked={welcomeMessageEnabled}
              onChange={(e) => setWelcomeMessageEnabled(e.target.checked)}
              className='cursor-pointer'
              disabled={isReadOnly}
            />
            <span className='slider'></span>
          </label>
        </div>
      </div>

      {welcomeMessageEnabled && (
        <div>
          <WysiwygRichTextEditor
            editorState={welcomeMessage}
            onSetEditorState={(state) => setWelcomeMessage(state)}
            id={undefined}
            onImageAdded={() => {}}
            contentType={'engagement_close_message'}
            questionId={undefined}
            disableImageUpload
            readOnly={isReadOnly}
          />
        </div>
      )}

      {welcomeMessageEnabled && (
        <div className='relative flex items-center w-full gap-1'>
          <p className='sm:w-2/3'>Button Text</p>
          <input
            type='text'
            placeholder='Set Button Text'
            className='text-base border outline-none input'
            value={welcomeMessageButtonText}
            onChange={(e) => setWelcomeMessageButtonText(e.target.value)}
            maxLength={30}
            disabled={isReadOnly}
          />
        </div>
      )}
    </section>
  );
};

export default WelcomeMessage;
