import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteLesson } from '../../../api/trainingsApi';

export const useDeleteLesson = ({ curriculumID }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteLesson,
    onSuccess: () =>
      queryClient.invalidateQueries(['curriculum-content', curriculumID]),
  });
};
