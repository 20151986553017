import { useQuery } from '@tanstack/react-query';
import { getRoles } from '../../../api/settings/companySettingsApi';

export const useRolesData = ({ page = 1, page_size = 10_000 }) => {
  return useQuery({
    queryKey: ['roles', page, page_size],
    queryFn: () => getRoles({ page, page_size }),
    keepPreviousData: true,
  });
};
