import React, { Fragment, useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import {
  useDeleteLesson,
  useReorderCurriculumItems,
} from '../../../hooks/data-hooks/trainings';
import { useDeleteTrainingAssessment } from '../../../hooks/data-hooks/assessments';

import { modalModes } from '../../../utils/commonUtils';
import {
  CURRICULUM_ITEM_TYPES,
  checkSkippedLessons,
} from '../../../utils/training';
import CurriculumContentItem from './CurriculumContentItem';
import CurriculumItemDeleteConfirmModal from './CurriculumItemDeleteConfirmModal';
import SortableContainer, { SORTING_STRATEGIES } from '../../SortableContainer';
import EmptyListRow from '../../EmptyListRow';
import ReorderErrorConfirmModal from './ReorderErrorConfirmModal';

const ListCurriculumContent = ({
  curriculumContent,
  curriculumId,
  isCustomTraining,
  onSetActiveLesson,
  setEditLessonOpened,
  setAddAssessmentModal,
  viewMode = 'TILE_MODE',
}) => {
  const [curriculumItems, setCurricumItems] = useState([]);
  const [deleteItemConfirmationModal, setDeleteItemConfirmationModal] =
    useState(undefined);
  const [reorderNotAllowedModal, setReorderNotAllowedModal] =
    useState(undefined);

  useEffect(() => {
    // setting items to the local state => it is easier to maintain the dnd functionality
    setCurricumItems(curriculumContent);
  }, [curriculumContent]);

  const openItemHandler = (item) => {
    if (item.type === CURRICULUM_ITEM_TYPES.LESSON) {
      onSetActiveLesson(item.value);
      setEditLessonOpened({ isOpened: true, mode: modalModes.EDIT });
    }
    if (item.type === CURRICULUM_ITEM_TYPES.ASSESSMENT) {
      setAddAssessmentModal({ create: false, assessment: item.value });
    }
  };

  const itemDeleteAttemptHandler = (item) => {
    const itemID = item.value.id;
    setDeleteItemConfirmationModal({
      show: true,
      itemType: item.type,
      itemID,
    });
  };

  const { mutate: deleteLesson } = useDeleteLesson({
    curriculumID: curriculumId,
  });

  const { mutate: deleteAssessment } = useDeleteTrainingAssessment({
    curriculumID: curriculumId,
  });

  const reorderErrorCb = (reason) => {
    setReorderNotAllowedModal({ reason });
    setCurricumItems(curriculumContent);
  };

  const { mutate: reorderItems } = useReorderCurriculumItems({
    curriculumID: curriculumId,
    reorderErrorCb,
  });

  const itemDeletionHandler = async () => {
    if (deleteItemConfirmationModal.itemType === CURRICULUM_ITEM_TYPES.LESSON) {
      deleteLesson({ lessonID: deleteItemConfirmationModal.itemID });
    }
    if (
      deleteItemConfirmationModal.itemType === CURRICULUM_ITEM_TYPES.ASSESSMENT
    ) {
      deleteAssessment({ assessmentID: deleteItemConfirmationModal.itemID });
    }
    setDeleteItemConfirmationModal(undefined);
    toast.success(`${deleteItemConfirmationModal.itemType} deleted`);
  };

  const reorderCurriclumItems = (trainingLessons, startIndex, endIndex) => {
    const result = [...trainingLessons];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    let i = -1;

    return result.map((r) => {
      i++;
      return { ...r, value: { ...r.value, position: i } };
    });
  };

  const onDragEndHandler = async ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = curriculumContent.findIndex(
        (item) => item.value.id === active.id
      );
      const newIndex = curriculumContent.findIndex(
        (item) => item.value.id === over.id
      );
      const items = reorderCurriclumItems(
        curriculumContent,
        oldIndex,
        newIndex
      );
      const reorderError = checkSkippedLessons(active, items, newIndex);
      if (!!reorderError) {
        setReorderNotAllowedModal(reorderError);
        return;
      }
      setCurricumItems(items);
      reorderItems({ curriculumID: curriculumId, payload: items });
    }
  };

  if (curriculumContent.length === 0) {
    return (
      <EmptyListRow
        noButton
        text="You haven't added any lessons or quizzes yet."
      />
    );
  }

  return (
    <Fragment>
      <SortableContainer
        onDragEndHandler={onDragEndHandler}
        useListItemsComponent
        sortingStrategy={
          viewMode === 'TILE_MODE'
            ? SORTING_STRATEGIES.RECTANGULAR
            : SORTING_STRATEGIES.VERTICAL
        }
      >
        {curriculumItems.map((item) => (
          <CurriculumContentItem
            key={item.value.id}
            item={item}
            viewMode={viewMode}
            openItemHandler={() => openItemHandler(item)}
            itemDeleteAttemptHandler={() => itemDeleteAttemptHandler(item)}
            showActionButtons={isCustomTraining}
            curriculumContent={curriculumContent}
            sortableID={item.value.id}
          />
        ))}
      </SortableContainer>

      {deleteItemConfirmationModal?.show && (
        <CurriculumItemDeleteConfirmModal
          showModal={!!deleteItemConfirmationModal.show}
          itemType={deleteItemConfirmationModal?.itemType}
          onSubmit={itemDeletionHandler}
          onClose={() => setDeleteItemConfirmationModal(undefined)}
        />
      )}
      {!!reorderNotAllowedModal && (
        <ReorderErrorConfirmModal
          showModal={!!reorderNotAllowedModal}
          onClose={() => setReorderNotAllowedModal(undefined)}
          data={reorderNotAllowedModal}
        />
      )}
    </Fragment>
  );
};

export default ListCurriculumContent;
