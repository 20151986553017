import { useQuery } from '@tanstack/react-query';
import { getEmployeeTrainingAssessmentQuestionAttempts } from '../../../api/employeeTrainingsApi';

export const useAssessmentQuestionAttemptsData = ({
  employeeID,
  assessmentID,
  questionID,
  enabled,
}) => {
  return useQuery({
    queryKey: [
      'employee-trainings-assessment-question-attempts',
      employeeID,
      assessmentID,
      questionID,
    ],
    queryFn: () =>
      getEmployeeTrainingAssessmentQuestionAttempts({
        employeeID,
        assessmentID,
        questionID,
      }),
    enabled,
    select: (response) => response.results,
  });
};
