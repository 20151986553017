import { useQuery } from '@tanstack/react-query';
import { getTrainingTags } from '../../../api/trainingsApi';

export const useTrainingsTagsData = ({
  companyID,
  page,
  pageSize,
  enabled,
}) => {
  return useQuery({
    queryKey: ['trainings-tags', companyID, page, pageSize],
    queryFn: () =>
      getTrainingTags({
        companyID,
        page,
        pageSize,
      }),
    keepPreviousData: true,
    enabled,
  });
};

export const prefetchTrainingsTagsData = ({
  queryClient,
  companyID,
  enabled,
}) => {
  queryClient.prefetchQuery({
    queryKey: ['trainings-tags', companyID, undefined, undefined],
    queryFn: () =>
      getTrainingTags({
        companyID,
        page: 1,
        pageSize: undefined,
      }),
    enabled,
  });
};
