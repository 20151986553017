import { Cell } from "rsuite-table";

const AnswerCell = ({ rowData, dataKey, ...props }) => {
  const answersData = rowData[dataKey]?.answer;
  let answerJSX = <p className="px-1 text-sm line-clamp-3">{answersData}</p>;
  if (answersData?.hasAnswers) {
    answerJSX = answersData.map((a) => (
      <div className={`${a.cssClasses} px-1`} key={a.text}>
        <span className="answer-icon"></span>
        <p className="text-sm line-clamp-1">{a.text}</p>
      </div>
    ));
  }
  return (
    <Cell {...props} style={{ padding: 0 }}>
      <div className="flex flex-col space-y-0.5">{answerJSX}</div>
    </Cell>
  );
};

export default AnswerCell;
