import { Cell } from "rsuite-table";

const statusColoring = {
  Completed: "bg-hka_green",
  "In Progress": "bg-orange-dark",
  "Not Started": "bg-gray-400",
};

const StatusCell = ({ rowData, dataKey, ...props }) => {
  const bgColor = statusColoring[rowData[dataKey]];
  return (
    <Cell {...props} style={{ padding: 0 }}>
      <div className="flex items-center w-full px-1 space-x-1">
        <div className={`h-1 w-1 ${bgColor} rounded-full z-50`}></div>
        <div className="text-sm">{rowData[dataKey]}</div>
      </div>
    </Cell>
  );
};

export default StatusCell;
