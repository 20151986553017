import { useState } from 'react';
import Select from 'react-select';

import { useAppContext } from '../../../libs/contextLib';
import { useEmployeesData } from '../../../hooks/data-hooks/employees/useEmployeesData';

// TO-DO: Check the OwnersInput component - it might have the same purpose
const OwnersSection = ({ owners, setOwners, isReadOnly }) => {
  const { isAuthenticated } = useAppContext();
  const { data: admins } = useEmployeesData({
    auth: isAuthenticated,
    page: 1,
    pageSize: 100,
    isAdmin: true,
  });

  const [oneOwnerRequiredWarning, setOneOwnerRequiredWarning] = useState(false);

  const changeOwnerHandler = (data) => {
    if (data.length > 0) {
      setOwners(data);
      setOneOwnerRequiredWarning(false);
    } else {
      setOneOwnerRequiredWarning(true);
    }
  };

  return (
    <section className='flex flex-col items-center justify-between sm:flex-row'>
      <div className='w-full mb-1 sm:mb-0 sm:w-3/5'>
        <h4 className='mb-0.2'>
          Engagement Owners{' '}
          <span className='text-sm text-hka_gray'>(Required)</span>
        </h4>
        <p className='text-sm'>
          The creator of the Engagement is automatically designated as an owner.
          Add additional owners to receive notifications when users complete
          this Engagement. Owners must be existing Haekka admins.
        </p>
      </div>
      <div className='w-full md:w-2/5'>
        <Select
          options={admins?.results.map((admin) => ({
            label: admin.name,
            value: admin.id,
          }))}
          onChange={changeOwnerHandler}
          value={owners}
          classNamePrefix='select'
          className='select--select-employees-manually'
          isMulti
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          unstyled
          menuPortalTarget={document.body}
          maxMenuHeight='13rem'
          menuPlacement='auto'
          isDisabled={isReadOnly}
        />
      </div>
      {oneOwnerRequiredWarning && (
        <p className='w-full mt-0.2 text-xs text-hka_red opacity-70 font-basis-medium'>
          At least one Engagement owner is required.
        </p>
      )}
    </section>
  );
};

export default OwnersSection;
