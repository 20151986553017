import { useQuery } from "@tanstack/react-query";
import { getEngagementStatistics } from "../../../api/engagementsApi";

const staleTime = 60000;

export const useEngagmentStatisticsData = ({ id }) => {
  return useQuery({
    queryKey: ["engagement_statistics", id],
    queryFn: () => getEngagementStatistics({ companyEngagementID: id }),
    staleTime,
  });
};

export const prefetchEngagementStatisticsData = (
  queryClient,
  companyEngagementID
) => {
  queryClient.prefetchQuery({
    queryKey: ["engagement_statistics", companyEngagementID],
    queryFn: () => getEngagementStatistics({ companyEngagementID }),
    staleTime,
  });
};
