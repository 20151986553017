import "./EngagementMetrics/EngagementTrendsChart.css";
import { Fragment } from "react";
import ActionButton from "../../ActionButton";
import EngagementTrendsChart from "./EngagementMetrics/EngagementTrendsChart";
import EmptyMetricsStateCard from "./EmptyMetricsStateCard";
import EmptyTopicStateCard from "./EmptyTopicStateCard";
import Divider from "../../Base/Divider";

const EngagementsCompletedSection = ({
  finishedEngagementsInLast30Days,
  finishedEngagementsOfAllTime,
  companyHasFinishedEngagementsData,
}) => {
  const engagementPercentage = companyHasFinishedEngagementsData
    ? Math.round(
        (finishedEngagementsInLast30Days / finishedEngagementsOfAllTime) * 100,
        10
      )
    : 0;
  return (
    <Fragment>
      <div className="flex-1 pr-4 truncate">
        <p className="mb-1 text-sm truncate">Completed (30 days)</p>
        <div className="flex items-center">
          <p className="mr-1 text-2xl">{finishedEngagementsInLast30Days}</p>
          <div>
            <p
              className={`
                px-1 py-0.2 text-xs rounded-full font-basis-bold text-hka_green ${
                  engagementPercentage > 0 ? "bg-hka_green-light" : ""
                }
              `}
            >
              {engagementPercentage > 0 ? "+ " : ""}
              {engagementPercentage}%
            </p>
          </div>
        </div>
      </div>
      <span className="min-h-full vr w-0.1 bg-hka_gray-light flex"></span>
      <div className="flex-1 pl-4 truncate">
        <p className="mb-1 text-sm truncate">Completed (all time)</p>
        <p className="text-2xl">{finishedEngagementsOfAllTime}</p>
      </div>
    </Fragment>
  );
};

const NoEngagementTrendsSection = () => {
  const engagementsDescription = `
          Engagements are a great way to connect security to your employees.
          Launch recurring or one-time surveys, quizzes, security announcements
          and more.
  `;

  const engagementsMetricsDescription = `
        While you work on engagements, we'll provide trend metrics here so you
        can see how often employees are interacting with content sent by Haekka.
  `;
  return (
    <EmptyTopicStateCard
      topic={"Engagements"}
      topicDescription={engagementsDescription}
      metricsDescription={engagementsMetricsDescription}
    />
  );
};

const NoEngagementTrendsDataSection = () => {
  return (
    <div className="flex items-center">
      <img
        alt="No Engagement Trend Data"
        src="https://haekka-public-images.s3.us-west-2.amazonaws.com/hka--engagement-no-trend-data.svg"
        className="mr-2 max-w-50 sm:max-w-50"
      ></img>
      <p className="">
        We don't have any trend data yet -- Complete an engagement to start
        viewing engagement trends!
      </p>
    </div>
  );
};

const EngagementTrendsSection = ({
  engagementTrendsInLast30Days,
  engagementTrendsOfAllTime,
}) => {
  const shouldRenderTrends = (trends) =>
    Object.keys(trends).reduce((acc, key) => (acc += trends[key]), 0) > 0;

  const fixTopAreaCutoff = (trends) => {
    const keys = Object.keys(trends);
    const areasToRoundOff = [];

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      const area = [];

      for (let j = i; j < keys.length; j++) {
        const nextKey = keys[j];

        const currentValue = trends[key];
        const nextValue = trends[nextKey];

        if (currentValue === 0 && nextValue === 0) {
          continue;
        }

        const flatAreas = areasToRoundOff.flat();

        if (currentValue === nextValue && !flatAreas.includes(j)) {
          area.push(j);
        } else {
          break;
        }
      }

      if (area.length > 1) {
        areasToRoundOff.push(area);
      }
    }

    for (let i = 0; i < areasToRoundOff.length; i++) {
      const area = areasToRoundOff[i];
      const delimiter = parseInt((area.length - 1) / 2);

      let increment = 0;

      for (let j = 0; j < area.length; j++) {
        if (j <= delimiter) {
          increment += 1;
        } else {
          increment -= 1;
        }

        const trend = trends[keys[area[j]]];

        trends[keys[area[j]]] = Number(`${trend}.${increment}`);
      }
    }
  };

  fixTopAreaCutoff(engagementTrendsInLast30Days);
  fixTopAreaCutoff(engagementTrendsOfAllTime);

  const shouldRenderLast30DaysChart = shouldRenderTrends(
    engagementTrendsInLast30Days
  );

  const shouldRenderAllTimeChart = shouldRenderTrends(
    engagementTrendsOfAllTime
  );

  const CHART_OPTIONS = {
    HKA_ORANGE: {
      COLORS: {
        PRIMARY: "#FF4200",
        SECONDARY: "#FDD461",
      },
      OPACITIES: {
        START: 1,
        END: 1,
      },
      CLASSES: {
        BORDER_COLOR: "orange-border-color",
      },
    },
    HKA_BLUE: {
      COLORS: {
        PRIMARY: "#0094FF",
        SECONDARY: "#7DC8FF",
      },
      OPACITIES: {
        START: 1,
        END: 0.35,
      },
      CLASSES: {
        BORDER_COLOR: "blue-border-color",
      },
    },
  };

  const aspectRatio =
    shouldRenderLast30DaysChart && shouldRenderAllTimeChart ? 3 : 6;

  return (
    <Fragment>
      {shouldRenderLast30DaysChart && (
        <div
          className={`flex-1 pt-0.5 ${shouldRenderAllTimeChart ? "pr-2" : ""}`}
        >
          <p className="mb-1 text-sm truncate">Trends (30 days)</p>
          <EngagementTrendsChart
            gradientName="LAST_30_DAYS_TRENDS_CHART"
            aspectRatio={aspectRatio}
            trends={engagementTrendsInLast30Days}
            primaryColor={CHART_OPTIONS.HKA_ORANGE.COLORS.PRIMARY}
            secondaryColor={CHART_OPTIONS.HKA_ORANGE.COLORS.SECONDARY}
            gradientStartOpacity={CHART_OPTIONS.HKA_ORANGE.OPACITIES.START}
            gradientEndOpacity={CHART_OPTIONS.HKA_ORANGE.OPACITIES.END}
            borderColorClass={CHART_OPTIONS.HKA_ORANGE.CLASSES.BORDER_COLOR}
          />
        </div>
      )}

      {shouldRenderLast30DaysChart && shouldRenderAllTimeChart && (
        <Divider vertical />
      )}

      {shouldRenderAllTimeChart && (
        <div
          className={`flex-1 pt-0.5 ${
            shouldRenderLast30DaysChart ? "pl-2" : ""
          }`}
        >
          <p className="mb-1 text-sm truncate">Trends (all time)</p>
          <EngagementTrendsChart
            gradientName="ALL_TIME_TREND_CHART"
            aspectRatio={aspectRatio}
            trends={engagementTrendsOfAllTime}
            primaryColor={CHART_OPTIONS.HKA_BLUE.COLORS.PRIMARY}
            secondaryColor={CHART_OPTIONS.HKA_BLUE.COLORS.SECONDARY}
            gradientStartOpacity={CHART_OPTIONS.HKA_BLUE.OPACITIES.START}
            gradientEndOpacity={CHART_OPTIONS.HKA_BLUE.OPACITIES.END}
            borderColorClass={CHART_OPTIONS.HKA_BLUE.CLASSES.BORDER_COLOR}
          />
        </div>
      )}

      {!shouldRenderLast30DaysChart && !shouldRenderAllTimeChart && (
        <NoEngagementTrendsDataSection />
      )}
    </Fragment>
  );
};

const EngagementMetrics = ({
  finishedEngagementsInLast30Days,
  finishedEngagementsOfAllTime,
  engagementTrendsInLast30Days,
  engagementTrendsOfAllTime,
  activeEngagementsCount,
}) => {
  const companyHasActiveEngagements = activeEngagementsCount > 0;

  const companyHasFinishedEngagementsData =
    finishedEngagementsInLast30Days !== 0 && finishedEngagementsOfAllTime !== 0;

  return (
    <Fragment>
      <section className="mb-2 space-y-1">
        <header className="flex flex-col lg:items-center lg:justify-between lg:flex-row">
          <h4 className="flex items-center mb-2 lg:mb-0">Engagements</h4>
          {companyHasActiveEngagements && (
            <ActionButton
              className="w-full button--blue sm:w-auto button"
              to={"/engagements"}
            >
              {`View Engagements (${activeEngagementsCount})`}{" "}
              <span className="icon-arrow right text-hka_blue -mr-0.5 ml-1"></span>
            </ActionButton>
          )}
        </header>

        <div className="flex flex-col space-y-2 lg:flex-row lg:space-y-0">
          <div className="flex-1 lg:pr-2 lg:max-w-50">
            <div className="flex h-full p-2 bg-white rounded-xl lg:p-4 shadow-light">
              {companyHasActiveEngagements ? (
                <EngagementsCompletedSection
                  finishedEngagementsOfAllTime={finishedEngagementsOfAllTime}
                  finishedEngagementsInLast30Days={
                    finishedEngagementsInLast30Days
                  }
                  companyHasFinishedEngagementsData={
                    companyHasFinishedEngagementsData
                  }
                />
              ) : (
                <EmptyMetricsStateCard
                  title="Create your first Engagement"
                  redirectPath="/engagements?create=true"
                  imageUrl="https://haekka-public-images.s3.us-west-2.amazonaws.com/hka--no-engagement.svg"
                />
              )}
            </div>
          </div>

          <div className="flex-1 lg:pl-2 lg:max-w-50">
            <div className="flex h-full bg-white rounded-xl p-2 shadow-light">
              {companyHasActiveEngagements ? (
                <EngagementTrendsSection
                  engagementTrendsInLast30Days={engagementTrendsInLast30Days}
                  engagementTrendsOfAllTime={engagementTrendsOfAllTime}
                />
              ) : (
                <NoEngagementTrendsSection />
              )}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default EngagementMetrics;
