import toast from 'react-hot-toast';
import * as api from '../api';
import { logEmployeesAssignedTraining } from '../utils/internal-event-logger';
import { updateExternalAppSettings } from '../api/integrationsApi';
import {
  assignEmployeesTrainingWithDoceboOption,
  assignEmployeesTrainingWithGoogleOption,
  assignEmployeesTrainingWithSeismicOption,
} from '../api/trainingsApi';

export const handleSlackOptionsTrainingSubmit = async ({
  slackAutoEnroll,
  selectedChannelsSync,
  isAuthenticated,
  object,
  setSlackAutoEnroll,
  channelOptions,
  setSelectedChannelsSync,
  slackAddAllUsers,
  manuallySelectedEmployees,
  selectedChannels,
  logEmployeesAssignedTraining,
  alertEmployees,
}) => {
  const updateData = {
    auto_enroll: slackAutoEnroll,
    channels: selectedChannelsSync.map((c) => c.value),
  };

  const trainingRes = await api.updateTraining({
    auth: isAuthenticated,
    trainingId: object.id,
    updateData,
  });
  setSlackAutoEnroll(trainingRes.auto_enroll);
  const ch = channelOptions.filter((co) =>
    trainingRes.channels.includes(co.value)
  );
  setSelectedChannelsSync(ch);

  if (slackAddAllUsers) {
    const res = await api.openBulkEmployeesTrainingCompany({
      auth: isAuthenticated,
      curriculumTrainingId: object.curriculum_detail.id,
      manuallySelectedUsers: manuallySelectedEmployees,
      alertEmployees,
    });
    toast.success(res.results);
  } else if (selectedChannels.length > 0) {
    const res = await api.openBulkEmployeesTrainingChannel({
      auth: isAuthenticated,
      curriculumTrainingId: object.curriculum_detail.id,
      channels: selectedChannels.map((c) => ({
        slack_channel: c.value,
      })),
      manuallySelectedUsers: manuallySelectedEmployees,
      alertEmployees,
    });
    toast.success(res.results);
  } else {
    // Add just manually selected users
    const res = await sumbitManuallySelectedEmployees({
      isAuthenticated,
      object,
      manuallySelectedEmployees,
      alertEmployees,
    });
    toast.success(res.results);
  }
  logEmployeesAssignedTraining({
    isAuthenticated,
    training: object,
    isAllUsersChecked: slackAddAllUsers,
    channels: selectedChannels,
    channelsSync: selectedChannelsSync,
    manuallySelecetedUsers: manuallySelectedEmployees.length,
    csvUsers: 0,
    copyFromTraining: undefined,
  });
};

const sumbitManuallySelectedEmployees = async ({
  isAuthenticated,
  object,
  manuallySelectedEmployees,
  uploadedCsvUsers = [],
  alertEmployees,
  copyUsersFromTraining = undefined,
}) => {
  return await api.openBulkEmployeesTrainingSelect({
    auth: isAuthenticated,
    curriculumTrainingId: object.curriculum_detail.id,
    manuallySelectedUsers: [...manuallySelectedEmployees, ...uploadedCsvUsers],
    alertEmployees,
    copyUsersFromTraining,
  });
};

export const handleManuallyOptionsTrainingSubmit = async ({
  isAuthenticated,
  object,
  manuallySelectedEmployees,
  uploadedCsvUsers,
  alertEmployees,
  copyUsersFromTraining,
  trainingTitle,
}) => {
  const res = await sumbitManuallySelectedEmployees({
    isAuthenticated,
    object,
    manuallySelectedEmployees,
    uploadedCsvUsers,
    alertEmployees,
    copyUsersFromTraining,
  });
  toast.success(res.results);

  logEmployeesAssignedTraining({
    isAuthenticated,
    training: object,
    isAllUsersChecked: false,
    channels: [],
    channelsSync: [],
    manuallySelecetedUsers: manuallySelectedEmployees.length,
    csvUsers: uploadedCsvUsers.length,
    copyFromTraining: trainingTitle,
  });
};

export const handleGroupOptionsTrainingSubmit = async ({
  isAuthenticated,
  object,
  selectedGroupsForTraining,
  activeGroup,
  syncGroups,
  alertEmployees,
}) => {
  if (activeGroup || syncGroups) {
    const updateData = {};
    if (activeGroup) updateData.group_id = activeGroup.id;
    if (syncGroups) updateData.groups_sync = syncGroups.map((i) => i.value);
    await api.updateTraining({
      auth: isAuthenticated,
      trainingId: object.id,
      updateData,
    });
  }

  const res = await api.openBulkEmployeesTrainingGroups({
    auth: isAuthenticated,
    curriculumTrainingId: object.curriculum_detail.id,
    groups: selectedGroupsForTraining,
    alertEmployees,
  });
  toast.success(res.results);
};

export const handleGoogleOptionsTrainingSubmit = async ({
  isAuthenticated,
  object,
  googleAutoEnroll,
  googleAddAllUsers,
  alertEmployees,
  selectedGoogleGroups,
  setSelectedGoogleGroups,
  googleGroupsToSync,
}) => {
  const updateData = {
    google_groups: googleGroupsToSync.map((c) => c.value),
  };

  await api.updateTraining({
    auth: isAuthenticated,
    trainingId: object.id,
    updateData,
  });

  await assignEmployeesTrainingWithGoogleOption({
    auth: isAuthenticated,
    curriculumTrainingId: object.curriculum_detail.id,
    googleAutoEnroll,
    googleAddAllUsers,
    alertEmployees,
    selectedGoogleGroups: selectedGoogleGroups.map((group) => group.value),
  });
  setSelectedGoogleGroups([]);
  toast.success(
    "We will start importing users from your Google Workspace. Depending on the number of users in your workspace or selected groups it can take a while. We will send you a message in Slack once we're done."
  );
};

export const handleOktaOptionsTrainingSubmit = async ({
  isAuthenticated,
  object,
  oktaGroupsSync,
  alertEmployees,
  selectedOktaGroups,
}) => {
  const updateData = {
    okta_groups: oktaGroupsSync.map((c) => c.value),
  };

  await api.updateTraining({
    auth: isAuthenticated,
    trainingId: object.id,
    updateData,
  });

  await api.openBulkEmployeesTrainingOkta({
    companyID: isAuthenticated.company,
    curriculumTrainingId: object.curriculum_detail.id,
    groups: selectedOktaGroups,
    alertEmployees,
  });
  toast.success(
    "We will start importing users from your Okta Groups. Depending on the number of users in selected groups it can take a while. We will send you a message in Slack once we're done."
  );

  logEmployeesAssignedTraining({
    isAuthenticated,
    training: object,
    selectedOktaGroups,
    oktaGroupsSync,
  });
};

export const handleDoceboOptionsTrainingSubmit = async ({
  isAuthenticated,
  object,
  addNewDoceboUsers,
  allDoceboUsers,
  setAllDoceboUsers,
  activeDoceboIntegration,
}) => {
  await updateExternalAppSettings({
    companyID: isAuthenticated.company,
    integrationID: activeDoceboIntegration.id,
    settings: {
      webhook_enabled: addNewDoceboUsers,
      resourcetype: 'DoceboInstallation',
      company: isAuthenticated.company,
    },
  });

  if (allDoceboUsers) {
    await assignEmployeesTrainingWithDoceboOption({
      auth: isAuthenticated,
      curriculumTrainingId: object.curriculum_detail.id,
    });
    setAllDoceboUsers(false);
    toast.success('We will start importing users from your Docebo course.');
  }

  // logEmployeesAssignedTraining({
  //   isAuthenticated,
  //   training: object,
  //   oktaUsers,
  //   oktaGroupsSync,
  // });
};

export const handleSeismicOptionsTrainingSubmit = async ({
  isAuthenticated,
  object,
  activeSeismicIntegration,
  addNewSeismicUsers,
  allSeismicUsers,
  setAllSeismicUsers,
}) => {
  await updateExternalAppSettings({
    companyID: isAuthenticated.company,
    integrationID: activeSeismicIntegration.id,
    settings: {
      webhook_enabled: addNewSeismicUsers,
      resourcetype: 'SeismicInstallation',
      company: isAuthenticated.company,
    },
  });

  if (allSeismicUsers) {
    await assignEmployeesTrainingWithSeismicOption({
      auth: isAuthenticated,
      curriculumTrainingId: object.curriculum_detail.id,
    });
    setAllSeismicUsers(false);
    toast.success(
      'We will start importing users from the associated Seismic content.'
    );
  }

  // logEmployeesAssignedTraining({
  //   isAuthenticated,
  //   training: object,
  //   oktaUsers,
  //   oktaGroupsSync,
  // });
};
