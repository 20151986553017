import { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";

const NextLessonBranch = ({ currAnswer, onEdit }) => {
  const { trainingLessons } = useSelector((state) => state.training);
  const [renderSelect, setRenderSelect] = useState(false);
  const deselectOption = { label: " -- Select Lesson -- ", value: null };
  const [selectedLesson, setSelectedLesson] = useState(deselectOption);

  useEffect(() => {
    const selectedLesson = trainingLessons.find(
      (l) => l.id === currAnswer.next_lesson
    );
    if (selectedLesson) {
      setSelectedLesson({
        label: selectedLesson.title,
        value: selectedLesson.id,
      });
    }
    setRenderSelect(true);
  }, [currAnswer.next_lesson, trainingLessons]);
  return (
    <div className="flex flex-col mt-0.5 pl-3 answer__next-lesson-branch relative">
      <div className="flex">
        <div className="flex flex-col justify-end w-full">
          <div className="">
            <p className="mb-0.2 text-xs text-hka_gray">
              This answer leads to the following lesson:
            </p>
            {renderSelect && (
              <Select
                defaultValue={selectedLesson ? selectedLesson : deselectOption}
                onChange={(e) => {
                  onEdit(e);
                  if (!e.value) setSelectedLesson(deselectOption);
                }}
                placeholder="Select lesson"
                options={[
                  deselectOption,
                  ...trainingLessons?.map((l) => {
                    return { label: l.title, value: l.id };
                  }),
                ]}
                // styles={customStyles}
                unstyled
                classNamePrefix="select"
              />
            )}
          </div>
        </div>
      </div>
      <div>
        {!selectedLesson?.value && (
          <p className="text-xs text-hka_red leading-1.2 mt-0.5">
            The next lesson is not set yet. If an employee chooses this answer
            as is, the training will be considered complete.
          </p>
        )}
      </div>
    </div>
  );
};

export default NextLessonBranch;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "black" : "black",
    backgroundColor: state.isSelected ? "#f2f2f3" : provided.backgroundColor,
    padding: 10,
  }),
  control: (base, state) => {
    return {
      ...base,
      height: "40px",
      border: state.is
        ? "red"
        : "1px solid rgba(218, 220, 236, var(--tw-border-opacity))",
      "&:hover": {},
      boxShadow: "none",
    };
  },
  input: (provided) => {
    return {
      ...provided,
      marginTop: "-12px",
    };
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      marginTop: "-2px",
    };
  },
  indicatorSeparator: (provided) => {
    return {
      ...provided,
      marginTop: "7px",
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      color: " rgba(135, 138, 154, var(--tw-text-opacity));",
      fontSize: "12px",
      letterSpacing: "0.025em",
    };
  },
};
