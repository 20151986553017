export const streamsSlice = (set) => ({
  previewPostModal: { show: false },
  setPreviewPostModal: (value) =>
    set((state) => ({
      streams: { ...state.streams, previewPostModal: value },
    })),
  streamModal: { show: false },
  setStreamModal: (value) =>
    set((state) => ({
      streams: { ...state.streams, streamModal: value },
    })),
  deleteStreamModal: { show: false },
  setDeleteStreamModal: (value) =>
    set((state) => ({
      streams: { ...state.streams, deleteStreamModal: value },
    })),
  subscribeToStreamModal: { show: false },
  setSubscribeToStreamModal: (value) =>
    set((state) => ({
      streams: { ...state.streams, subscribeToStreamModal: value },
    })),
  postModal: { show: false },
  setPostModal: (value) =>
    set((state) => ({
      streams: { ...state.streams, postModal: value },
    })),
  deletePostModal: { show: false },
  setDeletePostModal: (value) =>
    set((state) => ({
      streams: { ...state.streams, deletePostModal: value },
    })),
  customPostConfirmModal: { show: false },
  setCustomPostConfirmModal: (value) =>
    set((state) => ({
      streams: { ...state.streams, customPostConfirmModal: value },
    })),
  deleteCustomPostConfirmModal: { show: false },
  setDeleteCustomPostConfirmModal: (value) =>
    set((state) => ({
      streams: { ...state.streams, deleteCustomPostConfirmModal: value },
    })),
  postNotFoundModal: { show: false },
  setPostNotFoundModal: (value) =>
    set((state) => ({
      streams: { ...state.streams, postNotFoundModal: value },
    })),
  manageOwnersModal: { show: false },
  setManageOwnersModal: (value) =>
    set((state) => ({
      streams: { ...state.streams, manageOwnersModal: value },
    })),
});
