import { useRef, useState } from "react";
import useComponentVisible from "../../utils/custom-hooks/useComponentVisible";
import Tag from "../Tag";

const TrainingTagInput = ({
  tags,
  setTags,
  suggestions,
  updateSuggestions,
}) => {
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const [indexTracker, setIndexTracker] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const activeItemRef = useRef(null);
  const inputRef = useRef(null);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const onChange = (e) => {
    const userInput = e.currentTarget.value;
    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    setActiveSuggestion(-1);
    setFilteredSuggestions(filteredSuggestions);
    setIsComponentVisible(true);
  };

  const onClick = (e) => {
    setActiveSuggestion(-1);
    setFilteredSuggestions([]);
    setIsComponentVisible(false);
    setTags([...tags, { id: null, name: e.currentTarget.innerText.trim() }]);
    inputRef.current.value = "";
  };

  const executeScroll = () => activeItemRef.current.scrollIntoView();

  const onKeyUp = (e) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestion(-1);
      setIsComponentVisible(false);
      if (!filteredSuggestions[activeSuggestion]) {
        const isInTagsAlready = !!tags.find(
          (t) => t.name.toLowerCase() === inputRef.current.value.trim()
        );
        if (!isInTagsAlready && inputRef.current.value.trim().length > 0) {
          setTags([
            ...tags,
            {
              id: null,
              name: inputRef.current.value.trim(),
            },
          ]);
        }
        inputRef.current.value = "";
        return;
      }

      setTags([
        ...tags,
        {
          id: null,
          name:
            filteredSuggestions.length > 0
              ? filteredSuggestions[activeSuggestion].name
              : inputRef.current.value.trim(),
        },
      ]);

      inputRef.current.value = "";
    }
    // User pressed the up arrow, increment the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
      setIndexTracker(activeSuggestion - 1);
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion + 1 === filteredSuggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
      setIndexTracker(activeSuggestion + 1);
    }
    if (activeItemRef.current) executeScroll();
  };

  let suggestionsListComponent;
  if (isComponentVisible && inputRef.current.value !== "") {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className="w-full overflow-auto bg-white border rounded-lg max-h-12.3 shadow-smooth mt-0.5">
          {filteredSuggestions.map((suggestion, index) => {
            let className;
            // Flag the active suggestion with a class
            if (index === activeSuggestion) {
              className = "bg-gray-100 cursor-pointer text-hka_gray";
            }
            return (
              <li
                ref={index === indexTracker - 1 ? activeItemRef : null}
                className={
                  className +
                  "hover:cursor-pointer text-base px-1 text-hka_gray"
                }
                key={index}
                onClick={onClick}
              >
                {suggestion.name}
              </li>
            );
          })}
        </ul>
      );
    }
  }

  const removeTag = (index) => {
    const tagToRemove = tags.find((_, i) => i === index);
    updateSuggestions(tagToRemove);
    setTags(tags.filter((_, i) => i !== index));
  };

  return (
    <div className="flex flex-wrap items-start m-h-4">
      <section className="relative w-full mb-1">
        <input
          className="w-full h-4 pl-1 text-base border rounded-lg max-w-5 border-hka_gray-border focus:outline-none"
          placeholder={"Press enter to add tags"}
          onChange={onChange}
          onKeyUp={onKeyUp}
          ref={inputRef}
          maxLength={60}
        />
        {isComponentVisible && (
          <div ref={ref} className="absolute z-10 w-full top-full">
            {" "}
            {suggestionsListComponent}
          </div>
        )}
      </section>
      <ul className="flex flex-wrap items-center h-full">
        {tags.map((tag, index) => (
          <Tag tag={tag.name} key={index} onRemove={() => removeTag(index)} />
        ))}
      </ul>
    </div>
  );
};

export default TrainingTagInput;
