import React from "react";
import Select from "react-select";
import { useAppContext } from "../../libs/contextLib";

export default function GroupsMenu({ groups, onChange, value }) {
  const { isAuthenticated } = useAppContext();

  const userId = isAuthenticated.id;
  const isAdmin = isAuthenticated.is_admin;

  const formatOptionLabel = (group) => (
    <div className="flex flex-row items-center">
      {group.icon && (
        <span
          className={
            group.icon +
            ` w-2.4 h-2.4 flex items-center justify-center mr-0.5 bg-hka_gray-light icon-wrapper rounded text-hka_gray`
          }
        ></span>
      )}
      <span className="self-center break-words text-hka_gray">
        {group.name}
      </span>
    </div>
  );

  const formatGroupLabel = (data) => (
    <div className="flex items-center justify-between h-2 px-1">
      <p className="text-sm">{data.label}</p>
      <p className="text-sm">{data.options.length}</p>
    </div>
  );

  const getOptions = () => {
    const options = [];

    const userGroups = groups.filter(
      (group) => group && group.manager?.id === userId
    );
    if (userGroups.length > 0) {
      options.push({ label: "Your Groups", options: userGroups });
    }

    if (isAdmin) {
      const groupsNotOwnedByCurrentUser = groups.filter(
        (group) => group && group.manager?.id !== userId
      );
      if (groupsNotOwnedByCurrentUser.length > 0) {
        options.push({
          label: "All Groups",
          options: groupsNotOwnedByCurrentUser,
        });
      }
    }

    return options;
  };

  return (
    <Select
      isClearable
      value={value}
      onChange={onChange}
      placeholder="All Groups"
      options={getOptions()}
      className="flex-1 w-full h-4 text-hka_gray min-w-25r select--group"
      maxMenuHeight="20.8rem"
      classNamePrefix="select"
      formatOptionLabel={formatOptionLabel}
      formatGroupLabel={formatGroupLabel}
      isSearchable={false}
      unstyled
    />
  );
}
