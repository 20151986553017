import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { useAppContext } from '../../libs/contextLib';

const AssessmentQuestionSelector = ({
  trainingItem,
  assessmentQuestionData,
  onQuestionSelect,
}) => (
  <span
    className='flex gap-1 text-base tracking-wide truncate font-basis-bold cursor-default'
    onClick={(e) => e.stopPropagation()}
  >
    {trainingItem.questions.map((question, index) => (
      <div
        key={question}
        className={`w-2 h-2 rounded-md flex justify-center items-center mt-0.5 cursor-pointer ${
          assessmentQuestionData?.employee_question.assessment_question?.id ===
          question
            ? 'bg-orange-200'
            : 'bg-hka_gray-light'
        }`}
        onClick={(e) => onQuestionSelect(e, trainingItem, question)}
      >
        <p
          className={`${
            assessmentQuestionData?.employee_question.assessment_question.id ===
            question
              ? 'text-orange'
              : ''
          }`}
        >
          {index + 1}
        </p>
      </div>
    ))}
  </span>
);

const LessonNav = ({
  employeeLesson,
  setEmployeeLesson,
  employeeTraining,
  setLesson,
  lessons,
  showResultsPage,
  setShowResultsPage,
  getEmployeeLesson,
  trainingComplete,
  lessonSelect,
  branchingScenario,
  trainingItems,
  activeTrainingItem,
  setActiveTrainingItem,
  assessmentQuestionData,
  setShowAssessmentQuestion,
  setShowAssessmentResults,
}) => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAppContext();
  const queryClient = useQueryClient();

  const trainingLessons = trainingItems.filter(
    (item) => item.type === 'lesson'
  );
  const trainingAssessments = trainingItems.filter(
    (item) => item.type === 'assessment'
  );

  const getItemPosition = (trainingItem) => {
    if (pathname.includes('catalog')) {
      const itemIndex = trainingLessons.findIndex(
        (i) => i.item.id === trainingItem.item.id
      );
      return `Lesson ${itemIndex + 1}`;
    }
    if (trainingItem.type === 'lesson') {
      const itemIndex = trainingLessons.findIndex(
        (i) => i.curriculum_item_id === trainingItem.curriculum_item_id
      );
      return `Lesson ${itemIndex + 1}`;
    }
    if (trainingItem.type === 'assessment') {
      const itemIndex = trainingAssessments.findIndex(
        (i) => i.curriculum_item_id === trainingItem.curriculum_item_id
      );
      return `Quiz ${itemIndex + 1}`;
    }
  };

  const getItemTitle = ({ trainingItem }) => {
    if (trainingItem.type === 'lesson') {
      return trainingItem.title.includes('|')
        ? trainingItem.title.split('|')[1]
        : trainingItem.title;
    } else if (trainingItem.type === 'assessment') {
      return `${trainingItem.title} (${trainingItem.questions.length} Questions)`;
    }
  };

  const includesVideo = (item) => {
    const lessonItem = lessons.find((l) => l.id === item.curriculum_item_id);
    return !!lessonItem?.video_url;
  };

  const onQuestionSelectHandler = (e, trainingItem, question) => {
    e.stopPropagation();
    setShowResultsPage(false);
    setShowAssessmentQuestion({
      assessmentID: trainingItem.curriculum_item_id,
      questionID: question,
    });
    setActiveTrainingItem(trainingItem);
    setShowAssessmentResults(false);
    queryClient.invalidateQueries([
      'employee-trainings-assessments',
      isAuthenticated.id,
      trainingItem.curriculum_item_id,
      question,
    ]);
    queryClient.invalidateQueries([
      'employee-trainings-assessments',
      isAuthenticated.id,
      trainingItem.curriculum_item_id,
      'next_question',
    ]);
  };

  const selectItemHandler = async ({ trainingItem, index }) => {
    if (pathname.includes('catalog')) {
      setLesson(lessons[index]);
      setEmployeeLesson({
        id: lessons[index].id,
        finished: lessons[index].finished,
        lesson: lessons[index],
      });
      setShowResultsPage(false);
    } else {
      setActiveTrainingItem(trainingItem);
      if (trainingItem.type === 'lesson') {
        const employeeLessonTemp = await getEmployeeLesson(
          trainingItem.curriculum_item_id
        );
        if (
          branchingScenario &&
          !employeeLessonTemp.finished &&
          employeeTraining &&
          employeeLessonTemp.lesson.id.localeCompare(
            employeeTraining.active_lesson?.id
          ) !== 0
        ) {
          return;
        }
        setLesson(employeeLessonTemp.lesson);
        setEmployeeLesson(employeeLessonTemp);
        setShowResultsPage(false);
      } else if (trainingItem.type === 'assessment') {
        setShowResultsPage(false);
        if (trainingItem.finished) {
          setShowAssessmentResults({
            assessmentID: trainingItem.curriculum_item_id,
          });
        } else {
          setShowAssessmentResults(false);
          setShowAssessmentQuestion({
            assessmentID: trainingItem.curriculum_item_id,
            questionID: 'next_question',
          });
        }
      }
    }
  };

  return (
    <>
      <Select
        defaultValue={
          employeeLesson?.lesson ? employeeLesson.lesson.id : 'results_page'
        }
        onChange={(lesson) => {
          document.getElementById(lesson.value).click();
        }}
        value={
          employeeLesson?.lesson ? employeeLesson.lesson.id : 'results_page'
        }
        placeholder={
          employeeLesson?.lesson
            ? employeeLesson.lesson.title
            : showResultsPage
            ? 'Results'
            : 'Select training'
        }
        options={lessonSelect ? lessonSelect : null}
        classNamePrefix='select'
        className='relative z-10 mx-2 mb-1 select--lesson-nav sm:mx-3 lg:hidden outline:none'
        unstyled
      />
      <nav className='order-1 hidden h-full col-span-3 mr-3 space-y-1 lg:block lesson-nav'>
        <div>
          {trainingItems?.map((trainingItem, index) => (
            <span className='flex mb-1.5' key={trainingItem.employee_item_id}>
              <span
                id={trainingItem.employee_item_id}
                className={`cursor-pointer flex ${
                  trainingItem.curriculum_item_id ===
                    activeTrainingItem?.curriculum_item_id && !showResultsPage
                    ? 'active text-orange'
                    : branchingScenario &&
                      !trainingItem.finished &&
                      employeeTraining &&
                      trainingItem.curriculum_item_id.localeCompare(
                        employeeTraining.active_lesson?.id
                      ) !== 0
                    ? 'lesson--skipped'
                    : ''
                }`}
                onClick={() => selectItemHandler({ trainingItem, index })}
              >
                <div className='min-w-3'>
                  {trainingItem.finished ? (
                    <span className='icon-check text-hka_green'></span>
                  ) : (
                    <span className='block w-4 -my-3 h-7'></span>
                  )}
                </div>
                <div className='flex flex-col w-full ml-2 truncate lg:ml-1 2xl:ml-2'>
                  <span className='text-base'>
                    {getItemPosition(trainingItem)}
                  </span>
                  <span className='block text-base tracking-wide truncate font-basis-bold'>
                    {getItemTitle({ trainingItem })}
                  </span>
                  {trainingItem.type === 'assessment' && (
                    <AssessmentQuestionSelector
                      trainingItem={trainingItem}
                      assessmentQuestionData={assessmentQuestionData}
                      onQuestionSelect={onQuestionSelectHandler}
                    />
                  )}
                </div>
              </span>
              {includesVideo(trainingItem) && (
                <span
                  className={`ml-auto icon-video mt-0.4 ${
                    trainingItem.curriculum_item_id ===
                      employeeLesson?.lesson?.id && !showResultsPage
                      ? 'active'
                      : ''
                  }`}
                ></span>
              )}
            </span>
          ))}
          {trainingComplete && (
            <span
              id='results_page'
              key='results_page'
              className={`cursor-pointer ${
                showResultsPage ? 'active text-orange w-full' : ''
              }`}
              onClick={() => setShowResultsPage(true)}
            >
              <span className='icon-arrow right'></span>
              <span className='ml-0.2 2xl:ml-1.3 tracking-wide font-basis-bold'>
                Training Results
              </span>
            </span>
          )}
        </div>
      </nav>
    </>
  );
};

export default LessonNav;
