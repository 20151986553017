import React from "react";
import ActionButton from "../../ActionButton";

export default function TrainingMetrics({
  activeCompanyTrainings,
  finishedTraining30,
  finishedTraining60,
  finishedTrainingTotal,
  finishedEmployeeLessons30,
  finishedEmployeeLessons60,
  finishedEmployeeLessonsTotal,
}) {
  return (
    <section className="mb-2 space-y-1">
      <header className="flex flex-col lg:items-center lg:justify-between lg:flex-row">
        <h4 className="flex items-center mb-2 lg:mb-0">Training</h4>
        <ActionButton
          className="w-full button--blue sm:w-auto button"
          to={"/trainings"}
        >
          {`View Trainings (${activeCompanyTrainings})`}{" "}
          <span className="icon-arrow right text-hka_blue -mr-0.5 ml-1"></span>
        </ActionButton>
      </header>
      <div className="flex flex-col space-y-2 lg:flex-row lg:space-y-0">
        <div className="flex-1 lg:pr-2 lg:max-w-50">
          <div className="flex p-2 bg-white lg:p-4 rounded-xl shadow-light">
            <div className="flex-1 pr-4 truncate">
              <p className="mb-1 text-sm truncate">Completed (30 days)</p>
              <div className="flex items-center">
                <p className="mr-1 text-2xl">
                  {finishedTraining30 ? finishedTraining30 : "0"}
                </p>
                <div>
                  {finishedTrainingTotal !== 0 && finishedTraining30 !== 0 ? (
                    finishedTraining30 - finishedTraining60 > 0 ? (
                      <p className="px-1 py-0.2 text-xs rounded-full font-basis-bold text-hka_green bg-hka_green-light">
                        {"+ " +
                          (
                            ((finishedTraining30 - finishedTraining60) /
                              finishedTraining30) *
                            100
                          ).toFixed(0) +
                          "%"}
                      </p>
                    ) : (
                      <p className="px-1 py-0.2 text-xs rounded-full font-basis-bold text-hka_red bg-hka_red-light">
                        {"- " +
                          (
                            ((finishedTraining30 - finishedTraining60) /
                              finishedTraining30) *
                            -100
                          ).toFixed(0) +
                          "%"}
                      </p>
                    )
                  ) : (
                    <p className="text-sm font-basis-bold text-hka_green">
                      {"0 %"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <span className="min-h-full vr w-0.1 bg-hka_gray-light flex"></span>
            <div className="flex-1 pl-4 truncate">
              <p className="mb-1 text-sm truncate">Completed (all time)</p>
              <p className="text-2xl">
                {finishedTrainingTotal ? finishedTrainingTotal : "0"}
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1 lg:pl-2 lg:max-w-50">
          <div className="flex p-2 bg-white lg:p-4 rounded-xl shadow-light">
            <div className="flex-1 pr-4 truncate">
              <p className="mb-1 text-sm truncate">
                Lessons Completed (30 days)
              </p>
              <div className="flex items-center">
                <p className="mr-1 text-2xl">
                  {finishedEmployeeLessons30 ? finishedEmployeeLessons30 : "0"}
                </p>
                <div>
                  {activeCompanyTrainings.results &&
                  activeCompanyTrainings.results[0] &&
                  finishedEmployeeLessonsTotal !== 0 &&
                  finishedEmployeeLessons30 !== 0 ? (
                    finishedEmployeeLessons30 - finishedEmployeeLessons60 >
                    0 ? (
                      <p className="px-1 py-0.2 text-xs rounded-full font-basis-bold text-hka_green bg-hka_green-light">
                        {"+ " +
                          (
                            ((finishedEmployeeLessons30 -
                              finishedEmployeeLessons60) /
                              finishedEmployeeLessons30) *
                            100
                          ).toFixed(0) +
                          "%"}
                      </p>
                    ) : (
                      <p className="px-1 py-0.2 text-xs rounded-full font-basis-bold text-hka_red bg-hka_red-light">
                        {"- " +
                          (
                            ((finishedEmployeeLessons30 -
                              finishedEmployeeLessons60) /
                              finishedEmployeeLessons30) *
                            -100
                          ).toFixed(0) +
                          "%"}
                      </p>
                    )
                  ) : (
                    <p className="text-sm font-basis-bold text-hka_green">
                      {"0 %"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <span className="min-h-full vr w-0.1 bg-hka_gray-light flex"></span>
            <div className="flex-1 pl-4 truncate">
              <p className="mb-1 text-sm truncate">
                Lessons Completed (all time)
              </p>
              <p className="text-2xl">
                {finishedEmployeeLessonsTotal
                  ? finishedEmployeeLessonsTotal
                  : "0"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
