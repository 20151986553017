import { useQuery } from "@tanstack/react-query";
import { getEmployeeDetails } from "../../../api/employeesApi";

export const useEmployeeDetailsData = ({ employeeID, staleTime = 0 }) => {
  return useQuery({
    queryKey: ["employee-details", employeeID],
    queryFn: () =>
      getEmployeeDetails({
        employeeID,
      }),
    staleTime,
  });
};

export const prefetchEmployeeDetailsData = ({
  queryClient,
  employeeID,
  staleTime = 0,
}) => {
  queryClient.prefetchQuery({
    queryKey: ["employee-details", employeeID],
    queryFn: () => getEmployeeDetails({ employeeID }),
    staleTime,
  });
};
