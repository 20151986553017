import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useEmployeeAssessmentAttemptsData,
  useEmployeeTrainingAttemptsData,
} from '../../../../hooks/data-hooks/trainings';
import { formatAvatar } from '../../../../utils/formatAvatar';
import Modal from '../../../Modal/Modal';
import EmployeeLessonAttemptDetails from './EmployeeLessonAttemptDetails';
import ListEmployeeCurriculumItemAttempts from './ListEmployeeCurriculumItemAttempts';

const ViewEmployeeResultsModal = ({
  showModal,
  employee,
  lessons,
  curriculumContent,
  onClose,
}) => {
  const { id } = useParams();
  const [trainingAttempts, setTrainingAttempts] = useState([]);
  const [selectedTrainingAttempt, setSelectedTrainingAttempt] =
    useState(undefined);
  const [selectedItemAttempt, setSelecteItemAttempt] = useState(undefined);

  const isTheFirstTrainingAttemptSelected =
    trainingAttempts.length > 0 &&
    selectedTrainingAttempt?.value ===
      trainingAttempts[trainingAttempts.length - 1]?.value;

  const { data: attemptsData, isLoading } = useEmployeeTrainingAttemptsData({
    trainingID: id,
    employeeID: employee.employee,
  });

  const assessmentsAttemptsData = useEmployeeAssessmentAttemptsData({
    assessments: curriculumContent.filter((item) => item.type === 'Quiz'),
    employeeID: employee.employee,
  });

  useEffect(() => {
    if (attemptsData?.results.length > 0) {
      const mappedAttempts = attemptsData.results.map((attempt, index) => ({
        label: `Attempt ${attemptsData.results.length - index} ${
          !attempt.finish_date ? '(In Progress)' : ''
        }`,
        value: attempt.id,
        data: attempt,
      }));
      setTrainingAttempts(mappedAttempts);
      setSelectedTrainingAttempt(mappedAttempts[0]);
    }
  }, [attemptsData]);

  const customHeader = (
    <div className='flex items-center w-full h-full'>
      {employee.avatar_image ? (
        <img
          className='mr-1 user-img h-3.2 w-3.2'
          src={employee.avatar_image}
          alt={employee.employee_name}
        />
      ) : (
        formatAvatar({
          name: employee.employee_name,
          width: 3.2,
          marginRight: 1,
        })
      )}
      <h2 className='modal__heading'>
        {employee.employee_name} (training results)
      </h2>
    </div>
  );
  return (
    <Modal
      show={showModal}
      onSubmit={() => {}}
      onClose={onClose}
      title={`${employee.employee_name} (training results)`}
      submitButtonText='Mark as Complete'
      customHeader={customHeader}
      noFooter
      expandable
      modalClass='modal--training-results'
    >
      <section className='modal__body'>
        {!selectedItemAttempt && (
          <ListEmployeeCurriculumItemAttempts
            curriculumContent={curriculumContent}
            attempts={trainingAttempts}
            assessmentsAttemptsData={assessmentsAttemptsData}
            isLoadingData={isLoading}
            selectedTrainingAttempt={selectedTrainingAttempt}
            setSelectedTrainingAttempt={setSelectedTrainingAttempt}
            setSelecteItemAttempt={setSelecteItemAttempt}
            isTheFirstTrainingAttemptSelected={
              isTheFirstTrainingAttemptSelected
            }
          />
        )}

        {selectedItemAttempt && (
          <EmployeeLessonAttemptDetails
            selectedItemType={selectedItemAttempt.itemType}
            selectedAssessmentID={selectedItemAttempt.assessmentID}
            selectedItemAttempt={selectedItemAttempt.item}
            setSelecteItemAttempt={setSelecteItemAttempt}
            selectedTrainingAttempt={selectedTrainingAttempt}
            lessons={lessons}
            assessmentsAttemptsData={assessmentsAttemptsData}
            questions={curriculumContent
              .filter((i) => i.type === 'Quiz')
              .reduce(
                (accumulator, currentValue) => [
                  ...accumulator,
                  currentValue.value.assessment.questions,
                ],
                []
              )}
          />
        )}
      </section>
    </Modal>
  );
};

export default ViewEmployeeResultsModal;
