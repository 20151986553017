import { useState, useEffect } from 'react';
import { getEditorState } from '../../utils/wysiwygEditorHelpers';
import WysiwygRichTextEditor from '../WysiwygEditor/WysiwygRichTextEditor';
import { useAppContext } from '../../libs/contextLib';
import {
  useAssessmentQuestionAttemptsData,
  useCompleteTrainingFromAssessment,
  useRetakeTrainingHandler,
  useSubmitTrainingAssessmentQuestion,
} from '../../hooks/data-hooks/employeeTrainings';
import { useQueryClient } from '@tanstack/react-query';
import AssessmentResults from './AssessmentResults';
import LoaderInline from '../LoaderInline';
import * as api from '../../api';

const AssessmentBody = ({
  activeTrainingItem,
  assessmentQuestionData,
  trainingItems,
  isLoadingAssessmentQuestion,
  setShowAssessmentQuestion,
  showAssessmentResults,
  setShowAssessmentResults,
  setActiveTrainingItem,
  trainingInstance,
  setEmployeeTraining,
  setShowResultsPage,
  checkLesson,
  setShowRetakeTrainingCallout,
  setTrainingComplete,
}) => {
  const { isAuthenticated } = useAppContext();
  const queryClient = useQueryClient();

  const assessmentQuestion =
    assessmentQuestionData?.employee_question.assessment_question;

  const [editorState, setEditorState] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [showAssessmentReview, setShowAssessmentReview] = useState(false);
  const [isAttemptComplete, setIsAttemptComplete] = useState(false);

  const trainingAssessments = trainingItems.filter(
    (item) => item.type === 'assessment'
  );
  useEffect(() => {
    setShowAssessmentReview(
      !!assessmentQuestionData?.employee_question.finish_date
    );
  }, [assessmentQuestionData?.employee_question.finish_date]);

  useEffect(() => {
    const getState = async (editor_content, editor_state, markup) => {
      const res = await getEditorState(
        editor_state,
        editor_content,
        markup,
        false
      );
      setEditorState(res);
    };

    if (assessmentQuestion)
      getState(
        assessmentQuestion.editor_content,
        assessmentQuestion.editor_content?.quiz_editor_state,
        assessmentQuestion.slack_markup
      );
  }, [assessmentQuestion]);

  const cb = (res) => {
    setShowAssessmentReview(true);
    setIsAttemptComplete(res.is_assessment_attempt_complete);
  };

  const { mutate: sumbitQuestion } = useSubmitTrainingAssessmentQuestion({
    cb,
  });

  const { mutate: retakeTraining, isLoading } = useRetakeTrainingHandler({
    cb: () => {
      queryClient.invalidateQueries([
        'employee-trainings-assessments',
        isAuthenticated.id,
      ]);
      setActiveTrainingItem((thisTrainingItem) =>
        trainingItems.find(
          (item) =>
            item.curriculum_item_id === thisTrainingItem.curriculum_item_id
        )
      );
      setShowAssessmentQuestion({
        assessmentID: assessmentQuestionData.assessment_id,
        questionID: activeTrainingItem.questions[0],
      });
    },
  });

  const completeTrainingPostAction = async (completeTraningData) => {
    if (completeTraningData.did_user_pass) {
      setShowResultsPage(true);
    } else {
      setShowRetakeTrainingCallout(true);
      const employeeTraining = await api.getEmployeeWebTraining({
        auth: isAuthenticated,
        trainingId: trainingInstance.id,
      });
      const nextTrainingItem = employeeTraining.training_items.find(
        (item) => !item.finished
      );
      setActiveTrainingItem(nextTrainingItem);
    }

    if (completeTraningData.is_training_complete) {
      setTrainingComplete(true);
    }
  };

  const { mutate: completeTraining } = useCompleteTrainingFromAssessment({
    cb: completeTrainingPostAction,
  });

  const { data: questionAttempts } = useAssessmentQuestionAttemptsData({
    employeeID: isAuthenticated.id,
    assessmentID: assessmentQuestionData?.assessment_id,
    questionID: assessmentQuestion?.id,
    enabled:
      !!assessmentQuestion &&
      !!assessmentQuestionData?.employee_question.finish_date,
  });

  const itemPosition = () => {
    const itemIndex = trainingAssessments.findIndex(
      (i) => i.curriculum_item_id === activeTrainingItem.curriculum_item_id
    );
    return `Quiz ${itemIndex + 1}`;
  };

  const sumbitAnswersHandler = () => {
    sumbitQuestion({
      employeeID: isAuthenticated.id,
      assessmentID: assessmentQuestionData.assessment_id,
      questionID: assessmentQuestion.id,
      selectedAnswers,
    });
  };

  const reloadEmployeeTraining = async () => {
    const employeeTraining = await api.getEmployeeWebTraining({
      auth: isAuthenticated,
      trainingId: trainingInstance.id,
    });
    setEmployeeTraining(employeeTraining);
  };

  const completeQuestionHandler = () => {
    setShowAssessmentReview(false);
    setSelectedAnswers([]);
    if (isAttemptComplete) {
      setShowAssessmentResults({
        assessmentID: assessmentQuestionData.assessment_id,
      });
      reloadEmployeeTraining(); // this is to rehidrate training items and update navigation items
    } else {
      let questionID =
        assessmentQuestionData.employee_question.assessment_question.id;

      let nextAssessmentQuestion =
        activeTrainingItem.questions[
          activeTrainingItem.questions.findIndex((i) => i === questionID) + 1
        ];

      queryClient.invalidateQueries(['employee-trainings-assessments']);
      if (nextAssessmentQuestion) {
        setShowAssessmentQuestion({
          assessmentID: assessmentQuestionData.assessment_id,
          questionID: nextAssessmentQuestion,
        });
      } else {
        setShowAssessmentResults({
          assessmentID: activeTrainingItem.curriculum_item_id,
        });
      }
    }
  };

  const retakeTrainingHandler = async () => {
    queryClient.invalidateQueries(['employee-trainings-assessment-results']);
    queryClient.invalidateQueries(['employee-trainings-assessments']);
    retakeTraining({
      employeeID: isAuthenticated.id,
      assessmentID: activeTrainingItem.curriculum_item_id,
      trainingID: trainingInstance.id,
    });
    setShowAssessmentResults(false);
  };

  const onFinishTrainingHandler = async () => {
    if (activeTrainingItem.finished) {
      setShowResultsPage(true);
      return;
    }
    completeTraining({
      employeeID: isAuthenticated.id,
      assessmentID: activeTrainingItem.curriculum_item_id,
      trainingID: trainingInstance.id,
    });
  };

  const selectAnswerHandler = ({ answer }) => {
    setSelectedAnswers((previousAnswers) => {
      if (previousAnswers.includes(answer.id)) {
        return previousAnswers.filter((a) => a !== answer.id);
      } else {
        return [...previousAnswers, answer.id];
      }
    });
  };

  if ((isLoadingAssessmentQuestion && !showAssessmentResults) || isLoading)
    return (
      <LoaderInline wrapperClasses='relative flex-grow order-2 col-span-6 mx-2 sm:mx-3 lg:order-1 lg:col-span-10' />
    );

  if (showAssessmentResults) {
    return (
      <AssessmentResults
        assessmentID={showAssessmentResults.assessmentID}
        onConutinueTraining={(res) => {
          setShowAssessmentQuestion(undefined);
          const nextTrainingItem = trainingItems.find((item) => !item.finished);
          setActiveTrainingItem(nextTrainingItem);
          if (nextTrainingItem.type === 'lesson') {
            checkLesson(nextTrainingItem);
          }
          if (res.is_last_item_in_training) {
            // extract to onAssessmentCompletedProp
            setShowResultsPage(true);
          }
        }}
        onRetakeTraining={retakeTrainingHandler}
        onFinishTraining={onFinishTrainingHandler}
      />
    );
  }

  return (
    <section className='relative flex-grow order-2 col-span-6 mx-2 sm:mx-3 lg:order-1 lg:col-span-10'>
      <section className='p-2 mb-2 bg-white sm:px-2 shadow-light sm:p-3 rounded-2xl 2xl:px-6 2xl:py-4 xl:px-3'>
        <section className='mb-2'>
          <section className='flex items-center mb-1 sm:block'>
            <p>{itemPosition()}</p>
            <h2 className='font-basis-bold'>{activeTrainingItem?.title}</h2>
          </section>

          <h4 className='font-basis-bold mt-3'>
            Question {assessmentQuestion.position + 1}
          </h4>
        </section>

        <section className='mb-2'>
          {editorState && (
            <WysiwygRichTextEditor
              editorState={editorState}
              readOnly
              toolbarHidden
            />
          )}
        </section>

        <div className='my-3'>
          {assessmentQuestion.answers.map((answer) => {
            return (
              <div key={answer.id}>
                <div className='flex items-start mb-1 space-x-1 '>
                  {showAssessmentReview &&
                    (answer.correct ? (
                      <span className='w-2 h-2 rounded-full icon-check--sm text-hka_green bg-hka_green-light leading-2'></span>
                    ) : (
                      <span className='w-2 h-2 rounded-full icon-close--sm bg-hka_red-light text-hka_red leading-2'></span>
                    ))}
                  <span
                    className={`flex ${
                      activeTrainingItem.finished ? 'cursor-not-allowed' : ''
                    }`}
                  >
                    <label
                      className={`checkbox ${
                        showAssessmentReview ? 'cursor-not-allowed' : ''
                      }`}
                    >
                      <input
                        type='checkbox'
                        name='select-answer'
                        id={answer.id}
                        disabled={showAssessmentReview}
                        defaultChecked={
                          !!assessmentQuestionData?.employee_question
                            .finish_date &&
                          questionAttempts
                            ?.find((a) => !!a.finish_date)
                            ?.selected_answers.includes(answer.id)
                        }
                        onChange={() => selectAnswerHandler({ answer })}
                      />
                      <span className='icon-check'></span>
                    </label>
                    <label
                      className={`text-base tracking-wide cursor-pointer text-hka_gray font-basis-regular ${
                        showAssessmentReview ? 'cursor-not-allowed' : ''
                      }`}
                      htmlFor={answer.id}
                    >
                      {answer.answer}
                    </label>
                  </span>
                </div>
                {assessmentQuestionData?.employee_question.finish_date &&
                  answer.explanation && (
                    <p className='px-1.5 inline-flex ml-3 border border-hka_gray-light py-1 mb-3 bg-hka_gray-ultraLight rounded-xl'>
                      {answer.explanation}
                    </p>
                  )}
              </div>
            );
          })}
        </div>

        <section className='sticky bottom-0 z-10'>
          {!showAssessmentReview && (
            <section className='flex px-2 py-1.5 -mx-2 -mb-2 bg-white border-t rounded-b-2xl sm:-mb-3 lg:px-2 xl:px-3 xl:-mx-3 lg:-mx-2 2xl:px-6 2xl:-mx-6 xl:-mb-3 2xl:-mb-4 border-hka_gray-light '>
              <button
                onClick={sumbitAnswersHandler}
                className='sticky bottom-0 px-2 button button--blue'
                disabled={selectedAnswers.length === 0}
              >
                Submit Answer(s)
              </button>
            </section>
          )}
          {showAssessmentReview && (
            <section className='flex px-2 py-1.5 -mx-2 -mb-2 bg-white border-t rounded-b-2xl sm:-mb-3 lg:px-2 xl:px-3 xl:-mx-3 lg:-mx-2 2xl:px-6 2xl:-mx-6 xl:-mb-3 2xl:-mb-4 border-hka_gray-light '>
              <button
                onClick={completeQuestionHandler}
                className='sticky bottom-0 px-2 button button--blue'
              >
                {isAttemptComplete ? 'Quiz Results' : 'Next Question'}{' '}
                <span className='icon-arrow right'></span>
              </button>
            </section>
          )}
        </section>
      </section>
    </section>
  );
};

export default AssessmentBody;
