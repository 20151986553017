import { Fragment, useState } from 'react';

import { useSimpleEmployeeTrainingsData } from '../../hooks/data-hooks/employeeTrainings';

import LoaderInline from '../LoaderInline';
import Callout from '../Callout';
import ListItems from '../ListItems';
import TrainingItemWithStats from './TrainingItemWithStats';
import Pagination from '../Pagination';

const ListEmployeeTrainingsSection = ({
  employeeID,
  pageSize = 5,
  staleTime = 30000,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data: employeeTrainings, isLoading: isLoadingTrainings } =
    useSimpleEmployeeTrainingsData({
      employeeID,
      page: currentPage,
      pageSize,
      staleTime,
    });

  return (
    <Fragment>
      <h3 className='mb-1 font-basis-medium'>Assigned Training</h3>
      {isLoadingTrainings && <LoaderInline wrapperClasses='h-12 py-2' />}
      {!isLoadingTrainings && employeeTrainings.results.length === 0 && (
        <Callout
          title='Haekka'
          message={
            <>
              <span className='text-md'>&#128075;</span> It looks like this
              employee doesn't have assigned training(s).
            </>
          }
        />
      )}
      {employeeTrainings?.results.length > 0 && (
        <Fragment>
          <ListItems className='space-y-1'>
            {employeeTrainings.results.map((training) => (
              <TrainingItemWithStats key={training.id} training={training} />
            ))}
          </ListItems>
          <Pagination
            pageSize={pageSize}
            count={employeeTrainings.count}
            currentPage={currentPage}
            onPageChanged={(page) => setCurrentPage(page)}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default ListEmployeeTrainingsSection;
