import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const VantaSuccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
    toast.success('Vanta integration added successfully');
    navigate('/settings?view=integrations');
  }, [navigate]);

  return null;
};

export default VantaSuccess;
