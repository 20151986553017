import { Fragment, useContext } from 'react';
import AssignEmployeesContext from '../../store/assign-employeees-context';
import AssingEmployeesOptions from './AssingEmployeesOptions';
import GoogleOptions from './GoogleOptions';
import GroupsOptions from './GroupsOptions';
import ManuallyOptions from './ManuallyOptions';
import OktaOptions from './Okta/OktaOptions';
import SlackOptions from './SlackOptions';
import DoceboOption from './Docebo/DoceboOption';
import SeismicOption from './Seismic/SeismicOption';

const AssignEmployeesModalContent = () => {
  const { selectedTab } = useContext(AssignEmployeesContext);

  return (
    <Fragment>
      <AssingEmployeesOptions />
      {selectedTab === 'SLACK' && <SlackOptions />}
      {selectedTab === 'OKTA' && <OktaOptions />}
      {selectedTab === 'GOOGLE' && <GoogleOptions />}
      {selectedTab === 'DOCEBO' && <DoceboOption />}
      {selectedTab === 'MANUALLY' && <ManuallyOptions />}
      {selectedTab === 'GROUPS' && <GroupsOptions />}
      {selectedTab === 'SEISMIC' && <SeismicOption />}
    </Fragment>
  );
};

export default AssignEmployeesModalContent;
