import { AuthHeader, AUTH_MODES, AUTH_SCREENS } from "../../containers/Login";
import AuthButton from "./AuthButton";
import * as api from "../../api";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useAppContext } from "../../libs/contextLib";
import { useLocation } from "react-router-dom";
import Loader from "../Loader";
import { GOOGLE_LOGO } from "../../utils/constants";
import useScript from "../../utils/custom-hooks/useScript";

const GoogleAuth = ({
  authMode,
  setAuthMode,
  action,
  onError,
  setAuthScreen,
  handleChangeMode,
}) => {
  const { userHasAuthenticated, setIsConnectedWithGoogle } = useAppContext();
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  let params = queryString.parse(location.search);

  const googleSignInButton = useRef(null);

  useEffect(() => {
    const getGoogleRegisterUrl = async () => {
      try {
        const url = await api.signUpWithGoogle();
        setUrl(url);
      } catch (error) {
        onError(error);
      }
    };
    getGoogleRegisterUrl();
  }, [onError]);

  const checkInstalledApps = () => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    return api.checkInstalledApps({
      auth: auth,
    });
  };

  const logGoogleUserIn = useCallback(
    async (token) => {
      const res = await api.signInWithGoogle({
        token,
      });
      res.loggedInWith = "GOOGLE";
      localStorage.setItem("google_token_id", token);
      localStorage.setItem("auth", JSON.stringify(res));
      userHasAuthenticated(res);

      // TO-DO: extract this logic to utility function or hook (it's used in the App.js, SlackAuth and here)
      const { is_app_installed_in_slack, is_google_workspace_integrated } =
        await checkInstalledApps();
      localStorage.setItem(
        "apps_installations",
        JSON.stringify({
          ts: new Date(),
          slack: is_app_installed_in_slack,
          google: is_google_workspace_integrated,
        })
      );

      setIsConnectedWithGoogle(is_google_workspace_integrated);
    },
    [setIsConnectedWithGoogle, userHasAuthenticated]
  );

  useEffect(() => {
    const onLoad = async () => {
      const { google_success, google_error, id_token } = params;

      if (google_success === "true") {
        if (id_token) {
          setLoading(true);
          try {
            await logGoogleUserIn(id_token);
          } catch (error) {
            setLoading(false);
            if (error.message.endsWith("404")) {
              onError("no_workspace");
            }
          }
        }
      } else if (google_error === "403") {
        setAuthMode(AUTH_MODES.REGISTER);
        onError("missing_permissions");
      } else if (google_error === "403") {
        setAuthMode(AUTH_MODES.REGISTER);
        onError("generic_error");
      }
    };
    onLoad();
  }, [logGoogleUserIn, onError, params, setAuthMode, userHasAuthenticated]);

  const handleClick = (token) => {
    if (authMode === AUTH_MODES.LOGIN) {
      handleLogin(token);
    }
  };

  const handleLogin = async (token) => {
    localStorage.setItem("google_token_id", token);
    try {
      await logGoogleUserIn(token);
    } catch (error) {
      if (error.message.endsWith("404")) {
        onError("no_workspace");
      }
    }
  };

  const onGoogleLoginSuccess = (response) => {
    handleClick(response.credential);
  };

  useScript("https://accounts.google.com/gsi/client", () => {
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: onGoogleLoginSuccess,
    });
    window.google.accounts.id.renderButton(googleSignInButton.current, {
      theme: "outline",
      size: "250",
      text: "signin_with",
      width: "250",
    });
  });

  if (loading) {
    return <Loader />;
  }

  const notUsingGoogleJSX = (
    <p className="mt-1">
      Not using Google?{" "}
      <span
        className="cursor-pointer font-basis-bold text-orange"
        onClick={() => setAuthScreen(AUTH_SCREENS.SLACK)}
      >
        Sign in with Slack
      </span>
    </p>
  );
  return authMode === AUTH_MODES.LOGIN ? (
    <Fragment>
      <AuthHeader />
      {/* div below renders the AuthButton and sets the default Google button infront of the AuthButton (default Google button is fully transparent - opacity-0). 
      This is the only way I could figure out to preserve styling */}
      <div className="relative">
        <div className="w-full ">
          <AuthButton
            handleClick={() => {}}
            src={GOOGLE_LOGO}
            altText="Sign in with Google"
            action={action}
            service="Google"
            href={null}
          />
        </div>
        <div
          ref={googleSignInButton}
          className="opacity-0 absolute h-5 bg-blue-300 ml-0.5 w-full flex justify-center pt-0.3 top-1.9"
        ></div>
      </div>
      <div>
        <p>
          Need to register with <span className="font-basis-bold">Google</span>?{" "}
          <span
            className="cursor-pointer font-basis-bold text-orange"
            onClick={handleChangeMode}
          >
            Click here
          </span>
        </p>
        {notUsingGoogleJSX}
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <div className="mb-3">
        <p>
          <span className="font-basis-bold">Register your account</span>
        </p>
        <p>Use your Google account to register.</p>
      </div>
      <AuthButton
        handleClick={() => {}}
        src={GOOGLE_LOGO}
        altText="Sign in with Google"
        action={action}
        service="Google"
        href={authMode === AUTH_MODES.LOGIN ? null : url}
      />
      <div>
        <p>
          Already have an account?{" "}
          <span
            className="cursor-pointer font-basis-bold text-orange"
            onClick={handleChangeMode}
          >
            Sign in
          </span>
        </p>
        {notUsingGoogleJSX}
      </div>
    </Fragment>
  );
};

GoogleAuth.propTypes = {
  authMode: PropTypes.string.isRequired,
  setAuthMode: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  setAuthScreen: PropTypes.func.isRequired,
  handleChangeMode: PropTypes.func.isRequired,
};

export default GoogleAuth;
