import React from "react";
import { EmployeeCell, HeaderCell } from "./AssignedEmployeesTableCells";
import NoPhishingAttacksSent from "./NoPhishingAttacksSent";

export default function NoPhishingAttacksTableBody({
  campaign,
  rows,
  setSelectedEmployeeForRemoval,
}) {
  return (
    <div className="w-full bg-white rounded-lg shadow-light table--no-attacks">
      <div className="flex table__body table__body--no-attacks">
        <div className="flex flex-col flex-1 flex-grow min-h-full border-r border-hka_gray-light">
          {rows.length > 0 && (
            <div className="flex table__header">
              <HeaderCell columnInfo={{ label: "Employee" }} />
            </div>
          )}
          {rows.map((row, i) => (
            <div key={i} className="table__row">
              <EmployeeCell
                noBorder
                employee={row[0]}
                setSelectedEmployeeForRemoval={setSelectedEmployeeForRemoval}
              />
            </div>
          ))}
        </div>
        <NoPhishingAttacksSent campaignSendOption={campaign.send_option} />
      </div>
    </div>
  );
}
