import { useState } from 'react';
import { useAppContext } from '../../libs/contextLib';
import { readFileNameFromHeader } from '../commonUtils';
import { sleep } from '../sleep';
import {
  downloadCertficate,
  requestEmployeeTrainingCertificates,
} from '../../api/employeeTrainingsApi';

const readFileType = (type) => {
  if (type === 'application/pdf') return 'pdf';
  if (type === 'application/x-zip-compressed') return 'zip';
};

export const useDownloadCertificate = ({
  trainingID,
  employees,
  title,
  isDemo = false,
}) => {
  const { isAuthenticated } = useAppContext();
  const [progress, setProgress] = useState(0);
  const [errors, setErrors] = useState(null);

  const downloadCertificate = async () => {
    const brakePoint = 90;
    const millis = 400;
    let progress = 0;

    const myInterval = setInterval(function () {
      const i = Math.floor(Math.random() * 10);
      progress = progress + i;
      if (progress > 100) {
        setProgress(100);
      } else if (progress >= brakePoint) {
        clearInterval(myInterval);
      } else {
        setProgress(progress);
      }
    }, millis);

    setErrors(null);
    setProgress(0);

    const trackProgressHandler = (progressEvent) => {
      const loaded = progressEvent.loaded;
      const total = 850000; // 850000 is approximate size of a certificate (backend data is not usefull here)
      const progress = Math.round(loaded / total) * 100;
      if (progress > brakePoint) {
        if (progress > 100) {
          setProgress(100);
          return;
        }
        setProgress(progress);
        clearInterval(myInterval);
      }
    };

    try {
      const { data } = await requestEmployeeTrainingCertificates({
        auth: isAuthenticated,
        trainingID,
        employees,
        isDemo,
      });
      const progressHolder = data.progress_holder;

      let response = undefined;

      while (response?.status !== 200) {
        await sleep(700);
        response = await downloadCertficate({
          trainingID,
          trackProgress: trackProgressHandler,
          progressHolder,
        });
      }

      const filename = readFileNameFromHeader(
        response.headers['content-disposition']
      );
      const fileType = readFileType(response.headers['content-type']);

      const blob = new Blob([response.data], {
        type: fileType,
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    } catch (error) {
      setErrors(error);
      clearInterval(myInterval);
      setProgress(0);
      throw new Error(error);
    }
  };

  return { downloadCertificate, progress, errors };
};
