import api from '../../client';

export const getRoles = async ({ page = 1, page_size = 20 }) => {
  const { data } = await api.get('/api/roles', { params: { page, page_size } });
  return data;
};

export const createRole = async ({ payload }) => {
  await api.post(`/api/roles/`, payload);
};

export const updateRole = async ({ payload, roleID }) => {
  const { data } = await api.put(`/api/roles/global/${roleID}`, payload);
  return data;
};

export const deleteRole = async ({ roleID }) => {
  await api.delete(`/api/roles/global/${roleID}`);
};

export const getUserAccountsSettings = async ({ companyID }) => {
  const { data } = await api.get(`/api/companies/${companyID}/users_settings`);
  return data;
};

export const updateUserAccountsSettings = async ({ companyID, updateData }) => {
  const { data } = await api.patch(
    `/api/companies/${companyID}/users_settings`,
    { ...updateData }
  );
  return data;
};
