import { useState } from 'react';
import Select from 'react-select';
import { useExternalAppsData } from '../../hooks/data-hooks/integrations/useExternalAppsData';
import { getDoceboCourses } from '../../api/integrationsApi';

import RemoveDoceboCourseConfirmModal from './RemoveDoceboCourseConfirmModal';
import Note from '../Note';
import { DOCEBO_MANAGERS_NOTIFICATION_OPTIONS } from '../EditTrainingModal';
import SelectInput from '../Base/SelectInput';

const DoceboInput = ({
  doceboCourse,
  setDoceboCourse,
  doceboManagerNotifications,
  setDoceboManagerNotifications,
  doceboManagerNotificationsSchedule,
  setDoceboManagerNotificationsSchedule,
  removeAssigmentsWhenRemovingDocebo = true,
  setRemoveAssigmentsWhenRemovingDocebo = () => {},
}) => {
  const [removeDoceboTraining, setRemoveDoceboTraining] = useState(undefined);
  const [changedWarning, setChangedWarning] = useState(false);
  const { data: externalIntegrations } = useExternalAppsData();
  const activeDoceboApp = externalIntegrations?.results.find(
    (ei) => ei.resourcetype === 'DoceboInstallation' && ei.is_active
  );

  const changeEventHandler = (value) => {
    if (value.length > 0) {
      setChangedWarning(true);
      setDoceboCourse(value[value.length - 1]);
      if (!doceboManagerNotificationsSchedule) {
        setDoceboManagerNotificationsSchedule(
          DOCEBO_MANAGERS_NOTIFICATION_OPTIONS[0]
        );
      }
    }
    if (value.length === 0) {
      setRemoveDoceboTraining(true);
    }
  };

  if (!activeDoceboApp) return null;

  return (
    <>
      <SelectInput
        label={{ text: 'Associated Docebo Training', optional: true }}
        value={doceboCourse}
        onChange={changeEventHandler}
        placeholder='Search Docebo Courses'
        isMultiSelect
        isAsync={{
          apiFunction: async (args) =>
            await getDoceboCourses({ ...args, page: 1 }),
          responseProperty: 'items',
          responseItemName: 'title',
        }}
      />
      <section className='w-full mb-2'>
        {changedWarning && (
          <section className='mb-2 mt-1'>
            <Note type='warning'>
              <p className='py-0.2'>
                Changes are not saved yet. Submit the Modal to save it.
              </p>
            </Note>
          </section>
        )}
      </section>

      {!!doceboCourse && (
        <section className='w-full mb-3'>
          <h4 className='mb-1 font-basis-bold'>Docebo Manager Settings</h4>

          <section className='mb-2 flex'>
            <section className='flex items-center'>
              <label className='switch'>
                <input
                  type='checkbox'
                  name='has_weekly_quiz'
                  checked={doceboManagerNotifications}
                  onChange={() => setDoceboManagerNotifications((s) => !s)}
                  className='cursor-pointer'
                />
                <span className='slider'></span>
              </label>
            </section>
            <p
              className='ml-1 text-sm cursor-pointer'
              onClick={() => setDoceboManagerNotifications((s) => !s)}
            >
              Send managers a message when at least one of their employees has
              training overdue.
            </p>
          </section>
          <Select
            options={DOCEBO_MANAGERS_NOTIFICATION_OPTIONS}
            onChange={(o) => setDoceboManagerNotificationsSchedule(o)}
            value={doceboManagerNotificationsSchedule}
            classNamePrefix='select'
            className='select--select-employees-manually'
            unstyled
          />
        </section>
      )}
      {removeDoceboTraining && (
        <RemoveDoceboCourseConfirmModal
          onClose={() => setRemoveDoceboTraining(false)}
          onSubmit={() => {
            setDoceboCourse('');
            setRemoveDoceboTraining(false);
            setChangedWarning(true);
          }}
          removeAssigmentsWhenRemovingDocebo={
            removeAssigmentsWhenRemovingDocebo
          }
          setRemoveAssigmentsWhenRemovingDocebo={
            setRemoveAssigmentsWhenRemovingDocebo
          }
        />
      )}
    </>
  );
};

export default DoceboInput;
