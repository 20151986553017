import { Fragment, useState } from 'react';
import { modalModes } from '../../../../utils/commonUtils';
import Modal from '../../../Modal/Modal';
import Tag from '../../../Tag';

const RolesCRUDModal = ({ roleCrudModal, onClose, onSubmit }) => {
  const [roleTitle, setRoleTitle] = useState(roleCrudModal.roleCaption);

  const editMode = roleCrudModal.mode === modalModes.EDIT;

  const headerText = editMode ? 'Edit Role' : 'Add Role';
  const submitButtonText = editMode ? 'Yes, Rename Role' : 'Create';
  const submitButtonType = editMode ? 'danger' : 'success';

  const submitHandler = () => {
    onSubmit({
      title: roleTitle,
    });
  };

  return (
    <Modal
      show={true}
      title={headerText}
      submitButtonText={submitButtonText}
      submitButtonType={submitButtonType}
      onSubmit={submitHandler}
      onClose={onClose}
      submitValid={roleCrudModal.roleCaption !== roleTitle}
      compact
    >
      <Fragment>
        <section className='mb-2'>
          <h4 className='mb-1 font-basis-bold'>
            {roleCrudModal.mode === modalModes.ADD && 'New '}Role name
          </h4>
          <input
            type='text'
            name='days_till_due'
            value={roleTitle}
            className={`w-full h-4 pl-1 pr-1 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray border-hka_gray-lights hka-input mb-1`}
            onChange={(e) => setRoleTitle(e.target.value)}
          />
        </section>
        {editMode && (
          <div className='flex items-center'>
            <p className='mb-1 mr-1'>Selected Role:</p>
            <Tag tag={roleCrudModal.roleCaption} noRemoveIcon />
          </div>
        )}
      </Fragment>
    </Modal>
  );
};

export default RolesCRUDModal;
