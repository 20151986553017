import React from "react";
import Modal from "../Modal/Modal";

export default function DeleteAPIKeyModal({
  showModal,
  key,
  onSubmit,
  onClose,
}) {
  return (
    <Modal
      show={showModal}
      onSubmit={onSubmit}
      onClose={onClose}
      title="Delete API Key"
      submitButtonText="Delete API Key"
      submitButtonType="danger"
      compact
    >
      <p>Are you sure you want to delete this API key?</p>
    </Modal>
  );
}
