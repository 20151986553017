import React from "react";
import { getCampaignStatus } from "../../utils/phishingUtils";

export default function PhishingCampaignStatusIndicator({ campaign }) {
  const campaignStatus = getCampaignStatus(campaign);

  return (
    <span
      className={`leading-2.4 px-0.5 text-sm font-basis-medium rounded-md ${campaignStatus.styleClasses}`}
    >
      {campaignStatus.value}
    </span>
  );
}
