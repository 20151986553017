import React from "react";
import Modal from "../Modal/Modal";
import PropTypes from "prop-types";
import Note from "../Note";

export default function RenewTrainingModal({
  showModal,
  employee,
  onSubmit,
  onClose,
}) {
  return (
    <Modal
      show={showModal}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`Renew Training`}
      submitButtonText="Renew Training"
      submitButtonType="warning"
      compact
    >
      <p className="mb-1">
        Are you sure you want to renew this training for{" "}
        <span className="font-basis-bold">{employee.employee_name}?</span>
      </p>
      <Note type="warning">
        <p>
          Performing this action will reset the user's current progress and
          require them to retake the training.
        </p>
      </Note>
    </Modal>
  );
}

RenewTrainingModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  employee: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
