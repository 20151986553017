import { useCallback, useEffect, useState } from "react";
import * as api from "../../api";
import { onError } from "../../libs/errorLib";
import { transformSlackUserGroup } from "../groupModalUtils";

const useFetchSlackUserGroups = ({ auth, setMissingScopesError }) => {
  const [trigger, setTrigger] = useState(false);
  const [response, setResponse] = useState([]);
  const [completed, setCompleted] = useState(false);

  const doRequest = useCallback(() => {
    return api.getSlackUserGroups({
      auth,
    });
  }, [auth]);

  useEffect(() => {
    const fetchSlackUserGroups = async () => {
      try {
        const response = await doRequest();
        const data = response.map(transformSlackUserGroup);

        setResponse(data);
        setCompleted(true);
      } catch (e) {
        if (e.toString().includes("500")) {
          setMissingScopesError(true);
        } else {
          onError(e);
        }
      }
    };
    if (trigger && auth) fetchSlackUserGroups();
  }, [doRequest, trigger, auth]);

  return { response, completed, setTrigger };
};

export default useFetchSlackUserGroups;
