import { useMutation, useQueryClient } from '@tanstack/react-query';
import { removeExternalAppSettings } from '../../../api/integrationsApi';

export const useRemoveExternalAppSettings = ({
  resourcetype,
  integration,
  companyID,
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args) => removeExternalAppSettings({ ...args, resourcetype }),
    onSuccess: () => {
      queryClient.invalidateQueries(['external-installations-all']);
      queryClient.invalidateQueries([
        'external-installation',
        integration,
        companyID,
      ]);
    },
  });
};
