import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import CompleteTrainingModal from './CompleteTrainingModal';
import DropdownMenu from '../../Base/DropdownMenu';
import {
  useEmployeeTrainingsBulkUpdate,
  useDownloadEmployeeCertificates,
} from '../../../hooks/data-hooks/employeeTrainings';
import BulkDueDateConfirmModal from './BulkDueDateConfirmModal';

const BulkTrainingDropdownOptions = ({
  training,
  allEmployeesSelected,
  selectedEmployees,
  updateEmployeeList,
}) => {
  const [showCompleteTrainingModal, setShowCompleteTrainingModal] =
    useState(false);
  const [showBulkDueDateUpdateModal, setShowBulkDueDateUpdateModal] =
    useState(false);

  const { mutate: updateTrainings } = useEmployeeTrainingsBulkUpdate({
    onSuccess: (res) => {
      const { action } = res.data;
      if (action === 'complete_assigments') {
        setShowCompleteTrainingModal(false);
        toast.success(
          `We marked this training as complete with 100% success for all employees in the training!`
        );
      }
      if (action === 'due_date_update') {
        setShowBulkDueDateUpdateModal(false);
        toast.success(`Due date was changed for all employees!`);
      }
      updateEmployeeList();
    },
  });

  const { mutate: downloadCertificates } = useDownloadEmployeeCertificates({
    cb: () => {
      toast.success(
        `Excellent! The certificates for the selected employees will be sent to you via Slack shortly.${
          allEmployeesSelected
            ? ' Please note: It can take a while depending on how many employees are assigned.'
            : ''
        }`
      );
    },
  });

  const downloadCertificatesHandler = async () => {
    downloadCertificates({
      trainingID: training.id,
      employees: allEmployeesSelected ? 'ALL' : selectedEmployees,
      sendToSlack: true,
    });
  };

  const updateTrainingHandler = ({ action, date }) => {
    updateTrainings({
      trainingID: training.id,
      employees: allEmployeesSelected ? [] : selectedEmployees,
      allEmployees: allEmployeesSelected,
      action,
      updateData: { due_date: date },
    });
  };

  const dropMenuActions = [
    {
      icon: 'icon-download',
      label: 'Download Certificates',
      onClick: downloadCertificatesHandler,
      disabled: selectedEmployees.length === 0 && !allEmployeesSelected,
    },
    {
      icon: 'icon-calendar',
      label: 'Update Due Date',
      onClick: () => setShowBulkDueDateUpdateModal(true),
    },
    {
      icon: 'icon-check',
      label: 'Mark Trainings as Complete',
      onClick: () => setShowCompleteTrainingModal(true),
      disabled: selectedEmployees.length === 0 && !allEmployeesSelected,
    },
  ];

  const closeCompleteTrainingModalHandler = () => {
    setShowCompleteTrainingModal(false);
  };

  return (
    <Fragment>
      <DropdownMenu actions={dropMenuActions} />
      {showCompleteTrainingModal && (
        <CompleteTrainingModal
          showModal={showCompleteTrainingModal}
          employee={null}
          onSubmit={() =>
            updateTrainingHandler({ action: 'complete_assigments' })
          }
          onClose={closeCompleteTrainingModalHandler}
        />
      )}
      {showBulkDueDateUpdateModal && (
        <BulkDueDateConfirmModal
          showModal={showBulkDueDateUpdateModal}
          onClose={() => setShowBulkDueDateUpdateModal(false)}
          onSubmit={(date) =>
            updateTrainingHandler({ action: 'due_date_update', date })
          }
        />
      )}
    </Fragment>
  );
};

export default BulkTrainingDropdownOptions;
