import { ContentState, EditorState } from "draft-js";
import {
  EVENT_TRACKER,
  internalSlackChannelLogger,
} from "../analytics/eventTracker";
import { workflowsActions } from "../store/workflowsSlice";
import { expanderViewModes } from "./commonUtils";
import { GOOGLE_LOGO, SLACK_LOGO, ZENDESK_LOGO } from "./constants";
import { optimizeSlackMarkup } from "./lessonObjectHelperFunctions";
import { getEditorState } from "./wysiwygEditorHelpers";

export const workflowModalViews = {
  WORKFLOW_DETAILS: "Workflow Details",
  ASSIGN_EMPLOYEES: "Assign Employees",
  ASSIGNED_EMPLOYEES: "Assigned Employees",
};

export const workflowsMenuItems = ({ openModalHandler, setViewMode }) => [
  {
    icon: "icon-tile",
    value: expanderViewModes.TILE_MODE,
    label: "Tile View",
    onClick: () => {
      setViewMode(expanderViewModes.TILE_MODE);
      localStorage.setItem(
        "workflow_page_view_mode",
        expanderViewModes.TILE_MODE
      );
    },
  },
  {
    icon: "icon-list",
    value: expanderViewModes.LIST_MODE,
    label: "List View",
    onClick: () => {
      setViewMode(expanderViewModes.LIST_MODE);
      localStorage.setItem(
        "workflow_page_view_mode",
        expanderViewModes.LIST_MODE
      );
    },
  },
  {
    icon: "icon-plus",
    label: "Add Workflow",
    onClick: openModalHandler,
  },
];

export const WORKFLOW_SOURCES = [
  { id: "slack", src: SLACK_LOGO, alt: "Slack icon", size: 25, active: true },
  {
    id: "google",
    src: GOOGLE_LOGO,
    alt: "Google icon",
    size: 30,
    active: false,
  },
  {
    id: "zendesk",
    src: ZENDESK_LOGO,
    alt: "Zendesk icon",
    size: 50,
    active: false,
  },
];

export const RISK_LEVEL_OPTIONS = [
  {
    value: "none",
    label: "No Risk",
  },
  {
    value: "low",
    label: "Low Risk",
  },
  {
    value: "medium",
    label: "Medium Risk",
  },
  {
    value: "high",
    label: "High Risk",
  },
];

export const EVENT_ACTION_OPTIONS = [
  {
    value: "custom_response",
    label: "Send Response",
  },
  {
    value: "assign_training",
    label: "Assign Training",
  },
  {
    value: "admin_message",
    label: "Send admins a message",
  },
];

export const SENTIMENT_RATINGS_OPTIONS = [
  {
    value: "negative",
    label: "Negative",
  },
  {
    value: "neutral",
    label: "Neutral (Default)",
  },
  {
    value: "positive",
    label: "Positive",
  },
];

export const FREQUENCY_OPTIONS = [
  {
    value: true,
    label: "Just once (recommended)",
  },
  {
    value: false,
    label: "Every time the event take place",
  },
];

export const getInitialEditorStateForCustomResponse = ({ event }) => {
  const message = `Hello USER_NAME! This is an example message to send to a user.`;
  return EditorState.createWithContent(ContentState.createFromText(message));
};

export const populateWorkflowContext = async ({
  workflow,
  dispatch,
  fetchedEvents,
  setCustmResponseES,
  companyTrainings,
}) => {
  // initialize Event Source
  if (workflow.event_source.startsWith("Slack")) {
    dispatch(workflowsActions.selectSource({ source: "slack" }));
  }

  // initialize Event
  const event = fetchedEvents.find((e) => e.id === workflow.event.event_id);
  dispatch(workflowsActions.selectEvent({ event }));

  // initialize Risk Level
  const riskLevel = RISK_LEVEL_OPTIONS.find(
    (rl) => rl.value === workflow.event.risk_level
  );
  dispatch(workflowsActions.selectRiskLevel({ riskLevel }));

  // initialize Select Action and related fields
  if (workflow.action.name.includes("custom_response")) {
    dispatch(
      workflowsActions.selectAction({
        action: { value: "custom_response", label: "Send Response" },
      })
    );

    const res = await getEditorState(
      workflow.action.payload.editor_content.editor_state,
      workflow.action.payload.editor_content,
      null,
      false
    );
    setCustmResponseES(res);
  }

  if (workflow.action.name.includes("assign_training")) {
    dispatch(
      workflowsActions.selectAction({
        action: { value: "assign_training", label: "Assign Training" },
      })
    );

    if (companyTrainings.length === 0) {
      dispatch(
        workflowsActions.selectTraining({
          training: {
            value: workflow.action.payload.training_id,
            label: "",
          },
        })
      );
    }
  }

  if (workflow.action.name.includes("admin_message")) {
    dispatch(
      workflowsActions.selectAction({
        action: { value: "admin_message", label: "Send admins a message" },
      })
    );
  }

  const sentiment = SENTIMENT_RATINGS_OPTIONS.find(
    (s) => s.value === workflow.action.sentiment
  );
  dispatch(
    workflowsActions.selectSentimentalRating({ sentimentalRating: sentiment })
  );

  // initialize Send Frequency
  if (workflow.action.name.includes("assign_training")) {
    const oneTimeOnly = workflow.action.payload.assign_policy === "skip";
    const frequency = FREQUENCY_OPTIONS.find((fo) => fo.value === oneTimeOnly);
    dispatch(workflowsActions.selectFrequency({ frequency }));
  } else {
    const frequency = FREQUENCY_OPTIONS.find(
      (fo) => fo.value === workflow.action.payload.one_time_only
    );
    dispatch(workflowsActions.selectFrequency({ frequency }));
  }

  // initialize Allow users to silence this event
  dispatch(
    workflowsActions.setSilenceEvent({ value: workflow.can_be_supressed })
  );

  // initialize Event Destination
  if (workflow.event_destination.startsWith("Slack")) {
    dispatch(workflowsActions.selectDestination({ destination: "slack" }));
  }
};

export const getBodyData = async ({
  selectedSource,
  selectedEvent,
  selectedRiskLevel,
  selectedDestination,
  silenceEvent,
  selectedAction,
  object,
  customResponseES,
  selectedFrequency,
  selectedSentimentalRating,
  selectedTraining,
}) => {
  const bodyData = {
    event_source: selectedSource,
    action: {},
    event: {
      id: object ? object?.event.id : selectedEvent.value,
      risk_level: selectedRiskLevel.value,
      risk: selectedRiskLevel.value,
      event_id: selectedEvent.value,
      event_name: object?.event.event_name,
    },
    event_destination: selectedDestination,
    can_be_supressed: silenceEvent,
  };

  let actonPayload = {};
  let actionId = undefined;
  if (
    selectedAction.value === "custom_response" &&
    object?.action.name.includes("custom_response")
  ) {
    actionId = object?.action.id;
  }
  if (
    selectedAction.value === "assign_training" &&
    object?.action.name.includes("assign_training")
  ) {
    actionId = object?.action.id;
  }
  if (
    selectedAction.value === "admin_message" &&
    object?.action.name.includes("admin_message")
  ) {
    actionId = object?.action.id;
  }

  if (selectedAction.value === "custom_response") {
    const { editor_content, slackMarkup } = await optimizeSlackMarkup(
      object?.action.payload.editor_content?.id,
      customResponseES
    );
    actonPayload = {
      id: actionId,
      type: selectedAction.value,
      payload: {
        message: slackMarkup,
        editor_content,
        one_time_only: selectedFrequency.value,
        sentiment: selectedSentimentalRating.value,
      },
    };
  } else if (selectedAction.value === "assign_training") {
    actonPayload = {
      id: actionId,
      type: selectedAction.value,
      payload: {
        training_id: selectedTraining.value,
        sentiment: selectedSentimentalRating.value,
      },
    };
  } else if (selectedAction.value === "admin_message") {
    actonPayload = {
      id: actionId,
      type: selectedAction.value,
      payload: {
        one_time_only: selectedFrequency.value,
        sentiment: selectedSentimentalRating.value,
      },
    };
  }

  bodyData.action = actonPayload;
  return bodyData;
};

export const handleWorkflowCreatedTrack = ({ auth, reqData }) => {
  internalSlackChannelLogger(EVENT_TRACKER.WORKFLOW_CREATED, auth, {
    Admin: auth.name,
    Company: auth.company_details.title,
    "Company ID": auth.company_details.id,
    "Workflow ID": reqData.workflowID,
    Source: reqData.source,
    Event: reqData.event,
    Action: reqData.action,
    "Company Link to Admin Panel": `${process.env.REACT_APP_API_URL}/admin/company/company/${auth.company_details.id}/change`,
  });
};
