import './checkboxes.css';

const AnswerResultItem = ({ answer, selectedAnswers }) => {
  return (
    <div className='flex py-0.4'>
      <div>
        <label
          className={`mr-1 checkbox ${
            answer.correct ? 'correct' : 'incorrect'
          }`}
        >
          <input
            type='checkbox'
            id='select-all'
            checked={true}
            className='cursor-pointer'
            onChange={() => {}}
          />
          <span className={`icon-${answer.correct ? 'check' : 'close'}`}></span>
        </label>
      </div>

      <div>
        <label className='mr-1 checkbox user-selection'>
          <input
            type='checkbox'
            id='select-all'
            checked={selectedAnswers.includes(answer?.id)}
            className='cursor-pointer'
            onChange={() => {}}
          />
          <span className='icon-check'></span>
        </label>
      </div>

      <div className='mt-0.1'>
        <p
          className={`text-base ${
            !answer.correct &&
            selectedAnswers.includes(answer.id) &&
            'text-red-400'
          }`}
        >
          {answer.answer}
        </p>
      </div>
    </div>
  );
};

export default AnswerResultItem;
