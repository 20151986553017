import { useEffect, useState } from "react";

const PaginatorItem = ({
  caption,
  btnClass,
  activeClass,
  onSelect,
  disabled,
}) => {
  return (
    <button
      type="button"
      onClick={disabled ? () => {} : () => onSelect(caption)}
      className={`${activeClass} p-0 button mb-1 ${
        disabled ? "cursor-default" : "cursor-pointer"
      }`}
      // disabled={disabled} <--- Do not use disable property, scrollIntoView does not work with it
    >
      <span className={`${btnClass}`}>{(caption + "").trim()}</span>
    </button>
  );
};

const Paginator = ({
  pageSize = 10,
  count,
  currentPage,
  onPageChange = () => {},
}) => {
  const [pages, setPages] = useState([]);
  const pagesCount = Math.ceil(count / pageSize);

  useEffect(() => {
    const pgs = [];
    if (pagesCount < 8) {
      for (let i = 1; i < pagesCount + 1; i++) {
        pgs.push(i);
      }
      setPages(["LEFT", ...pgs, "RIGHT"]);
    } else {
      if (currentPage < 4) {
        for (let i = 0; i < currentPage; i++) {
          pgs.push(i + 1);
        }
      } else {
        pgs.push(1, "... ", currentPage - 1, currentPage);
      }

      if (pagesCount - currentPage > 2) {
        pgs.push(currentPage + 1, " ...", pagesCount);
      } else {
        for (let i = currentPage + 1; i < pagesCount + 1; i++) {
          pgs.push(i);
        }
      }
      setPages(["LEFT", ...pgs, "RIGHT"]);
    }
  }, [count, currentPage, pageSize, pagesCount]);

  const onSelectHandler = (page) => {
    if (page === ". . .") {
      return;
    } else if (page === " ") {
      onPageChange(currentPage - 1);
      return;
    } else if (page === "  ") {
      onPageChange(currentPage + 1);
      return;
    }
    onPageChange(page);
  };

  return (
    <div className="flex flex-wrap justify-center space-x-1">
      {pages.map((page, i) => {
        let caption = "";
        let btnClass = "";
        let activeClass = "";
        let disabled = false;

        switch (page) {
          case "LEFT":
            caption = " "; // important: one blank space
            btnClass = "icon-arrow left text-hka_blue";
            if (currentPage === 1) {
              activeClass = "bg-hka_gray";
              btnClass = "icon-arrow left text-white";
              disabled = true;
            } else {
              activeClass = "bg-hka_blue-light";
            }
            break;
          case "RIGHT":
            caption = "  "; // important: two blank spaces
            btnClass = "icon-arrow right";
            if (currentPage === pagesCount) {
              activeClass = "bg-hka_gray";
              btnClass = "icon-arrow right text-white";
              disabled = true;
            } else {
              activeClass = "bg-hka_blue-light";
            }
            break;

          case " ...":
          case "... ":
            caption = ". . .";
            activeClass = "cursor-default";
            btnClass = "pb-0.7 text-orange";
            break;

          default:
            caption = page;
            if (page === currentPage) {
              activeClass = "bg-orange text-white";
            } else {
              activeClass = "bg-hka_gray-light text-hka_gray";
            }
        }

        return (
          <PaginatorItem
            key={i}
            caption={caption}
            btnClass={btnClass}
            activeClass={activeClass}
            disabled={disabled}
            onSelect={onSelectHandler}
          />
        );
      })}
    </div>
  );
};

export default Paginator;
