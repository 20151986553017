import React, { Fragment } from "react";

export default function CampaignDetailsModalFragment({ name, setName }) {
  return (
    <Fragment>
      <div className="space-y-2">
      <section className="flex items-center gap-2">
        <img
          src="https://haekka-product-assets.s3.us-west-2.amazonaws.com/create-first-phishing.svg"
          alt="Engagement Icon"
          className="min-w-5"
        />
        <p className="text-sm sm:text-base">
          Phishing campaigns are a great way to improve employee awareness about cyber attacks, and foster a strong security culture within your organization.
        </p>
        </section>
        <span className="hr"></span>
        <section className="flex flex-col items-center justify-between sm:flex-row">
          <h4 className="w-full mb-1 sm:mb-0 sm:w-3/5">Campaign Name <span className="text-sm text-hka_gray">(Required)</span></h4>
          <div className="relative flex flex-col flex-1 w-full sm:w-2/5">
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Campaign Name"
            value={name}
            className="text-base transition-all border outline-none input focus:ring ring-hka_blue-light"
            maxLength={30}
          />
          </div>
        </section>
      </div>
    </Fragment>
  );
}
