import { Fragment, useContext } from "react";
import AssignEmployeesContext from "../../../store/assign-employeees-context";
import WysiwygRichTextEditor from "../../WysiwygEditor/WysiwygRichTextEditor";
import { useDispatch } from "react-redux";
import { workflowsActions } from "../../../store/workflowsSlice";

const CustomResponseAction = ({ customResponseES, setCustmResponseES }) => {
  const dispatch = useDispatch();

  const addImageHandler = (imageID) => {
    dispatch(workflowsActions.addImage({ imageID }));
  };

  const { object } = useContext(AssignEmployeesContext);

  return (
    <Fragment>
      <div className="pb-2 pl-2 border-l-4">
        <p className="text-hka_gray-dark mb-0.5">Response Message</p>
        <WysiwygRichTextEditor
          editorState={customResponseES}
          onSetEditorState={(state) => setCustmResponseES(state)}
          id={object?.id}
          onImageAdded={addImageHandler}
          contentType={"workflow"}
        />
      </div>
    </Fragment>
  );
};

export default CustomResponseAction;
