import { Fragment } from "react";
import useValidateInputLength from "../../utils/custom-hooks/useValidateInputLength";
import { lessonProperties } from "../../utils/trainingUtils";
import { useSelector, useDispatch } from "react-redux";
import { lessonActions } from "../../store/lessonSlice";

const TitleSection = () => {
  const dispatch = useDispatch();
  const { isSimpleLesson, title, subtitle } = useSelector(
    (state) => state.lesson
  );

  const { showWarning: showWarningTitle, remainingChars: remainingCharsTitle } =
    useValidateInputLength(title, 170, 200);
  const {
    showWarning: showWarningSubitle,
    remainingChars: remainingCharsSubtitle,
  } = useValidateInputLength(subtitle, 170, 200);

  return (
    <Fragment>
      <section className="w-full mb-2">
        <h4 className="mb-1 font-basis-bold">
          Title <span className="text-sm text-hka_gray">(Required)</span>
        </h4>
        <div className="flex">
          <input
            maxLength={200}
            type="text"
            value={title}
            onChange={(e) =>
              dispatch(
                lessonActions.updateLesson({
                  property: lessonProperties.TITLE,
                  value: e.target.value,
                })
              )
            }
            placeholder="Enter Lesson Title"
            className={`w-full p-1 font-semibold outline-none rounded-xl text-base2 ${
              showWarningTitle
                ? "pr-4 border-2 text-hka_red bg-hka_red-light border-hka_red input--question"
                : "pr-0 border text-hka_gray-dark border-hka_gray-light"
            }`}
          />
          {showWarningTitle && (
            <p
              className={`border-l w-4 border-hka_gray-border text-sm leading-4 -ml-4 text-center font-basis-bold ${
                showWarningTitle ? "text-hka_red border-hka_red" : ""
              }`}
            >
              {remainingCharsTitle}
            </p>
          )}
        </div>
      </section>

      {!isSimpleLesson && (
        <section className="w-full mb-2">
          <h4 className="mb-1 font-basis-bold">
            Subtitle <span className="text-sm text-hka_gray">(Optional)</span>
          </h4>
          <div className="flex">
            <input
              maxLength={200}
              type="text"
              value={subtitle}
              onChange={(e) =>
                dispatch(
                  lessonActions.updateLesson({
                    property: lessonProperties.SUBTITLE,
                    value: e.target.value,
                  })
                )
              }
              placeholder="Enter Lesson Subtitle"
              className={`w-full p-1 font-semibold outline-none rounded-xl text-base2 ${
                showWarningSubitle
                  ? "pr-4 border-2 text-hka_red bg-hka_red-light border-hka_red input--question"
                  : "pr-0 border text-hka_gray-dark border-hka_gray-light"
              }`}
            />
            {showWarningSubitle && (
              <p
                className={`border-l w-4 border-hka_gray-border text-sm leading-4 -ml-4 text-center font-basis-bold ${
                  showWarningSubitle ? "text-hka_red border-hka_red" : ""
                }`}
              >
                {remainingCharsSubtitle}
              </p>
            )}
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default TitleSection;
