import { useEmployeeTrainingAssessmentResults } from '../../hooks/data-hooks/employeeTrainings';
import { useAppContext } from '../../libs/contextLib';
import LoaderInline from '../LoaderInline';

const AssessmentResults = ({
  assessmentID,
  onConutinueTraining,
  onFinishTraining,
  onRetakeTraining,
}) => {
  const { isAuthenticated } = useAppContext();

  const { data, isLoading } = useEmployeeTrainingAssessmentResults({
    employeeID: isAuthenticated.id,
    assessmentID,
  });

  if (isLoading) return <LoaderInline wrapperClasses='w-full min-h-30' />;

  const showRetakeButton =
    (data.retakes === 'force_retake' && !data.has_user_passed) ||
    (data.retakes === 'optional' && !data.is_last_item_in_training);

  const showContinueButton =
    (data.retakes === 'force_retake' && data.has_user_passed) ||
    data.retakes === 'force_continue' ||
    data.retakes === 'optional';

  return (
    <section className='relative flex-grow order-2 col-span-6 mx-2 sm:mx-3 lg:order-1 lg:col-span-10'>
      <section className='p-2 mb-2 bg-white sm:px-2 shadow-light sm:p-3 rounded-2xl 2xl:px-6 2xl:py-4 xl:px-3'>
        <section className='flex items-center mb-3 sm:block'>
          <h2 className='font-basis-bold'>{data.assessment_title} Results</h2>
        </section>

        <section className='flex items-center mb-3 sm:block'>
          {data.questions
            .sort((a, b) => a.position - b.position)
            .map((question) => (
              <div className='flex flex-col mb-2' key={question.position}>
                <h2 className='mb-1 text-orange'>
                  Question {question.position + 1}
                </h2>
                <p>
                  You selected {question.number_of_correct_answers_selected} of{' '}
                  {question.number_of_correct_answers_total} correct answers. (
                  {(
                    (question.number_of_correct_answers_selected /
                      question.number_of_correct_answers_total) *
                    100
                  ).toFixed(0)}
                  %)
                </p>
              </div>
            ))}
        </section>

        <section className='flex items-center mb-1 sm:block'>
          <h2 className='mb-1'>Final Score: {data.score_percentage}%</h2>
          <p>The passing score for this quiz is {data.passing_grade}%</p>
        </section>

        {data.retakes === 'force_retake' && !data.has_user_passed && (
          <section className='flex items-center mt-3 sm:block'>
            <p className='text-orange'>
              You have not achieved a passing grade for this quiz. You must
              retake the quiz in order to finish the training.
            </p>
          </section>
        )}

        {(data.is_last_item_in_training ||
          (data.are_lessons_complete && data.are_assessments_complete)) &&
          showContinueButton && (
            <p className='mt-3 text-orange'>
              To complete this training, please click the Finish Training button
              below.
            </p>
          )}

        <section className='sticky bottom-0 z-10 mt-3'>
          <section className='flex gap-2 px-2 py-1.5 -mx-2 -mb-2 bg-white border-t rounded-b-2xl sm:-mb-3 lg:px-2 xl:px-3 xl:-mx-3 lg:-mx-2 2xl:px-6 2xl:-mx-6 xl:-mb-3 2xl:-mb-4 border-hka_gray-light '>
            {showRetakeButton && (
              <button
                onClick={onRetakeTraining}
                className='sticky bottom-0 px-2 button button--blue'
              >
                Retake Quiz <span className='icon-arrow right'></span>
              </button>
            )}

            {showContinueButton && (
              <button
                onClick={() =>
                  data.is_last_item_in_training ||
                  (data.are_lessons_complete && data.are_assessments_complete)
                    ? onFinishTraining()
                    : onConutinueTraining(data)
                }
                className='sticky bottom-0 px-2 button button--blue'
              >
                {data.is_last_item_in_training ||
                (data.are_lessons_complete && data.are_assessments_complete)
                  ? 'Finish'
                  : 'Continue'}{' '}
                Training <span className='icon-arrow right'></span>
              </button>
            )}
          </section>
        </section>
      </section>
    </section>
  );
};

export default AssessmentResults;
