export const getAuthData = () => {
  try {
    return JSON.parse(localStorage.getItem("auth"));
  } catch (err) {
    console.log("error when getting auth data from local storage", err);
    return false;
  }
};

export const clearAuthData = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("google_token_id");
  localStorage.removeItem("apps_installations");
  localStorage.removeItem("login_token");
};
