import PropTypes from "prop-types";
import ActionButton from "../ActionButton";
import UpgradePlanButton from "../UpgradePlanButton";

const CurriculumItem = ({
  curriculum,
  onAddTrainingClick,
  onOpenPreview,
  needsUpgrade,
}) => {
  const freeLabel = (
    <label className="px-0.7 tracking-wider m-0 text-xs text-center uppercase border rounded-full bg-orange-light leading-1.8 border-orange border-opacity-40 font-basis-bold text-orange">
      Free Course
    </label>
  );

  return (
    <div className="flex flex-col p-2 transition-all bg-white cursor-pointer lg:space-x-2 lg:py-2 lg:pr-2 lg:pl-0 catalog-training lg:items-center lg:flex-row shadow-light rounded-xl">
      <div className="flex flex-1 space-x-2 truncate">
        <div
          className="flex lg:justify-center lg:w-10"
          onClick={() => onOpenPreview(curriculum)}
        >
          {curriculum.author_icon_url ? (
            <img
              src={curriculum.author_icon_url}
              alt=""
              className="w-3.6 h-3.6 rounded-md"
            />
          ) : (
            <img
              src="https://haekka-author-icons.s3.us-west-2.amazonaws.com/HKA--UPDATED--Author_Haekka.png"
              alt=""
              className="h-3.6 rounded-lg w-3.6"
            />
          )}
        </div>
        <div
          className="flex-1 truncate"
          onClick={() => onOpenPreview(curriculum)}
        >
          <div className="flex">
            <h4 className="mr-1 truncate link--bold">{curriculum.title}</h4>
            {curriculum.is_available_for_free && freeLabel}
          </div>
          <p className="truncate">{curriculum.subtitle}</p>
        </div>
      </div>
      {/* lessons */}
      <div className="flex items-center lg:justify-center lg:w-10 pt-0.5 lg:pt-0">
        <p className="text-right lg:w-1/2 font-basis-bold">
          <span className="inline-flex w-8 lg:hidden lg:mr-0">Lessons:</span>
          {curriculum.lessons ? curriculum.lessons.length : 0}
        </p>
        <div className="inline-block w-1/2">
          {curriculum.includes_video && (
            <span className="opacity-50 icon-video text-hka_gray"></span>
          )}
        </div>
      </div>

      {/* category */}
      <div className="flex items-center pt-0.5 lg:pt-0 lg:w-10">
        <p className="inline-flex w-8 lg:hidden">Category:</p>
        <p className="inline-block px-1 text-xs text-center truncate border-2 rounded-full font-basis-bold border-hka_gray-border">
          {curriculum.curriculum_type_details.name}
        </p>
      </div>

      {/* action button */}
      <div className="mt-1 lg:mt-0">
        {curriculum.is_already_in_use ? (
          <ActionButton
            type="catalog"
            to={`/trainings/${curriculum.active_training_id}`}
          >
            View
            <span className="icon-arrow right text-hka_blue"></span>
          </ActionButton>
        ) : needsUpgrade ? (
          <UpgradePlanButton parentCpmToTrack={"Catalog -> CurriculumItem"} />
        ) : (
          <button
            type="success"
            value={curriculum.id}
            onClick={onAddTrainingClick}
            className="w-full bg-green-100 sm:w-13 button hover:bg-green-200 text-hka_green"
          >
            <span className="icon-plus text-hka_green"></span>Add
          </button>
        )}
      </div>
    </div>
  );
};

CurriculumItem.propTypes = {
  curriculum: PropTypes.object.isRequired,
  onAddTrainingClick: PropTypes.func.isRequired,
  onOpenPreview: PropTypes.func.isRequired,
  needsUpgrade: PropTypes.bool.isRequired,
};

export default CurriculumItem;
