import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import { prefetchEmployeeDetailsData } from '../../hooks/data-hooks/employees';
import { prefetchEmployeeEngagementsData } from '../../hooks/data-hooks/employeeEngagements.js/useEmployeeEngagementsData';

import { formatAvatar } from '../../utils/formatAvatar';
import LoaderInline from '../LoaderInline';
import { prefetchSimpleEmployeeTrainingsData } from '../../hooks/data-hooks/employeeTrainings';

const staleTime = 30000;
const page = 1;
const pageSize = 5;

const ListItemUserManage = ({
  user,
  groups,
  loadingGroupsCompleted = true,
}) => {
  const queryClient = useQueryClient();

  const onItemHover = () => {
    prefetchEmployeeDetailsData({
      queryClient,
      employeeID: user.id,
      staleTime,
    });
    prefetchEmployeeEngagementsData({
      queryClient,
      employeeID: user.id,
      page,
      pageSize,
      staleTime,
    });
    prefetchSimpleEmployeeTrainingsData({
      queryClient,
      employeeID: user.id,
      page,
      pageSize,
      staleTime,
    });
  };

  return (
    <li className='-py-1' onMouseOver={onItemHover}>
      <Link
        to={`/employees/${user.id}`}
        className='flex justify-center w-full p-2 -my-1 -mr-1 transition-all transform border-b border-hka_gray-light hover:bg-hka_gray-ultraLight md:space-y-0 md:flex-row '
      >
        <div className='flex items-center flex-1'>
          {/* Avatar */}
          {user.avatar_image ? (
            <img
              className='mr-1 rounded-md'
              src={user.avatar_image}
              alt={user.name}
              height={32}
              width={32}
            />
          ) : (
            formatAvatar({
              name: user.name,
              width: 3.2,
              marginRight: 1,
              textSize: 'text-sm',
            })
          )}
          {/* <img className="mr-1 user-img" src={user.avatar_image} alt="" /> */}

          <div className=''>
            {/* User Name */}
            <p className='text-hka_gray-dark font-basis-medium'>{user.name}</p>

            {/* User Name */}
            <p className='text-sm'>{user.email}</p>
          </div>
        </div>

        {/* Loader */}
        {!loadingGroupsCompleted && (
          <LoaderInline wrapperClasses={'min-h-2'} loaderSize={2} />
        )}

        <div className='flex items-center flex-1 w-full'>
          {/* Groups */}
          <div className='flex items-center flex-1'>
            {groups.map((group) => (
              <span
                key={group.id}
                className={`${
                  group.icon || ''
                } bg-hka_gray-light rounded-md mr-0.5 text-hka_gray hover:bg-gray-200 transition-all`}
                data-tip
                data-for={group.name}
              >
                <ReactTooltip id={group.name} effect='solid'>
                  <span>{group.name}</span>
                </ReactTooltip>
              </span>
            ))}
          </div>

          {/* User Level */}
          <div
            className={`flex-1 justify-end level-${
              user.level < 10 ? `0${user.level}` : user.level
            }`}
          >
            <span className='inline-flex text-sm text-hka_gray '>
              Level {user.level}
            </span>
          </div>

          {/* Chevron Icon */}
          <span className='icon-chevron right'></span>
        </div>
      </Link>
    </li>
  );
};

export default ListItemUserManage;
