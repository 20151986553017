import { useEffect, useState } from "react";

const Pagination = ({
  pageSize = 10,
  count,
  currentPage,
  onPageChanged = () => {},
}) => {
  const [previousPage, setPreviousPage] = useState(undefined);
  const [nextPage, setNextPage] = useState(undefined);

  useEffect(() => {
    if (currentPage) {
      setPreviousPage(currentPage <= 1 ? undefined : currentPage - 1);
      setNextPage(
        count - currentPage * pageSize > 0 ? currentPage + 1 : undefined
      );
    }
  }, [count, currentPage, pageSize]);

  return (
    <div className="flex flex-col items-start justify-between pt-1 pb-2 sm:items-center pagination sm:flex-row">
      <div className="flex w-full mb-1 space-x-1 sm:mb-0 sm:w-auto">
        {previousPage && (
          <button
            onClick={() => onPageChanged(previousPage)}
            type="button"
            className="flex-1 button--gray button button__icon--left bg-hka_gray-light"
          >
            <span className="flex icon-arrow left"></span>
            <span className="">Back</span>
          </button>
        )}
        {nextPage && (
          <button
            onClick={() => onPageChanged(nextPage)}
            type="button"
            className="flex-1 button--gray bg-hka_gray-light button button__icon--right"
          >
            <span className="">Next</span>
            <span className="icon-arrow right"></span>
          </button>
        )}
      </div>
      <div className="flex space-x-2">
        <p className="text-sm">
          Total:
          <span className="ml-0.5 font-basis-bold">
            {(count || 0).toLocaleString()}
          </span>
        </p>
        {(previousPage || nextPage) && (
          <p className="text-sm">
            Current Page:
            <span className="ml-0.5 font-basis-bold text-sm">
              {(currentPage || 1).toLocaleString()}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default Pagination;
