import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateEngagementCombinedHandler } from '../../../utils/engagementsUtils';
import { onError } from '../../../libs/errorLib';

export const useUpdateEngagementData = ({ id, onClose }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateEngagementCombinedHandler,
    onMutate: (newData) => {
      queryClient.cancelQueries(['engagement', id]);
      const cacheSnapshot = queryClient.getQueryData(['engagement', id]);
      queryClient.setQueryData(
        ['engagement', id],
        () => newData.companyEngagement
      );
      onClose();
      return cacheSnapshot;
    },
    onError: (_error, _newData, rollback) => {
      onError(_error);
      rollback();
    },
    onSettled: () => {
      queryClient.refetchQueries(['engagement', id]);
      queryClient.invalidateQueries(['company-engagement-schedules', id]);
    },
  });
};
