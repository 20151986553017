import { Fragment, useContext } from "react";
import toast from "react-hot-toast";

import * as api from "../../api";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";

import AssignEmployeesContext from "../../store/assign-employeees-context";
import CreateGroupModal from "../Groups/CreateGroupModal";
import EditGroupModal from "../Groups/EditGroupModal";

const EmployeesModalsContainers = ({
  createGroupModal,
  setCreateGroupModal,
  selectedGroup,
  setSelectedGroup,
  editGroupModal,
  setEditGroupModal,
}) => {
  const { isAuthenticated } = useAppContext();
  const assignEmployeesContext = useContext(AssignEmployeesContext);
  const { groups, setGroups } = assignEmployeesContext;

  const handleOnCreateGroupModalClose = (payload = null) => {
    setCreateGroupModal(false);
    if (!payload) return;

    const { data } = payload;
    setGroups([...groups, data]);
    setSelectedGroup(data);
  };

  const handleOnEditGroupModalClose = (payload = null) => {
    setEditGroupModal(false);
    if (!payload) return;

    const { data } = payload;
    const clone = [...groups];
    const index = clone.findIndex((g) => g.id === data.id);
    clone.splice(index, 1, data);
    setGroups(clone);
    setSelectedGroup(data);
  };

  const handleOnDeleteGroupModalClose = (payload = null) => {
    const { data } = payload;
    setGroups(groups.filter((g) => g.id !== data.id));
    setSelectedGroup(null);
    setEditGroupModal(false);
  };

  const handleGroupDelete = async () => {
    try {
      await api.deleteGroup({
        auth: isAuthenticated,
        id: selectedGroup.id,
      });
      toast.success("Group deleted!");
      handleOnDeleteGroupModalClose({
        data: selectedGroup,
      });
    } catch (error) {
      onError(error);
    }
  };

  return (
    <Fragment>
      <CreateGroupModal
        showModal={createGroupModal}
        onCloseModal={handleOnCreateGroupModalClose}
        assignEmployeesContext={assignEmployeesContext}
      />
      <EditGroupModal
        showModal={selectedGroup && editGroupModal}
        onCloseModal={handleOnEditGroupModalClose}
        onGroupDelete={handleGroupDelete}
        activeGroup={selectedGroup}
        assignEmployeesContext={assignEmployeesContext}
      />
    </Fragment>
  );
};

export default EmployeesModalsContainers;
