import { Fragment, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { useGlobalStore } from '../store';
import { useAppContext } from '../libs/contextLib';
import AssignEmployeesContext from '../store/assign-employeees-context';
import { getCompanyStreamPost } from '../api/streamsApi';
import { useStreamsData } from '../hooks/data-hooks/streams';

import StreamsHeader from '../components/Streams/StreamsHeader';
import Callout from '../components/Callout';
import Stream from '../components/Streams/Streams/Stream';
import Pagination from '../components/Pagination';
import StreamModalsContainer from '../components/Streams/StreamModalsContainer';

const Streams = () => {
  const queryClient = useQueryClient();
  const { isAuthenticated } = useAppContext();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const [expanded, setExpanded] = useState(true);
  const pageSize = 10;
  const { setPreviewPostModal, setPostNotFoundModal } = useGlobalStore(
    (state) => state.streams
  );

  const {
    onImportUsers,
    setObject,
    setObjectType,
    setModalOpened,
    setInternalModalTab,
  } = useContext(AssignEmployeesContext);

  const { data: streamsPageData } = useStreamsData({
    page: currentPage,
  });

  const cStreams = queryClient.getQueriesData(['company-stream']);
  useEffect(() => {
    const initalStateExpanded =
      cStreams.map((data) => data[1]).filter((cStream) => cStream?.is_active)
        .length <= 4;
    setExpanded(initalStateExpanded);
  }, [cStreams]);

  useEffect(() => {
    onImportUsers();
  }, [onImportUsers]);

  useEffect(() => {
    const loadPost = async ({ streamID, postID }) => {
      try {
        const streamPost = await getCompanyStreamPost({
          companyID: isAuthenticated.company,
          streamID,
          postID,
        });
        setPreviewPostModal({
          show: true,
          post: streamPost.post,
          electedText: true,
          header: false,
          approveButton: !streamPost.post.is_sent,
          skipButton: !streamPost.post.is_sent,
          editButton: isAuthenticated.company_details.is_global_content_maker
            ? true
            : (streamPost.post.is_editable || !!streamPost.post.company) &&
              !streamPost.post.is_sent,
        });
      } catch (error) {
        setPostNotFoundModal({ show: true });
      }
    };

    const urlParams = new URLSearchParams(location.search);
    const electedParam = urlParams.get('elected');
    const streamParam = urlParams.get('stream');
    const postParam = urlParams.get('post');

    if (electedParam && !!streamsPageData) {
      loadPost({ streamID: streamParam, postID: postParam });
    }
  }, [
    isAuthenticated.company,
    isAuthenticated.company_details.is_global_content_maker,
    location.search,
    setInternalModalTab,
    setModalOpened,
    setObject,
    setObjectType,
    setPostNotFoundModal,
    setPreviewPostModal,
    streamsPageData,
  ]);

  return (
    <Fragment>
      <StreamsHeader currentPage={currentPage} />

      <main>
        {streamsPageData?.results.length === 0 && (
          <Callout
            title='Haekka'
            message={`No Streams are created yet. ${
              isAuthenticated.company_details.is_global_content_maker
                ? 'Click the Create button above ☝'
                : ''
            }`}
            date={false}
            permanent={true}
          />
        )}

        <div className='space-y-1'>
          {streamsPageData?.results.map((stream) => (
            <Stream
              stream={stream}
              currentPage={currentPage}
              initalStateExpanded={expanded}
              key={stream.id}
            />
          ))}
        </div>

        <Pagination
          pageSize={pageSize}
          count={streamsPageData?.count}
          currentPage={currentPage}
          onPageChanged={(page) => setCurrentPage(page)}
        />
      </main>

      <StreamModalsContainer />
    </Fragment>
  );
};

export default Streams;
