import React, { Fragment, useEffect, useState } from "react";
import ManualUsersSelect from "../AssignEmployees/ManualUsersSelect";
import Modal from "../Modal/Modal";
import ListItems from "../ListItems";
import ListItemGroupModal from "./ListItemGroupModal";
import { createGroup } from "../../api";
import { useAppContext } from "../../libs/contextLib";
import toast from "react-hot-toast";
import { onError } from "../../libs/errorLib";
import AssignEmployeesToGroup from "./AssignEmployeesToGroup";
import ActionButton from "../ActionButton";
import {
  actions,
  views,
  groupIcons,
  formatOptionLabel,
  modes,
  types,
} from "../../utils/groupModalUtils";

export default function CreateGroupModal({
  showModal,
  onCloseModal,
  assignEmployeesContext,
}) {
  const { onImportUsers } = assignEmployeesContext;

  const [name, setName] = useState("");
  const [icon, setIcon] = useState(null);
  const [manager, setManager] = useState(null);
  const [members, setMembers] = useState([]);
  const [channels, setChannels] = useState([]);
  const [slackUserGroups, setSlackUserGroups] = useState([]);
  const [view, setView] = useState(views.GROUP_DETAILS);
  const [allowSubmission, setAllowSubmission] = useState(false);

  const { isAuthenticated } = useAppContext();

  const storeOptions = (options) => {
    const channels = options.filter(({ type }) => type === types.CHANNEL);
    const members = options.filter(({ type }) => type === types.EMPLOYEE);
    const slackGroups = options.filter(
      ({ type }) => type === types.SLACK_USER_GROUP
    );

    setChannels(channels);
    setMembers(members);
    setSlackUserGroups(slackGroups);
  };

  const handleOnSubmit = async () => {
    const payload = {
      name,
      icon,
      manager: manager.value,
      members: members.map(({ value }) => value),
      channels: channels.map(({ value }) => value),
      channelsToSync: channels
        .filter(({ sync }) => sync)
        .map(({ value }) => value),
      slackUserGroups: slackUserGroups.map(({ value }) => value),
    };

    try {
      const action = actions.GROUP_CREATE;
      const data = await createGroup({ auth: isAuthenticated, ...payload });

      toast.success("Group Created!");

      handleOnClose({ data, action });
    } catch (error) {
      onError(error);
    }
  };

  const handleOnClose = (payload = null) => {
    resetState();
    onCloseModal(payload);
  };

  const handleOnRemove = (payload) => {
    const clear = ({ value }) => value !== payload.value;

    // Clear component-level state
    if (payload.type === types.EMPLOYEE) {
      setMembers(members.filter(clear));
    } else if (payload.type === types.CHANNEL) {
      setChannels(channels.filter(clear));
    } else if (payload.type === types.SLACK_USER_GROUP) {
      setSlackUserGroups(slackUserGroups.filter(clear));
    }
  };

  const resetState = () => {
    setName("");
    setIcon(null);
    setManager(null);
    setMembers([]);
    setChannels([]);
    setSlackUserGroups([]);
    setView(views.GROUP_DETAILS);
    setAllowSubmission(false);
  };

  useEffect(() => {
    if (name && manager) {
      setAllowSubmission(true);
    } else {
      setAllowSubmission(false);
    }
  }, [name, manager]);

  useEffect(() => {
    onImportUsers();
  }, [onImportUsers]);

  if (!showModal) return null;

  return (
    <Modal
      show={showModal}
      title="Create A New Group"
      submitButtonText="Create Group"
      onClose={handleOnClose}
      onSubmit={handleOnSubmit}
      submitValid={allowSubmission}
      expandable={true}
    >
      <section>
        <div className="flex mb-2">
          {[views.GROUP_DETAILS, views.GROUP_MEMBERS].map((tab) => (
            <h4
              key={tab}
              className={`tab font-basis-bold mr-2 cursor-pointer ${
                tab === view
                  ? "text-orange border-b-2 pb-0.5 border-current"
                  : "text-hka_gray"
              }`}
              data-tab={tab}
              onClick={() => setView(tab)}
            >
              {tab === views.GROUP_DETAILS ? "Group Details" : "Group Members"}
            </h4>
          ))}
        </div>
        {view === views.GROUP_DETAILS ? (
          <Fragment>
            <p className="mb-2">
              Groups make assigning and organizing trainings incredibly easy.
              With groups, you can cater trainings to specific roles and/or
              teams within your organization.
            </p>
            <div className="mb-2">
              <h4 className="mb-1 font-basis-bold">
                Group Name <span className="text-hka_gray">(Required)</span>
              </h4>
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Group Name"
                value={name}
                className="input focus:outline-none"
                maxLength={30}
              />
            </div>
            <div className="mb-2">
              <h4 className="mb-1 font-basis-bold">
                Assign Manager <span className="text-hka_gray">(Required)</span>
              </h4>
              <ManualUsersSelect
                isMulti={false}
                options={assignEmployeesContext.employees}
                defaultOptions={[manager] || null}
                onChange={setManager}
                formatOptionLabel={formatOptionLabel}
                labelText="Select Manager"
              />
            </div>
            <div className="mb-2">
              <h4 className="mb-1 font-basis-bold">
                Group Icon <span className="text-hka_gray">(Optional)</span>
              </h4>
              <div className="icons">
                {groupIcons.map((groupIcon) => (
                  <span
                    key={groupIcon}
                    className={`cursor-pointer ${groupIcon} ${
                      groupIcon === icon && "text-orange underline"
                    }`}
                    onClick={() => setIcon(groupIcon)}
                  ></span>
                ))}
              </div>
              <div className="my-2">
                <ActionButton
                  type="primary"
                  onClick={() => setView(views.GROUP_MEMBERS)}
                >
                  <span className="hidden sm:block text-hka_blue">
                    Add Employees to this group
                  </span>
                  <span className="sm:hidden icon-arrow right text-hka_blue"></span>
                </ActionButton>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="mb-2">
              <AssignEmployeesToGroup
                defaultOptions={[...members, ...channels, ...slackUserGroups]}
                employees={assignEmployeesContext.employees}
                channels={assignEmployeesContext.channelOptions}
                slackUserGroups={assignEmployeesContext.slackUserGroups || []}
                onChange={storeOptions}
              />
            </div>
            <ListItems>
              {slackUserGroups.length > 0 && (
                <Fragment>
                  <p className="text-hka_gray px-0.5 bg-hka_gray-light text-sm font-basis-bold inline-flex rounded-md mt-2">
                    Slack User Groups
                  </p>
                  {slackUserGroups.map((slackUserGroup, i) => (
                    <ListItemGroupModal
                      mode={modes.CREATE}
                      payload={slackUserGroup}
                      key={`slackUserGroup-${i}`}
                      onRemove={handleOnRemove}
                    />
                  ))}
                </Fragment>
              )}
              {channels.length > 0 && (
                <Fragment>
                  <p className="text-hka_gray px-0.5 bg-hka_gray-light text-sm font-basis-bold inline-flex rounded-md mt-2">
                    Channels
                  </p>
                  {channels.map((channel, i) => (
                    <ListItemGroupModal
                      mode={modes.CREATE}
                      payload={channel}
                      key={`channel-${i}`}
                      onRemove={handleOnRemove}
                    />
                  ))}
                </Fragment>
              )}

              {members.length > 0 && (
                <Fragment>
                  <p className="text-hka_gray px-0.5 bg-hka_gray-light text-sm font-basis-bold inline-flex rounded-md mt-2">
                    Members
                  </p>
                  {members.map((member, i) => (
                    <ListItemGroupModal
                      mode={modes.CREATE}
                      payload={member}
                      key={`member-${i}`}
                      onRemove={handleOnRemove}
                    />
                  ))}
                </Fragment>
              )}
            </ListItems>
          </Fragment>
        )}
      </section>
    </Modal>
  );
}
