import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';

import DropdownMenu from '../../Base/DropdownMenu';
import RenewTrainingModal from '../RenewTrainingModal';
import RemoveEmployeeFromTrainingModal from '../RemoveEmployeeFromTrainingModal';
import CompleteTrainingModal from './CompleteTrainingModal';
import ViewEmployeeResultsModal from './ViewEmployeeResultsModal/ViewEmployeeResultsModal';
import {
  useEmployeeTrainingsBulkUpdate,
  useDownloadEmployeeCertificates,
  useTrainingReminder,
} from '../../../hooks/data-hooks/employeeTrainings';
import { useMutation } from '@tanstack/react-query';
import {
  closeEmployeeTraining,
  renewTraining,
} from '../../../api/employeeTrainingsApi';

const SingleTrainingDropdownOptions = ({
  training,
  employee,
  updateEmployeeList,
  trainingLessons,
  curriculumContent,
}) => {
  const [showRenewTrainingModal, setShowRenewTrainingModal] = useState(false);
  const [showRemoveEmployeeModal, setShowRemoveEmployeeModal] = useState(false);
  const [showCompleteTrainingModal, setShowCompleteTrainingModal] =
    useState(false);
  const [showViewEmployeeResultsModal, setShowViewEmployeeResultsModal] =
    useState(false);

  const { mutate: updateTrainings } = useEmployeeTrainingsBulkUpdate({
    onSuccess: (res) => {
      const { action } = res.data;
      if (action === 'complete_assigments') {
        setShowCompleteTrainingModal(false);
        toast.success(
          `We marked this training as complete with 100% success for ${employee.employee_name}!`
        );
      }
      updateEmployeeList();
    },
  });

  const { mutate: downloadCertificates } = useDownloadEmployeeCertificates({
    cb: () => {
      toast.success(
        `Excellent! The certificate for ${employee.employee_name} will be sent to you via Slack shortly.`
      );
    },
  });

  const { mutate: sendReminder } = useTrainingReminder({
    cb: () =>
      toast.success(`A reminder was sent to ${employee.employee_name}!`),
  });

  const { mutate: removeEmployee } = useMutation({
    mutationFn: closeEmployeeTraining,
    onSuccess: () => {
      updateEmployeeList();
      toast.success(
        `${employee.employee_name} was removed from ${training.curriculum_detail.title}`
      );
    },
  });

  const { mutate: renewEmployeeTraining } = useMutation({
    mutationFn: renewTraining,
    onSuccess: () => {
      toast.success(`Training was renewed!`);
      setShowRenewTrainingModal(false);
      updateEmployeeList();
    },
  });

  const sendReminderHandler = async () => {
    sendReminder({
      receiverUserId: employee?.employee || null,
      curriculumId: training.curriculum_detail.id,
    });
  };

  const removeEmployeeHandler = async () => {
    removeEmployee({
      curriculumId: training.curriculum_detail.id,
      employeeId: employee.employee,
    });
  };

  const downloadCertificateHandler = async () => {
    downloadCertificates({
      trainingID: training.id,
      employees: [employee.employee],
      sendToSlack: true,
    });
  };

  const markAsCompleteHandler = async (action) => {
    updateTrainings({
      trainingID: training.id,
      employees: [employee.employee],
      allEmployees: false,
      action,
    });
  };

  const renewEmployeeTrainingHandler = async () => {
    renewEmployeeTraining({
      employeeID: employee.employee,
      trainingID: training.id,
    });
  };

  const dropMenuActions = [
    {
      icon: 'icon-bell',
      label: 'Remind Employee',
      onClick: sendReminderHandler,
      disabled:
        employee.completed_percentage >= 100 || !employee.employee_slack_uid,
    },
    {
      icon: 'icon-chart',
      label: 'View Results',
      onClick: () => setShowViewEmployeeResultsModal(true),
      disabled: employee.number_of_attempts === 0,
    },
    {
      icon: 'icon-renew',
      label: 'Renew Training',
      onClick: () => setShowRenewTrainingModal(true),
    },
    {
      icon: 'icon-download',
      label: 'Download Certificate',
      onClick: downloadCertificateHandler,
      disabled: employee.completed_percentage < 100,
    },
    {
      icon: 'icon-check',
      label: 'Mark Training as Complete',
      onClick: () => setShowCompleteTrainingModal(true),
      disabled: employee.completed_percentage >= 100,
    },
    {
      icon: 'icon-trash',
      label: 'Remove Employee',
      onClick: () => setShowRemoveEmployeeModal(true),
    },
  ];

  return (
    <Fragment>
      <DropdownMenu actions={dropMenuActions} />

      <RenewTrainingModal
        showModal={showRenewTrainingModal}
        employee={employee}
        onSubmit={renewEmployeeTrainingHandler}
        onClose={() => setShowRenewTrainingModal(false)}
      />
      <RemoveEmployeeFromTrainingModal
        showModal={showRemoveEmployeeModal}
        employee={employee}
        onSubmit={removeEmployeeHandler}
        onClose={() => setShowRemoveEmployeeModal(false)}
      />
      {showCompleteTrainingModal && (
        <CompleteTrainingModal
          showModal={showCompleteTrainingModal}
          employee={employee}
          onSubmit={() => markAsCompleteHandler('complete_assigments')}
          onClose={() => setShowCompleteTrainingModal(false)}
        />
      )}
      {employee && showViewEmployeeResultsModal && (
        <ViewEmployeeResultsModal
          showModal={true}
          employee={employee}
          lessons={trainingLessons}
          curriculumContent={curriculumContent}
          onClose={() => setShowViewEmployeeResultsModal(false)}
        />
      )}
    </Fragment>
  );
};

export default SingleTrainingDropdownOptions;
