import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useAppContext } from '../../../libs/contextLib';
import {
  useCompanyStreamData,
  useUpdateStreamSubscription,
} from '../../../hooks/data-hooks/streams';

import { useQueryClient } from '@tanstack/react-query';

import OwnersInput from '../../Base/OwnersInput';
import Modal from '../../Modal/Modal';

const ManageOwnersModal = ({ show = true, onClose, stream }) => {
  const { isAuthenticated } = useAppContext();
  const queryClient = useQueryClient();

  const [owners, setOwners] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  const { data: companyStream } = useCompanyStreamData({
    companyID: isAuthenticated.company,
    streamID: stream.id,
  });

  const { mutate: updateStreamSubscription, isLoading: submitting } =
    useUpdateStreamSubscription({
      onSuccess: () => {
        queryClient.invalidateQueries([
          'company-stream',
          isAuthenticated.company,
          stream.id,
        ]);
        toast.success(`Stream owners updated!`);
        onClose();
      },
    });

  useEffect(() => {
    if (!!companyStream) {
      setOwners(
        companyStream.owners.map((o) => ({ value: o.id, label: o.name }))
      );
    }
  }, [companyStream]);

  useEffect(() => {
    setIsFormValid(!submitting && owners.length > 0);
  }, [owners.length, submitting]);

  const submitHandler = () => {
    updateStreamSubscription({
      companyID: isAuthenticated.company,
      streamID: stream.id,
      payload: { owners: owners.map((o) => o.value) },
    });
  };

  return (
    <Modal
      show={show}
      title='Manage Stream Owners'
      onClose={onClose}
      onSubmit={submitHandler}
      submitButtonText='Submit'
      submitValid={isFormValid}
      expandable
    >
      <section className='mb-3 min-h-14'>
        <OwnersInput
          owners={owners}
          setOwners={setOwners}
          instanceOf={'stream'}
        />
      </section>
    </Modal>
  );
};

export default ManageOwnersModal;
