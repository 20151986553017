import { formatTrainingDate, formatDaysTillDue } from './formatTrainingDate';
import { addMonths, addYears, parseISO } from 'date-fns';
import TRAINING_RENEW_OPTIONS from './renewOptions';

export const formatMinimalDaysTillDueDate = (daysUntillDue) => {
  return daysUntillDue
    ? `Due ${formatDaysTillDue(daysUntillDue)} days after assignment.`
    : 'Days until due not set.';
};

export const formatMinimalRenewDateMessage = (renewCycle) => {
  return (
    TRAINING_RENEW_OPTIONS.find(({ id }) => renewCycle === id).title ||
    TRAINING_RENEW_OPTIONS.find(({ id }) => id === 'none')
  );
};

export const formatDaysTillDueDate = (daysTillDue, endDate, renewCycle) => {
  let returnString = 'Days until due not set.';

  if (daysTillDue && renewCycle.localeCompare('none') === 0) {
    returnString = ` Due ${formatDaysTillDue(
      daysTillDue
    )} days after assignment.`;
  } else if (daysTillDue && renewCycle.localeCompare('annual') === 0) {
    returnString = ` Due annually ${formatDaysTillDue(
      daysTillDue
    )} days after assignment.`;
  } else if (daysTillDue && renewCycle.localeCompare('semi-annual') === 0) {
    returnString = ` Due semi-annually ${formatDaysTillDue(
      daysTillDue
    )} days after assignment.`;
  } else if (daysTillDue && renewCycle.localeCompare('quarterly') === 0) {
    returnString = ` Due quarterly ${formatDaysTillDue(
      daysTillDue
    )} days after assignment.`;
  }
  if (endDate && renewCycle.localeCompare('none') === 0) {
    returnString = ` Due on ${formatTrainingDate(endDate)}.`;
  } else if (endDate && renewCycle.localeCompare('annual') === 0) {
    returnString = ` Due annually on ${formatTrainingDate(endDate)}.`;
  } else if (endDate && renewCycle.localeCompare('semi-annual') === 0) {
    returnString = ` Due semi-annually on ${formatTrainingDate(
      addMonths(new Date(endDate), 6)
    )} and ${formatTrainingDate(addYears(new Date(endDate), 1))}.`;
  } else if (endDate && renewCycle.localeCompare('quarterly') === 0) {
    returnString = ` Due quarterly on ${formatTrainingDate(
      addMonths(new Date(endDate), 3)
    )}, ${formatTrainingDate(
      addMonths(new Date(endDate), 6)
    )}, ${formatTrainingDate(
      addMonths(new Date(endDate), 9)
    )}, and ${formatTrainingDate(addYears(new Date(endDate), 1))}.`;
  }

  return returnString;
};

export const formatRenewDateMessage = (renewCycle, startDate) => {
  let returnString = 'Training does not renew.';
  if (renewCycle.localeCompare('annual') === 0) {
    returnString = ` Renews every 12 months (${formatTrainingDate(
      addYears(new Date(startDate), 1)
    )}).`;
  } else if (renewCycle.localeCompare('semi-annual') === 0) {
    returnString = ` Renews every 6 months (${formatTrainingDate(
      addMonths(new Date(startDate), 0)
    )} and ${formatTrainingDate(addMonths(new Date(startDate), 6))}).`;
  } else if (renewCycle.localeCompare('quarterly') === 0) {
    returnString = ` Renews every 3 months (${formatTrainingDate(
      addMonths(new Date(startDate), 0)
    )}, ${formatTrainingDate(
      addMonths(new Date(startDate), 3)
    )}, ${formatTrainingDate(
      addMonths(new Date(startDate), 6)
    )}, and ${formatTrainingDate(addMonths(new Date(startDate), 9))}).`;
  }

  return returnString;
};

export const formatTrainingEndDate = (
  startDateStr,
  renewCycleStr,
  endDateStr,
  daysTillDueStr
) => {
  const startDate = startDateStr ? parseISO(startDateStr) : undefined;
  const endDate = endDateStr ? parseISO(endDateStr) : undefined;
  const daysTillDue =
    daysTillDueStr && +daysTillDueStr !== 0 ? daysTillDueStr : undefined;
  const renewCycle = String(renewCycleStr);
  const payload = {
    message: formatDaysTillDueDate(daysTillDue, endDate, renewCycle),
  };

  const renewDateString = formatRenewDateMessage(renewCycle, startDate);

  payload.message += renewDateString;

  if (endDate && renewCycle.localeCompare('web_training') === 0) {
    payload.message = ` Due on ${formatTrainingDate(endDate)}.`;
  } else if (renewCycle.localeCompare('web_training') === 0) {
    payload.message = ' No Due Date Assigned.';
  } else if (renewCycleStr === 'none') {
    payload.message = ' Training does not renew.';
  }
  return payload.message;
};
