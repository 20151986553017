import React from "react";
import { Link } from "react-router-dom";

const ActionButton = ({
  type,
  to,
  onClick,
  disabled,
  children,
  value,
  rounded = false,
  customCss = null,
}) => {
  let buttonClass = "";
  switch (type) {
    case "button--icon":
      buttonClass = "h-4 w-4 leading-4";
      break;
    case "responsive--blue":
      buttonClass =
        "button px-0 md:px-2 text-hka_blue bg-hka_blue-light hover:bg-blue-200";
      break;
    case "responsive--full":
      buttonClass =
        "button w-full sm:w-auto px-0 sm:px-2 lg:pl-1.5 text-hka_blue bg-hka_blue-light hover:bg-blue-200";
      break;
    case "catalog":
      buttonClass =
        "button w-full sm:w-13 px-0 sm:px-2 lg:pl-1.5 text-hka_blue bg-hka_blue-light hover:bg-blue-200";
      break;
    case "primary":
      buttonClass = "button text-hka_blue bg-hka_blue-light hover:bg-blue-200 font-basis-medium";
      break;
    case "success":
      buttonClass = "button bg-green-100 hover:bg-green-200 text-hka_green";
      break;
    case "secondary":
      buttonClass = "button text-hka_gray bg-hka_gray-light hover:bg-gray-300";
      break;
    case "danger":
      buttonClass = "button bg-hka_red-light text-hka_red hover:bg-red-200";
      break;
    case "warning":
      buttonClass = "button bg-orange-light text-orange hover:bg-orange-hover";
      break;
    case "dotted":
      buttonClass =
        "button text-hka_gray hover:bg-gray-300 border border-hka_gray border-dashed";
      break;
    case "solidBorder":
      buttonClass =
        "button text-hka_gray hover:bg-gray-300 border border-hka_gray border-solid";
      break;
    case "defaultNoIcon":
      buttonClass =
        "button text-hka_blue leading-4 bg-hka_blue-light hover:bg-blue-200 min-w-4 px-2 w-full sm:w-auto";
      break;
    default:
      buttonClass = "button button--blue";
      break;
  }

  if (rounded) {
    buttonClass = `${buttonClass} rounded-full px-0`;
  }

  if (customCss) {
    buttonClass += ` ${customCss}`;
  }

  if (to !== undefined) {
    return (
      <Link to={to} className={buttonClass}>
        {children}
      </Link>
    );
  }

  if (typeof onClick === "function") {
    return (
      <button
        value={value}
        onClick={onClick}
        disabled={disabled}
        className={`${buttonClass}`}
      >
        {children}
      </button>
    );
  }

  return (
    <button type="button" className={buttonClass} disabled={disabled}>
      {children}
    </button>
  );
};

export default ActionButton;
