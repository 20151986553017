import { toast } from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { sendEngagementNow } from "../../../api/engagementsApi";

export const useSendEngagementNowMutation = ({
  enabled = true,
  companyEngagementID,
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: sendEngagementNow,
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries([
          "company-engagement-schedules",
          companyEngagementID,
        ]);
      }, 1500);
      toast.success("Engagement sent successfully!");
    },
    enabled,
  });
};
