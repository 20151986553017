import React, { useState, useMemo, Fragment, useEffect } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';

import 'react-circular-progressbar/dist/styles.css';

import useDebounce from '../../../utils/custom-hooks/useDebounce';
import { useAppContext } from '../../../libs/contextLib';

import Pagination from '../../Pagination';
import ListItems from '../../ListItems';

import LoaderInline from '../../LoaderInline';

import RemindAllEmployeesModal from '../RemindAllEmployeesModal';
import {
  useEmployeeTrainingsData,
  useTrainingReminder,
} from '../../../hooks/data-hooks/employeeTrainings';
import { useQueryClient } from '@tanstack/react-query';
import EmployeeTrainingItem from './EmployeeTrainingItem';
import ListHeader from './ListHeader';

export const FILTER_OPTIONS = [
  { value: null, label: 'All Trainings' },
  {
    value: 'training_complete',
    label: 'Completed Trainings',
  },
  {
    value: 'training_incomplete',
    label: 'Incomplete Trainings',
  },
];

export const SORT_OPTIONS = [
  {
    value: 'start_date',
    label: 'Assigned Date (Ascending)',
  },
  {
    value: '-start_date',
    label: 'Assigned Date (Descending)',
  },
  {
    value: 'due_date',
    label: 'Due Date (Ascending)',
  },
  {
    value: '-due_date',
    label: 'Due Date (Descending)',
  },
];

function ListTrainingUsers({
  training,
  setShowAssignEmployeesModal,
  trainingLessons,
  curriculumContent,
  assessmentOnlyTraining,
}) {
  const queryClient = useQueryClient();
  const { isAuthenticated } = useAppContext();

  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[1].value);
  const [filterOption, setFilterOption] = useState(FILTER_OPTIONS[0].value);
  const [showRemindAllEmployeesModal, setShowRemindAllEmployeesModal] =
    useState(false);
  const [allEmployeesSelected, setAllEmployeesSelected] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const debaouncedValue = useDebounce(searchQuery, 300);

  const { data: curriculumEmployees, isLoading: loadingEmployees } =
    useEmployeeTrainingsData({
      companyId: isAuthenticated.company,
      curriculumId: training.curriculum_detail.id,
      page: currentPage,
      query: debaouncedValue,
      filter: filterOption,
      sort: sortOption,
    });

  const { mutate: sendReminder } = useTrainingReminder({
    cb: () => {
      toast.success('All reminders sent!');
      setShowRemindAllEmployeesModal(false);
    },
  });

  useEffect(() => {
    if (curriculumEmployees && allEmployeesSelected) {
      const employeeIDs = curriculumEmployees.results.map(
        (employee) => employee.employee
      );
      setSelectedEmployees(employeeIDs);
    }
  }, [allEmployeesSelected, curriculumEmployees]);

  const completed_percentage = useMemo(() => {
    return training?.total_employees === 0
      ? 0
      : Math.round(
          (training.employees_finished_count / training.total_employees) * 100
        );
  }, [training]);

  const remindAllEmployeesHandler = () => {
    sendReminder({
      receiverUserId: null,
      curriculumId: training.curriculum_detail.id,
    });
  };

  const listChangedHandler = () => {
    queryClient.invalidateQueries([
      'employee-trainings',
      isAuthenticated.company,
      training.curriculum_detail.id,
      currentPage,
      debaouncedValue,
      filterOption,
      sortOption,
    ]);
  };

  const menuItems = [
    {
      icon: 'icon-add-users',
      label: 'Assign Employees',
      onClick: setShowAssignEmployeesModal,
    },
  ];

  if (completed_percentage < 100 && curriculumEmployees?.count > 0) {
    menuItems.push({
      icon: 'icon-bell',
      label: 'Remind All Employees',
      onClick: () => setShowRemindAllEmployeesModal(true),
    });
  }

  if (loadingEmployees) return <LoaderInline />;

  return (
    <Fragment>
      <ListHeader
        allEmployeesSelected={allEmployeesSelected}
        setAllEmployeesSelected={setAllEmployeesSelected}
        setSelectedEmployees={setSelectedEmployees}
        curriculumEmployees={curriculumEmployees}
        training={training}
        selectedEmployees={selectedEmployees}
        listChangedHandler={listChangedHandler}
        setSortOption={setSortOption}
        setFilterOption={setFilterOption}
        setSearchQuery={setSearchQuery}
        setCurrentPage={setCurrentPage}
        menuItems={menuItems}
      />

      <ListItems className='training--assigned-employees'>
        {curriculumEmployees.results.map((user) => (
          <EmployeeTrainingItem
            key={user.employee}
            user={user}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
            setAllEmployeesSelected={setAllEmployeesSelected}
            training={training}
            listChangedHandler={listChangedHandler}
            trainingLessons={trainingLessons}
            curriculumContent={curriculumContent}
            assessmentOnlyTraining={assessmentOnlyTraining}
          />
        ))}
      </ListItems>

      <Pagination
        pageSize={10}
        count={curriculumEmployees.count}
        currentPage={currentPage}
        onPageChanged={(page) => setCurrentPage(page)}
      />

      <RemindAllEmployeesModal
        showModal={showRemindAllEmployeesModal}
        onSendReminder={remindAllEmployeesHandler}
        onClose={() => setShowRemindAllEmployeesModal(false)}
      />
    </Fragment>
  );
}

ListTrainingUsers.propTypes = {
  training: PropTypes.object.isRequired,
};

export default ListTrainingUsers;
