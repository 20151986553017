import React, { useState, useEffect, useCallback } from 'react';
import { useAppContext } from '../libs/contextLib';
import * as api from '../api';
import { onError } from '../libs/errorLib';
import Loader from '../components/Loader';
import CreateTrainingModal from '../components/CreateTrainingModal';
import CurriculumItem from '../components/Catalog/CurriculumItem';
import Filter from '../components/Catalog/Filter';
import TrainingPreviewModal from '../components/Catalog/TrainingPreviewModal';
import { useLocation } from 'react-router-dom';
import Pagination from '../components/Pagination';
import useDebounce from '../utils/custom-hooks/useDebounce';
import TransitionWrapper from '../components/Base/TransitionWrapper';
import { useTrainingsTagsData } from '../hooks/data-hooks/trainings';

const Catalog = () => {
  const PAGE_SIZE = 10;

  const { isAuthenticated } = useAppContext();

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [curriculums, setCurriculums] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesIds, setCategoriesIds] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [authorsIds, setAuthorsIds] = useState([]);
  const [videoIncluded, setVideoIncluded] = useState(false);
  const [selectedCurriculumId, setSelectedCurriculumId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [createTrainingModalShow, setCreateTrainingModalShow] = useState(false);
  const [selectedCurriculum, setSelectedCurriculum] = useState(null);
  const [openTrainingPreviewModal, setOpenTrainingPreviewModal] =
    useState(true);
  const [curriculumTypes, setCurriculumTypes] = useState([]);
  const [allCurriculums, setAllCurriculums] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(null);

  const location = useLocation();
  const debouncedValue = useDebounce(searchQuery, 300);

  const [currentPage, setCurrentPage] = useState(1);

  const { data: tags } = useTrainingsTagsData({
    companyID: isAuthenticated.company,
    enabled: isAuthenticated.is_admin
  });

  const loadCurriculums = useCallback(
    (page, title, authors, categories, includesVideoContent) => {
      return api.curriculums({
        auth: isAuthenticated,
        includeActive: true,
        includeLessons: true,
        pageSize: PAGE_SIZE,
        title,
        page,
        authors,
        categories,
        includesVideoContent,
        includeCustomCurriculums: false,
      });
    },
    [isAuthenticated]
  );

  useEffect(() => {
    const onLoad = async () => {
      try {
        setIsLoading(true);

        const authors = await api.getCurriculumAuthors({
          auth: isAuthenticated,
        });

        const { results: categories } = await api.curriculumTypes({
          auth: isAuthenticated,
          includeTitles: false,
          pageSize: 1000000,
        });

        setCurriculumTypes(categories);

        const categoryOptions = [];

        categories.forEach((category) => {
          categoryOptions.push({ ...category, selected: false });
        });

        setCategories(categoryOptions);
        setAuthors([
          ...authors
            .filter((author) => author)
            .map((author) => ({
              name: author,
              id: author,
              selected: false,
            })),
        ]);

        const unsortedCurriculums = await api.allCurriculums({
          auth: isAuthenticated,
        });

        const sortedCurriculums = unsortedCurriculums.sort((a, b) => {
          return a.title.localeCompare(b.title, 'en', { sensitivity: 'base' });
        });

        setAllCurriculums(sortedCurriculums);

        if (isInitialLoad) {
          setIsInitialLoad(false);
        }
      } catch (error) {
        onError(error);
      } finally {
        setIsLoading(false);
      }
    };

    onLoad();
  }, [isAuthenticated, isInitialLoad]);

  useEffect(() => {
    async function load() {
      try {
        setIsLoading(true);

        const { results: curriculums, count } = await loadCurriculums(
          currentPage,
          searchQuery,
          authorsIds,
          categoriesIds,
          videoIncluded
        );

        setCount(count);

        setCurriculums(
          curriculums.map((c) => {
            return {
              ...c,
              show: true,
              author_name: c.author_name ? c.author_name : 'unknown',
            };
          })
        );
      } catch (e) {
        onError(e);
      } finally {
        setIsLoading(false);
      }
    }

    load();
  }, [
    isAuthenticated,
    location,
    page,
    debouncedValue,
    authorsIds,
    categoriesIds,
    videoIncluded,
    currentPage,
    loadCurriculums,
    searchQuery,
  ]);

  useEffect(() => {
    setPage(new URLSearchParams(location.search).get('page') || 1);
  }, [location.search]);

  const onTrainingCreate = (newTraining) => {
    let curriculumsCopy = [...curriculums];
    curriculumsCopy = curriculumsCopy.map((curriculum) => {
      if (newTraining.curriculum_detail.id === curriculum.id) {
        curriculum.active_training_id = newTraining.id;
        curriculum.is_already_in_use = true;
      }
      return curriculum;
    });
    setCurriculums(curriculumsCopy);
  };

  const handleAddTrainingClick = (curriculumId) => {
    setSelectedCurriculumId(curriculumId);
    setCreateTrainingModalShow(true);
  };

  const onUpdate = (e, target) => {
    // Reset page counter when filters change
    setPage(1);

    if (target === 'AUTHOR') {
      const included = authorsIds.includes(e.target.value);
      if (included) {
        setAuthorsIds(
          authorsIds.filter((authorId) => authorId !== e.target.value)
        );
      } else {
        setAuthorsIds([...authorsIds, e.target.value]);
      }
      setAuthors((prev) =>
        prev.map((a) => {
          if (a.id === e.target.value) {
            return { ...a, selected: !a.selected };
          } else {
            return a;
          }
        })
      );
    } else if (target === 'CATEGORY') {
      const included = categoriesIds.includes(e.target.value);
      if (included) {
        setCategoriesIds(
          categoriesIds.filter((categoryId) => categoryId !== e.target.value)
        );
      } else {
        setCategoriesIds([...categoriesIds, e.target.value]);
      }
      setCategories((prev) =>
        prev.map((a) => {
          if (a.id === e.target.value) {
            return { ...a, selected: !a.selected };
          } else {
            return a;
          }
        })
      );
    } else if (target === 'VIDEO_INCLUDED') {
      setVideoIncluded((p) => !p);
    } else if (target === 'SEARCH_QUERY') {
      setSearchQuery(e.target.value);
    }
  };

  const handleOpenPreview = (curriculum) => {
    setSelectedCurriculum(curriculum);
    setOpenTrainingPreviewModal(true);
  };

  if (isLoading && isInitialLoad) {
    return (
      <TransitionWrapper show={true}>
        <Loader fullscreen />
      </TransitionWrapper>
    );
  }

  return (
    <>
      <header className='flex justify-between w-full mb-2'>
        <h1 className='flex-1'>Catalog</h1>
      </header>
      <main>
        <div className='flex flex-col space-y-0.5 lg:space-y-0 lg:space-x-1 lg:flex-row mb-1 lg:mb-0'>
          <div className='flex items-center flex-1 pl-0.5 h-4 bg-white rounded-lg lg:flex-none border border-hka_gray-border'>
            <span className='icon-search text-hka_gray mt-0.2'></span>
            <input
              className='h-3 text-base border-none min-w-20 max-h-3.8 focus:outline-none'
              type='text'
              value={searchQuery}
              onChange={(e) => onUpdate(e, 'SEARCH_QUERY')}
              placeholder='Search Trainings'
            />
          </div>
          {authors.length > 0 && (
            <Filter
              forceListView
              label='Author'
              options={authors}
              onUpdate={(e) => onUpdate(e, 'AUTHOR')}
            />
          )}
          {categories.length > 0 && (
            <Filter
              label='Category'
              options={categories}
              onUpdate={(e) => onUpdate(e, 'CATEGORY')}
            />
          )}
          <Filter
            label='Includes Video Content'
            options={[videoIncluded]}
            onUpdate={(e) => onUpdate(e, 'VIDEO_INCLUDED')}
          />
        </div>

        <div className='hidden pt-2 pb-1 pr-2 space-x-2 lg:flex opacity-60'>
          <p className='w-10 text-xs text-center font-basis-medium'>AUTHOR</p>
          <p className='flex-1 text-xs font-basis-medium'>TITLE</p>
          <p className='w-10 text-xs text-center font-basis-medium'>LESSONS</p>
          <p className='w-10 text-xs font-basis-medium'>CATEGORY</p>
          <p className='text-xs w-13 font-basis-medium'></p>
        </div>
        <div className='space-y-1'>
          {curriculums.map((curriculum) => (
            <CurriculumItem
              onOpenPreview={(curriculum) => handleOpenPreview(curriculum)}
              key={curriculum.id}
              curriculum={curriculum}
              onAddTrainingClick={() => handleAddTrainingClick(curriculum.id)}
              needsUpgrade={
                !isAuthenticated.company_details.all_content &&
                !curriculum.is_available_for_free
              }
            />
          ))}
        </div>
        <div className='my-1'>
          <Pagination
            pageSize={PAGE_SIZE}
            count={count}
            currentPage={currentPage}
            onPageChanged={(page) => setCurrentPage(page)}
          />
        </div>
      </main>

      {selectedCurriculum && (
        <TrainingPreviewModal
          showModal={openTrainingPreviewModal}
          onClose={() => setOpenTrainingPreviewModal(false)}
          selectedCurriculum={selectedCurriculum}
          onAddTrainingClick={(curiculumId) =>
            handleAddTrainingClick(curiculumId)
          }
        />
      )}

      {createTrainingModalShow && (
        <CreateTrainingModal
          showModal={createTrainingModalShow}
          onTrainingCreate={onTrainingCreate}
          trainingTags={tags.results}
          curriculumTypes={curriculumTypes}
          allCurriculums={allCurriculums}
          defaultCurriculum={curriculums.find(
            (item) => item.id === selectedCurriculumId
          )}
          key={selectedCurriculumId}
          onClose={() => {
            setCreateTrainingModalShow(false);
            setSelectedCurriculumId(null);
          }}
        />
      )}
    </>
  );
};

Catalog.propTypes = {};

export default Catalog;
