import React, { Fragment, useContext, useEffect, useState } from "react";
import SlackOptions from "../../../AssignEmployees/SlackOptions";
import ManuallyOptions from "../../../AssignEmployees/ManuallyOptions";
import { SLACK_LOGO } from "../../../../utils/constants";
import AssignEmployeesContext from "../../../../store/assign-employeees-context";

const SOURCES = {
  SLACK: { label: "Slack", url: SLACK_LOGO },
  MANUALLY: { label: "Manually", icon: "users" },
};

const AssignUsersModalFragmentHeader = ({
  selectedSource,
  setSelectedSource,
}) => {
  const activeSourceStyles =
    "border-hka_blue bg-hka_blue-light bg-opacity-50 text-hka_blue";
  const inactiveSourceStyles = "border-hka_gray-border";

  return (
    <Fragment>
      <h4 className="mb-1 font-basis-bold">
        Select how you want to add employees
      </h4>
      <div className="flex mb-3 space-x-2">
        {Object.keys(SOURCES).map((source) => {
          const sourceData = SOURCES[source];
          return (
            <div
              key={sourceData.label}
              onClick={() => {
                setSelectedSource(source);
              }}
              className={`font-basis-bold cursor-pointer w-full ${
                source === selectedSource ? "" : ""
              }`}
            >
              <div
                className={`border ${
                  source === selectedSource
                    ? activeSourceStyles
                    : inactiveSourceStyles
                } border-opacity-80 rounded-lg flex flex-col items-center p-1 pt-1.2`}
              >
                {sourceData.url ? (
                  <img
                    style={{ cursor: "pointer" }}
                    alt={sourceData.label}
                    height={30}
                    width={30}
                    src={sourceData.url}
                    className="mb-0.5"
                  />
                ) : (
                  <span
                    className={`icon-${sourceData.icon} icon-xl mb-0.5 ${
                      source === selectedSource
                        ? "text-hka_blue"
                        : "text-hka_gray"
                    }`}
                  ></span>
                )}

                <div
                  className={`text-base font-basis-bold ${
                    source === selectedSource
                      ? "text-hka_blue"
                      : "text-hka_gray"
                  }`}
                >
                  {sourceData.label}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default function AssignUsersModalFragment({ campaign }) {
  const [selectedSource, setSelectedSource] = useState(null);

  const { channelOptions, setSlackAutoEnroll, setSelectedChannelsSync } =
    useContext(AssignEmployeesContext);

  useEffect(() => {
    const onCampaignChange = () => {
      setSlackAutoEnroll(campaign.slack_auto_enroll);

      const campaignSelectedChannelsSync = channelOptions.filter(
        ({ value }) => campaign?.channels_to_sync?.includes(value) || false
      );
      setSelectedChannelsSync(campaignSelectedChannelsSync);
    };

    if (campaign) {
      onCampaignChange();
    }
  }, [campaign]);

  return (
    <Fragment>
      <AssignUsersModalFragmentHeader
        selectedSource={selectedSource}
        setSelectedSource={setSelectedSource}
      />
      {selectedSource === "SLACK" && <SlackOptions />}
      {selectedSource === "MANUALLY" && <ManuallyOptions />}
    </Fragment>
  );
}
