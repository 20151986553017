import React from "react";
import { formatTrainingEndDate } from "../../utils/formatTrainingEndDate";
import "./TrainingItemWithStats.css";
import ProgressBar from "@ramonak/react-progress-bar";

const TrainingItemWithStats = ({ training }) => {
  return (
    <li className="flex-col items-start px-2 bg-white rounded-lg sm:items-center shadow-light sm:flex-row">
      <section className="w-full py-1 pr-2 mb-1 truncate sm:w-1/2 sm:mb-0">
        <p className="truncate text-hka_gray-dark font-basis-medium">
          📚 {training.curriculum_title}
        </p>
        <p className="flex-1 text-sm truncate">
          {formatTrainingEndDate(
            training.renew_date ? training.renew_date : training.start_date,
            training.renew_cycle,
            training.end_date,
            training.days_till_due
          )}
        </p>
      </section>

      <section className="flex items-center w-full sm:w-1/2">
        <section className="flex mr-4 sm:mr-0">
          <p className="mr-1 text-sm opacity-70 font-basis-medium">Grade</p>
          <p className="text-sm font-basis-medium text-hka_gray-dark">
            {training.latest_grade ? training.latest_grade + `%` : "—"}
          </p>
        </section>

        <section className="flex flex-col w-2/3 sm:items-end sm:w-full">
          <div className="w-16">
            <ProgressBar
              completed={training.completed_percentage}
              isLabelVisible={false}
              width={"100%"}
              height={"0.5rem"}
              borderRadius={"0.25rem"}
              baseBgColor={"#EFF0F7"}
              bgColor={"#F99A00"}
            />
            {training.curriculum_action_id === "branching" &&
            training.completed_percentage === 100 ? (
              <p className="text-xs font-basis-medium">
                {training.completed_lessons} of {training.completed_lessons}{" "}
                lessons complete
              </p>
            ) : (
              <p className="text-xs font-basis-medium">
                {training.completed_lessons} of {training.total_lessons} lessons
                complete
              </p>
            )}
          </div>
        </section>
      </section>
    </li>
  );
};

export default TrainingItemWithStats;
