import React from 'react';
import Modal from '../../Modal/Modal';

export default function CurriculumItemDeleteConfirmModal({
  showModal,
  itemType,
  onSubmit,
  onClose,
}) {
  return (
    <Modal
      show={showModal}
      title={`Remove ${itemType}`}
      submitButtonText={`Remove ${itemType}`}
      submitButtonType='danger'
      onSubmit={onSubmit}
      onClose={onClose}
      compact
    >
      <p>Are you sure you want to remove this {itemType}?</p>
    </Modal>
  );
}
