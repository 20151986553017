import { format } from 'date-fns';

import { useGlobalStore } from '../../../store';
import { useAppContext } from '../../../libs/contextLib';

import TrainingTagButton from '../../TrainingTag/TrainingTagButton';

const Post = ({ post, reviewPosts }) => {
  const { isAuthenticated } = useAppContext();
  const isGlobalContentWriter =
    isAuthenticated.company_details.is_global_content_maker;

  const {
    setPreviewPostModal,
    setPostModal,
    setCustomPostConfirmModal,
    setDeletePostModal,
    setDeleteCustomPostConfirmModal,
  } = useGlobalStore((state) => state.streams);

  const sendTime = new Date(post.send_time);
  const date = format(sendTime, 'PP');
  const time = format(sendTime, 'h:mm b');

  const postCategoryTag = { name: post.category.title };

  const editPostHandler = () => {
    if (isGlobalContentWriter) {
      setPostModal({
        show: true,
        mode: 'EDIT_POST',
        post,
        stream: post.stream,
      });
    }

    if (!isGlobalContentWriter) {
      if (!post.is_editable) {
        return;
      }
      if (post.is_sent) {
        return;
      }

      // edit custom post
      if (post.company) {
        setPostModal({
          show: true,
          mode: 'EDIT_POST',
          post,
          stream: post.stream,
        });
      }

      // convert to custom post confirmation modal
      if (!post.company) {
        setCustomPostConfirmModal({ show: true, post });
      }
    }
  };

  const previewPostHandler = () => {
    setPreviewPostModal({
      show: true,
      post,
      electedText: false,
      header: true,
      approveButton: false,
      skipButton: false,
      editButton:
        isGlobalContentWriter ||
        (post.is_sent ? false : post.is_editable || post.company),
    });
  };

  let dateAndStatsSection = undefined;
  const approvalStatus = post.approvalStatus;
  const isSentForReview = post.isSentForReview;

  if (!isGlobalContentWriter && !post.is_sent) {
    dateAndStatsSection = (
      <>
        <div className='mr-1 -ml-0.5 flex items-center'>
          <i className='icon-calendar'></i>Will send on {date}
        </div>
      </>
    );
  } else if (post.is_sent) {
    dateAndStatsSection = (
      <>
        <div className='-ml-0.5 flex items-center absolute top-2 right-2'>
          <i className='icon-check'></i>Sent on {date}
        </div>
        <div className='mr-1.5 flex items-center'>
          <i className='icon-users'></i>
          {post.delivered} {post.send_to_admins_only ? 'Admins' : 'Users'}
        </div>
        {post.acknowledge_button && (
          <div
            className='mr-1.5 flex items-center'
            data-tip='Post Acknowledgement'
          >
            <i className='icon-training-completion'></i>
            {Math.round(post.ack)}%
          </div>
        )}
        {post.include_rating && (
          <div
            className='mr-1.5 -ml-0.5 flex items-center'
            data-tip='Post Rating (1-3)'
          >
            <i className='icon-star'></i>
            {Math.round(post.rate)} ({post.voted} voted)
          </div>
        )}
      </>
    );
  } else if (isGlobalContentWriter && !post.is_sent) {
    dateAndStatsSection = (
      <>
        <div className='mr-1 -ml-0.5 flex items-center'>
          <i className='icon-calendar'></i> {date}
        </div>
        <div className='flex items-center'>
          <i className='icon-clock'></i> {time}
        </div>
      </>
    );
  }

  let actionButtonsSection = undefined;

  if (isGlobalContentWriter && !post.is_sent) {
    actionButtonsSection = (
      <>
        <span
          className='button--small cursor-pointer hover:bg-hka_gray-light mr-0.5'
          onClick={editPostHandler}
          data-tip='Edit Post'
        >
          <i className='text-hka_gray icon-cog'></i>
        </span>
        <span
          className='relative transition-all rounded-lg cursor-pointer hover:bg-hka_gray-light'
          onClick={() => setDeletePostModal({ show: true, postID: post.id })}
          data-tip='Delete Post'
        >
          <i className='text-hka_gray icon-trash'></i>
        </span>
      </>
    );
  } else if (isGlobalContentWriter && post.is_sent) {
    actionButtonsSection = (
      <>
        <span
          className='relative transition-all rounded-lg cursor-pointer hover:bg-hka_gray-light'
          onClick={editPostHandler}
          data-tip='Edit Post'
        >
          <i className='text-hka_gray icon-cog'></i>
        </span>
      </>
    );
  } else if (!isGlobalContentWriter && !post.is_sent) {
    actionButtonsSection = (
      <div className='flex flex-col items-end'>
        <div>
          {reviewPosts && approvalStatus === 'none' && isSentForReview && (
            <div className='absolute flex text-sm top-2 right-2 px-0.5 py-0.2 bg-orange text-white rounded-md font-basis-medium'>
              Approval Pending
            </div>
          )}
          {approvalStatus === 'approved' && (
            <div className='flex text-sm'>
              <i className='icon-check text-hka_green -mt-0.2 mr-0.2'></i>{' '}
              Approved
            </div>
          )}
          {approvalStatus === 'skipped' && (
            <div className='flex text-base'>
              <i className='icon-close text-hka_red -mt-0.2 mr-0.2'></i> Skipped
            </div>
          )}
        </div>
        <div className='flex'>
          {(post.is_editable || post.company) && (
            <span
              className='transition-all transform rounded-lg cursor-pointer button--small hover:bg-hka_gray-light text-hka_gray icon-cog'
              onClick={editPostHandler}
              data-tip='Edit Post'
            ></span>
          )}
          {post.company && (
            <span
              className='transition-all transform rounded-lg cursor-pointer button--small hover:bg-hka_gray-light text-hka_gray icon-trash'
              onClick={() =>
                setDeleteCustomPostConfirmModal({ show: true, post })
              }
              data-tip='Delete Post'
            ></span>
          )}
        </div>
      </div>
    );
  } else if (!isGlobalContentWriter && post.is_sent) {
    actionButtonsSection = (
      <>
        {approvalStatus === 'skipped' && (
          <div className='flex text-base'>
            <i className='icon-close text-hka_red -mt-0.2 mr-0.2'></i> Skipped
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div
        className={`w-full flex border text-hka_gray-dark border-hka_gray-light p-2 rounded-lg ${
          post.is_sent ? 'stream__post--sent' : 'stream__post'
        }`}
      >
        <div className='w-full'>
          <div
            className='text-base truncate cursor-pointer max-w-50 font-basis-bold link'
            onClick={previewPostHandler}
          >
            {post.title} {post.company ? '(Custom)' : ''}
          </div>
          <div className='flex flex-row flex-wrap text-sm text-hka_gray'>
            {dateAndStatsSection}
          </div>
          <div className='mt-1'>
            <TrainingTagButton tag={postCategoryTag} />
          </div>
        </div>
        <div className='flex items-center ml-auto'>{actionButtonsSection}</div>
      </div>
    </>
  );
};

export default Post;
