export const ANSWERS_PER_QUESTION_ALLOWED = 10;

export const SLACK_LOGO =
  'https://haekka-public-images.s3.us-west-2.amazonaws.com/hka--slack-logo-icon.svg';

export const OKTA_LOGO =
  'https://haekka-public-images.s3.us-west-2.amazonaws.com/HKA--logo-okta.png';

export const GOOGLE_LOGO =
  'https://haekka-public-images.s3.us-west-2.amazonaws.com/hka--google-logo-icon.svg';

export const ZENDESK_LOGO =
  'https://haekka-public-images.s3.us-west-2.amazonaws.com/hka--zendesk-logo-icon.svg';

export const DOCEBO_LOGO =
  'https://haekka-public-images.s3.us-west-2.amazonaws.com/docebo-logo-vector.svg';

export const SEISMIC_LOGO =
  'https://haekka-public-images.s3.us-west-2.amazonaws.com/seismic_icon.svg';

export const EXTERNAL_APP_REDIRECT_URL =
  'https://haekka-backend.herokuapp.com/api/integrations/oauth/callback';
