import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import * as api from "../api";
import { onError } from "../libs/errorLib";
import { normilizeVideoURL } from "../utils/commonUtils";
import VideoContentPlayer from "../components/VideoContentPlayer/VideoContentPlayer";

const TrainingVideoLesson = () => {
  const { isAuthenticated } = useAppContext();
  const location = useLocation();
  const { training, lesson } = queryString.parse(location.search);
  const [videoURL, setVideoURL] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [lessonTitle, setLessonTitle] = useState(undefined);

  useEffect(() => {
    const initiateVideo = async () => {
      try {
        const employeeLesson = await api.getEmployeeLesson({
          auth: isAuthenticated,
          employeeId: isAuthenticated.id,
          trainingId: training,
          lessonId: lesson,
        });
        if (employeeLesson.lesson.lesson_custom_video.length > 0) {
          setVideoURL(
            normilizeVideoURL(
              employeeLesson.lesson.lesson_custom_video[0].hashed_id
            )
          );
        } else if (employeeLesson.lesson.video_url) {
          setVideoURL(normilizeVideoURL(employeeLesson.lesson.video_url));
        } else {
          setError("This lesson does not contain video content.");
        }
        setLessonTitle(employeeLesson.lesson.title);
      } catch (error) {
        setError("Something went wrong. Please contact Haekka for support.");
        onError(error);
      }
    };

    if ((lesson, training)) initiateVideo();
  }, [isAuthenticated, lesson, training]);

  return (
    <VideoContentPlayer
      headerCaption={"Video Lesson"}
      title={lessonTitle}
      error={error}
      videoURL={videoURL}
    />
  );
};

export default TrainingVideoLesson;
