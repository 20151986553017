import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  updateAssessment,
  updateTrainingCompanyAssessment,
} from '../../../api/assessmentApi';

const updateTrainingAssessmentHandler = async ({
  assessmentID,
  assessmentPayload,
  trainingCompanyAssessmentID,
  trainingCompanyAssessment,
}) => {
  await updateAssessment({
    assessmentID,
    payload: assessmentPayload,
  });
  return await updateTrainingCompanyAssessment({
    trainingCompanyAssessmentID,
    payload: trainingCompanyAssessment,
  });
};

export const useUpdateTrainingAssessment = ({
  curriculumID,
  cb = () => {},
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateTrainingAssessmentHandler,
    onSuccess: () => {
      cb();
      queryClient.invalidateQueries(['curriculum-content', curriculumID]);
    },
  });
};
