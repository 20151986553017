import React from "react";
import ActionButton from "../ActionButton";
import { format } from "date-fns";

export default function APIKeysContainer({
  onGenerateApiKeyButtonClick,
  keys,
  setSelectedApiKey,
}) {
  const formatTimestamp = (timestamp) => {
    return format(new Date(timestamp), "yyyy-MM-dd");
  };

  return (
    <section className="w-full p-2 mb-2 space-y-2 bg-white col-span-full shadow-light rounded-xl">
      {/* Text */}
      <section className="container-body-text">
        <h3 className="setting__title mb-0.5">Haekka API Keys</h3>
        <p className="text-sm">
          We do not store API keys for you. When you generate an API key you are
          responsible for storing that key in a safe place.
        </p>
      </section>
      {/* Keys */}
      {keys.length > 0 && (
        <section className="space-y-1 keys">
          <h3 className="setting__title mb-0.5">API Keys</h3>
          {keys.map((key) => (
            <div
              key={key.id}
              className="flex items-center px-2 py-1 space-x-1 border rounded-xl key"
            >
              <div className="flex flex-col flex-1 sm:flex-row">
                <p className="flex-1">
                  🔐 <span className="font-basis-bold">{key.name}</span>
                </p>
                <p className="flex-1 ml-2 text-sm">
                  {formatTimestamp(key.created)}
                </p>
              </div>
              <button
                className="button button--red button__icon--left"
                onClick={() => setSelectedApiKey(key)}
              >
                <span className="icon-trash mb-0.2 text-hka_red"></span>
                Delete Key
              </button>
            </div>
          ))}
        </section>
      )}
      {/* Actions */}
      <section className="actions">
        <div className="generate-button">
          <ActionButton
            type="responsive--full"
            onClick={onGenerateApiKeyButtonClick}
            className="px-2"
          >
            <span>Generate New API Key</span>
          </ActionButton>
        </div>
      </section>
    </section>
  );
}
