import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import * as api from "../api";
import VideoContentPlayer from "../components/VideoContentPlayer/VideoContentPlayer";
import { normilizeVideoURL } from "../utils/commonUtils";
import { getCompanyEngagement } from "../api/engagementsApi";

const EngagementVideoLesson = () => {
  const { isAuthenticated } = useAppContext();
  const location = useLocation();
  const { engagement, question } = queryString.parse(location.search);
  const [videoURL, setVideoURL] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [headerCaption, setHeaderCaption] = useState(undefined);

  useEffect(() => {
    const initiateVideo = async () => {
      try {
        const engagementInstance = await getCompanyEngagement({
          auth: isAuthenticated,
          companyEngagementID: engagement,
        });
        if (question === "null") {
          if (engagementInstance.quiz.engagement_custom_video.length === 0) {
            throw new Error("This engagement soed not contain a video content");
          }
          setVideoURL(
            normilizeVideoURL(
              engagementInstance.quiz.engagement_custom_video[0].hashed_id
            )
          );
          setHeaderCaption("Engagment Video Lesson");
        } else {
          const questionInstance = engagementInstance.quiz.questions.find(
            (q) => q.id === question
          );

          if (questionInstance.engagement_custom_video.length === 0) {
            throw new Error("This engagement soed not contain a video content");
          }

          setVideoURL(
            normilizeVideoURL(
              questionInstance.engagement_custom_video[0].hashed_id
            )
          );
          setHeaderCaption("Engagment Question Video Lesson");
        }
      } catch (error) {
        setError("Something went wrong. Please contact Haekka for support.");
        onError(error);
      }
    };

    if (engagement) initiateVideo();
  }, [isAuthenticated, engagement, question]);

  return (
    <VideoContentPlayer
      headerCaption={headerCaption}
      title={undefined}
      error={error}
      videoURL={videoURL}
    />
  );
};

export default EngagementVideoLesson;
