import { SLACK_LOGO } from "../../utils/constants";
import AssignedEmployeesCardSecton from "./AssignedEmployeesCardSecton";
import { useSelector } from "react-redux";

export const Icon = ({ alt, size, src }) => {
  return <img alt={alt} height={size} width={size} src={src} />;
};

const WorkflowTile = ({
  workflow,
  workflowActionTitle,
  editWorflowHandler,
  onDelete,
  assignedEmployeesData,
}) => {
  const { events } = useSelector((state) => state.workflows);

  let source_icon;
  let destination_icon;
  if (workflow.event_source.startsWith("Slack")) {
    source_icon = { icon: SLACK_LOGO, alt: "Slack icon" };
  }
  if (workflow.event_destination.startsWith("Slack")) {
    destination_icon = { icon: SLACK_LOGO, alt: "Slack icon" };
  }

  const event = events.find((ev) => ev.name === workflow.event);

  return (
    <div className="card">
      <section className="card__header">
        <div className="flex">
          <Icon src={source_icon.icon} alt={source_icon.alt} size={25} />{" "}
          <span className="icon-arrow right mx-0.5"></span>{" "}
          <Icon
            src={destination_icon.icon}
            alt={destination_icon.alt}
            size={25}
          />
        </div>
        <div className="card__actions">
          <span
            className="card__action icon-cog"
            onClick={() => editWorflowHandler(workflow.id)}
          ></span>
          <span
            className="card__action icon-trash"
            onClick={() => onDelete(workflow.id)}
          ></span>
        </div>
      </section>
      <section>
        <p className="text-sm font-basis-medium">Event</p>
        <p className="text-sm">{event?.description}</p>
      </section>
      <section>
        <p className="text-sm font-basis-medium mb-0.2">Action</p>
        <p className="text-sm">{workflowActionTitle}</p>
      </section>
      <section>
        <p className="text-sm font-basis-medium mb-0.5">Assigned Employees</p>
        <AssignedEmployeesCardSecton
          assignedEmployeesData={assignedEmployeesData}
        />
      </section>
    </div>
  );
};

export default WorkflowTile;
