import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateLesson } from '../../../api/trainingsApi';

export const useUpdateLesson = ({ curriculumID }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateLesson,
    onSuccess: () =>
      queryClient.invalidateQueries(['curriculum-content', curriculumID]),
  });
};
