import { useEffect, useState } from "react";
import * as api from "../../api";
import { onError } from "../../libs/errorLib";
import queryString from "query-string";
import { sleep } from "../sleep";

const useFetchGroups = ({ auth, params = {} }) => {
  const [trigger, setTrigger] = useState(false);
  const [response, setResponse] = useState([]);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    const doRequest = (page, page_size) => {
      return api.getGroups({
        auth,
        params: {
          ...params,
          page,
          page_size,
        },
      });
    };

    const fetchGroups = async () => {
      try {
        let page = 1;
        let page_size = 200;
        let fetchedGroups = [];
        let response = null;

        let loadMore = true;
        do {
          response = await doRequest(page, page_size);
          const next = response.next;
          let params = queryString.parse(
            next?.substring(next.indexOf("?"), next.length)
          );
          page = +params.page;
          page_size = +params.page_size;

          fetchedGroups = fetchedGroups.concat(response.results);
          if (response.next) await sleep(200);
          if (!response.next) loadMore = false;
        } while (response.next && loadMore);

        setResponse(fetchedGroups);
        setCompleted(true);
      } catch (e) {
        onError(e);
      }
    };
    if (trigger && auth) fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, auth]);

  return { response, completed, setTrigger };
};

export default useFetchGroups;
