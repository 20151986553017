import React from "react";
import Modal from "../../../Modal/Modal";
import Note from "../../../Note";

export default function RemoveAnswerConfirmationModal({
  showModal,
  onSubmit,
  onClose,
}) {
  return (
    <Modal
      show={showModal}
      title="Delete Message Answer"
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonText="Delete Answer"
      submitButtonType="danger"
      compact
    >
      <p className="mb-1">Are you sure you want to delete this answer? </p>
      <Note type="danger">
        <p className="font-basis-medium">
          The action will not take effect until you save the message.
        </p>
      </Note>
    </Modal>
  );
}
