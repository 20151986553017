import PropTypes from "prop-types";

const AuthButton = ({
  handleClick,
  src,
  altText,
  service,
  action,
  href = undefined,
}) => {
  const child = (
    <div className="flex justify-center w-full">
      <div
        className="flex items-center justify-center w-24 h-5 space-x-1 truncate border rounded-md cursor-pointer border-hka_gray-border"
        onClick={handleClick}
      >
        <img
          style={{ cursor: "pointer" }}
          alt={altText}
          height={25}
          width={25}
          src={src}
        />
        <p>
          {action} <span className="font-basis-bold">{service}</span>
        </p>
      </div>
    </div>
  );

  if (href) {
    return <a href={href}>{child}</a>;
  }

  return child;
};

AuthButton.propTypes = {
  action: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  src: PropTypes.string,
  href: PropTypes.string || PropTypes.undefined,
  altText: PropTypes.string,
  service: PropTypes.string,
};

export default AuthButton;
