import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { getCompanyEngagmentSchedules } from '../../../api/engagementsApi';

const staleTime = 120000;

export const useEngagementSchedulesData = ({
  companyEngagementID,
  transformData = true,
}) => {
  return useQuery({
    queryKey: ['company-engagement-schedules', companyEngagementID],
    queryFn: () => getCompanyEngagmentSchedules({ companyEngagementID }),
    select: (schedulesData) => {
      if (transformData) {
        return schedulesData
          .sort((a, b) => new Date(a.created) - new Date(b.created))
          .map((sc, index) => ({
            value: sc.id,
            label: `${format(
              new Date(sc.created),
              'MMMM do, yyyy'
            )} to ${format(
              schedulesData[index + 1]
                ? new Date(schedulesData[index + 1].created)
                : new Date(),
              'MMMM Mo, yyyy'
            )}`,
          }));
      }
      return schedulesData.sort(
        (a, b) => new Date(b.created) - new Date(a.created)
      );
    },
    staleTime: 60000,
    refetchInterval: 60000,
  });
};

export const prefetchEngagementSchedulesData = (
  queryClient,
  companyEngagementID
) => {
  queryClient.prefetchQuery({
    queryKey: ['company-engagement-schedules', companyEngagementID],
    queryFn: () => getCompanyEngagmentSchedules({ companyEngagementID }),
    staleTime,
  });
};
