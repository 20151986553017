import { useQuery } from '@tanstack/react-query';
import { getEmployeeTrainings } from '../../../api/employeeTrainingsApi';

export const useEmployeeTrainingsData = ({
  companyId,
  curriculumId,
  page,
  query,
  filter = null,
  sort = null,
}) => {
  return useQuery({
    queryKey: [
      'employee-trainings',
      companyId,
      curriculumId,
      page,
      query,
      filter,
      sort,
    ],
    queryFn: () =>
      getEmployeeTrainings({
        companyId,
        curriculumId,
        page,
        query,
        filter,
        sort,
      }),
    keepPreviousData: true,
  });
};
