import React from "react";
import PropTypes from "prop-types";
import { useAppContext } from "../../libs/contextLib";
import axios from "axios";
import toast from "react-hot-toast";
import { onError } from "../../libs/errorLib";
import AuthButton from "./AuthButton";
import { SLACK_LOGO } from "../../utils/constants";
import * as api from "../../api";
import { AuthHeader, AUTH_SCREENS } from "../../containers/Login";

const SlackAuth = ({ action, setAuthScreen }) => {
  const { userHasAuthenticated, setIsConnectedWithSlack } = useAppContext();
  const redirectUri = encodeURIComponent(window.location.origin + "/login");
  const redirectUrl = redirectUri;
  const API_URL = process.env.REACT_APP_API_URL;
  const slackClientId = process.env.REACT_APP_CLIENT_ID;

  const onSuccess = async (code) => {
    const qParams = [`code=${code}`, `redirect_uri=${redirectUri}`].join("&");

    const checkInstalledApps = () => {
      const auth = JSON.parse(localStorage.getItem("auth"));
      return api.checkInstalledApps({
        auth: auth,
      });
    };

    try {
      const { data } = await axios.get(
        `${API_URL}/signin_with_slack?${qParams}`
      );
      const res = data;
      res.loggedInWith = "SLACK";
      localStorage.setItem("auth", JSON.stringify(res));
      localStorage.setItem("login_token", res.login_token);
      userHasAuthenticated(res);

      // TO-DO: extract this logic to utility function or hook (it's used in the App.js, GoogleAuth and here)
      const { is_app_installed_in_slack, is_google_workspace_integrated } =
        await checkInstalledApps();
      localStorage.setItem(
        "apps_installations",
        JSON.stringify({
          ts: new Date(),
          slack: is_app_installed_in_slack,
          google: is_google_workspace_integrated,
        })
      );

      setIsConnectedWithSlack(is_app_installed_in_slack);
    } catch (err) {
      let message = err.message;
      if (
        err.response &&
        err.response.data &&
        typeof err.response.data === "string"
      ) {
        message = err.response.data;
      }
      toast.error(message);
    }
  };

  const onFailure = (e) => {
    onError(e);
  };

  function openPopup() {
    const width = 400;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    let url = `https://slack.com/openid/connect/authorize?response_type=code&scope=openid&client_id=${slackClientId}`;
    if (redirectUrl) {
      url += `&redirect_uri=${redirectUrl}`;
    }

    return window.open(
      url,
      "",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  }

  function handleClick() {
    polling(openPopup());
  }

  function polling(popup) {
    const polling = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(polling);
        onFailure("Popup has been closed by user");
      }

      const closeDialog = () => {
        clearInterval(polling);
        popup.close();
      };

      try {
        if (!popup.location.hostname.includes("slack.com")) {
          if (popup.location.search) {
            const query = new URLSearchParams(popup.location.search);
            const slackCode = query.get("code");

            closeDialog();
            if (slackCode) {
              return onSuccess(slackCode);
            }

            if (onFailure) {
              onFailure(query.get("error"));
            }
          }
        }
      } catch (error) {
        console.error(error);
        // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
        // A hack to get around same-origin security policy errors in IE.
      }
    }, 500);
  }

  return (
    <>
      <AuthHeader />
      <AuthButton
        handleClick={handleClick}
        src={SLACK_LOGO}
        altText="Sign in with Slack"
        action={action}
        service="Slack"
      />
      <div>
        {/* <p>
          Not using Slack?{" "}
          <span
            className="cursor-pointer font-basis-bold text-orange"
            onClick={() => setAuthScreen(AUTH_SCREENS.GOOGLE)}
          >
            Sign in with Google
          </span>
        </p> */}
        <p className="px-4 mt-2 sm:mt-1">
          <span className="font-basis-bold">Don't have an account?</span> No
          worries, you can still use the button above to sign in. We'll create
          an account automatically for you.
        </p>
      </div>
    </>
  );
};

SlackAuth.propTypes = {
  action: PropTypes.string.isRequired,
  setAuthScreen: PropTypes.func.isRequired,
};

export default SlackAuth;
