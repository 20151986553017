import { useState, useEffect } from 'react';

import { useDeleteAssessmentAnswer } from '../../hooks/data-hooks/assessments';

import { reorderList } from '../../utils/commonUtils';
import { ANSWERS_PER_QUESTION_ALLOWED } from '../../utils/constants';

import Note from '../Note';
import Divider from '../Base/Divider';
import SortableContainer from '../SortableContainer';
import WysiwygRichTextEditor from '../WysiwygEditor/WysiwygRichTextEditor';
import AssessmentQuestionAnswer from './AssessmentQuestionAnswer';
import DeleteAnswerConfirmModal from './confirmationModals/DeleteAnswerConfirmModal';

const AssessmentQuestion = ({
  question,
  selectedQuestion,
  setQuestions,
  curriculumID,
}) => {
  const [deleteAnswerModal, setDeleteAnswerModal] = useState(undefined);
  const [correctAnswersDisabled, setCorrectAnswersDisabled] = useState(false);

  const { mutate: deleteAssessment } = useDeleteAssessmentAnswer({
    curriculumID,
  });

  useEffect(() => {
    const numberOfCorrectAnswers = question.answers.filter(
      (a) => a.correct
    ).length;
    if (numberOfCorrectAnswers > 0 && question.single_correct) {
      setCorrectAnswersDisabled(true);
    }
    if (
      !question.single_correct ||
      (question.single_correct && numberOfCorrectAnswers === 0)
    ) {
      setCorrectAnswersDisabled(false);
    }
  }, [question.answers, question.single_correct]);

  const updateAnswersHandler = ({ answer, field, value }) => {
    setQuestions((ps) =>
      ps.map((q) => {
        if (q.position === selectedQuestion) {
          return {
            ...q,
            answers: q.answers.map((a) =>
              a.position === answer.position ? { ...a, [field]: value } : a
            ),
          };
        }
        return q;
      })
    );
  };

  const updateQuestionHandler = (field, value) => {
    setQuestions((ps) =>
      ps.map((q) => {
        if (q.position === selectedQuestion) {
          let newData = {
            ...q,
            [field]: value,
          };
          if (field === 'single_correct' && value === true) {
            newData = {
              ...newData,
              answers: newData.answers.map((a) => ({ ...a, correct: false })),
            };
          }
          return newData;
        }
        return q;
      })
    );
  };

  const onAddAnswer = () => {
    setQuestions((ps) =>
      ps.map((q) => {
        if (q.position === selectedQuestion) {
          return {
            ...q,
            answers: [
              ...q.answers,
              {
                id: `question-${selectedQuestion}-answer-${q.answers.length}`,
                answer: '',
                correct: !question.single_correct,
                position: q.answers.length,
              },
            ],
          };
        }
        return q;
      })
    );
  };

  const onDragEndHandler = async ({ active, over }) => {
    if (active.id !== over.id) {
      setQuestions((ps) =>
        ps.map((q) => {
          if (q.position === selectedQuestion) {
            const oldIndex = q.answers.findIndex(
              (answer) => answer.id === active.id
            );
            const newIndex = q.answers.findIndex(
              (answer) => answer.id === over.id
            );
            const items = reorderList(q.answers, oldIndex, newIndex).map(
              (item, index) => ({ ...item, position: index })
            );
            return {
              ...q,
              answers: items,
            };
          }
          return q;
        })
      );
    }
  };

  const getAnswerData = () => {
    return question.answers.find(
      (a) => a.position === deleteAnswerModal.aPosition
    );
  };

  const removeAnswerHandler = () => {
    if (getAnswerData().id) {
      deleteAssessment({ answerID: getAnswerData().id });
    }

    setQuestions((ps) =>
      ps.map((q) => {
        if (q.position === selectedQuestion) {
          return {
            ...q,
            answers: q.answers.filter(
              (a) => a.position !== deleteAnswerModal.aPosition
            ),
          };
        }
        return q;
      })
    );
    setDeleteAnswerModal(undefined);
  };

  const updateEditorStateHandler = (state) =>
    setQuestions((questions) => {
      const qs = questions.map((q) => {
        if (q.position === question.position) {
          return {
            ...q,
            editorState: state,
          };
        }
        return q;
      });
      return qs;
    });

  const addImageHandler = (imageId) => {
    setQuestions((questions) => {
      const qs = questions.map((q) => {
        if (q.position === question.position) {
          return {
            ...q,
            imageIDs: [...q.imageIDs, imageId],
          };
        }
        return q;
      });
      return qs;
    });
  };

  return (
    <div
      className={`${selectedQuestion !== question.position ? 'hidden' : ''}`}
    >
      <WysiwygRichTextEditor
        editorState={question.editorState}
        onSetEditorState={updateEditorStateHandler}
        id={question.id}
        onImageAdded={addImageHandler}
        contentType={'assessment_question_content'}
        questionId={undefined}
      />

      <section className='section--answers -mx-2.5 mt-2'>
        <div className='flex items-center justify-between'>
          <h4 className=''>Answers(s)</h4>
          {question.answers.length < ANSWERS_PER_QUESTION_ALLOWED && (
            <button
              onClick={onAddAnswer}
              type='button'
              className='px-2 button button--blue bg-hka_blue-light'
            >
              Add Answer
            </button>
          )}
        </div>
        <div className='flex flex-col justify-between lg:flex-row'>
          <section className='flex items-center justify-between gap-1 py-1'>
            <p className=''>Allow user to only select one answer</p>
            <label className='switch'>
              <input
                type='checkbox'
                checked={question.single_select}
                onChange={(e) =>
                  updateQuestionHandler('single_select', e.target.checked)
                }
                className='cursor-pointer'
              />
              <span className='slider'></span>
            </label>
          </section>

          <section className='flex items-center justify-between gap-1 py-1'>
            <p>Allow only one correct answer for this question</p>
            <label className='switch'>
              <input
                type='checkbox'
                checked={question.single_correct}
                onChange={(e) =>
                  updateQuestionHandler('single_correct', e.target.checked)
                }
                className='cursor-pointer'
              />
              <span className='slider'></span>
            </label>
          </section>
        </div>
        {question.answers.length > 0 && (
          <SortableContainer
            onDragEndHandler={onDragEndHandler}
            containerName={`quiz_question__${question.position}__answers`}
          >
            {question.answers.map((answer) => (
              <AssessmentQuestionAnswer
                key={answer.id}
                sortableID={answer.id}
                answer={answer}
                updateAnswersHandler={updateAnswersHandler}
                setDeleteAnswerModal={setDeleteAnswerModal}
                correctAnswersDisabled={correctAnswersDisabled}
              />
            ))}
          </SortableContainer>
        )}
        {question.answers.length === 0 && (
          <Note>
            <p className='flex flex-wrap items-center text-sm '>
              At least one{' '}
              <span className='mx-0.5 icon-check w-2.4 h-2.4 rounded-md text-hka_green bg-hka_green-light flex items-center justify-center'></span>
              <span className='font-basis-bold mr-0.5'>correct</span> and one
              <span className='mx-0.5 icon-close w-2.4 h-2.4 rounded-md text-hka_red bg-hka_red-light flex items-center justify-center'></span>
              <span className='font-basis-bold mr-0.5'>incorrect</span> answer
              is required.
            </p>
          </Note>
        )}

        {question.answers.length === ANSWERS_PER_QUESTION_ALLOWED && (
          <p className='font-basis-medium text-hka_gray'>
            You can't add more than {ANSWERS_PER_QUESTION_ALLOWED} answers per
            question.
          </p>
        )}
        {deleteAnswerModal?.showConfirmModal && (
          <DeleteAnswerConfirmModal
            onSubmit={removeAnswerHandler}
            onClose={() => setDeleteAnswerModal(undefined)}
            answer={getAnswerData().answer}
          />
        )}
      </section>
    </div>
  );
};

export default AssessmentQuestion;
