import React from "react";
import formatTrainingAndLessonDurationToText from "../utils/formatTrainingAndLessonDurationToText";

export default function TrainingOrLessonDuration({ duration }) {
  return (
    <div className="flex flex-row align-center items-center mt-1">
      <span className="icon-clock -ml-0.4"></span>
      <p className="ml-0.2">
        Estimated time to complete:{" "}
        <b>{formatTrainingAndLessonDurationToText(duration)}</b>
      </p>
    </div>
  );
}
