import Modal from "../Modal/Modal";

const DeleteVideoModal = ({ showModal, onSubmit, onClose }) => {
  return (
    <Modal
      show={showModal}
      title="Remove Lesson Video"
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonText="Delete Video"
      submitButtonType="danger"
      compact
    >
      <p>Are you sure you want to remove the video for this lesson?</p>
    </Modal>
  );
};

export default DeleteVideoModal;
