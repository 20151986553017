import { Cell } from 'rsuite-table';

const AcknowledgedCell = ({ rowData, dataKey, ...props }) => {
  let caption = '-';
  if (rowData.answer_type === 'external_url' && !!rowData[dataKey]) {
    caption = 'Clicked';
  }
  if (rowData.answer_type === 'user_acknowledged' && !!rowData[dataKey]) {
    caption = 'Acknowledged';
  }
  return (
    <Cell {...props} style={{ padding: 0 }}>
      <div className='flex items-center justify-center w-full h-3 ml-auto space-x-1'>
        <div className='text-sm'>{caption}</div>
      </div>
    </Cell>
  );
};

export default AcknowledgedCell;
