import React from "react";
import Modal from "../../../Modal/Modal";

export default function ChangeAnswerTypeWarningModal({
  show,
  message,
  to,
  onSubmit,
  onClose,
}) {
  return (
    <Modal
      show={show}
      title="Confirm answer type change"
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonText={`Select ${to?.label} action`}
      compact
    >
      <p>{message}</p>
    </Modal>
  );
}
