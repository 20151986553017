import React, { useEffect, useState } from "react";
import Modal from "../../Modal/Modal";
import CampaignDetailsModalFragment from "./fragments/CampaignDetailsModalFragment";
import LoaderInline from "../../LoaderInline";

export default function CreatePhishingCampaignModal({
  showModal,
  onCreateCampaign,
  onClose,
  isSubmitting,
}) {
  const [allowSubmission, setAllowSubmission] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    setAllowSubmission(!!name);
  }, [name]);

  const resetState = () => {
    setAllowSubmission(false);
    setName("");
  };

  const onSubmit = () => {
    const payload = {
      name,
    };

    onCreateCampaign(payload);
    resetState();
  };

  const handleOnClose = () => {
    resetState();
    onClose();
  };

  return (
    <Modal
      expandable
      show={showModal}
      onClose={handleOnClose}
      onSubmit={onSubmit}
      submitValid={allowSubmission}
      title="Create Phishing Campaign"
      submitButtonText="Create Campaign"
      modalClass="modal--create-phishing-campaign"
    >
      <section>
        {isSubmitting ? (
          <LoaderInline />
        ) : (
          <CampaignDetailsModalFragment name={name} setName={setName} />
        )}
      </section>
    </Modal>
  );
}
