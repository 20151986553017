import api from "../client";
import axios from "axios";
import { videoUploadActions } from "./video-upload";
import { getWistiaRefreshToken, updateCustomVideo } from "../api";
import { randomString } from "../utils/commonUtils";
import {
  addUploadRequestReference,
  getCurrentWistiaUploadPayload,
  handleVideoUploadTrack,
} from "../utils/video-upload-utils";

const CancelToken = axios.CancelToken;
export const genAccessTokenMsg = "Generating access token...";

export const sendVideo = (file, auth, id, type, position, parent) => {
  return async (dispatch) => {
    dispatch(
      videoUploadActions.updateUpload({
        upload: {
          success: true,
          progress: 0,
          title: file.name,
          source: undefined,
          etc: "",
          message: genAccessTokenMsg,
          type,
          id,
          parent,
          completed: false,
          mapped: !!id,
          position,
        },
      })
    );

    const uploadToWistia = async () => {
      const { id: expiringToken, project_id: projectId } =
        await getWistiaRefreshToken({
          auth,
        });

      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("access_token", expiringToken);
      formData.append("project_id", projectId);
      const timeStarted = new Date();
      const source = CancelToken.source();

      const randomID = randomString(10);
      addUploadRequestReference({ randomID, source });

      try {
        const { data } = await axios.post(
          `https://upload.wistia.com`,
          formData,
          {
            cancelToken: source.token,
            onUploadProgress: (progressEvent) => {
              const currentUpload = getCurrentWistiaUploadPayload({
                progressEvent,
                timeStarted,
                id,
                type,
                position,
                parent,
                title: file.name,
                randomID,
              });
              dispatch(
                videoUploadActions.updateUpload({ upload: currentUpload })
              );
            },
          }
        );

        dispatch(
          finalizeUpload({
            auth,
            data,
            id,
            parent,
            type,
            title: file.name,
            position,
          })
        );
      } catch (error) {
        dispatch(
          videoUploadActions.updateUpload({
            upload: {
              success: false,
              progress: 100,
              title: file.name,
              source: undefined,
              etc: "",
              message:
                error.response?.data.detail ||
                error.message ||
                "Something went wrong!",
              type,
              id,
              parent,
              completed: true,
              mapped: !!id,
              position,
            },
          })
        );
      }
    };

    uploadToWistia();
  };
};

const finalizeUpload = ({ auth, data, id, parent, type, title, position }) => {
  return async (dispatch, getState) => {
    const reqData = {
      service_id: data.id,
      name: data.name,
      duration: data.duration,
      hashed_id: data.hashed_id,
      progress: data.progress,
      thumbnail: data.thumbnail.url,
      object_type: type,
      object_id: id,
    };
    const selectedUpload = getState().videoUpload.uploadsInProgress.find(
      (u) =>
        u.id === id &&
        u.type === type &&
        u.position === position &&
        u.parent === parent
    );
    // if ID (updating existing object) -> update CustomVideo object in the backend
    if (id) {
      const updateResponse = await updateCustomVideo({ auth, reqData });

      dispatch(videoUploadActions.setUpdateResponses({ id, updateResponse }));

      dispatch(
        videoUploadActions.updateUpload({
          upload: {
            id,
            type,
            position,
            parent,
            success: true,
            message: "Upload completed!",
            progress: 100,
            title,
            completed: true,
          },
        })
      );
    } else {
      if (selectedUpload.mapped) {
        reqData.object_id = selectedUpload.mappedId;
        const updateResponse = await updateCustomVideo({ auth, reqData });

        dispatch(
          videoUploadActions.setUpdateResponses({
            id: selectedUpload.mappedId,
            updateResponse,
          })
        );

        dispatch(
          videoUploadActions.updateUpload({
            upload: {
              id,
              type,
              position,
              parent,
              success: true,
              message: "Upload completed!",
              progress: 100,
              title,
              completed: true,
            },
          })
        );
      }
      // for No -> set upload data to the uploadInProgress and mark it as mapped
      else {
        dispatch(
          videoUploadActions.updateUpload({
            upload: {
              success: true,
              message: `Upload completed. Waiting for ${
                type === "engagement"
                  ? "Engagement"
                  : type === "engagement-question"
                  ? "Engagement Question"
                  : "Lesson"
              } to be saved...`,
              progress: 100,
              title,
              type,
              id,
              position,
              parent,
              completed: false,
              uploadResponseData: reqData,
              mapped: true,
            },
          })
        );
      }
    }
    handleVideoUploadTrack({ auth, type, reqData });
  };
};
