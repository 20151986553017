import React from "react";
import Modal from "../Modal/Modal";
import Note from "../Note";

export default function RemoveEmployeeFromTrainingModal({
  showModal,
  employee,
  onSubmit,
  onClose,
}) {
  return (
    <Modal
      show={showModal}
      title="Remove Employee"
      submitButtonText="Remove Employee"
      submitButtonType="danger"
      onSubmit={onSubmit}
      onClose={onClose}
      compact
    >
      <p className="mb-1">
        Are you sure you want to remove{" "}
        <span className="font-basis-bold">{employee.employee_name}</span> from
        this training?
      </p>
      <Note type="danger">
        <p>
          Performing this acton will remove this user and all their data from
          this training. Please double check the user's name before completing
          this action.
        </p>
      </Note>
    </Modal>
  );
}
