import Tag from '../../../Tag';
import Paginator from '../../../Paginator';
import { useState } from 'react';
import RolesCRUDModal from './RolesCRUDModal';
import ConfirmRoleDeleteModal from './ConfirmRoleDeleteModal';
import { useAppContext } from '../../../../libs/contextLib';
import { modalModes } from '../../../../utils/commonUtils';
import ActionButton from '../../../ActionButton';
import LoaderInline from '../../../LoaderInline';
import Divider from '../../../Base/Divider';
import {
  useRolesData,
  useUpdateUserAccountsSettings,
  useUserAccountsSettingsData,
} from '../../../../hooks/data-hooks/settings';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createRole,
  deleteRole,
  updateRole,
} from '../../../../api/settings/companySettingsApi';

const RolesSettings = () => {
  const queryClient = useQueryClient();
  const { isAuthenticated } = useAppContext();

  const page_size = 20;
  const [selectedPage, setSelectedPage] = useState(1);
  const [roleCrudModal, setRoleCrudModal] = useState({
    show: false,
    mode: undefined,
    roleID: undefined,
    roleCaption: undefined,
    isRoleGlobal: false,
  });
  const [deleteRoleModal, setDeleteRoleModal] = useState({
    show: false,
    roleID: undefined,
    roleCaption: undefined,
  });

  const { data: roles, isLoading: loadingRoles } = useRolesData({
    page: selectedPage,
    page_size,
  });

  const {
    data: settings,
    isLoading: isLoadingSettings,
    isFetching,
  } = useUserAccountsSettingsData({
    companyID: isAuthenticated.company,
  });

  const { mutate: updateSettings, isLoading: isSubmittingSettings } =
    useUpdateUserAccountsSettings({
      companyID: isAuthenticated.company,
    });

  const onSuccess = () => {
    setRoleCrudModal({
      show: false,
      mode: undefined,
      roleID: undefined,
      roleCaption: undefined,
      isRoleGlobal: false,
    });
    setDeleteRoleModal({
      show: false,
      roleID: undefined,
      roleCaption: undefined,
    });
    queryClient.invalidateQueries(['roles', selectedPage, page_size]);
  };

  const { mutate: createCompanyRole } = useMutation({
    mutationFn: createRole,
    onSuccess,
  });

  const { mutate: updateCompanyRole } = useMutation({
    mutationFn: updateRole,
    onSuccess,
  });

  const { mutate: deleteCompanyRole } = useMutation({
    mutationFn: deleteRole,
    onSuccess,
  });

  const submitUpdateHandler = (payload) => {
    if (roleCrudModal.mode === modalModes.ADD) {
      createCompanyRole({ payload });
    }
    if (roleCrudModal.mode === modalModes.EDIT) {
      updateCompanyRole({ payload, roleID: roleCrudModal.roleID });
    }
  };

  const selectRoleHandler = (role, mode) => {
    setRoleCrudModal({
      show: true,
      mode,
      roleID: role.id,
      roleCaption: role.title,
      isRoleGlobal: !role.company,
    });
  };

  const removeRoleHandler = (role) => {
    setDeleteRoleModal({
      show: true,
      roleID: role.id,
      roleCaption: role.title,
    });
  };

  const submitDeleteHandler = () => {
    deleteCompanyRole({ roleID: deleteRoleModal.roleID });
  };

  const roleSettingChangedHandler = (e) => {
    updateSettings({
      updateData: { is_user_role_optional: !e.target.checked },
    });
  };

  if (loadingRoles || isLoadingSettings)
    return (
      <section className='col-span-full'>
        <LoaderInline wrapperClasses={'w-full min-h-7 py-2'} />;
      </section>
    );

  return (
    <section className='p-2 mb-4 bg-white col-span-full shadow-light rounded-xl'>
      <section className='flex justify-between mb-2'>
        <section>
          <h3 className='setting__title mb-0.5'>Roles</h3>

          <p className='text-sm'>
            <b>On by default.</b> Haekka will ask a user to select their role
            when they are first assigned training.
          </p>
        </section>
        <section className='flex items-center'>
          <label className='switch'>
            <input
              type='checkbox'
              checked={!settings.is_user_role_optional}
              onChange={roleSettingChangedHandler}
              className='cursor-pointer'
              disabled={isSubmittingSettings || isFetching}
            />
            <span className='slider'></span>
          </label>
        </section>
      </section>

      <Divider />
      <p className='mt-2 mb-1 text-sm'>
        Click a role below to select and edit it, or click{' '}
        <span className='icon-sm icon-close bg-hka_gray-light h-2.4 w-2.5 pr-0.1 rounded-sm inline-flex'></span>{' '}
        to delete it.
      </p>
      <div>
        {!loadingRoles && (
          <div className='flex flex-wrap mb-1.5'>
            {roles.results.map((role) => {
              return (
                <Tag
                  tag={role.title}
                  key={role.id}
                  onRemove={() => removeRoleHandler(role)}
                  onSelect={() => selectRoleHandler(role, modalModes.EDIT)}
                  pointer
                />
              );
            })}
          </div>
        )}

        {roles?.count > page_size && (
          <Paginator
            pageSize={page_size}
            count={roles.count}
            currentPage={selectedPage}
            onPageChange={(page) => {
              setSelectedPage(page);
            }}
          />
        )}
      </div>
      <ActionButton
        type='responsive--full'
        onClick={() =>
          selectRoleHandler(
            {
              id: undefined,
              title: '',
              company: isAuthenticated?.company_details.id,
            },
            modalModes.ADD
          )
        }
      >
        Create New Role
      </ActionButton>
      {roleCrudModal.show && (
        <RolesCRUDModal
          roleCrudModal={roleCrudModal}
          onClose={() => setRoleCrudModal(false)}
          onSubmit={submitUpdateHandler}
        />
      )}
      {deleteRoleModal.show && (
        <ConfirmRoleDeleteModal
          onClose={() =>
            setDeleteRoleModal({
              show: false,
              roleID: undefined,
              roleCaption: undefined,
            })
          }
          roleCaption={deleteRoleModal.roleCaption}
          onSubmit={submitDeleteHandler}
        />
      )}
    </section>
  );
};

export default RolesSettings;
