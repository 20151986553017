import api from '../client';

export const getEmployees = async ({
  auth,
  page,
  page_size = 10,
  is_active,
  active_training,
  group,
  name,
  limit,
  slackUsersOnly = false,
  isAdmin,
}) => {
  const companyId = auth.company;

  const params = {
    page,
    page_size,
  };

  if (limit) {
    params.limit = limit;
  }

  if (is_active !== null && is_active !== undefined) {
    params.is_active = is_active ? 'True' : 'False';
  }

  if (active_training !== null && active_training !== undefined) {
    params.active_training = active_training ? 'True' : 'False';
  }

  if (group) {
    params.group = group.id;
  }

  if (auth.is_manager && !auth.is_admin) {
    params.is_manager = auth.is_manager;
  }

  if (name) {
    params.name = name;
  }

  if (slackUsersOnly) {
    params.slack_users_only = true;
  }

  if (isAdmin) {
    params.is_admin = true;
  }

  const { data } = await api.get(`/api/companies/${companyId}/employees/list`, {
    params,
  });
  return data;
};

export const getEmployeeDetails = async ({ employeeID }) => {
  const { data } = await api.get(`/api/employees/${employeeID}/details`);
  return data;
};

export const deleteEmployee = async ({ companyID, employeeID }) => {
  const { data } = await api.delete(
    `/api/companies/${companyID}/employees/${employeeID}`
  );
  return data;
};
