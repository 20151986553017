import api from '../client';

// Employee Trainings

export const getEmployeeTrainings = async ({
  companyId,
  curriculumId,
  page,
  query,
  filter = null,
  sort = null,
}) => {
  const params = {
    page,
  };
  if (query) params.query = query;
  if (filter) params.filter = filter;
  if (sort) params.sort = sort;

  const { data } = await api.get(
    `/api/companies/${companyId}/employees/curriculums/${curriculumId}`,
    {
      params,
    }
  );

  return data;
};

export const getSimpleEmployeeTrainings = async ({
  employeeID,
  page = 1,
  pageSize = 10,
  tags,
  name,
}) => {
  const params = {
    page,
    page_size: pageSize,
  };

  if (tags) {
    params.tags = tags;
  }

  if (name) {
    params.name = name;
  }

  const { data } = await api.get(
    `/api/employees/${employeeID}/simple-trainings`,
    {
      params,
    }
  );

  return data;
};

export const completeEmployeeTrainings = async ({
  trainingID,
  employees,
  allEmployees = false,
  action,
  updateData = undefined,
}) => {
  return await api.post(`/api/employees/trainings/${trainingID}/bulk_update`, {
    employees,
    all_employees: allEmployees,
    action,
    update_data: updateData,
  });
};

export const requestEmployeeTrainingCertificates = async ({
  trainingID,
  employees,
  isDemo = false,
  sendToSlack = false,
}) => {
  return await api.post(`/api/employees/trainings/${trainingID}/certificate`, {
    employees,
    is_demo: isDemo,
    origin: 'Web Dashboard',
    send_to_slack: sendToSlack,
  });
};

export const downloadCertficate = async ({
  trackProgress,
  progressHolder,
  trainingID,
}) => {
  return await api.get(
    `/api/employees/trainings/${trainingID}/certificate?progress_holder_id=${progressHolder}`,
    {
      responseType: 'arraybuffer',
      onDownloadProgress: (progressEvent) => {
        trackProgress(progressEvent);
      },
    }
  );
};

export const sendReminderAboutTraining = async ({
  receiverUserId,
  curriculumId,
}) => {
  const { data } = await api.post(
    `/api/employees/trainings/${curriculumId}/send_reminder`,
    {
      receiver_user_id: receiverUserId,
    }
  );

  return data;
};

export const closeEmployeeTraining = async ({ employeeId, curriculumId }) => {
  return await api.patch(
    `/api/employees/${employeeId}/trainings/${curriculumId}/close`,
    {}
  );
};

export const renewTraining = async ({ employeeID, trainingID }) => {
  return await api.put(
    `/api/employees/${employeeID}/trainings/${trainingID}/renew`,
    {}
  );
};

// Employee Training Assessments

export const getEmployeeTrainingAssessmentQuestion = async ({
  employeeID,
  assessmentID,
  questionID,
}) => {
  const { data } = await api.get(
    `/api/employees/${employeeID}/training-assessment/${assessmentID}?question_id=${questionID}`
  );
  return data;
};

export const submitEmployeeTrainingAssessmentQuestion = async ({
  employeeID,
  assessmentID,
  questionID,
  selectedAnswers,
}) => {
  const { data } = await api.post(
    `/api/employees/${employeeID}/training-assessment/${assessmentID}/question/${questionID}`,
    {
      selected_answers: selectedAnswers,
    }
  );
  return data;
};

export const getEmployeeTrainingAssessmentSummary = async ({
  employeeID,
  assessmentID,
}) => {
  const { data } = await api.get(
    `/api/employees/${employeeID}/training-assessment/${assessmentID}/summary`
  );
  return data;
};

export const getEmployeeTrainingAssessmentQuestionAttempts = async ({
  employeeID,
  assessmentID,
  questionID,
}) => {
  const { data } = await api.get(
    `/api/employees/${employeeID}/training-assessment/${assessmentID}/question/${questionID}/attempts`
  );
  return data;
};

export const retakeEmployeeTraining = async ({
  employeeID,
  assessmentID,
  trainingID,
}) => {
  const { data } = await api.post(
    `/api/employees/${employeeID}/training-assessment/${assessmentID}/retake`,
    { training_id: trainingID }
  );
  return data;
};

export const completeEmployeeTrainingFromAssessment = async ({
  employeeID,
  assessmentID,
  trainingID,
}) => {
  const { data } = await api.post(
    `/api/employees/${employeeID}/training-assessment/${assessmentID}/complete-training`,
    { training_id: trainingID }
  );
  return data;
};
