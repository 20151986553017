import usePolling from "../../utils/custom-hooks/usePolling";
import * as api from "../../api";
import { useAppContext } from "../../libs/contextLib";
import { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import ListItems from "../ListItems";
import Paginator from "../Paginator";
import Modal from "../Modal/Modal";
import Note from "../Note";

const CsvValidatedResult = ({
  progressTracker,
  resetFile,
  assignEmployeesContext,
}) => {
  const { isAuthenticated } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);
  const { uploadedCsvUsers, setUploadedCsvUsers } = assignEmployeesContext;

  const [completed, setCompleted] = useState(false);
  const [total, setTotal] = useState(0);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);
  const [warnings, setWarnings] = useState(false);
  const [nonExistingEmail, setNonExistingEmails] = useState([]);
  const [invalidEmail, setNonInvalidEmails] = useState([]);
  const [guestEmails, setGuestEmails] = useState([]);
  const [duplicatedUsers, setDuplicatedUsers] = useState([]);

  const [paginatedEmployees, setPaginatedEmployees] = useState([]);
  const [removalWarning, setRemovalWarning] = useState(undefined);

  useEffect(() => {
    if (nonExistingEmail.length > 0) {
      setWarnings(true);
    }
  }, [nonExistingEmail]);

  usePolling(async () => {
    if (progressTracker && !completed) {
      const { data } = await api.getTemporaryData({
        auth: isAuthenticated,
        dataID: progressTracker,
      });
      setCompleted(data.completed);
      setProgress(data.progress);
      setError(data.error);
      setNonExistingEmails(data.non_existing_emails || []);
      setNonInvalidEmails(data.invalid_emails || []);
      setGuestEmails(data.guests || []);
      setDuplicatedUsers(data.duplicates || []);
      setUploadedCsvUsers(data.employees || []);
      setTotal(data.employees?.length || 0);
    }
  }, 750);

  const clearError = () => {
    setError(false);
    setCompleted(false);
    setProgress(0);
    resetFile();
  };

  const pageSize = 10;

  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    let endIndex = currentPage * pageSize;
    if (endIndex > uploadedCsvUsers.length) {
      endIndex = uploadedCsvUsers.length;
    }
    const employeeSlice = uploadedCsvUsers.slice(startIndex, endIndex);

    setPaginatedEmployees(employeeSlice);
  }, [currentPage, uploadedCsvUsers]);

  const removeEmployee = () => {
    setUploadedCsvUsers((users) =>
      users.filter((user) => user.id !== removalWarning.id)
    );
    setRemovalWarning(undefined);
  };

  if (error) {
    return (
      <section className="flex items-center p-1 mt-1 text-white bg-red-500 rounded-md">
        <div className="flex justify-center w-3">
          <div className="border-2 border-white w-2.5 h-2.5 rounded-full flex justify-center items-center">
            <span className="font-extrabold text-white text-base2">!</span>
          </div>
        </div>
        <div className="ml-1">
          <p className="text-white">Selected file can't be uploaded!</p>
          <p className="mt-1 text-white">Error: {error}</p>
        </div>
        <div
          className="ml-auto mr-1 border border-white rounded-md px-1 py-0.5 font-basis-medium cursor-pointer"
          onClick={clearError}
        >
          OK
        </div>
      </section>
    );
  }

  return (
    <section className="mt-1">
      <div className="mb-2">
        <ProgressBar
          completed={progress || 0}
          isLabelVisible={false}
          width={"100%"}
          height={"0.5rem"}
          borderRadius={"0.5rem"}
          baseBgColor={"#EFF0F7"}
          bgColor={!!error ? "#f91e00" : "#F99A00"}
        />
        {progress !== 100 && (
          <p className="mt-0.5 font-basis-medium text-sm text-hka_gray-dark">
            Verifying data in the uploaded file...
          </p>
        )}
        {progress === 100 && (
          <p className="mt-0.5 font-basis-medium text-sm text-hka_gray-dark">
            <span className="text-base">🙌</span> Success! Your users have been
            verified.
          </p>
        )}
      </div>

      {warnings && (
        <Note type="danger">
          <p className="mb-1 text-base text-hka_gray-dark">
            The following errors occurred while processing your CSV file:
          </p>
          <div>
            {nonExistingEmail.length > 0 && (
              <div className="mb-2">
                <h4>Cannot Find Slack Account Email</h4>
                <p className="text-base text-hka_gray-dark mb-0.7">
                  Make sure user emails within the CSV match Slack account
                  emails. The following users will be ignored if you decide to
                  proceed:
                </p>
                {nonExistingEmail.map((email, i) => (
                  <p className="ml-1 font-basis-bold" key={email + i}>
                    • {email}
                  </p>
                ))}
              </div>
            )}

            {invalidEmail.length > 0 && (
              <div className="mb-2">
                <h4>Invalid Email</h4>
                <p className="text-base text-hka_gray-dark mb-0.7">
                  The following email addresses are not valid. We will ignore
                  these if you decide to proceed.
                </p>
                {invalidEmail.map((email, i) => (
                  <p className="ml-1 font-basis-bold" key={email + i}>
                    • {email}
                  </p>
                ))}
              </div>
            )}

            {guestEmails.length > 0 && (
              <div className="mb-2">
                <h4>Guest Emails</h4>
                <p className="text-base text-hka_gray-dark">
                  The following users are guests in your workspace, and are set
                  to be excluded.
                </p>
                <p>
                  To include guest accounts:{" "}
                  <span className="font-basis-bold">{`Settings > Slack > Include Guests Accounts`}</span>
                </p>
                {guestEmails.map((email, i) => (
                  <p className="ml-1" key={email + i}>
                    • {email}
                  </p>
                ))}
              </div>
            )}

            {duplicatedUsers.length > 0 && (
              <div className="mb-2">
                <h4>Duplicate Emails</h4>
                <p className="text-base text-hka_gray-dark mb-0.7">
                  Please remove duplicate entries within your file, and upload
                  it again. All duplicate entries will be excluded from the
                  import.
                </p>
                {duplicatedUsers.map((user, i) => (
                  <p className="ml-1 font-basis-bold" key={user.email + i}>
                    • {user.email}
                  </p>
                ))}
              </div>
            )}
          </div>
        </Note>
      )}

      <div className="mt-3">
        <p className="mb-1 text-base2">Employees for assignment:</p>
        {!completed && paginatedEmployees.length === 0 && (
          <p className="mb-1 text-base">Scanning the file...</p>
        )}
        {completed && paginatedEmployees.length === 0 && (
          <p className="mb-1 text-base">No valid users found in the file.</p>
        )}

        <ListItems>
          {paginatedEmployees.map((employee) => (
            <li
              key={employee.value}
              className="relative items-start border-b lg:items-center hover:z-10 focus-within:z-10 border-hka_gray-light"
            >
              <img
                className="mr-2 user-img"
                src={employee.avatar}
                alt={employee.avatar}
              />
              <div className="flex flex-col items-start flex-1 flex-grow lg:flex-row flex-nowrap lg:items-center">
                <p className="flex flex-1 leading-3 flex-nowrap text-hka_gray-dark">
                  {employee.label}
                </p>
              </div>
              <div className="flex justify-end space-x-1 lg:pl-4">
                <button
                  onClick={() => setRemovalWarning(employee)}
                  className="relative px-0 bg-hka_red-light hover:bg-red-200 "
                  data-tip="Remove User"
                >
                  <span className="text-hka_red icon-trash"></span>
                </button>
              </div>
            </li>
          ))}
          {uploadedCsvUsers.length > pageSize && (
            <div className="mt-2">
              <Paginator
                pageSize={pageSize}
                count={total}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          )}
        </ListItems>
      </div>
      {!!removalWarning && (
        <Modal
          show={true}
          title={`Remove ${removalWarning.name}?`}
          onClose={() => setRemovalWarning(undefined)}
          onSubmit={removeEmployee}
          submitButtonText="Remove Employee"
          submitButtonType="danger"
          compact
        ></Modal>
      )}
    </section>
  );
};

export default CsvValidatedResult;
