export const formatAvatar = ({
  name,
  width = 4,
  textSize = "text-base",
  marginRight = 2,
}) => {
  return (
    <div
      className={`flex items-center justify-center w-${width} h-${width} mr-${marginRight} rounded-md bg-orange`}
    >
      {name
        .split(" ")
        .map((el, i) => (
          <span
            key={i}
            className={`text-white font-basis-bold ${textSize} ${
              i % 2 === 0 ? "mr-0.1" : ""
            }`}
          >
            {el.substring(0, 1)}
          </span>
        ))
        .slice(0, 2)}
    </div>
  );
};
