import Modal from "../../../Modal/Modal";

const IntegrationDeleteConfirmationModal = ({
  integration,
  showModal,
  onSubmit,
  onClose,
}) => {
  const btnText = integration.charAt(0).toUpperCase() + integration.slice(1);

  return (
    <Modal
      show={showModal}
      title={`Remove ${btnText}`}
      submitButtonText={`Remove ${btnText}`}
      submitButtonType="danger"
      onSubmit={onSubmit}
      onClose={onClose}
      compact
    >
      <p>Are you sure you want to remove {btnText}?</p>
    </Modal>
  );
};

export default IntegrationDeleteConfirmationModal;
