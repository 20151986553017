import React, { useState, useEffect, useContext } from 'react';
import Loader from '../components/Loader';
import { useAppContext } from '../libs/contextLib';
import { onError } from '../libs/errorLib';
import * as api from '../api';
import InstallSlackApp from '../components/AppInstallation/InstallSlackApp';
import Trainings from './Trainings';
import Callout from '../components/Callout';
import Statistics from '../components/Home/Statistics';
import TopPerformers from '../components/Home/TopPerformers';
import AssignEmployeesContext from '../store/assign-employeees-context';
import TransitionWrapper from '../components/Base/TransitionWrapper';
import { slackVariables } from '../config';
import { prefetchEngagementsData } from '../hooks/data-hooks/engagements';
import {
  prefetchTrainingsTagsData,
  prefetchTrainingsWithAssigmentData,
} from '../hooks/data-hooks/trainings';
import { useQueryClient } from '@tanstack/react-query';
import { getLoggedInUserDetails } from '../utils/commonUtils';

export default function Home() {
  const {
    isAuthenticated,
    connectedWithSlack,
    appInstalled,
    setAppInstalled,
    featureFlags,
  } = useAppContext();

  const queryClient = useQueryClient();

  const { onImportUsers, missingScopesError, setMissingScopesError } =
    useContext(AssignEmployeesContext);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [employeesWithTrainingAssigned, setEmployeesWithTrainingAssigned] =
    useState(0);
  const [topPerformers, setTopPerformers] = useState([]);
  const [finishedTrainingTotal, setFinishedTrainingTotal] = useState(0);
  const [finishedTraining30, setFinishedTraining30] = useState(0);
  const [finishedTraining60, setFinishedTraining60] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [finishedEmployeeLessonsTotal, setFinishedEmployeeLessonsTotal] =
    useState(0);
  const [finishedEmployeeLessons30, setFinishedEmployeeLessons30] = useState(0);
  const [finishedEmployeeLessons60, setFinishedEmployeeLessons60] = useState(0);
  const [activeCompanyTrainings, setActiveCompanyTrainings] = useState([]);
  const [activeEngagementsCount, setActiveEngagementsCount] = useState(0);
  const [finishedEngagementsInLast30Days, setFinishedEngagementsInLast30Days] =
    useState(0);
  const [finishedEngagementsOfAllTime, setFinishedEngagementsOfAllTime] =
    useState(0);
  const [engagementTrendsInLast30Days, setEngagementTrendsInLast30Days] =
    useState(null);
  const [engagementTrendsOfAllTime, setEngagementTrendsOfAllTime] =
    useState(null);
  const [hasPhishingAccess, setHasPhishingAccess] = useState(false);
  const [phishingMetrics, setPhishingMetrics] = useState(null);

  const setEngagementMetrics = (engagementMetrics) => {
    setFinishedEngagementsInLast30Days(
      engagementMetrics.completed_last_30_days
    );
    setFinishedEngagementsOfAllTime(engagementMetrics.completed_all_time);
    setActiveEngagementsCount(engagementMetrics.company_weekly_quiz_count);
    setEngagementTrendsInLast30Days(
      engagementMetrics.trends_for_the_last_30_days
    );
    setEngagementTrendsOfAllTime(engagementMetrics.trends_of_all_time);
  };

  // prefetch data
  prefetchEngagementsData(queryClient);
  prefetchTrainingsWithAssigmentData({
    queryClient,
    companyID: isAuthenticated.company,
  });
  prefetchTrainingsTagsData({
    queryClient,
    companyID: isAuthenticated.company,
    enabled: isAuthenticated.is_admin,
  });

  useEffect(() => {
    onImportUsers();
  }, [onImportUsers]);

  useEffect(() => {
    const getStats = () => {
      return api.getTrainingsStats({
        auth: isAuthenticated,
      });
    };
    const completed_top = () => {
      return api.companyTop({ auth: isAuthenticated });
    };
    const onLoad = async () => {
      try {
        const [stats, employeeDetails, engagementMetrics, topPerformers] =
          await Promise.all([
            getStats(),
            getLoggedInUserDetails({
              auth: isAuthenticated,
            }),
            api.getCompanyEngagementMetrics({ auth: isAuthenticated }),
            completed_top(),
          ]);

        const { has_phishing_access } = employeeDetails.company_details;

        setHasPhishingAccess(has_phishing_access || false);

        setActiveCompanyTrainings(stats.active_trainings);
        setFinishedTrainingTotal(stats.employee_completed_trainings);
        setFinishedTraining30(stats.employee_completed_trainings_30);
        setFinishedTraining60(stats.employee_completed_trainings_30_60);
        setFinishedEmployeeLessonsTotal(stats.employee_finished_lesson);
        setFinishedEmployeeLessons30(stats.employee_finished_lesson_30);
        setFinishedEmployeeLessons60(stats.employee_finished_lesson_30_60);
        setTotalEmployees(stats.total_employees);
        setEmployeesWithTrainingAssigned(
          stats.employees_with_training_assigned
        );

        setEngagementMetrics(engagementMetrics);

        if (featureFlags?.PHISHING && has_phishing_access) {
          const phishingMetrics = await api.getCompanyPhishingMetrics({
            auth: isAuthenticated,
          });

          setPhishingMetrics(phishingMetrics);
        }

        setTopPerformers(topPerformers.filter((tp) => !!tp.employee));

        setIsLoading(false);
      } catch (e) {
        onError(e);
      }
    };
    onLoad();
  }, []);

  if (isLoading)
    return (
      <TransitionWrapper show={isLoading}>
        <Loader fullscreen />
      </TransitionWrapper>
    );

  return (
    <>
      <div className='h-full space-y-6'>
        {isAuthenticated.loggedInWith === 'SLACK' && !connectedWithSlack ? (
          <InstallSlackApp />
        ) : (
          <div className='dashboard'>
            <>
              {isAuthenticated.is_admin ? (
                <>
                  <header className='mb-2 sm:mb-4'>
                    <h1>Dashboard</h1>
                  </header>
                  {missingScopesError && !appInstalled && (
                    <div className='callout--app-installed'>
                      <Callout
                        title={'🚨 Missing Scope Error!'}
                        message={
                          <>
                            Hello! Haekka has updated it's Slack Scopes.
                            <br />
                            You need to reinstall the Haekka Slack app. Please
                            click{' '}
                            <a
                              href={slackVariables.shareableSlackAppUrl}
                              className='cursor-pointer text-orange link'
                            >
                              this link
                            </a>{' '}
                            to do so.
                          </>
                        }
                        date={false}
                        onClose={() => setMissingScopesError(false)}
                      ></Callout>
                    </div>
                  )}

                  {appInstalled && (
                    <div className='callout--app-installed'>
                      <Callout
                        title={'🙌 Haekka Slack app installed!'}
                        message={
                          <>
                            We've already started to import users from your
                            Slack workspace — It can take a while depending on
                            how big your workspace is. <br />
                            We'll send you a notification once this process is
                            complete.
                          </>
                        }
                        date={false}
                        onClose={() => setAppInstalled(false)}
                      ></Callout>
                    </div>
                  )}

                  <Statistics
                    activeCompanyTrainings={activeCompanyTrainings}
                    finishedTraining30={finishedTraining30}
                    finishedTrainingTotal={finishedTrainingTotal}
                    finishedTraining60={finishedTraining60}
                    finishedEmployeeLessons30={finishedEmployeeLessons30}
                    finishedEmployeeLessonsTotal={finishedEmployeeLessonsTotal}
                    finishedEmployeeLessons60={finishedEmployeeLessons60}
                    totalEmployees={totalEmployees}
                    isAuthenticated={isAuthenticated}
                    activeEngagementsCount={activeEngagementsCount}
                    finishedEngagementsOfAllTime={finishedEngagementsOfAllTime}
                    engagementTrendsInLast30Days={engagementTrendsInLast30Days}
                    engagementTrendsOfAllTime={engagementTrendsOfAllTime}
                    finishedEngagementsInLast30Days={
                      finishedEngagementsInLast30Days
                    }
                    employeesWithTrainingAssigned={
                      employeesWithTrainingAssigned
                    }
                    hasPhishingAccess={hasPhishingAccess}
                    phishingMetrics={phishingMetrics}
                  />

                  <TopPerformers topPerformers={topPerformers} />

                  <footer className='pb-2'></footer>
                </>
              ) : (
                <Trainings />
              )}
            </>
          </div>
        )}
      </div>
    </>
  );
}
