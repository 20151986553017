import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useAppContext } from '../libs/contextLib';
import * as api from '../api';
import { onError } from '../libs/errorLib';
import ListItems from '../components/ListItems';
import ListItemUserRemove from '../components/ListItemUserRemove';
import toast from 'react-hot-toast';
import Loader from '../components/Loader';
import ActionButton from '../components/ActionButton';
import AddAdminModal from '../components/AddAdminModal';
import AssignEmployeesContext, {
  OBJECT_TYPES,
} from '../store/assign-employeees-context';
import TransitionWrapper from '../components/Base/TransitionWrapper';
import RemoveAdminModal from '../components/Admins/RemoveAdminModal';

import { useEmployeesData } from '../hooks/data-hooks/employees/useEmployeesData';
import { useQueryClient } from '@tanstack/react-query';

export default function Admins() {
  const { isAuthenticated } = useAppContext();
  const queryClient = useQueryClient();
  const [adminUserToBeDeleted, setAdminUserToBeDeleted] = useState(null);
  const [deleteAdminSubmitting, setDeleteAdminSubmitting] = useState(false);

  const assignEmployeesContext = useContext(AssignEmployeesContext);
  const { setObjectType, onImportUsers, setModalOpened, modalOpened } =
    assignEmployeesContext;

  useEffect(() => {
    onImportUsers();
    setObjectType(OBJECT_TYPES.ADMIN);
  }, [onImportUsers, setObjectType]);

  const { data: admins, isLoading } = useEmployeesData({
    auth: isAuthenticated,
    page: 1,
    pageSize: 100,
    isAdmin: true,
  });

  async function handleRemove() {
    const user = adminUserToBeDeleted;
    setDeleteAdminSubmitting(true);
    try {
      await api.deleteEmployeeAdmin({
        auth: isAuthenticated,
        employeeId: user.id,
      });
      setAdminUserToBeDeleted(null);
      queryClient.invalidateQueries(['employees']);
      toast.success(`${user.name} was removed from admins`);
    } catch (e) {
      onError(e);
    }
    setDeleteAdminSubmitting(false);
  }

  if (isLoading) {
    return (
      <TransitionWrapper show={isLoading}>
        <Loader />
      </TransitionWrapper>
    );
  }

  return (
    <Fragment>
      <TransitionWrapper show={isLoading}>
        <Loader fullscreen />
      </TransitionWrapper>
      <header className='flex justify-between mb-2 sm:mb-4'>
        <h1>Admins</h1>
        <ActionButton onClick={() => setModalOpened(true)}>
          <span className='h-3 sm:-ml-1 mb-0.3 icon-plus text-hka_blue'></span>
          <span className='hidden sm:inline-block'>Add Admin</span>
        </ActionButton>
      </header>
      <ListItems>
        {admins.results.map((employee) => (
          <ListItemUserRemove
            key={employee.id}
            name={employee.name}
            avatarImage={employee.avatar_image}
            onRemove={() => setAdminUserToBeDeleted(employee)}
          />
        ))}
      </ListItems>
      {adminUserToBeDeleted !== null && (
        <RemoveAdminModal
          showModal={true}
          onSubmit={handleRemove}
          onClose={() => setAdminUserToBeDeleted(null)}
          submitValid={!deleteAdminSubmitting}
        />
      )}
      <AddAdminModal
        admins={admins.results}
        show={modalOpened}
        onClose={() => setModalOpened(false)}
        assignEmployeesContext={assignEmployeesContext}
      />
    </Fragment>
  );
}
