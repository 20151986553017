import { useState } from 'react';
import { useAppContext } from '../../../libs/contextLib';
import {
  useRemoveExternalAppSettings,
  useUpdateExternalAppSettings,
} from '../../../hooks/data-hooks/integrations';
import LoaderInline from '../../LoaderInline';
import { useEffect } from 'react';
import Note from '../../Note';
import SeismicConfigurationModal from './SeismicConfigurationModal';
import RemoveExternalAppConfirmModal from './RemoveExternalAppConfirmModal';

const SeismicIntegration = ({ activeSeismicApp, isLoadingExternalApps }) => {
  const { isAuthenticated: auth } = useAppContext();
  const [seismicSubdomainModal, setSeismicSubdomainModal] = useState(false);

  const [syncEmployees, setSyncEmployees] = useState(true);
  const [changedWarning, setChangeWarning] = useState(false);
  const [removeAppWarning, setRemoveAppWarning] = useState(false);

  useEffect(() => {
    if (!isLoadingExternalApps && !!activeSeismicApp) {
      setSyncEmployees(activeSeismicApp.sync_employees);
    }
  }, [activeSeismicApp, isLoadingExternalApps]);

  const { mutate: updateSeismicInstallation, isLoading: submittingSettings } =
    useUpdateExternalAppSettings({
      resourcetype: 'SeismicInstallation',
      companyID: auth.company,
    });

  const { mutate: removeSeismicInstallation, isLoading: removingSeismicApp } =
    useRemoveExternalAppSettings({
      resourcetype: 'SeismicInstallation',
      integration: 'seismic',
      companyID: auth.company,
    });

  const updateSeismicInstallationHandler = () => {
    updateSeismicInstallation({
      companyID: auth.company,
      integrationID: activeSeismicApp.id,
      settings: {
        sync_employees: syncEmployees,
      },
    });
    setChangeWarning(false);
  };

  const removeSeismicInstallationHandler = () => {
    removeSeismicInstallation({
      companyID: auth.company,
      integrationID: activeSeismicApp.id,
    });
    setRemoveAppWarning(false);
  };

  const syncEmployeesHandler = () => {
    setSyncEmployees((s) => !s);
    setChangeWarning(true);
  };

  if (
    isLoadingExternalApps ||
    (syncEmployees === undefined && !!activeSeismicApp)
  ) {
    return <LoaderInline wrapperClasses='m-3' />;
  }

  return (
    <div className='p-2 mb-4 bg-white shadow-light rounded-xl col-span-full'>
      <h3 className='mb-1 setting__title'>Seismic Integration</h3>
      {!activeSeismicApp && (
        <section className='w-full md:w-max-content mt-2 flex flex-col rounded-md border border-hka_gray-light p-1.5 min-w-20 relative'>
          <div className='h-4 mb-3 overflow-hidden'>
            <img
              src='https://haekka-public-images.s3.us-west-2.amazonaws.com/seismic-logo.svg'
              alt='Seismic Logo'
              className='w-10'
            />
          </div>
          <button
            className='w-full button button--save'
            onClick={() => setSeismicSubdomainModal(true)}
          >
            Link with Seismic
          </button>
        </section>
      )}

      {!!activeSeismicApp && (
        <section>
          <img
            src='https://haekka-public-images.s3.us-west-2.amazonaws.com/seismic-logo.svg'
            alt='Seismic Logo'
            className='w-10 pt-1'
          />

          <section className='flex w-full p-2 gap-2 items-start justify-start mt-1'>
            <label className='switch'>
              <input
                type='checkbox'
                name='acknowledge_button'
                checked={syncEmployees}
                onChange={syncEmployeesHandler}
                className='cursor-pointer'
              />
              <span className='slider'></span>
            </label>
            <section className='flex flex-col gap-1'>
              <h4 className='cursor-pointer' onClick={syncEmployeesHandler}>
                Sync Employees
              </h4>
              <p>
                <span className='font-basis-bold'>On by default</span>, Haekka
                will automatically sync users from your Seismic account on a
                daily basis.
              </p>
            </section>
          </section>

          {changedWarning && (
            <section className='mb-2'>
              <Note type='warning'>
                <p className='py-0.2'>
                  Your changes have not been saved. Please click the save button
                  below.
                </p>
              </Note>
            </section>
          )}

          <div className='flex flex-col items-center justify-start w-full gap-1 md:flex-row'>
            <button
              className={`w-full button button--save md:w-auto`}
              onClick={() => setSeismicSubdomainModal(true)}
              type='secondary'
            >
              Update Credentials
            </button>

            <button
              className={`w-full button button--remove md:w-auto`}
              onClick={() => setRemoveAppWarning(true)}
              type='danger'
              disabled={removingSeismicApp}
            >
              Disconnect Seismic
            </button>

            {changedWarning && (
              <button
                className={`w-full button button--save md:w-auto`}
                onClick={updateSeismicInstallationHandler}
                type='success'
                disabled={submittingSettings}
              >
                Save
              </button>
            )}
          </div>
        </section>
      )}

      {seismicSubdomainModal && (
        <SeismicConfigurationModal
          onClose={() => setSeismicSubdomainModal(false)}
        />
      )}

      {removeAppWarning && (
        <RemoveExternalAppConfirmModal
          onSubmit={removeSeismicInstallationHandler}
          onClose={() => setRemoveAppWarning(false)}
          integrationCaption='Seismic'
        />
      )}
    </div>
  );
};

export default SeismicIntegration;
