import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { EditorState } from 'draft-js';
import Select from 'react-select';
import toast from 'react-hot-toast';
import validator from 'validator';
import ReactDatePicker from 'react-datepicker';
import { addHours } from 'date-fns';

import './date-picker.css';

import { useAppContext } from '../../../libs/contextLib';
import {
  useCreateStreamPost,
  usePostCategoriesData,
  useUpdateSentPosts,
  useUpdateStreamPost,
} from '../../../hooks/data-hooks/streams';
import { updateLessonImage } from '../../../api';

import Modal from '../../Modal/Modal';
import WysiwygRichTextEditor from '../../WysiwygEditor/WysiwygRichTextEditor';
import { customStyles } from '../../Workflows/CreateEditWorkflowModal/CreateEvent';
import LoaderInline from '../../LoaderInline';
import { optimizeSlackMarkup } from '../../../utils/lessonObjectHelperFunctions';
import { getEditorState } from '../../../utils/wysiwygEditorHelpers';
import ActionButton from '../../ActionButton';

const PostModal = ({ selectedPost, modalMode, stream, onClose }) => {
  const queryClient = useQueryClient();
  const { isAuthenticated } = useAppContext();
  const isGlobalContentWriter =
    isAuthenticated.company_details.is_global_content_maker;

  const [id, setId] = useState(undefined);
  const [title, setTitle] = useState('');
  const [videoURL, setVideoURL] = useState('');
  const [isVideoURLValid, setIsVideoURLValid] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [adminsOnly, setAdminsOnly] = useState(false);
  const [time, setTime] = useState(addHours(new Date(), 2).toISOString());
  const [sendNow, setSendNow] = useState(false);
  const [acknowledgeButton, setAcknowledgeButton] = useState(false);
  const [includeRating, setIncludeRating] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [addCategoryField, setAddCategoryField] = useState(false);
  const [categories, setCategories] = useState([]);
  const [editorRelatedImages, setEditorRelatedImages] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [postContentES, setPostContentES] = useState(null);

  const { data: categoriesData, isLoading: loadingCategories } =
    usePostCategoriesData({ page: 1 });

  useEffect(() => {
    if (selectedPost) {
      setId(selectedPost.id);
      setTitle(selectedPost.title);
      setVideoURL(selectedPost.video_url ?? '');
      if ((selectedPost.video_url ?? '').length > 0) {
        setIsVideoURLValid(validator.isURL(selectedPost.video_url));
      }
      setSelectedCategory({
        label: selectedPost.category.title,
        value: selectedPost.category.id,
      });
      setAdminsOnly(selectedPost.send_to_admins_only);
      setTime(new Date(selectedPost.send_time).toISOString());
      setSendNow(selectedPost.send_now);
      setAcknowledgeButton(selectedPost.acknowledge_button);
      setIncludeRating(selectedPost.include_rating);
      setIsEditable(selectedPost.is_editable);
      setIsSent(selectedPost.is_sent);
    }
  }, [selectedPost]);

  const { mutate: createPost, isLoading: isSubmittingCreate } =
    useCreateStreamPost({
      onSuccess: (res) => {
        onClose();
        toast.success(`Stream Post created`);
        queryClient.invalidateQueries(['streams']);
        if (editorRelatedImages.length > 0) {
          updateLessonImage({
            auth: isAuthenticated,
            id: res.id,
            imagesIds: editorRelatedImages,
            contentType: 'stream_post',
          });
        }
      },
    });

  const { mutate: updateSentPost } = useUpdateSentPosts();

  const { mutate: updatePost, isLoading: isSubmittingUpdate } =
    useUpdateStreamPost({
      onSuccess: () => {
        if (isGlobalContentWriter && selectedPost.is_sent) {
          // update sent posts
          updateSentPost({ postID: selectedPost.id });
        }
        toast.success(`Stream Post updated`);
        queryClient.invalidateQueries(['streams']);
        onClose();
      },
    });

  useEffect(() => {
    setCategories(
      categoriesData?.results.map((c) => ({ label: c.title, value: c.id }))
    );
  }, [categoriesData]);

  useEffect(() => {
    if ((videoURL ?? '').length === 0) {
      setIsVideoURLValid(true);
      return;
    }
    setIsVideoURLValid(validator.isURL(videoURL));
  }, [videoURL]);

  useEffect(() => {
    if (!acknowledgeButton) {
      setIncludeRating(false);
    }
  }, [acknowledgeButton]);

  useEffect(() => {
    setIsFormValid(
      title.length > 0 &&
        postContentES?.getCurrentContent().getPlainText('\u0001') !== '' &&
        !!selectedCategory &&
        (videoURL ? isVideoURLValid : true) &&
        !isSubmittingCreate &&
        !isSubmittingUpdate
    );
  }, [
    postContentES,
    selectedCategory,
    title,
    videoURL,
    isVideoURLValid,
    isSubmittingCreate,
    isSubmittingUpdate,
  ]);

  useEffect(() => {
    const initializePostES = async () => {
      const state = await getEditorState(
        selectedPost?.editor_content.editor_state,
        selectedPost?.editor_content,
        null,
        false
      );
      setPostContentES(state);
    };
    if (modalMode === 'CREATE_POST') {
      setPostContentES(EditorState.createEmpty());
    } else {
      initializePostES();
    }
  }, [modalMode, isAuthenticated, selectedPost?.editor_content]);

  const addImageHandler = (imageID) => {
    setEditorRelatedImages((prev) => [...prev, imageID]);
  };

  const saveCategoryHandler = () => {
    const composedCategory = {
      label: newCategory,
    };
    setCategories((prev) => [...prev, composedCategory]);
    setSelectedCategory(composedCategory);
    setNewCategory('');
    setAddCategoryField(false);
  };

  const composePayload = async () => {
    const { editor_content, slackMarkup } = await optimizeSlackMarkup(
      selectedPost?.editor_content?.id,
      postContentES
    );

    return {
      id,
      title,
      video_url: videoURL,
      post_content: slackMarkup,
      editor_content,
      send_to_admins_only: adminsOnly,
      send_time: time,
      send_now: sendNow,
      acknowledge_button: acknowledgeButton,
      include_rating: includeRating,
      is_editable: isEditable,
      stream_post_category: {
        id: selectedCategory?.value,
        title: selectedCategory.label,
      },
      stream,
    };
  };

  const createPostActionHandler = async () => {
    if (!isGlobalContentWriter) {
      return;
    }

    createPost({ payload: await composePayload() });
  };

  const editPostActionHandler = async () => {
    if (!isGlobalContentWriter && selectedPost.is_sent) {
      return;
    }

    // updating scheduled Post
    updatePost({
      postID: selectedPost.id,
      payload: await composePayload(),
    });
  };

  const submitHandler = async () => {
    if (modalMode === 'CREATE_POST') {
      createPostActionHandler();
    } else {
      editPostActionHandler();
    }
  };

  const showPostIsSentWarning = isGlobalContentWriter && isSent;

  return (
    <Modal
      show={true}
      title={modalMode === 'CREATE_POST' ? 'Create Post' : 'Edit Post'}
      onClose={onClose}
      onSubmit={submitHandler}
      submitButtonText={
        modalMode === 'CREATE_POST' ? 'Create Post' : 'Update Post'
      }
      submitValid={isFormValid}
      expandable
    >
      {showPostIsSentWarning && (
        <section className='mb-2.5 bg-orange-dark p-1.5 rounded-lg'>
          <p className='mb-1 text-white'>
            This warning is visible to Haekka Writer only.
          </p>
          <p className='mb-1 text-white'>
            This Post is already sent. After you edit it and hit the Update Post
            button below, the Post will be updated and messages sent to users in
            Slack will be updated.
          </p>
          <p className='text-white'>
            The action could take a while to complete, depending on the number
            of messages to update.
          </p>
        </section>
      )}

      <section className='mb-2.5'>
        <h4 className='mb-1 font-basis-bold'>
          Post Name <span className='text-hka_gray'>(Required)</span>
        </h4>
        <input
          type='text'
          name='title'
          value={title}
          placeholder='Enter Post Name'
          className='w-full h-4 pl-1 pr-1 mb-1 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray-dark border-hka_gray-lights hka-input'
          onChange={(e) => setTitle(e.target.value)}
        />
      </section>

      <section className='mb-2.5'>
        <h4 className='mb-1 font-basis-bold'>
          Video URL <span className='text-hka_gray'>(Optional)</span>
        </h4>
        <input
          type='text'
          name='video_url'
          value={videoURL}
          placeholder='Enter Video URL'
          className='w-full h-4 pl-1 pr-1 mb-1 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray-dark border-hka_gray-lights hka-input'
          onChange={(e) => setVideoURL(e.target.value)}
        />
        {!isVideoURLValid && (
          <span className='block text-xs text-hka_red top-full'>
            Please enter a valid URL
          </span>
        )}
      </section>

      <section className='mb-3'>
        <h4 className='mb-1 font-basis-bold'>
          Post Content <span className='text-hka_gray'>(Optional)</span>
        </h4>
        <WysiwygRichTextEditor
          editorState={postContentES}
          onSetEditorState={(state) => setPostContentES(state)}
          id={id}
          onImageAdded={addImageHandler}
          contentType={'stream_post'}
        />
      </section>

      {isGlobalContentWriter && (
        <>
          <section className='mb-3'>
            <h4 className='mb-1 font-basis-bold'>Post Category</h4>
            {loadingCategories ? (
              <LoaderInline wrapperClasses={'w-full min-h-2'} loaderSize={3} />
            ) : (
              <div>
                <div>
                  {addCategoryField ? (
                    <>
                      <input
                        autoFocus
                        type='text'
                        name='newCategory'
                        value={newCategory}
                        placeholder='Enter new category'
                        className='w-full h-4 pl-1 pr-1 mb-1 text-base border rounded-lg focus:outline-none focus:border-hka-blue text-hka_gray-dark border-hka_gray-lights hka-input'
                        onChange={(e) => setNewCategory(e.target.value)}
                      />
                      <div className='flex -mt-1.2 mb-1'>
                        <div className='ml-2'>
                          <ActionButton
                            type={'button--icon'}
                            onClick={saveCategoryHandler}
                          >
                            <span className='hidden sm:inline-block text-orange-dark '>
                              Save Category
                            </span>
                          </ActionButton>
                        </div>

                        <div className='ml-3'>
                          <ActionButton
                            type={'button--icon'}
                            onClick={() => setAddCategoryField(false)}
                          >
                            <span className='hidden sm:inline-block text-orange-dark '>
                              Cancel
                            </span>
                          </ActionButton>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Select
                        onChange={(category) => setSelectedCategory(category)}
                        value={selectedCategory}
                        placeholder='Select Category'
                        isMulti={false}
                        options={categories}
                        classNamePrefix='select'
                        styles={customStyles}
                        maxMenuHeight={140}
                        className='w-full'
                        unstyled
                      />
                      <div className='relative'>
                        <ActionButton
                          type={'button--icon'}
                          onClick={() => setAddCategoryField(true)}
                        >
                          <span className='absolute left-0 hidden sm:inline-block text-orange-dark'>
                            Add New Category
                          </span>
                        </ActionButton>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </section>
          {!showPostIsSentWarning && (
            <>
              <section className='mb-3'>
                <h4 className='mb-1 font-basis-bold'>
                  Who should get this Post?
                </h4>

                <section className='mb-1.5 flex'>
                  <section className='flex items-center'>
                    <label className='switch'>
                      <input
                        type='checkbox'
                        name='admins_only'
                        checked={adminsOnly}
                        onChange={(e) => setAdminsOnly(e.target.checked)}
                        className='cursor-pointer'
                      />
                      <span className='slider'></span>
                    </label>
                  </section>
                  <h4 className='ml-1 font-basis-bold'>Admins Only</h4>
                </section>

                <section className='mb-1.5 flex'>
                  <section className='flex items-center'>
                    <label className='switch'>
                      <input
                        type='checkbox'
                        name='all_employees'
                        checked={!adminsOnly}
                        onChange={(e) => setAdminsOnly(!e.target.checked)}
                        className='cursor-pointer'
                      />
                      <span className='slider'></span>
                    </label>
                  </section>
                  <h4 className='ml-1 font-basis-bold'>
                    All Employees (Including Admins)
                  </h4>
                </section>
              </section>

              <section className='mb-3'>
                <h4 className='mb-1 font-basis-bold'>Send Date</h4>
                <p className='mb-1'>
                  Set the date for this Post to be sent, or select 'Send now' to
                  immediately send.
                </p>
                <div className='flex flex-col gap-2 mb-2 sm:flex-row'>
                  <div className='flex items-center justify-center w-full sm:w-1/2 customDatePickerWidthDate'>
                    <ReactDatePicker
                      minDate={new Date()}
                      className={`w-full h-4 pl-4 pr-1 focus:outline-none disabled:bg-hka_gray-light text-hka_gray datepicker`}
                      selected={new Date(time)}
                      onChange={(date) => setTime(date.toISOString())}
                      dateFormat='PP'
                      disabled={sendNow}
                    />
                  </div>
                  <div className='flex items-center justify-center w-full sm:w-1/2 customDatePickerWidthTime'>
                    <ReactDatePicker
                      selected={time ? new Date(time) : new Date()}
                      onChange={(date) => setTime(date.toISOString())}
                      className={`w-full h-4 pl-4 pr-1 focus:outline-none disabled:bg-hka_gray-light text-hka_gray datepicker`}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption='Time'
                      dateFormat='h:mm aa'
                      disabled={sendNow}
                    />
                  </div>
                </div>
                <section className='mb-1.5 flex'>
                  <section className='flex items-center'>
                    <label className='switch'>
                      <input
                        type='checkbox'
                        name='send_now'
                        checked={sendNow}
                        onChange={(e) => setSendNow(e.target.checked)}
                        className='cursor-pointer'
                      />
                      <span className='slider'></span>
                    </label>
                  </section>
                  <h4 className='ml-1 font-basis-bold'>Send Now</h4>
                </section>
              </section>

              <section className='mb-3'>
                <h4 className='mb-1 font-basis-bold'>Feedback</h4>

                <section className='mb-1.5 flex'>
                  <section className='flex items-center'>
                    <label className='switch'>
                      <input
                        type='checkbox'
                        name='acknowledge_button'
                        checked={acknowledgeButton}
                        onChange={(e) => setAcknowledgeButton(e.target.checked)}
                        className='cursor-pointer'
                      />
                      <span className='slider'></span>
                    </label>
                  </section>
                  <h4 className='ml-1 font-basis-bold'>
                    Include an 'Acknowledge' button
                  </h4>
                </section>

                <section className='mb-1.5 flex'>
                  <section className='flex items-center'>
                    <label className='switch'>
                      <input
                        type='checkbox'
                        name='include_rating'
                        checked={includeRating}
                        disabled={!acknowledgeButton}
                        onChange={(e) => setIncludeRating(e.target.checked)}
                        className='cursor-pointer'
                      />
                      <span className='slider'></span>
                    </label>
                  </section>
                  <h4 className='ml-1 font-basis-bold'>Include a rating</h4>
                </section>
              </section>
            </>
          )}

          {!showPostIsSentWarning && (
            <section className='mb-2.5'>
              <h4 className='mb-1 font-basis-bold'>
                Configure settings for administrators
              </h4>

              {isAuthenticated.company_details.is_global_content_maker && (
                <section className='mb-1.5 flex'>
                  <section className='flex items-center'>
                    <label className='switch'>
                      <input
                        type='checkbox'
                        name='is_editable'
                        checked={isEditable}
                        onChange={(e) => setIsEditable(e.target.checked)}
                        className='cursor-pointer'
                      />
                      <span className='slider'></span>
                    </label>
                  </section>
                  <h4 className='ml-1 font-basis-bold'>
                    Should this Post be editable by Admins
                  </h4>
                </section>
              )}
            </section>
          )}
        </>
      )}
    </Modal>
  );
};

export default PostModal;
