import React from "react";
import Divider from "../../Base/Divider";
import Slider from "../../Base/Slider";

export default function NotificationScheduleDayRow({ day, enabled, onUpdate }) {
  return (
    <div>
      <div className="flex flex-row justify-between py-1 align-center">
        <h4 className="text-hka_gray setting__title">{day}</h4>
        <Slider
          checked={enabled}
          onChange={() =>
            onUpdate({
              [day.toLowerCase()]: !enabled,
            })
          }
        />
      </div>
      <Divider />
    </div>
  );
}
