import { useMutation, useQueryClient } from "@tanstack/react-query";
import { onError } from "../../../libs/errorLib";
import { engagementEmployeeUnassign } from "../../../api/engagementsApi";

export const useRemoveEmployee = ({ onClose }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: engagementEmployeeUnassign,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["engagement-employee-results"],
      });
      onClose();
    },
    onError: (_error) => onError(_error),
  });
};
