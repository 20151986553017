import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../libs/contextLib';
import { onError } from '../../libs/errorLib';
import * as api from '../../api';
import toast from 'react-hot-toast';
import Modal from '../Modal/Modal';
import EditLesson from './EditLesson';
import { modalModes } from '../../utils/commonUtils';
import {
  composeLessonPayload,
  handleCustomVideo,
  initializeEditors,
  initializeLesson,
  lessonProperties,
  validateEditLessonForm,
} from '../../utils/trainingUtils';
import { useSelector, useDispatch } from 'react-redux';
import { lessonActions } from '../../store/lessonSlice';
import {
  useAddLesson,
  useUpdateLesson,
} from '../../hooks/data-hooks/trainings';
import { useQueryClient } from '@tanstack/react-query';

const EditLessonModal = ({
  show,
  curriculumId,
  mode,
  lesson,
  nextLessonPosition,
  position,
  isHaekkaOwner,
  setEditLessonOpened,
  trainingScenarioType,
}) => {
  const queryClient = useQueryClient();
  const { isAuthenticated } = useAppContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const dispatch = useDispatch();
  const { uploadsInProgress } = useSelector((state) => state.videoUpload);
  const {
    isSimpleLesson,
    answers,
    title,
    subtitle,
    videoURL,
    externalResources,
    allowMultipleCorrectAnswers,
    fetchedLesson,
    editorImages,
    scenarioType,
    videoURLValid,
    externalResourcesValid,
    showToBrowserLinkInSlack,
  } = useSelector((state) => state.lesson);

  // EditorState is JS object, and Redux does not allow to store such a
  // non-serializable object in state. Therefore, we're handling these here:
  const [notesEditorState, setNotesEditorState] = useState(undefined);
  const [summaryEditorState, setSummaryEditorState] = useState(undefined);
  const [questionEditorState, setQuestionEditorState] = useState(undefined);

  const onLessonAddedSuccess = (response) => {
    queryClient.invalidateQueries(['curriculum-content', curriculumId]);
    if (editorImages.length > 0) {
      api.updateLessonImage({
        auth: isAuthenticated,
        id: response.id,
        imagesIds: editorImages,
        contentType: 'lesson',
      });
    }
  };

  const { mutate: addLesson } = useAddLesson({
    curriculumID: curriculumId,
    onSuccess: onLessonAddedSuccess,
  });
  const { mutate: updateLesson } = useUpdateLesson({
    curriculumID: curriculumId,
  });

  const updateEditorState = (property, editorState) => {
    switch (property) {
      case 'notes':
        setNotesEditorState(editorState);
        break;
      case 'summary':
        setSummaryEditorState(editorState);
        break;
      case 'question':
        setQuestionEditorState(editorState);
        break;

      default:
        throw new Error('Wrong indentifier.');
    }
  };

  useEffect(() => {
    const isFormValid = validateEditLessonForm({
      title,
      isSimpleLesson,
      videoURLValid,
      externalResourcesValid,
      summaryEditorState,
      questionEditorState,
      answers,
    });
    setIsFormValid(isFormValid);
  }, [
    answers,
    externalResourcesValid,
    isSimpleLesson,
    questionEditorState,
    summaryEditorState,
    title,
    videoURLValid,
  ]);

  useEffect(() => {
    if (mode === modalModes.ADD) {
      dispatch(
        lessonActions.updateLesson({
          property: lessonProperties.IS_SIMPLE_LESSON,
          value: !isHaekkaOwner,
        })
      );
      initializeEditors(null, mode, updateEditorState);
      dispatch(lessonActions.setLoadingLesson(false));
    } else if (mode !== modalModes.ADD && lesson) {
      initializeLesson({
        isAuthenticated,
        lesson,
        dispatch,
        scenarioType,
        mode,
        updateEditorState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, isHaekkaOwner, lesson]);

  useEffect(() => {
    // TO-DO: improve this
    const loadEmojis = async () => {
      const emojis = await api.getEmojisJsonMapper({
        auth: isAuthenticated,
        file: 'emojis.json',
      });
      localStorage.setItem('haekka-emojis', emojis);

      const emojis_2 = await api.getEmojisJsonMapper({
        auth: isAuthenticated,
        file: 'emojis-2.json',
      });
      localStorage.setItem('haekka-emojis-2', emojis_2);
    };
    if (
      !localStorage.getItem('haekka-emojis') ||
      !localStorage.getItem('haekka-emojis-2')
    )
      loadEmojis();
  }, []);

  const closeModalHandler = () => {
    setEditLessonOpened(undefined);
    dispatch(lessonActions.resetStore());
  };

  const handleSubmit = async (event) => {
    setIsSubmitting(true);

    if (event) {
      event.preventDefault();
    }

    const lessonPayload = await composeLessonPayload({
      fetchedLesson,
      curriculumId,
      notesEditorState,
      summaryEditorState,
      questionEditorState,
      isSimpleLesson,
      position,
      title,
      subtitle,
      videoURL,
      externalResources,
      allowMultipleCorrectAnswers,
      answers,
      showToBrowserLinkInSlack,
      updateEditorState,
    });

    try {
      if (mode === modalModes.ADD) {
        lessonPayload.position = nextLessonPosition;
        const res = addLesson({
          lesson: lessonPayload,
        });
        toast.success(`Lesson added`);
        // check if video is envoled
        await handleCustomVideo({
          uploadsInProgress,
          nextLessonPosition,
          dispatch,
          fetchedLesson,
          isAuthenticated,
          lesson: res,
        });
      } else {
        updateLesson({
          lesson: lessonPayload,
        });
        toast.success(`${lesson.title} updated!`);
      }
      closeModalHandler();
    } catch (e) {
      onError(e);
      toast.error('Error ocured');
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      show={show}
      title={mode === modalModes.ADD ? 'Add Lesson' : 'Edit Lesson'}
      onClose={closeModalHandler}
      onSubmit={handleSubmit}
      submitButtonText='Save Lesson'
      submitValid={isSubmitting ? false : isFormValid}
      expandable
    >
      <EditLesson
        mode={mode}
        nextLessonPosition={nextLessonPosition}
        notesEditorState={notesEditorState}
        summaryEditorState={summaryEditorState}
        questionEditorState={questionEditorState}
        updateEditorState={updateEditorState}
        setEditLessonOpened={setEditLessonOpened}
        scenarioType={trainingScenarioType}
      />
    </Modal>
  );
};

export default EditLessonModal;
