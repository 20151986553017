import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ExternalAppInstallationsSuccess = () => {
  const { integration } = useParams();
  const navigate = useNavigate();
  const integrationCapitalized =
    integration.charAt(0).toUpperCase() + integration.slice(1);

  useEffect(() => {
    if (integration) {
      toast.success(`${integrationCapitalized} integration added successfully`);
      navigate('/settings?view=integrations');
    }
  }, [integration, integrationCapitalized, navigate]);

  return null;
};

export default ExternalAppInstallationsSuccess;
