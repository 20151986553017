import { createSlice } from '@reduxjs/toolkit';
import {
  allowOneCorrectAnswerOnly,
  lessonProperties,
  validateURL,
} from '../utils/trainingUtils';

const initialState = {
  fetchedLesson: undefined,
  isSimpleLesson: false,
  title: '',
  subtitle: '',
  videoURL: '',
  videoURLValid: true,
  showToBrowserLinkInSlack: true,
  externalResources: '',
  externalResourcesValid: true,
  editorImages: [],
  allowMultipleCorrectAnswers: false,
  answers: [],
  question: undefined,
  scenarioType: undefined,
  loadingLesson: true,
};

const lessonSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    updateLesson(state, action) {
      if (
        [
          lessonProperties.EXTERNAL_RESOURCES,
          lessonProperties.VIDEO_URL,
        ].includes(action.payload.property)
      ) {
        const [isValid, property] = validateURL(
          action.payload.value,
          action.payload.property
        );

        state[property] = isValid;
      }

      state[action.payload.property] = action.payload.value;
    },

    updateAnswerType(state, action) {
      const multiSelect = action.payload.questionType === 'multi_select';
      const value = action.payload.questionType
        ? multiSelect
        : !state.allowMultipleCorrectAnswers;
      state.allowMultipleCorrectAnswers = value;

      if (!value) {
        let answersCopy = [...state.answers];
        answersCopy = allowOneCorrectAnswerOnly(answersCopy);
        state.answers = answersCopy;
      }
    },

    setAnswers(state, action) {
      state.answers = action.payload;
    },

    setQuestion(state, action) {
      delete action.payload.answers;
      state.question = action.payload;
    },

    setFetchedLesson(state, action) {
      state.fetchedLesson = action.payload;
    },

    setLoadingLesson(state, action) {
      state.loadingLesson = action.payload;
    },

    addImage(state, action) {
      state.editorImages.push(action.payload);
    },

    addAnswer(state, action) {
      state.answers.push({
        id: 'temp_id' + state.answers.length,
        answer: '',
        position: state.answers.length,
        lesson_question: action.payload.questionId,
        correct: false,
      });
    },

    editAnswer(state, action) {
      const answerForEditing = state.answers.find(
        (answer) => answer.id === action.payload.answerIndex
      );
      answerForEditing[action.payload.property] = action.payload.value;

      const answers = [...state.answers].map((answer) => {
        if (answer.id === action.payload.answerIndex) {
          return answerForEditing;
        }
        if (
          !state.allowMultipleCorrectAnswers &&
          action.payload.property === 'correct'
        ) {
          answer.correct = false;
        }
        return answer;
      });
      state.answers = answers;
    },

    setNextQuestion(state, action) {
      const answerForEditingNextLesson = {
        ...state.answers[action.payload.answerIndex],
      };
      answerForEditingNextLesson.next_lesson = action.payload.value;

      const answersAfterNextLessonSet = [...state.answers].map((answer, i) => {
        if (i === action.payload.answerIndex) {
          return answerForEditingNextLesson;
        }
        return answer;
      });
      state.answers = answersAfterNextLessonSet;
    },

    editExplanation(state, action) {
      const answerForEditingExplanation = {
        ...state.answers.find(
          (answer) => answer.id === action.payload.answerIndex
        ),
      };
      answerForEditingExplanation.explanation = action.payload.value;

      const answersAfterExplanationUpdate = [...state.answers].map((answer) => {
        if (answer.id === action.payload.answerIndex) {
          return answerForEditingExplanation;
        }
        return answer;
      });
      state.answers = answersAfterExplanationUpdate;
    },

    resetStore: () => initialState,
  },
});

export const lessonActions = lessonSlice.actions;

export default lessonSlice.reducer;
