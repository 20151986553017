import { useQuery } from '@tanstack/react-query';
import { getEmployees } from '../../../api/employeesApi';

export const useEmployeesData = ({
  auth,
  page,
  pageSize,
  name,
  isActiveTraining,
  isActiveEmployees,
  selectedGroup,
  isAdmin,
}) => {
  return useQuery({
    queryKey: [
      'employees',
      page,
      pageSize,
      name,
      isActiveTraining,
      isActiveEmployees,
      selectedGroup,
      isAdmin,
    ],
    queryFn: () =>
      getEmployees({
        auth,
        page,
        page_size: pageSize,
        name,
        active_training: isActiveTraining,
        is_active: isActiveEmployees,
        group: selectedGroup,
        isAdmin,
      }),
    keepPreviousData: true,
  });
};
