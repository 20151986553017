import React, { useMemo } from 'react';
import {
  formatMinimalDaysTillDueDate,
  formatRenewDateMessage,
} from '../../utils/formatTrainingEndDate';
import { parseISO } from 'date-fns';
import TrainingOrLessonDuration from '../TrainingOrLessonDuration';

export default function TrainingOptionIndicators({ training }) {
  const trainingData = useMemo(() => {
    const startDate = training.renew_date
      ? training.renew_date
      : training.start_date;
    const endDate = training.end_date ? parseISO(training.end_date) : undefined;
    const daysTillDue =
      training.days_till_due && +training.days_till_due !== 0
        ? training.days_till_due
        : undefined;

    return {
      daysTillDue,
      startDate: startDate ? parseISO(startDate) : undefined,
      endDate: endDate ? parseISO(endDate) : undefined,
      renewCycle: String(training.renew_cycle),
    };
  }, [training]);
  return (
    <div className='flex flex-col mb-1 space-y-0'>
      <TrainingOrLessonDuration duration={training.average_length} />
      <div className='flex items-center'>
        <span className='icon-calendar -ml-0.5 mr-0.3'></span>
        <p>{formatMinimalDaysTillDueDate(trainingData.daysTillDue)}</p>
      </div>
      <div className='flex items-center'>
        <span className='icon-renew -ml-0.5 mr-0.3'></span>
        <p>
          {formatRenewDateMessage(
            trainingData.renewCycle,
            trainingData.startDate
          )}
        </p>
      </div>
      {training.curriculum_detail.action_id === 'branching' && (
        <div className='flex items-center'>
          {(training.end_date || training.renew_cycle !== 'none') && (
            <span className='-ml-0.5 mr-0.3 icon-branching text-hka_gray'></span>
          )}
          <p>Branching Scenarios</p>
        </div>
      )}
    </div>
  );
}
