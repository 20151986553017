import toast from 'react-hot-toast';
import { useExternalAppsData } from '../../../hooks/data-hooks/integrations/useExternalAppsData';
import { useAppContext } from '../../../libs/contextLib';
import Callout from '../../Callout';
import UpgradePlanButton from '../../UpgradePlanButton';
import LoaderInline from '../../LoaderInline';
import Select from 'react-select';
import { useContext, useEffect, useState } from 'react';
import AssignEmployeesContext from '../../../store/assign-employeees-context';
import Note from '../../Note';
import Divider from '../../Base/Divider';
import useCopyToClipboard from '../../../utils/custom-hooks/useCopyToClipboard';
import { useConfigureExternalAppWebhook } from '../../../hooks/data-hooks/integrations';

const calloutMessage =
  String.fromCodePoint('0x1F44B') +
  ` Your plan does not include access to Haekka's Docebo integration. Please upgrade your plan.`;

const doceboWebhookUrl =
  'https://app.haekka.com/api/integrations/webhook/docebo';

const DoceboOption = () => {
  const { isAuthenticated } = useAppContext();
  const {
    object,
    allDoceboUsers,
    setAllDoceboUsers,
    addNewDoceboUsers,
    setAddNewDoceboUsers,
    setActiveDoceboIntegration,
  } = useContext(AssignEmployeesContext);
  const [hookConfigOption, setHookConfigOption] = useState('automatic');

  const { data: externalIntegrations, isLoading: isLoadingExternalApps } =
    useExternalAppsData();
  const activeDoceboApp = externalIntegrations?.results.find(
    (ei) => ei.resourcetype === 'DoceboInstallation' && ei.is_active
  );

  const onConfigureSuccess = (res) => {
    if (res.success) {
      toast.success('Docebo Webhook configured successfully');
    }
    if (!res.success) {
      toast.error(
        <section>
          <p>
            Configuring webhook failed with following message:{' '}
            <b>{res.messages[0]}</b>
          </p>
        </section>
      );
    }
  };

  const { mutate: configureWebhook, isLoading: submittingConfigAction } =
    useConfigureExternalAppWebhook({ callbackFn: onConfigureSuccess });

  useEffect(() => {
    setActiveDoceboIntegration(activeDoceboApp);
    setAddNewDoceboUsers(activeDoceboApp?.webhook_enabled ?? false);
  }, [activeDoceboApp, setActiveDoceboIntegration, setAddNewDoceboUsers]);

  const [copyToClipboardBackendURL, { success: backendURLcopied }] =
    useCopyToClipboard();

  const configureWebhookHandler = () => {
    configureWebhook({
      integrationName: 'docebo',
      companyID: isAuthenticated.company,
    });
  };

  if (isLoadingExternalApps) return <LoaderInline />;

  if (!isAuthenticated.company_details.has_docebo) {
    return (
      <div className='col-span-full'>
        <Callout
          title={'Haekka'}
          message={calloutMessage}
          permanent
          date={false}
        >
          <UpgradePlanButton parentCpmToTrack={'Settings'} />
        </Callout>
      </div>
    );
  }

  if (!object.docebo_course) {
    return (
      <p className='py-3'>
        👋 We did not find a Docebo course associated with this Training. You
        can associate a Docebo course with this training in the Training
        Settings.
      </p>
    );
  }

  return (
    <section className='py-2'>
      <section>
        <h4 className='mb-1 font-basis-bold'>
          Add users from the associated Docebo course:
        </h4>
        <Select
          onChange={() => {}}
          value={{
            label: object.docebo_course.title,
            value: object.docebo_course.course_id,
          }}
          classNamePrefix='select'
          className='select--select-employees-manually'
          unstyled
          placeholder='Search Docebo Courses'
          isDisabled
        />
        <p className='mt-1'>
          This Haekka training is currently associated with{' '}
          <b>{object.docebo_course.title}</b> course in Docebo. You can change
          this in the training settings.
        </p>
      </section>
      <section className='mt-3'>
        <Note type='note'>
          <p className='text-base2 text-hka_gray-dark'>
            To ensure all existing and future users in this Docebo course are
            assigned in Haekka, toggle both switches below. Haekka will run a
            sync before notifying any users to ensure that employees who have
            completed this training are not inadvertently notified.
          </p>
        </Note>
      </section>
      <section className='mt-3'>
        <div className='flex justify-between pb-2'>
          <p className='text-hka_gray-dark'>
            Add <span className='font-basis-bold'>all existing users</span>
          </p>
          <label className='switch'>
            <input
              type='checkbox'
              name='select-all-users'
              checked={allDoceboUsers}
              onChange={(e) => setAllDoceboUsers(e.target.checked)}
              className='cursor-pointer'
            />
            <span className='slider'></span>
          </label>
        </div>

        <Divider />

        <div className='flex justify-between py-2'>
          <p className='text-hka_gray-dark'>
            Automatically add{' '}
            <span className='font-basis-bold'>
              new users and sync docebo courses
            </span>
          </p>
          <label className='switch'>
            <input
              type='checkbox'
              name='select-all-users'
              checked={addNewDoceboUsers}
              onChange={(e) => setAddNewDoceboUsers(e.target.checked)}
              className='cursor-pointer'
            />
            <span className='slider'></span>
          </label>
        </div>

        <div>
          {activeDoceboApp?.webhook_verified && (
            <section>
              <p>
                Webhook verified!{' '}
                {addNewDoceboUsers
                  ? ''
                  : 'To sync your Docebo courses and enrollments, keep the switch above ON.'}
              </p>
            </section>
          )}
          {!activeDoceboApp?.webhook_verified && (
            <section className='text-base text-hka_gray-dark'>
              To sync new enrollments from Docebo, you need to configure a
              webhook in the Docebo platform. You can do this in two ways:
              <section>
                <section className='p-2 bg-gray-100 rounded-md border border-gray-200 mt-2'>
                  <input
                    type='radio'
                    id='automatic'
                    value='automatic'
                    checked={hookConfigOption === 'automatic'}
                    onChange={(e) => setHookConfigOption(e.target.value)}
                  />
                  <label
                    htmlFor='automatic'
                    className='text-base font-bold text-gray-500 cursor-pointer'
                  >
                    Let us configure it for you (recommended):
                  </label>
                </section>

                {hookConfigOption === 'automatic' && (
                  <section className='mt-2 p-2 bg-white border border-gray-100 rounded-md'>
                    <section className='flex flex-col'>
                      <section className='flex flex-col'>
                        <p className='text-base text-gray-500'>
                          Reminder: Make sure you have the Webhook app in Docebo
                          installed. You can learn how to do that{' '}
                          <a
                            href='https://help.docebo.com/hc/en-us/articles/4415258978322-Managing-Apps-and-Features'
                            target='_blank'
                            rel='noreferrer'
                            className='text-orange underline'
                          >
                            here
                          </a>
                          . Clicking "Create Webhook" below will add a new
                          webhook to your Docebo platform and configure it
                          properly. You can always review it in Docebo.
                        </p>
                      </section>

                      <section className='w-full mt-2'>
                        <button
                          onClick={configureWebhookHandler}
                          disabled={submittingConfigAction}
                          className='w-full px-4 py-2 text-blue-500 font-basis-bold bg-blue-100 hover:bg-blue-200 disabled:opacity-50'
                        >
                          Create Webhook
                        </button>
                      </section>
                    </section>
                  </section>
                )}

                <section>
                  <div className='p-2 bg-gray-100 rounded-md border border-gray-200 mt-2'>
                    <input
                      type='radio'
                      id='manual'
                      value='manual'
                      checked={hookConfigOption === 'manual'}
                      onChange={(e) => setHookConfigOption(e.target.value)}
                    />
                    <label
                      htmlFor='manual'
                      className='text-base font-bold text-gray-500 cursor-pointer'
                    >
                      Configure it manually by yourself:
                    </label>
                  </div>
                  {hookConfigOption === 'manual' && (
                    <section className='mt-2 p-2 bg-white border border-gray-100 rounded-md'>
                      <div className='flex mb-1'>
                        <p className='w-2'>1.</p>
                        <p>
                          Go to your Docebo dashboard and add new webhook.{' '}
                          <a
                            href='https://help.docebo.com/hc/en-us/articles/360020124459-Creating-and-Managing-Webhooks'
                            target='_blank'
                            rel='noreferrer'
                            className='text-orange'
                          >
                            See how to do that
                          </a>
                          .
                        </p>
                      </div>

                      <div className='flex mb-1'>
                        <p className='w-2'>2.</p>
                        <p>
                          In the <b>Payload URL</b> field enter this URL{' '}
                          <span
                            className='text-orange cursor-pointer'
                            onClick={() =>
                              copyToClipboardBackendURL(doceboWebhookUrl)
                            }
                          >
                            ({backendURLcopied ? 'copied' : 'copy to clipboard'}
                            )
                          </span>
                        </p>
                      </div>

                      <div className='flex mb-1'>
                        <p className='w-2'>3.</p>
                        <p>
                          In the <b>Authentication</b> section, make sure to
                          leave the <b>Enable Basic Auth</b> unchecked.
                        </p>
                      </div>

                      <div className='flex mb-1'>
                        <p className='w-2'>4.</p>
                        <p>
                          In the <b>Payload</b> section, make sure to leave the{' '}
                          <b>Allow platform to group payloads</b> unchecked.
                        </p>
                      </div>

                      <div className='flex mb-1'>
                        <p className='w-2'>5.</p>
                        <p>
                          In the <b>Events</b> section, select{' '}
                          <b>Enrollment has been updated</b>,{' '}
                          <b>Lerner completed course</b>,{' '}
                          <b>User enrolled in a course</b>, and{' '}
                          <b>User unenrolled from course</b> events.
                        </p>
                      </div>
                    </section>
                  )}

                  <section className='mt-2.5'>
                    <p className='text-base'>
                      Once your webhook is verified, we will hide this note.{' '}
                      {hookConfigOption === 'manual'
                        ? 'For this option, we will verify your webhook configuration as soon as we receive the first event.'
                        : ''}
                    </p>
                  </section>
                </section>
              </section>
            </section>
          )}
        </div>
      </section>
    </section>
  );
};

export default DoceboOption;
