import React, { Fragment, useEffect, useState } from "react";
import { formatAvatar } from "../../utils/formatAvatar";
import { getEmployeesAssignedToPhishingCampaign } from "../../api";
import { onError } from "../../libs/errorLib";
import LoaderInline from "../LoaderInline";

export default function PhishingCampaingCardAssignedEmployeesSection({
  auth,
  phishingCampaign,
}) {
  const SLICE_SIZE = 5;

  const [isLoading, setIsLoading] = useState(true);
  const [assignedEmployees, setAssignedEmployees] = useState([]);

  useEffect(() => {
    const onLoad = async () => {
      try {
        setIsLoading(true);
        const employees = await getEmployeesAssignedToPhishingCampaign({
          auth,
          campaignId: phishingCampaign.id,
          page: 1,
          pageSize: SLICE_SIZE,
        });

        setAssignedEmployees(employees);
      } catch (error) {
        onError(error);
      } finally {
        setIsLoading(false);
      }
    };

    onLoad();
  }, []);

  return (
    <section>
      {isLoading ? (
        <LoaderInline wrapperClasses="w-full" loaderSize="3" />
      ) : (
        <Fragment>
          <Fragment>
            <p className="text-sm font-basis-medium mb-0.5">
              Assigned Employees
            </p>

            <div
              className={`card__item-group${
                assignedEmployees?.count > 0 ? " pl-0.5" : ""
              }`}
            >
              {assignedEmployees?.count > 0 ? (
                assignedEmployees?.results?.map((employee) => (
                  <div
                    className="card__item--avatar"
                    key={employee.id}
                  >
                    {employee.avatar_image ? (
                      <img
                        src={employee.avatar_image}
                        alt={employee.name}
                      />
                    ) : (
                      formatAvatar({
                        name: employee.name,
                        width: 2,
                        marginRight: 0,
                        textSize: "text-sm",
                      })
                    )}
                  </div>
                ))
              ) : (
                <p className="text-sm opacity-75">No employees assigned</p>
              )}
              {assignedEmployees?.count > SLICE_SIZE && (
                <p className="ml-1 text-sm truncate font-basis-medium">
                  {" "}
                  +{assignedEmployees.count - SLICE_SIZE}
                </p>
              )}
            </div>
          </Fragment>
        </Fragment>
      )}
    </section>
  );
}
