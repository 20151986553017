import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal/Modal';
import Note from '../../Note';

const CompleteTrainingModal = ({ showModal, employee, onSubmit, onClose }) => {
  return (
    <Modal
      show={showModal}
      onSubmit={onSubmit}
      onClose={onClose}
      title={`Mark Training as Complete`}
      submitButtonText='Mark as Complete'
      submitButtonType='warning'
      compact
    >
      <p className='mb-1'>
        Are you sure you want to mark this training as complete for{' '}
        <span className='font-basis-bold'>
          {!!employee
            ? employee.employee_name
            : 'all employees in the training'}
          ?
        </span>
      </p>
      <Note type='warning'>
        <p>
          Performing this action will set the training's finish date for{' '}
          {employee ? 'this employee ' : 'all employees '}
          to the current date and mark training as complete with 100% success.
        </p>
      </Note>
    </Modal>
  );
};

CompleteTrainingModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  employee: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompleteTrainingModal;
