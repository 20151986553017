import { useQuery } from '@tanstack/react-query';
import { getEngagementAnonymousResults } from '../../../api/engagementsApi';

const getKeys = (companyEngagementID, currentPage, schedule) => [
  'engagement-employee-anonymous-results',
  companyEngagementID,
  currentPage,
  schedule,
];

export const useEmployeeEngagementAnynomousResultsData = ({
  currentPage,
  companyEngagementID,
  schedule,
  enabled = true,
}) => {
  return useQuery({
    queryKey: getKeys(companyEngagementID, currentPage, schedule),
    queryFn: () =>
      getEngagementAnonymousResults({
        page: currentPage,
        companyEngagementID,
        schedule,
      }),
    keepPreviousData: true,
    enabled,
  });
};
