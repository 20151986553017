import WysiwygRichTextEditor from '../../WysiwygEditor/WysiwygRichTextEditor';

const EditorSection = ({
  questionEditorState,
  setQuestionEditorState,
  setImageIds,
  create,
  selectedQuestion,
  isReadOnly,
}) => {
  return (
    <section className='section--message-editor'>
      <h4 className='mb-0.5'>
        Message Content{' '}
        <span className='text-sm text-hka_gray'>(Required)</span>
      </h4>

      <WysiwygRichTextEditor
        editorState={questionEditorState}
        onSetEditorState={(state) => setQuestionEditorState(state)}
        id={
          selectedQuestion?.id?.startsWith('question')
            ? undefined
            : selectedQuestion?.id
        }
        onImageAdded={(imageId) => setImageIds((prev) => [...prev, imageId])}
        contentType={'engagement_question'}
        questionId={create ? undefined : selectedQuestion.id}
        readOnly={isReadOnly}
      />
    </section>
  );
};

export default EditorSection;
