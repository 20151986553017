import Modal from "../../Modal/Modal";

const CustomPostConfirmModal = ({ onSubmit, onClose }) => {
  return (
    <Modal
      show={true}
      title="Edit Stream Post"
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonText="Edit Post"
      modalClass={"modal--small"}
    >
      <p>Are you sure you want to edit this post?</p>
    </Modal>
  );
};

export default CustomPostConfirmModal;
