import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';

import { useAppContext } from '../libs/contextLib';
import { updateEmployee } from '../api/settings/usersSettingsApi';
import { useRolesData } from '../hooks/data-hooks/settings';
import { onError } from '../libs/errorLib';

import Modal from './Modal/Modal';

const EditRolesModal = ({ showModal, setRoleTitle, employee, onModalHide }) => {
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const [roleUpdated, setRoleUpdated] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  const { data: availableRoles } = useRolesData({ page: 1 });

  useEffect(() => {
    setSelectedRole(
      employee?.role_serialized
        ? {
            value: employee.role_serialized.id,
            label: employee.role_serialized.title,
          }
        : null
    );
  }, [availableRoles, employee.role_serialized]);

  async function handleSubmit() {
    const { value, label } = selectedRole;

    try {
      setRoleTitle(label);

      await updateEmployee({
        employeeID: employee.id,
        updateData: { role: value },
      });

      if (employee.id === isAuthenticated.id) {
        const auth = JSON.parse(localStorage.getItem('auth'));
        auth.role = value;
        localStorage.setItem('auth', JSON.stringify(auth));

        const tempAuth = isAuthenticated;
        tempAuth.role = value;

        userHasAuthenticated(tempAuth);
      }

      onModalHide();

      toast.success('Role updated!');
    } catch (error) {
      onError(error);
    }
  }

  const customStyles = {
    control: (base, state) => {
      return {
        ...base,
        border: 'none',
        boxShadow: `0 0 0 1px ${
          state.selectProps.error ? '#f2181f' : base.borderColor
        }`,
      };
    },
    singleValue: (base, state) => {
      return {
        ...base,
        color: `${state.selectProps.error ? '#f2181f' : base.borderColor}`,
      };
    },
  };

  return (
    <Modal
      show={showModal}
      title='Select Employee Role'
      onClose={onModalHide}
      onSubmit={handleSubmit}
      submitValid={roleUpdated}
      submitButtonText='Update Employee Role'
      compact
    >
      <section>
        <p className='mb-1'>
          Select a role for{' '}
          <span className='font-basis-bold'>
            {employee?.name || 'this employee'}
          </span>
        </p>
        {availableRoles?.results.length > 0 && (
          <Select
            defaultValue={selectedRole}
            onChange={(value) => {
              setRoleUpdated(!!value);
              setSelectedRole(value);
            }}
            value={selectedRole}
            placeholder='Select Role'
            isMulti={false}
            options={availableRoles.results.map((o) => ({
              label: o.title,
              value: o.id,
            }))}
            classNamePrefix='select'
            styles={customStyles}
            unstyled
          />
        )}
      </section>
    </Modal>
  );
};

export default EditRolesModal;
