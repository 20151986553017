import React from 'react';

const ListItems = ({
  name,
  children,
  flex,
  fullWidth = false,
  className,
  htag = 'h1',
}) => {
  let header = null;

  switch (htag) {
    case !!name && 'h1':
      header = <h1>{name}</h1>;
      break;
    case !!name && 'h3':
      header = <h3 className='mb-2 font-basis-bold'>{name}</h3>;
      break;

    default:
      header = null;
      break;
  }

  return (
    <>
      {header}
      {children?.length > 0 &&
        (flex !== 'row' ? (
          <ul
            className={`${fullWidth ? 'w-full' : ''}${
              className ? className : ''
            }`}
          >
            {children}
          </ul>
        ) : (
          <div className='grid w-full grid-cols-1 gap-1 mt-1 mb-0 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
            {children}
          </div>
        ))}
    </>
  );
};

export default ListItems;
