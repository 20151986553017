import { useContext, useEffect, useState } from "react";
import {
  getBodyData,
  getInitialEditorStateForCustomResponse,
  populateWorkflowContext,
  RISK_LEVEL_OPTIONS,
  SENTIMENT_RATINGS_OPTIONS,
  workflowModalViews,
} from "../../../utils/workflowsUtils";
import Modal from "../../Modal/Modal";
import SelectDestination from "./SelectDestination";
import CreateEvent from "./CreateEvent";
import SelectSource from "./SelectSource";
import { onError } from "../../../libs/errorLib";
import { useAppContext } from "../../../libs/contextLib";
import AssignEmployeesWorkflow from "../AssignEmployeesWorkflow";
import AssignEmployeesContext, {
  OBJECT_TYPES,
} from "../../../store/assign-employeees-context";
import ListAssignedEmployees from "../ListAssignedEmployees";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { workflowsActions } from "../../../store/workflowsSlice";
import {
  createWorkflowAction,
  fetchEventsData,
  updateWorkflowAction,
} from "../../../store/workflowsActions";

const CreateEditWorkflowModal = () => {
  // EditorState is non-serializable JS object, and Redux does not allow to store such a
  // non-serializable object in state. Parsing it to string and back to JS objects on each state change would
  // work, but that action would have to take a place on each key stroke. Therefore, we're handling ES here:
  const [customResponseES, setCustmResponseES] = useState(undefined);

  const { isAuthenticated } = useAppContext();
  const dispatch = useDispatch();
  const {
    setObject,
    setObjectType,
    onModalClose,
    handleSubmit: handleAssigneEmployeesSubmit,
  } = useContext(AssignEmployeesContext);

  const [dataLoaded, setDataLoaded] = useState(false);

  const {
    selectedSource,
    selectedEvent,
    selectedAction,
    selectedRiskLevel,
    selectedSentimentalRating,
    events,
    selectedFrequency,
    selectedDestination,
    selectedView,
    modalMode,
    silenceEvent,
    selectedTraining,
    selectedWorkflow,
    companyTrainings,
  } = useSelector((state) => state.workflows);

  const selectViewHandler = (view) => {
    dispatch(workflowsActions.selectView({ view }));
  };

  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    const getInitalalEditorState = () => {
      return getInitialEditorStateForCustomResponse({
        event: selectedEvent?.label,
      });
    };
    const getCustomResponseEditorState = async () => {
      setCustmResponseES(getInitalalEditorState);
    };
    if (modalMode === "CREATE_WORKFLOW") {
      getCustomResponseEditorState();
    }
    if (
      selectedAction?.value === "custom_response" &&
      !selectedWorkflow?.action.name.includes("custom_response")
    ) {
      getCustomResponseEditorState();
    }
  }, [
    modalMode,
    selectedAction?.value,
    selectedEvent?.label,
    selectedWorkflow?.action.name,
  ]);

  useEffect(() => {
    const onInit = async () => {
      try {
        dispatch(fetchEventsData({ auth: isAuthenticated }));
        setObject(selectedWorkflow);
        setObjectType(OBJECT_TYPES.WORKFLOW);
        if (selectedWorkflow) {
          await populateWorkflowContext({
            workflow: selectedWorkflow,
            dispatch,
            fetchedEvents: events,
            setCustmResponseES,
            companyTrainings,
          });
          setDataLoaded(true);
        }
      } catch (e) {
        onError(e);
      }
    };

    onInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    selectedSource,
    setObject,
    setObjectType,
    selectedWorkflow,
  ]);

  useEffect(() => {
    if (!selectedWorkflow && selectedEvent && events.length > 0) {
      const recommendedRiskLevel = events.find(
        (e) => e.id === selectedEvent.value
      ).recommended_risk_level;
      let selectedRisk = undefined;
      const riskLevels = RISK_LEVEL_OPTIONS.map((risk) => {
        if (risk.value === recommendedRiskLevel) {
          selectedRisk = {
            value: risk.value,
            label: risk.label + " (recommended)",
          };
          return selectedRisk;
        }
        return risk;
      });
      dispatch(workflowsActions.setRiskLevels({ riskLevels }));
      dispatch(workflowsActions.selectRiskLevel({ riskLevel: selectedRisk }));
    }
  }, [selectedWorkflow, events, selectedEvent, dispatch]);

  useEffect(() => {
    const valid =
      !!selectedSource &&
      !!selectedEvent &&
      !!selectedAction &&
      !!selectedDestination &&
      !!selectedRiskLevel &&
      !!selectedSentimentalRating &&
      (selectedAction?.value === "assign_training"
        ? true
        : selectedFrequency !== undefined);
    setFormIsValid(valid);
  }, [
    selectedSource,
    selectedEvent,
    selectedAction,
    selectedDestination,
    selectedRiskLevel,
    selectedSentimentalRating,
    selectedFrequency,
    // setFormIsValid,
  ]);

  const closeModalHandler = () => {
    onModalClose(); //clear Assign Employees Context
    dispatch(
      workflowsActions.closeWorkflowModal({
        view: workflowModalViews.WORKFLOW_DETAILS,
        sentiment: SENTIMENT_RATINGS_OPTIONS[0],
      })
    );
  };

  const handleSubmit = async () => {
    if (selectedView === workflowModalViews.WORKFLOW_DETAILS) {
      selectViewHandler(workflowModalViews.ASSIGN_EMPLOYEES);
      return;
    }

    try {
      const bodyData = await getBodyData({
        selectedSource,
        selectedEvent,
        selectedRiskLevel,
        selectedDestination,
        silenceEvent,
        selectedAction,
        object: selectedWorkflow || null,
        customResponseES,
        selectedFrequency,
        selectedSentimentalRating,
        selectedTraining,
      });
      if (modalMode === "CREATE_WORKFLOW") {
        dispatch(
          createWorkflowAction({
            auth: isAuthenticated,
            bodyData,
            setObject,
            handleAssigneEmployeesSubmit,
          })
        );
        toast.success("Workflow saved");
      } else {
        dispatch(
          updateWorkflowAction({
            auth: isAuthenticated,
            bodyData,
            workflowId: selectedWorkflow.id,
            setObject,
            handleAssigneEmployeesSubmit,
          })
        );
        toast.success("Workflow updated");
      }
      closeModalHandler();
    } catch (error) {
      onError(error);
    }
  };

  const submitButtonText =
    selectedView === workflowModalViews.WORKFLOW_DETAILS
      ? "Assign Employees"
      : selectedWorkflow
      ? "Save Workflow"
      : "Create Workflow";

  return (
    <Modal
      show={selectedWorkflow ? dataLoaded : true}
      title={selectedWorkflow ? "Edit Workflow" : "Create a new workflow"}
      submitButtonText={submitButtonText}
      onClose={closeModalHandler}
      submitValid={formIsValid}
      onSubmit={handleSubmit}
      expandable
    >
      <section className="modal--create-workflow">
        {/* Modal tabs */}
        <div className="flex">
          {Object.entries(workflowModalViews).map(([tabKey, tabLabel]) => {
            // Hide List of Assigned Employees for non existing Workflow
            if (!selectedWorkflow && tabLabel === "Assigned Employees") {
              return null;
            }
            return (
              <h4
                key={tabKey}
                className={`tab font-basis-bold mr-2 cursor-pointer ${
                  tabLabel === selectedView
                    ? "text-orange border-b-2 pb-0.5 border-current"
                    : "text-hka_gray"
                }`}
                data-tab={tabLabel}
                onClick={() => selectViewHandler(tabLabel)}
              >
                {tabLabel}
              </h4>
            );
          })}
        </div>

        {/* Modal Body sections */}
        {selectedView === workflowModalViews.WORKFLOW_DETAILS && (
          <div className="">
            <SelectSource />
            <CreateEvent
              customResponseES={customResponseES}
              setCustmResponseES={setCustmResponseES}
            />
            <SelectDestination />
          </div>
        )}
        {selectedView === workflowModalViews.ASSIGN_EMPLOYEES && (
          <AssignEmployeesWorkflow workflow={selectedWorkflow} />
        )}

        {selectedView === workflowModalViews.ASSIGNED_EMPLOYEES && (
          <ListAssignedEmployees
            switchViewHandler={(view) => selectViewHandler(view)}
            workflow={selectedWorkflow}
            onClose={closeModalHandler}
          />
        )}
      </section>
    </Modal>
  );
};

export default CreateEditWorkflowModal;
