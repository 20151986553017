import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import Card from "../Base/Card";

function PhishingCampaignTemplateCard({
  template,
  lastAttack,
  actions,
  formattedLastSentDate,
  reportRate,
  isWhitelisted,
}) {
  const CardContent = () => {
    return (
      <>
        <h4 className="mb-1 truncate font-basis-medium">{template.name}</h4>
        <div className="flex flex-row items-center space-x-0.5">
          <div className="flex items-center space-x-0.2">
            <span className="-ml-0.5 icon-calendar opacity-60"></span>
            <p className="text-sm">
              {formattedLastSentDate ? "Sent on" : "Has not sent"}
            </p>
          </div>
          <p className="text-sm ">{formattedLastSentDate}</p>
        </div>
      </>
    );
  };

  const CardFooter = () => {
    return (
      <div className="flex flex-row items-center space-x-1">
        <div className="w-4 h-4">
          <CircularProgressbar
            value={reportRate}
            styles={buildStyles({
              textColor: "#878a9a",
              pathColor: "#36C5F0",
              trailColor: "#EFF0F7",
            })}
            strokeWidth={10}
          />
        </div>
        <div>
          <span className="text-base2 font-basis-medium">{reportRate}%</span>
          <p className="text-sm font-basis-medium">Successfully Reported</p>
        </div>
      </div>
    );
  };

  return (
    <Card
      imageUrlOrIconClass={
        template.icon ||
        "https://haekka-author-icons.s3.us-west-2.amazonaws.com/HKA--UPDATED--Author_Haekka.png"
      }
      title={template.application || "Haekka"}
      content={<CardContent />}
      footer={lastAttack ? <CardFooter /> : null}
      actions={actions}
      renderActionsAsMenu={true}
      warningMessage={!isWhitelisted ? "Domain not whitelisted." : ""}
    />
  );
}

export default PhishingCampaignTemplateCard;
