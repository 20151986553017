import { Fragment, useContext, useEffect } from 'react';
import AssignEmployeesContext from '../../store/assign-employeees-context';
import AssingEmployeesOptions from '../AssignEmployees/AssingEmployeesOptions';
import ManuallyOptions from '../AssignEmployees/ManuallyOptions';
import SlackOptions from '../AssignEmployees/SlackOptions';

const AssignEmployeesWorkflow = ({ workflow }) => {
  const {
    selectedTab,
    setSlackAutoEnroll,
    channelOptions,
    setSelectedChannelsSync,
  } = useContext(AssignEmployeesContext);

  useEffect(() => {
    if (workflow?.auto_enroll) {
      setSlackAutoEnroll(workflow.auto_enroll);
    }
    if (channelOptions?.length && workflow > 0) {
      const ch = channelOptions.filter((co) =>
        workflow.channels.includes(co.value)
      );
      setSelectedChannelsSync(ch);
    }
  }, [workflow, channelOptions, setSlackAutoEnroll, setSelectedChannelsSync]);

  return (
    <Fragment>
      <div className='mt-2'>
        <AssingEmployeesOptions hideForWorkflows />
        {selectedTab === 'SLACK' && <SlackOptions />}
        {selectedTab === 'MANUALLY' && <ManuallyOptions />}
      </div>
    </Fragment>
  );
};

export default AssignEmployeesWorkflow;
