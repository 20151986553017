import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPhishingCampaignActionEmployeeDetails } from "../../../api";
import { useAppContext } from "../../../libs/contextLib";
import { onError } from "../../../libs/errorLib";
import { Popover } from "@headlessui/react";
import { getActionTypeLabelAndColorClass } from "../../../utils/phishingUtils";
import LoaderInline from "../../LoaderInline";

function EmployeeActionsCardItem({ action }) {
  const formatActionDate = (actionDate) => {
    const [year, month, day] = actionDate.split("-");

    return `${month}/${day}/${year}`;
  };

  const formattedActionDescription = getActionTypeLabelAndColorClass(
    action.action_type
  );

  return (
    <div className="flex flex-row items-center justify-between h-6 px-2 space-x-2 border-r border-hka_gray-light">
      <div>
        <p
          className={`font-basis-medium ${
            formattedActionDescription.colorClass || "text-hka_gray-dark"
          }`}
        >
          {formattedActionDescription.label}
        </p>
        <p className="text-xs opacity-70 text-hka_gray">
          {" "}
          {formatActionDate(action.created__date)}
        </p>
      </div>

      <p>{action.count}</p>
    </div>
  );
}

export default function EmployeeActionsCard({ attack, employee, sortOption }) {
  const { isAuthenticated } = useAppContext();
  const { phishingCampaignId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [employeeActionDetails, setEmployeeActionDetails] = useState(null);

  useEffect(() => {
    const onLoad = async () => {
      try {
        setIsLoading(true);
        const data = await getPhishingCampaignActionEmployeeDetails({
          auth: isAuthenticated,
          sortOption: sortOption.value,
          phishingCampaignId: phishingCampaignId,
          employeeId: employee,
          attacks: [attack],
        });

        setEmployeeActionDetails(data);
      } catch (error) {
        onError(error);
      } finally {
        setIsLoading(false);
      }
    };

    onLoad();
  }, [attack, employee, isAuthenticated, phishingCampaignId, sortOption.value]);

  return (
    <Popover className="relative z-20">
      <Popover.Button className="table__menu-button">
        <div className="rounded-lg ml-1 transform rotate-90 w-2.4 p-0 m-0 min-w-0 cursor-pointer sm:pointer-events-auto hover:bg-hka_gray-light">
          <span className="icon-menu-2"></span>
        </div>
      </Popover.Button>

      <Popover.Panel className="absolute z-40 bg-white rounded-lg left-4 -top-1 shadow-smooth min-w-8">
        <div className="flex">
          {isLoading ? (
            <LoaderInline />
          ) : (
            employeeActionDetails?.map((action, i) => (
              <EmployeeActionsCardItem key={i} action={action} />
            ))
          )}
        </div>
      </Popover.Panel>
    </Popover>
  );
}
