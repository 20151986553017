import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createAssessment,
  createTrainingCompanyAssessment,
} from '../../../api/assessmentApi';

const createTrainingAssessmentHandler = async ({
  assessmentPayload,
  trainingCompanyAssessment,
}) => {
  const assessment = await createAssessment(assessmentPayload);
  trainingCompanyAssessment.assessment = assessment.id;
  return await createTrainingCompanyAssessment(trainingCompanyAssessment);
};

export const useCreateTrainingAssessment = ({
  curriculumID,
  cb = () => {},
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createTrainingAssessmentHandler,
    onSuccess: () => {
      cb();
      queryClient.invalidateQueries(['curriculum-content', curriculumID]);
    },
  });
};
