import { useQuery } from '@tanstack/react-query';
import { retrieveLessons } from '../../../api/trainingsApi';
import { CURRICULUM_ITEM_TYPES } from '../../../utils/training';

const select = (data) => {
  let lessonPosition = 1;
  let assesmentPosition = 1;

  return [
    ...data.lessons.map((lesson) => {
      const item = {
        type: CURRICULUM_ITEM_TYPES.LESSON,
        value: lesson,
        position: lesson.position,
        itemSpecificPosition: lessonPosition,
      };
      lessonPosition++;
      return item;
    }),
    ...data.assessments.map((assessment) => {
      const item = {
        type: CURRICULUM_ITEM_TYPES.ASSESSMENT,
        value: assessment,
        position: assessment.position,
        itemSpecificPosition: assesmentPosition,
        skipLessonsTotal: assessment.lessons_to_skip.length,
        gradingThreshold: assessment.passing_grade,
      };
      assesmentPosition++;
      return item;
    }),
  ].sort((a, b) => a.position - b.position);
};

export const useCurriculumContentData = ({ curriculumID, enabled = true }) => {
  return useQuery({
    queryKey: ['curriculum-content', curriculumID],
    queryFn: () =>
      retrieveLessons({
        curriculumID,
      }),
    select,
    enabled,
  });
};
