import { HeaderCell } from "rsuite-table";
import "./sortable-header-cell.css";

const renderSortIcon = (sortType) => {
  if (sortType === "asc") {
    return (
      <div className="flex items-center h-full -mr-0.4">
        <span className={"sort sort--ascending"}></span>
      </div>
    );
  } else if (sortType === "desc") {
    return (
      <div className="flex items-center h-full -mr-0.4">
        <span className={"sort sort--descending"}></span>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <span className={"icon-chevron transform rotate-180"}></span>
      <span className={"icon-chevron"}></span>
    </div>
  );
};

const SortableHeaderCell = ({ rowData, dataKey, children, ...props }) => {
  return (
    <HeaderCell
      {...props}
      className="custom-header-cell"
      dataKey={dataKey}
      renderSortIcon={renderSortIcon}
    >
      <div
        className={`flex items-center text-sm font-basis-medium w-full text-hka_gray-dark`}
      >
        {children}
      </div>
    </HeaderCell>
  );
};

export default SortableHeaderCell;
