import React, { Fragment, useEffect, useMemo } from "react";
import { format } from "date-fns";
import { useState } from "react";
import { onError } from "../../libs/errorLib";
import { getPhishingCampaignActionDetails } from "../../api";
import Expander from "../Expander";
import useDebounce from "../../utils/custom-hooks/useDebounce";
import LoaderInline from "../LoaderInline";
import NoPhishingAttacksSent from "./AssignedEmployeesTable/NoPhishingAttacksSent";
import { formatAvatar } from "../../utils/formatAvatar";

function AcitivityMonitorCard({ action, dayOverride = null }) {
  const attackSentDate = new Date(action.attack.created);
  const createdDate = new Date(action.created);

  const formattedActionRecordedDate = format(createdDate, "iiii, MMM. dd, yyyy");
  const formattedAttackSentDate = format(attackSentDate, "MMM. dd, yyyy");
  const formattedTime = format(createdDate, "hh:mm a");

  const formatActivityText = (() => {
    switch (action.action_type) {
      case "do_nothing":
        return "has received the phishing email";
      case "open_phishing_mail":
        return "has opened the phishing email";
      case "click_phishing_link":
        return "has clicked on the phishing link";
      case "breach_credentials":
        return "has breached credentials";
      case "report_phishing":
        return `successfully reported the ${formattedAttackSentDate} attack`;
      case "forwarded_phishing_mail":
        return "has forwarded the phishing email";
      default:
        // This case should never happen
        return null;
    }
  })();

  const activityText = `${formatActivityText} ${
    dayOverride
      ? `${dayOverride} at ${formattedTime}`
      : `on ${formattedActionRecordedDate} at ${formattedTime}`
  }`;

  return (
    <div className="flex flex-row items-center p-2 space-x-1 bg-white rounded-lg shadow-light"> 
      {action.employee.avatar_image ? (
        <img
          className="rounded-md"
          src={action.employee.avatar_image}
          alt={action.employee.name}
          height={24}
          width={24}
        />
      ) : (
        formatAvatar({
          name: action.employee.name,
          width: 2.4,
          marginRight: 0,
          textSize: "text-sm",
        })
      )}
      <p className="font-basis-bold text-hka_gray-dark">
        {action.employee.name}
      </p>
      <p>{activityText}</p>
    </div>
  );
}

function ActivityMonitorActionGroup({
  auth,
  campaign,
  searchQuery,
  filteringOptions,
  sortOption,
  dayOverride = null,
  setActionGroupsContainData,
}) {
  const [isLoading, setisLoading] = useState(false);
  const [actionsResponse, setActionsResponse] = useState(null);
  const [page, setPage] = useState(1);

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const capitalize = (str) => str.replace(/^\w/, (c) => c.toUpperCase());

  useEffect(
    () => setPage(1),
    [debouncedSearchQuery, filteringOptions, sortOption]
  );

  useEffect(() => {
    async function loadActions() {
      try {
        setisLoading(true);

        const response = await getPhishingCampaignActionDetails({
          auth,
          phishingCampaignId: campaign.id,
          params: {
            page,
            attacks: filteringOptions?.map(({ value }) => value) || [],
            name: debouncedSearchQuery,
            sort: sortOption.value,
            dayOverride: dayOverride?.toUpperCase() || "OLDER",
          },
        });

        setActionGroupsContainData((value) => [...value, response.count > 0]);

        if (actionsResponse?.results && page > 1) {
          response.results = actionsResponse.results.concat(response.results);
        }

        setActionsResponse(response);
      } catch (error) {
        onError(error);
      } finally {
        setisLoading(false);
      }
    }

    loadActions();
  }, [page, debouncedSearchQuery, filteringOptions, sortOption]);

  return actionsResponse?.results?.length > 0 ? (
    <Expander title={dayOverride ? capitalize(dayOverride) : "Older"}>
      <section className="space-y-1">
        {isLoading ? (
          <LoaderInline />
        ) : (
          <Fragment>
            {actionsResponse?.results?.map((action, i) => (
              <AcitivityMonitorCard
                key={i}
                action={action}
                dayOverride={dayOverride ? dayOverride.toLowerCase() : null}
              />
            ))}
            {actionsResponse?.next && (
              <p
                className="cursor-pointer text-orange font-basis-bold"
                onClick={() => setPage((page) => page + 1)}
              >
                Load More
              </p>
            )}
          </Fragment>
        )}
      </section>
    </Expander>
  ) : (
    <></>
  );
}

export default function ActivityMonitor({
  auth,
  campaign,
  searchQuery,
  filteringOptions,
  sortOption,
}) {
  const [actionGroupsContainData, setActionGroupsContainData] = useState([]);
  const DAY_OVERRIDES = ["today", "yesterday", null]; // Today, Yesterday, Older

  const renderNoPhishingAttacksSentFragment = useMemo(() => {
    if (actionGroupsContainData.length === 0) return false;
    return actionGroupsContainData.every((value) => !value);
  }, [actionGroupsContainData]);

  return (
    <Fragment>
      {DAY_OVERRIDES.map((dayOverride, i) => (
        <ActivityMonitorActionGroup
          key={i}
          auth={auth}
          campaign={campaign}
          searchQuery={searchQuery}
          filteringOptions={filteringOptions}
          sortOption={sortOption}
          dayOverride={dayOverride}
          setActionGroupsContainData={setActionGroupsContainData}
        />
      ))}
      {renderNoPhishingAttacksSentFragment && (
        <NoPhishingAttacksSent campaignSendOption={campaign.send_option} />
      )}
    </Fragment>
  );
}
