import { format } from 'date-fns';
import { Fragment, useContext, useEffect, useState } from 'react';
import AssignEmployeesContext from '../../store/assign-employeees-context';
import Modal from '../Modal/Modal';
import AssingEmployeesOptions from './AssingEmployeesOptions';
import ManuallyOptions from './ManuallyOptions';
import SlackOptions from './SlackOptions';
import OktaOptions from './Okta/OktaOptions';
import Loader from '../Loader';

const AssignEmployeesEngagements = ({ modalClosedEvent }) => {
  const [nextRenewDate, setNextRenewDate] = useState(undefined);
  const assignEmployeesContext = useContext(AssignEmployeesContext);
  const {
    setModalOpened,
    onModalClose,
    handleSubmit,
    selectedTab,
    submitIsValid,
    assignEngagementNow,
    setAssignEngagementNow,
    object,
  } = assignEmployeesContext;

  useEffect(() => {
    if (object?.next_renew_date) {
      const dateInstance = new Date(object.next_renew_date);
      const renewal_data = `${format(dateInstance, 'MMMM do yyyy')} at ${format(
        dateInstance,
        'h:mm aa'
      )}`;
      setNextRenewDate(renewal_data);
    }
  }, [object]);

  if (!object) {
    return <Loader />;
  }

  return (
    <Modal
      id='manage-quiz-employees-modal'
      title='Manage Employees'
      submitButtonText='Assign Employees'
      onClose={() => {
        setModalOpened(false);
        onModalClose();
      }}
      onSubmit={() => {
        handleSubmit();
        modalClosedEvent();
        onModalClose();
      }}
      expandable
      submitValid={submitIsValid}
      show
    >
      <Fragment>
        <AssingEmployeesOptions hideForEngagements />
        {(selectedTab === 'SLACK' ||
          selectedTab === 'MANUALLY' ||
          selectedTab === 'OKTA') &&
          object.send_cycle !== 'once' && (
            <Fragment>
              <div className='flex justify-between w-full pb-1'>
                <p className='text-hka_gray-dark'>
                  Assign Employees right away
                </p>
                <label className='switch'>
                  <input
                    type='checkbox'
                    name='workplace-sync'
                    checked={assignEngagementNow}
                    onChange={() => setAssignEngagementNow((p) => !p)}
                    className='cursor-pointer'
                  />
                  <span className='slider'></span>
                </label>
              </div>
              <p className='pb-2 mb-2 text-sm border-b border-hka_gray-light'>
                The next assign/renew schedule for this Engagement is{' '}
                <span className='font-basis-bold'>on {nextRenewDate}</span>. You
                can choose to assign employees right away (they will also be
                reassigned on {nextRenewDate}) by enabling the switch, or to be
                assigned on {nextRenewDate} for the first time by disabling the
                switch below.
              </p>
            </Fragment>
          )}
        {selectedTab === 'SLACK' && <SlackOptions />}
        {selectedTab === 'MANUALLY' && <ManuallyOptions />}
        {selectedTab === 'OKTA' && <OktaOptions />}
      </Fragment>
    </Modal>
  );
};

export default AssignEmployeesEngagements;
