import { useEffect, useState } from 'react';

import WysiwygRichTextEditor from '../../../WysiwygEditor/WysiwygRichTextEditor';
import { getEditorState } from '../../../../utils/wysiwygEditorHelpers';
import AnswerResultItem from './AnswerResultItem';
import Divider from '../../../Base/Divider';

const EmployeeLessonAttemptDetails = ({
  selectedItemType,
  setSelecteItemAttempt,
  selectedItemAttempt,
  selectedTrainingAttempt,
  lessons,
  assessmentsAttemptsData,
  selectedAssessmentID,
  questions,
}) => {
  const [title, setTitle] = useState('');
  const [answers, setAnswers] = useState([]);
  const [position, setPosition] = useState([]);

  const [editorState, setNotesEditorState] = useState(undefined);
  const [lessonHasNoQuestions, setLessonHasNoQuestions] = useState(false);

  const selectedAnswers = JSON.parse(selectedItemAttempt.selected_answers);

  const changeLessonHandler = (id) => {
    const nextPosition = id === '+' ? position + 1 : position - 1;
    if (selectedItemType === 'Lesson') {
      const nextLesson = lessons[nextPosition];
      const nextLessonAttempt =
        selectedTrainingAttempt.data.lesson_attempts.find(
          (a) => a.lesson === nextLesson?.id
        );
      setSelecteItemAttempt({ itemType: 'Lesson', item: nextLessonAttempt });
    }
    if (selectedItemType === 'Assessment') {
      const nextQuestion = questions[0][nextPosition];
      let assessmentAttempts = assessmentsAttemptsData.find(
        (ad) => ad?.assessmentID === selectedAssessmentID
      );
      assessmentAttempts = assessmentAttempts.data.filter(
        (ad) => ad.training_attempt_id === selectedTrainingAttempt?.data.id
      );
      assessmentAttempts = assessmentAttempts.sort(
        (a, b) => new Date(a.finish_date) - new Date(b.finish_date)
      );
      assessmentAttempts = assessmentAttempts.map((attempt, index) => ({
        label: `Attempt ${index + 1} ${
          !attempt.finish_date ? '(In Progress)' : ''
        }`,
        value: attempt.id,
        data: attempt,
      }));
      const nextAssessmentAttempt =
        assessmentAttempts[0].data.question_attempts.find(
          (a) => a.assessment_question_id === nextQuestion.id
        );
      setSelecteItemAttempt({
        itemType: 'Assessment',
        item: nextAssessmentAttempt,
        assessmentID: selectedAssessmentID,
      });
    }
  };

  useEffect(() => {
    const initializeEditorState = async () => {
      if (selectedItemType === 'Lesson') {
        const item = lessons.find((l) => l.id === selectedItemAttempt.lesson);
        const state = await getEditorState(
          item.editor_content.question_editor_state,
          item.editor_content,
          null,
          false
        );
        if (item.questions.length > 0) {
          setNotesEditorState(state);
          setLessonHasNoQuestions(false);
        } else {
          setLessonHasNoQuestions(true);
        }

        setTitle(item.title);
        setAnswers(item.questions[0]?.answers);
        setPosition(
          lessons.findIndex((l) => l.id === selectedItemAttempt.lesson)
        );
      }

      if (selectedItemType === 'Assessment') {
        const item = questions[0].find(
          (q) => q.id === selectedItemAttempt.assessment_question_id
        );
        const state = await getEditorState(
          item.editor_content.quiz_editor_state,
          item.editor_content,
          null,
          false
        );
        setNotesEditorState(state);

        const itemPosition = questions[0].findIndex(
          (q) => q.id === selectedItemAttempt.assessment_question_id
        );

        setPosition(itemPosition);
        setTitle(`Question ${itemPosition + 1}`);
        setAnswers(item.answers);
      }
    };
    initializeEditorState();
  }, [
    lessons,
    questions,
    selectedItemAttempt.assessment_question_id,
    selectedItemAttempt.lesson,
    selectedItemType,
  ]);

  return (
    <section>
      <div
        className='flex items-center mb-2'
        onClick={() => setSelecteItemAttempt(undefined)}
      >
        <span className='icon-arrow left text-orange'></span>
        <button
          type='button'
          onClick={() => {}}
          className='button text-orange -ml-1.3'
        >
          Summary
        </button>
      </div>
      <div className='flex flex-col h-full gap-0.3 justify-between'>
        <div className='text-sm'>
          {selectedItemType === 'Lesson' ? 'Lesson' : 'Question'} {position + 1}
        </div>
        <div className='text-base2 font-basis-bold text-hka_gray'>{title}</div>
      </div>

      {lessonHasNoQuestions && (
        <p className='my-3'>This lesson has no questions.</p>
      )}

      {!lessonHasNoQuestions && (
        <section>
          <section className='my-2'>
            <WysiwygRichTextEditor
              editorState={editorState}
              readOnly
              toolbarHidden
            />
          </section>

          {selectedAnswers && (
            <section className='my-2'>
              {answers.map((answer) => (
                <AnswerResultItem
                  key={answer.id}
                  answer={answer}
                  selectedAnswers={selectedAnswers}
                />
              ))}
            </section>
          )}
          {!selectedAnswers && (
            <section>
              <p className='text-orange'>
                This lesson was skipped based on the user's quiz results.
              </p>
            </section>
          )}
        </section>
      )}

      {!lessonHasNoQuestions && (
        <section className='my-2'>
          <h2 className='w-full font-basis-regular'>
            Grade:{' '}
            {(
              (selectedItemAttempt.earned_score /
                selectedItemAttempt.possible_score) *
              100
            ).toFixed(0)}
            %
          </h2>
          <div className='mt-0.5'>
            <p className='text-sm'>
              {selectedItemAttempt.earned_score} out of{' '}
              {selectedItemAttempt.possible_score} correct answers selected.
            </p>
          </div>
        </section>
      )}

      <Divider />

      <section className='mt-1 flex justify-between'>
        <button
          onClick={() => changeLessonHandler('-')}
          className='px-2 button button--blue'
          disabled={position === 0}
        >
          <span className='icon-arrow transform rotate-90'></span> Previous{' '}
          {selectedItemType === 'Lesson' ? 'Lesson' : 'Question'}
        </button>
        <button
          onClick={() => changeLessonHandler('+')}
          className='px-2 button button--blue'
          disabled={
            selectedTrainingAttempt.data.lesson_attempts.length === position + 1
          }
        >
          Next {selectedItemType === 'Lesson' ? 'Lesson' : 'Question'}{' '}
          <span className='icon-arrow transform -rotate-90'></span>
        </button>
      </section>
    </section>
  );
};

export default EmployeeLessonAttemptDetails;
