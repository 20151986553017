import LoaderInline from '../../LoaderInline';

import './customScrollbar.css';

const title = (answer_type) => {
  if (answer_type === 'user_acknowledged') {
    return 'Acknowledged';
  }
  if (answer_type === 'neutral' || answer_type === 'multiple_choice') {
    return 'Answers';
  }
  if (answer_type === 'written_response') {
    return 'Responses';
  }
  return '';
};

const getAnswers = ({ messageStatistics, questionAnswers }) => {
  const {
    question,
    answer_type,
    acknowledged_count,
    assigned_employees_count,
    written_answer_count,
    answers,
    completed,
  } = messageStatistics || {};

  if (answer_type === 'written_response') {
    return [
      {
        text: `${written_answer_count}/${assigned_employees_count}`,
        progressColor: 'bg-hka_blue-light',
        progress: (
          (written_answer_count / assigned_employees_count) *
          100
        ).toFixed(0),
        question,
      },
    ];
  }

  if (answer_type === 'user_acknowledged') {
    return [
      {
        text: `${acknowledged_count}/${assigned_employees_count}`,
        progressColor: 'bg-hka_blue-light',
        progress: (
          (acknowledged_count / assigned_employees_count) *
          100
        ).toFixed(0),
        question,
      },
    ];
  }

  if (answer_type === 'neutral') {
    return questionAnswers.map((item) => ({
      text: item.answer,
      progressColor: 'bg-hka_blue-light',
      progress: (
        (answers[item.id] / assigned_employees_count || 0) * 100
      ).toFixed(0),
      question: item.id,
    }));
  }

  if (answer_type === 'multiple_choice') {
    return questionAnswers.map((item) => ({
      text: item.answer,
      progressColor: item.correct ? 'bg-hka_green-light' : 'bg-hka_red-light',
      progress: (
        (answers[item.id] / assigned_employees_count || 0) * 100
      ).toFixed(0),
      question: item.id,
    }));
  }

  if (answer_type === 'no_action') {
    return [
      {
        text: 'Received announcement',
        progressColor: 'bg-hka_blue-light',
        progress: 100,
        question,
      },
    ];
  }

  if (answer_type === 'external_url') {
    return [
      {
        text: 'Users',
        progressColor: 'bg-hka_blue-light',
        progress: (
          (acknowledged_count / assigned_employees_count || 0) * 100
        ).toFixed(0),
        question,
      },
    ];
  }

  return [];
};

const ProgressBar = ({ text, progress, progressColor }) => {
  return (
    <div className='w-full h-2.6 rounded-md relative overflow-hidden bg-hka_gray-ultraLight'>
      <div
        style={{ width: `${progress}%` }}
        className={` absolute h-full top-0 left-0 rounded-md ${progressColor}`}
      ></div>
      <div className='w-full h-2.6 absolute top-0 left-0 flex justify-between items-center px-0.7'>
        <p className='pr-1 text-sm truncate text-hka_gray font-basis-medium'>
          {text}
        </p>
        <p className='text-sm text-hka_gray-dark font-basis-bold'>
          {progress}%
        </p>
      </div>
    </div>
  );
};

const MessageCardStats = ({
  questionAnswers,
  messageStatistics,
  isLoadingStatistics,
}) => {
  if (isLoadingStatistics)
    return <LoaderInline wrapperClasses={'w-full h-full'} loaderSize={3} />;

  const { answer_type } = messageStatistics || {};

  const answers = getAnswers({ messageStatistics, questionAnswers });

  return (
    <section className='h-14'>
      <p className='mb-0.5 text-sm font-basis-medium'>{title(answer_type)}</p>
      <div
        id='custom-scrollbar'
        className={`space-y-0.5 ${answers.length > 4 && 'overflow-y-scroll'}`}
      >
        {!messageStatistics && <p>Message results not yet available.</p>}
        {answers.map((answer) => (
          <ProgressBar
            key={answer.question}
            text={answer.text}
            progressColor={answer.progressColor}
            progress={answer.progress}
          />
        ))}
      </div>
    </section>
  );
};

export default MessageCardStats;
