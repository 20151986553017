import { useQuery } from "@tanstack/react-query";
import { getChannelEngagementResults } from "../../../api/engagementsApi";

const getKeys = (companyEngagementID, currentPage, keys) => [
  "channel-engagement-results",
  companyEngagementID,
  currentPage,
  ...keys,
];

const staleTime = 60000;

export const useChannelEngagementResults = ({
  currentPage,
  companyEngagementID,
  keys,
  enabled,
}) => {
  return useQuery({
    queryKey: getKeys(companyEngagementID, currentPage, keys),
    queryFn: () =>
      getChannelEngagementResults({
        companyEngagementID,
        page: currentPage,
        schedule: keys[0],
        name: keys[1],
        sortColumn: keys[2],
        sortType: keys[3],
      }),
    keepPreviousData: true,
    enabled,
    staleTime,
  });
};

export const prefetchChannelEngagentResults = (
  queryClient,
  companyEngagementID
) => {
  const currentPage = 1;
  const keys = [undefined, "", undefined, undefined];
  queryClient.prefetchQuery({
    queryKey: getKeys(companyEngagementID, currentPage, keys),
    queryFn: () =>
      getChannelEngagementResults({
        companyEngagementID,
        page: currentPage,
      }),
    staleTime,
  });
};
