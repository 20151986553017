import React, { Fragment } from "react";
import ActionButton from "../../ActionButton";
import AttackSummaryCard from "./PhishingMetrics/AttackSummaryCard";
import EmptyMetricsStateCard from "./EmptyMetricsStateCard";
import EmptyTopicStateCard from "./EmptyTopicStateCard";

export default function PhishingMetrics({ phishingMetrics }) {
  const numberOfActiveCampaigns = phishingMetrics.number_of_active_campaigns;

  return (
    <section className="mb-2 space-y-1">
      <header className="flex flex-col lg:items-center lg:justify-between lg:flex-row">
        <h4 className="flex items-center mb-2 lg:mb-0">Phishing</h4>
        {numberOfActiveCampaigns > 0 && (
          <ActionButton
            className="w-full button--blue sm:w-auto button"
            to={"/phishing"}
          >
            {`View Campaigns (${numberOfActiveCampaigns})`}{" "}
            <span className="icon-arrow right text-hka_blue -mr-0.5 ml-1"></span>
          </ActionButton>
        )}
      </header>
      <div className="flex flex-col space-y-2 lg:flex-row lg:space-y-0">
        {numberOfActiveCampaigns > 0 ? (
          <Fragment>
            <AttackSummaryCard
              data={phishingMetrics.last_campaign}
              subtitle="Latest Campaign"
              padding={"pr-0 sm:pr-2"}
            />
            <AttackSummaryCard
              data={phishingMetrics.all_time}
              subtitle="All Campaigns"
              padding={"pl-0 sm:pl-2"}
            />
          </Fragment>
        ) : (
          <Fragment>
            <div className="flex-1 lg:pr-2 lg:max-w-50">
              <div className="flex h-full p-2 bg-white rounded-xl lg:p-4 shadow-light">
                <EmptyMetricsStateCard
                  title="Create your first Campaign"
                  redirectPath="/phishing?create=true"
                  imageUrl="https://haekka-product-assets.s3.us-west-2.amazonaws.com/create-first-phishing.svg"
                />
              </div>
            </div>

            <div className="flex-1 lg:pl-2 lg:max-w-50">
              <div className="flex h-full p-2 bg-white rounded-xl lg:p-4 shadow-light">
                <EmptyTopicStateCard
                  topic="Phishing"
                  topicDescription="Creating awareness about the dangers of phishing emails is a
                  important aspect of an organizations security posture.
                  "
                  metricsDescription="We’ll provide Phishing success and failure metrics here so you can see how well your employees are able to identify malicious emails."
                />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </section>
  );
}
