import api from '../client';
import { getReorderCurriculumItemsPayload } from '../utils/training';

// Trainings

export const getTrainingsWithAssigmentsData = async ({
  companyID,
  selectedTags,
  title,
  page,
  pageSize,
}) => {
  const params = {
    page,
    page_size: pageSize,
    selected_tags: selectedTags,
    title,
  };
  const { data } = await api.get(`api/companies/${companyID}/trainings/list`, {
    params,
  });
  return data;
};

// Curriclum Lessons:

export const retrieveLessons = async ({ curriculumID }) => {
  const { data } = await api.get(`/api/curriculums/${curriculumID}/content`);
  return data;
};

export const addLesson = async ({ lesson }) => {
  const { data } = await api.post(`/api/lessons/`, lesson);
  return data;
};

export const updateLesson = async ({ lesson }) => {
  const { data } = await api.put(`/api/lessons/${lesson.id}`, lesson);
  return data;
};

export const deleteLesson = async ({ lessonID }) => {
  await api.delete(`/api/lessons/${lessonID}`);
};

export const reorderCurriculumItems = async ({ curriculumID, payload }) => {
  const { data } = await api.post(
    `/api/curriculums/${curriculumID}/reorder_content`,
    getReorderCurriculumItemsPayload(payload)
  );
  return data;
};

// Trainings

export const getTrainingStatistics = async ({ companyID, trainingID }) => {
  const { data } = await api.get(
    `/api/companies/${companyID}/trainings/${trainingID}/statistics`
  );
  return data;
};

export const sendEmployeeAssignedTrainingNotification = async ({
  trainingID,
}) => {
  await api.post(
    `api/employees/trainings/${trainingID}/alert_employees_of_training`,
    {}
  );
};

// Training Tags

export const getTrainingTags = async ({
  companyID,
  page = 1,
  pageSize = 10_000,
}) => {
  const params = {
    page,
    page_size: pageSize,
  };

  const { data } = await api.get(`/api/companies/${companyID}/trainings/tags`, {
    params,
  });
  return data;
};

export const deleteTrainingTag = async ({ companyID, tag_id }) => {
  await api.delete(`/api/companies/${companyID}/trainings/tags/${tag_id}`);
};

export const updateTrainingTag = async ({ companyID, tag_id, name }) => {
  const { data } = await api.patch(
    `/api/companies/${companyID}/trainings/tags/${tag_id}`,
    { name }
  );
  return data;
};

// Employee Trinings

export const getEmployeeTrainingAttempts = async ({
  trainingID,
  employeeID,
}) => {
  const { data } = await api.get(
    `/api/employees/${employeeID}/trainings/${trainingID}/attempts`
  );
  return data;
};

export const getEmployeeAssessmentAttempts = async ({
  assessmentID,
  employeeID,
}) => {
  const { data } = await api.get(
    `/api/employees/${employeeID}/training-assessment/${assessmentID}/attempts`
  );
  return data;
};

// Assign Employees

export const assignEmployeesTrainingWithDoceboOption = async ({
  auth,
  curriculumTrainingId,
}) => {
  const companyId = auth.company;
  const { data } = await api.post(
    `api/employees/trainings/${curriculumTrainingId}/docebo`,
    {
      company_id: companyId,
    }
  );
  return data;
};

export const assignEmployeesTrainingWithSeismicOption = async ({
  auth,
  curriculumTrainingId,
}) => {
  const companyId = auth.company;
  const { data } = await api.post(
    `api/employees/trainings/${curriculumTrainingId}/seismic`,
    {
      company_id: companyId,
    }
  );
  return data;
};

export const assignEmployeesTrainingWithGoogleOption = async ({
  auth,
  curriculumTrainingId,
  googleAutoEnroll,
  googleAddAllUsers,
  alertEmployees,
  selectedGoogleGroups,
}) => {
  const companyId = auth.company;
  const { data } = await api.post(
    `api/employees/trainings/${curriculumTrainingId}/google`,
    {
      company_id: companyId,
      add_all_google_users: googleAddAllUsers,
      auto_enroll_google: googleAutoEnroll,
      alert_employees: alertEmployees,
      groups: selectedGoogleGroups,
    }
  );
  return data;
};
