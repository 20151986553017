const highlightExpanderMenuItem = (targetValue) => {
  const menuItems = document.querySelectorAll("[data-value]");

  menuItems.forEach((element) => {
    const value = element.getAttribute("data-value");
    if (value === targetValue) {
      element.firstChild.classList.add("text-orange");
    } else {
      element.firstChild.classList.remove("text-orange");
    }
  });
};

export { highlightExpanderMenuItem };
