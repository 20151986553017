import { Fragment, useContext } from 'react';
import AssignEmployeesContext from '../../store/assign-employeees-context';
import {
  DOCEBO_LOGO,
  GOOGLE_LOGO,
  OKTA_LOGO,
  SEISMIC_LOGO,
  SLACK_LOGO,
} from '../../utils/constants';
import { useExternalAppsData } from '../../hooks/data-hooks/integrations/useExternalAppsData';
import { useDispatch, useSelector } from 'react-redux';
import { getIntegrationsAction } from '../../store/settingsActions';
import { useAppContext } from '../../libs/contextLib';

const AssingEmployeesOptions = ({
  hideForEngagements,
  hideForStreams,
  hideForWorkflows,
}) => {
  const { isAuthenticated } = useAppContext();
  const dispatch = useDispatch();
  const { clearContext, selectedTab, setSelectedTab } = useContext(
    AssignEmployeesContext
  );
  // const { isGoogleAppInstalled } = useAppContext();
  const { integrations } = useSelector((state) => state.settings);
  if (integrations === undefined) {
    dispatch(
      getIntegrationsAction({
        auth: isAuthenticated,
      })
    );
  }

  const { data: externalIntegrations } = useExternalAppsData();
  const activeDoceboApp = externalIntegrations?.results.find(
    (ei) => ei.resourcetype === 'DoceboInstallation' && ei.is_active
  );
  const activeGoogleApp = externalIntegrations?.results.find(
    (ei) => ei.resourcetype === 'GoogleInstallation' && ei.is_active
  );
  const activeSeismicApp = externalIntegrations?.results.find(
    (ei) => ei.resourcetype === 'SeismicInstallation' && ei.is_active
  );

  const TABS = {
    SLACK: { caption: 'Slack', url: SLACK_LOGO },
  };

  if (integrations?.okta && !hideForStreams && !hideForWorkflows) {
    TABS.OKTA = {
      caption: 'Okta',
      url: OKTA_LOGO,
      width: 65,
      height: 'auto',
      imgClasses: { marginTop: '5px' },
    };
  }

  if (
    activeDoceboApp &&
    !hideForEngagements &&
    !hideForStreams &&
    !hideForWorkflows
  ) {
    TABS.DOCEBO = {
      caption: 'Docebo',
      url: DOCEBO_LOGO,
      width: 70,
      imgClasses: { marginTop: '5px' },
    };
  }

  if (
    activeGoogleApp &&
    !hideForEngagements &&
    !hideForStreams &&
    !hideForWorkflows
  ) {
    TABS.GOOGLE = { caption: 'Google', url: GOOGLE_LOGO };
  }
  if (
    activeSeismicApp &&
    !hideForEngagements &&
    !hideForStreams &&
    !hideForWorkflows
  ) {
    TABS.SEISMIC = {
      caption: 'Seismic',
      url: SEISMIC_LOGO,
      width: 27,
    };
  }

  TABS.MANUALLY = { caption: 'Manually', icon: 'users' };

  if (!hideForEngagements && !hideForStreams && !hideForWorkflows) {
    TABS.GROUPS = { caption: 'Groups', icon: 'group' };
  }

  return (
    <Fragment>
      <section>
        <h4 className='mb-1 font-basis-bold'>
          Select how you want to add employees
        </h4>
        <div className='flex mb-2 space-x-2'>
          {Object.keys(TABS).map((tab) => {
            const tabData = TABS[tab];
            return (
              <div
                key={tabData.caption}
                onClick={() => {
                  clearContext();
                  setSelectedTab(tab);
                }}
                className={`font-basis-bold cursor-pointer w-full ${
                  tab === selectedTab ? '' : ''
                }`}
              >
                <div
                  className={`border ${
                    tab === selectedTab
                      ? 'border-hka_blue bg-hka_blue-light bg-opacity-50 text-hka_blue'
                      : 'border-hka_gray-border'
                  } border-opacity-80 rounded-lg flex flex-col items-center p-1 pt-1.2 h-8`}
                >
                  {tabData.url ? (
                    <img
                      style={{ cursor: 'pointer', ...tabData?.imgClasses }}
                      alt={tabData.caption}
                      height={tabData.height ? tabData.height : 30}
                      width={tabData.width ? tabData.width : 30}
                      src={tabData.url}
                      className='mb-0.5'
                    />
                  ) : (
                    <span
                      className={`icon-${tabData.icon} icon-xl mb-0.5 ${
                        tab === selectedTab ? 'text-hka_blue' : 'text-hka_gray'
                      }`}
                    ></span>
                  )}

                  <div
                    className={`text-base font-basis-bold mt-auto ${
                      tab === selectedTab ? 'text-hka_blue' : 'text-hka_gray'
                    }`}
                  >
                    {tabData.caption}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </Fragment>
  );
};

export default AssingEmployeesOptions;
