import { format } from "date-fns";

export const formatCreationDate = (date) => format(date, "MMM d yy");

export const formatQuizDate = (date) => format(date, "EEEE");

export const formatTrainingDate = (date, variant) => {
  switch (variant) {
    case "employee":
      return format(date, "MMM d yyyy");
    case "admin":
    default:
      return format(date, "MMM d");
  }
};

export const formatDaysTillDue = (date) =>
  date ? date.toString().split(" ")[0] : "";

export const formatValueDate = (date) => format(date, "yyyy-MM-dd");
