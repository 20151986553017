import axios from "axios";

const api = axios.create({
  headers: { "Content-Type": "multipart/form-data" },
});

export const getSlackUserInfo = async (access_token, userId) => {
  const bodyFormData = new FormData();
  bodyFormData.append("token", access_token);
  bodyFormData.append("content", "null");

  const { data } = await api.post(
    `https://slack.com/api/users.info?user=${userId}`,
    bodyFormData
  );
  return data;
};

export const getSlackTeamInfo = async (access_token, team_id) => {
  const bodyFormData = new FormData();
  bodyFormData.append("token", access_token);
  bodyFormData.append("team", team_id);

  const { data } = await api.post(
    "https://slack.com/api/team.info",
    bodyFormData
  );
  return data;
};

const postMassageToSlack = async (access_token, text, blocks, channel) => {
  const bodyFormData = new FormData();
  bodyFormData.append("token", access_token);
  bodyFormData.append("text", text);
  bodyFormData.append("blocks", JSON.stringify(blocks));

  await api.post(
    `https://slack.com/api/chat.postMessage?channel=${channel}`,
    bodyFormData
  );
};
