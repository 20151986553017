import React from "react";
import Modal from "../../Modal/Modal";
import { API_URL } from "../../../client";

export default function TemplatePreviewModal({ showModal, onClose, template }) {
  return (
    <Modal show={showModal} title="Template Preview" onClose={onClose} noFooter>
      <div className="h-full overflow-hidden border rounded-md border-hka_gray-border">
        <img
          src={API_URL + template.preview_image_url}
          alt="Template Preview"
          className=""
        />
      </div>
    </Modal>
  );
}
