import { useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import AssignEmployeesContext from '../../store/assign-employeees-context';
import { getGoogleGroups } from '../../api/integrationsApi';

import SelectInput from '../Base/SelectInput';

const syncGroupsMessage = (
  <p>
    <span className='font-basis-bold'>
      Groups syncing assigns Google users added to the group after the training
      is created.
    </span>{' '}
    Haekka syncs selected groups on a daily basis
    <br />
    To add existing users from a group, use Add users from Google Groups
    (above).
  </p>
);

const GoogleOptions = () => {
  const assignEmployeesContext = useContext(AssignEmployeesContext);
  const {
    googleAddAllUsers,
    setGoogleAddAllUsers,
    googleAutoEnroll,
    setGoogleAutoEnroll,
    selectedGoogleGroups,
    setSelectedGoogleGroups,
    googleGroupsToSyncIDs,
    googleGroupsToSync,
    setGoogleGroupsToSync,
  } = assignEmployeesContext;

  const { data: groupsReponse, isFetching: isLoadingSyncGroups } = useQuery({
    queryKey: ['google-groups', googleGroupsToSyncIDs],
    queryFn: () =>
      getGoogleGroups({
        search_term: googleGroupsToSyncIDs.join(','),
        search_by: 'id',
      }),
    onSuccess: (res) => {
      setGoogleGroupsToSync(
        res.groups.map((g) => ({ label: g.name, value: g.id }))
      );
    },
    staleTime: Infinity,
    enabled: googleGroupsToSyncIDs.length > 0,
  });

  useEffect(() => {
    setGoogleGroupsToSync(
      (groupsReponse?.groups ?? []).map((g) => ({
        value: g.id,
        label: g.name,
      }))
    );
  }, [googleGroupsToSyncIDs, groupsReponse, setGoogleGroupsToSync]);

  return (
    <>
      <section className='mb-3'>
        <h4 className='font-basis-bold mb-1.5'>
          Add everyone from your Google Workspace{' '}
          <span className='text-hka_gray'>(Optional)</span>
        </h4>
        <div className='flex space-x-2'>
          <label className='switch'>
            <input
              type='checkbox'
              name='select-all-users'
              checked={googleAddAllUsers}
              onChange={() => setGoogleAddAllUsers((p) => !p)}
              className='cursor-pointer'
            />
            <span className='slider'></span>
          </label>
          <div className='mb-2'>
            <h4 className='font-basis-bold'>
              Add all existing Google Workspace users
            </h4>
          </div>
        </div>
        <div className='flex space-x-2'>
          <label className='switch'>
            <input
              type='checkbox'
              name='workplace-sync'
              checked={googleAutoEnroll}
              onChange={() => setGoogleAutoEnroll((p) => !p)}
              className='cursor-pointer'
            />
            <span className='slider'></span>
          </label>
          <h4 className='font-basis-bold'>
            Automatically add any new Google Workspace users to this training
          </h4>
        </div>
        <p className='font-basis-regular mt-1.5'>
          Note: To guarantee that all current and future members of your Google
          Workspace are assigned this training, please enable both switches
          above.
        </p>
      </section>

      <SelectInput
        label={{
          text: 'Add users from Google Groups',
          optional: true,
        }}
        value={selectedGoogleGroups}
        onChange={(gs) => setSelectedGoogleGroups(gs)}
        placeholder='Search Google Groups'
        isMultiSelect
        isDisabled={googleAddAllUsers}
        isAsync={{
          apiFunction: async (args) =>
            await getGoogleGroups({ ...args, page: '', search_by: 'name' }),
          responseProperty: 'groups',
          responseItemName: 'name',
        }}
        infoMessages={[
          {
            show: googleAddAllUsers,
            type: 'warning',
            message: 'Group selection is ignored when All users switch is on',
          },
        ]}
      />

      <SelectInput
        label={{
          text: 'Sync users from Google Groups',
          optional: true,
        }}
        value={googleGroupsToSync}
        onChange={(gs) => setGoogleGroupsToSync(gs)}
        placeholder='Search Google Groups'
        isMultiSelect
        isAsync={{
          apiFunction: async (args) =>
            await getGoogleGroups({ ...args, page: '', search_by: 'name' }),
          responseProperty: 'groups',
          responseItemName: 'name',
        }}
        isDisabled={isLoadingSyncGroups}
        infoMessages={[
          {
            show: true,
            type: 'info',
            message: syncGroupsMessage,
          },
        ]}
      />
    </>
  );
};

export default GoogleOptions;
