import { useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";

import {
  useEngagementSchedulesData,
  useRequestEngagementEvidenceReport,
} from "../../hooks/data-hooks/engagements";

import { useAppContext } from "../../libs/contextLib";
import Modal from "../Modal/Modal";

const EngagementEvidenceModal = ({ show, onCancel }) => {
  const { id: companyEngagementID } = useParams();
  const { isAuthenticated } = useAppContext();

  const [selectedOption, setSelectedOption] = useState(undefined);

  const { data: schedules, isLoading } = useEngagementSchedulesData({
    companyEngagementID,
  });

  const { mutate } = useRequestEngagementEvidenceReport({ onClose: onCancel });

  const submitHandler = async () => {
    mutate({
      auth: isAuthenticated,
      scheduleID: selectedOption.value,
    });
  };

  return (
    <Modal
      show={show}
      title="Download Evidence"
      onSubmit={submitHandler}
      onClose={onCancel}
      submitButtonType="success"
      submitButtonText="Download"
    >
      <h4 className="font-basis-bold mt-1 mb-1.5">Select Renewal Period</h4>

      <section
        className={`${!isLoading && schedules.length < 5 ? "mb-17" : "mb-40"}`}
      >
        <Select
          onChange={(option) => setSelectedOption(option)}
          value={selectedOption}
          isMulti={false}
          options={schedules}
          classNamePrefix="select"
          unstyled
        />
      </section>
    </Modal>
  );
};

export default EngagementEvidenceModal;
