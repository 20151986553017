import React, { Fragment } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import DropdownMenu from "../Base/DropdownMenu";

function PhishingCampaignTemplateRow({
  template,
  lastAttack,
  actions,
  formattedLastSentDate,
  reportRate,
  isWhitelisted,
}) {
  return (
    <div className="flex justify-between p-2 bg-white rounded-lg shadow-light phishing__template">
      {/* Template Icon/Application/Title */}
      <div className="flex items-center w-1/2 pr-1 md:w-1/4">
        <img
          className="w-2.4 h-2.4 mr-1 rounded"
          src={
            template.icon ||
            "https://haekka-author-icons.s3.us-west-2.amazonaws.com/HKA--UPDATED--Author_Haekka.png"
          }
          alt="Icon"
        ></img>
        <div className="truncate">
          <p className="hidden text-sm font-basis-medium text-hka_gray md:flex">
            {template.application || "Haekka"}
          </p>
          <h4 className="w-full text-left truncate font-basis-medium">
            {template.name}
          </h4>
          {/* Last Sent Date */}
          <div className="flex items-center md:hidden">
            <span className="opacity-50 h-2 -ml-0.5 -mt-0.5 icon-calendar"></span>
            <p className="text-sm opacity-60 font-basis-medium">
              {formattedLastSentDate ? "Last sent" : "Has not sent"}
            </p>
            <p className="text-sm ml-0.5">
              {formattedLastSentDate}
            </p>
          </div>
        </div>
      </div>

      {/* Last Sent Date */}
      <div className="items-center hidden md:flex md:w-1/4">
        <span className="opacity-60 icon-calendar"></span>
        <div className="flex">
          <p className="text-sm whitespace-nowrap">
            {formattedLastSentDate ? "Last sent" : "Has not sent"}
          </p>
          {/* <p className="hidden text-sm opacity-75 font-basis-medium xl:flex">Last sent</p> */}
          <p className="text-sm whitespace-nowrap ml-0.5">
            {formattedLastSentDate}
          </p>
        </div>
      </div>

      {/* Report Rate */}
      <div className="flex items-center justify-end w-10 space-x-1 lg:w-25">
      {lastAttack && (
        <>
          <div className="w-3 h-3">
            <CircularProgressbar
              value={reportRate}
              styles={buildStyles({
                textColor: "#878a9a",
                pathColor: "#36C5F0",
                trailColor: "#EFF0F7",
              })}
              strokeWidth={10}
            />
          </div>
          <div className="flex items-center">
            <p className="mr-0.5 font-basis-medium text-hka_gray-dark">
              {reportRate}%
            </p>
            <p className="hidden text-sm font-basis-medium lg:flex">
              Successfully Reported
            </p>
          </div>
        </>
      )}
      </div>

      {/* Actions */}
      <div className="flex items-center justify-end space-x-1 md:w-8">
        {!isWhitelisted && (
          <span
            className="rounded-full icon-warning bg-orange text-orange-light"
            data-tip="Domain not whitelisted."
          ></span>
        )}
        {actions.length > 0 && <DropdownMenu actions={actions} />}
      </div>
    </div>
  );
}

export default PhishingCampaignTemplateRow;
