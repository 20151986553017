import { uninstallVanta, syncVantaManually } from '../../api';
import { useAppContext } from '../../libs/contextLib';
import toast from 'react-hot-toast';
import { onError } from '../../libs/errorLib';
import Note from '../Note';

const VantaIngration = ({ vantaOAuthLink, isVantaInstalled }) => {
  const { isAuthenticated: auth } = useAppContext();

  const openVantaLink = () => {
    window.open(vantaOAuthLink, '_blank');
  };

  const disconnectVanta = async () => {
    try {
      await uninstallVanta({ auth });
      toast.success('Vanta integration has been disconnected.');
      window.location.reload();
    } catch (error) {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  const syncVantaManuallyHandler = async () => {
    try {
      await syncVantaManually({ auth });
      toast.success("We'll start syncing your trainings right away!");
    } catch (error) {
      onError(error);
    }
  };

  return (
    <div className='p-2 mb-4 bg-white shadow-light rounded-xl col-span-full'>
      <h3 className='mb-1 setting__title'>Vanta Integration</h3>
      {isVantaInstalled && (
        <Note type='info'>
          <p>
            <span className='font-basis-bold'>Note:</span> if you are
            experiencing issues with training data not updating in Vanta, we
            recommend verifying that every employee has a training due date and
            that the training is appropriately tagged with one or more of the
            following frameworks: SOC2, ISO27001, HIPAA, PCI, GDPR, or CCPA.
          </p>
        </Note>
      )}

      <section className='w-full md:w-max-content mt-2 flex flex-col rounded-md border border-hka_gray-light p-1.5 min-w-20 relative'>
        <div className='h-4 mb-3 overflow-hidden'>
          <img
            src='https://haekka-public-images.s3.us-west-2.amazonaws.com/vanta-logo-vector.svg'
            alt='Vanta Logo'
            className='w-8'
          />
        </div>
        <div className='flex flex-col items-center justify-start w-full gap-1 md:flex-row'>
          <button
            className={`w-full button button--save md:w-auto`}
            disabled={isVantaInstalled}
            onClick={() => openVantaLink()}
          >
            {isVantaInstalled ? 'Vanta Installed' : 'Link with Vanta'}
          </button>
          {isVantaInstalled && (
            <button
              className={`w-full button button--save md:w-auto`}
              onClick={openVantaLink}
            >
              Reinstall Vanta
            </button>
          )}
          {isVantaInstalled && (
            <button
              className={`w-full button button--remove md:w-auto`}
              onClick={() => disconnectVanta()}
            >
              Disconnect Vanta
            </button>
          )}
          {isVantaInstalled && (
            <button
              className={`w-full button button--save md:w-auto`}
              onClick={syncVantaManuallyHandler}
            >
              Sync Training Data
            </button>
          )}
        </div>
      </section>
    </div>
  );
};

export default VantaIngration;
