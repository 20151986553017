import React from "react";

export default function EmptyTopicStateCard({
  topic,
  topicDescription,
  metricsDescription,
}) {
  return (
    <div className="flex-col">
      <div className="mb-2">
        <p className="font-basis-medium text-base2 mb-0.5 flex items-center">
          <img
            alt="Check"
            src="https://haekka-public-images.s3.us-west-2.amazonaws.com/hka--engagement-check.svg"
            className="mr-1"
          ></img>{" "}
          {topic}
        </p>
        <p>{topicDescription}</p>
      </div>
      <p className="font-basis-medium text-base2 mb-0.5 flex items-center">
        <img
          alt="Metrics"
          src="https://haekka-public-images.s3.us-west-2.amazonaws.com/hka--engagement-metrics.svg"
          className="mr-1"
        ></img>{" "}
        {topic} Metrics
      </p>
      <p>{metricsDescription}</p>
    </div>
  );
}
