import Select, { createFilter } from "react-select";
import { FixedSizeList as List } from "react-window";
import PropTypes from "prop-types";

const height = 35;

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      className="select__menu-list"
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

const ChannelsSelect = ({
  defaultOptions,
  onChange,
  options,
  disabled,
  labelText = "Select channels",
}) => {
  return (
    <Select
      components={{ MenuList }}
      value={defaultOptions}
      onChange={onChange}
      placeholder={labelText}
      isMulti
      options={options}
      classNamePrefix="select"
      isDisabled={disabled}
      filterOption={createFilter({ ignoreAccents: false })}
      defaultValue={defaultOptions}
      unstyled
    />
  );

  // return (
  //   <Select
  //     value={defaultOptions}
  //     onChange={onChange}
  //     placeholder={labelText}
  //     isMulti
  //     options={options}
  //     classNamePrefix="select"
  //     maxMenuHeight="20.8rem"
  //     ght="20.8rem"
  //     isDisabled={disabled}
  //   />
  // );
};

ChannelsSelect.propTypes = {
  defaultOptions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  labelText: PropTypes.string,
};

export default ChannelsSelect;
