import React from "react";
import Modal from "../../Modal/Modal";

export default function RemoveEmployeeFromCampaignModal({
  show,
  employee,
  onClose,
  onRemove,
}) {
  return (
    <Modal
      show={show}
      title="Remove Employee"
      submitButtonType="danger"
      onClose={onClose}
      onSubmit={onRemove}
      submitButtonText="Remove"
      compact
    >
      <p>Are you sure you want to remove {employee.name} from this campaign?</p>
    </Modal>
  );
}
