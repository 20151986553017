import { useMutation, useQueryClient } from '@tanstack/react-query';
import { configureExternalAppWebhook } from '../../../api/integrationsApi';

export const useConfigureExternalAppWebhook = ({ callbackFn = () => {} }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: configureExternalAppWebhook,
    onSuccess: (res) => {
      if (res.success) {
        queryClient.invalidateQueries(['external-installations-all']);
      }
      callbackFn(res);
    },
  });
};
