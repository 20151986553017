import api from "../../client";
import { denormalizeTestPhishingIMAPConfigurationPayload } from "./_denormalization";
import { normalizeTestPhishingIMAPConfigurationResponse } from "./_normalization";

export const getPhishingForwardedEmailIMAPBindings = async () => {
  try {
    const { data } = await api.get("/api/phishing/imap");
    return data;
  } catch (error) {
    throw error;
  }
};

export const updatePhishingForwardedEmailIMAPBinding = async (binding) => {
  try {
    const { data } = await api.put("/api/phishing/imap", {
      ...binding,
      password: binding.decrypted_password,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const testPhishingForwardedEmailIMAPBinding = async (
  binding,
  imapHost,
  imapPort
) => {
  try {
    const { data } = await api.get("/api/phishing/imap/test/binding", {
      params: {
        email: binding.email,
        password: binding.decrypted_password,
        imap_host: imapHost,
        imap_port: imapPort,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const testPhishingIMAPConfiguration = async (configuration) => {
  try {
    const { data } = await api.post(
      "/api/phishing/imap/test/configuration",
      denormalizeTestPhishingIMAPConfigurationPayload(configuration)
    );

    return normalizeTestPhishingIMAPConfigurationResponse(data);
  } catch (error) {
    throw error;
  }
};
