import React from "react";
import ReactDatePicker from "react-datepicker";

export default function NotificationScheduleTimepickerRow({
  sendFrom,
  sendTo,
  onUpdate,
}) {
  return (
    <div className="flex flex-row pt-2 space-x-2">
      <div className="flex items-center">
        <span className="mr-1 text-sm font-basis-bold text_hka">From</span>
        <div className="timepicker">
          <ReactDatePicker
            selected={sendFrom}
            onChange={(date) => onUpdate("send_from", date)}
            className="w-full h-4 pl-4 pr-1 focus:outline-none disabled:bg-hka_gray-light text-hka_gray datepicker"
            dateFormat="h:mm aa"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
          />
        </div>
      </div>
      <div className="flex items-center">
        <span className="mr-1 text-sm font-basis-bold text_hka">To</span>
        <div className="timepicker">
          <ReactDatePicker
            selected={sendTo}
            onChange={(date) => onUpdate("send_to", date)}
            className="w-full h-4 pl-4 pr-1 focus:outline-none disabled:bg-hka_gray-light text-hka_gray datepicker"
            dateFormat="h:mm aa"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
          />
        </div>
      </div>
    </div>
  );
}
