const Tag = ({ tag, onRemove = () => {}, onSelect, noRemoveIcon, pointer }) => {
  return (
    <div
      className={`${
        pointer ? "cursor-pointer" : ""
      } w-auto py-0 mr-1 mb-1 flex items-center justify-center text-hka_gray rounded text-xs bg-hka_gray-light border border-hka_gray-border border-opacity-20`}
      onClick={onSelect}
    >
      <span className="px-1 text-sm leading-2.8 tracking-wide rounded">
        {tag}
      </span>
      {!noRemoveIcon && (
        <span
          className="relative flex items-center justify-center w-3 h-3 -my-0.1 -ml-0.5 -mr-0.1 cursor-pointer icon-close icon-sm hover:bg-hka_red-light hover:text-hka_red icon-remove-tag transition-all rounded-sm"
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
        ></span>
      )}
    </div>
  );
};

export default Tag;
