import { useState } from 'react';

import { useDeleteAssessmentQuestion } from '../../hooks/data-hooks/assessments';

import { initQuestion } from '../../utils/assessments';
import DeleteQuestionConfirmModal from './confirmationModals/DeleteQuestionConfirmModal';

const QuestionTabs = ({
  questions,
  setQuestions,
  selectedQuestion,
  setSelectedQuestion,
  curriculumID,
}) => {
  const [deleteQuestionModal, setDeleteQuestionModal] = useState(undefined);

  const { mutate: deleteQuestion } = useDeleteAssessmentQuestion({
    curriculumID,
  });

  const addNewQuestionHandler = () => {
    setQuestions((questions) => [...questions, initQuestion(questions.length)]);
    setSelectedQuestion(questions.length);
  };

  const removeQuestionHandler = () => {
    const questionToDelete = questions.find(
      (q) => q.position === deleteQuestionModal.qPosition
    );
    if (questionToDelete.id) {
      deleteQuestion({ questionID: questionToDelete.id });
    }
    const updatedQuestions = questions
      .filter((q) => q.position !== deleteQuestionModal.qPosition)
      .map((q, i) => ({ ...q, position: i }));
    setQuestions(updatedQuestions);
    setDeleteQuestionModal(undefined);
    if (selectedQuestion === questions.length - 1) {
      setSelectedQuestion((ps) => ps - 1);
    }
  };

  return (
    <section className='mb-2'>
      <h4 className='mb-1'>Questions</h4>
      <div className='flex gap-2 overflow-x-auto overflow-y-hidden -mx-2.5 px-2.5 border-b border-hka_gray-light'>
      {questions.map((question) => (
        <div
          key={question.position}
          onClick={() => {
            setSelectedQuestion(question.position);
          }}
          className={`flex gap-0.5 cursor-pointer leading-3 h-3 ${
            selectedQuestion === question.position
              ? ''
              : ''
          }`}
        >
          <p
            className={`text-base whitespace-nowrap font-basis-bold text-hka_gray cursor-pointer leading-3 ${
              selectedQuestion === question.position
                ? ' text-hka_blue border-b-2 border-hka_blue'
                : ''
            }`}
          >
            Question {question.position + 1}
          </p>
          <span
            className='icon-sm icon-close text-hka_gray hover:bg-hka_gray-light h-2.4 w-2.4 rounded mt-0.3'
            onClick={(e) => {
              e.stopPropagation();
              setDeleteQuestionModal({
                showConfirmModal: true,
                qPosition: question.position,
              });
            }}
          ></span>
        </div>
      ))}
      <div
        className='text-sm leading-3 cursor-pointer font-basis-bold text-hka_blue whitespace-nowrap'
        onClick={addNewQuestionHandler}
      >
        + Add Question
      </div>

      {deleteQuestionModal?.showConfirmModal && (
        <DeleteQuestionConfirmModal
          onSubmit={removeQuestionHandler}
          onClose={() => setDeleteQuestionModal(undefined)}
          questionPosition={deleteQuestionModal.qPosition}
          questions={questions}
        />
      )}
      </div>
    </section>
  );
};

export default QuestionTabs;
