import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import IMAPBindingRow from "../IMAPBindingRow";
import ActionButton from "../../../../ActionButton";
import { IMAP_SETTINGS } from "../../../../../utils/phishingUtils";
import {
  getPhishingForwardedEmailIMAPBindings,
  updatePhishingForwardedEmailIMAPBinding,
} from "../../../../../api/phishing";
import { updateCompanyPhishingSettings } from "../../../../../api";
import { onError } from "../../../../../libs/errorLib";
import { toast } from "react-hot-toast";
import TestIMAPConfigurationModal from "./TestIMAPConfiguratioModal";
import LoaderInline from "../../../../LoaderInline";

const IMAP_SETTINGS_OPTIONS = Object.keys(IMAP_SETTINGS).map((key) => ({
  value: IMAP_SETTINGS[key],
  label: `${key[0].toUpperCase()}${key.slice(1).toLowerCase()}`,
}));
IMAP_SETTINGS_OPTIONS.push({
  value: null,
  label: "Other",
});

const DEFAULT_IMAP_SETTING = IMAP_SETTINGS_OPTIONS.find(
  (option) => option.label === "Google"
);

export default function IMAPSettings({
  auth,
  phishingCompanySettings,
  phishingSettingsReload,
}) {
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [emailService, setEmailService] = useState("");
  const [imapHost, setImapHost] = useState(DEFAULT_IMAP_SETTING.value.host);
  const [imapPort, setImapPort] = useState(DEFAULT_IMAP_SETTING.value.port);
  const [imapSettingsChanged, setImapSettingsChanged] = useState(false);
  const [showTestIMAPConfigurationModal, setShowTestIMAPConfigurationModal] =
    useState(false);
  const [
    phishingForwardedEmailIMAPBindings,
    setPhishingForwardedEmailIMAPBindings,
  ] = useState([]);

  useEffect(() => {
    if (phishingCompanySettings) {
      const option = IMAP_SETTINGS_OPTIONS.find(
        (option) =>
          option.value?.host === phishingCompanySettings.imap_ssl_host &&
          Number(option.value?.port) ===
            Number(phishingCompanySettings.imap_ssl_port)
      );

      if (option) {
        setEmailService(option.label);
        setImapHost(option.value?.host || "");
        setImapPort(option.value?.port || "");
      } else {
        setEmailService("Other");
        setImapHost(phishingCompanySettings.imap_ssl_host || "");
        setImapPort(phishingCompanySettings.imap_ssl_port || "");
      }
    }
  }, [phishingCompanySettings]);

  useEffect(() => {
    const onLoad = async () => {
      try {
        setLoading(true);
        setImapSettingsChanged(false);
        const imapBindings = await getPhishingForwardedEmailIMAPBindings();
        setPhishingForwardedEmailIMAPBindings(imapBindings);
      } catch (error) {
        onError(error);
      } finally {
        setLoading(false);
      }
    };

    onLoad();
  }, [reload, phishingSettingsReload]);

  useEffect(() => {
    const onEmailServiceChange = () => {
      const config = IMAP_SETTINGS_OPTIONS.find(
        (option) => option.label === emailService
      );

      if (config && config.value) {
        setImapHost(config.value.host);
        setImapPort(config.value.port);
      } else {
        setImapHost(phishingCompanySettings.imap_ssl_host || "");
        setImapPort(phishingCompanySettings.imap_ssl_port || "");
      }
    };

    onEmailServiceChange();
  }, [emailService]);

  const handleUpdateIMAPSettings = async () => {
    try {
      const promises = [];

      phishingForwardedEmailIMAPBindings.forEach((binding) =>
        promises.push(updatePhishingForwardedEmailIMAPBinding(binding))
      );

      await Promise.all(promises);

      await updateCompanyPhishingSettings({
        auth,
        payload: {
          imapHost,
          imapPort,
        },
      });

      toast.success("IMAP settings updated successfully!");
      setReload((v) => !v);
    } catch (error) {
      onError(error);
    }
  };

  const onTestIMAPConfigurationButtonClick = async () => {
    setShowTestIMAPConfigurationModal(true);
  };

  const imapConfiguration = useMemo(() => {
    return {
      host: imapHost,
      port: imapPort,
      bindings: phishingForwardedEmailIMAPBindings,
    };
  }, [phishingForwardedEmailIMAPBindings, imapHost, imapPort]);

  return (
    <section className="flex flex-col items-center p-2 mb-4 space-y-2 bg-white col-span-full shadow-light rounded-xl">
      {loading ? (
        <LoaderInline />
      ) : (
        <div className="flex flex-col items-center justify-between w-full space-y-1 sm:space-y-0 sm:space-x-2 sm:flex-row">
          <div className="w-full">
            <h3 className="setting__title mb-0.5">
              IMAP Host & Port Configuration Settings
            </h3>
            <div className="mb-1 space-y-1">
              <p className="text-sm">
                Configure your IMAP settings to allow Haekka to detect when a
                user forwards a phishing email to the specified email addresses.{" "}
                <br />
              </p>
            </div>
            <div className="flex flex-col mb-1 space-y-1 md:flex-row md:space-x-1 md:space-y-0">
              <Select
                className="w-full h-4 min-h-0 md:w-2/3"
                classNamePrefix="select"
                placeholder="Select your email service"
                classNames={"select--imap-host"}
                options={IMAP_SETTINGS_OPTIONS}
                value={IMAP_SETTINGS_OPTIONS.find(
                  (option) => option.label === emailService
                )}
                onChange={(option) => {
                  setImapSettingsChanged(true);
                  setEmailService(option.label);
                }}
                unstyled
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
              />
              <input
                disabled={!emailService || emailService !== "Other"}
                type="text"
                className="input input--imap-host"
                placeholder="IMAP Host"
                value={imapHost}
                onChange={(e) => {
                  setImapSettingsChanged(true);
                  setImapHost(e.target.value);
                }}
              />
              <input
                disabled={!emailService || emailService !== "Other"}
                type="text"
                className="input input--imap-port"
                placeholder="IMAP Port"
                value={imapPort}
                onChange={(e) => {
                  setImapSettingsChanged(true);
                  setImapPort(e.target.value);
                }}
              />
            </div>

            {emailService === "Google" && (
              <p className="mb-1 text-sm">
                <a
                  className="link"
                  href="https://support.google.com/accounts/answer/185833?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>{" "}
                to generate a Google App Password for Haekka to use to access
                your IMAP server.
              </p>
            )}
            <div className="flex flex-col w-full border-t border-t-hka_gray-light">
              <div className="mb-2">
                {phishingForwardedEmailIMAPBindings.map((binding, i) => (
                  <IMAPBindingRow
                    key={i}
                    index={i}
                    binding={binding}
                    imapHost={imapHost}
                    imapPort={imapPort}
                    setPhishingForwardedEmailIMAPBindings={
                      setPhishingForwardedEmailIMAPBindings
                    }
                    setImapSettingsChanged={setImapSettingsChanged}
                  />
                ))}
              </div>
              <div className="flex flex-col items-center justify-end w-full space-x-0 md:space-x-1 space-y-0.5 sm:flex-row sm:space-y-0">
                <ActionButton
                  type="primary"
                  onClick={onTestIMAPConfigurationButtonClick}
                >
                  Test IMAP Configuration
                </ActionButton>
                <ActionButton
                  disabled={!imapSettingsChanged}
                  type="success"
                  onClick={handleUpdateIMAPSettings}
                >
                  Save Settings
                </ActionButton>
              </div>
            </div>
          </div>
        </div>
      )}
      <TestIMAPConfigurationModal
        showModal={showTestIMAPConfigurationModal}
        onClose={() => setShowTestIMAPConfigurationModal(false)}
        imapConfiguration={imapConfiguration}
        onSubmit={handleUpdateIMAPSettings}
      />
    </section>
  );
}
