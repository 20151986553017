const ShowCorrectnessIndicatorOnResults = ({
  showCorrectnessIndicatorOnResults,
  setShowCorrectnessIndicatorOnResults,
}) => {
  return (
    <section>
      <h4 className='font-basis-bold'>
        Show correct and incorrect answers on results?{' '}
        <span className='text-sm text-hka_gray'>(Optional)</span>
      </h4>
      <section className='mb-3'>
        <p className='mb-1 text-sm'>
          Should Haekka display which answers are correct and incorrect when
          presenting results of a question to a user, regardless of the user's
          own answer selection?
        </p>
        <div className='flex items-center'>
          <label className='switch'>
            <input
              type='checkbox'
              checked={showCorrectnessIndicatorOnResults}
              onChange={() => setShowCorrectnessIndicatorOnResults((p) => !p)}
            />
            <span className='slider'></span>
          </label>
        </div>
      </section>
    </section>
  );
};

export default ShowCorrectnessIndicatorOnResults;
