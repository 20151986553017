import { useMutation } from '@tanstack/react-query';
import { submitEmployeeTrainingAssessmentQuestion } from '../../../api/employeeTrainingsApi';

export const useSubmitTrainingAssessmentQuestion = ({ cb = () => {} }) => {
  return useMutation({
    mutationFn: submitEmployeeTrainingAssessmentQuestion,
    onSuccess: (res) => {
      cb(res);
    },
  });
};
