import api from '../client';

export const getExternalAppIntegrationLink = async ({
  companyID,
  integration,
  config = { method: 'GET' },
}) => {
  const url = `/api/integrations/${integration}/company/${companyID}/oauth/url`;
  const params = {
    subdomain: config.subdomain,
  };

  if (config.method === 'GET') {
    const { data } = await api.get(url, { params });
    return data;
  }
  const { data } = await api.post(url, config, { params });
  return data;
};

export const createExternalAppWithBasicAuth = async ({
  companyID,
  integration,
  settings,
}) => {
  const { data } = await api.post(
    `/api/integrations/${integration}/company/${companyID}/basic_http_auth`,
    settings
  );
  return data;
};

export const updateExternalAppSettings = async ({
  companyID,
  integrationID,
  settings,
}) => {
  const { data } = await api.put(
    `/api/integrations/${integrationID}/company/${companyID}`,
    settings
  );
  return data;
};

export const listExternalApps = async () => {
  const { data } = await api.get('/api/integrations');
  return data;
};

export const removeExternalAppSettings = async ({
  companyID,
  integrationID,
  resourcetype,
}) => {
  const { data } = await api.delete(
    `/api/integrations/${integrationID}/company/${companyID}?resourcetype=${resourcetype}`
  );
  return data;
};

export const configureExternalAppWebhook = async ({
  companyID,
  integrationName,
}) => {
  const { data } = await api.post(
    `/api/integrations/${integrationName}/company/${companyID}/wh_config`
  );
  return data;
};

export const getWebhookIntegrationAuthHeader = async ({
  integration,
  companyID,
}) => {
  const { data } = await api.get(
    `/api/integrations/${integration}/company/${companyID}/get_webhook_auth_header`
  );
  return data;
};

export const getDoceboCourses = async ({
  page = 1,
  page_size = 200,
  search_term = '',
}) => {
  const { data } = await api.get(
    `/api/integrations/docebo/courses?page=${page}&page_size=${page_size}&search_term=${search_term}`
  );
  return data;
};

export const getGoogleGroups = async ({
  page = 1,
  page_size = 200,
  search_term = '',
  search_by,
}) => {
  const params = {
    page,
    page_size,
    search_term,
    search_by,
  };
  const { data } = await api.get(`/api/integrations/google/groups`, { params });
  return data;
};

export const getSeismicContent = async () => {
  const { data } = await api.get(`/api/integrations/seismic/content`);
  return data;
};

export const getMergeGoups = async ({
  companyID,
  integration,
  nextPage,
  search_term = '',
}) => {
  const params = { next_page: nextPage, search_term };
  const { data } = await api.get(
    `/api/integrations/merge/${integration}/company/${companyID}/get_merge_groups`,
    {
      params,
    }
  );
  return data;
};

export const storeMergeAccessToken = async ({
  integration,
  companyID,
  public_token,
}) => {
  const { data } = await api.post(
    `/api/integrations/merge/${integration}/company/${companyID}/store_access_token`,
    { public_token: public_token }
  );
  return data;
};

export const mergeGetLinkToken = async ({
  integration,
  companyID,
  employeeID,
}) => {
  const { data } = await api.get(
    `/api/integrations/merge/${integration}/company/${companyID}/employee/${employeeID}/get_link_token`
  );
  return data;
};
