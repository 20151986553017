import { convertToRaw } from 'draft-js';
import { convertToSlackNotation, getEditorState } from './wysiwygEditorHelpers';
import * as api from '../api';
import { modalModes } from './commonUtils';
import { videoUploadActions } from '../store/video-upload';
import { lessonActions } from '../store/lessonSlice';
import validator from 'validator';

export const lessonProperties = {
  TITLE: 'title',
  SUBTITLE: 'subtitle',
  IS_SIMPLE_LESSON: 'isSimpleLesson',
  VIDEO_URL: 'videoURL',
  EXTERNAL_RESOURCES: 'externalResources',
  SHOW_TO_BROWSER_LINK_IN_SLACK: 'showToBrowserLinkInSlack',
};

export const TRAINING_RENEW_CYCLES = {
  DOES_NOT_RENEW: 'none',
  ANNUALLY: 'annual',
  SEMI_ANNUALLY: 'semi-annual',
  QUARTERLY: 'quarterly',
};

export const allowOneCorrectAnswerOnly = (answers) => {
  let correctAnswers = 0;
  answers.forEach((a) => {
    if (a.correct) correctAnswers++;
  });
  if (correctAnswers > 1) {
    answers.forEach((a) => {
      a.correct = false;
    });
  }
  return answers;
};

export const initializeEditors = async (
  fetchedLesson,
  mode,
  updateEditorState
) => {
  // initialize Notes Editor State
  const nes = await getEditorState(
    fetchedLesson?.editor_content?.note_editor_state,
    fetchedLesson?.editor_content,
    fetchedLesson?.note,
    mode === modalModes.ADD
  );
  updateEditorState('notes', nes);

  // initialize Summary Editor State
  const ses = await getEditorState(
    fetchedLesson?.editor_content?.summary_editor_state,
    fetchedLesson?.editor_content,
    fetchedLesson?.summary,
    mode === modalModes.ADD
  );
  updateEditorState('summary', ses);

  // initialize Question Editor State
  const qes = await getEditorState(
    fetchedLesson?.editor_content?.question_editor_state,
    fetchedLesson?.editor_content,
    fetchedLesson?.questions[0]?.title,
    mode === modalModes.ADD
  );
  updateEditorState('question', qes);
};

export const initializeLesson = async ({
  isAuthenticated,
  lesson,
  dispatch,
  scenarioType,
  mode,
  updateEditorState,
}) => {
  const fetchedLesson = await api.getLesson({
    auth: isAuthenticated,
    lessonId: lesson.id,
  });
  dispatch(lessonActions.setFetchedLesson(fetchedLesson));

  dispatch(
    lessonActions.updateLesson({
      property: lessonProperties.IS_SIMPLE_LESSON,
      value: fetchedLesson.is_simple_form,
    })
  );

  dispatch(
    lessonActions.updateLesson({
      property: lessonProperties.TITLE,
      value: fetchedLesson.title,
    })
  );

  dispatch(
    lessonActions.updateLesson({
      property: lessonProperties.SUBTITLE,
      value: fetchedLesson.subtitle,
    })
  );

  dispatch(
    lessonActions.updateLesson({
      property: lessonProperties.EXTERNAL_RESOURCES,
      value: fetchedLesson.written_content || '',
    })
  );

  dispatch(
    lessonActions.updateLesson({
      property: lessonProperties.VIDEO_URL,
      value: fetchedLesson.video_url || '',
    })
  );

  dispatch(
    lessonActions.updateLesson({
      property: lessonProperties.SHOW_TO_BROWSER_LINK_IN_SLACK,
      value: fetchedLesson.show_to_browser_link_in_slack,
    })
  );

  dispatch(
    lessonActions.updateAnswerType({
      questionType: fetchedLesson.questions[0]?.type,
    })
  );

  if (scenarioType === 'branching') {
    dispatch(
      lessonActions.updateAnswerType({
        questionType: 'branching',
      })
    );
  }
  const lessonHasQuestion = fetchedLesson.questions[0];
  if (lessonHasQuestion) {
    dispatch(lessonActions.setQuestion({ ...fetchedLesson.questions[0] }));
  }
  dispatch(
    lessonActions.setAnswers(
      lessonHasQuestion
        ? fetchedLesson.questions[0]?.answers.map((a) => ({
            ...a,
            explanation: a.explanation === '' ? null : a.explanation,
          }))
        : []
    )
  );

  await initializeEditors(fetchedLesson, mode, updateEditorState);
  dispatch(lessonActions.setLoadingLesson(false));
};

export async function composeLessonPayload({
  fetchedLesson,
  curriculumId,
  notesEditorState,
  summaryEditorState,
  questionEditorState,
  isSimpleLesson,
  position,
  title,
  subtitle,
  videoURL,
  externalResources,
  allowMultipleCorrectAnswers,
  answers,
  showToBrowserLinkInSlack,
}) {
  //handle editors states
  const noteEditorStateRaw = JSON.stringify(
    convertToRaw(notesEditorState.getCurrentContent())
  );
  let notesSlackMarkup = undefined;
  if (!notesEditorState.getCurrentContent().hasText()) {
    notesSlackMarkup = null;
  } else {
    notesSlackMarkup = await convertToSlackNotation(
      notesEditorState.getCurrentContent(),
      fetchedLesson
    );
  }

  const summaryEditorStateRaw = JSON.stringify(
    convertToRaw(summaryEditorState.getCurrentContent())
  );
  const summarySlackMarkup = await convertToSlackNotation(
    summaryEditorState.getCurrentContent(),
    fetchedLesson
  );

  const questionEditorStateRaw = JSON.stringify(
    convertToRaw(questionEditorState.getCurrentContent())
  );

  let questionSlackMarkup = undefined;
  if (!questionEditorState.getCurrentContent().hasText()) {
    questionSlackMarkup = null;
  } else {
    questionSlackMarkup = await convertToSlackNotation(
      questionEditorState.getCurrentContent(),
      fetchedLesson
    );
  }

  // handle answers - do not let empty answers to be sent
  answers = answers.filter((a) => !!a.answer);
  answers = answers.map((answer, index) => ({
    ...answer,
    position: index,
    id: answer.id.startsWith('temp_id') ? undefined : answer.id,
  }));

  // check httpProtocol for written_content
  const isProtocolOk = externalResources?.startsWith('http');
  if (externalResources && !isProtocolOk) {
    externalResources = 'https://' + externalResources;
  }

  // handle title update
  if (/^((Lesson.+ \|))/i.test(title)) {
    title = title.substring(11, title.length);
  }

  // compose queston
  const question = {
    id: fetchedLesson?.questions[0]?.id,
    explanation: fetchedLesson?.questions[0]?.explanation || '',
    lesson: fetchedLesson?.questions[0]?.lesson,
    position: fetchedLesson?.questions[0]?.position || '',
    title: questionSlackMarkup || '',
    type: allowMultipleCorrectAnswers ? 'multi_select' : 'multi_choice',
    answers: answers,
  };

  // compose payload
  const payload = {
    id: fetchedLesson?.id,
    curriculum: curriculumId ? curriculumId : fetchedLesson.curriculum.id,
    explanation_content: fetchedLesson?.explanation_content || '',
    help_content: fetchedLesson?.help_content || '',
    includes_video: fetchedLesson?.includes_video,
    is_simple_form: isSimpleLesson,
    position: position,
    title: title,
    subtitle: subtitle,
    video_url: videoURL,
    show_to_browser_link_in_slack: showToBrowserLinkInSlack,
    written_content: externalResources,
    summary: summarySlackMarkup,
    note: notesSlackMarkup,
    editor_content: {
      id: fetchedLesson?.editor_content.id,
      content: fetchedLesson
        ? [...fetchedLesson.editor_content.content]
        : undefined,
      note_editor_state: noteEditorStateRaw,
      summary_editor_state: summaryEditorStateRaw,
      question_editor_state: questionEditorStateRaw,
    },
    question: isSimpleLesson && !questionSlackMarkup ? null : question,
    lesson_custom_video: fetchedLesson?.lesson_custom_video,
  };

  return payload;
}

export const handleCustomVideo = async ({
  uploadsInProgress,
  nextLessonPosition,
  dispatch,
  fetchedLesson,
  isAuthenticated,
  lesson,
}) => {
  const upload = uploadsInProgress.find(
    (u) =>
      u.id === fetchedLesson?.id &&
      u.type === 'lesson' &&
      u.parent === fetchedLesson?.id &&
      u.position === nextLessonPosition
  );
  // if mapped (video upload finished) -> update object
  if (upload && upload.mapped) {
    await api.updateCustomVideo({
      auth: isAuthenticated,
      reqData: { ...upload.uploadResponseData, object_id: lesson.id },
    });

    dispatch(
      videoUploadActions.updateUpload({
        upload: {
          id: fetchedLesson?.id,
          type: 'lesson',
          parent: fetchedLesson?.id,
          position: nextLessonPosition,
          success: true,
          message: 'Upload completed!',
          progress: 100,
          title: upload.uploadResponseData.name,
          completed: true,
        },
      })
    );
  }
  // if NOT mapped (video upload is NOT finished) -> update uploadProgress object in context
  // set it as mapped and set mappedId
  else if (upload && !upload.mapped) {
    dispatch(
      videoUploadActions.updateUpload({
        upload: {
          ...upload,
          success: true,
          message: 'Lesson saved. Waiting for upload to complete...',
          title: '',
          type: 'lesson',
          mapped: true,
          mappedId: lesson.id,
        },
      })
    );
  }
};

export const validateURL = (value, property) => {
  let checkedProperty = undefined;
  switch (property) {
    case lessonProperties.VIDEO_URL:
      checkedProperty = 'videoURLValid';
      break;
    case lessonProperties.EXTERNAL_RESOURCES:
      checkedProperty = 'externalResourcesValid';
      break;

    default:
      break;
  }
  if (value === '') return [true, checkedProperty];

  return [validator.isURL(value), checkedProperty];
};

export const validateEditLessonForm = ({
  title,
  isSimpleLesson,
  videoURLValid,
  externalResourcesValid,
  summaryEditorState,
  questionEditorState,
  answers,
}) => {
  if (summaryEditorState && questionEditorState) {
    const allAnswersHaveText = answers.every((a) => a.answer.length > 0);
    let correctAnswersNumber = 0;
    answers.forEach((a) => {
      if (a.correct) correctAnswersNumber++;
    });
    const isValid =
      allAnswersHaveText &&
      !!title &&
      summaryEditorState.getCurrentContent().hasText() &&
      (isSimpleLesson
        ? true &&
          (questionEditorState.getCurrentContent().hasText()
            ? !!answers[0]?.answer && correctAnswersNumber > 0
            : true)
        : !!answers[0]?.answer &&
          questionEditorState.getCurrentContent().hasText() &&
          correctAnswersNumber > 0) &&
      videoURLValid &&
      externalResourcesValid;
    return isValid;
  }
};
