import Modal from '../../Modal/Modal';

const DeleteStreamModal = ({ show, streamTitle, onSubmit, onClose }) => {
  return (
    <Modal
      show={show}
      title={`Delete Stream`}
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonText='Delete Stream'
      submitButtonType='danger'
      compact
    >
      <p>
        Are you sure you want to delete{' '}
        <span className='font-basis-bold'>{streamTitle}</span>?
      </p>
    </Modal>
  );
};

export default DeleteStreamModal;
