import { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./Modal.css";
import ReactTooltip from "react-tooltip";
import { Dialog, Transition } from "@headlessui/react";
import { useAppContext } from "../../libs/contextLib";
import ActionButton from "../ActionButton";

const Modal = ({
  show,
  title,
  customHeader,
  onClose,
  onSubmit,
  submitButtonText,
  submitButtonType = "success",
  submitValid = true,
  hideSubmitButton = false,
  expandable,
  noFooter,
  children,
  modalClass,
  compact,
  additionalFooterContent,
}) => {
  const [expanded, setExpanded] = useState(false);

  const { setNumberOfOpenModals } = useAppContext();

  const modalRef = useRef(false);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    ReactTooltip.rebuild();

    return () => {
      modalRef.current = true;
    };
  }, []);

  useEffect(() => {
    setNumberOfOpenModals((count) => {
      let newCount = show ? count + 1 : count - 1;

      if (newCount < 0) newCount = 0;

      return newCount;
    });
    return () => {
      if (modalRef.current) {
        setNumberOfOpenModals((count) => (count > 0 ? count - 1 : 0));
      }
    };
  }, [setNumberOfOpenModals, show]);

  const toggleFullscreen = () => {
    setExpanded(!expanded);
  };

  const handleOnClose = (e) => {
    onClose(e);
  };

  const handleOnSubmit = () => {
    onSubmit();
  };

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 antialiased"
          ref={modalRef}
          initialFocus={cancelButtonRef}
          onClose={() => null} // prevent clicks from shadow roots outside the panel (like Grammarly extension)
        >
          {/* Overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="modal__overlay" />
          </Transition.Child>

          {/* Modal */}
          <div className="fixed inset-0">
            <div className="flex items-center justify-center min-h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="transition-all transform ">
                  <div
                    className={`${compact ? "modal--compact" : "modal"}${
                      expanded ? " fullscreen" : ""
                    } ${modalClass ? modalClass : ""}`}
                  >
                    <div className="modal__content">
                      <header className="modal__header">
                        {customHeader ? (
                          customHeader
                        ) : (
                          // <h2 className="modal__heading">{title}</h2>
                          <Dialog.Title as="h2" className="modal__heading">
                            {title}
                          </Dialog.Title>
                        )}
                        <div className="modal__header-controls space-x-0.5">
                          {expandable && (
                            <button
                              className="button--fullscreen button--modal-header-control"
                              onClick={toggleFullscreen}
                            >
                              <span
                                className={`icon-arrow ${
                                  expanded ? "bottom-left" : "top-right"
                                }`}
                              ></span>
                            </button>
                          )}
                          <button
                            onClick={handleOnClose}
                            className="button--close button--modal-header-control"
                          >
                            <span className="icon-close"></span>
                          </button>
                        </div>
                      </header>
                      {children && (
                        <>
                          {noFooter ? (
                            <>{children}</>
                          ) : (
                            <main className="modal__body">{children}</main>
                          )}
                        </>
                      )}
                      {!noFooter && (
                        <footer className="modal__footer">
                          <button
                            ref={cancelButtonRef}
                            onClick={handleOnClose}
                            type="button"
                            className="button button--gray bg-hka_gray-light"
                          >
                            Cancel
                          </button>

                          {additionalFooterContent && (
                            <div className="w-1/2">
                              {additionalFooterContent}
                            </div>
                          )}

                          {!hideSubmitButton && (
                            <ActionButton
                              onClick={handleOnSubmit}
                              type={submitButtonType}
                              disabled={!submitValid}
                            >
                              {submitButtonText}
                            </ActionButton>
                          )}
                        </footer>
                      )}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  customHeader: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  submitButtonText: PropTypes.string,
  submitButtonType: PropTypes.string,
  submitValid: PropTypes.bool,
  expandable: PropTypes.bool,
  noFooter: PropTypes.bool,
  children: PropTypes.node,
  additionalFooterContent: PropTypes.node,
};

export default Modal;
