import { createSlice } from "@reduxjs/toolkit";
import { genAccessTokenMsg } from "./video-actions";

const initialState = {
  uploadsInProgress: [],
  updateResponses: {},
};

const videoUploaderSlice = createSlice({
  name: "video-upload",
  initialState,
  reducers: {
    updateUpload(state, action) {
      const { upload } = action.payload;

      let currentUploadIndex = state.uploadsInProgress.findIndex((u) =>
        upload.id
          ? u.id === upload.id &&
            u.type === upload.type &&
            u.position === upload.position &&
            u.parent === upload.parent
          : u.type === upload.type &&
            u.position === upload.position &&
            u.parent === upload.parent
      );

      let message = state.uploadsInProgress[currentUploadIndex]?.message;
      if (message === genAccessTokenMsg && upload.message !== " ") {
        message = upload.message;
      }

      if (upload.message?.length > 0) {
        message =
          upload.stillToLoad === 0
            ? `Updating ${
                upload.type === "engagement"
                  ? "Engagement data"
                  : upload.type === "engagement-question"
                  ? "Engagement Question data"
                  : "Lesson data"
              }`
            : upload.message;
      }

      if (currentUploadIndex >= 0) {
        state.uploadsInProgress[currentUploadIndex] = {
          ...state.uploadsInProgress[currentUploadIndex],
          ...upload,
          message,
        };
      } else {
        state.uploadsInProgress.push(upload);
      }
    },

    clearAllUploads(state) {
      state.uploadsInProgress = [];
      state.updateResponses = {};
    },

    clearCompletedUploads(state) {
      state.uploadsInProgress = state.uploadsInProgress.filter(
        (upload) => !upload.completed
      );
    },

    removeDeletedUpload(state, action) {
      state.uploadsInProgress = state.uploadsInProgress.filter(
        (upload) => upload.id !== action.payload.videoId
      );
      const tempResponsesObject = state.updateResponses;
      delete tempResponsesObject[action.payload.objectId];
      state.updateResponses = tempResponsesObject;
    },

    setUpdateResponses(state, action) {
      state.updateResponses = {
        ...state.updateResponses,
        [action.payload.id]: action.payload.updateResponse,
      };
    },
  },
});

export const videoUploadActions = videoUploaderSlice.actions;

export default videoUploaderSlice.reducer;
