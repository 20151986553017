import ProgressBar from '@ramonak/react-progress-bar';
import { useLocation } from 'react-router-dom';
import CertificateDownloadProgressBar from './CertificateDownloadProgressBar';
import { formatEmployeeTrainingProgressLabel } from '../../utils/training';

const LessonDetails = ({
  trainingComplete,
  requestCertificate,
  generatingCertificate,
  progress,
  showProgressBar,
  certificateError,
  completedLessons,
  totalLessons,
  subtitle,
  branchingScenario,
  employeeTraining,
}) => {
  const { pathname } = useLocation();

  const composeProgressLabel = () => {
    const totalLessons = employeeTraining.training_items.filter(
      (i) => i.type === 'lesson'
    ).length;
    const completedLessons = employeeTraining.training_items.filter(
      (i) => i.type === 'lesson' && i.finished
    ).length;
    const totalAssessments = employeeTraining.training_items.filter(
      (i) => i.type === 'assessment'
    ).length;
    const completedAssessments = employeeTraining.training_items.filter(
      (i) => i.type === 'assessment' && i.finished
    ).length;

    return formatEmployeeTrainingProgressLabel({
      totalLessons,
      completedLessons,
      totalAssessments,
      completedAssessments,
    });
  };

  return (
    <section className='hidden col-span-3 mx-3 lg:block'>
      <p className='mb-1 font-basis-bold'>Training Progress</p>
      <ProgressBar
        completed={((completedLessons / totalLessons) * 100).toFixed(2)}
        isLabelVisible={false}
        width={'100%'}
        height={'0.5rem'}
        borderRadius={'0.25rem'}
        baseBgColor={'#EFF0F7'}
        bgColor={'#F99A00'}
      />
      <section className='mb-4'>
        {branchingScenario && trainingComplete ? (
          <p className='mt-1 text-sm'>
            {completedLessons} of {completedLessons} lessons complete
          </p>
        ) : (
          <p className='mt-1 text-sm'>{composeProgressLabel()}</p>
        )}
      </section>
      {subtitle && (
        <>
          <p className='mb-1 font-basis-bold'>About this Training</p>
          <p className='mb-4 text-sm leading-1.7'>{subtitle}</p>
        </>
      )}
      <div className='relative'>
        <button
          disabled={
            pathname.includes('catalog')
              ? false
              : !trainingComplete || generatingCertificate
          }
          onClick={requestCertificate}
          className='w-full mb-1 button button--blue'
        >
          {generatingCertificate ? 'Generating' : 'Request'} Certificate
        </button>
        {showProgressBar && (
          <CertificateDownloadProgressBar
            progress={progress}
            certificateError={certificateError}
          />
        )}
        {pathname.includes('catalog') && (
          <p className='mt-1 text-sm leading-1.7'>
            Note: a demo certificate will be generated.
          </p>
        )}
      </div>
    </section>
  );
};

export default LessonDetails;
