import { Cell } from 'rsuite-table';
import { formatAvatar } from '../../../utils/formatAvatar';

const getAvatar = (avatar, name) => (
  <div className=''>
    {avatar ? (
      <div className='w-2.4 h-2.4'>
        <img src={avatar} alt={name} className='rounded-md'></img>
      </div>
    ) : (
      <div className='overflow-hidden'>
        {formatAvatar({
          name,
          width: 2.4,
          textSize: 'text-sm',
          marginRight: 0,
        })}
      </div>
    )}
  </div>
);

const EmployeeCell = ({
  rowData,
  dataKey,
  isPrivateEngagement,
  onRemindAttempt,
  onRemoveAttempt,
  ...props
}) => {
  return (
    <Cell {...props} style={{ padding: 0 }} className='cell--employee'>
      <div className='flex items-center justify-center w-24 px-1'>
        <div className='flex items-center flex-1 w-20 space-x-1'>
          {getAvatar(rowData[dataKey].avatar_image, rowData[dataKey].name)}
          <div className='truncate'>
            <p className='truncate w-13 font-basis-medium text-hka_gray-dark'>
              {rowData[dataKey].name}
            </p>

            {rowData[dataKey].active && !rowData[dataKey].is_sent && (
              <p className='text-sm -mt-0.1 opacity-70'>(scheduled)</p>
            )}
          </div>
        </div>

        {isPrivateEngagement &&
          rowData[dataKey].is_sent &&
          !rowData[dataKey].finished && (
            <span
              className='card__action icon-bell'
              onClick={() => onRemindAttempt(rowData[dataKey].id)}
            ></span>
          )}

        {isPrivateEngagement && rowData[dataKey].active && (
          <span
            onClick={() =>
              onRemoveAttempt({
                employeeID: rowData[dataKey].id,
                engagementID: rowData[dataKey].engagement_id,
                name: rowData[dataKey].name,
              })
            }
            className='card__action icon-trash'
          ></span>
        )}
      </div>
    </Cell>
  );
};

export default EmployeeCell;
