import { useState } from 'react';
import Select from 'react-select';

import Divider from '../../../Base/Divider';
import EmployeeResultItem from './EmployeeResultItem';

const AssessmentAttemptsResults = ({
  assessmentItem,
  isLoadingData,
  selectAttemptHandler,
  assessmentAttempts,
}) => {
  const [selectedAssessmentAttempt, setSelectedAssessmentAttempt] = useState(
    assessmentAttempts?.length > 0 ? assessmentAttempts[0] : undefined
  );

  return (
    <section className='mt-4'>
      {assessmentAttempts.length > 0 && (
        <div className='flex justify-end w-full'>
          <Select
            onChange={(value) => {
              setSelectedAssessmentAttempt(value);
            }}
            value={selectedAssessmentAttempt}
            options={assessmentAttempts}
            classNamePrefix='select'
            unstyled
            maxMenuHeight='17rem'
            menuPlacement='auto'
            className='w-2/5'
          />
        </div>
      )}

      <div key={assessmentItem.value.id} className='mt-2'>
        <div className='mb-1 text-base '>
          Quiz {assessmentItem.itemSpecificPosition}:{' '}
          {assessmentItem.value.assessment.title}
        </div>

        <Divider horizontal />

        {assessmentItem.value.assessment.questions.map((question, index) => (
          <EmployeeResultItem
            key={question.id}
            itemType={'Assessment'}
            lesson={{ title: '' }}
            position={index + 1}
            isLoadingData={isLoadingData}
            attemptResults={selectedAssessmentAttempt?.data.question_attempts.find(
              (qa) => qa.assessment_question_id === question.id
            )}
            onAttemptSelect={selectAttemptHandler}
            assessmentItemId={assessmentItem.value.id}
          />
        ))}
      </div>

      {selectedAssessmentAttempt &&
        !!selectedAssessmentAttempt.data.finish_date && (
          <div className='flex justify-end w-full'>
              <p className='mt-1 text-sm text-hka_gray-dark'>
                Quiz Grade:{' '}
                <span className='ml-0.5 font-basis-bold'>
                {(
                  (selectedAssessmentAttempt.data.earned_score /
                    selectedAssessmentAttempt.data.possible_score) *
                  100
                ).toFixed(0)}
                %
                </span>
              </p>
            </div>
          
        )}

      {selectedAssessmentAttempt &&
        !selectedAssessmentAttempt.data.finish_date && (
          <div className='flex justify-end'>
            <div className='flex flex-col pt-2'>
              <h2 className='w-full font-basis-regular'>Quiz Grade: N/A</h2>
            </div>
          </div>
        )}
    </section>
  );
};

export default AssessmentAttemptsResults;
