const LoaderInline = ({
  wrapperClasses = "w-full min-h-24",
  loaderSize = "6",
}) => {
  return (
    <div className={`flex justify-center items-center ${wrapperClasses}`}>
      <img
        src="https://haekka-public-images.s3-us-west-2.amazonaws.com/HKA--loading-animation.gif"
        alt=""
        className={`w-${loaderSize} h-${loaderSize}`}
      />
    </div>
  );
};

export default LoaderInline;
