import { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { format } from 'date-fns';

import { Table, Column, ColumnGroup } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';
import './table.css';

import {
  useChannelEngagementReactionsData,
  useChannelEngagementResults,
  useCompanyEngagementData,
  useEmployeeEngagementResultsData,
  useEngagementSchedulesData,
  useRemindEmployees,
  useRemoveEmployee,
} from '../../../hooks/data-hooks/engagements';
import useDebounce from '../../../utils/custom-hooks/useDebounce';

import {
  currentEmployeesReactions,
  composeTitle,
  transformEmployeesResults,
} from '../../../utils/engagementsUtils';

import Pagination from '../../Pagination';
import RemindEmployeesModal from '../confirmationModals/RemindEmployeesModal';
import RemoveEmployeeConfirmationModal from '../confirmationModals/RemoveEmployeeConfirmationModal';
import Loader from '../../Loader';
import Note from '../../Note';
import SortableHeaderCell from './SortableHeaderCell/SortableHeaderCell';
import EmployeeCell from './EmployeeCell';
import StatusCell from './StatusCell';
import CompletedDateCell from './CompletedDateCell';
import AnswerCell from './AnswerCell';
import AcknowledgedCell from './AcknowledgedCell';
import ReactionsCell from './ReactionsCell';
import PreviewMessageModal from './PreviewMessageModal';

const dateFormat = 'MMM d, yyyy';

const EmployeeEngagementsTable = ({ isAnonymousEngagement }) => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [employeeResults, setEmployeeResults] = useState([]);
  const [channelEngagementResults, setChannelEngagementResults] = useState([]);
  const [channelEngagementReactions, setChannelEngagementReactions] = useState(
    []
  );
  const [globalMessages, setGlobalMessages] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(undefined);
  const [nameSearch, setNameSearch] = useState('');
  const [sortColumn, setSortColumn] = useState(undefined);
  const [sortType, setSortType] = useState(undefined);

  const searchNameValue = useDebounce(nameSearch, 300);

  const [allEmployeesReminder, setAllEmployeesReminder] = useState(undefined);
  const [removeEmployee, setRemoveEmployee] = useState(undefined);
  const [previewMessageModal, setPreviewMessageModal] = useState(undefined);

  const { data: companyEngagement, isLoading: isLoadingEngagments } =
    useCompanyEngagementData({
      id,
    });
  const engagementType = companyEngagement?.quiz.engagement_type;
  let answer_type = '';
  if (engagementType === 'channel') {
    answer_type = companyEngagement.quiz.questions[0].answer_type;
  }

  const { data, isLoading: isLoadingResults } =
    useEmployeeEngagementResultsData({
      currentPage,
      companyEngagementID: id,
      keys: [selectedSchedule?.value, searchNameValue, sortColumn, sortType],
      enabled: engagementType === 'private',
    });

  const {
    data: paginatedChannelEngagementResults,
    isLoading: isLoadingChannelEngagement,
  } = useChannelEngagementResults({
    currentPage,
    companyEngagementID: id,
    keys: [selectedSchedule?.value, searchNameValue, sortColumn, sortType],
    enabled: engagementType === 'channel',
  });

  const {
    data: videoReactions,
    error: videoReactionsError,
    isLoading: isLoadingVideoReactions,
  } = useChannelEngagementReactionsData({
    channel: companyEngagement.quiz.channel,
    timestamp: companyEngagement.quiz.channel_engagements[0]?.video_ts,
    enabled: !!companyEngagement.quiz.channel_engagements[0]?.video_ts,
  });

  const {
    data: messageReactions,
    error: messageReactionsError,
    isLoading: isLoadingMessageReactions,
  } = useChannelEngagementReactionsData({
    channel: companyEngagement.quiz.channel,
    timestamp: companyEngagement.quiz.channel_engagements[0]?.message_ts,
    enabled: !!companyEngagement.quiz.channel_engagements[0]?.message_ts,
  });

  const { mutate: remindEmployeesMutation } = useRemindEmployees({
    onClose: () => setAllEmployeesReminder(undefined),
  });

  const { mutate: removeEmployeeMutation } = useRemoveEmployee({
    onClose: () => setRemoveEmployee(undefined),
  });

  useEffect(() => {
    const combinedReactions = currentEmployeesReactions({
      videoReactions,
      messageReactions,
      channelEngagementResults,
    });
    setChannelEngagementReactions(combinedReactions);
  }, [messageReactions, videoReactions, channelEngagementResults]);

  const { data: schedules } = useEngagementSchedulesData({
    companyEngagementID: id,
    transformData: false,
  });

  useEffect(() => {
    if (data && engagementType === 'private') {
      const globMessages = companyEngagement.quiz.questions;
      const transformedData = transformEmployeesResults({
        data,
        questions: globMessages,
      });
      setEmployeeResults(transformedData);
      setGlobalMessages(
        globMessages.map((m) => ({ title: composeTitle(m), id: m.id }))
      );
    }
    if (!isLoadingChannelEngagement && engagementType === 'channel') {
      setChannelEngagementResults(
        paginatedChannelEngagementResults?.results.map((data) => ({
          ...data,
          answer_type,
        }))
      );
    }
  }, [
    answer_type,
    companyEngagement,
    data,
    engagementType,
    isLoadingChannelEngagement,
    paginatedChannelEngagementResults,
  ]);

  const handleSortColumn = (sortColumn, sortType) => {
    setSortColumn(sortColumn);
    setSortType(sortType);
  };

  const mappedSchedules = () => {
    if (schedules) {
      return schedules.map((schedule) => ({
        value: schedule.id,
        label: format(new Date(schedule.send_time), dateFormat),
      }));
    }
    return [];
  };

  const handleReminder = (data) => {
    remindEmployeesMutation({
      receiverUserId: data.id,
      companyEngagementID: companyEngagement.id,
    });
  };

  const removeEmployeeHandler = () => {
    removeEmployeeMutation({
      employeeID: removeEmployee.employeeID,
      employeeEngagmentID: removeEmployee.engagementID,
    });
  };

  const mockSchedule = () => {
    let mock = undefined;
    if (schedules.length > 1) {
      mock = schedules
        .sort((a, b) => new Date(a.created) - new Date(b.created))
        .filter((s) => s.is_sent)[0];
    } else {
      mock = schedules[0];
    }
    return {
      value: mock.id,
      label: format(new Date(mock.send_time), dateFormat),
    };
  };

  if (engagementType === 'private' && (isLoadingEngagments || isLoadingResults))
    return <Loader />;

  if (engagementType === 'channel' && isLoadingChannelEngagement)
    return <Loader />;

  if (
    engagementType === 'channel' &&
    companyEngagement.quiz.channel_engagements.length === 0
  ) {
    return (
      <Note type='info'>
        <p>
          This channel Engagement has not been sent yet. No employees have
          acknowledged or reacted to this Engagement yet.
        </p>
      </Note>
    );
  }

  if (
    engagementType === 'channel' &&
    companyEngagement.quiz.questions.length === 0
  ) {
    return (
      <Note type='info'>
        <p>
          This channel Engagement has no messages yet. No employees have
          acknowledged or reacted to this Engagement yet.
        </p>
      </Note>
    );
  }

  const reactionScopeIsMissing = videoReactionsError || messageReactionsError;
  const isLoadingReactions =
    (videoReactions && isLoadingVideoReactions) || isLoadingMessageReactions;

  const isTableDataLoading =
    engagementType === 'private'
      ? isLoadingEngagments || isLoadingResults
      : isLoadingChannelEngagement;

  const tableData =
    engagementType === 'private' ? employeeResults : channelEngagementResults;

  const rowsCount =
    engagementType === 'private'
      ? data.count
      : paginatedChannelEngagementResults?.count;

  return (
    <Fragment>
      <div className='justify-between mb-1 space-y-1 sm:flex sm:space-y-0'>
        <div className='flex items-center flex-1 pl-0.5 bg-white rounded-lg lg:flex-none border border-hka_gray-border w-30'>
          <span className='icon-search text-hka_gray mt-0.2'></span>
          <input
            className='h-3 text-base border-none max-h-3.8 focus:outline-none'
            type='text'
            value={nameSearch}
            onChange={(e) => setNameSearch(e.target.value)}
            placeholder='Search Employees'
            autoFocus
          />
          {engagementType === 'private' && (
            <span
              onClick={() => setAllEmployeesReminder({ type: 'ALL_USERS' })}
              className='icon-bell text-hka_gray border-l border-hka_gray-border px-0.5 cursor-pointer'
            ></span>
          )}
        </div>
        <div className='w-full sm:ml-1 sm:w-24'>
          <Select
            value={selectedSchedule ?? mockSchedule()}
            options={mappedSchedules()}
            onChange={(schedule) => setSelectedSchedule(schedule)}
            placeholder={'Select Period'}
            classNamePrefix='select'
            unstyled
          />
        </div>
      </div>

      <Table
        classPrefix='rs-table'
        // bordered
        cellBordered
        height={400}
        autoHeight
        affixHeader
        affixHorizontalScrollbar
        headerHeight={60}
        data={tableData}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        wordWrap='break-word'
        loading={isTableDataLoading}
      >
        <Column width={240} verticalAlign='middle' fixed sortable>
          <SortableHeaderCell>Employee</SortableHeaderCell>
          <EmployeeCell
            dataKey='employee'
            isPrivateEngagement={engagementType === 'private'}
            onRemindAttempt={(id) =>
              setAllEmployeesReminder({ type: 'SINGLE_USER', id })
            }
            onRemoveAttempt={(employeeData) => setRemoveEmployee(employeeData)}
          />
        </Column>

        {engagementType === 'private' && (
          <Column
            width={140}
            verticalAlign='middle'
            fixed
            flexGrow={isAnonymousEngagement ? 1 : undefined}
            sortable
          >
            <SortableHeaderCell>Status</SortableHeaderCell>
            <StatusCell dataKey='status' />
          </Column>
        )}

        {engagementType === 'private' && (
          <Column
            width={150}
            verticalAlign='middle'
            fixed
            flexGrow={isAnonymousEngagement ? 1 : undefined}
            sortable
          >
            <SortableHeaderCell>Completed Date</SortableHeaderCell>
            <CompletedDateCell dataKey='finish_date' />
          </Column>
        )}

        {engagementType === 'private' && !isAnonymousEngagement && (
          <ColumnGroup
            header={
              selectedSchedule?.label ??
              format(new Date(schedules[0].send_time), dateFormat)
            }
            align='left'
            verticalAlign='middle'
            groupHeaderHeight={30}
          >
            {globalMessages.map((item, i) => (
              <Column
                width={200}
                flexGrow={i === globalMessages.length - 1 ? 1 : undefined}
                minWidth={i === globalMessages.length - 1 ? 200 : undefined}
                key={item.id}
              >
                <SortableHeaderCell>
                  <div
                    onClick={() => setPreviewMessageModal({ message: item.id })}
                    className='text-sm line-clamp-1 link font-basis-medium'
                  >
                    {item.title}
                  </div>
                </SortableHeaderCell>
                <AnswerCell dataKey={item.id} />
              </Column>
            ))}
          </ColumnGroup>
        )}

        {engagementType === 'channel' && (
          <ColumnGroup
            header={
              selectedSchedule?.label ??
              format(new Date(schedules[0].send_time), dateFormat)
            }
            align='left'
            verticalAlign='middle'
            groupHeaderHeight={30}
          >
            <Column width={200}>
              <SortableHeaderCell>Message</SortableHeaderCell>
              <AcknowledgedCell dataKey={'acknowledged'} />
            </Column>

            {
              <Column flexGrow={1}>
                <SortableHeaderCell>Employee Reactions</SortableHeaderCell>
                <ReactionsCell
                  dataKey={'employee'}
                  isLoading={isLoadingReactions}
                  channelEngagementReactions={channelEngagementReactions}
                  reactionScopeIsMissing={reactionScopeIsMissing}
                />
              </Column>
            }
          </ColumnGroup>
        )}
      </Table>

      <div className='mt-1'>
        <Pagination
          pageSize={15}
          count={rowsCount}
          currentPage={currentPage}
          onPageChanged={(page) => setCurrentPage(page)}
        />
      </div>

      <RemindEmployeesModal
        type={allEmployeesReminder?.type}
        showModal={!!allEmployeesReminder}
        onClose={() => setAllEmployeesReminder(undefined)}
        onSendReminder={() => handleReminder(allEmployeesReminder)}
      />
      <RemoveEmployeeConfirmationModal
        showModal={!!removeEmployee}
        employeeData={removeEmployee}
        onSumbit={removeEmployeeHandler}
        onClose={() => setRemoveEmployee(undefined)}
      />
      {!!previewMessageModal && (
        <PreviewMessageModal
          showModal={!!previewMessageModal}
          onClose={() => setPreviewMessageModal(undefined)}
          message={companyEngagement.quiz.questions.find(
            (q) => q.id === previewMessageModal?.message
          )}
        />
      )}
    </Fragment>
  );
};

export default EmployeeEngagementsTable;
