import WysiwygRichTextEditor from '../WysiwygEditor/WysiwygRichTextEditor';
import Answer from './Answer';
import { useSelector, useDispatch } from 'react-redux';
import { lessonActions } from '../../store/lessonSlice';
import React from 'react';
import SortableContainer from '../SortableContainer';
import { reorderList } from '../../utils/commonUtils';
import { useEffect } from 'react';

const EditQuestion = ({
  questionEditorState,
  updateEditorState,
  scenarioType,
}) => {
  const dispatch = useDispatch();

  const {
    isSimpleLesson,
    allowMultipleCorrectAnswers,
    question,
    fetchedLesson,
    answers,
  } = useSelector((state) => state.lesson);

  const addImageHandler = (id) => {
    dispatch(lessonActions.addImage(id));
  };

  const onDragEndHandler = async ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = answers.findIndex((answer) => answer.id === active.id);
      const newIndex = answers.findIndex((answer) => answer.id === over.id);
      const items = reorderList(answers, oldIndex, newIndex);
      dispatch(lessonActions.setAnswers(items));
    }
  };

  // Override the z-index of the draggable elements
  useEffect(() => {
    const elements = Array.from(
      document.getElementsByClassName('lesson--answers')
    );

    elements.forEach((element, i) =>
      element.setAttribute('style', `z-index: ${99999 - i} !important`)
    );
  }, [answers]);

  return (
    <div>
      <section className='w-full mb-2'>
        <h4 className='mb-1 font-basis-bold'>
          Question{' '}
          <span className='text-sm text-hka_gray'>
            {isSimpleLesson ? '(Optional)' : '(Required)'}
          </span>
        </h4>
        <WysiwygRichTextEditor
          editorState={questionEditorState}
          onSetEditorState={(state) => updateEditorState('question', state)}
          id={fetchedLesson?.id}
          onImageAdded={addImageHandler}
          contentType={'lesson'}
        />
      </section>

      {scenarioType === 'lesson_submit' && (
        <section className='flex items-center justify-between w-full mb-2'>
          <p className=''>Allow multiple correct answers for this question?</p>
          <label className='switch'>
            <input
              type='checkbox'
              name='users_completed_training_admin'
              checked={allowMultipleCorrectAnswers}
              onChange={() => {
                dispatch(lessonActions.updateAnswerType({}));
              }}
            />
            <span className='slider'></span>
          </label>
        </section>
      )}
      <section className='mb-2 bg-hka_gray-ultraLight px-2.5 -mx-2.5 py-2 border-t border-b border-hka_gray-light'>
        <h4 className='mb-1 font-basis-bold'>
          Answer(s){' '}
          <span className='text-sm text-hka_gray'>
            {isSimpleLesson
              ? questionEditorState?.getCurrentContent().hasText()
                ? '(Required)'
                : '(Optional)'
              : '(Required)'}
          </span>
        </h4>
        <div className='relative z-10'>
          <SortableContainer
            onDragEndHandler={onDragEndHandler}
            containerName='lesson--answers'
          >
            {answers.map((answer) => (
              <Answer
                currAnswer={answer}
                scenarioType={scenarioType}
                key={answer.id}
                aIndex={answer.id}
                sortableID={answer.id}
              />
            ))}
          </SortableContainer>
        </div>
        <p
          className='inline-flex w-auto cursor-pointer button button--blue'
          onClick={() => {
            dispatch(lessonActions.addAnswer({ questionId: question?.id }));
          }}
        >
          Add Answer
        </p>
      </section>
    </div>
  );
};

export default EditQuestion;
