import Modal from "../../Modal/Modal";

const RemoveAssignedEmployee = ({
  onClose,
  onSubmit,
  selectedEmployeeForRemoval,
}) => {
  return (
    <Modal
      show={true}
      title="Remove Employee"
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonText="Remove Employee"
      submitButtonType="danger"
      compact
    >
      <p>
        Are you sure you want to remove{" "}
        <span className="font-basis-bold">
          {selectedEmployeeForRemoval.name}
        </span>{" "}
        from this workflow?
      </p>
    </Modal>
  );
};

export default RemoveAssignedEmployee;
