import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

import * as api from "../../../api";
import { useAppContext } from "../../../libs/contextLib";
import { onError } from "../../../libs/errorLib";

import VideoUploader from "../../VideoUpload/VideoUploader";
import RemoveVideoConfirmationModal from "./confirmationModals/RemoveVideoConfirmationModal";
import { onDeleteMessageSuccess } from "../../../utils/engagementsUtils";
import { handleVideoDeletedTrack } from "../../../utils/video-upload-utils";
import Note from "../../../components/Note";

const VideoSection = ({
  video,
  setVideo,
  companyEngagement,
  selectedQuestion,
  nextQuestionPosition,
  videoUploadCompletenessChecked,
  questionEditorState,
  isReadOnly,
}) => {
  const queryClient = useQueryClient();
  const { isAuthenticated } = useAppContext();
  const [showDeleteVideoModal, setShowDeleteVideoModal] = useState(false);

  const { isLoading: isDeleteVideoMutating, mutate: deleteVideoMutation } =
    useMutation({
      mutationFn: api.deleteCustomVideo,
      onSuccess: () => {
        setShowDeleteVideoModal(false);
        setVideo(undefined);
        toast.success("Video deleted");
        queryClient.setQueryData(["engagement", companyEngagement.id], (curr) =>
          onDeleteMessageSuccess(curr, selectedQuestion)
        );
        queryClient.refetchQueries(["engagement", companyEngagement.id]);
      },
    });

  const onVideoDeleteAttempt = () => {
    setShowDeleteVideoModal(true);
  };

  const deleteVideoHandler = () => {
    try {
      deleteVideoMutation({
        auth: isAuthenticated,
        videoId: video.id,
        type: "engagement-question",
      });
    } catch (error) {
      onError(error);
    }
  };

  const captionJSX = (
    <h4 className="mb-0.5">
      Video Upload{" "}
      <span className="text-sm text-hka_gray">(MP4 format only)</span>
    </h4>
  );

  return (
    <section className="section--video-upload">
      {!!video && (
        <section className="">
          {captionJSX}
          <div className="video--uploaded">
            <img
              src={video.thumbnail}
              alt={video.name}
              className="w-auto h-4 rounded"
            />{" "}
            <div className="flex-1 truncate">
              <p className="text-sm truncate font-basis-medium">{video.name}</p>
              <p className="text-sm">({video.duration} sec)</p>
            </div>
            <button
              className="button button--gray"
              onClick={onVideoDeleteAttempt}
            >
              Delete
            </button>
          </div>
          {!video.processed && videoUploadCompletenessChecked && (
            <Note type="default">
              <p>
                👋 Your video is being processed. We will send you a message in
                Slack once it's ready. Depending on the size of your file, it
                can take several minutes.
              </p>
            </Note>
          )}
        </section>
      )}

      {!video && (
        <>
          {/* {captionJSX} */}
          {!!selectedQuestion && (
            <>
              {captionJSX}
              <VideoUploader
                type={"engagement-question"}
                id={selectedQuestion?.id}
                position={nextQuestionPosition.current}
                parent={companyEngagement.quiz.id}
                compact
                disabled={isReadOnly}
              />
            </>
          )}
          {!selectedQuestion && (
            <Note type="default">
              <span className="icon-video mb-0.2 mr-0.5"></span>
              <p>Save this message first before uploading a video.</p>
            </Note>
          )}
        </>
      )}

      {!isReadOnly && showDeleteVideoModal && (
        <RemoveVideoConfirmationModal
          showModal={showDeleteVideoModal}
          questionEditorState={questionEditorState}
          onSubmit={deleteVideoHandler}
          onClose={() => setShowDeleteVideoModal(false)}
          isSubmitValid={!isDeleteVideoMutating}
        />
      )}
    </section>
  );
};

export default VideoSection;
