export const denormalizeTestPhishingIMAPConfigurationPayload = (payload) => {
  // Bindings within the payload should only contain the 'email' and 'password' properties.
  // The 'decrypted_password' property should be set to 'password'.
  // The 'host' and 'port' properties should be left untouched.

  const { bindings } = payload;

  return {
    ...payload,
    bindings: bindings.map((binding) => ({
      email: binding.email,
      password: binding.decrypted_password,
    })),
  };
};
