import { Fragment, useState } from "react";

import { useDeleteMessageData } from "../../../hooks/data-hooks/engagements";

import { composeTitle } from "../../../utils/engagementsUtils";
import ListItems from "../../ListItems";
import EngagementMessageCard from "./EngagementMessageCard";
import RemoveMessageConfirmationModal from "./confirmationModals/RemoveMessageConfirmationModal";

const ListEngagementsMessages = ({
  companyEngagementID,
  questions,
  engagmentStatistics,
  isLoadingStatistics,
  onQuestionEdit,
  isReadOnly,
}) => {
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);

  const { isLoading: isMutating, mutate: deleteMessageMutation } =
    useDeleteMessageData({
      companyEngagementID,
      selectedQuestionId,
      setSelectedQuestionId,
    });

  const deleteMessagehandler = () => {
    deleteMessageMutation({
      messageID: selectedQuestionId,
    });
  };

  return (
    <Fragment>
      <ListItems
        fullWidth
        emptyListText={
          'Oops! It looks like you don\'t have any question in this training. Click "Add Question" button to create a question.'
        }
        flex={"row"}
      >
        {questions.map((question) => {
          const title = composeTitle(question);
          return (
            <EngagementMessageCard
              key={question.id}
              question={question}
              questionTitle={title}
              onQuestionDeleteAttempt={() => setSelectedQuestionId(question.id)}
              onQuestionEdit={() => onQuestionEdit(question.id)}
              messageStatistics={engagmentStatistics?.find(
                (data) => data.question === question.id
              )}
              isLoadingStatistics={isLoadingStatistics}
              controlsDisabled={isReadOnly}
            />
          );
        })}
      </ListItems>
      {selectedQuestionId && (
        <RemoveMessageConfirmationModal
          showModal={!!selectedQuestionId}
          onClose={() => {
            setSelectedQuestionId(null);
          }}
          onSubmit={deleteMessagehandler}
          sumbitValid={!isMutating}
        />
      )}
    </Fragment>
  );
};

export default ListEngagementsMessages;
