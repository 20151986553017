import { useExternalAppsData } from '../../../hooks/data-hooks/integrations/useExternalAppsData';
import { useAppContext } from '../../../libs/contextLib';
import Callout from '../../Callout';
import UpgradePlanButton from '../../UpgradePlanButton';
import LoaderInline from '../../LoaderInline';
import Select from 'react-select';
import { useContext, useEffect } from 'react';
import AssignEmployeesContext from '../../../store/assign-employeees-context';
import Note from '../../Note';
import Divider from '../../Base/Divider';
import SeismicWebHook from './SeismicWebHook';

const calloutMessage =
  String.fromCodePoint('0x1F44B') +
  ` Your plan does not include access to Haekka's Seismic integration. Please upgrade your plan.`;

const SeismicOption = () => {
  const { isAuthenticated } = useAppContext();
  const {
    object,
    allSeismicUsers,
    setAllSeismicUsers,
    addNewSeismicUsers,
    setAddNewSeismicUsers,
    setActiveSeismicIntegration,
  } = useContext(AssignEmployeesContext);

  const { data: externalIntegrations, isLoading: isLoadingExternalApps } =
    useExternalAppsData();
  const activeSeismicApp = externalIntegrations?.results.find(
    (ei) => ei.resourcetype === 'SeismicInstallation' && ei.is_active
  );

  useEffect(() => {
    setActiveSeismicIntegration(activeSeismicApp);
    setAddNewSeismicUsers(activeSeismicApp?.webhook_enabled ?? false);
  }, [activeSeismicApp, setActiveSeismicIntegration, setAddNewSeismicUsers]);

  if (isLoadingExternalApps) return <LoaderInline />;

  if (!isAuthenticated.company_details.has_seismic) {
    return (
      <div className='col-span-full'>
        <Callout
          title={'Haekka'}
          message={calloutMessage}
          permanent
          date={false}
        >
          <UpgradePlanButton parentCpmToTrack={'Settings'} />
        </Callout>
      </div>
    );
  }

  if (!object.seismic_content) {
    return (
      <p className='py-3'>
        👋 We did not find a Seismic content associated with this Training. You
        can associate a Seismic content with this training in the Training
        Settings.
      </p>
    );
  }

  return (
    <section className='py-2'>
      <section>
        <h4 className='mb-1 font-basis-bold'>
          Add users from the associated Seismic course:
        </h4>
        <Select
          onChange={() => {}}
          value={{
            label: object.seismic_content.title,
            value: object.seismic_content.course_id,
          }}
          classNamePrefix='select'
          className='select--select-employees-manually'
          unstyled
          placeholder='Search Seismic Content'
          isDisabled
        />
        <p className='mt-1'>
          This Haekka training is currently associated with{' '}
          <b>{object.seismic_content.title}</b> course in Seismic. You can
          change this in the training settings.
        </p>
      </section>
      <section className='mt-3'>
        <Note type='note'>
          <p className='text-base2 text-hka_gray-dark'>
            To ensure all existing and future users in this Seismic course are
            assigned in Haekka, toggle both switches below. Haekka will run a
            sync before notifying any users to ensure that employees who have
            completed this training are not inadvertently notified.
          </p>
        </Note>
      </section>
      <section className='mt-3'>
        <div className='flex justify-between pb-2'>
          <p className='text-hka_gray-dark'>
            Add <span className='font-basis-bold'>all existing users</span>
          </p>
          <label className='switch'>
            <input
              type='checkbox'
              name='select-all-users'
              checked={allSeismicUsers}
              onChange={(e) => setAllSeismicUsers(e.target.checked)}
              className='cursor-pointer'
            />
            <span className='slider'></span>
          </label>
        </div>

        <Divider />

        <SeismicWebHook
          seismicApp={activeSeismicApp}
          addNewSeismicUsers={addNewSeismicUsers}
          setAddNewSeismicUsers={setAddNewSeismicUsers}
        />
      </section>
    </section>
  );
};

export default SeismicOption;
