import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createEngagementCombinedHandler } from '../../../utils/engagementsUtils';
import { onError } from '../../../libs/errorLib';
import { useNavigate } from 'react-router-dom';

export const useCreateEngagementData = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: createEngagementCombinedHandler,
    onSuccess: (newCompanyEngagement) => {
      queryClient.setQueryData(
        ['engagement', newCompanyEngagement.id],
        newCompanyEngagement
      );
      navigate(`/engagements/${newCompanyEngagement.id}`);
    },
    onError: (_error) => onError(_error),
  });
};
